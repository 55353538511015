import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";

import NavPills from "components/NavPills/NavPills.js";
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import BackupIcon from '@material-ui/icons/Backup';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'

import EduLabsAttendantsTab from './EduLabsAttendantsTab.js'
import CodesUploadTab from './CodesUploadTab.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
}))

const AdminEduLabs = () => {
  const classes = useStyles()

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  //Snackbar messages (to inform user of success)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const handleShowSuccessMessage = (message) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message)
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} >
          <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link color="inherit" href="/admin/SelectTransSem">
                  Main
                </Link>
                <Typography color="textPrimary">Διαχείριση εργαστηρίων</Typography>
            </Breadcrumbs>
          </div>
        </Grid>

       <Grid item xs={12} style={{ paddingTop : '20px'}}>

         <NavPills
           color="warning"
           alignCenter
           tabs={[
             {
               tabButton: "Διαχείριση Εγγραφών",
               tabIcon: SubscriptionsIcon,
               tabContent: (
                 <EduLabsAttendantsTab showSuccess={(msg) => handleShowSuccessMessage(msg)}
                  showError={(msg) => setAlert(<ErrorAlert message={msg} hideAlert={hideAlert}/>)}
                  />
               )
             },

             {
               tabButton: "Ανέβασμα κωδικών",
               tabIcon: BackupIcon,
               tabContent: (
                 <CodesUploadTab showSuccess={(msg) => handleShowSuccessMessage(msg)}
                  showError={(msg) => setAlert(<ErrorAlert message={msg} hideAlert={hideAlert}/>)}
                 />
               )
             },

           ]}
         />

       </Grid>
      </Grid>
      {alert}

      <Snackbar anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default AdminEduLabs
