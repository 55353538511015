import React from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';

import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Container from '@material-ui/core/Container';

import { checkNoErrors } from 'utils.js'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  inner_form: {
    align: 'left',
    margin: '20px'
  },
  fileinput: {
    display: 'none',
  },
  image: {
    height: 60,
    width: 90,
  }
}))

export default function ImagesForm(props) {
  const classes = useStyles();
  const { baseUrl, onSave } = props

  const [images, setImages] = React.useState([])
  const [imageFile, setImageFile] = React.useState(null)
  const [imageTitle, setImageTitle] = React.useState('')
  const [editingImgFilename, setEditingImgFilename] = React.useState('')
  const [editingImgIdx, setEditingImgIdx] = React.useState(-1)
  const [errors, setErrors] = React.useState({})

  React.useEffect(() => {
    //console.log(props.images)
    setImages(props.images)
  }, [props.images])

  const addOrEditImage = () => {
    let newErrors = Object.assign({}, errors)
    newErrors.file = !imageFile ? 'Υποχρεωτικό πεδίο' : ''
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      const newFile = {title: imageTitle || '', file: imageFile || '',
      filename: (imageFile instanceof File) ? imageFile.name : editingImgFilename}
      //console.log(newFile)

      let temp;
      if (editingImgIdx < 0){
        temp = [...images, newFile]
      } else {
        temp = [...images]
        temp[editingImgIdx] = newFile
      }
      setImages(temp)
      onSave(temp)

      resetImagesFields()
    }
  }

  const resetImagesFields = () => {
    setImageFile(null)
    setImageTitle('')
    setEditingImgFilename('')
    setEditingImgIdx(-1)
    setErrors({})
  }

  const prepareToEditImage = (image, idx) => {
    setImageTitle(image.title)
    setEditingImgFilename(image.filename)
    setImageFile(image.file)
    setEditingImgIdx(idx)
  }

  const removeImage = (idx) => {
    let temp = [...images]
    temp.splice(idx, 1)
    setImages(temp)
    onSave(temp)
  }

  const uploadImage = (e) => {
    const allowedExtensions = /(\.(jpg|jpeg|gif|png|bmp|tiff|pjp|jfif|svg|svgz|webp|ico|xbm|dib|tif|pjpeg|avif))$/i;
    let newErrors = Object.assign({}, errors)

    if (!allowedExtensions.exec(e.target.files[0].name)) {
      newErrors.file = "Πρέπει να ανεβάσετε αρχείο εικόνας"
      setImageFile(null)
    } else {
      setImageFile(e.target.files[0])
      newErrors.file = ''
    }

    setErrors(newErrors)
  }

  const handleTitleChange = (e) => {
    setImageTitle(e.target.value)
  }

  return(
    <>
      <FormLabel>Εικόνες</FormLabel>
      <Container maxWidth='xs' className={classes.inner_form}>
        <TextField
          label='Τίτλος'
          value={imageTitle}
          error={Boolean(errors.imageTitle)}
          onChange={handleTitleChange}
          style = {{width:500, paddingTop:10, marginTop:0}}
        />
        <FormHelperText error>{errors.imageTitle}</FormHelperText>

        <br/>
        <input
          accept="image/*"
          className={classes.fileinput}
          id="image-form-id"
          type="file"
          onChange={uploadImage}
        />
        <label htmlFor="image-form-id">
          <Button variant="contained" color="primary" component="span">
            Ανεβασμα εικονας
          </Button>
        </label>
        <FormHelperText error>{errors.file}</FormHelperText>
        <br/>
        {
          imageFile && !(imageFile instanceof File) && editingImgFilename
        }
        {imageFile && imageFile instanceof File && imageFile.name}
        <br/>

        <Button onClick={addOrEditImage} size='sm'>Προσθήκη</Button>
        <Button onClick={resetImagesFields} size='sm'>Καθαρισμος</Button>
      </Container>

      <List dense>
        {images && images.map((img, idx) =>
          <ListItem key={idx}>
            {img && !(img.file instanceof File) &&
              <img alt={img.title || ''} title={img.title || ''} src={baseUrl+img.filename} className={classes.image}/>}
            {img && (img.file instanceof File) && img.filename + (img.title && '('+ img.title + ')')}
            <IconButton aria-label="edit" onClick={() => prepareToEditImage(img, idx)}>
              <EditIcon fontSize="small"/>
            </IconButton>
            <IconButton aria-label="delete" onClick={() => removeImage(idx)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </ListItem>
        )}
      </List>
    </>
  )
}
