import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';

import Paper from '@material-ui/core/Paper';
import Button from "components/CustomButtons/Button.js";
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'
import { GenericConfirmMessage } from 'views/CustomAlerts/ConfirmationDialog.js'

import { getLabMainPage, getLabTypeName } from './utils.js'
import { truncateText, stripHTMLtags } from 'utils.js'
import { getAllLabComments, selectCommentsToShow } from 'clients/labEducationEtcClient.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  paper: {
    padding: 20
  },
}))

const EduLabSelectComments = (props) => {
  const classes = useStyles()

  const [lab, setLab] = React.useState({})
  const [selected, setSelected] = React.useState([])
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [saving, setSaving] = React.useState(false)

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - lab.comments.length) : 0;

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  const handleSelect = (e, id) => {
    let newSelected = [...selected]
    if (e.target.checked){
      newSelected.push(id)
    } else {
      const pos = newSelected.indexOf(id)
      if (pos >= 0){
        newSelected.splice(pos, 1)
      }
    }
    //console.log(newSelected)
    setSelected(newSelected)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const handleSubmit = () => {
    //console.log(selected)
    setSaving(true)

    if (selected.length > 0){
      performSubmit()
    } else {
      const msg = 'Έχετε επιλέξει να μην εμφανίζεται κανένα σχόλιο! Είστε σίγουρος/η ότι θέλετε να προχωρήσετε;'
      setAlert(<GenericConfirmMessage message={msg} onConfirm={performSubmit} onCancel={cancelSubmit}/>)
    }
  }

  const cancelSubmit = () => {
    hideAlert()
    setSaving(false)
  }

  const performSubmit = () => {
    const params = {lab_id: lab.id, comments: selected}
    //console.log(params)

    selectCommentsToShow(params,
      () => window.location.href = '/admin/EduLabFullView/' + lab.id,
      (err) => {
        setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
        setSaving(false)
      }
    )
  }

  React.useEffect(() => {
    if (props.match.params.lab_id){
      getAllLabComments(props.match.params.lab_id,
        (data) => {
          setLab(data.lab)
          if (data.lab.comments){
            setSelected(data.lab.comments.filter(c => c.show).map(c => c.id))
          }
        },
        (err) => console.log(err)//window.location.href = '/admin/LabNotFound'
      )
    }
  }, [props.match.params.lab_id])

  /*
  For collapsible ReactTable:
  https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/expanding?file=/src/App.js:1817-1847
  https://stackoverflow.com/questions/56675985/expand-subcomponent-rows-by-default-with-react-table
  */

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} >
          <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link color="inherit" href="/admin/SelectTransSem">
                  Main
                </Link>
                <Link color='inherit' href={getLabMainPage(lab.lab_type || '')}>
                  {getLabTypeName(lab.lab_type || '')}
                </Link>
                <Link color='inherit' href={"/admin/EduLabFullView/"+lab.id}>{lab.title || ''}</Link>
                <Typography color="textPrimary">Επιλογή σχολίων για προβολή</Typography>
            </Breadcrumbs>
          </div>
        </Grid>

       <Grid item xs={12} style={{ paddingTop : '20px'}}>
         <Container maxWidth="lg">
            <Paper className={classes.paper}>

             <TableContainer>
              <Table>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                     rows.slice().sort(getComparator(order, orderBy)) */}
                  {
                    stableSort(lab.comments, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = selected.includes(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <CollapsibleRow key={row.id} row={row} labelId={labelId}
                          isItemSelected={isItemSelected} handleSelect={handleSelect}/>
                      );
                    })
                  }
                  {
                    emptyRows > 0 &&
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={5} />
                      </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={lab.comments ? lab.comments.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage='Εγγραφές ανά σελίδα:'
            />

            <div align='center'>
              <Button color='primary' onClick={handleSubmit}
               disabled={saving || !lab.comments || lab.comments.length===0}>Καταχωριση</Button>
            </div>
          </Paper>
         </Container>
        </Grid>
      </Grid>
      {alert}
    </div>
  )
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  if (array){
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  } else {
    return []
  }
}

const headCells = [
  {
    id: 'id',
    label: 'ID',
    sortable: true,
  },
  {
    id: 'user_fullname',
    label: 'Χρήστης',
    sortable: true,
  },
  {
    id: 'text',
    label: 'Σχόλιο',
    sortable: false,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, onRequestSort } =
    props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan={5} align='left'>
          Επιλεγμένα σχόλια: {numSelected}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell padding="checkbox">
          Επιλογή
        </TableCell>
        <TableCell />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='right'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {
              headCell.sortable ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
              :
              <span>{headCell.label}</span>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CollapsibleRow = (props) => {
  const parse = require('html-react-parser');

  const { row, labelId, isItemSelected, handleSelect } = props
  const [open, setOpen] = React.useState(false);

  return(
    <>
      <TableRow
        key={row.id}
        hover
        onClick={(event) => handleSelect(event, row.id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right">{row.id}</TableCell>
        <TableCell align="right">{row.user_fullname}</TableCell>
        <TableCell align="right">{truncateText(stripHTMLtags(row.text), 40)}</TableCell>
      </TableRow>
      <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography variant="body1" gutterBottom component="div">
              {parse(row.text)}
            </Typography>
          </Box>
        </Collapse>
      </TableCell>
      </TableRow>
    </>
  )
}


export default EduLabSelectComments
