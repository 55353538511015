import {
  FETCH_SEARCH_REQUEST,
  FETCH_SEARCH_SUCCESS,
  FETCH_SEARCH_FAILURE,
  CHANGE_SEARCH_RESULTS_PAGE,
} from './types';
import {searchWithKeywordApi} from '../api';
import {apiErrorHandler} from '../utils/errorhandler';

export const fetchSearchWithKeyword = keyword => dispatch => {
  dispatch(fetchSearchWithKeywordRequest());

  searchWithKeywordApi(keyword,
    (response) => {
      //console.log(response)
      dispatch(fetchSearchWithKeywordSuccess(response.results));
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(fetchSearchWithKeywordFailure(errorMessage));
    });
};

export const fetchSearchWithKeywordRequest = () => {
  return {
    type: FETCH_SEARCH_REQUEST,
  };
};

export const fetchSearchWithKeywordSuccess = data => {
  //console.log(data)
  return {
    type: FETCH_SEARCH_SUCCESS,
    results: data,
  };
};

export const fetchSearchWithKeywordFailure = error => {
  return {
    type: FETCH_SEARCH_FAILURE,
    error,
  };
};

export const changeSearchResultsPage = page => {
  return {
    type: CHANGE_SEARCH_RESULTS_PAGE,
    page,
  }
}
