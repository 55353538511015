import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import Grid from '@material-ui/core/Grid';

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import { getAllWorksWithBooks } from "clients/worksClient.js";
import { sortWorksAndBooks } from 'sorting.js'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({




   control: {
     padding: theme.spacing(2),
   },



 paper: {
   padding: theme.spacing(5),
   margin: '15px',
   maxWidth: 800,
  // height: 300,
   width: 600,
 },
 image: {
   width: 128,
   height: 128,
 },
 img: {
   margin: 'auto',
   display: 'block',
   maxWidth: '100%',
   maxHeight: '100%',
 },


  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  TreeGrid: {
     width: 600,

  }

}));

export default function BookSelectionTree() {

  const classes = useStyles();
  //const theme = useTheme();

  const spacing = 2

  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
    //console.log(nodeIds)
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
    //console.log(nodeIds);

    if (nodeIds.indexOf('-') >= 0){
      //only select if it is a leaf node (book)
      window.location.href = "./SelectLyrics/" + nodeIds;
    }
  };

  const [works, setWorks] = React.useState([]);

  React.useEffect(()=>{
    getAllWorksWithBooks('',
      (data) => setWorks(sortWorksAndBooks(data.works)),
      (err) => console.log(err)
    );
  }, [])


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

    <Grid container className={classes.root} spacing={2}>
    <Grid item xs={12} >
      <div>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/admin/SelectTransSem">
            Main
          </Link>

          <Link color="inherit" href="/admin/bookSelection">
            Αριστοτελικά έργα, Μεταφράσεις, Σχόλια
          </Link>
          <Typography color="textPrimary">Επιλογή Έργου-Βιβλίου</Typography>
      </Breadcrumbs>
      </div>
    </Grid>


  <Grid item xs={12} style={{ paddingTop : '20px'}} >
       <Grid container justify="center" spacing={spacing}>

           <Grid >
               <Grid container spacing={10} className={classes.TreeGrid}>

                 <Grid item xs={12} sm container>
                   <Grid item xs container direction="column" spacing={2}>
                     <Grid item xs>

                     <Card>
                       <CardHeader className="AristosCardHeader">
                          Επιλογή Έργου-Βιβλίου
                       </CardHeader>
                       <CardBody>
                      <GridContainer  spacing={10}>
                          <GridItem xs={12} sm={12} md={6}>


                          <TreeView  classes={{ root: 'outbox' }}

                             defaultCollapseIcon={<ExpandMoreIcon />}
                             defaultExpandIcon={<ChevronRightIcon />}
                             expanded={expanded}
                             selected={selected}
                             onNodeToggle={handleToggle}
                             onNodeSelect={handleSelect}
                           >
                           {works.map((work) => {
                             return (
                               <TreeItem key={work.id} classes={{ label: 'pateras' }} nodeId={work.id.toString()} label={work.name}>
                                 {work.books && work.books.map((book) => {
                                   const book_id = work.id + '-' + book.id
                                   return (
                                     <TreeItem key={book_id} classes={{ label: 'paidi' }}  nodeId={book_id} label={book.name}/>
                                   )
                                 })}
                               </TreeItem>
                             )
                           })}

                           </TreeView>
                           </GridItem>
                       </GridContainer>
                       </CardBody>
                     </Card>



                     </Grid>

                   </Grid>

                 </Grid>
               </Grid>
           </Grid>
       </Grid>
     </Grid>
    </Grid>





    </div>
  );
}
