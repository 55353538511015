import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Assignment from "@material-ui/icons/Assignment";
import ReactTable from "components/ReactTable/ReactTable.js";

import Button from "components/CustomButtons/Button.js";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import ListSubheader from '@material-ui/core/ListSubheader';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { getAllLabs, getLabSubscribers, changeSubscriptionStatus } from "clients/labEducationEtcClient.js"
import { checkNoErrors } from 'utils.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const useStyles = makeStyles((theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardFix: {
      fontSize: "1em!important",
  },
}))

const statusOptions = [{id: 0, name: 'Εκκρεμεί'}, {id:1, name: 'Εγκεκριμένη'}, {id: 2, name: 'Ακυρωμένη'}]

const EduLabsAttendantsTab = (props) => {
  const classes = useStyles()
  const { showError, showSuccess } = props

  const [education_labs, setEducation_labs] = React.useState([])
  const [tour_labs, setTour_labs] = React.useState([])
  const [summer_schools, setSummer_schools] = React.useState([])
  const [attendants, setAttendants] = React.useState([])
  const [maxAttendants, setMaxAttendants] = React.useState(0)

  const [lab_id, setLab_id] = React.useState('')
  const [selected, setSelected] = React.useState([])
  const [newstatus, setNewstatus] = React.useState('')
  const [errors, setErrors] = React.useState({})
  const [saving, setSaving] = React.useState(false)

  //fields changes
  const handleLabChange = (e) => {
    const newVal = (e.target.value===undefined) ? '' : e.target.value
    /*let newErrors = Object.assign({}, errors)
    newErrors.lab_id = validate('lab_id', newVal)
    setErrors(newErrors)*/

    setLab_id(newVal)
    getAttendantsForLab(newVal)
    setSelected([])
    setErrors({})
    setNewstatus('')
  }

  const handleSelect = (e, id) => {
    let newSelected = [...selected]
    if (e.target.checked){
      newSelected.push(id)
    } else {
      const pos = newSelected.indexOf(id)
      if (pos >= 0){
        newSelected.splice(pos, 1)
      }
    }
    //console.log(newSelected)
    setSelected(newSelected)
  }

  const handleStatusChange = (e) => {
    const newVal = (e.target.value===undefined) ? '' : e.target.value
    let newErrors = Object.assign({}, errors)
    newErrors.newstatus = validate('newstatus', newVal)
    setErrors(newErrors)

    setNewstatus(newVal)
  }

  const validate = (name, value) => {
    switch (name) {
      case 'lab_id':
      case 'newstatus':
        return (value==='') ? 'Υποχρεωτικό πεδίο' : ''

      case 'selected':
        return (value.length===0) ? 'Υποχρεωτική επιλογή αιτήσεων' : ''

      default:
        return ''
    }
  }

  const handleSubmit = () => {
    let newErrors = Object.assign({}, errors)
    newErrors.lab_id = validate('lab_id', lab_id)
    newErrors.selected = validate('selected', selected)
    newErrors.newstatus = validate('newstatus', newstatus)
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      setSaving(true)
      const params = {lab_id: lab_id, status: newstatus, users: selected}
      //console.log(params)

      changeSubscriptionStatus(params,
        () =>  {
          showSuccess('Η καταχώριση έγινε με επιτυχία')
          setSaving(false)

          //update attendants list
          let newAttendants = [...attendants]
          for (let key in newAttendants) {
            if (selected.includes(newAttendants[key].user_id)){
              newAttendants[key].status = newstatus
            }
          }
          setAttendants(newAttendants)
        },
        (err) => {
          showError(err.message)
          setSaving(false)
        }
      )
    }
  }

  const getAttendantsForLab = (id) => {
    if (id===''){
      setAttendants([])
    } else {
      getLabSubscribers(id, '',
        (data) => {
          setAttendants(data.attendants)
          setMaxAttendants(data.maxAttendants)
        },
        (err) => {
          setAttendants([])
          console.log(err)
        }
      )
    }
  }

  const constructTableRow = (person) => {
    return {
      user_id: person.user_id,
      firstname: person.firstname,
      lastname: person.lastname,
      contactWay: (person.contactWay==1) ? 'Τηλέφωνο' : 'Email',
      contactDetails: person.contactDetails,
      purchaseCode: person.purchaseCode,
      status: statusTxt(person.status),
      select: <Checkbox size='small' checked={selected.includes(person.user_id)} onChange={(e) => handleSelect(e, person.user_id)} />
    }
  }

  const statusTxt = (status) => {
    switch (status) {
      case 0:
        return 'Εκκρεμεί'

      case 1:
        return 'Εγκεκριμένη'

      case 2:
        return 'Ακυρωμένη'

      default:
        return ''
    }
  }

  //from https://react-table.tanstack.com/docs/examples/filtering
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Render a multi-select box
    return (
      <Select
        style={{ minWidth: 100}}
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        displayEmpty
        renderValue={(selected) => {
          if (filterValue === undefined) {
             return <em>Επιλογή</em>;
           } else {
             return filterValue
           }
        }}
      >
        <MenuItem value="">Όλες</MenuItem>
        {statusOptions.map((option, i) => (
          <MenuItem key={i} value={option.name}>{option.name}</MenuItem>
        ))}
      </Select>
    )
  }


  React.useEffect(() => {
    getAllLabs('?visible=true',
      (data) => {
        setEducation_labs(data.education_labs)
        setTour_labs(data.tour_labs)
        setSummer_schools(data.summer_schools)
      },
      (err) => console.log(err)
    )
  }, [])

  return(
     <Container maxWidth='lg'>

       <Card className={classes.cardFix}>
         <CardHeader className="AristosCardHeader" icon>
           <CardIcon color="primary">
             <Assignment />
           </CardIcon>
           <h4>Εγγραφές εργαστηρίων</h4>
         </CardHeader>

         <CardBody>
           <FormControl sx={{ m: 1, minWidth: 200 }} error={Boolean(errors.lab_id)}>
             <InputLabel id="lab-select">Επιλογή εργαστηρίου</InputLabel>
             <Select
               style={{ marginTop : '10px', marginBottom:'20px', minWidth: 200}}
               value={lab_id}
               onChange={handleLabChange}
               label="Επιλογή εργαστηρίου"
               labelId="lab-select"
             >
               {
                 access_rights && access_rights.labs_edu.w &&
                  <ListSubheader>Εκπαιδευτικά-περιηγητικά εργαστήρια</ListSubheader>
                }
                {
                  education_labs.map(lab =>
                    <MenuItem key={lab.id} value={lab.id}>{lab.title}</MenuItem>
                  )
                }
               {
                 /*access_rights && access_rights.labs_tour.w &&
                  <ListSubheader>Περιηγητικά εργαστήρια</ListSubheader>
                }
                {
                  tour_labs.map(lab =>
                    <MenuItem key={lab.id} value={lab.id}>{lab.title}</MenuItem>
                  )
                }
                {
                 access_rights && access_rights.labs_summer.w &&
                  <ListSubheader>Summer schools</ListSubheader>
                }
                {
                  summer_schools.map(lab =>
                    <MenuItem key={lab.id} value={lab.id}>{lab.title}</MenuItem>
                  )*/
                }
              </Select>
             <FormHelperText>{errors.lab_id}</FormHelperText>
           </FormControl>

           {
             lab_id &&
             <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography variant='body1'>Μέγιστος αριθμός συμμετεχόντων: {maxAttendants}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant='body1'>Αρ. εκκρεμών/εγκεκριμένων αιτήσεων: {attendants.filter(a => (a.status==0 || a.status==1)).length}</Typography>
              </Grid>
             </Grid>
           }

           <ReactTable
             columns={[
               {
                 Header: "Όνομα",
                 accessor: "firstname"
               },
               {
                 Header: "Επώνυμο",
                 accessor: "lastname"
               },
               {
                 Header: "Επικοινωνία",
                 accessor: "contactDetails"
               },
               {
                 Header: 'Κωδικός πληρωμής',
                 accessor: "purchaseCode"
               },
               {
                 Header: 'Κατάσταση αίτησης',
                 accessor: 'status',
                 Filter: SelectColumnFilter,
               },
               //https://demos.creative-tim.com/material-dashboard-pro-react/#/documentation/react-table
               //last column is by default non-filterable/sortable. If they want to move select column, check how to change this
               {
                 Header: 'Επιλογή',
                 accessor: "select",
               },
             ]}
             data={attendants.map(att => constructTableRow(att))}
           />
           <FormHelperText error>{errors.selected}</FormHelperText>

           {
             lab_id &&
             <div align='center'>
               <InputLabel id="status-select">Νέα Κατάσταση</InputLabel>
               <Select
                 style={{ marginTop : '10px', marginBottom:'20px', minWidth: 200}}
                 value={newstatus}
                 onChange={handleStatusChange}
                 label="Επιλογή νέας κατάστασης"
                 labelId="status-select"
               >
                <MenuItem value=''></MenuItem>
                {
                  statusOptions.map(option =>
                    <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                  )
                }
               </Select>
               <FormHelperText style={{textAlign: 'center'}} error>{errors.newstatus}</FormHelperText>
               <br/>

               <Button color='primary' disabled={selected.length===0 || saving} onClick={handleSubmit}>Καταχωριση</Button>
              </div>
            }
         </CardBody>
       </Card>

     </Container>
  )
}

export default EduLabsAttendantsTab
