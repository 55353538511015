import React, { useState,useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";

import avatar from "assets/img/faces/marc.jpg";

import {getAllCategories, addCategory} from "clients/categoryClients"

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);




export default function AddCatErg() {
  const classes = useStyles();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [categoryValue, setCategoryValue] = useState("");

  useEffect(() => {
    getAllCategories(
      (ret) => {
        setIsLoaded(true);
        setTableData(ret);
      },
      (err) => {
        setIsLoaded(true);
        setError(err);
      }
    )}, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  }
  else{

  return (



    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Δημιουργία κατηγορίας έργου</h4>
              <p className={classes.cardCategoryWhite}>Εισαγωγή ονόματος κατηγορίας έργου</p>
            </CardHeader>
            <CardBody>

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Κατηγορία έργου"
                    id="first-name"

                  /*  inputProps={{
                                value : categoryValue,
                                onChange : {e => setCategoryValue(e.target.value)}
                    }}*/

                    inputProps={{
                      //defaultValue: "your-default-value",
                      type: "text",
                      value : categoryValue,
                      onChange : (e) => {setCategoryValue(e.target.value)}
                    }}

                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>

              </GridContainer>


            </CardBody>
            <CardFooter>

            <Button color="primary" onClick={
              () => {
                addCategory({"title": categoryValue},
                (id) => setTableData([...tableData, [categoryValue, "0", "0"]]),
                (err) => console.log(err)
              )


            }
          }

            >Εισαγωγή {tableData.length}</Button>
            </CardFooter>
          </Card>

        </GridItem>
      {/*
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
              <h4 className={classes.cardTitle}>Alec Thompson</h4>
              <p className={classes.description}>
                Don{"'"}t be scared of the truth because we need to restart the
                human foundation in truth And I love you like Kanye loves Kanye
                I love Rick Owens’ bed design but the back is...
              </p>
              <Button color="primary" round>
                Follow
              </Button>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={5}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Κατηγορίες έργων</h4>
              <p className={classes.cardCategoryWhite}>
                Κατηγορίες Αριστοτελικών έργων
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Κατηγορία", "Έργα", "Βιβλία"]}
                tableData={tableData}
              />
            </CardBody>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
}
