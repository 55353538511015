import React from 'react'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import CustomInput from "components/CustomInput/CustomInput.js";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Pagination from '@material-ui/lab/Pagination';
import adminIcon from "images/administrator.svg";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import { replace_letters_with_diacritics_regex } from 'utils.js'
import { makeStyles } from '@material-ui/core/styles';
import get_rights_from_session from 'rights_from_session.js'

const useStyles = makeStyles((theme) => ({
  selected_letter: {
    fontWeight: 700,
  },
  autocompl: {
    maxWidth: 500,
  }
}))

//access rights
const access_rights = get_rights_from_session()

const AllEntriesDisplay = (props) => {
  const classes = useStyles()
  const { entries, categories, keywordToFilterBy } = props
  const [filteredEntries, setFilteredEntries] = React.useState([])

  const [letter, setLetter] = React.useState('')
  const [kword, setKword] = React.useState('')
  const [alphabet] = React.useState(['Α', 'Β', 'Γ', 'Δ', 'Ε', 'Ζ', 'Η', 'Θ', 'Ι', 'Κ', 'Λ', 'Μ', 'Ν', 'Ξ',
    'Ο', 'Π', 'Ρ', 'Σ', 'Τ', 'Υ', 'Φ', 'Χ', 'Ψ', 'Ω'])

  const [page, setPage] = React.useState(1);
  const pageSize = 25;
  const pageStart = (page - 1) * pageSize
  const pageEnd = pageStart + pageSize

  const [category, setCategory] = React.useState(0)
  const [searchVal, setSearchVal] = React.useState('')

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchValChange = (e) => {
    setSearchVal(e.target.value)
    setLetter('')
    search(category, e.target.value, kword)
  }

  const handleKwordChange = (e) => {
    setKword(e.target.value)
    search(category, searchVal || letter, e.target.value)
  }

  const filterByLetter = (a) => {
    search(category, a, kword);
    setLetter(a)
    setSearchVal('')
  }

  const filterByCategory = (c) => {
    search(c, letter, kword)
    setCategory(c)
  }

  const search = (categoryToFilterBy, titleStartsWith, keyword) => {
    const re = new RegExp('^' + titleStartsWith, "i");
    //console.log(re)
    const tempFiltered = entries.filter((entry) =>
      entry.plain_title.match(re) && categoryMatches(entry.category, categoryToFilterBy) &&
          ((keyword===null || keyword==='') ? true : entry.keywords && keywordMatch(entry.keywords, keyword))
    )
    setFilteredEntries(tempFiltered)
    setPage(1)
  }

  const clearFilters = () => {
    setLetter('')
    setCategory(0)
    setKword('')
    setSearchVal('')
    search(0, '', '')
  }

  const categoryMatches = (entryCategory, categoryToFilterBy) => {
    //console.log(entryCategory)
    switch (categoryToFilterBy) {
      case 0:
        return true;

      case '':
        return !entryCategory;

      default:
        return entryCategory && entryCategory === categoryToFilterBy;
    }
  }

  const keywordMatch = (entryKeywords, keyword) => {
    //console.log('kw='+keyword)
    //console.log('ekw='+entryKeywords)
    const re = new RegExp('^' + replace_letters_with_diacritics_regex(keyword), "i");
    const results = entryKeywords.filter(kw => kw.match(re))

    return (results && results.length > 0)
  }

  React.useEffect(() => {
    setFilteredEntries(entries)
    //console.log('f='+keywordToFilterBy)
    if (keywordToFilterBy && keywordToFilterBy!==''){
      //console.log('filter')
      setKword(keywordToFilterBy)
      search(category, searchVal || letter, keywordToFilterBy)
    }
  }, [entries, keywordToFilterBy])

  return(
    <div>
      <Link onClick={() => clearFilters()} href='#' className={filteredEntries.length===entries.length ? classes.selected_letter : ''}>
        Όλα τα λήμματα
      </Link>
      <div id="lexicon_alpha">
        {alphabet.map((a) =>
          <Link key={a} onClick={() => filterByLetter(a)} href='#'>
            <span className={(a===letter) ? classes.selected_letter : ''}>{a}</span>&nbsp;|&nbsp;
          </Link>
        )}
      </div>
      <div>
        {
          categories.map((c, idx) =>
            <Link key={idx} onClick={() => filterByCategory(c.title)} href='#'>
              <span className={(c.title===category) ? classes.selected_letter : ''}>{c.title}</span>&nbsp;|&nbsp;
            </Link>
          )
        }
        {
          access_rights.lexicon && access_rights.lexicon.w &&
            <IconButton onClick={() => window.location.href='/admin/LexiconCats/'}>
              <img style={{width:20, height:20}} src={adminIcon} alt="..." />
            </IconButton>
        }
      </div>

      <GridContainer>
        <GridItem xs={12} sm={3}>
          <CustomInput
             labelText="Αναζήτηση λήμματος"
             id="searchLines"
             formControlProps={{
               fullWidth: false
             }}
             inputProps={{
               value: searchVal,
               onChange: handleSearchValChange
             }}
          />
        </GridItem>
        <GridItem xs={12} sm={9}>
          <CustomInput
             labelText="Αναζήτηση με λέξη-κλειδί"
             id="searchKeyword"
             formControlProps={{
               fullWidth: false
             }}
             inputProps={{
               value: kword,
               onChange: handleKwordChange
             }}
          />
        </GridItem>
      </GridContainer>

      <List id="lexicon_list">
      {filteredEntries.slice(pageStart, pageEnd).map((e) =>
        <ListItem key={e.lemma_id}>
          {
            e.is_link ?
              <>
                {e.title}: βλ.<Link color="primary" href={"/admin/Lexicon/"+e.link_to.lemma_id}>{e.link_to.title}</Link>
                {
                  access_rights.lexicon && access_rights.lexicon.w &&
                    <IconButton onClick={() => window.location.href='/admin/AddLexiconEntry/'+e.lemma_id} size='small'>
                      <EditIcon fontSize='small'/>
                    </IconButton>
                }
              </>
            :
            <Link className='archaic_text' color="primary" href={"/admin/Lexicon/"+e.lemma_id}>{e.title}</Link>
          }
        </ListItem>
      )}
      </List>
      <Pagination count={Math.ceil(filteredEntries.length/pageSize)} color="primary" page={page} style={{marginTop:"0px"}} onChange={handlePageChange}/>
    </div>
  )
}

export default AllEntriesDisplay
