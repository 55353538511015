import React from 'react';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Assignment from "@material-ui/icons/Assignment";
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import { RoleMemberActions } from "./AdminUsersGroupsButtonsActions.js"
import { AddRolesMemberDialog, EditRoleMemberDialog, ViewRoleRightsDialog } from "./AdminUsersGroupsDialogForms.js"
import { getUsersByRole, getOneUser } from "clients/usersClient.js"
import { addUsersToRole, editUserRole, delUsersFromRole } from "clients/rolesClient.js"
import { useAsyncReference } from "utils.js"

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },

  LabelCol: {
    color:"#3f51b5",
    fontweight: "bold"
  },

  cardFix: {
      fontSize: "1em!important",
  },
}))

const RolesMembersTab = (props) => {

  const handleClickOpenRoleMember = () => {
    if (roleToView===0){
      //setAlert(<WarningAlert message='Επιλέξτε πρώτα ομάδα' hideAlert={hideAlert}/>)
      showWarningAlert('Επιλέξτε πρώτα ρόλο')
    } else {
      setDisableRoleMemberSubmit(false)
      getUsersByRole(roleToView, false,
        (data) => {
          setUsersData(data.users_all)
          setOpenRoleMember(true);
        },
        (err) => showErrorAlert(err.message)
      )
    }
  };

  const handleCloseRoleMember = () => {
    setOpenRoleMember(false);
    setChecked([])
    setWorksForRole([])
    setLabsForRole([])
    setForumsForRole([])
  };

  const handleAddMemberToRole = () => {
    if (validateAddMemberToRole()){
      //console.log('adding to role')
      setDisableRoleMemberSubmit(true)

      //create params
      let params = {user_ids: checked, role_id: roleToView}
      if (objRoleToView.define_works){
        params.work_ids = worksForRole
      }
      if (objRoleToView.define_labs){
        getLabsParams(params)
      }
      if (objRoleToView.define_forums){
        params.forum_ids = forumsForRole
      }
      //console.log(params)

      addUsersToRole(params,
        () => {
          let newData = RoleMembersdata.current;
          const newUsers = Usersdata.filter((user) => (checked.indexOf(user.user_id) >= 0))
          //console.log(newUsers)
          newUsers.forEach((user) => newData.push(constructRoleMemberdataRow(user)))
          //console.log(newData)
          setRoleMembersdata(newData);

          handleCloseRoleMember()
          setDisableRoleMemberSubmit(false)
          handleShowSuccessMessage('H καταχώριση έγινε με επιτυχία!')
        },
        (err) => {
          showErrorAlert(err.message)
          setDisableRoleMemberSubmit(false)
        }
      )
    }
  }

  const getLabsParams = (params) => {
    let labs_tr = []
    //let labs_edu = []
    //console.log(labsForRole)
    labsForRole.forEach(lab => {
      //console.log(lab)
      if (lab.startsWith('tr_')){
        labs_tr.push(parseInt(lab.substring(3)))
      }/* else if (lab.startsWith('edu_')){
        labs_edu.push(parseInt(lab.substring(4)))
      }*/
    })
    params.labs_tr = labs_tr
    //params.labs_edu = labs_edu
  }

  const validateAddMemberToRole = () => {
    if (roleToView===0){
      showErrorAlert('Δεν έχετε επιλέξει Ρόλο!');
      return false;
    }

    if (checked.length===0){
      showErrorAlert('Δεν έχετε επιλέξει χρήστες!');
      return false;
    }

    let err = validateWorks(worksForRole)
    setErrorWork(err)
    if (err.length!==0){
      return false;
    }

    err = validateLabs(labsForRole)
    setErrorLabs(err)
    if (err.length!==0){
      return false;
    }

    err = validateForums(forumsForRole)
    setErrorForums(err)
    if (err.length!==0){
      return false;
    }

    return true;
  }

  const validateWorks = (value) => {
    return objRoleToView.define_works && (value.length===0) ? 'Υποχρεωτικό πεδίο' : ''
  }

  const validateLabs = (value) => {
    return objRoleToView.define_labs && (value.length===0) ? 'Υποχρεωτικό πεδίο' : ''
  }

  const validateForums = (value) => {
    return objRoleToView.define_forums && (value.length===0) ? 'Υποχρεωτικό πεδίο' : ''
  }

  const handleClickOpenEditRoleMember = (user_id) => {
    if (roleToView===0){
      //setAlert(<WarningAlert message='Επιλέξτε πρώτα ομάδα' hideAlert={hideAlert}/>)
      showWarningAlert('Επιλέξτε πρώτα ρόλο')
    } else {
      getOneUser(user_id,
        (data) => {
          //console.log(data.user)
          setDisableRoleMemberSubmit(false)
          setOpenEditRoleMember(true)
          setUserToEdit(user_id)
          getUserRoleDetails(data.user.user_roles, roleToView)
        },
        (err) => showErrorAlert(err.message)
      )
    }
  };

  const getUserRoleDetails = (user_roles, selectedRole) => {
    if (user_roles) {
      const selRoleArr = user_roles.filter((ur) => {
        return ur.id == selectedRole
      });

      if (selRoleArr && selRoleArr[0]) {
        const selRole = selRoleArr[0]
        if (selRole.works) {
          setWorksForRole(selRole.works)
        }
        let labs = [];
        if (selRole.labs_tr) {
          selRole.labs_tr.forEach((lab) => labs.push("tr_" + lab));
        }
        /*if (selRole.labs_edu) {
          selRole.labs_edu.forEach((lab) => labs.push("edu_" + lab));
        }*/
        setLabsForRole(labs)
        if (selRole.forums){
          setForumsForRole(selRole.forums)
        }
      }
    }
  }

  const handleCloseEditRoleMember = () => {
    setOpenEditRoleMember(false);
    setWorksForRole([])
    setLabsForRole([])
    setForumsForRole([])
  };

  const handleEditMemberRole = () => {
    if (validateEditMemberRole()){
      //console.log('adding to role')
      setDisableRoleMemberSubmit(true)

      //create params
      let params = {role_id: roleToView, user_id: userToEdit}
      if (objRoleToView.define_works){
        params.work_ids = worksForRole
      }
      if (objRoleToView.define_labs){
        getLabsParams(params)
      }
      if (objRoleToView.define_forums){
        params.forum_ids = forumsForRole
      }
      //console.log(params)

      editUserRole(params,
        () => {
          handleCloseEditRoleMember()
          setDisableRoleMemberSubmit(false)
          handleShowSuccessMessage('H καταχώριση έγινε με επιτυχία!')
        },
        (err) => {
          showErrorAlert(err.message)
          setDisableRoleMemberSubmit(false)
        }
      )
    }
  }

  const validateEditMemberRole = () => {
    if (roleToView===0){
      showErrorAlert('Δεν έχετε επιλέξει Ρόλο!');
      return false;
    }

    if (userToEdit===0){
      showErrorAlert('Δεν έχετε επιλέξει χρήστη!');
      return false;
    }

    let err = validateWorks(worksForRole)
    setErrorWork(err)
    if (err.length!==0){
      return false;
    }

    err = validateLabs(labsForRole)
    setErrorLabs(err)
    if (err.length!==0){
      return false;
    }

    err = validateForums(forumsForRole)
    setErrorForums(err)
    if (err.length!==0){
      return false;
    }

    return true;
  }

  const handleDeleteMemberFromRole = (id) => {
    const params = {user_id: id, role_id: roleToView}
    //console.log(params)

    delUsersFromRole(params,
      () => {
        let newData = RoleMembersdata.current;
        const pos = newData.findIndex(o => o.id === id);
        //console.log(pos+' '+id)
        if (pos >= 0){
          newData.splice(pos, 1)
          //console.log(newData)
          setRoleMembersdata([...newData]);
        }
        handleShowSuccessMessage('H διαγραφή από την ομάδα έγινε με επιτυχία!')
      },
      (err) => {
        showErrorAlert(err.message)
        setDisableRoleMemberSubmit(false)
      }
    )
  }

  const handleRoleToViewChange = (e) => {
    setRoleToView(e.target.value)

    const role = Rolesdata[e.target.value];
    return (role !== undefined) ? setObjRoleToView(role) : setObjRoleToView({})
  }

  const handlePreviewRole = (boolView) => {
    setOpenRolePreview(boolView)
  }

  const constructRoleMemberdataRow = (user) => {
    return {
      id: user.user_id,
      firstname: user.firstname,
      lastname: user.lastname,
      username: user.username,
      //phone: user.phone,
      active: user.active,
      activeTxt: user.active ? 'ΝΑΙ' : 'ΌΧΙ',
      actions: (
        <RoleMemberActions roleMember={user}
          handleClickEdit={(id) => handleClickOpenEditRoleMember(id)}
          showEdit={objRoleToView && (objRoleToView.define_works || objRoleToView.define_labs || objRoleToView.define_forums)}
           handleClickDelete={(id) => showConfirmDelete(id, handleDeleteMemberFromRole)}
           />
      )
    };
  }

  const loadRoleMembers = () => {
    if (roleToView > 0){
      getUsersByRole(roleToView, true,
        (data) => {
          setRoleMembersdata(data.users_all.map((user) => constructRoleMemberdataRow(user)))
        },
        (err) => console.log(err)
      )
    } else {
      setRoleMembersdata([])
    }
  }

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    let newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    //console.log(newChecked);
  };

  const handleChangeWork = (e)  => {
    setWorksForRole(e.target.value)
    setErrorWork(validateWorks(e.target.value))
  }

  const handleChangeLabs = (e) => {
    setLabsForRole(e.target.value)
    setErrorLabs(validateLabs(e.target.value))
  }

  const handleChangeForums = (e) => {
    setForumsForRole(e.target.value)
    setErrorForums(validateForums(e.target.value))
  }


  const { Rolesdata, Worksdata, labsdata, forumsdata,
    handleShowSuccessMessage, showWarningAlert, showErrorAlert, showConfirmDelete } = props

  const [RoleMembersdata, setRoleMembersdata] = useAsyncReference([])
  const [roleToView, setRoleToView] = React.useState(0)
  const [objRoleToView, setObjRoleToView] = React.useState({})
  const [disableRoleMemberSubmit, setDisableRoleMemberSubmit] = React.useState(false)
  const [openRoleMember, setOpenRoleMember] = React.useState(false);
  const [openEditRoleMember, setOpenEditRoleMember] = React.useState(false)
  const [openRolePreview, setOpenRolePreview] = React.useState(false)
  const [checked, setChecked] = React.useState([]);
  const [worksForRole, setWorksForRole] = React.useState([])
  const [errorWork, setErrorWork] = React.useState('')
  const [labsForRole, setLabsForRole] = React.useState([])
  const [errorLabs, setErrorLabs] = React.useState('')
  const [forumsForRole, setForumsForRole] = React.useState([])
  const [errorForums, setErrorForums] = React.useState('')
  const [userToEdit, setUserToEdit] = React.useState(0)
  const [Usersdata, setUsersData] = React.useState([])

  const classes = useStyles();

  React.useEffect(() => {
    //used to update role memebers every time a new role is selected
    //console.log('getting members')
    loadRoleMembers()
  }, [roleToView])

  return (
    <>
      <Card className={classes.cardFix}>
        <CardHeader className="AristosCardHeader" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4>Ανάθεση ρόλων σε χρήστες</h4>
        </CardHeader>
        <CardBody>

        <h4 className={classes.LabelCol}>Χρήστες με ρόλο</h4>

           <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
                style={{ marginTop : '20px'}}
              >
                Επιλογή Ρόλου
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}

                value={roleToView}
                onChange={handleRoleToViewChange}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}

                style={{ marginTop : '10px', marginBottom:'20px', width:'250px',}}

              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                  value='0'
                >
                  Επιλογή ρόλου
                </MenuItem>
                {
                  Object.keys(Rolesdata).map((roleId, idx) =>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      key={roleId}
                      value={roleId}
                    >
                      {Rolesdata[roleId].name}
                    </MenuItem>
                  )
                }
              </Select>

              <Tooltip title='Επισκόπηση δικαιωμάτων ρόλου'>
                <span>
                  <IconButton onClick={() => handlePreviewRole(true)} disabled={roleToView<=0}><InfoOutlinedIcon/></IconButton>
                </span>
              </Tooltip>

              <ReactTable
                columns={[
                  {
                    Header: "Username",
                    accessor: "username"
                  },
                  {
                    Header: "Όνομα",
                    accessor: "firstname"
                  },
                  {
                    Header: "Επώνυμο",
                    accessor: "lastname"
                  },
                  {
                    Header: "Ενεργός/ή",
                    accessor: "activeTxt"
                  },
                  {
                    Header: "Ενέργειες",
                    accessor: "actions"
                  }
                ]}
                data={RoleMembersdata.current}
              />
          <Button className={classes.marginRight} onClick={handleClickOpenRoleMember}
            disabled={roleToView<=0}>Προσθήκη Χρηστών σε Ρόλο</Button>
        </CardBody>
      </Card>

      <AddRolesMemberDialog
        handleAdd={handleAddMemberToRole} handleClose={handleCloseRoleMember} open={openRoleMember}
        Usersdata={Usersdata} checked={checked} handleToggle={handleToggle}
        disableButton={disableRoleMemberSubmit}
        define_works={objRoleToView.define_works} Worksdata={Worksdata}
        handleChangeWork={handleChangeWork} worksForRole={worksForRole} errorWork={errorWork}
        define_labs={objRoleToView.define_labs} labsdata={labsdata}
        handleChangeLabs={handleChangeLabs} labsForRole={labsForRole} errorLabs={errorLabs}
        define_forums={objRoleToView.define_forums} forumsdata={forumsdata}
        handleChangeForums={handleChangeForums} forumsForRole={forumsForRole} errorForums={errorForums}
        />

      <EditRoleMemberDialog
        handleEdit={handleEditMemberRole} handleClose={handleCloseEditRoleMember}
        open={openEditRoleMember} disableButton={disableRoleMemberSubmit}
        define_works={objRoleToView.define_works} Worksdata={Worksdata}
        handleChangeWork={handleChangeWork} worksForRole={worksForRole} errorWork={errorWork}
        define_labs={objRoleToView.define_labs} labsdata={labsdata}
        handleChangeLabs={handleChangeLabs} labsForRole={labsForRole} errorLabs={errorLabs}
        define_forums={objRoleToView.define_forums} forumsdata={forumsdata}
        handleChangeForums={handleChangeForums} forumsForRole={forumsForRole} errorForums={errorForums}
        />

      <ViewRoleRightsDialog
        handleClose={() => handlePreviewRole(false)} open={openRolePreview} role={objRoleToView}/>

    </>
  )
}

export default RolesMembersTab
