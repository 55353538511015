import React from 'react';

import { mappings_small_letters, mappings_for_simple_accents } from 'variables/letter_mappings.js'

//https://css-tricks.com/dealing-with-stale-props-and-states-in-reacts-functional-components/
function useAsyncReference(value, isProp = false) {
  const ref = React.useRef(value);
  const [, forceRender] = React.useState(false);

  function updateState(newState) {
    /*console.log(newState)
    console.log(ref.current)*/
    if (!Object.is(ref.current, newState)) {
      ref.current = newState;
      forceRender(s => !s);
    }
  }

  if (isProp) {
    ref.current = value;
    return ref;
  }

  return [ref, updateState];
}

function checkNoErrors(errors){
  const errMessages = Object.keys(errors).filter((k) => errors[k]);

  return (errMessages.length===0)
}

const getCaretPosition = (ctrl) => {
  //https://www.vishalon.net/blog/javascript-getting-and-setting-caret-position-in-textarea/
  if (document.selection) {
      ctrl.focus();
      var range = document.selection.createRange();
      var rangelen = range.text.length;
      range.moveStart('character', -ctrl.value.length);
      var start = range.text.length - rangelen;
      return {
          'start': start,
          'end': start + rangelen
      };
  } // IE >=9 and other browsers
  // eslint-disable-next-line
  else if (ctrl.selectionStart || ctrl.selectionStart == '0') {
      return {
          'start': ctrl.selectionStart,
          'end': ctrl.selectionEnd
      };
  } else {
      return {
          'start': -1,
          'end': -1
      };
  }
}

function replace_letters_with_diacritics_regex(str){
  const mappings = [
    '[αἀἁἂἃἄἅἆἇὰάᾀᾁᾂᾃᾄᾅᾆᾇᾲᾳᾴᾶᾷᾰᾱά]',
    '[εἐἑἒἓἔἕὲέέ]',
    '[ηἠἡἢἣἤἥἦἧὴήᾐᾑᾒᾔᾕᾖᾗῂῃῄῆῇή]',
    '[ιἰἱἲἳἴἵἶἷὶίῖῒΐῗῐῑίϊΐ]',
    '[οὀὁὂὃὄὅὸόό]',
    '[ρῤῥ]',
    '[υὐὑὒὓὔὕὖὗὺύῦῢΰῧῠῡύϋΰ]',
    '[ωὠὡὢὣὤὥὦὧὼώᾠᾡᾢᾣᾤᾥᾦᾧῲῳῴῶῷώ]'
  ]

  mappings.forEach((m) => {
  	const re = new RegExp(m,"gi");
    str = str.replace(re, m)
  })

  return str
}

function replace_diacretics_with_plain_letter(str){
  mappings_small_letters.forEach(m => {
    const re = new RegExp(m.findStr,"gi");
    str = str.replace(re, m.replaceStr)
  });

  return str
}

function replace_simple_accents_with_regexp(str){
  mappings_for_simple_accents.forEach(m => {
    str = str.replace(m.findStr, m.replaceStr)
  });
  //console.log(str)

  return new RegExp(str, "gi")
}

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength){
    return text
  } else {
    return text.substring(0, maxLength)+'...'
  }
}

const addHttpToLink = (link) => {
  if (link.trim() !== ''){
    return link.match(/^https?:\/\//i) ? link : 'http://' + link
  } else {
    return ''
  }
}

const downloadAndOpenBlob = (blob, filename) => {
  // Create blob link to download
  //console.log(blob.type) gets mimetype
  const url = window.URL.createObjectURL(
    new Blob([blob]),
  );
  /*
  Alternately: but we can't set the file name like this
  window.open(url, '_blank')
  */
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank'
  link.setAttribute(
    'download',
    filename,
  );

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

const stripHTMLtags = (str) => {
  return str.replace(/(<([^>]+)>)/gi, "");
}

const disableEvent = (e) => {
  e.preventDefault();
  return false;
}

const arraysHaveSameContents = (a, b) => {
  for (const v of new Set([...a, ...b]))
    if (a.filter(e => e === v).length !== b.filter(e => e === v).length)
      return false;
  return true;
}

const replaceFileObjects = (files_arr) => {
  return files_arr.map(f => {
    return {title: f.title, filename: f.filename}
  })
}

export { useAsyncReference, checkNoErrors, getCaretPosition, truncateText,
  replace_letters_with_diacritics_regex, replace_diacretics_with_plain_letter,
  replace_simple_accents_with_regexp, addHttpToLink, downloadAndOpenBlob,
  stripHTMLtags, disableEvent, arraysHaveSameContents, replaceFileObjects }
