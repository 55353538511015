import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';
import ListSubheader from '@material-ui/core/ListSubheader';

const SelectLabsForRole = (props) => {
  const { selectedLabs, allLabs, handleChange, error } = props

  const getSelectedNamesByLabType = (selected, labTypeCode) => {
    if (allLabs[labTypeCode]){
      return allLabs[labTypeCode].filter((lab) =>
        selected.indexOf(labTypeCode+'_'+lab.id) > -1)
        .map((lab) => lab.title)
        .join(', ')
    } else {
      return ''
    }
  }

  return(
    <FormControl error={Boolean(error)}>
       {<Select
         value={selectedLabs}
         onChange={handleChange}
         multiple
         displayEmpty
         renderValue={(selected) => {
           if (selected.length === 0) {
              return <em>Επιλογή Εργαστηρίων για πρόσβαση</em>;
            } else {
              let arr = []
              arr.push(getSelectedNamesByLabType(selected, 'tr'))
              /*arr.push(getSelectedNamesByLabType(selected, 'edu'))
              arr.push(getSelectedNamesByLabType(selected, 'summer'))*/
              //console.log(arr)
              return arr.filter(item => item !== '').join(', ');
            }
         }}
         style={{ marginTop : '10px', marginBottom:'20px'}}
         autoWidth={true}
       >
        <ListSubheader>Εργαστήρια Μετάφρασης &amp; Πρόσληψης</ListSubheader>
         {
           allLabs.tr && allLabs.tr.map((lab) =>
             <MenuItem
               key={lab.id}
               value={'tr_'+lab.id}
             >
               <Checkbox checked={selectedLabs.indexOf('tr_'+lab.id) > -1} />
               {lab.title}
             </MenuItem>
          )
         }
        {/* <ListSubheader>Μεταφραστικά &amp; Εκπαιδευτικά Εργαστήρια</ListSubheader>
         {
           allLabs.edu && allLabs.edu.map((lab) =>
             <MenuItem
               key={lab.id}
               value={'edu_'+lab.id}
             >
               <Checkbox checked={selectedLabs.indexOf('edu_'+lab.id) > -1} />
               {lab.title}
             </MenuItem>
          )
         }
         <ListSubheader>Summer Schools</ListSubheader>
         {
           allLabs.summer && allLabs.summer.map((lab) =>
             <MenuItem
               key={lab.id}
               value={'summer_'+lab.id}
             >
               <Checkbox checked={selectedLabs.indexOf('summer_'+lab.id) > -1} />
               {lab.title}
             </MenuItem>
          )
        }*/}
       </Select>}
       <FormHelperText>{error}</FormHelperText>
    </FormControl>
  )
}

export default SelectLabsForRole
