import React, {Component} from 'react';
//import {Link} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import {Segment, Grid} from 'semantic-ui-react';
import StatusMessage from '../statusmessage';
//import Avatar from '../avatar';
import './styles.css';

import Button from "components/CustomButtons/Button.js";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import NewForum from '../newforum'
import NewForumsMass from '../newforumsmass'
import LockIcon from '@material-ui/icons/Lock';
import FolderIcon from '@material-ui/icons/Folder';
import { getAllLabsApi } from '../../api'
import { getAllWorks } from 'clients/worksClient.js'
import { sortWorksAndBooks } from 'sorting.js'

import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

export default class ForumList extends Component {
  state = {
    openAdd: false,
    openAddMass: false,
    forumToEdit: {},
    allLabs: [],
    allWorks: [],
    //alert: null,
  }

  prepareToAdd = (f) => {
    this.setState({openAdd: true, forumToEdit: f})
  }

  handleClose = () => {
    this.setState({openAdd: false, forumToEdit: {}})
  }

  handleSubmit = (newforum) => {
    this.setState({openAdd: false})
    this.props.addOrEditForum(newforum)
  }

  prepareToAddMass = () => {
    this.setState({openAddMass: true})
  }

  handleCloseMass = () => {
    this.setState({openAddMass: false})
  }

  handleSubmitMass = (newforum) => {
    //console.log('ok')
    this.handleCloseMass()
    this.props.massAddForums(newforum)
  }

  delete = (id) => {
    this.props.deleteForum(id)
  }

  canDeleteForum = (forum) => {
    return this.props.canCreateForum && forum.subforums_count == 0 &&
      forum.threads_count == 0
  }

  /*canCreateForum = () => {
    if (!access_rights){
      return false
    } else if (this.props.father === 0){
      return access_rights.forums.wtl
    } else {
      return access_rights.forums.d.includes(this.props.father) || access_rights.forums.wtl
    }
  }

  canEditForum = (forumid) => {
    if (!access_rights){
      return false
    } else {
      return access_rights.forums.d.includes(forumid) || access_rights.forums.wtl
    }
  }*/

  /*setAlert = (alertValue) => {
    this.setState({alert: alertValue})
  }*/

  componentDidMount(){
    //console.log('get all types')
    const paths_array = ['translation_labs', 'education_labs_for_forums']
    getAllLabsApi(paths_array,
      (labs) => this.setState({allLabs: labs}),
      (err) => console.log(err)
    )

    getAllWorks(
      (data) => this.setState({allWorks: sortWorksAndBooks(data.works)}),
      (err) => console.log(err)
    )
  }

  render() {
    const { isLoading, error, forums, father, newForumLoading, canCreateForum,
      fatherIsPrivate, fatherLab, work, name } = this.props;

    const headerRow = (
      <Segment vertical className="grammi-titlos">
        <Grid textAlign="left" padded="horizontally">
          <Grid.Column width={6}>
            <Grid.Row>
              Κατηγορία
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={2}>
            <div className="home-column home-stats home-vertical">
              Συζητήσεις/αναρτήσεις
            </div>
          </Grid.Column>
          <Grid.Column width={4}>Τελευταία δραστηριότητα</Grid.Column>
        </Grid>
      </Segment>
    )

    const forumCardList = (error || !forums || isLoading || forums.length === 0) ?
    <StatusMessage
      error={error || !forums}
      errorClassName="home-error"
      errorMessage={error}
      loading={isLoading}
      loadingMessage={'Περιμένετε όσο γίνεται φόρτωση κατηγοριών συζητήσεων'}
      nothing={forums && forums.length === 0}
      nothingMessage={'Δεν υπάρχουν κατηγορίες συζητήσεων'}
      nothingClassName="home-error"
      type="default"
    />
    :
    <>
      {headerRow}
      {
        forums.map(forum => {
        const {
          name,
          id,
          description,
          posts_count,
          threads_count,
          last_activity,
          isPrivate,
          subforums_count,
        } = forum;

        let lastActivity = (
          <div className="home-text home-vertical home-last-activity">{'—  Καμία δραστηριότητα —'}</div>
        );

        if (last_activity) {
          let {
            thread_id,
            thread_name,
            user_fullname,
            pinned,
            date,
          } = last_activity;

          thread_name =
            thread_name.length > 43
              ? thread_name.substring(0, 43) + '...'
              : thread_name;
          lastActivity = (
            <div className="home-row">
              <div className="home-column home-last-activity">
                <div>
                  <Link href={`/admin/Thread/${id}-${thread_id}`}>{thread_name}</Link>
                </div>
                <div className="home-meta">
                  {user_fullname}
                  <b>{`  —  ${date}`}</b>
                </div>
              </div>
            </div>
          );
        }

        return (
          <Segment vertical key={id}>
            <Grid textAlign="left" padded="horizontally">
              <Grid.Column width={6}>
                <Grid.Row>
                  <FolderIcon/>
                  {
                    isPrivate && <LockIcon/>
                  }
                  <Link href={`/admin/Forum/${id}`}>{name}</Link>
                  {
                    (canCreateForum || access_rights.forums.d.includes(id)) &&
                    <IconButton size='small' onClick={() => this.prepareToAdd(forum)}><EditIcon/></IconButton>
                  }
                  {
                    this.canDeleteForum(forum) &&
                    <IconButton size='small' onClick={() => this.delete(forum.id)}
                      disabled={this.props.isDeleting==forum.id}><DeleteIcon/></IconButton>
                  }
                </Grid.Row>
                <Grid.Row>{description}</Grid.Row>
              </Grid.Column>
              <Grid.Column width={2}>
                <div className="home-column home-stats home-vertical">
                  <div style={{paddingBottom: '5px'}}>
                    {threads_count}
                    {threads_count != 1 ? ' συζητήσεις' : ' συζήτηση'}
                  </div>
                  <div style={{paddingBottom: '5px'}}>
                    {posts_count}
                    {posts_count != 1 ? ' αναρτήσεις' : ' ανάρτηση'}
                  </div>
                  <div>
                    {subforums_count}
                    {subforums_count != 1 ? ' υποκατηγορίες' : ' υποκατηγορία'}
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={4}>{lastActivity}</Grid.Column>
            </Grid>
          </Segment>
        )
      })
    }
  </>

    return (
      <div>
        {
          canCreateForum &&
          <div className='homeAddButton'>
            <Button color='primary' round onClick={() => this.prepareToAdd({})}>
              Προσθήκη κατηγοριας συζητήσεων<AddIcon/>
            </Button>
          </div>
        }
        {
          canCreateForum && father > 0 &&
          <div className='homeAddButton'>
            <Button color='primary' round onClick={this.prepareToAddMass}>
              Μαζική δημιουργία κατηγοριων για χωρία<AddIcon/>
            </Button>
          </div>
        }
        <div className="homeContainer">
          <Segment.Group className="home-list">{forumCardList}</Segment.Group>
          <NewForum open={this.state.openAdd} handleClose={this.handleClose}
            onSubmit={this.handleSubmit} father={father} allLabs={this.state.allLabs}
            forum={this.state.forumToEdit} isLoading={newForumLoading}
            fatherIsPrivate={fatherIsPrivate} fatherLab={fatherLab}
            allWorks={this.state.allWorks}
          />
          <NewForumsMass open={this.state.openAddMass}  handleClose={this.handleCloseMass}
            onSubmit={this.handleSubmitMass} isLoading={newForumLoading}
            father={father} fatherWork={work} fatherName={name}
            allWorks={this.state.allWorks}/>
        </div>
      </div>
    );
  }
}
