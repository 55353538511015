import React from 'react';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Assignment from "@material-ui/icons/Assignment";
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import { GroupMemberActions } from "./AdminUsersGroupsButtonsActions.js"
import { AddUsergroupMemberDialog, ViewGroupRolesAndRightsDialog } from "./AdminUsersGroupsDialogForms.js"
import { getUsersByGroup } from "clients/usersClient.js"
import { addUsersToGroup, delUsersFromGroup } from "clients/usergroupsClient.js"
import { useAsyncReference } from "utils.js"

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },

  LabelCol: {
    color:"#3f51b5",
    fontweight: "bold"
  },

  cardFix: {
      fontSize: "1em!important",
  },
}))

const GroupMembersTab = (props) => {

  const handleClickOpenGroupMember = () => {
    if (groupToView===0){
      //setAlert(<WarningAlert message='Επιλέξτε πρώτα ομάδα' hideAlert={hideAlert}/>)
      showWarningAlert('Επιλέξτε πρώτα ομάδα')
    } else {
      setDisableGroupMemberSubmit(false)
      getUsersByGroup(groupToView, false,
        (data) => {
          setUsersData(data.users_all)
          setOpenGroupMember(true);
        },
        (err) => showErrorAlert(err.message)
      )
    }
  };

  const handleCloseGroupMember = () => {
    setOpenGroupMember(false);
    setChecked([])
  };

  const handleAddMemberToGroup = () => {
    if (validateAddMemberToGroup()){
      //console.log('adding to group')
      setDisableGroupMemberSubmit(true)
      const params = {user_ids: checked, usergroup_id: groupToView}
      //console.log(params)

      addUsersToGroup(params,
        () => {
          let newData = GroupMembersdata.current;
          const newUsers = Usersdata.filter((user) => (checked.indexOf(user.user_id) >= 0))
          //console.log(newUsers)
          newUsers.forEach((user) => newData.push(constructGroupMemberdataRow(user)))
          //console.log(newData)
          setGroupMembersdata(newData);

          handleCloseGroupMember()
          setDisableGroupMemberSubmit(false)
          handleShowSuccessMessage('H καταχώριση έγινε με επιτυχία!')
        },
        (err) => {
          showErrorAlert(err.message)
          setDisableGroupMemberSubmit(false)
        }
      )
    }
  }

  const validateAddMemberToGroup = () => {
    if (groupToView===0){
      showErrorAlert('Δεν έχετε επιλέξει ομάδα!');
      return false;
    }

    if (checked.length===0){
      showErrorAlert('Δεν έχετε επιλέξει χρήστες!');
      return false;
    }

    return true;
  }

  const handleDeleteMemberFromGroup = (id) => {
    const params = {user_id: id, usergroup_id: groupToView}
    //console.log(params)

    delUsersFromGroup(params,
      () => {
        let newData = GroupMembersdata.current;
        const pos = newData.findIndex(o => o.id === id);
        //console.log(pos+' '+id)
        if (pos >= 0){
          newData.splice(pos, 1)
          //console.log(newData)
          setGroupMembersdata([...newData]);
        }
        handleShowSuccessMessage('H διαγραφή από την ομάδα έγινε με επιτυχία!')
      },
      (err) => {
        showErrorAlert(err.message)
        setDisableGroupMemberSubmit(false)
      }
    )
  }

  const handleGroupToViewChange = (e) => {
    setGroupToView(e.target.value)

    const group = Groupsdata.find(o => o.usergroup_id === e.target.value);
    return (group !== undefined) ? setObjUsergroupToView(group) : setObjUsergroupToView({})
  }

  const constructGroupMemberdataRow = (user) => {
    return {
      id: user.user_id,
      firstname: user.firstname,
      lastname: user.lastname,
      username: user.username,
      //phone: user.phone,
      active: user.active,
      activeTxt: user.active ? 'ΝΑΙ' : 'ΌΧΙ',
      actions: (
        <GroupMemberActions groupMember={user}
           handleClickDelete={(id) => showConfirmDelete(id, handleDeleteMemberFromGroup)}
           />
      )
    };
  }

  const loadGroupMembers = () => {
    if (groupToView > 0){
      getUsersByGroup(groupToView, true,
        (data) => {
          setGroupMembersdata(data.users_all.map((user) => constructGroupMemberdataRow(user)))
        },
        (err) => console.log(err)
      )
    } else {
      setGroupMembersdata([])
    }
  }

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    let newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    //console.log(newChecked);
  };

  const handlePreviewGroup = (boolView) => {
    setOpenGroupPreview(boolView)
  }

  const { Groupsdata, Worksdata, labsdata, forumsdata,
    handleShowSuccessMessage, showWarningAlert, showErrorAlert, showConfirmDelete } = props

  const [GroupMembersdata, setGroupMembersdata] = useAsyncReference([])
  const [groupToView, setGroupToView] = React.useState(0)
  const [objUsergroupToView, setObjUsergroupToView] = React.useState({})
  const [disableGroupMemberSubmit, setDisableGroupMemberSubmit] = React.useState(false)
  const [openGroupMember, setOpenGroupMember] = React.useState(false);
  const [openGroupPreview, setOpenGroupPreview] = React.useState(false)
  const [checked, setChecked] = React.useState([]);
  const [Usersdata, setUsersData] = React.useState([])

  let labsdataListFormatted = []
  //console.log(labsdata)
  labsdata.tr && labsdata.tr.forEach(lab =>
    labsdataListFormatted.push({id: 'tr_'+lab.id, title: lab.title})
  )
  labsdata.edu && labsdata.edu.forEach(lab =>
    labsdataListFormatted.push({id: 'edu_'+lab.id, title: lab.title})
  )

  const classes = useStyles();

  React.useEffect(() => {
    //used to update group memebers every time a new group is selected
    //console.log('getting members')
    loadGroupMembers()
  }, [groupToView])

  return (
    <>
      <Card className={classes.cardFix}>
        <CardHeader className="AristosCardHeader" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4>Μέλη ομάδας χρηστών</h4>
        </CardHeader>
        <CardBody>

        <h4 className={classes.LabelCol}>Μέλη ομάδας</h4>

           <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
                style={{ marginTop : '20px'}}
              >
                Επιλογή Ομάδας
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}

                value={groupToView}
                onChange={handleGroupToViewChange}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}

                style={{ marginTop : '10px', marginBottom:'20px', width:'250px',}}

              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                  value='0'
                >
                  Επιλογή ομάδας
                </MenuItem>
                {
                  Groupsdata.map((group, idx) =>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      key={group.usergroup_id ? group.usergroup_id : idx}
                      value={group.usergroup_id}
                    >
                      {group.usergroup_name}
                    </MenuItem>
                  )
                }
              </Select>

              <Tooltip title='Επισκόπηση ομάδας χρήστη'>
                <span>
                  <IconButton onClick={() => handlePreviewGroup(true)} disabled={groupToView<=0}><InfoOutlinedIcon/></IconButton>
                </span>
              </Tooltip>

              <ReactTable
                columns={[
                  {
                    Header: "Username",
                    accessor: "username"
                  },
                  {
                    Header: "Όνομα",
                    accessor: "firstname"
                  },
                  {
                    Header: "Επώνυμο",
                    accessor: "lastname"
                  },
                  {
                    Header: "Ενεργός/ή",
                    accessor: "activeTxt"
                  },
                  {
                    Header: "Ενέργειες",
                    accessor: "actions"
                  }
                ]}
                data={GroupMembersdata.current}
              />
          <Button className={classes.marginRight} onClick={handleClickOpenGroupMember}
            disabled={groupToView<=0}>Προσθήκη Μέλους Ομάδας</Button>
        </CardBody>
      </Card>

      <AddUsergroupMemberDialog
        handleAdd={handleAddMemberToGroup} handleClose={handleCloseGroupMember} open={openGroupMember}
        Usersdata={Usersdata} checked={checked} handleToggle={handleToggle}
        disableButton={disableGroupMemberSubmit}/>

      <ViewGroupRolesAndRightsDialog handleClose={() => handlePreviewGroup(false)}
        open={openGroupPreview} usergroup={objUsergroupToView} works={Worksdata}
        labs={labsdataListFormatted} forums={forumsdata}/>
    </>
  )
}

export default GroupMembersTab
