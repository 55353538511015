import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Typography from '@material-ui/core/Typography';
import SidebarShort from "components/Sidebar/SidebarShort.js";
import CssBaseline from '@material-ui/core/CssBaseline';
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { Copyright } from 'views/StandardIncludes/Footer.js'

import { checkPasswordChangeToken, passwordChangeWithToken } from 'clients/passwordResetClient.js'
import { checkNoErrors } from 'utils.js'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const PasswordChange = (props) => {
  const classes = useStyles();

  const [token, setToken] = React.useState('')
  const [tokenExpired, setTokenExpired] = React.useState(false)
  const [tokenExpiredErrMsg, setTokenExpiredErrMsg] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [password2, setPassword2] = React.useState('')
  const [errors, setErrors] = React.useState('')

  React.useEffect(() => {
    if (props.match.params.token){
      setToken(props.match.params.token)

      checkPasswordChangeToken(props.match.params.token,
        () => {},
        (err) => {
          setTokenExpired(true)
          setTokenExpiredErrMsg(err.message)
        }
      )
    }
  }, [props.match.params.token])

  const onPasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const onPassword2Change = (e) => {
    setPassword2(e.target.value)
  }

  const handleChangePassword = () => {
    let newErrors = {}
    if (password!==password2){
      newErrors.password = 'Οι κωδικοί δεν ταυτίζονται'
      newErrors.password2 = 'Οι κωδικοί δεν ταυτίζονται'
    }
    if (password.length===0){
      newErrors.password = 'Υποχρεωτικό πεδίο'
    } else if (password.length < 8 || password.length > 30){
      newErrors.password = 'Ο κωδικός πρέπει να έχει μήκος από 8 έως 30 χαρακτήρες'
    }
    if (password2.length===0){
      newErrors.password2 = 'Υποχρεωτικό πεδίο'
    }
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      const params = {passwd: password, token: token}
      //console.log(params)

      passwordChangeWithToken(params,
         () => {
           setPassword('')
           setPassword2('')
           handleShowMessage('Ο κωδικός σας άλλαξε με επιτυχία!', true)
         },
         (err) => handleShowMessage('Παρουσιάστηκε σφάλμα: ' + err.message, false)
       )
    }
  }

  //Snackbar messages (to inform user of success)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success')

  const handleShowMessage = (message, success) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message)
    setSnackbarSeverity(success ? 'success': 'error')
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return(
    <Container component="main" maxWidth="xs">
       <CssBaseline />
       <SidebarShort/>
       <div className={classes.paper}>

       <GridContainer justify="center">
         <GridItem xs={12} sm={12} >

           <Card login className={classes["cardHidden"]}>
               <CardHeader
                 className={`${classes.cardHeader} ${classes.textCenter}`}
                 color="primary"
               >
                  <h4 className={classes.cardTitle}>Αλλαγή password</h4>
               </CardHeader>
             <CardBody>
                <Grid container spacing={2}>
                  {
                    tokenExpired &&
                    <Grid item xs={12}>
                      <Typography variant='body2' color='error'>
                        Παρουσιάστηκε σφάλμα κατά την επιβεβαίωση του συνδέσμου: {tokenExpiredErrMsg}
                      </Typography>
                    </Grid>
                  }
                  <Grid item xs={12}>

                    <CustomInput
                       error={Boolean(errors.password)}
                       labelText="Νέος κωδικός"
                       id="password"
                       formControlProps={{
                         fullWidth: true
                       }}
                       inputProps={{
                         value: password,
                         onChange: event => onPasswordChange(event),
                         type: "password",
                       }}
                   />
                     <FormHelperText error>{errors.password}</FormHelperText>
                 </Grid>
                 <Grid item xs={12}>

                   <CustomInput
                      error={Boolean(errors.password2)}
                      labelText="Επιβεβαίωση κωδικού"
                      id="password2"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: password2,
                        onChange: event => onPassword2Change(event),
                        type: "password",
                      }}
                  />
                    <FormHelperText error>{errors.password2}</FormHelperText>
                </Grid>
               </Grid>

               <Button
                 fullWidth
                 variant="contained"
                 color="primary"
                 onClick={handleChangePassword}
                 disabled={tokenExpired}
               >
                Αλλαγή password
               </Button>
            </CardBody>
          </Card>

         </GridItem>
        </GridContainer>

        </div>
        

        <Snackbar anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

    </Container>

  )
}

export default PasswordChange
