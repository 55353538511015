import React, {Component} from 'react';
//import {Link} from 'react-router-dom';
import {getSelectedBlock} from 'draftjs-utils';
import htmlToDraft from 'html-to-draftjs';
import {List} from 'immutable';
import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw,
  Modifier,
} from 'draft-js';
import {Form, Icon, Divider} from 'semantic-ui-react';
import './styles.css';
import RichEditor from '../richeditor';
import FileUpload from '../fileupload';
import KeywordsInput from '../keywordsinput';

import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";
import { checkNoErrors, arraysHaveSameContents } from 'utils.js'
import { maxCharsInPost } from '../../utils/config.js'
import { saveDraft, getDraft } from '../../utils/utils.js'

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export default class NewThread extends Component {
  constructor(props) {
    super(props);
    const {name, content, keywords, file} = this.props;
    let editorState = this.convertToEditorState(content);
    //console.log('c='+file)
    this.state = {
      name,
      editorState,
      keywords: keywords || [],
      keywordInput: '',
      errors : {},
      file: file ? {name: file} : null,
      fileError: '',
      //use this to enable Restore Draft button after a Draft Save, as localStorage changes don't cause a React re-render
      draftExists: false,
      //Snackbar messages (to inform user of success)
      openSnackbar: false,
    };
  }

  componentDidUpdate(prevProps){
    const {name: newName, content: newContent, keywords: newKeywords, file: newFile} = this.props;
    if (prevProps.name !== newName || prevProps.content !== newContent ||
      prevProps.file !== newFile || prevProps.keywords !== newKeywords){
      const editorState = this.convertToEditorState(newContent);
      this.setState({
        name: newName,
        editorState,
        keywords: newKeywords,
        file: newFile ? {name: newFile} : null,
        fileError: '',
        keywordInput: '',
      });
    }
    if (this.props.success !== prevProps.success && this.props.success){
      //console.log('point3')
      //show success message
      this.setState({
        openSnackbar: true,
      })
    }
  }

  componentDidMount(){
    window.addEventListener('beforeunload', this.beforeUnloadListener);
  }

  componentWillUnmount(){
    window.removeEventListener('beforeunload', this.beforeUnloadListener);
  }

  beforeUnloadListener = (e) => {
    const content = this.state.editorState.getCurrentContent().getPlainText();
    if (this.hasUnsavedContentInForm()){
      console.log('thread')
      console.log(content)
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
  }

  hasUnsavedContentInForm = () => {
    const draft = getDraft()
    let savedContent = ''
    let savedName = ''
    let savedKeywords = []

    if (draft){
      const { content, name, keywords } = draft
      const editorState = this.convertToEditorState(content);
      savedContent = editorState.getCurrentContent().getPlainText();
      savedName = name
      savedKeywords = keywords
    }

    const formContent = this.state.editorState.getCurrentContent().getPlainText();
    if (formContent.trim().length > 0 && formContent !== savedContent ||
      this.state.name.trim().length > 0 && this.state.name !== savedName ||
      this.state.keywords.length > 0 && !arraysHaveSameContents(this.state.keywords, savedKeywords) ||
      this.state.file != null
    ){
      /*console.log(formContent)
      console.log(savedContent)
      console.log(this.state.name)
      console.log(savedName)
      console.log(this.state.keywords)
      console.log(savedKeywords)*/
      return true
    } else {
      return false
    }
  }

  convertToEditorState = content => {
    let editorState = EditorState.createEmpty();
    if (content) {
      try {
        const contentState = convertFromRaw(JSON.parse(content));
        editorState = EditorState.createWithContent(contentState);
      } catch (error) {
        const contentState = ContentState.createFromText(content);
        editorState = EditorState.createWithContent(contentState);
      }
    }
    return editorState;
  };

  toggleShowEditor = () => {
    this.props.toggleShowEditor();
  };

  onSave = () => {
    // save to localStorage
    const {name, editorState, keywords} = this.state;
    const content = JSON.stringify(
      convertToRaw(editorState.getCurrentContent()),
    );
    saveDraft(name, content, keywords)
    this.setState({draftExists: true})
    /*this.props.updateNewThread({
      name: name,
      content: content,
    });
    this.setState({
      file: null,
      fileError: '',
    });
    this.toggleShowEditor();*/
  };

  onRestore = () => {
    //restore from localStorage
    const draft = getDraft()
    if (draft){
      const { content, name, keywords } = draft
      /*console.log(content)
      console.log(name)
      console.log(keywords)*/
      const editorState = this.convertToEditorState(content);
      this.setState({
        name,
        keywords,
        editorState,
        keywordInput: '',
        //update errors with new values
        errors: {...this.state.errors, name: this.validateName(name)}
      })
    }
  }

  onCancel = () => {
    // reset & clear everything
    const editorState = EditorState.createEmpty();
    this.setState({
      name: '',
      keywords: '',
      editorState,
      errors: {},
      file: null,
      fileError: '',
      keywordInput: '',
    });
    /*const content = JSON.stringify(
      convertToRaw(editorState.getCurrentContent()),
    );*/
    this.props.updateNewThread({
      name: '',
      content: '',
    });
    if (this.props.id){
      this.props.cancelEdit()
    } else {
      this.toggleShowEditor();
    }
  };

  onNameChange = (e) => {
    this.setState({
      name: e.target.value,
      errors: {...this.state.errors, name: this.validateName(e.target.value)}
    });
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
    });
  };

  onKeywordsInputChange = (e, newInputValue) => {
    /*console.log('input')
    console.log(newInputValue)*/
    this.setState({keywordInput: newInputValue})
  }

  onKeywordsValueChange = (e, newValue) => {
    /*console.log('value')
    console.log(newValue)*/
    this.setState({keywords: newValue})
  }

  isFormValid = () => {
    let errors = {...this.state.errors}
    errors.name = this.validateName(this.state.name)
    this.setState({errors})
    return checkNoErrors(errors);
  };

  onSubmit = () => {
    if (this.isFormValid()) {
      const {name, editorState, keywords, file} = this.state;
      const {id, forum, createThread} = this.props;
      const content = JSON.stringify(
        convertToRaw(editorState.getCurrentContent()),
      );
      let newThread = {
        name: name,
        content: content,
        keywords: keywords,
        file: file ? file.name : null,
      };
      const newFile = file && file instanceof File ? file : null

      if (id){
        newThread.id = id
      } else {
        newThread.forum = forum
      }
      //console.log(newThread)
      createThread(newThread, newFile);
    }
  };

  validateName = (value) => {
    const len = value.trim().length
    if (len===0){
      return  'Υποχρεωτικό πεδίο'
    } else if (len > 100) {
      return 'Max. 100 χαρακτήρες'
    } else {
      return ''
    }
  }

  isValidLength = contentState => {
    //const maxLength = this.props.maxLength || 100;
    return contentState.getPlainText('').length < maxCharsInPost;
  };

  handleBeforeInput = input => {
    const {editorState} = this.state;
    if (!this.isValidLength(editorState.getCurrentContent())) {
      return 'handled';
    }
  };

  handlePastedText = (text, html, editorState, onChange) => {
    if (html) {
      const contentBlock = htmlToDraft(html);
      let contentState = editorState.getCurrentContent();
      contentBlock.entityMap.forEach((value, key) => {
        contentState = contentState.mergeEntityData(key, value);
      });
      contentState = Modifier.replaceWithFragment(
        contentState,
        editorState.getSelection(),
        new List(contentBlock.contentBlocks),
      );
      if (!this.isValidLength(contentState)) {
        return 'handled';
      }
      onChange(
        EditorState.push(editorState, contentState, 'insert-characters'),
      );
      return true;
    }
    const selectedBlock = getSelectedBlock(editorState);
    const newState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text,
      editorState.getCurrentInlineStyle(),
    );
    if (!this.isValidLength(newState)) {
      return 'handled';
    }
    onChange(EditorState.push(editorState, newState, 'insert-characters'));
    if (selectedBlock && selectedBlock.type === 'code') {
      return true;
    }
    return false;
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({openSnackbar: false})
  }

  onFileUpdate = (file, err) => {
    this.setState({file: file, fileError: err})
  }

  render() {
    const {
      isAuthenticated,
      isLoading,
      id,
      showEditor,
    } = this.props;
    const {name, editorState, keywords} = this.state;
    if (!isAuthenticated) {
      return <Button color='primary' onClick={() => window.location.href='/admin/SignIn'}>
        Συνδεθειτε για να αναρτησετε
        </Button>
      ;
    }

    const successMessageSnackBar = (
      <Snackbar anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={this.state.openSnackbar}
        autoHideDuration={3000}
        onClose={this.handleCloseSnackbar}
        >
        <Alert onClose={this.handleCloseSnackbar} severity="success">
          Η καταχώριση έγινε με επιτυχία
        </Alert>
      </Snackbar>
    )

    if (!showEditor) {
      return (
        <div>
          {successMessageSnackBar}
          <div className="newThread-hidden">
            <Button
              size="sm"
              color="primary"
              onClick={this.toggleShowEditor}>
              <Icon name="edit" />
              Νέα Συζήτηση
            </Button>
          </div>
        </div>
      );
    }

    const draftExists = (getDraft() !== null) || this.state.draftExists

    return (
      <div className="newThread-show">
        <Form loading={isLoading} className="attached fluid segment">
          <TextField
           error={Boolean(this.state.errors.name)}
           label='Τίτλος'
           style = {{width:500, paddingTop:10, marginTop:0}}
           value={name}
           onChange={this.onNameChange}
           />
          <FormHelperText error>{this.state.errors.name}</FormHelperText>
          <Divider />

          <RichEditor
            placeholder="Start typing your thread content here..."
            editorState={editorState}
            wrapperClassName="newThread-wrapper"
            toolbarClassName="newThread-toolbar"
            editorClassName="newThread-editor"
            onEditorStateChange={this.onEditorStateChange}
            handleBeforeInput={this.handleBeforeInput}
            handlePastedText={this.handlePastedText}
          />
          <FormHelperText>
            Αριθμός χαρακτήρων: {this.state.editorState.getCurrentContent().getPlainText('').length}
            &nbsp;(max. {maxCharsInPost})
          </FormHelperText>

          <FileUpload file={this.state.file} onFileUpdate={this.onFileUpdate}
            fileError={this.state.fileError}/>

          <KeywordsInput keywords={this.state.keywords}
            keywordInput={this.state.keywordInput} error=''
            handleInputChange={this.onKeywordsInputChange}
            handleValueChange={this.onKeywordsValueChange}/>

          <Button
            color="rose"
            size="sm"
            disabled={isLoading}
            onClick={this.onSubmit}>
            <Icon name="edit" />
            {this.props.id ? 'Καταχωριση επεξεργασίας' : 'Ανάρτηση συζήτησης'}
          </Button>
          <Button
            color="primary"
            size="sm"
            disabled={isLoading}
            onClick={this.onSave}>
            <Icon name="save" />
            Αποθήκευση Προσχεδίου
          </Button>
          <Button
            color='primary'
            size="sm"
            disabled={isLoading || !draftExists}
            onClick={this.onRestore}>
            Επαναφορά Προσχεδίου
          </Button>
          <Button
            color='primary'
            size="sm"
            disabled={isLoading}
            onClick={this.onCancel}>
            <Icon name="cancel" />
            {this.props.id ? 'Ακύρωση' : 'Καθαρισμος'}
          </Button>
        </Form>
      </div>
    );
  }
}

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
