import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';

const SelectForumsForRole = (props) => {
  const { forum, allForums, handleChange, error } = props
  return(
    <FormControl error={Boolean(error)}>
       <Select
         value={forum}
         onChange={handleChange}
         multiple
         displayEmpty
         renderValue={(selected) => {
           if (selected.length === 0) {
              return <em>Επιλογή Φόρουμ για διαχείριση</em>;
            } else {
              const selNames = allForums.filter((f) => selected.indexOf(f.id) > -1)
                .map((f) => f.name)
              //console.log(selNames)
              return selNames.join(', ');
            }
         }}
         style={{ marginTop : '10px', marginBottom:'20px'}}
       >
         {
           allForums.map((f) =>
             <MenuItem
               key={f.id}
               value={f.id}
             >
               <Checkbox checked={forum.indexOf(f.id) > -1} />
               {f.name}
             </MenuItem>
          )
         }

       </Select>
       <FormHelperText>{error}</FormHelperText>
    </FormControl>
  )
}

export default SelectForumsForRole
