import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Button from "components/CustomButtons/Button.js";
import Container from '@material-ui/core/Container';
import CustomInput from "components/CustomInput/CustomInput.js";
import SearchIcon from '@material-ui/icons/Search';

import LabThumbnail from './LabThumbnail.js'
import { getAccessRightsFieldForLabType } from './utils.js'
import { replace_letters_with_diacritics_regex } from 'utils.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const useStyles = makeStyles((theme) => ({
  centered: {
    textAlign: 'center',
  },
}))

const LabsMainPage = (props) => {
  const classes = useStyles()
  const { labtype, labs, keyword } = props
  const labs_type_access = getAccessRightsFieldForLabType(labtype)

  const [filteredLabs, setFilteredLabs] = React.useState([])
  const [searchKeyword, setSearchKeyword] = React.useState(keyword || '')

  const handleSearchKeywordChange = (e) => {
    setSearchKeyword(e.target.value)
  }

  const handleSearch = () => {
    filterByKeyword(searchKeyword)
  }

  const filterByKeyword = kword => {
    if (!kword || kword.trim()===''){
      setFilteredLabs(labs)
    } else {
      //console.log('filter by '+kword)
      const re = new RegExp('^' +replace_letters_with_diacritics_regex(kword) + '$', "i");
      setFilteredLabs(labs.filter(l =>
        {
          let isMatch = false
          l.tags.forEach(tag => {
            if (tag.match(re)){
              isMatch = true
            }
          })
          return isMatch
        }))
    }
  }

  React.useEffect(() => {
    setFilteredLabs(labs)
    //setSearchKeyword(keyword)
    filterByKeyword(keyword)
  }, [labs, keyword])

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.centered}>
          <CustomInput
             labelText="Αναζήτηση με λέξη-κλειδί"
             id="searchKeyword"
             formControlProps={{
               fullWidth: false,
             }}
             inputProps={{
               value: searchKeyword,
               onChange: handleSearchKeywordChange
             }}
          />
          <Button justIcon color='primary' onClick={handleSearch}><SearchIcon/></Button>
        </Grid>
        <Grid item xs={12} className={classes.centered}>
          {
            access_rights && labs_type_access && access_rights[labs_type_access].w &&
            <Button justIcon round color="primary" onClick={() => window.location.href='/admin/EduLabEdit/'+labtype}><AddIcon/></Button>
          }
        </Grid>
        {filteredLabs && filteredLabs.map((lab) =>
          <Grid key={lab.id} item xs={12} sm={4}>
            <LabThumbnail lab={lab} writeAccess={access_rights && labs_type_access && access_rights[labs_type_access].w}/>
          </Grid>
        )}
        {
          filteredLabs && filteredLabs.length===0 &&
          <Grid item xs={12} className={classes.centered}>
            Δε βρέθηκαν εργαστήρια
          </Grid>
        }
      </Grid>
    </Container>
  )
}

export default LabsMainPage
