import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from "components/CustomButtons/Button.js";
import Divider from '@material-ui/core/Divider';

import EduLabSignupForm from "./EduLabSignupForm.js"

import { labsEduImagesURL } from 'clients/clientConfig.js'
import { getLabMainPage, getLabTypeName, getAccessRightsFieldForLabType } from './utils.js'
import { getOneLab } from 'clients/labEducationEtcClient.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  paper: {
    padding: 20
  },
  labImage: {
    height: 120,
    width: 180,
    margin: 'auto',
    display: 'block'
  },
  centered: {
    textAlign: 'center',
  },
}))

const EduLabSignup = (props) => {
    const classes = useStyles()

    const [lab, setLab] = React.useState({})
    const [numAlreadyAttending, setNumAlreadyAttending] = React.useState(0)

    React.useEffect(() => {
      if (props.match.params.lab_id){
        getOneLab(props.match.params.lab_id, '',
          (data) => {
            if (data.education_labs.length > 0){
              setLab(data.education_labs[0])
              setNumAlreadyAttending(data.education_labs[0].attendants)
            } else {
              window.location.href = '/admin/LabNotFound'
            }
          },
          (err) => window.location.href = '/admin/LabNotFound'
        )
      }
    }, [props.match.params.lab_id])

    return(
      <div className={classes.root}>
        <CssBaseline />
        <Sidebar/>

        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} >
            <div>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                  <Link color="inherit" href="/admin/SelectTransSem">
                    Main
                  </Link>
                  <Link color='inherit' href={getLabMainPage(lab.lab_type || '')}>
                    {getLabTypeName(lab.lab_type || '')}
                  </Link>
                  <Link color='inherit' href={"/admin/EduLabPreview/"+lab.id}>
                    {lab.title || ''}
                  </Link>
                  <Typography color="textPrimary">Εγγραφή</Typography>
              </Breadcrumbs>
            </div>
          </Grid>

         <Grid item xs={12} style={{ paddingTop : '20px'}}>
           <Container maxWidth="md">
            <Paper className={classes.paper}>
              <Typography variant='h4' align='center'>Εγγραφή στο εργαστήριο &quot;{lab.title}&quot;</Typography>
              {lab.image && <img alt='' src={labsEduImagesURL+lab.id+'/'+lab.image} className={classes.labImage}/>}
              <br/>
              {
                lab.freeAccess ?
                <Typography variant='body1' align='center'>
                  Το συγκεκριμένο εργαστήριο έχει ελεύθερη πρόσβαση και δε χρειάζεται εγγραφή
                </Typography>
                :
                (localStorage.getItem('UserName')===null || localStorage.getItem('UserName')==='null') ?
                <SignInDetails/> :
                <EduLabSignupForm lab={lab} numAlreadyAttending={numAlreadyAttending}/>
              }
              <Divider/>
              <div className={classes.centered}>
                <Button color='primary' onClick={() => window.location.href = "/admin/EduLabPreview/"+lab.id}>Επιστροφή στο εργαστήριο</Button>
              </div>
            </Paper>
          </Container>
        </Grid>

      </Grid>
    </div>
    )
}

const SignInDetails = () => {
  const classes = useStyles()

  return(
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='body1' align='center'>
          Για να εγγραφείτε στο εργαστήριο πρέπει πρώτα να συνδεθείτε στο σύστημα.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <Button color='primary' onClick={() => window.location.href = "/admin/SignIn"}>Σύνδεση στο λογαριασμό μου</Button>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <Button color='primary' onClick={() => window.location.href = "/admin/SignUp"}>Δημιουργία λογαριασμού</Button>
      </Grid>
    </Grid>
  )
}

export default EduLabSignup
