import React from 'react';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { sortAlphabetically } from 'sorting.js'

const useStyles = makeStyles((theme) => ({
  header: {
    color: "#c00000",
  },
  lyrics: {
    color: '#808080',
    fontWeight: '700'
  },
  text: {
    marginLeft: '20px'
  },
  scholar: {
    color: 'black',
    fontWeight: '700'
  }
}));

const baseURLforFiles = 'https://aristotelistes.cti.gr/files/'

const OriginalTextCommentsTab = (props) => {
  const classes = useStyles()
  const parse = require('html-react-parser');

  const sortComments = (a, b) => {
    const aNum = /^\d+/.exec(a.lines)
    const bNum = /^\d+/.exec(b.lines)
    //console.log(aNum)
    if (aNum ===null || bNum  === null){
    	return sortAlphabetically(a.lines, b.lines)
    } else {
    	const numDiff = aNum - bNum
        if (numDiff!==0){
        	return numDiff
        } else {
        	return sortAlphabetically(a.lines.substring(aNum.toString().length),
            b.lines.substring(bNum.toString().length))
        }
    }
  }

  const {scholarComments, onEditScholarComment, onDeleteScholarComment, edit} = props
  if (scholarComments){
    scholarComments.sort(sortComments)
    scholarComments.forEach(comment => {
      comment.text = comment.text.replace(/\n/g, '<br/>')
    })
  }

  return (
    <>
      {scholarComments && scholarComments.length > 0 &&
        <>
          <Typography variant='h6' className={classes.header}>Σχόλια μελετητών</Typography>
          {scholarComments.map((comment, idx) =>
            <div key={idx}>
                <Typography className={classes.lyrics}>
                  [{comment.lines} {comment.phrase}]&nbsp;
                  {edit && <IconButton size='small' onClick={() => onEditScholarComment(comment)}><EditIcon/></IconButton>}
                  {edit && <IconButton size='small' onClick={() => onDeleteScholarComment(comment.comment_id)}><DeleteIcon/></IconButton>}
                </Typography>
                <Typography className={classes.scholar}>{comment.scholar} {comment.scholar_reference}</Typography>
                <Typography className={classes.text} component="div">{parse(comment.text)}</Typography>
                {comment.translationGr &&
                  <Typography className={classes.text} component="div"><u>Μετάφραση</u>: {parse(comment.translationGr)}</Typography>
                }
                {/* TODO: add english translation if we are in english website*/}
                {comment.links_arr && comment.links_arr.length > 0 &&
                  <List dense>
                    <ListSubheader>Εξωτερικοί σύνδεσμοι</ListSubheader>
                    {comment.links_arr.map((l, i) =>
                      <ListItem key={i}><Link variant='body2' href={l.link}>{l.title}</Link></ListItem>
                    )}
                  </List>
                }
                {comment.tags_arr && comment.tags_arr.length > 0 &&
                  <Typography>Λέξεις-κλειδιά:
                    {
                      comment.tags_arr.map((tag, idx) =>
                        <Link key={idx} href={'/admin/CommentSearchByTagResults/'+tag}>{tag}, </Link>
                      )
                    }
                  </Typography>
                }
              {comment.filenames_arr && comment.filenames_arr.length > 0 &&
                <List dense>
                  <ListSubheader>Αρχεία αναφοράς</ListSubheader>
                  {comment.filenames_arr.map((fname, i) =>
                    <ListItem key={i}><Link variant='body2' href={baseURLforFiles + comment.comment_id + '/' + fname}>{fname}</Link></ListItem>
                  )}
                </List>
              }
              <br/>
            </div>
          )}
        </>
      }

    </>
  )
}

export default OriginalTextCommentsTab
