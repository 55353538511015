import React from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const ContentSearch = (props) => {
  const { lab_id } = props
  const [search, setSearch] = React.useState('')
  const [error, setError] = React.useState('')

  const handleSearchChange = (e) => {
    if (e.target.value.trim() !== ''){
      setError('')
    }
    setSearch(e.target.value)
  }

  const handleSubmit = () => {
    if (search.trim() === ''){
      setError('Υποχρεωτικό πεδίο')
    } else {
      setError('')
      window.location.href = '/admin/EduLabSearchResults/' + lab_id + '?search=' + search
    }
  }

  React.useEffect(() => {
    if (props.search){
      setSearch(props.search)
    }
  }, [props.search])

  return(
    <div id="labsearch">
      <TextField
      label='Αναζήτηση περιεχομένου'
      value={search}
      onChange={handleSearchChange}
      error={error!==''}
      InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <Button justIcon color='primary' onClick={handleSubmit}><SearchIcon/></Button>

      <FormHelperText error>{error}</FormHelperText>
    </div>
  )
}

export default ContentSearch
