import { fetchWithGET, fetchWithMethod, fetchWithFile, fetchBlob } from './clientUtils.js'

function getAllLabsForType(lab_type, success, errorFunc){
  const querystring = lab_type ? '?lab_type=' + lab_type : ''
  return fetchWithGET('education_labs', querystring, success, errorFunc)
}

function getOneLab(id, querystring, success, errorFunc){
  return fetchWithGET('education_labs/' + id, querystring, success, errorFunc)
}

function addLab(files, vals, success, errorFunc){
  return fetchWithFile('education_labs', 'POST', files, vals, success, errorFunc)

}

function editLab(files, vals, success, errorFunc){
  return fetchWithFile('education_labs', 'PUT', files, vals, success, errorFunc)
}

function getOtherLabs(querystring, success, errorFunc){
  return fetchWithGET('education_labs/top_labs', querystring, success, errorFunc)
}

function getOnePage(id, querystring, success, errorFunc){
  return fetchWithGET('education_labs_pages/' + id, querystring, success, errorFunc)
}

function addPage(files, vals, success, errorFunc){
  return fetchWithFile('education_labs_pages', 'POST', files, vals, success, errorFunc)
}

function editPage(files, vals, success, errorFunc){
  return fetchWithFile('education_labs_pages', 'PUT', files, vals, success, errorFunc)
}

function getLabFile(file_id, success, errorFunc){
  return fetchBlob('get_lab_file/'+file_id, success, errorFunc)
}

function subscribeToLab(params, success, errorFunc){
  return fetchWithMethod('education_labs/subscribe', 'POST', params, success, errorFunc)
}

function changeSubscriptionStatus(params, success, errorFunc){
  return fetchWithMethod('education_labs/subscribe', 'PUT', params, success, errorFunc)
}

function getAllLabs(querystring, success, errorFunc){
  return fetchWithGET('education_labs_all_types', querystring, success, errorFunc)
}

function getLabSubscribers(id, querystring, success, errorFunc){
  return fetchWithGET('education_labs/subscribers/' + id, querystring, success, errorFunc)
}

function uploadCodes(files, vals, success, errorFunc){
  return fetchWithFile('education_labs/upload_codes', 'POST', files, vals, success, errorFunc)
}

function searchLabPages(querystring, success, errorFunc){
  return fetchWithGET('education_labs/search', querystring, success, errorFunc)
}

function rateLab(params, success, errorFunc){
  return fetchWithMethod('education_labs/rate', 'POST', params, success, errorFunc)
}

function copyLab(params, success, errorFunc){
  return fetchWithMethod('education_labs/copy', 'POST', params, success, errorFunc)
}

function addLabComment(params, success, errorFunc){
  return fetchWithMethod('education_labs/comment', 'POST', params, success, errorFunc)
}

function selectCommentsToShow(params, success, errorFunc){
  return fetchWithMethod('education_labs/comment_select', 'PUT', params, success, errorFunc)
}

function getAllLabComments(id, success, errorFunc){
  return fetchWithGET('education_labs/comment/' + id, '', success, errorFunc)
}

export { getAllLabsForType, getOneLab, addLab, editLab, getOtherLabs,
  getOnePage, addPage, editPage, getLabFile, subscribeToLab, getAllLabs,
  getLabSubscribers, changeSubscriptionStatus, uploadCodes, searchLabPages, rateLab,
  copyLab, addLabComment, selectCommentsToShow, getAllLabComments }
