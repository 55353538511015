import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'

const useStyles = makeStyles((theme) => ({
  fileinput: {
    display: 'none',
  },
}))

const MaterialUploadForm = (props) => {
  const classes = useStyles()
  const { onSave, onClose, open, errors,
     title, handleTitleChange, file, handleFileUpload } = props

  return(
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth
      maxWidth={"sm"}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Ανέβασμα αρχείου
        </DialogTitle>

        <DialogContent>
          <TextField
            label='Τίτλος'
            value={title}
            error={Boolean(errors.title)}
            onChange={handleTitleChange}
            style = {{width:500, paddingTop:10, marginTop:0}}
          />
          <FormHelperText error>{errors.title}</FormHelperText>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormLabel>Αρχείο</FormLabel>
              <br/>
              <input
                accept=".pdf, .doc, .docx"
                className={classes.fileinput}
                id="contained-button-file"
                type="file"
                onChange={handleFileUpload}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Ανεβασμα αρχείου
                </Button>
              </label>
              <FormHelperText error>{errors.file}</FormHelperText>
            </Grid>
            <Grid item xs={9}>
              {file && file.name}
            </Grid>
          </Grid>

        </DialogContent>

        <DialogActions>
          <Button onClick={onSave}>Καταχωριση</Button>
          <Button onClick={onClose}>Κλεισιμο</Button>
        </DialogActions>
     </Dialog>
  )
}

export default MaterialUploadForm
