import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createPost, fetchThread, deletePost, deleteThread, editPost, editThread,
  editPostCancel,
  editThreadCancel,
  createThreadSave,
  createThreadToggle,
  changeThreadPage,
  restorePost,
  togglePinnedThread,
  toggleFavouriteThread,
  toggleNotificationsForThread,
  copyThreadToFile,
  likeThread,
  likePost,
  forwardThread,
} from '../../actions';
import Thread from '../../components/thread';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import { ConfirmDeleteMessage } from 'views/CustomAlerts/ConfirmationDialog.js'
import { hasDeleteRightsForParents } from '../../utils/utils.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

class ThreadContainer extends Component {
  state = {
    alert: null
  }

  componentDidMount() {
    const {threadid} = this.props.match.params;
    this.props.fetchThread(threadid);
  }

  componentDidUpdate(prevProps){
    const {threadid} = prevProps.match.params;
    const {threadid: newThread} = this.props.match.params;
    if (threadid !== newThread) {
      //console.log('fetch')
      this.props.fetchThread(newThread);
    }
  }

  setAlert = (alertValue) => {
    this.setState({alert: alertValue})
  }

  confirmDeletePost = (...rest) => {
    this.setAlert(<ConfirmDeleteMessage onConfirm={() => this.deletePost(...rest)} onCancel={() => this.setAlert(null)}/>)
  }

  deletePost = (...rest) => {
    this.props.deletePost(...rest)
    this.setAlert(null)
  }

  confirmDeleteThread = (...rest) => {
    this.setAlert(<ConfirmDeleteMessage onConfirm={() => this.deleteThread(...rest)} onCancel={() => this.setAlert(null)}/>)
  }

  deleteThread = (...rest) => {
    this.props.deleteThread(...rest)
    this.setAlert(null)
  }

  handleChangePage = (e, page) => {
    this.props.changePage(page)
  }

  forwardThread = (thread_id, selected) => {
    const params = {id: thread_id, users: selected}
    //console.log(params)
    this.props.forwardThread(params)
  }

  render() {
    const {threadid: threadID} = this.props.match.params;
    const {
      isLoading,
      name,
      content,
      file,
      pinned,
      user_id,
      user_fullname,
      date,
      posts,
      forum_id,
      forum_name,
      parents,
      error,
      page,
      date_edited,
      edited_by,
      keywords,
      createPost,
      //need the following 2 to avoid conflicts with Thread params of the same name, but with different value
      deletePost,
      deleteThread,
      forwardThread,
      ...rest
    } = this.props;

    const rootStyles = {
      display: 'flex',
      flexGrow: 1,
    }

    return (
      <div style={rootStyles}>
        <CssBaseline />
        <Sidebar/>

        <Grid container spacing={2} style={rootStyles}>
          <Grid item xs={12} >
            <div>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                  <Link color="inherit" href="/admin/SelectTransSem">
                    Main
                  </Link>
                  <Link color="inherit" href="/admin/Forums">Forums</Link>
                  {
                    parents && parents.map(p =>
                      <Link key={p.id} color='inherit' href={"/admin/Forum/"+p.id}>{p.name}</Link>
                    )
                  }
                  <Link color='inherit' href={"/admin/Forum/"+forum_id}>{forum_name || ''}</Link>
                  <Typography color="textPrimary">{name}</Typography>
              </Breadcrumbs>
            </div>
          </Grid>

         <Grid item xs={12} style={{ paddingTop : '20px'}}>
          <Container maxWidth='lg'>
            <Paper>
              <Thread
                id={threadID}
                isLoading={isLoading}
                name={name}
                content={content}
                file={file}
                pinned={pinned}
                user_id={user_id}
                user_fullname={user_fullname}
                date={date}
                posts={posts}
                error={error}
                page={page}
                isAuthenticated={access_rights && access_rights.forums.w}
                date_edited={date_edited}
                edited_by={edited_by}
                keywords={keywords}
                createPost={createPost}
                authenticatedIsStaff={access_rights && (access_rights.forums.wtl || hasDeleteRightsForParents(parents, forum_id, access_rights.forums.d))}
                authenticatedIsForumAdmin={access_rights && hasDeleteRightsForParents(parents, forum_id, access_rights.forums.d)}
                deletePost={this.confirmDeletePost}
                deleteThread={this.confirmDeleteThread}
                handleChangePage={this.handleChangePage}
                forwardThread={this.forwardThread}
                {...rest}
              />

              {this.state.alert}
            </Paper>
          </Container>
        </Grid>
      </Grid>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.thread.isLoading,
  name: state.thread.name,
  content: state.thread.content,
  file: state.thread.file,
  pinned: state.thread.pinned,
  keywords: state.thread.keywords,
  user_id: state.thread.user_id,
  user_fullname: state.thread.user_fullname,
  date: state.thread.date,
  posts: state.thread.posts,
  page: state.thread.page,
  date_edited: state.thread.date_edited,
  edited_by: state.thread.edited_by,
  favourite_admin: state.thread.favourite_admin,
  favourite_user: state.thread.favourite_user,
  forum_id: state.thread.forum_id,
  forum_name: state.thread.forum_name,
  parents: state.thread.parents,
  likes: state.thread.likes,
  error: state.thread.error,
  newPostSuccess: state.thread.newPostSuccess,
  newPostLoading: state.thread.newPostLoading,
  newPostError: state.thread.newPostError,
  deletePostSuccess: state.thread.deletePostSuccess,
  deletePostError: state.thread.deletePostError,
  deletePostList: state.thread.deletePostList,
  isDeleting: state.thread.isDeleting,
  deleteError: state.thread.deleteError,
  editThreadLoading: state.thread.editThreadLoading,
  editThreadSuccess: state.thread.editThreadSuccess,
  editThreadError: state.thread.editThreadError,
  editThreadName: state.thread.editThreadName,
  editThreadContent: state.thread.editThreadContent,
  restorePostSuccess: state.thread.restorePostSuccess,
  restorePostError: state.thread.restorePostError,
  togglePinnedSuccess: state.thread.togglePinnedSuccess,
  toggleFavouriteSuccess: state.thread.toggleFavouriteSuccess,
  toggleError: state.thread.toggleError,
  toggleNotificationsSuccess: state.thread.toggleNotificationsSuccess,
  isCopying: state.thread.isCopying,
  copyThreadError: state.thread.copyThreadError,
  isLikingID: state.thread.isLikingID,
  likeSuccess: state.thread.likeSuccess,
  likeError: state.thread.likeError,
  isForwarding: state.thread.isForwarding,
  forwardThreadSuccess: state.thread.forwardThreadSuccess,
  forwardThreadError: state.thread.forwardThreadError,
});

const mapDispatchToProps = dispatch => ({
  fetchThread: thread => {
    dispatch(fetchThread(thread));
  },
  createPost: (newPost, newFile) => {
    dispatch(createPost(newPost, newFile));
  },
  deletePost: (id, threadID) => {
    dispatch(deletePost(id, threadID));
  },
  deleteThread: id => {
    dispatch(deleteThread(id));
  },
  editPost: (newPost, newFile) => {
    dispatch(editPost(newPost, newFile))
  },
  editThread: (newThread, newFile) => {
    dispatch(editThread(newThread, newFile))
  },
  editThreadSave: newThread => {
    dispatch(createThreadSave(newThread));
  },
  editThreadToggle: () => {
    dispatch(createThreadToggle());
  },
  cancelEdit: () => {
    dispatch(editPostCancel())
  },
  cancelEditThread: () => {
    dispatch(editThreadCancel())
  },
  changePage: (page) => {
    dispatch(changeThreadPage(page))
  },
  restorePost: (id, threadID) => {
    dispatch(restorePost(id, threadID));
  },
  togglePinned: (thread_id, prevPinnedState) => {
    dispatch(togglePinnedThread(thread_id, 'thread_view', prevPinnedState))
  },
  toggleFavourite: (thread_id, prevFavouriteState) => {
    dispatch(toggleFavouriteThread(thread_id, 'thread_view', prevFavouriteState))
  },
  toggleNotifications: (thread_id) => {
    dispatch(toggleNotificationsForThread(thread_id))
  },
  copyThread: (thread_id) => {
    dispatch(copyThreadToFile(thread_id))
  },
  likeThread: (params) => {
    dispatch(likeThread(params))
  },
  likePost : (params) => {
    dispatch(likePost(params))
  },
  forwardThread: (params) => {
    dispatch(forwardThread(params))
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThreadContainer);
