import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Sidebar from "components/Sidebar/Sidebar.js";


import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import BlogIcon from '@material-ui/icons/FormatBold';
import WikiIcon from '@material-ui/icons/Search';
import SeminarIcon from '@material-ui/icons/PeopleAlt';


import Avatar from '@material-ui/core/Avatar';

import WikiCircle from '@material-ui/icons/Language';
import BlogCircle from '@material-ui/icons/FormatBold';
import { green } from '@material-ui/core/colors';
import { blue } from '@material-ui/core/colors';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Box from '@material-ui/core/Box';

import GroupWorkIcon from '@material-ui/icons/GroupWork';

import seminarIcon1 from "images/seminar1.svg";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },


    exampleWrapper: {
      position: 'relative',
      marginTop: theme.spacing(0),
      height: 80,
    },

    speedDial: {
      position: 'absolute',
      '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
    BlogIconCol: {
      backgroundColor: green[100],
      color: green[600],
    },
    WikiIconCol: {
      backgroundColor: "#2C8CF4",
      color: blue[600],
    },


}));


const actions = [
  { icon: <BlogIcon />, name: 'Προσθήκη Blog', id:1 },
  { icon: <WikiIcon />, name: 'Προσθήκη Wiki', id:2 },
  { icon: <SeminarIcon />, name: 'Προσθήκη Σεμιναρίου', id:3 },
];

export default function MiniDrawer() {

  const [direction, setDirection] = React.useState('right');
  const [hidden, setHidden] = React.useState(false);

  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const handleHiddenChange = (event) => {
    setHidden(event.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const NewBlogWiki = (e, id) => {

    e.preventDefault();
    if(id=="1"){
      // do something
    }else if(id=="2"){
      //do something else
    }else if(id=="3"){
      window.location.href = "./AddSeminar";
    }
    setOpen(!open);
  };



  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [spacing, setSpacing] = React.useState(2);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  return (

    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

    <Grid container className={classes.root} spacing={2}>
    <Grid item xs={12} >
      <div>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/admin/SelectTransSem">
            Main
          </Link>
          <Link color="inherit" href="/admin/Seminars">
            Σεμινάρια
          </Link>
      </Breadcrumbs>
      </div>
    </Grid>
     <Grid item xs={12} style={{ paddingTop : '20px'}}>
       <Grid container justify="center" spacing={spacing}>

           <Grid>
               <Grid container spacing={2}>

                 <Grid item xs={12} sm container>
                   <Grid item xs container direction="column" spacing={2}>
                     <Grid item xs>

                     <Card  >
                       <CardHeader color="primary">

                         <Box  display="flex" flexDirection="row">
                             <Box fontWeight={700} m={1} className={classes.cardContentTrans}>
								 <Avatar className={classes.WikiIconCol}>)
								 <img style={{width:35, height:35, marginRight:7, marginTop:3,}} src={seminarIcon1} alt="..." />

									   </Avatar>
                               </Box>
                               <Box  m={1} className={classes.cardContentTrans}>
                                   {/*<h4 className={classes.cardTitleWhite}>Blogs</h4> */}
                                   <p className={classes.cardCategoryWhite}>
                                   Σεμινάρια Αριστοτελικών έργων που έχουν προγραμματιστεί/υλοποιηθεί
                                   </p>
                               </Box>
                         </Box>
                       </CardHeader>
                       <CardBody>
                         <Table
                           tableHeaderColor="primary"
                           tableHead={["Τίτλος", "Δημιουργός", "Ημερομηνία", "Αναρτήσεις"]}
                           tableData={[
                             [<a href= "./SeminarStruct" target="_new"><u>Περί γενέσεως και φθοράς</u></a>, "Γιώργος Βάρσος", "19/10/2020", "35"],
                             [<a href= "./SeminarStruct" target="_new"><u>Φυσική ακρόαση</u></a>, "Ηλίας Γεωργούλας", "17/9/2020", , "35"],
                             [<a href= "./SeminarStruct" target="_new"><u>Μετεωρολογικά</u></a>, "Βάλια Δημοπούλου", "6/5/2020", "35"],
                             [<a href= "./SeminarStruct" target="_new"><u>Μετά τα φυσικά</u></a>, "Παύλος Kαλλιγάς", "16/4/2020", "35"],
                             [<a href= "./SeminarStruct" target="_new"><u>Περί κόσμου</u></a>, "Γεράσιμος Κουζέλης", "22/3/2020", "35"],
                             [<a href= "./SeminarStruct" target="_new"><u>Περί γενέσεως και φθοράς</u></a>, "Γιώργος Βάρσος", "19/10/2020", "35"],
                             [<a href= "./SeminarStruct" target="_new"><u>Φυσική ακρόαση</u></a>, "Ηλίας Γεωργούλας", "17/9/2020", , "35"],
                             [<a href= "./SeminarStruct" target="_new"><u>Μετεωρολογικά</u></a>, "Βάλια Δημοπούλου", "6/5/2020", "35"],
                             [<a href= "./SeminarStruct" target="_new"><u>Μετά τα φυσικά</u></a>, "Παύλος Kαλλιγάς", "16/4/2020", "35"],
                             [<a href= "./SeminarStruct" target="_new"><u>Περί κόσμου</u></a>, "Γεράσιμος Κουζέλης", "22/3/2020", "35"],
                           ]}
                         />
                       </CardBody>
                     </Card>



                     </Grid>

                   </Grid>

                 </Grid>
               </Grid>
           </Grid>
       </Grid>
     </Grid>
    </Grid>
    </div>

  );
}
