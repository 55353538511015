import React from 'react';

import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import TagsInput from "react-tagsinput";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import CssBaseline from '@material-ui/core/CssBaseline';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Container from '@material-ui/core/Container';
import ImagesForm from 'views/Images/ImagesForm.js'
/*
https://www.tiny.cloud/docs/integrations/react/
https://www.tiny.cloud/docs/general-configuration-guide/basic-setup/
https://www.tiny.cloud/docs/ui-components/typesoftoolbarbuttons/#
*/
import { Editor } from '@tinymce/tinymce-react';
import LinkToLexiconEntryForm from 'views/Editor/LinkToLexiconEntryForm.js'
import LinkToCommentForm from 'views/Editor/LinkToCommentForm.js'
import LinkToSectionForm from 'views/Editor/LinkToSectionForm.js'
import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'
import { checkNoErrors, replaceFileObjects } from 'utils.js'
import { sortWorksAndBooks } from 'sorting.js'

import { getOneLemma, addLemma, editLemma, addLemmaLink, editLemmaLink,
  getAllLexiconCategories, getAllLemmata } from 'clients/lexiconClient.js'
import { getAllWorks } from 'clients/worksClient.js'
import { getAllSections } from 'clients/sectionsClient.js'
import { lexiconImagesURL } from 'clients/clientConfig.js'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(5),
    margin: '15px',
    maxWidth: 1400,
   /*height: 300,
    width: 600,*/
  },
  inner_form: {
    align: 'left',
    margin: '20px'
  }
}))

const AddLexiconEntry = (props) => {
  const classes = useStyles()
  const htmlEditorRef = React.useRef(null);
  const bibliographyHtmlEditorRef = React.useRef(null)

  const [lemma_id, setLemma_id] = React.useState(0)
  const [title, setTitle] = React.useState('')
  const [content, setContent] = React.useState('')
  const [bibliography, setBibliography] = React.useState('')
  const [tags_1, setTags_1] = React.useState([])
  const [lexicon_links, setLexicon_links] = React.useState([])
  const [keywords, setKeywords] = React.useState([])
  const [sectionsRef, setSectionsRef] = React.useState([])
  const [author, setAuthor] = React.useState('')
  const [category, setCategory] = React.useState('')
  const [categoryInput, setCategoryInput] = React.useState('')
  const [images, setImages] = React.useState([])
  const [is_link, setIs_link] = React.useState(false)
  const [link_to, setLink_to] = React.useState({})
  const [errors, setErrors] = React.useState({})
  const [saving, setSaving] = React.useState(false)

  const [section, setSection] = React.useState({})
  const [refLine, setRefLine] = React.useState('')
  const [sectionErrors, setSectionErrors] = React.useState({})
  const [editingRefIdx, setEditingRefIdx] = React.useState(-1)

// TODO: possibly get as user types
  const [availableTagsWork, setAvailableTagsWork] = React.useState([])
  const [availableSections, setAvailableSections] = React.useState([])
  const [allCategories, setAllCategories] = React.useState([])
  const [availableLemmata, setAvailableLemmata] = React.useState([])

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  //popup for custom buttons of editor
  const [showPopupLinkToEntry, setShowPopupLinkToEntry] = React.useState(false)
  const [showPopupLinkToComment, setShowPopupLinkToComment] = React.useState(false)
  const [showPopupLinkToSection, setShowPopupLinkToSection] = React.useState(false)

  const insertLinkToOtherEntry = (value) => {
    setShowPopupLinkToEntry(false)
    //console.log(value)
    htmlEditorRef.current.insertContent(value)
  }

  const insertLinkToComment = (value) => {
    setShowPopupLinkToComment(false)
    htmlEditorRef.current.insertContent(value)
  }

  const insertLinkToSeciton = (value) => {
    setShowPopupLinkToSection(false)
    htmlEditorRef.current.insertContent(value)
  }

  React.useEffect(() => {
    //console.log(props.match.params.entry_id)
    if (props.match.params.entry_id){
      getOneLemma(props.match.params.entry_id,
        (data) => {
          if (data.lemmata[0]){
            setLemma_id(data.lemmata[0].lemma_id)
            setTitle(data.lemmata[0].title)
            setContent(data.lemmata[0].value)
            setBibliography(data.lemmata[0].bibliography)
            setTags_1(data.lemmata[0].tags_1 || [])
            setSectionsRef(data.lemmata[0].sectionsRef || [])
            setAuthor(data.lemmata[0].author || '')
            setCategory(data.lemmata[0].category || '')
            setLexicon_links(data.lemmata[0].lexicon_links || [])
            setKeywords(data.lemmata[0].keywords || [])
            setImages(data.lemmata[0].images ?
              data.lemmata[0].images.map(i => {return {...i, file: i.filename}})
              : [])//[{title: 'some title', filename: 'image1.jpg', file: 'image1.jpg'}])
            setIs_link(data.lemmata[0].is_link || false)
            setLink_to(data.lemmata[0].link_to || {})
          }
        },
        (err) => console.log(err)
      )
    }
  }, [props.match.params.entry_id])

  React.useEffect(() => {
    getAllWorks(
      (data) => setAvailableTagsWork(sortWorksAndBooks(data.works).map((w) => w.name)),
      (err) => console.log(err)
    )

    getAllSections(
      (data) => setAvailableSections(data.documents),
      (err) => console.log(err)
    )

    getAllLexiconCategories(
      (data) => setAllCategories(data.categories),
      (err) => console.log(err)
    )

    getAllLemmata(
      (data) => setAvailableLemmata(data.lemmata),
      (err) => console.log(err)
    )
  }, [])

  const handleTitleChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.title = (e.target.value==='') ? 'Υποχρεωτικό πεδίο' : ''
    setErrors(newErrors)

    setTitle(e.target.value)
  }

  const handleAuthorChange = (e) => {
    setAuthor(e.target.value)
  }

  const handleCategoryChange = (e, newValue) => {
    //console.log(newValue)
    setCategory(newValue)
  }

  const handleCategoryInputChange = (e, newValue) => {
    //console.log(newValue)
    setCategoryInput(newValue)
  }

  const handleTags1Change = (e, value) => {
    /*let newErrors = Object.assign({}, errors)
    newErrors.tags_1 = (value.length===0) ? 'Υποχρεωτικό πεδίο' : ''
    setErrors(newErrors)*/

    setTags_1(value)
  }

  const handleLexixonLinksChange = (e, value) => {
    //console.log(value)
    setLexicon_links(value.map(v => {
      return {'lemma_id': v.lemma_id, 'title': v.title}
    }))
  }

  const handleChangeKeywords = kw => {
    setKeywords(kw)
  }

  const handleSectionChange = (e, value) => {
    let newErrors = Object.assign({}, sectionErrors)
    newErrors.section = !value || !value.id ? 'Υποχρεωτικό πεδίο' : ''
    setSectionErrors(newErrors)
    //console.log(value)

    setSection(value)
  }

  const handleRefLineChange = (e) => {
    setRefLine(e.target.value)
  }

  const handleIsLinkToggle = (e) => {
    setIs_link(e.target.checked)
  }

  const handleLink_toChange = (e, value) => {
    let newErrors = Object.assign({}, errors)

    if (value){
      setLink_to({'lemma_id': value.lemma_id, 'title': value.title})
      newErrors.link_to = ''
    } else {
      setLink_to({})
      newErrors.link_to = 'Υποχρεωτικό πεδίο';
    }

    setErrors(newErrors)
  }

  //functions for section reference addition/removal
  const addOrEditSectionRef = () => {
    let newErrors = Object.assign({}, sectionErrors)
    newErrors.section = !section || !section.id ? 'Υποχρεωτικό πεδίο' : ''
    setSectionErrors(newErrors)

    if (checkNoErrors(newErrors)){
      const newSectionRef = {id: section.id, work_id: section.work_id, work_name: section.work_name,
        lines: section.lines, refLine: refLine}
      //console.log(newSectionRef)

      if (editingRefIdx < 0){
        const tempSectionRefs = [...sectionsRef, newSectionRef]
        setSectionsRef(tempSectionRefs)
      } else {
        let tempSectionRefs = [...sectionsRef]
        tempSectionRefs[editingRefIdx] = newSectionRef
        setSectionsRef(tempSectionRefs)
      }

      resetSectionRefsFields()

    }
  }

  const resetSectionRefsFields = () => {
    setSection({})
    setRefLine('')
    setEditingRefIdx(-1)
  }

  const prepareToEditSectionRef = (sectionRef, idx) => {
    setSection(sectionRef)
    setRefLine(sectionRef.refLine)
    setEditingRefIdx(idx)
  }

  const removeSectionRef = (idx) => {
    let temp = [...sectionsRef]
    temp.splice(idx, 1)
    setSectionsRef(temp)
  }

  const sectionsRefWithoutWorkName = () => {
    let temp = []
    sectionsRef.forEach(sr => temp.push(Object.assign({}, sr)))
    return temp.map((sr) => {delete sr.work_name; return sr;})
  }

  //form submission
  const handleSubmit = () => {
    setSaving(true)

    if (is_link){
      handleSubmitLinkEntry()
    } else {
      handleSubmitRegularEntry()
    }
  }

  const handleSubmitRegularEntry = () => {
    const newContent = (htmlEditorRef.current) ? htmlEditorRef.current.getContent() : ''
    const newBibliography = (bibliographyHtmlEditorRef.current) ? bibliographyHtmlEditorRef.current.getContent() : ''

    //validation
    const newErrors = validateAll(newContent)
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      let params = {title: title, value: newContent, tags_1: tags_1, bibliography: newBibliography,
        sectionsRef: sectionsRefWithoutWorkName(), author: author, category: category || '',
        lexicon_links: lexicon_links, keywords: keywords, images: replaceFileObjects(images)}
      if (lemma_id){
        params.lemma_id = lemma_id
      }
      let newFiles = images
        .filter(i => (i.file instanceof File))
        .map(i => i.file)
      /*console.log(params)
      console.log(newFiles)
      setSaving(false)*/

      if (lemma_id){
        editLemma(newFiles, params,
          () => window.location.href='/admin/Lexicon/'+lemma_id,
          (err) => {
            setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
            setSaving(false)
          }
        )

      } else {
        addLemma(newFiles, params,
          (data) => window.location.href='/admin/Lexicon/'+data.inserted_id,
          (err) => {
            setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
            setSaving(false)
          }
        )
      }
    } else {
      setSaving(false)
    }
  }

  const handleSubmitLinkEntry = () => {
    //validation
    const newErrors = validateAllForLink()
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      let params = {title: title, link_to: link_to}
      if (lemma_id){
        params.lemma_id = lemma_id
      }
      console.log(params)
      /*setSaving(false)*/

      if (lemma_id){
        editLemmaLink(params,
          () => window.location.href='/admin/Lexicon/'+lemma_id,
          (err) => {
            setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
            setSaving(false)
          }
        )

      } else {
        addLemmaLink(params,
          (data) => window.location.href='/admin/Lexicon/'+data.inserted_id,
          (err) => {
            setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
            setSaving(false)
          }
        )
      }
    } else {
      setSaving(false)
    }
  }

  const validateAll = (content) => {
    let newErrors = Object.assign({}, errors)
    newErrors.title = (title==='') ? 'Υποχρεωτικό πεδίο' : '';
    newErrors.content = (content==='') ? 'Υποχρεωτικό πεδίο' : '';
    //newErrors.tags_1 = (tags_1.length===0) ? 'Υποχρεωτικό πεδίο' : ''

    return newErrors
  }

  const validateAllForLink = () => {
    let newErrors = {}
    newErrors.title = (title==='') ? 'Υποχρεωτικό πεδίο' : '';
    newErrors.link_to = !(link_to && link_to.lemma_id) ? 'Υποχρεωτικό πεδίο' : '';

    return newErrors
  }

  const handleCancel = () => {
    if (lemma_id){
      window.location.href='/admin/Lexicon/'+lemma_id
    } else {
      window.location.href='/admin/Lexicon/'
    }
  }

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <GridContainer className={classes.root} spacing={2}>
        <GridItem xs={12} >
          <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color="inherit" href="/admin/Lexicon">
                Λεξικό εννοιών
              </Link>
              <Link color="inherit" href={"/admin/Lexicon/AddLexiconEntry/"+(lemma_id > 0 ? lemma_id : '')}>
                {lemma_id ? 'Επεξεργασία λήμματος' : 'Εισαγωγή νέου λήμματος'}
              </Link>
          </Breadcrumbs>
          </div>
        </GridItem>
        <GridItem  xs={12} style={{ paddingTop : '20px'}}>
          <Paper className={classes.paper}>
            <Typography variant='h4'>{lemma_id ? 'Επεξεργασία λήμματος' : 'Εισαγωγή νέου λήμματος'}</Typography>

            <TextField
             id="outlined-helperText"
             error={Boolean(errors.title)}
             label='Τίτλος'
             style = {{width:500, paddingTop:10, marginTop:0}}
             value={title}
             onChange={handleTitleChange}
             />
             <FormHelperText error>{errors.title}</FormHelperText>

             <FormControlLabel control={
               <Checkbox size='small' checked={is_link} onChange={handleIsLinkToggle} />
             } label='Παραπεμπτικό λήμμα'/>
             <br/>

             {
               is_link ?
               <>
               <Autocomplete
                 id="lexicon-link-to"
                 options={availableLemmata.sort((a,b) => -b.title.localeCompare(a.title))}
                 getOptionLabel={(option) => option.title || ''}
                 getOptionSelected={(option, value) => value.lemma_id ? option.lemma_id === value.lemma_id : false}
                 onChange={handleLink_toChange}
                 value={link_to}
                 renderInput={(params) => (
                   <TextField
                     {...params}
                     variant="standard"
                     label="Λήμμα στο οποίο παραπέμπει"
                     placeholder="Λήμμα στο οποίο παραπέμπει"
                     error={Boolean(errors.link_to)}
                     helperText={errors.link_to}
                   />
                 )}
               />
               </>

               :

               <>
               <TextField
                id="outlined-helperText"
                error={Boolean(errors.author)}
                label='Συντάκτης/τρια'
                style = {{width:500, paddingTop:10, marginTop:0}}
                value={author}
                onChange={handleAuthorChange}
                />
                <FormHelperText error>{errors.author}</FormHelperText>

                <Autocomplete
                  id="free-solo-categories"
                  freeSolo autoComplete
                  options={allCategories.map((option) => option.title)}
                  value={category}
                  inputValue={categoryInput}
                  onChange={handleCategoryChange}
                  onInputChange={handleCategoryInputChange}
                  autoSelect
                  renderInput={(params) =>
                    <TextField {...params}
                    variant="standard"
                    label="Θεματική Ενότητα"
                    placeholder="Θεματική Ενότητα"
                    error={Boolean(errors.category)}
                    helperText={errors.category}
                    />}
                />

               <FormLabel>Κείμενο</FormLabel>
               <Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                  onInit={(evt, editor) => htmlEditorRef.current = editor}
                  initialValue={content}
                  init={{
                    height: 500,
                    menubar: false,
                    entity_encoding : "raw",
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                    'bold italic forecolor backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | addlinktolemma | addlinktocomment | addlinktosection | help',
                    //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    setup: function(editor) {
                       editor.ui.registry.addButton('addlinktolemma', {
                         text: 'Link σε λήμμα',
                         onAction: () => setShowPopupLinkToEntry(true),
                       });
                       editor.ui.registry.addButton('addlinktocomment', {
                         text: 'Link σε σχόλιο',
                         onAction: () => setShowPopupLinkToComment(true),
                       });
                       editor.ui.registry.addButton('addlinktosection', {
                         text: 'Link σε χωρίο',
                         onAction: () => setShowPopupLinkToSection(true),
                       });
                     }
                  }}
                />
                <FormHelperText error>{errors.content}</FormHelperText>

                <FormLabel>Βιβλιογραφία</FormLabel>
                <Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                   onInit={(evt, editor) => bibliographyHtmlEditorRef.current = editor}
                   initialValue={bibliography}
                   init={{
                     height: 300,
                     menubar: false,
                     entity_encoding : "raw",
                     plugins: [
                       'advlist autolink lists link image charmap print preview anchor',
                       'searchreplace visualblocks code fullscreen',
                       'insertdatetime media table paste code help wordcount'
                     ],
                     toolbar: 'undo redo | formatselect | ' +
                     'bold italic forecolor backcolor | alignleft aligncenter ' +
                     'alignright alignjustify | bullist numlist outdent indent | ' +
                     'link removeformat | help',
                     //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                   }}
                 />
                 <FormHelperText error>{errors.bibliography}</FormHelperText>

                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={availableTagsWork}
                  //getOptionLabel={(option) => option.title}
                  onChange={handleTags1Change}
                  value={tags_1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Συνδεδεμένα κείμενα"
                      placeholder="Συνδεδεμένα κείμενα"
                      error={Boolean(errors.tags_1)}
                      helperText={errors.tags_1}
                    />
                  )}
                />
                <br/>

                <Autocomplete
                  multiple
                  id="lexicon-links-standard"
                  options={availableLemmata.sort((a,b) => -b.title.localeCompare(a.title))}
                  getOptionLabel={(option) => option.title}
                  getOptionSelected={(option, value) => option.lemma_id === value.lemma_id}
                  onChange={handleLexixonLinksChange}
                  value={lexicon_links}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Συνδεδεμένα λήμματα"
                      placeholder="Συνδεδεμένα λήμματα"
                      error={Boolean(errors.lexicon_links)}
                      helperText={errors.lexicon_links}
                    />
                  )}
                />
                <br/>

                <FormLabel error={Boolean(errors.keywords)}>Λέξεις-κλειδιά (πληκτρολογήστε και πατήστε Enter)</FormLabel>
                <br/>
                <TagsInput
                  value={keywords}
                  onChange={handleChangeKeywords}
                  tagProps={{ className: "react-tagsinput-tag info"}}
                  inputProps={{placeholder: 'Προσθήκη'}}
                />
                <FormHelperText error>{errors.keywords}</FormHelperText>

                <FormLabel>Χωρία</FormLabel>
                <Container maxWidth='xs' className={classes.inner_form}>
                  <Autocomplete
                    id="sections"
                    options={availableSections.sort((a,b) => -b.work_name.localeCompare(a.work_name))}
                    groupBy={(option) => option.work_name}
                    getOptionLabel={(option) => option.lines || ''}
                    getOptionSelected={(option, value) => option.id === value.id}
                    style={{ width: 300 }}
                    value={section}
                    onChange={handleSectionChange}
                    renderInput={(params) =>
                      <TextField {...params}
                        label="Αριθμός χωρίου"
                        variant="standard"
                        error={Boolean(sectionErrors.section)}
                        helperText={sectionErrors.section}
                      />}
                  />

                  <TextField
                    label="Στίχος/οι"
                    variant="standard"
                    error={Boolean(sectionErrors.refLine)}
                    style = {{paddingTop:10, marginTop:0}}
                    value={refLine}
                    onChange={handleRefLineChange}
                  />
                  <FormHelperText error>{sectionErrors.refLine}</FormHelperText>

                  <Button onClick={addOrEditSectionRef} size='sm'>Προσθηκη</Button>
                  <Button onClick={resetSectionRefsFields} size='sm'>Καθαρισμος</Button>
                </Container>

                <List dense>
                  {sectionsRef && sectionsRef.map((section, idx) =>
                    <ListItem key={idx}>
                      {section.work_name + ', ' + section.lines + ' ' + section.refLine}
                      <IconButton aria-label="edit" onClick={() => prepareToEditSectionRef(section, idx)}>
                        <EditIcon fontSize="small"/>
                      </IconButton>
                      <IconButton aria-label="delete" onClick={() => removeSectionRef(idx)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ListItem>
                  )}
                </List>

                <ImagesForm baseUrl={lexiconImagesURL + '/' + lemma_id + '/'} images={images} onSave={setImages}/>
               </>
             }

             <Button onClick={handleSubmit} color='rose' disabled={saving}>Καταχωριση</Button>
             <Button onClick={handleCancel}>Ακύρωση</Button>

             {alert}

             <LinkToLexiconEntryForm onClose={()=>setShowPopupLinkToEntry(false)}
                onSave={insertLinkToOtherEntry} open={showPopupLinkToEntry}/>

             <LinkToCommentForm onClose={()=>setShowPopupLinkToComment(false)}
                onSave={insertLinkToComment} open={showPopupLinkToComment}/>

             <LinkToSectionForm onClose={()=>setShowPopupLinkToSection(false)}
                onSave={insertLinkToSeciton} open={showPopupLinkToSection} sections={availableSections}/>
            </Paper>
          </GridItem>
        </GridContainer>
    </div>
  )
}

export default AddLexiconEntry
