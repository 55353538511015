import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
//import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";

import 'global.js';

//import Sidebar from "components/Sidebar/Sidebar.js";
//import SidebarShort from "components/Sidebar/SidebarShort.js";

//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";


let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        const path = prop.layout + prop.path
        return (
          prop.access ?
            <Route
              path={path}
              component={prop.component}
              key={key}
            /> :
            <Redirect key={key} from={path} to="/admin/ForbiddenAccess"/>
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/SelectTransSem" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {

// check if user is logged => redirect to login
//alert(localStorage.getItem('UserName'));
var UserPage = window.location.href;

var LoginPage = '/SignIn';
var RegisterPage = '/SignUp';

//alert(UserPage.indexOf(LoginPage, UserPage.length - LoginPage.length) == -1);
//alert(localStorage.getItem('UserName'));
// if user has not been logged and the page currently accessed is not the Login Page and is not the RegisterPage
  //if (!localStorage.getItem('UserName'))  {
    //if (UserPage.indexOf(LoginPage, UserPage.length - LoginPage.length) == -1 && UserPage.indexOf(RegisterPage, UserPage.length - RegisterPage.length) == -1)
    //  window.location.href = "./SignIn";
  //}



  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
  setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
    {/*  <Sidebar/> */}

      <div className={classes.mainPanel} ref={mainPanel}>
    {/*   <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />*/}
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div id="central_content" className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div id="central_content" className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}

      </div>
    </div>
  );
}
