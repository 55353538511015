import { fetchWithGET, fetchWithMethod, fetchWithFile } from './clientUtils.js'

function addScholarComment(files, vals, success, errorFunc){
  return fetchWithFile('comments', 'POST', files, vals, success, errorFunc)
}

function editScholarComment(files, vals, success, errorFunc){
  return fetchWithFile('comments', 'PUT', files, vals, success, errorFunc)
}

function deleteScholarComment(id, success, errorFunc){
  return fetchWithMethod('comments', 'DELETE', {comment_id: id}, success, errorFunc)
}

function getComments(params, success, errorFunc){
  let querystring = '?'
  if (params){
    if (params.work_id){
      querystring += 'work_id=' + params.work_id + '&'
    }
    if (params.book_id){
      querystring += 'book_id=' + params.book_id + '&'
    }
    if (params.doc_id){
      querystring += 'doc_id=' + params.doc_id + '&'
    }
  }

  return fetchWithGET('comments', querystring, success, errorFunc)
}

function uploadScholarComments(file, success, errorFunc){
  //return success({message:''})
  return fetchWithFile('comments_from_file', 'POST', file, [], success, errorFunc)
}

function getCommentsByTag(tag, success, errorFunc){
  return fetchWithGET('comments/'+tag, '', success, errorFunc)
}

export { addScholarComment, editScholarComment, deleteScholarComment, getComments, uploadScholarComments, getCommentsByTag }
