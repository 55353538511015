import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse'
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'

import FilterTree from './FilterTree.js'
import SearchForm from './SearchForm.js'
import SearchResults from './SearchResults.js'

import { searchConcordance } from 'clients/concordanceClient.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
}))

const Concordance = (props) => {
  const classes = useStyles()

  const [searchFilter, setSearchFilter] = React.useState([])
  const [searchParams, setSearchParams] = React.useState({})
  const [results, setResults] = React.useState([])
  const [searching, setSearching] = React.useState(false)
  const [showInstructions, setShowInstructions] = React.useState(false);

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  const handleInstructionsClick = () => {
    setShowInstructions(!showInstructions)
  }

  const handleFilter = (selected) => {
    setSearchFilter(selected)
    if (Object.keys(searchParams).length > 0){
      applySearch({...searchParams, works: selected})
    }
  }

  const handleSearch = (params) => {
    setSearchParams(params)
    applySearch({...params, works: searchFilter})
  }

  const applySearch = (params) => {
    //console.log(params)
    setSearching(true)

    searchConcordance(params,
      (data) => {
        setSearching(false)
        setResults(data.concordance)
      },
      (err) => {
        setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
        setResults([])
        setSearching(false)
      }
    )
    /*setResults([
      {term: 'πράξεσιν', occurences: [
        {section_code: 'ΑΡΙΣΤ ΗΝικ 1099a', section_id: '1-1-1', text:'ἀγαθὸς ὁ μὴ χαίρων ταῖς καλαῖς πράξεσιν·οὔτε γὰρ δίκαιον οὐθεὶς ἂν εἴποι τὸν μὴ'},
        {section_code: 'ΑΡΙΣΤ ΗΝικ 1099a', section_id: '1-1-1', text:'τὸν μὴ χαίροντα ταῖς ἐλευθερίοις πράξεσιν·ὁμοίως δὲ καὶ ἐπὶ τῶν ἄλλων. εἰ δ᾽'},
        ]},
      {term: 'πράξεων', occurences: [
        {section_code: 'ΑΡΙΣΤ ΗΝικ 1094a', section_id: '2-2-1', text:'τῶν ἐνεργειῶν τὰ ἔργα. πολλῶν δὲ πράξεων οὐσῶν καὶ τεχνῶν καὶ ἐπιστημῶν πολλὰ'},
        {section_code: 'ΑΡΙΣΤ ΗΝικ 1095a', section_id: '1-1-2', text:'νέος· ἄπειρος γὰρ τῶν κατὰ τὸν βίον πράξεων,οἱ λόγοι δ᾽ ἐκ τούτων καὶ περὶ'},
        {section_code: 'ΑΡΙΣΤ ΗΝικ 1095a', section_id: '2-1-1', text:'κάτι άλλο με πράξεων, οἱ λόγοι δ᾽ ἐκ τούτων καὶ περὶ'},
      ]},
      {term: 'πράξεως', occurences: [{section_code: 'ΑΡΙΣΤ ΗΝικ 1120a', section_id: '2-1-2', text:'γὰρ ἕλοιτ᾽ ἂν τὰ χρήματα τῆς καλῆς πράξεως,τοῦτο δ᾽ οὐκ ἐλευθερίου. οὐδὲ'}]},
    ])*/
  }

  /*React.useEffect(() => {
    if (props.match.params.search){
      const params = {searchTerm: props.match.params.search, exact_search: true, with_accents: false}
      setSearchParams(params)
      applySearch({...params, filter: searchFilter})
    }
  }, [props.match.params.search])*/

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} >
          <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link color="inherit" href="/admin/SelectTransSem">
                  Main
                </Link>
                <Typography color="textPrimary">Συμφραστικός Πίνακας Λέξεων</Typography>
            </Breadcrumbs>
          </div>
        </Grid>

       <Grid id="searchflex" item container xs={12} style={{ paddingTop : '20px'}}>
          <Grid item xs={3}>
            <Typography variant='h5'>Φίλτρα αναζήτησης</Typography>
            <FilterTree onApplyFilter={handleFilter}/>
          </Grid>
          <Grid item xs={9}>
            <Typography variant='h5'>
              Αναζήτηση ΑΕ λέξεων&nbsp;
              <Link variant='body2' href='#' onClick={handleInstructionsClick}>
                {
                  showInstructions ?
                  <>'Κλείσιμο'<CloseIcon/></> :
                  <>Οδηγίες<InfoIcon/></>
                }
              </Link>
            </Typography>

            <Collapse id="searchinstructions" in={showInstructions} timeout="auto" unmountOnExit>
              <Typography variant='h6' component='div'>Οδηγίες αναζήτησης λέξεων</Typography>
              <Typography variant='body1' component='div'>
              Η αναζήτηση κανονικά γίνεται <b>ατονικά</b> (δηλ. χωρίς την χρήση τόνων και πνευμάτων),
              αλλά και ανεξάρτητα από τη χρήση κεφαλαίων ή πεζών χαρακτήρων.
              <ul>
                <li>Αν θέλουμε αναζήτηση με τόνους και πνεύματα, επιλέγουμε το κουμπί «Ο τονισμός είναι σημαντικός».
                [ΠΡΟΣΟΧΗ: Γίνεται διάκριση ανάμεσα σε οξεία (όχι σημάδι τόνου) / βαρεία.]</li>
                <li>Αν θέλουμε αναζήτηση με τόνους και πνεύματα <b>ΚΑΙ</b> διάκριση πεζών-κεφαλαίων, επιλέγουμε το κουμπί «Ακριβής αναζήτηση».</li>
              </ul>
              </Typography>
              <Typography variant='subtitle2' component='div'>Αναζήτηση λήμματος</Typography>
              <Typography variant='body1' component='div'>
              <ul>
                <li>αγωγ ή αγωγ*: Αναζητά λέξεις που ξεκινούν με <b>αγωγ</b>.</li>
                <li>*ικος: Αναζητά λέξεις που τελειώνουν σε <b>ικος</b>.</li>
                <li>*δημ*: Αναζητά λέξεις που περιέχουν <b>δημ</b>.</li>
                <li>δ*ος: Αναζητά λέξεις που ξεκινούν με <b>δ</b> και τελειώνουν σε <b>ος</b>.</li>
                <li>&quot;μίμησις πράξεως&quot;: Αναζητά τη συγκεκριμένη φράση «μίμησις πράξεως» μέσα σε ένα όριο λέξεων (&plusmn; 15 πριν και 15 μετά)</li>
              </ul>
              </Typography>
              <Typography variant='subtitle2' component='div'>Λογικοί τελεστές</Typography>
              <Typography variant='body1' component='div'>
              <ul>
                <li>AND ή &amp;: Λογική σύζευξη. Α &amp; Β &#8594; Αναζητά χωρία που περιέχουν ΚΑΙ το Α ΚΑΙ το Β.</li>
                <li>OR: Λογική διάζευξη. A OR B &#8594; Αναζητά χωρία που περιέχουν το Ή το Α Ή το Β Ή και τα δύο.</li>
                <li>NOT: Λογική άρνηση. Α ΝΟΤ Β &#8594; Αναζητά χωρία που περιέχουν το Α, αλλά όχι το Β. </li>
              </ul>
              </Typography>
              <Typography variant='subtitle2' component='div'>Φίλτρα</Typography>
              <Typography variant='body1'>
              Μπορεί να γίνει αναζήτηση μόνο σε ορισμένα από τα έργα του Αριστοτέλη (επιλέγοντας όποιο/όποια θέλουμε από τη δενδρική δομή στα αριστερά και πατώντας ΕΦΑΡΜΟΓΗ).
              </Typography>
            </Collapse>

            <SearchForm onSearch={handleSearch} searchTerm={props.match.params.search}/>
            <hr/>

            <Typography variant='h5'>Αποτελέσματα</Typography>
            {
              searching ?
              <Typography component='div'>
                Εκτελείται αναζήτηση, παρακαλούμε περιμένετε...<CircularProgress/>
              </Typography> :
              <SearchResults results={results}/>
            }
          </Grid>
       </Grid>
      </Grid>
      {alert}
    </div>
  )
}

export default Concordance
