import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Sidebar from "components/Sidebar/Sidebar.js";


import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import BlogIcon from '@material-ui/icons/FormatBold';
import WikiIcon from '@material-ui/icons/Search';
import SeminarIcon from '@material-ui/icons/PeopleAlt';

import Avatar from '@material-ui/core/Avatar';

import WikiCircle from '@material-ui/icons/Language';
import BlogCircle from '@material-ui/icons/FormatBold';
import { green } from '@material-ui/core/colors';
import { blue } from '@material-ui/core/colors';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Box from '@material-ui/core/Box';

import SeminarImg from "images/seminar.jpg";
import pdfIcon from "images/pdf.svg";
import wordIcon from "images/word.svg";
import excelIcon from "images/excel.svg";
import textIcon from "images/text.svg";
import videoIcon from "images/video.svg";
import dropboxIcon from "images/dropbox.svg";
import EventNoteIcon from '@material-ui/icons/EventNote';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },


    exampleWrapper: {
      position: 'relative',
      marginTop: theme.spacing(0),
      height: 80,
    },

    speedDial: {
      position: 'absolute',
      '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
    BlogIconCol: {
      backgroundColor: green[100],
      color: green[600],
    },
    WikiIconCol: {
      backgroundColor: blue[100],
      color: blue[600],
    },
	pdfIconCol: {
      backgroundColor: "#AB1C22",
    },
	wordIconCol: {
      backgroundColor: "#295392",
    },
	excelIconCol: {
      backgroundColor: "#026E39",
    },

	textIconCol: {
      backgroundColor: "#77B9C5",
    },
	videoIconCol: {
      backgroundColor: "#F7BB27",
    },
	dropboxIconCol: {
      backgroundColor: "#007ADE",
    },
	AgendaIconStyle: {
      display:"block",
	  float:"right",
	  color:"#fff !important",
	  marginTop:"-45px",
	  border:"2px solid #fff",
	  borderRadius:"10px",
	  padding:"3px"
   },
   
   cardCategoryWhite: {
	   marginTop:"8px !important"
   },




}));


const actions = [
  { icon: <BlogIcon />, name: 'Προσθήκη Blog', id:1 },
  { icon: <WikiIcon />, name: 'Προσθήκη Wiki', id:2 },
  { icon: <SeminarIcon />, name: 'Προσθήκη Σεμιναρίου', id:3 },
];

export default function MiniDrawer() {

  const [direction, setDirection] = React.useState('right');
  const [hidden, setHidden] = React.useState(false);

  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const handleHiddenChange = (event) => {
    setHidden(event.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const NewBlogWiki = (e, id) => {

    e.preventDefault();
    if(id=="1"){
      // do something
    }else if(id=="2"){
      //do something else
    }else if(id=="3"){
      window.location.href = "./AddSeminar";
    }
    setOpen(!open);
  };



  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [spacing, setSpacing] = React.useState(2);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  return (

    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

    <Grid container className={classes.root} spacing={2}>
    <Grid item xs={12} >
      <div>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/admin/SelectTransSem">
            Main
          </Link>

          <Link color="inherit" href="/admin/Seminars">
            Σεμινάρια
          </Link>
          <Link color="inherit" >
            Περί γενέσεως και φθοράς
          </Link>
      </Breadcrumbs>
      </div>
    </Grid>
     <Grid item xs={12} style={{ paddingTop : '20px'}}>
       <Grid container justify="center" spacing={spacing}>
           <Grid>
               <Grid container spacing={2}>
                 <Grid item xs={12} sm container>
                   <Grid item xs container direction="column" spacing={2}>
                     <Grid item xs>

                     <Card  >
                       <CardHeader color="primary">

                         <Box  display="flex" flexDirection="row">
                             <Box fontWeight={700} m={1} className={classes.cardContentTrans}>
                                   <Avatar className={classes.WikiIconCol}>
                                   <SeminarIcon />
                                   </Avatar>
                               </Box>
                               <Box  m={1} className={classes.cardContentTrans}>
                                   {/*<h4 className={classes.cardTitleWhite}>Blogs</h4> */}
                                   <p className={classes.cardCategoryWhite}>
                                   Blogs/Wikis Σεμιναρίου
                                   </p>
                               </Box>
                         </Box>
						 
						 <IconButton href="http://150.140.90.140/wiki/index.php/Agenda" title="Η Ατζέντα του σεμιναρίου" className={classes.AgendaIconStyle}> 
							<EventNoteIcon/>
						</IconButton>
						
						
                       </CardHeader>
                       <CardBody>
                         <Table
                           tableHeaderColor="primary"
                           tableHead={["Τύπος", "Τίτλος", "Δημιουργός", "Ημερομηνία", "Αναρτήσεις"]}
                           tableData={[
                             [<Avatar className={classes.BlogIconCol}><BlogCircle /></Avatar>,<a href= "./Blogs" target="_new"><u>Περί γενέσεως και φθοράς</u></a>, "Γιώργος Βάρσος", "19/10/2020", "35"],
                             [<Avatar className={classes.BlogIconCol}><BlogCircle /></Avatar>,<a href= "./Blogs" target="_new"><u>Φυσική ακρόαση</u></a>, "Ηλίας Γεωργούλας", "17/9/2020", , "35"],
                             [<Avatar className={classes.WikiIconCol}><WikiCircle /></Avatar>,<a href= "./Wikis" target="_new"><u>Μετεωρολογικά</u></a>, "Βάλια Δημοπούλου", "6/5/2020", "35"],
                             [<Avatar className={classes.BlogIconCol}><BlogCircle /></Avatar>,<a href= "./Blogs" target="_new"><u>Μετά τα φυσικά</u></a>, "Παύλος Kαλλιγάς", "16/4/2020", "35"],
                             [<Avatar className={classes.WikiIconCol}><WikiCircle /></Avatar>,<a href= "./Wikis" target="_new"><u>Περί κόσμου</u></a>, "Γεράσιμος Κουζέλης", "22/3/2020", "35"],
                             [<Avatar className={classes.BlogIconCol}><BlogCircle /></Avatar>,<a href= "./Blogs" target="_new"><u>Περί γενέσεως και φθοράς</u></a>, "Γιώργος Βάρσος", "19/10/2020", "35"],
                             [<Avatar className={classes.WikiIconCol}><WikiCircle /></Avatar>,<a href= "./Wikis" target="_new"><u>Φυσική ακρόαση</u></a>, "Ηλίας Γεωργούλας", "17/9/2020", , "35"],
                             [<Avatar className={classes.WikiIconCol}><WikiCircle /></Avatar>,<a href= "./Wikis" target="_new"><u>Μετεωρολογικά</u></a>, "Βάλια Δημοπούλου", "6/5/2020", "35"],
                             [<Avatar className={classes.BlogIconCol}><BlogCircle /></Avatar>,<a href= "./Blogs" target="_new"><u>Μετά τα φυσικά</u></a>, "Παύλος Kαλλιγάς", "16/4/2020", "35"],
                             [<Avatar className={classes.BlogIconCol}><BlogCircle /></Avatar>,<a href= "./Blogs" target="_new"><u>Περί κόσμου</u></a>, "Γεράσιμος Κουζέλης", "22/3/2020", "35"],
                           ]}
                         />
                       </CardBody>
                     </Card>



                     </Grid>

                   </Grid>

                 </Grid>




               </Grid>
           </Grid>
       </Grid>
     </Grid>


     <Grid item xs={12} style={{ paddingTop : '20px'}}>
       <Grid container justify="center" spacing={spacing}>
           <Grid>
               <Grid container spacing={2}>
                 <Grid item xs={12} sm container>
                   <Grid item xs container direction="column" spacing={2}>
                     <Grid item xs>

                     <Card  >
                       <CardHeader color="primary">

                         <Box  display="flex" flexDirection="row">
                             <Box fontWeight={700} m={1} className={classes.cardContentTrans}>
                                   <Avatar className={classes.dropboxIconCol}>
                                    <img style={{width:25, height:25}} src={dropboxIcon} alt="..." />
                                   </Avatar>
                               </Box>
                               <Box  m={1} className={classes.cardContentTrans}>
                                   {/*<h4 className={classes.cardTitleWhite}>Blogs</h4> */}
                                   <p className={classes.cardCategoryWhite}>
                                   Αποθετήριο Σεμιναρίου
                                   </p>
                               </Box>
                         </Box>
                       </CardHeader>
                       <CardBody>

                         {/*<Table
                           tableHeaderColor="primary"
                           tableHead={["Τύπος", "Τίτλος αρχείου", "Δημιουργός", "Ημερομηνία", "Περιγραφή"]}
                           tableData={[
                             [<Avatar className={classes.pdfIconCol}><img style={{width:20, height:20}} src={pdfIcon} alt="..." /></Avatar>,<a href= "https://bit.ly/2ry9lCK" target="_new"><u>Περί Ψυχής</u></a>, "Γιώργος Βάρσος", "19/10/2020", "“Περί Ψυχής”- Σύγγραμμα του Αριστοτέλη (Μετάφραση)"],
                             [<Avatar className={classes.videoIconCol}><img style={{width:20, height:20}} src={videoIcon} alt="..." /></Avatar>,<a href= "https://www.youtube.com/watch?v=0AWMr5gHL_E" target="_new"><u>Αριστοτέλης και Συναισθηματική Νοημοσύνη</u></a>, "Γεράσιμος Κουζέλης", "22/3/2020", "ΦΡΥΚΤΩΡΙΕΣ: διάλεξη 'ΑΡΙΣΤΟΤΕΛΗΣ ΚΑΙ ΣΥΝΑΙΣΘΗΜΑΤΙΚΗ ΝΟΗΜΟΣΥΝΗ'."],
                              [<Avatar className={classes.wordIconCol}><img style={{width:20, height:20}} src={wordIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Φυσική ακρόαση</u></a>, "Ηλίας Γεωργούλας", "17/9/2020", , "“Περί Ψυχής”- Σύγγραμμα του Αριστοτέλη (Μετάφραση)"],
                              [<Avatar className={classes.excelIconCol}><img style={{width:20, height:20}} src={excelIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Μετεωρολογικά</u></a>, "Βάλια Δημοπούλου", "6/5/2020", "“Περί Ψυχής”- Σύγγραμμα του Αριστοτέλη (Μετάφραση)"],
                              [<Avatar className={classes.textIconCol}><img style={{width:20, height:20}} src={textIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Μετά τα φυσικά</u></a>, "Παύλος Kαλλιγάς", "16/4/2020", "“Περί Ψυχής”- Σύγγραμμα του Αριστοτέλη (Μετάφραση)"],
                             [<Avatar className={classes.pdfIconCol}><img style={{width:20, height:20}} src={pdfIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Περί γενέσεως και φθοράς</u></a>, "Γιώργος Βάρσος", "19/10/2020", "“Περί Ψυχής”- Σύγγραμμα του Αριστοτέλη (Μετάφραση)"],
                             [<Avatar className={classes.wordIconCol}><img style={{width:20, height:20}} src={wordIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Φυσική ακρόαση</u></a>, "Ηλίας Γεωργούλας", "17/9/2020", , "“Περί Ψυχής”- Σύγγραμμα του Αριστοτέλη (Μετάφραση)"],
                             [<Avatar className={classes.pdfIconCol}><img style={{width:20, height:20}} src={pdfIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Μετεωρολογικά</u></a>, "Βάλια Δημοπούλου", "6/5/2020", "“Περί Ψυχής”- Σύγγραμμα του Αριστοτέλη (Μετάφραση)"],
                             [<Avatar className={classes.wordIconCol}><img style={{width:20, height:20}} src={wordIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Μετά τα φυσικά</u></a>, "Παύλος Kαλλιγάς", "16/4/2020", "“Περί Ψυχής”- Σύγγραμμα του Αριστοτέλη (Μετάφραση)"],
                             [<Avatar className={classes.textIconCol}><img style={{width:20, height:20}} src={textIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Περί κόσμου</u></a>, "Γεράσιμος Κουζέλης", "22/3/2020", "“Περί Ψυχής”- Σύγγραμμα του Αριστοτέλη (Μετάφραση)"],
                           ]}
                         /> */}
                         <Table
                           tableHeaderColor="primary"
                           tableHead={["Τύπος", "Τίτλος αρχείου", "Δημιουργός", "Ημερομηνία"]}
                           tableData={[
 [<Avatar className={classes.pdfIconCol}><img style={{width:20, height:20}} src={pdfIcon} alt="..." /></Avatar>,<a href= "https://repository.kallipos.gr/bitstream/11419/683/1/00.%209965_master_document.pdf" target="_new"><u>Η φιλοσοφία του Αριστοτέλη</u></a>, "Βασίλης Κάλφας", "19/10/2020"],
 [<Avatar className={classes.pdfIconCol}><img style={{width:20, height:20}} src={pdfIcon} alt="..." /></Avatar>,<a href= "https://eclass.uoa.gr/modules/document/file.php/PSPA280/%CE%93.%CE%9A.%20%CE%95%CE%B9%CE%BA%CE%BF%CC%81%CE%BD%CE%B1%20%CE%B5%CF%80%CE%B9%CF%83%CF%84%CE%B7%CC%81%CE%BC%CE%B7%CF%82%20%CE%95%CE%B9%CF%83%CE%B1%CE%B3%CF%89%CE%B3%CE%B9%CE%BA%CE%B1%CC%81.pdf" target="_new"><u>Έκθεση και επιχειρηµατολογία</u></a>, "Γεράσιμος Κουζέλης", "19/10/2020"],

 [<Avatar className={classes.videoIconCol}><img style={{width:20, height:20}} src={videoIcon} alt="..." /></Avatar>,<a href= "https://www.youtube.com/watch?v=Jyp203LHPzs&feature=youtu.be" target="_new"><u>Από τον Θαλή στον Αριστοτέλη</u></a>, "Βασίλης Κάλφας", "22/3/2020"],

                             [<Avatar className={classes.pdfIconCol}><img style={{width:20, height:20}} src={pdfIcon} alt="..." /></Avatar>,<a href= "https://bit.ly/2ry9lCK" target="_new"><u>Περί Ψυχής</u></a>, "Γιώργος Βάρσος", "19/10/2020"],
                             [<Avatar className={classes.videoIconCol}><img style={{width:20, height:20}} src={videoIcon} alt="..." /></Avatar>,<a href= "https://www.youtube.com/watch?v=0AWMr5gHL_E" target="_new"><u>Αριστοτέλης και Συναισθηματική Νοημοσύνη</u></a>, "Γεράσιμος Κουζέλης", "22/3/2020"],
                              [<Avatar className={classes.wordIconCol}><img style={{width:20, height:20}} src={wordIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Φυσική ακρόαση</u></a>, "Ηλίας Γεωργούλας", "17/9/2020"],
                              [<Avatar className={classes.excelIconCol}><img style={{width:20, height:20}} src={excelIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Μετεωρολογικά</u></a>, "Βάλια Δημοπούλου", "6/5/2020"],
                              [<Avatar className={classes.textIconCol}><img style={{width:20, height:20}} src={textIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Μετά τα φυσικά</u></a>, "Παύλος Kαλλιγάς", "16/4/2020"],
                             [<Avatar className={classes.pdfIconCol}><img style={{width:20, height:20}} src={pdfIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Περί γενέσεως και φθοράς</u></a>, "Γιώργος Βάρσος", "19/10/2020"],
                             [<Avatar className={classes.wordIconCol}><img style={{width:20, height:20}} src={wordIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Φυσική ακρόαση</u></a>, "Ηλίας Γεωργούλας", "17/9/2020"],
                             [<Avatar className={classes.pdfIconCol}><img style={{width:20, height:20}} src={pdfIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Μετεωρολογικά</u></a>, "Βάλια Δημοπούλου", "6/5/2020"],
                             [<Avatar className={classes.wordIconCol}><img style={{width:20, height:20}} src={wordIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Μετά τα φυσικά</u></a>, "Παύλος Kαλλιγάς", "16/4/2020"],
                             [<Avatar className={classes.textIconCol}><img style={{width:20, height:20}} src={textIcon} alt="..." /></Avatar>,<a href= "./SeminarStruct" target="_new"><u>Περί κόσμου</u></a>, "Γεράσιμος Κουζέλης", "22/3/2020"],
                           ]}
                         />
                       </CardBody>
                     </Card>



                     </Grid>

                   </Grid>

                 </Grid>




               </Grid>
           </Grid>
       </Grid>
     </Grid>



    </Grid>
    </div>

  );
}
