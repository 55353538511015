import {
  FETCH_THREAD_REQUEST,
  FETCH_THREAD_SUCCESS,
  FETCH_THREAD_FAILURE,
  CREATE_THREAD_REQUEST,
  CREATE_THREAD_SUCCESS,
  CREATE_THREAD_FAILURE,
  CREATE_THREAD_SAVE,
  CREATE_THREAD_TOGGLE,
  DELETE_THREAD_REQUEST,
  DELETE_THREAD_SUCCESS,
  DELETE_THREAD_FAILURE,
  EDIT_THREAD_REQUEST,
  EDIT_THREAD_SUCCESS,
  EDIT_THREAD_FAILURE,
  EDIT_THREAD_CANCEL,
  CHANGE_THREAD_PAGE,
  RESTORE_THREAD_REQUEST,
  RESTORE_THREAD_SUCCESS,
  RESTORE_THREAD_FAILURE,
  TOGGLE_THREAD_PINNED_REQUEST,
  TOGGLE_THREAD_PINNED_SUCCESS,
  TOGGLE_THREAD_PINNED_FAILURE,
  TOGGLE_THREAD_FAVOURITE_REQUEST,
  TOGGLE_THREAD_FAVOURITE_SUCCESS,
  TOGGLE_THREAD_FAVOURITE_FAILURE,
  COPY_THREAD_REQUEST,
  COPY_THREAD_SUCCESS,
  COPY_THREAD_FAILURE,
  LIKE_THREAD_POST_REQUEST,
  LIKE_THREAD_POST_SUCCESS,
  LIKE_THREAD_POST_FAILURE,
  TOGGLE_NOTIFICATIONS_THREAD_REQUEST,
  TOGGLE_NOTIFICATIONS_THREAD_SUCCESS,
  TOGGLE_NOTIFICATIONS_THREAD_FAILURE,
  FORWARD_THREAD_REQUEST,
  FORWARD_THREAD_SUCCESS,
  FORWARD_THREAD_FAILURE,
} from './types';
import {
  fetchThreadApi,
  createThreadApi,
  fetchForumApi,
  deleteThreadApi,
  editThreadApi,
  restoreThreadApi,
  togglePinnedThreadApi,
  toggleFavouriteThreadApi,
  toggleNotificationsThreadApi,
  copyThreadApi,
  likeThreadApi,
  forwardThreadApi,
} from '../api';
import {fetchForumSuccess, fetchForumFailure, changeForumThreadPage} from './forum';
import {apiErrorHandler} from '../utils/errorhandler';
import {threadPageSize} from '../utils/config.js'
import { downloadAndOpenBlob } from 'utils.js'

export const fetchThread = thread => dispatch => {
  dispatch(fetchThreadRequest());
  //console.log('fetch thread')

  fetchThreadApi(thread,
    (response) => {
      dispatch(fetchThreadSuccess(response));
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(fetchThreadFailure(errorMessage));
    });
};

export const fetchThreadRequest = () => {
  return {
    type: FETCH_THREAD_REQUEST,
  };
};

export const fetchThreadSuccess = data => {
  return {
    type: FETCH_THREAD_SUCCESS,
    thread: data.thread,
    forum_id: data.forum_id,
    forum_name: data.forum_name,
    parents: data.parents,
  };
};

export const fetchThreadFailure = error => {
  return {
    type: FETCH_THREAD_FAILURE,
    error,
  };
};

export const createThread = (newThread, newFile) => dispatch => {
  dispatch(createThreadRequest(newThread));

  createThreadApi(newThread, newFile,
    (response) => {
      dispatch(createThreadSuccess(response.data));

      // re-load forum page
      fetchForumApi(newThread.forum,
        (response) => {
          dispatch(fetchForumSuccess(response));
          //go to last page - that is where the thread will appear
          const totalThreads = response.forum.threads ? response.forum.threads.length : 0
          const lastPage = Math.ceil(totalThreads/threadPageSize)
          //console.log(lastPage)
          dispatch(changeForumThreadPage(lastPage))

        },
        (error) => {
          const errorMessage = apiErrorHandler(error);
          dispatch(fetchForumFailure(errorMessage));
        });
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(createThreadFailure(errorMessage));
    });
};

export const createThreadRequest = newThread => {
  return {
    type: CREATE_THREAD_REQUEST,
    newThread,
  };
};

export const createThreadSuccess = newThread => {
  return {
    type: CREATE_THREAD_SUCCESS,
    newThread,
  };
};

export const createThreadFailure = error => {
  return {
    type: CREATE_THREAD_FAILURE,
    error,
  };
};

export const createThreadSave = newThread => {
  return {
    type: CREATE_THREAD_SAVE,
    name: newThread.name,
    content: newThread.content,
  };
};

export const createThreadToggle = () => {
  return {
    type: CREATE_THREAD_TOGGLE,
  };
};

export const deleteThread = id => dispatch => {
  dispatch(deleteThreadRequest());

  deleteThreadApi(id,
    (response) => {
      dispatch(deleteThreadSuccess());

      // re-load thread page
      const [forum_id, thread_id] = id.split('-')
      window.location.href = '/admin/Forum/' + forum_id
      /*fetchThreadApi(id,
        (response) => {
          dispatch(fetchThreadSuccess(response.data));
        },
        (error) => {
          const errorMessage = apiErrorHandler(error);
          dispatch(fetchThreadFailure(errorMessage));
        });*/
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(deleteThreadFailure(errorMessage));
    });
};

export const deleteThreadRequest = () => {
  return {
    type: DELETE_THREAD_REQUEST,
  };
};

export const deleteThreadSuccess = () => {
  return {
    type: DELETE_THREAD_SUCCESS,
  };
};

export const deleteThreadFailure = error => {
  return {
    type: DELETE_THREAD_FAILURE,
    error,
  };
};

export const editThread = (newThread, newFile) => dispatch => {
  dispatch(editThreadRequest(newThread));

  editThreadApi(newThread, newFile,
    (response) => {
      //console.log(response)
      dispatch(editThreadSuccess(newThread, response.filename,
        response.date_edited, response.edited_by));
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(editThreadFailure(errorMessage));
    });
};

export const editThreadRequest = newThread => {
  return {
    type: EDIT_THREAD_REQUEST,
    newThread,
  };
};

export const editThreadSuccess = (newThread, newFile, date_edited, edited_by) => {
  return {
    type: EDIT_THREAD_SUCCESS,
    newThread,
    newFile,
    date_edited,
    edited_by,
  };
};

export const editThreadFailure = (error) => {
  return {
    type: EDIT_THREAD_FAILURE,
    error,
  };
};

export const editThreadCancel = () => {
  return {
    type: EDIT_THREAD_CANCEL,
  }
}

export const changeThreadPage = (page) => {
  return {
    type: CHANGE_THREAD_PAGE,
    page,
  }
}

export const restoreThread = id => dispatch => {
  dispatch(restoreThreadRequest());

  restoreThreadApi(id,
    (response) => {
      dispatch(restoreThreadSuccess());

      // re-load forum page
      dispatch(reloadForumCurrentPage(id))
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(restoreThreadFailure(id, errorMessage));
    });
}

export const restoreThreadRequest = id => {
  return {
    type: RESTORE_THREAD_REQUEST,
    id,
  };
};

export const restoreThreadSuccess = id => {
  return {
    type: RESTORE_THREAD_SUCCESS,
    id,
  };
};

export const restoreThreadFailure = (id, error) => {
  return {
    type: RESTORE_THREAD_FAILURE,
    id,
    error,
  };
};

export const togglePinnedThread = (id, view_type, prevPinnedState) => dispatch => {
  dispatch(togglePinnedThreadRequest(prevPinnedState));

  togglePinnedThreadApi(id,
    (response) => {
      dispatch(togglePinnedThreadSuccess());

      if (view_type==='forum_view'){
        //console.log('reload')
        // re-load forum page
        dispatch(reloadForumCurrentPage(id))
      }
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(togglePinnedThreadFailure(id, errorMessage));
    });
}

export const togglePinnedThreadRequest = togglePrevState => {
  return {
    type: TOGGLE_THREAD_PINNED_REQUEST,
    togglePrevState: togglePrevState,
  };
};

export const togglePinnedThreadSuccess = id => {
  return {
    type: TOGGLE_THREAD_PINNED_SUCCESS,
  };
};

export const togglePinnedThreadFailure = (id, error) => {
  return {
    type: TOGGLE_THREAD_PINNED_FAILURE,
    id,
    error,
  };
};

export const toggleFavouriteThread = (id, view_type, prevFavouriteState) => dispatch => {
  dispatch(toggleFavouriteThreadRequest(prevFavouriteState));

  toggleFavouriteThreadApi(id,
    (response) => {
      dispatch(toggleFavouriteThreadSuccess(response.favourite_admin,
        response.favourite_user));

      if (view_type==='forum_view'){
        //console.log('reload')
        // re-load forum page
        dispatch(reloadForumCurrentPage(id))
      }
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(toggleFavouriteThreadFailure(id, errorMessage));
    });
}

export const toggleFavouriteThreadRequest = togglePrevState => {
  return {
    type: TOGGLE_THREAD_FAVOURITE_REQUEST,
    togglePrevState,
  };
};

export const toggleFavouriteThreadSuccess = (favourite_admin, favourite_user) => {
  return {
    type: TOGGLE_THREAD_FAVOURITE_SUCCESS,
    favourite_admin,
    favourite_user,
  };
};

export const toggleFavouriteThreadFailure = (id, error) => {
  return {
    type: TOGGLE_THREAD_FAVOURITE_FAILURE,
    id,
    error,
  };
};

export const copyThreadToFile = thread_id => dispatch => {
  //console.log(thread_id)
  dispatch(copyThreadRequest())

  copyThreadApi(thread_id,
    (response) => {
      //console.log('success')
      const filename = Date.now() + 'thread_' + thread_id + '.txt'
      downloadAndOpenBlob(response, filename)
      dispatch(copyThreadSuccess())
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(copyThreadFailure(errorMessage));
    });
}

export const copyThreadRequest = () => {
  return {
    type: COPY_THREAD_REQUEST,
  }
}

export const copyThreadSuccess = () => {
  return {
    type: COPY_THREAD_SUCCESS,
  }
}

export const copyThreadFailure = (error) => {
  return {
    type: COPY_THREAD_FAILURE,
    error,
  }
}

export const likeThread = (params) => dispatch => {
  dispatch(likeThreadPostRequest(params.id))

  likeThreadApi(params,
    (response) => {
      //console.log('success')
      dispatch(likeThreadPostSuccess())

      fetchThreadApi(params.id,
        (response) => {
          //console.log('refetched')
          dispatch(fetchThreadSuccess(response));
        },
        (error) => {
          const errorMessage = apiErrorHandler(error);
          dispatch(fetchThreadFailure(errorMessage));
        });
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(likeThreadPostFailure(errorMessage));
    });
}

export const likeThreadPostRequest = (likingID) => {
  //console.log(likingID)
  return {
    type: LIKE_THREAD_POST_REQUEST,
    likingID,
  }
}

export const likeThreadPostSuccess = () => {
  return {
    type: LIKE_THREAD_POST_SUCCESS,
  }
}

export const likeThreadPostFailure = error => {
  return {
    type: LIKE_THREAD_POST_FAILURE,
    error,
  }
}

export const toggleNotificationsForThread = (thread_id) => dispatch => {
  dispatch(toggleNotificationsThreadRequest())

  toggleNotificationsThreadApi(thread_id,
    (response) => {
      toggleNotificationsInSession(thread_id)

      dispatch(toggleNotificationsThreadSuccess());

    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(toggleNotificationsThreadFailure(errorMessage));
    });
}

export const toggleNotificationsThreadRequest = () => {
  return {
    type: TOGGLE_NOTIFICATIONS_THREAD_REQUEST,
  };
};

export const toggleNotificationsThreadSuccess = () => {
  return {
    type: TOGGLE_NOTIFICATIONS_THREAD_SUCCESS,
  };
};

export const toggleNotificationsThreadFailure = (error) => {
  return {
    type: TOGGLE_NOTIFICATIONS_THREAD_FAILURE,
    error,
  };
};

export const forwardThread = (params) => dispatch => {
  dispatch(forwardThreadRequest())

  forwardThreadApi(params,
    (response) => {
      dispatch(forwardThreadSuccess());
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(forwardThreadFailure(errorMessage));
    });
}

export const forwardThreadRequest = () => {
  return {
    type: FORWARD_THREAD_REQUEST,
  };
};

export const forwardThreadSuccess = () => {
  return {
    type: FORWARD_THREAD_SUCCESS,
  };
};

export const forwardThreadFailure = (error) => {
  return {
    type: FORWARD_THREAD_FAILURE,
    error,
  };
};

const toggleNotificationsInSession = (thread_id) => {
  const threadSubscriptions = JSON.parse(localStorage.getItem("thread_subscriptions")) || []
  let newSubscriptions = []
  if (threadSubscriptions.includes(thread_id)){
    newSubscriptions = threadSubscriptions.filter(sub => sub !== thread_id)
  } else {
    newSubscriptions = [...threadSubscriptions, thread_id]
  }
  //console.log(newSubscriptions)
  localStorage.setItem("thread_subscriptions", JSON.stringify(newSubscriptions))
}

const reloadForumCurrentPage = (full_thread_id) => dispatch => {
  const [forum_id, thread_id] = full_thread_id.split('-')

  fetchForumApi(forum_id,
    (response) => {
      dispatch(fetchForumSuccess(response));
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(fetchForumFailure(errorMessage));
    });
}
