import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  createThreadSave,
  createThreadToggle,
  fetchForum,
  fetchForums,
  createThread,
  createForum,
  massCreateForums,
  changeForumThreadPage,
  restoreThread,
  togglePinnedThread,
  toggleFavouriteThread,
  deleteForum,
} from '../../actions';
import ThreadList from '../../components/threadlist';
import NewThread from '../../components/newthread';
import ForumList from '../../components/forumlist';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { hasDeleteRightsForParents } from '../../utils/utils.js'

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'
import { ConfirmDeleteMessage } from 'views/CustomAlerts/ConfirmationDialog.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

class ForumContainer extends Component {
  state = {
    errorAlert: null,
    //Snackbar messages (to inform user of success)
    snackbarMessage: '',
    openSnackbar: false,
  }

  loadForum = (forum) => {
    if (forum){
      this.props.fetchForum(forum);
    } else {
      this.props.fetchForums();
    }
  }

  canCreateForum = () => {
    if (!access_rights){
      return false
    } else if (this.props.father === 0){
      return access_rights.forums.wtl
    } else {
      return this.authenticatedIsStaff()
    }
  }

  authenticatedIsStaff = () => {
    return access_rights.forums.wtl ||
    hasDeleteRightsForParents(this.props.parents, this.props.father, access_rights.forums.d)
  }

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({openSnackbar: false})
  }

  handleChangePage = (e, page) => {
    this.props.changePage(page)
  }

  hideErrorAlert = () => {
    this.setState({errorAlert: null})
  };

  handleShowSuccessMessage = (message) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
    })
  }

  confirmDeleteForum = (id) => {
    const alert = <ConfirmDeleteMessage onConfirm={() => this.deleteForum(id)} onCancel={this.hideErrorAlert}/>
    this.setState({errorAlert: alert})
  }

  deleteForum = (id) => {
    this.props.deleteForum(id, this.props.father)
    this.hideErrorAlert()
  }

  componentDidMount() {
    //console.log(this.props.match.params)
    const {forumid} = this.props.match.params;
    this.loadForum(forumid)
  }

  componentDidUpdate(prevProps) {
    const {forumid: oldForum} = prevProps.match.params;
    const {forumid: futureForum} = this.props.match.params;
    if (oldForum !== futureForum) {
      this.loadForum(futureForum)
    }

    if (this.props.newForumSuccess !== prevProps.newForumSuccess && this.props.newForumSuccess){
      this.handleShowSuccessMessage('Η καταχώριση έγινε με επιτυχία')
    }

    if (this.props.restoreThreadSuccess !== prevProps.restoreThreadSuccess && this.props.restoreThreadSuccess){
      this.handleShowSuccessMessage('Η επαναφορά έγινε με επιτυχία')
    }

    if (this.props.togglePinnedSuccess !== prevProps.togglePinnedSuccess && this.props.togglePinnedSuccess){
      this.handleShowSuccessMessage('Το ' + (this.props.togglePrevState ? 'ξεκαρφίτσωμα' : 'καρφίτσωμα') + ' έγινε με επιτυχία')
    }

    if (this.props.toggleFavouriteSuccess !== prevProps.toggleFavouriteSuccess && this.props.toggleFavouriteSuccess){
      this.handleShowSuccessMessage('Η ' + (this.props.togglePrevState ? 'αφαίρεση επισήμανσης' : 'επισήμανση') + ' έγινε με επιτυχία')
    }

    if (this.props.forumDeleteSuccess !== prevProps.forumDeleteSuccess && this.props.forumDeleteSuccess){
      this.handleShowSuccessMessage('Η διαγραφή έγινε με επιτυχία')
    }

    //handle error messages
    let errorToDisplay = ''
    if (this.props.newThreadError !== prevProps.newThreadError && this.props.newThreadError){
      errorToDisplay = this.props.newThreadError
    } else if (this.props.newForumError !== prevProps.newForumError && this.props.newForumError){
      errorToDisplay = this.props.newForumError
    } else if (this.props.restoreThreadError !== prevProps.restoreThreadError && this.props.restoreThreadError){
      errorToDisplay = this.props.restoreThreadError
    } else if (this.props.toggleError !== prevProps.toggleError && this.props.toggleError){
      errorToDisplay = this.props.toggleError
    } else if (this.props.forumDeleteError !== prevProps.forumDeleteError && this.props.forumDeleteError){
      errorToDisplay = this.props.forumDeleteError
    }

    if (errorToDisplay !== ''){
      const alert = (<ErrorAlert message={errorToDisplay} hideAlert={this.hideErrorAlert}/>)
      this.setState({errorAlert: alert})
    }
  }

  render() {
    const {
      isLoading,
      name,
      id,
      description,
      threads,
      error,
      parents,
      isPrivate,
      lab,
      newThreadLoading,
      newThreadName,
      newThreadContent,
      newThreadId,
      newThreadShow,
      createThread,
      createThreadSave,
      createThreadToggle,
      threadPage,
      restoreThread,
      togglePinned,
      toggleFavourite,
    } = this.props;

    const rootStyles = {
      display: 'flex',
      flexGrow: 1,
    }

    const {forumid} = this.props.match.params;
    /*console.log('fp='+isPrivate)
    console.log('fl='+lab)*/
    //console.log(localStorage.getItem('content_draft'))

    return (
      <div style={rootStyles}>
        <CssBaseline />
        <Sidebar/>

        <Grid container spacing={2} style={rootStyles}>
          <Grid item xs={12} >
            <div>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                  <Link color="inherit" href="/admin/SelectTransSem">
                    Main
                  </Link>
                  <Link color="inherit" href="/admin/Forums">Forums</Link>
                  {
                    parents && parents.map(p =>
                      <Link key={p.id} color='inherit' href={"/admin/Forum/"+p.id}>{p.name || ''}</Link>
                    )
                  }
                  <Typography color="textPrimary">{name}</Typography>
              </Breadcrumbs>
            </div>
          </Grid>

         <Grid item xs={12} style={{ paddingTop : '20px'}}>
          <Container maxWidth='lg'>
            <Paper>
              <ForumList {...this.props} addOrEditForum={this.props.createForum}
                canCreateForum={this.canCreateForum()} fatherIsPrivate={isPrivate}
                fatherLab={lab} massAddForums={this.props.massCreateForums}
                deleteForum={this.confirmDeleteForum}/>
              {forumid &&
                <>
                <NewThread
                  forum={id}
                  isAuthenticated={access_rights && access_rights.forums.w}
                  isLoading={newThreadLoading}
                  name={newThreadName}
                  content={newThreadContent}
                  file={null}
                  keywords={[]}
                  id={newThreadId}
                  showEditor={newThreadShow}
                  createThread={createThread}
                  updateNewThread={createThreadSave}
                  toggleShowEditor={createThreadToggle}
                  maxLength={2000}
                />
                <ThreadList
                  isLoading={isLoading || !id}
                  name={name}
                  forum_id={id}
                  description={description}
                  threads={threads}
                  error={error}
                  page={threadPage}
                  handleChangePage={this.handleChangePage}
                  restoreAction={restoreThread}
                  authenticatedIsStaff={access_rights && this.authenticatedIsStaff()}
                  togglePinnedAction={togglePinned}
                  isAuthenticated={access_rights && access_rights.forums.w}
                  toggleFavouriteAction={toggleFavourite}
                />
              </>
              }

              <Snackbar anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={this.state.openSnackbar}
                autoHideDuration={3000}
                onClose={this.handleCloseSnackbar}
                >
                <Alert onClose={this.handleCloseSnackbar} severity="success">
                  {this.state.snackbarMessage}
                </Alert>
              </Snackbar>

              {this.state.errorAlert}
              </Paper>
            </Container>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.forum.isLoading,
  name: state.forum.name,
  id: state.forum.id,
  description: state.forum.description,
  threads: state.forum.threads,
  forums: state.forum.forums,
  father: state.forum.id || 0,
  parents: state.forum.parents,
  isPrivate: state.forum.isPrivate || false,
  lab: state.forum.lab || '',
  work: state.forum.work || '',
  error: state.forum.error,
  threadPage: state.forum.threadPage,
  newThreadLoading: state.forum.newThreadLoading,
  newThreadSuccess: state.forum.newThreadSuccess,
  newThreadName: state.forum.newThreadName,
  newThreadContent: state.forum.newThreadContent,
  newThreadId: state.forum.newThreadId,
  newThreadError: state.forum.newThreadError,
  newThreadShow: state.forum.newThreadShow,
  newForumId: state.forum.newForumId,
  newForumSuccess: state.forum.newForumSuccess,
  newForumLoading: state.forum.newForumLoading,
  newForumError: state.forum.newForumError,
  restoreThreadSuccess: state.thread.restoreThreadSuccess,
  restoreThreadError: state.thread.restoreThreadError,
  togglePinnedSuccess: state.thread.togglePinnedSuccess,
  toggleFavouriteSuccess: state.thread.toggleFavouriteSuccess,
  toggleError: state.thread.toggleError,
  togglePrevState: state.thread.togglePrevState,
  isDeleting: state.forum.isDeleting,
  forumDeleteSuccess: state.forum.forumDeleteSuccess,
  forumDeleteError: state.forum.forumDeleteError,
});

const mapDispatchToProps = dispatch => ({
  fetchForum: forum => {
    dispatch(fetchForum(forum));
  },
  fetchForums: () => {
    dispatch(fetchForums());
  },
  createThread: (newThread, newFile) => {
    dispatch(createThread(newThread, newFile));
  },
  createThreadSave: newThread => {
    dispatch(createThreadSave(newThread));
  },
  createThreadToggle: () => {
    dispatch(createThreadToggle());
  },
  createForum: forum => {
    dispatch(createForum(forum))
  },
  massCreateForums: forumdata => {
    dispatch(massCreateForums(forumdata))
  },
  deleteForum: (forumid, father) => {
    dispatch(deleteForum(forumid, father))
  },
  changePage: page => {
    dispatch(changeForumThreadPage(page))
  },
  restoreThread: thread_id => {
    dispatch(restoreThread(thread_id))
  },
  togglePinned: (thread_id, prevPinnedState) => {
    dispatch(togglePinnedThread(thread_id, 'forum_view', prevPinnedState))
  },
  toggleFavourite: (thread_id, prevFavouriteState) => {
    dispatch(toggleFavouriteThread(thread_id, 'forum_view', prevFavouriteState))
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForumContainer);

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
