import React from 'react';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Assignment from "@material-ui/icons/Assignment";

import { UserActions } from "./AdminUsersGroupsButtonsActions.js"
import { AddUserDialog, ViewUserRolesAndUsergroups } from "./AdminUsersGroupsDialogForms.js"
import { getValidationErrorsForUser } from "./UserForm.js"
import { addUser, editUser, toggleActiveUser } from "clients/usersClient.js"
import { checkNoErrors } from "utils.js"

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },

  LabelCol: {
    color:"#3f51b5",
    fontweight: "bold"
  },

  cardFix: {
      fontSize: "1em!important",
  },
}))

const UsersTab = (props) => {

  const constructUserdataRow = (user) => {
    return {
      id: user.user_id,
      firstname: user.firstname,
      lastname: user.lastname,
      username: user.username,
      //phone: user.phone,
      active: user.active,
      activeTxt: user.active ? 'ΝΑΙ' : 'ΌΧΙ',
      actions: (
        <UserActions user={user} handleClickEdit={handleClickEditUser}
          handleClickView={handleOpenView}
          handleClickToggleActive={(id, newActive) => handleToggleActiveUser(id, newActive)}/>
      )
    };
  }

  const handleClickOpenUser = (dialogTitle, dialogButtonLabel) => {
    setDialogTitle(dialogTitle);
    setDialogButtonLabel(dialogButtonLabel);
    setOpenUser(true);
    setDisableUserSubmit(false)
  };

  const handleCloseUser = () => {
    setOpenUser(false);

    setUserId(0)
    setFirstname('')
    setLastname('')
    setUsername('')
    setUsername2('')
    setPassword('')
    setPassword2('')
    setErrorsUser({})
  };

  const handleOpenView = (user) => {
    setFirstname(user.firstname)
    setLastname(user.lastname)
    if (user.user_roles){
      setUserRoles(user.user_roles.map(r => {
        return {id: r.id, name: Rolesdata[r.id].name};
      }))
    }
    if (user.usergroups){
      setUserGroups(Groupsdata.filter(g => user.usergroups.includes(g.usergroup_id)))
    }

    setOpenView(true)
  }

  const handleCloseView = () => {
    setFirstname('')
    setLastname('')
    setUserRoles([])
    setUserGroups([])

    setOpenView(false)
  }

  const handleClickEditUser = (user) => {
    setUserId(user.user_id)
    setFirstname(user.firstname)
    setLastname(user.lastname)
    //setUsername(user.username)

    handleClickOpenUser('Επεξεργασία στοιχείων Χρήστη', 'Επεξεργασία');
  }

  const handleAddUser = () => {
    let params = {firstname: firstname, lastname: lastname, username: username, username2: username2, password: password, password2: password2}

    if (validateUser(params, (userId === 0))){
      setDisableUserSubmit(true)

      if (userId > 0){
        //edit
        params = {firstname: firstname, lastname: lastname, user_id: userId}
        //console.log(params)

        editUser(params,
          () => {
            //show changes in Table
            let newData = Usersdata;
            const changeIndex = newData.findIndex((o) => (o.user_id === userId))
            const updatedUser = Object.assign(newData[changeIndex], params)
            //console.log(updatedUser)
            newData[changeIndex] = updatedUser
            //console.log(newData)
            updateUserData(newData);
            handleCloseUser();
            handleShowSuccessMessage('H καταχώριση έγινε με επιτυχία!')
            setDisableUserSubmit(false)
          },
          (err) => {
            showErrorAlert(err.message)
            setDisableUserSubmit(false)
           }
        )
      } else {
        //add
        params = {firstname: firstname, lastname: lastname, username: username, pass: password}
        //console.log(params)

        addUser(params,
          (data) => {
            //add to Table
            let newData = Usersdata;
            //console.log(newData)
            const newUser = Object.assign(params, {user_id: data.inserted_id, active: false})
            delete newUser.pass
            //console.log(newUser)
            newData.push(newUser)
            updateUserData(newData)
            handleCloseUser();
            handleShowSuccessMessage('H καταχώριση έγινε με επιτυχία!')
            setDisableUserSubmit(false)
          },
          (err) => {
            showErrorAlert(err.message);
            setDisableUserSubmit(false)
           }
        )
      }
    }
  }

  const handleToggleActiveUser = (id, newActive) => {
    const params = {user_id: id, active_value: Boolean(newActive)}

    toggleActiveUser(params,
      () => {
        let newData = Usersdata;
        const changeIndex = newData.findIndex((o) => (o.user_id === id))
        const updatedUser = Object.assign(newData[changeIndex], {active: Boolean(newActive), user_id: id})
        //console.log(updatedUser)
        newData[changeIndex] = updatedUser
        //console.log(newData)
        updateUserData(newData);

        const action = newActive ? 'ενεργοποίηση' : 'απενεργοποίηση'
        handleShowSuccessMessage('H ' + action + ' έγινε με επιτυχία!')
      },
      (err) => showErrorAlert(err.message)
    )
  }

  const validateUser = (fields, checkPassword) => {
    const newErrors = getValidationErrorsForUser(fields, checkPassword);
    setErrorsUser(newErrors)
    //console.log(newErrors)

    return checkNoErrors(newErrors)
  }

  const handleChangeUserField = (e, fieldname) => {
    switch (fieldname){
      case 'firstname':
         setFirstname(e.target.value)
         break;

     case 'lastname':
         setLastname(e.target.value)
         break;

     case 'username':
         setUsername(e.target.value)
         break;

     case 'username2':
         setUsername2(e.target.value)
         break;

     case 'password':
         setPassword(e.target.value)
         break;

     case 'password2':
         setPassword2(e.target.value)
         break;

    default:
        ;
    }
  }

  const { Usersdata, updateUserData, handleShowSuccessMessage, showErrorAlert,
    Rolesdata, Groupsdata } = props
  const UsersdataFormatted = Usersdata.map(user => constructUserdataRow(user))
  const classes = useStyles()

  const [userId, setUserId] = React.useState(0)
  const [firstname, setFirstname] = React.useState('')
  const [lastname, setLastname] = React.useState('')
  const [username, setUsername] = React.useState('')
  const [username2, setUsername2] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [password2, setPassword2] = React.useState('')
  const [userRoles, setUserRoles] = React.useState([])
  const [userGroups, setUserGroups] = React.useState([])

  const [errorsUser, setErrorsUser] = React.useState({})
  const [disableUserSubmit, setDisableUserSubmit] = React.useState(false)
  const [dialogTitle, setDialogTitle] = React.useState('')
  const [dialogButtonLabel, setDialogButtonLabel] = React.useState('')
  const [openUser, setOpenUser] = React.useState(false);
  const [openView, setOpenView] = React.useState(false)

  return (
    <>
      <Card className={classes.cardFix}>
        <CardHeader className="AristosCardHeader" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4>Χρήστες</h4>
        </CardHeader>
        <CardBody>
          <ReactTable
            columns={[
              {
                Header: "Username",
                accessor: "username"
              },
              {
                Header: "Όνομα",
                accessor: "firstname"
              },
              {
                Header: "Επώνυμο",
                accessor: "lastname"
              },
              {
                Header: "Ενεργός/ή",
                accessor: "activeTxt"
              },
              {
                Header: "Ενέργειες",
                accessor: "actions"
              }
            ]}
            data={UsersdataFormatted}
          />

          <Button className={classes.buttonAdd} onClick={() => handleClickOpenUser('Προσθήκη Χρήστη', 'Προσθηκη')}>Προσθήκη Χρήστη</Button>

        </CardBody>
      </Card>

      <AddUserDialog
        handleAdd={handleAddUser} handleClose={handleCloseUser} open={openUser}
        dialogTitle={dialogTitle} dialogButtonLabel={dialogButtonLabel}
        firstname={firstname} lastname={lastname} username={username} username2={username2}
        user_id={userId} password={password} password2={password2}
        errors={errorsUser} handleChange={handleChangeUserField} disableButton={disableUserSubmit}
      />

      <ViewUserRolesAndUsergroups
        firstname={firstname} lastname={lastname} open={openView}
        handleClose={handleCloseView} roles={userRoles} usergroups={userGroups}
      />

    </>
  )
}

export default UsersTab
