import { fetchWithGET, fetchWithMethod , fetchWithFile} from './clientUtils.js'

function getAllSections(success, errorFunc){
  return fetchWithGET('sections', '', success, errorFunc)
}

function getOneSection(id, success, errorFunc){
  return fetchWithGET('sections/' + id, '',
    (data) => {
      if (data.documents[0]){
        return success(data);
      } else {
        return errorFunc(new Error('Το χωρίο δε βρέθηκε'))
      }
    },
    errorFunc
  )
}

function getSectionsForWork(work_id, success, errorFunc){
  const querystring = '?work_id=' + work_id
  return fetchWithGET('sections_titles', querystring, success, errorFunc)
}

function getSectionsForBook(work_id, book_id, success, errorFunc){
  const querystring = '?work_id=' + work_id + '&book_id=' + book_id

  return fetchWithGET('sections_to_translate', querystring, success, errorFunc)
}

function addSection(vals, success, errorFunc){
  //console.log(JSON.stringify(vals));
  return fetchWithMethod('sections', 'POST', vals, success, errorFunc);
}

function editSection(vals, success, errorFunc){
  //console.log(JSON.stringify(vals));
  return fetchWithMethod('sections', 'PUT', vals, success, errorFunc);
}

function deleteSection(vals, success, errorFunc){
  //console.log(JSON.stringify(vals));
  return fetchWithMethod('sections', 'DELETE', vals, success, errorFunc);
}

function uploadSection(file, vals, success, errorFunc){
  return fetchWithFile('sections_from_file', 'POST', file, vals, success, errorFunc)
}

function getLabsForSection(section_id, success, errorFunc){
  return fetchWithGET('sections/labs/' + section_id, '', success, errorFunc)
}

function getForumsForSection(section_id, success, errorFunc){
  return fetchWithGET('sections/forums/' + section_id, '', success, errorFunc)
}

export {getAllSections, getOneSection, addSection, editSection, deleteSection,
  getSectionsForBook, uploadSection, getLabsForSection, getSectionsForWork,
  getForumsForSection}
