import React from 'react';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const LabsTab = (props) => {
  const { labs } = props

  const allowedSeminarView = (lab_id) => {
    return access_rights.labs_tr &&
      (access_rights.labs_tr.r.includes(lab_id) || access_rights.labs_tr.w)
  }

  return(
    labs.labs_tr && labs.labs_tr.map(lab =>
      <Typography varian='body1' key={lab.seminar_id}>
        <Link href={allowedSeminarView(lab.lab_id) ? '/admin/TRSeminarView/'+lab.seminar_id : '/admin/TranslationReceptionLabs'}>
          {lab.lab_title+ ' - ' + lab.seminar_title}
        </Link>
      </Typography>
    )
  )
}

export default LabsTab
