import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";

import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Button from "components/CustomButtons/Button.js";
import TagsInput from "react-tagsinput";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Container from '@material-ui/core/Container';
import { Editor } from '@tinymce/tinymce-react';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'
import { ConfirmCancelMessage } from 'views/CustomAlerts/ConfirmationDialog.js'

import { getOneSeminar, getOneLab, addSeminar, editSeminar } from 'clients/labTranslationReceptionClient.js'
import { getAllWorks } from 'clients/worksClient.js'
import { getAllSections } from 'clients/sectionsClient.js'
import { labsTRImagesURL } from 'clients/clientConfig.js'
import { checkNoErrors, addHttpToLink } from 'utils.js'
import { validUrlPattern } from 'variables/general.js'
import { sortWorksAndBooks } from 'sorting.js'

//import LinkToForum from './LinkToForum.js'
import LinkToLexiconEntryForm from 'views/Editor/LinkToLexiconEntryForm.js'
import LinkToSection from './LinkToSection.js'
import LinkToComment from './LinkToComment.js'
import LinkToTranslationNote from './LinkToTranslationNote.js'
import MaterialTextForm from './MaterialTextForm.js'
import MaterialFileForm from './MaterialFileForm.js'
import MaterialUploadForm from './MaterialUploadForm.js'
import { TextMaterialEditList, FileMaterialEditList } from './MaterialLists.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  fileinput: {
    display: 'none',
  },
  inner_form: {
    margin: 0
  },
  labImage: {
    height: 60,
    width: 90,
  }
}))

const SeminarEdit = (props) => {
  const classes = useStyles()

  const [lab, setLab] = React.useState({id: 0, title: ''})
  const [seminar, setSeminar] = React.useState({})
  const [availableTagsWork, setAvailableTagsWork] = React.useState([])
  const infoHtmlEditorRef = React.useRef(null);

  const [title, setTitle] = React.useState('')
  const [image, setImage] = React.useState(null)
  const [coordinator, setCoordinator] = React.useState('')
  const [videoLink, setVideoLink] = React.useState('')
  const [info, setInfo] = React.useState('')
  const [labels, setLabels] = React.useState([])
  const [speaker, setSpeaker] = React.useState('')
  const [attendants, setAttendants] = React.useState('')
  const [place, setPlace] = React.useState('')
  const [time, setTime] = React.useState('')
  const [meetingLink, setMeetingLink] = React.useState('')
  const [workTags, setWorkTags] = React.useState([])

  const [materialTexts, setMaterialTexts] = React.useState([])
  const [materialFiles, setMaterialFiles] = React.useState([])
  const [summaryTexts, setSummaryTexts] = React.useState([])
  const [summaryFiles, setSummaryFiles] = React.useState([])

  const [openTextForm, setOpenTextForm] = React.useState(false)
  const [openFilesForm, setOpenFilesForm] = React.useState(false)
  const [openUploadForm, setOpenUploadForm] = React.useState(false)
  const [materialOrSummary, setMaterialOrSummary] = React.useState('')

  const [editingTextIdx, setEditingTextIdx] = React.useState(-1)
  const [editingFileIdx, setEditingFileIdx] = React.useState(-1)
  const [editingFilename, setEditingFilename] = React.useState('')
  const [editingMaterialText, setEditingMaterialText] = React.useState('')
  const [editingMaterialTitle, setEditingMaterialTitle] = React.useState('')
  const [editingMaterialFile, setEditingMaterialFile] = React.useState('')
  const [file, setFile] = React.useState(null)
  const [materialErrors, setMaterialErrors] = React.useState({})

  const [links_arr, setLinks_arr] = React.useState([])
  const [linkHref, setLinkHref] = React.useState('')
  const [editingLinkIdx, setEditingLinkIdx] = React.useState(-1)
  const [linkTitle, setLinkTitle] = React.useState('')

  const [platform_arr, setPlatform_arr] = React.useState([])
  const [openLyricsForm, setOpenLyricsForm] = React.useState(false)
  const [openCommentForm, setOpenCommentForm] = React.useState(false)
  const [openNoteForm, setOpenNoteForm] = React.useState(false)

  const [lexicon_arr, setLexicon_arr] = React.useState([])
  const [openLexiconForm, setOpenLexiconForm] = React.useState(false)

  /*const [forum_arr, setForum_arr] = React.useState([])
  const [openForumForm, setOpenForumForm] = React.useState(false)*/

  const [saving, setSaving] = React.useState(false)
  const [errors, setErrors] = React.useState({})

  //for MaterialTextForm
  const [availableSections, setAvailableSections] = React.useState([])

  React.useEffect(()=> {
    if (props.match.params.lab_id){
      getOneLab(props.match.params.lab_id, '',
        (data) => {
          if (data.translation_labs.length > 0){
            setLab(data.translation_labs[0])
          } else {
            window.location.href = '/admin/TranslationReceptionLabs'
          }
        },
        (err) => window.location.href = '/admin/TranslationReceptionLabs'
      )

      getAllWorks(
        (wdata) => {
          setAvailableTagsWork(sortWorksAndBooks(wdata.works))//.map((w) => w.name)),

          //get seminar after we have got works, so that we can update the names of the work tags
          if (props.match.params.seminar_id){
            getOneSeminar(props.match.params.seminar_id,
              (data) => {
                setSeminar(data.seminars[0])

                setTitle(data.seminars[0].title)
                setCoordinator(data.seminars[0].coordinator)
                setInfo(data.seminars[0].info)
                setVideoLink(data.seminars[0].videoLink)
                setLabels(data.seminars[0].labels)
                setSpeaker(data.seminars[0].speaker)
                setAttendants(data.seminars[0].attendants)
                setPlace(data.seminars[0].place)
                setTime(data.seminars[0].time)
                setMeetingLink(data.seminars[0].meetingLink)
                setMaterialTexts(data.seminars[0].materialTexts)
                setMaterialFiles(data.seminars[0].materialFiles)
                setSummaryTexts(data.seminars[0].summaryTexts || [])
                setSummaryFiles(data.seminars[0].summaryFiles || [])
                setLinks_arr(data.seminars[0].links_arr)
                setPlatform_arr(data.seminars[0].platform_arr)
                setLexicon_arr(data.seminars[0].lexicon_arr)

                const tempWorkTags = data.seminars[0].workTags || []
                setWorkTags(wdata.works.filter(w => tempWorkTags.includes(w.id)))

                if (data.seminars[0].image!==null && data.seminars[0].image!==''){
                  setImage({name: data.seminars[0].image})
                }
              },
              (err) => console.log(err)
            )
          }
        },
        (werr) => console.log(werr)
      )
    } else {
      window.location.href = '/admin/TranslationReceptionLabs'
    }
  }, [props.match.params.lab_id, props.match.params.seminar_id])

  React.useEffect(() => {
    getAllSections(
      (data) => setAvailableSections(data.documents),
      (err) => console.log(err)
    )
  }, [])

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  //field changes
  const handleTitleChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.title = validate('title', e.target.value)
    setErrors(newErrors)

    setTitle(e.target.value)
  }

  const handleCoordinatorChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.coordinator = validate('coordinator', e.target.value)
    setErrors(newErrors)

    setCoordinator(e.target.value)
  }

  const handleAttendantsChange = (e) => {
    setAttendants(e.target.value)
  }

  /*const handleInfoChange = (e) => {
    setInfo(e.target.value)
  }*/

  const handleChangeLabels = l => {
    setLabels(l)
  }
  const handleSpeakerChange = (e) => {
    setSpeaker(e.target.value)
  }

  const handlePlaceChange = (e) => {
    setPlace(e.target.value)
  }

  const handleTimeChange = (e) => {
    setTime(e.target.value)
  }

  const handleMeetingLinkChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.meetingLink = validate('meetingLink', e.target.value)
    setErrors(newErrors)

    setMeetingLink(e.target.value)
  }

  const handleVideoLinkChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.videoLink = validate('videoLink', e.target.value)
    setErrors(newErrors)

    setVideoLink(e.target.value)
  }

  const handleWorkTagsChange = (e, value) => {
    setWorkTags(value)
  }
  const handleChangeLinkTitle = e => {
    setLinkTitle(e.target.value)
  }

  const handleChangeLinkHref = e => {
    let newErrors = Object.assign({}, errors)
    newErrors.linkHref = validate('linkHref', e.target.value)
    setErrors(newErrors)

    setLinkHref(e.target.value)
  }

  //function for material addition/editing
  const addOrEditText = (newTextBody) => {
    let newErrors = Object.assign({}, materialErrors)
    newErrors.title = validateMaterial('title', editingMaterialTitle)
    newErrors.text = validateMaterial('text', newTextBody)
    setMaterialErrors(newErrors)

    if (checkNoErrors(newErrors)){
      const newText = {title: editingMaterialTitle, text: newTextBody}

      if (materialOrSummary==='material'){
        setMaterialTexts(getNewTextArray(materialTexts, newText))
      } else {
        setSummaryTexts(getNewTextArray(summaryTexts, newText))
      }

      setOpenTextForm(false)
    }
  }

  const getNewTextArray = (oldTextArray, newText) => {
    if (editingTextIdx < 0){
      const temp = [...oldTextArray, newText]
      return temp
    } else {
      let temp = [...oldTextArray]
      temp[editingTextIdx] = newText
      return temp
    }
  }

  const prepareToEditText = (text, idx, textType) => {
    setEditingMaterialTitle(text.title || '')
    setEditingMaterialText(text.text || '')
    setEditingTextIdx(idx)
    setMaterialErrors({})
    setMaterialOrSummary(textType)
    setOpenTextForm(true)
  }

  const removeText = (idx, textType) => {
    if (textType==='material'){
      let temp = [...materialTexts]
      temp.splice(idx, 1)
      setMaterialTexts(temp)
    } else {
      let temp = [...summaryTexts]
      temp.splice(idx, 1)
      setSummaryTexts(temp)
    }
  }

  //file links
  const getFilenameFromLink = (link) => {
    const pos = link.lastIndexOf('/')
    if (pos >= 0){
      return link.substring(pos+1)
    } else {
      return link
    }
  }

  const addOrEditFileLink = () => {
    let newErrors = Object.assign({}, materialErrors)
    newErrors.title = validateMaterial('title', editingMaterialTitle)
    newErrors.file = validateMaterial('file', editingMaterialFile)
    setMaterialErrors(newErrors)

    if (checkNoErrors(newErrors)){
      const newFile = {title: editingMaterialTitle, file: addHttpToLink(editingMaterialFile), filename: getFilenameFromLink(editingMaterialFile)}

      if (materialOrSummary==='material'){
        setMaterialFiles(getNewFileArray(materialFiles, newFile))
      } else {
        setSummaryFiles(getNewFileArray(summaryFiles, newFile))
      }

      setOpenFilesForm(false)
    }
  }

  const getNewFileArray = (oldFileArray, newFile) => {
    if (editingFileIdx < 0){
      const temp = [...oldFileArray, newFile]
      return temp
    } else {
      let temp = [...oldFileArray]
      temp[editingFileIdx] = newFile
      //temp[editingFileIdx].title = editingMaterialTitle
      //temp[editingFileIdx].file = editingMaterialFile
      return temp
    }
  }

  const prepareToEditFile = (file, idx, fileType) => {
    setEditingMaterialTitle(file.title || '')
    setEditingFileIdx(idx)
    setEditingMaterialFile(file.file || '')
    setMaterialErrors({})
    setMaterialOrSummary(fileType)
    setOpenFilesForm(true)
  }

  const removeFile = (idx, fileType) => {
    if (fileType==='material'){
      let temp = [...materialFiles]
      temp.splice(idx, 1)
      setMaterialFiles(temp)
    } else {
      let temp = [...summaryFiles]
      temp.splice(idx, 1)
      setSummaryFiles(temp)
    }
  }

  //file uploads
  const addOrEditFileUpload = () => {
    let newErrors = Object.assign({}, materialErrors)
    newErrors.title = validateMaterial('title', editingMaterialTitle)
    if (editingFileIdx < 0){
      newErrors.file = validateMaterial('upload', file)
    }
    setMaterialErrors(newErrors)

    if (checkNoErrors(newErrors)){
      const fileField = file || ''
      const filename = (file!==null) ? file.name : editingFilename
      const newFile = {title: editingMaterialTitle, file: fileField, filename: filename}
      //console.log(newFile)

      if (materialOrSummary==='material'){
        setMaterialFiles(getNewFileArray(materialFiles, newFile))
      } else {
        setSummaryFiles(getNewFileArray(summaryFiles, newFile))
      }

      setOpenUploadForm(false)
    }
  }

  const prepareToEditUpload = (file, idx, fileType) => {
    //console.log(file)
    setEditingMaterialTitle(file.title || '')
    setEditingFileIdx(idx)
    setFile(file.file || null)
    setEditingFilename(file.filename || '')
    setMaterialErrors({})
    setMaterialOrSummary(fileType)
    setOpenUploadForm(true)
  }

  const handleMaterialTitleChange = (e) => {
    let newErrors = Object.assign({}, materialErrors)
    newErrors.title = validateMaterial('title', e.target.value)
    setMaterialErrors(newErrors)

    setEditingMaterialTitle(e.target.value)
  }

  const handleMaterialFileChange = (e) => {
    let newErrors = Object.assign({}, materialErrors)
    newErrors.file = validateMaterial('file', e.target.value)
    setMaterialErrors(newErrors)

    setEditingMaterialFile(e.target.value)
  }

  const validateMaterial = (name, value) => {
    switch (name) {
      case 'title':
      case 'text':
        return (value==='') ? 'Υποχρεωτικό πεδίο' : ''

      case 'file':
        if (value===''){
          return 'Υποχρεωτικό πεδίο'
        } else if (!validUrlPattern.test(value)){
          return 'Μη έγκυρο link'
        } else {
          return ''
        }

      case 'upload':
        return (value===null) ? 'Υποχρεωτικό πεδίο' : ''

      default:
        return ''
    }
  }

  //functions for link addition/removal
  const addOrEditLink = () => {
    let newErrors = Object.assign({}, errors)
    newErrors.linkHref = validate('linkHref', linkHref)
    setErrors(newErrors)

    if (newErrors.linkHref===''){
      const newLink = {link: addHttpToLink(linkHref),
        title: (linkTitle==='') ? linkHref : linkTitle}

      if (editingLinkIdx < 0){
        const tempLinks = [...links_arr, newLink]
        setLinks_arr(tempLinks)
      } else {
        let tempLinks = [...links_arr]
        tempLinks[editingLinkIdx] = newLink
        setLinks_arr(tempLinks)
      }

      resetLinkFields()
    }
  }

  const resetLinkFields = () => {
    setLinkHref('')
    setLinkTitle('')
    setEditingLinkIdx(-1)
  }

  const prepareToEditLink = (link, idx) => {
    setLinkHref(link.link)
    setLinkTitle(link.title)
    setEditingLinkIdx(idx)
  }

  const removeLink = (idx) => {
    let temp = [...links_arr]
    temp.splice(idx, 1)
    setLinks_arr(temp)
  }

  /*const addForumLink = (thread) => {
    const tempforum = [...forum_arr, thread]
    setForum_arr(tempforum)
    setOpenForumForm(false)
  }

  const removeForumLink = (idx) => {
    let temp = [...forum_arr]
    temp.splice(idx, 1)
    setForum_arr(temp)
  }*/

  const addLexiconLink = (link) => {
    const tempLex = [...lexicon_arr, link]
    setLexicon_arr(tempLex)
    setOpenLexiconForm(false)
  }

  const removeLexiconLink = (idx) => {
    let temp = [...lexicon_arr]
    temp.splice(idx, 1)
    setLexicon_arr(temp)
  }

  const addPlatformLink = (link) => {
    const temp = [...platform_arr, link]
    setPlatform_arr(temp)

    setOpenLyricsForm(false)
    setOpenNoteForm(false)
    setOpenCommentForm(false)
  }

  const removePlatformLink = (idx) => {
    let temp = [...platform_arr]
    temp.splice(idx, 1)
    setPlatform_arr(temp)
  }

  //file upload functions
  const uploadImage = (e) => {
    const allowedExtensions = /(\.(jpg|jpeg|gif|png|bmp|tiff|pjp|jfif|svg|svgz|webp|ico|xbm|dib|tif|pjpeg|avif))$/i;
    let newErrors = Object.assign({}, errors)

    if (!allowedExtensions.exec(e.target.files[0].name)) {
      newErrors.image = "Πρέπει να ανεβάσετε αρχείο εικόνας"
      if (!seminar.image){
        setImage(null)
      }
    } else {
      setImage(e.target.files[0])
      newErrors.image = ''
    }

    setErrors(newErrors)
  }

  const uploadFile = (e) => {
    const allowedExtensions = /(\.(pdf|doc|docx))$/i;
    let newErrors = Object.assign({}, materialErrors)

    if (e.target.files[0].size > 2500000){
      newErrors.file = "Πρέπει να ανεβάσετε αρχείο μέχρι 2.5ΜΒ"
      setFile(null)
    } else if (!allowedExtensions.exec(e.target.files[0].name)) {
      newErrors.file = "Πρέπει να ανεβάσετε αρχείο pdf ή word"
      setFile(null)
    } else {
      setFile(e.target.files[0])
      newErrors.file = ''
    }

    setMaterialErrors(newErrors)
  }

  //validation
  const validate = (name, value) => {
    switch (name) {
      case 'title':
      case 'coordinator':
        return (value==='') ? 'Υποχρεωτικό πεδίο' : ''

      case 'meetingLink':
      case 'videoLink':
        return (value!=='') && !validUrlPattern.test(value) ? 'Μη έγκυρο link' : ''

      case 'linkHref':
        if (value===''){
          return 'Υποχρεωτικό πεδίο'
        } else if (!validUrlPattern.test(value)){
          return 'Μη έγκυρο link'
        } else {
          return ''
        }

      default:
        return ''
    }
  }

  const replaceFileObjects = (files_arr) => {
    return files_arr.map(f => {
      return (f.file instanceof File) ?
        {title: f.title, file: '', filename: f.filename} :
        f
      })
  }

  const handleSubmit = () => {
    setSaving(true)
    const newInfo = (infoHtmlEditorRef.current) ? infoHtmlEditorRef.current.getContent() : ''

    let newErrors = Object.assign({}, errors)
    newErrors.title = validate('title', title)
    newErrors.coordinator = validate('coordinator', coordinator)
    newErrors.meetingLink = validate('meetingLink', meetingLink)
    newErrors.videoLink = validate('videoLink', videoLink)
    //the following errors should be disregarded
    newErrors.image = ''
    //newErrors.video = ''
    newErrors.linkHref = ''
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      //setSaving(false)

      let params = {title: title, lab_id: lab.id, image: (image!=null) ? image.name : '', coordinator: coordinator, info: newInfo, videoLink: videoLink,
        labels: labels, speaker: speaker, attendants: attendants, place: place, time: time, meetingLink: meetingLink,
        materialTexts: materialTexts, materialFiles: replaceFileObjects(materialFiles), links_arr: links_arr, platform_arr: platform_arr,
        lexicon_arr: lexicon_arr, summaryTexts: summaryTexts, summaryFiles: replaceFileObjects(summaryFiles),
        workTags: workTags.map(w => w.id)
      }

      let newFiles = materialFiles.concat(summaryFiles)
        .filter(f => (f.file instanceof File))
        .map(f => f.file)
      if (image instanceof File){
        newFiles.push(image)
      }
      /*console.log(newFiles)
      console.log(replaceFileObjects(materialFiles))
      console.log(replaceFileObjects(summaryFiles))*/

      if (seminar.id){
        params.id = seminar.id
        //console.log(params)

        editSeminar(newFiles, params,
          () => window.location.href = '/admin/TRSeminarView/' + seminar.id,
          (err) => {
            setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
            setSaving(false)
          }
        )
      } else {
        //console.log(params)
        addSeminar(newFiles, params,
          (data) => window.location.href = '/admin/TRSeminarView/' + data.inserted_id,
          (err) => {
            setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
            setSaving(false)
          }
        )
      }
    } else {
      setSaving(false)
    }
  }

  const handleCancel = () => {
    setAlert(<ConfirmCancelMessage onConfirm={redirectToMain} onCancel={hideAlert} />)
  }

  const redirectToMain = () => {
    window.location.href="/admin/TRLabView/" + lab.id
  }

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} >
          <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color="inherit" href="/admin/TranslationReceptionLabs">
                Εργαστήρια μετάφρασης και πρόσληψης
              </Link>
              <Link color="inherit" href={'/admin/TRLabView/' + lab.id}>{lab.title}</Link>
              {
                seminar.id &&
                <Link color="inherit" href={'/admin/TRSeminarView/' + seminar.id}>{seminar.title}</Link>
              }
              <Typography color="textPrimary">
                {seminar.id ? 'Επεξεργασία σεμιναρίου ' : 'Προσθήκη σεμιναρίου'}
              </Typography>
            </Breadcrumbs>
          </div>
        </Grid>

       <Grid item xs={12} style={{ paddingTop : '20px'}}>
         <Typography variant='h4'>{seminar.id ? 'Επεξεργασία σεμιναρίου' : 'Προσθήκη νέου σεμιναρίου'}</Typography>

         <TextField
          error={Boolean(errors.title)}
          label='Τίτλος'
          style = {{width:500, paddingTop:10, marginTop:0}}
          value={title}
          onChange={handleTitleChange}
          />
          <FormHelperText error>{errors.title}</FormHelperText>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormLabel>Εικόνα</FormLabel>
              <br/>
              <input
                accept="image/*"
                className={classes.fileinput}
                id="contained-button-image"
                type="file"
                onChange={uploadImage}
              />
              <label htmlFor="contained-button-image">
                <Button variant="contained" color="primary" component="span">
                  Ανεβασμα εικονας
                </Button>
              </label>
              <FormHelperText error>{errors.image}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={8}>
              {image && !(image instanceof File) && <img alt='Εικόνα' src={labsTRImagesURL+lab.id+'/'+image.name} className={classes.labImage}/>}
              {image && (image instanceof File) && image.name}
            </Grid>
          </Grid>

          <TextField
           error={Boolean(errors.coordinator)}
           label='Συντονιστής'
           style = {{width:500, paddingTop:10, marginTop:0}}
           value={coordinator}
           onChange={handleCoordinatorChange}
           />
          <FormHelperText error>{errors.coordinator}</FormHelperText>

          <TextField
           error={Boolean(errors.speaker)}
           label='Εισηγητής'
           style = {{width:500, paddingTop:10, marginTop:0}}
           value={speaker}
           onChange={handleSpeakerChange}
           />
         <FormHelperText error>{errors.speaker}</FormHelperText>

          <FormLabel>Πληροφορίες</FormLabel>
          <Editor id="info" tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
           onInit={(evt, editor) => infoHtmlEditorRef.current = editor}
           initialValue={info}
           init={{
             height: 300,
             width: 800,
             entity_encoding : "raw",
             plugins: [
               'advlist autolink lists link image charmap print preview anchor',
               'searchreplace visualblocks code fullscreen',
               'media table paste code help wordcount imagetools'
             ],
             menubar: false,
             toolbar: 'undo redo | formatselect | ' +
             'bold italic underline | alignleft aligncenter ' +
             'alignright alignjustify | outdent indent | ' +
             'removeformat | help',
             //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
           }}
           />
          <FormHelperText error>{errors.info}</FormHelperText>

          <TextField
           error={Boolean(errors.attendants)}
           label='Συμμετέχοντες'
           style = {{width:500, paddingTop:10, marginTop:0}}
           value={attendants}
           onChange={handleAttendantsChange}
           multiline rows={4}
           variant='outlined'
           />
         <FormHelperText error>{errors.attendants}</FormHelperText>

         <TextField
          error={Boolean(errors.videoLink)}
          label='Link σε Βίντεο σεμιναρίου'
          style = {{width:500, paddingTop:10, marginTop:0}}
          value={videoLink}
          onChange={handleVideoLinkChange}
          />
        <FormHelperText error>{errors.videoLink}</FormHelperText>

        <FormLabel error={Boolean(errors.labels)}>Λέξεις-κλειδιά (πληκτρολογήστε και πατήστε Enter)</FormLabel>
        <br/>
        <TagsInput
          value={labels}
          onChange={handleChangeLabels}
          tagProps={{ className: "react-tagsinput-tag info"}}
          inputProps={{placeholder: 'Προσθήκη'}}
        />
        <FormHelperText error>{errors.labels}</FormHelperText>

         <TextField
          error={Boolean(errors.place)}
          label='Τόπος συνάντησης'
          style = {{width:500, paddingTop:10, marginTop:0}}
          value={place}
          onChange={handlePlaceChange}
          />
        <FormHelperText error>{errors.place}</FormHelperText>

        <TextField
         error={Boolean(errors.time)}
         label='Χρόνος συνάντησης'
         style = {{width:500, paddingTop:10, marginTop:0}}
         value={time}
         onChange={handleTimeChange}
         />
         {/*type="datetime-local"
         InputLabelProps={{
            shrink: true,
          }}*/}
       <FormHelperText error>{errors.time}</FormHelperText>

      <TextField
         error={Boolean(errors.meetingLink)}
         label='Δεσμός εξ αποστάσεως συνάντησης'
         style = {{width:500, paddingTop:10, marginTop:0}}
         value={meetingLink}
         onChange={handleMeetingLinkChange}
         />
       <FormHelperText error>{errors.meetingLink}</FormHelperText>

       <FormLabel>Συνοπτική Παρουσίαση</FormLabel><br/>
       <Button color='primary' onClick={() => prepareToEditText({}, -1, 'summary')}>Προσθηκη κειμένου</Button>
       <Button color='primary' onClick={() => prepareToEditUpload({}, -1, 'summary')}>Μεταφόρτωση αρχείου</Button>
       <Button color='primary' onClick={() => prepareToEditFile({}, -1, 'summary')}>Link σε αρχείο</Button>
       <TextMaterialEditList textList={summaryTexts} textType='summary'
          prepareToEditText={prepareToEditText} removeText={removeText}/>
       <FileMaterialEditList fileList={summaryFiles} fileType='summary'
          prepareToEditFile={prepareToEditFile} prepareToEditUpload={prepareToEditUpload} removeFile={removeFile}/>

       <FormLabel>Υλικό σεμιναρίου</FormLabel><br/>
       <Button color='primary' onClick={() => prepareToEditText({}, -1, 'material')}>Προσθηκη κειμένου</Button>
       <Button color='primary' onClick={() => prepareToEditUpload({}, -1, 'material')}>Μεταφόρτωση αρχείου</Button>
       <Button color='primary' onClick={() => prepareToEditFile({}, -1, 'material')}>Link σε αρχείο</Button>
       <TextMaterialEditList textList={materialTexts} textType='material'
          prepareToEditText={prepareToEditText} removeText={removeText}/>
       <FileMaterialEditList fileList={materialFiles} fileType='material'
          prepareToEditFile={prepareToEditFile} prepareToEditUpload={prepareToEditUpload} removeFile={removeFile}/>

       <FormLabel>Σύνδεσμοι προς την πλατφόρμα</FormLabel><br/>
       <Button color='primary' onClick={() => setOpenLyricsForm(true)}>Link σε χωρίο</Button>
        <Button color='primary' onClick={() => setOpenNoteForm(true)}>Link σε μεταφραστική σημείωση</Button>
       <Button color='primary' onClick={() => setOpenCommentForm(true)}>Link σε σχόλιο</Button>
       <List dense>
       {platform_arr.map((link, idx) =>
         <ListItem key={idx}>
           <Link href={link.link}>{link.title}</Link>
           <IconButton aria-label="delete" onClick={() => removePlatformLink(idx)}>
             <DeleteIcon fontSize="small" />
           </IconButton>
         </ListItem>
       )}
       </List>

       <FormLabel>Σύνδεσμοι σε λήμματα του Λεξικού</FormLabel><br/>
       <Button color='primary' onClick={() => setOpenLexiconForm(true)}>Link σε λήμμα</Button>
       <List dense>
         {lexicon_arr.map((link, idx) =>
           <ListItem key={idx}>
             <Link href={link.link}>{link.name}</Link>
             <IconButton aria-label="delete" onClick={() => removeLexiconLink(idx)}>
               <DeleteIcon fontSize="small" />
             </IconButton>
           </ListItem>
         )}
       </List>

       <Autocomplete
         multiple
         id="work-tags"
         options={availableTagsWork}
         getOptionLabel={(option) => option.name}
         getOptionSelected={(option, value) => option.id === value.id}
         onChange={handleWorkTagsChange}
         value={workTags}
         style={{maxWidth: 800}}
         renderInput={(params) => (
           <TextField
             {...params}
             variant="standard"
             label="Έργα που αφορά"
             placeholder="Έργα που αφορά"
             error={Boolean(errors.workTags)}
             helperText={errors.workTags}
           />
         )}
       />
       <br/>

       <FormLabel>Εξωτερικοί σύνδεσμοι</FormLabel>
       <Container maxWidth='xs' className={classes.inner_form}>
         <TextField label="Τίτλος"
            value={linkTitle}
            onChange={handleChangeLinkTitle}
           error={Boolean(errors.linkTitle)}/>
         <FormHelperText error>{errors.linkTitle}</FormHelperText>

         <TextField label="Link"
             value={linkHref}
             onChange={handleChangeLinkHref}
             error={Boolean(errors.linkHref)}/>
         <FormHelperText error>{errors.linkHref}</FormHelperText>

         <Button onClick={addOrEditLink} size='sm'>Προσθηκη συνδεσμου</Button>
         <Button onClick={resetLinkFields} size='sm'>Καθαρισμος</Button>
       </Container>
       <List dense>
         {links_arr.map((link, idx) =>
           <ListItem key={idx}>
             <Link href={link.link}>{link.title}</Link>
             <IconButton aria-label="edit" onClick={() => prepareToEditLink(link, idx)}>
               <EditIcon fontSize="small"/>
             </IconButton>
             <IconButton aria-label="delete" onClick={() => removeLink(idx)}>
               <DeleteIcon fontSize="small" />
             </IconButton>
           </ListItem>
         )}
       </List>

       {/*<FormLabel>Forum</FormLabel><br/>
       <Button color='primary' onClick={() => setOpenForumForm(true)}>Link σε συζήτηση</Button>
       <List dense>
         {forum_arr.map((link, idx) =>
           <ListItem key={idx}>
             <Link href={link.link}>{link.title}</Link>
             <IconButton aria-label="delete" onClick={() => removeForumLink(idx)}>
               <DeleteIcon fontSize="small" />
             </IconButton>
           </ListItem>
         )}
       </List>*/}

        <Button color="rose" onClick={handleSubmit} disabled={saving}>Καταχωριση</Button>
        <Button onClick={handleCancel}>Ακυρωση</Button>
       </Grid>
      </Grid>

      {alert}

      <MaterialTextForm open={openTextForm} onSave={addOrEditText}
        onClose={() => setOpenTextForm(false)} title={editingMaterialTitle} text={editingMaterialText}
        handleTitleChange={handleMaterialTitleChange} availableSections={availableSections}
        errors={materialErrors}/>

      <MaterialFileForm open={openFilesForm} onSave={addOrEditFileLink}
        onClose={() => setOpenFilesForm(false)} title={editingMaterialTitle} file={editingMaterialFile}
        handleTitleChange={handleMaterialTitleChange} handleFileChange={handleMaterialFileChange}
        errors={materialErrors}/>

      <MaterialUploadForm open={openUploadForm} onSave={addOrEditFileUpload}
        onClose={() => setOpenUploadForm(false)} title={editingMaterialTitle} file={file}
        handleTitleChange={handleMaterialTitleChange} handleFileUpload={uploadFile}
        errors={materialErrors}/>

      <LinkToSection open={openLyricsForm} onSave={addPlatformLink}
        onClose={() => setOpenLyricsForm(false)}/>

      <LinkToComment open={openCommentForm} onSave={addPlatformLink}
        onClose={() => setOpenCommentForm(false)}/>

      <LinkToTranslationNote open={openNoteForm} onSave={addPlatformLink}
        onClose={() => setOpenNoteForm(false)}/>

      <LinkToLexiconEntryForm open={openLexiconForm} onSave={addLexiconLink}
        onClose={() => setOpenLexiconForm(false)} return_unformatted/>

    </div>
  )
}

export default SeminarEdit
