import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Button from "components/CustomButtons/Button.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FormHelperText from '@material-ui/core/FormHelperText';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ErrorAlert, WarningAlert } from 'views/CustomAlerts/CustomAlerts.js'

import { uploadScholarComments } from 'clients/commentsClient.js'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5),
    margin: '15px',
    maxWidth: 800,
   // height: 300,
    width: 600,
  },
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  input: {
    display: 'none',
  },
}))

export default function UploadComment (){
  const classes = useStyles();
  const spacing = 2;

  const [file, setFile] = React.useState(null)
  const [error, setError] = React.useState('')

  //Snackbar messages (to inform user of success)
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //form functions
  const uploadFile = (e) => {
    //console.log('file upload')
    //console.log(e.target.files[0].name)
    const allowedExtensions = /(\.xlsx)$/i;
    /*
    // TODO: check for size???
    if (e.target.files[0].size > 1000){
      console.log('too big file')
    } else {*/
    if (!allowedExtensions.exec(e.target.files[0].name)) {
      setError("Πρέπει να ανεβάσετε αρχείο Excel με κατάληξη .xlsx")
      setFile(null)
    } else {
      setFile(e.target.files[0])
      setError('')
    }
  }

  const handleAdd = () => {
    if (!file){
      setError("Υποχρεωτικό πεδίο")
    } else {
      setError('')

      uploadScholarComments(file,
        (data) => {
          //show messages
          if (data.message===''){
            setOpenSnackbar(true);
          } else {
            const message = 'Η καταχώριση έγινε με επιτυχία, με εξαίρεση τα εξής:' + data.message
            setAlert(<WarningAlert message={message} hideAlert={hideAlert}/>)
          }
        },
        (err) => setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
      )
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} >
          <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link color="inherit" href="/admin/SelectTransSem">
                  Main
                </Link>

                <Link color="inherit" href="/admin/bookSelection">
                  Αριστοτελικά έργα, Μεταφράσεις, Σχόλια
                </Link>
                <Typography color="textPrimary">Ανέβασμα σχολίων</Typography>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid item xs={12} style={{ paddingTop : '20px'}}>
          <Grid container justify="center" spacing={spacing} >
            <Grid>
              <Paper className={classes.paper}>
                <Grid item xs={12} container spacing={2}>
                  <input
                    accept=".xlsx"
                    className={classes.input}
                    id="contained-button-file-excel"
                    type="file"
                    onChange={uploadFile}
                    value={''}
                  />
                  <label htmlFor="contained-button-file-excel">
                    <Button variant="contained" color="primary" component="span">
                      Ανεβασμα αρχειου
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  {file && file.name}
                </Grid>
                <Grid item xs={6}>
                  <FormHelperText error>{error}</FormHelperText>
                </Grid>
                <Grid item xs={12} style = {{paddingTop:20}}>
                  <Button color="primary" onClick={handleAdd}>Προσθήκη</Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {alert}

      <Snackbar anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        >
        <Alert onClose={handleCloseSnackbar} severity="success">
          H καταχώριση έγινε με επιτυχία
        </Alert>
      </Snackbar>
    </div>
  )
}
