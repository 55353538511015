import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

/*import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";*/
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
//import Button from "components/CustomButtons/Button.js";
import Sidebar from "components/Sidebar/Sidebar.js";


import { green } from '@material-ui/core/colors';
import { blue } from '@material-ui/core/colors';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import Grid from '@material-ui/core/Grid';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


// @material-ui/icons
/*import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Close from "@material-ui/icons/Close";
// core components
import CardIcon from "components/Card/CardIcon.js";
import ReactTable from "components/ReactTable/ReactTable.js";*/

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import NavPills from "components/NavPills/NavPills.js";

import Info from "@material-ui/icons/Info";
import LocationOn from "@material-ui/icons/LocationOn";

//import { WorkAdminActions } from "./AdminUsersGroupsButtonsActions.js"
import GroupMembersTab from './GroupMembersTab.js'
import UsergroupsTab from './UsergroupsTab.js'
import UsersTab from './UsersTab.js'
import RolesMembersTab from './RolesMembersTab.js'

import { getAllUsers } from "clients/usersClient.js"
import { getAllUsergroups } from "clients/usergroupsClient.js"
import { getAllRoles } from "clients/rolesClient.js"
import { getAllWorks } from "clients/worksClient.js";
import { fetchForumsApi } from 'views/Forums/api/forum.js'
import { fetchAllWithGET } from 'clients/clientUtils.js'
import { sortWorksAndBooks } from 'sorting.js'

import { ConfirmDeleteMessage } from 'views/CustomAlerts/ConfirmationDialog.js'
import { ErrorAlert, WarningAlert } from 'views/CustomAlerts/CustomAlerts.js'

const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },


    exampleWrapper: {
      position: 'relative',
      marginTop: theme.spacing(0),
      height: 80,
    },

    speedDial: {
      position: 'absolute',
      '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
    BlogIconCol: {
      backgroundColor: green[100],
      color: green[600],
    },
    WikiIconCol: {
      backgroundColor: "#2C8CF4",
      color: blue[600],
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    },

    LabelCol: {
      color:"#3f51b5",
      fontweight: "bold"
    },

	cardFix: {
      fontSize: "1em!important",
    },

	buttonAdd: {
      margin: "30px auto 0 auto",
	  display: "block",
    },



}));




export default function AdminUsersGroupsPrivs() {

  const [Usersdata, setUsersData] = React.useState([]);
  const [Groupsdata, setGroupsdata] = React.useState([])
  const [Rolesdata, setRolesdata] = React.useState({})
  const [Worksdata, setWorksdata] = React.useState([])
  const [forumsdata, setForumsdata] = React.useState([])
  const [labsAllTypes, setLabsAllTypes] = React.useState({})

  const classes = useStyles();

  const loadDataForActiveTab = (active) => {
    switch (active) {
      case 0:
        getAllUsers(
          (data) => {
            setUsersData(data.users_all)
          },
          (err) => console.log(err)
        )

        getAllUsergroups(
          (data) => {
            setGroupsdata(data.usergroups)
          },
          (err) => console.log(err)
        )

        getAllRoles(
          (data) => setRolesdata(data.roles),
          (err) => console.log(err)
        )
        break;

      case 1:
        getAllUsergroups(
          (data) => {
            setGroupsdata(data.usergroups)
          },
          (err) => console.log(err)
        )
        //loadGroupMembers()

        getAllWorks(
          (data) => setWorksdata(sortWorksAndBooks(data.works)),
          (err) => console.log(err)
        )

        getAllTypesLabs()

        fetchForumsApi(0,
          (data) => setForumsdata(data.forums),
          (err) => console.log(err)
        )
        break;

      case 2:
        getAllRoles(
          (data) => setRolesdata(data.roles),
          (err) => console.log(err)
        )

        getAllWorks(
          (data) => setWorksdata(sortWorksAndBooks(data.works)),
          (err) => console.log(err)
        )

        getAllTypesLabs()

        fetchForumsApi(0,
          (data) => setForumsdata(data.forums),
          (err) => console.log(err)
        )
        break;

      case 3:
          getAllUsergroups(
            (data) => {
              setGroupsdata(data.usergroups)
            },
            (err) => console.log(err)
          )

          getAllTypesLabs()
          break;

      default:
        ;
    }
  }

  const getAllTypesLabs = () => {
    //console.log('get all types')
    // TODO: add edu labs call
    const paths_array = ['translation_labs']

    fetchAllWithGET(paths_array,
      (data_array) => {
        //console.log('got data')
        let newLabs = {}
        data_array.forEach(data => {
          if (data.translation_labs){
            newLabs.tr = data.translation_labs
          }
          // TODO: replace with edu labs call
        })
        //newLabs.edu = [{id:1, title:'eduLab1'}, {id:2, title: 'eduLab2'}]
        //console.log(newLabs)

        setLabsAllTypes(newLabs)
      },
      (err) => console.log(err)
    )
  }

  React.useEffect(() => {
    loadDataForActiveTab(0)
  }, [])

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  const showConfirmDelete = (id, onConfirmClick) => {
    setAlert(<ConfirmDeleteMessage onConfirm={() => { hideAlert(); onConfirmClick(id); }} onCancel={hideAlert}/>)
  }

  //Snackbar messages (to inform user of success)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const handleShowSuccessMessage = (message) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message)
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (

    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

    <Grid container className={classes.root} spacing={2}>
    <Grid item xs={12} >
      <div>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href="/admin/SelectTransSem">
            Main
          </Link>
          <Link color="inherit" href="/admin/Seminars">
            Διαχείριση
          </Link>
      </Breadcrumbs>
      </div>
    </Grid>
    <Grid item xs={12} style={{ paddingTop : '20px'}}>
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={8}>
      {/*  <h3 className={classes.pageSubcategoriesTitle}>Page Subcategories</h3> */}
        <br />
        <NavPills
          color="warning"
          alignCenter
          onChange={loadDataForActiveTab}
          tabs={[
            {
              tabButton: "Χρήστες",
              tabIcon: Info,
              tabContent: (
                <UsersTab Usersdata={Usersdata} updateUserData={setUsersData} handleShowSuccessMessage={handleShowSuccessMessage}
                  showErrorAlert={(msg) => setAlert(<ErrorAlert message={msg} hideAlert={hideAlert}/>)}
                  Rolesdata={Rolesdata} Groupsdata={Groupsdata}
                />
              )
            },

            {
              tabButton: "Μέλη ομάδας χρηστών",
              tabIcon: LocationOn,
              tabContent: (
                <GroupMembersTab Groupsdata={Groupsdata} handleShowSuccessMessage={handleShowSuccessMessage}
                  showWarningAlert={(msg) => setAlert(<WarningAlert message={msg} hideAlert={hideAlert}/>)}
                  showErrorAlert={(msg) => setAlert(<ErrorAlert message={msg} hideAlert={hideAlert}/>)}
                  showConfirmDelete={showConfirmDelete} Worksdata={Worksdata} labsdata={labsAllTypes}
                  forumsdata={forumsdata}
                  />
              )
            },

            {
              tabButton: "Ανάθεση ρόλων σε χρήστες",
              tabIcon: LocationOn,
              tabContent: (
                <RolesMembersTab Rolesdata={Rolesdata} Worksdata={Worksdata} labsdata={labsAllTypes}
                  forumsdata={forumsdata}
                  handleShowSuccessMessage={handleShowSuccessMessage}
                  showWarningAlert={(msg) => setAlert(<WarningAlert message={msg} hideAlert={hideAlert}/>)}
                  showErrorAlert={(msg) => setAlert(<ErrorAlert message={msg} hideAlert={hideAlert}/>)}
                  showConfirmDelete={showConfirmDelete}
                  />
              )
            },

            {
              tabButton: "Ανάθεση ρόλων σε Ομάδες χρηστών",
              tabIcon: LocationOn,
              tabContent: (
                <UsergroupsTab Groupsdata={Groupsdata} updateUsergroupData={setGroupsdata} handleShowSuccessMessage={handleShowSuccessMessage}
                  showErrorAlert={(msg) => setAlert(<ErrorAlert message={msg} hideAlert={hideAlert}/>)}
                  showConfirmDelete={showConfirmDelete} labsAllTypes={labsAllTypes}
                />
              )
            },

          ]}
        />
      </GridItem>
    </GridContainer>

    </Grid>

    </Grid>


      {alert}

      <Snackbar anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>

  );
}
