//import axios from 'axios';
import { fetchWithMethod, fetchWithFile } from 'clients/clientUtils.js'

export const createPostApi = (newPost, newFile, success, errorFunc) => {
  //return axios.post(POST_CREATE_URL, newPost, getConfig());
  return fetchWithFile('post', 'POST', newFile, newPost, success, errorFunc)
};

export const deletePostApi = (id, threadID, success, errorFunc) => {
  //return axios.delete(POST_URL + id + POST_DELETE_URL, getConfig());
  const params = {id: id, thread_id: threadID}
  //console.log(params)
  return fetchWithMethod('post', 'DELETE', params, success, errorFunc)
};

export const editPostApi = (newPost, newFile, success, errorFunc) => {
  /*return axios.put(
    POST_URL + id + POST_EDIT_URL,
    {content: content},
    getConfig(),
  );*/
  return fetchWithFile('post', 'PUT', newFile, newPost, success, errorFunc)
};

export const restorePostApi = (id, threadID, success, errorFunc) => {
  const params = {id: id, thread_id: threadID}
  return fetchWithMethod('post/restore', 'PUT', params, success, errorFunc)
}

export const likePostApi = (vals, success, errorFunc) => {
  return fetchWithMethod('post/like', 'PUT', vals, success, errorFunc)
}
