import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Divider from '@material-ui/core/Divider';

import EditIcon from '@material-ui/icons/Edit';
import ReadIcon from '@material-ui/icons/EventNote'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'

import { labsEduImagesURL } from 'clients/clientConfig.js'
import { copyLab } from 'clients/labEducationEtcClient.js'

const useStyles = makeStyles((theme) => ({
  left: {
    textAlign: 'left',
  },
  centered: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  labImage: {
    height: 120,
    width: 180,
    margin: 'auto',
    display: 'block'
  },
  divider: {
    marginTop: '5px',
    marginBottom: '5px',
  }
}))

const LabThumbnail = (props) => {
  const { lab, writeAccess } = props
  const classes = useStyles()
  const parse = require('html-react-parser');

  const [disableCopy, setDisableCopy] = React.useState(false)

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  const handleCopyLab = (id) => {
    setDisableCopy(true)
    const params = {lab_id: id}
    //console.log(params)
    copyLab(params,
      (data) => window.location.href = '/admin/EduLabFullView/' + data.inserted_id,
      (err) => {
        setDisableCopy(false)
        setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
      }
    )
  }

  return(
    <Card>

      <CardActionArea href={"/admin/EduLabPreview/"+lab.id}>
        <CardHeader className="AristosCardHeader" title={lab.title} avatar={
          !lab.visible && <VisibilityOffIcon color='disabled'/>
        }/>
        <CardMedia className="AristosLabImage" image={labsEduImagesURL+lab.id+'/'+lab.image} title={lab.title}/>
        <CardContent>
          <Typography variant='body2' component='div'>
            {parse(lab.info || '')}<br/>
            Γλώσσα: {lab.language}
            <Divider className={classes.divider}/>

            <Grid container spacing={2}>
               <Grid item xs={4} className={classes.left}>Έναρξη {lab.labDateFmt}</Grid>
               <Grid item xs={4} className={classes.centered}>{lab.duration} </Grid>
               <Grid item xs={4} className={classes.right}>
                 <LocationOnIcon/>{lab.location}
               </Grid>
            </Grid>

          </Typography>
        </CardContent>
      </CardActionArea>

      <CardActions>
        <Tooltip title='Προβολή'>
          <IconButton aria-label="go to view" href={"/admin/EduLabPreview/"+lab.id}><ReadIcon style={{color:"#387BE1", marginTop:"2px"}}/></IconButton>
        </Tooltip>
        {
          writeAccess &&
          <>
            <Tooltip title='Επεξεργασία'>
              <IconButton aria-label="go to edit" href={"/admin/EduLabEdit/"+lab.lab_type+"/"+lab.id}><EditIcon style={{color:"#EBBA1A"}}/></IconButton>
            </Tooltip>
            <Tooltip title='Αντιγραφή'>
              <IconButton aria-label="copy" onClick={() => handleCopyLab(lab.id)} disabled={disableCopy}><FileCopyIcon style={{color:"#00BA1A"}}/></IconButton>
            </Tooltip>
          </>
        }
      </CardActions>
      {alert}

    </Card>
  )
}

export default LabThumbnail
