export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const FETCH_HOME_REQUEST = 'FETCH_HOME_REQUEST';
export const FETCH_HOME_SUCCESS = 'FETCH_HOME_SUCCESS';
export const FETCH_HOME_FAILURE = 'FETCH_HOME_FAILURE';
export const FETCH_FORUM_REQUEST = 'FETCH_FORUM_REQUEST';
export const FETCH_FORUM_SUCCESS = 'FETCH_FORUM_SUCCESS';
export const FETCH_FORUM_FAILURE = 'FETCH_FORUM_FAILURE';
export const FETCH_THREAD_REQUEST = 'FETCH_THREAD_REQUEST';
export const FETCH_THREAD_SUCCESS = 'FETCH_THREAD_SUCCESS';
export const FETCH_THREAD_FAILURE = 'FETCH_THREAD_FAILURE';
export const CREATE_FORUM_REQUEST = 'CREATE_FORUM_REQUEST';
export const CREATE_FORUM_SUCCESS = 'CREATE_FORUM_SUCCESS';
export const CREATE_FORUM_FAILURE = 'CREATE_FORUM_FAILURE';
export const CHANGE_FORUM_THREAD_PAGE = 'CHANGE_FORUM_THREAD_PAGE';
export const DELETE_FORUM_REQUEST = 'DELETE_FORUM_REQUEST';
export const DELETE_FORUM_SUCCESS = 'DELETE_FORUM_SUCCESS';
export const DELETE_FORUM_FAILURE = 'DELETE_FORUM_FAILURE';

export const CREATE_THREAD_REQUEST = 'CREATE_THREAD_REQUEST';
export const CREATE_THREAD_SUCCESS = 'CREATE_THREAD_SUCCESS';
export const CREATE_THREAD_FAILURE = 'CREATE_THREAD_FAILURE';
export const CREATE_THREAD_SAVE = 'CREATE_THREAD_SAVE';
export const CREATE_THREAD_TOGGLE = 'CREATE_THREAD_TOGGLE';
export const DELETE_THREAD_REQUEST = 'DELETE_THREAD_REQUEST';
export const DELETE_THREAD_SUCCESS = 'DELETE_THREAD_SUCCESS';
export const DELETE_THREAD_FAILURE = 'DELETE_THREAD_FAILURE';
export const EDIT_THREAD_REQUEST = 'EDIT_THREAD_REQUEST';
export const EDIT_THREAD_SUCCESS = 'EDIT_THREAD_SUCCESS';
export const EDIT_THREAD_FAILURE = 'EDIT_THREAD_FAILURE';
export const EDIT_THREAD_CANCEL = 'EDIT_THREAD_CANCEL';
export const CHANGE_THREAD_PAGE = 'CHANGE_THREAD_PAGE';
export const RESTORE_THREAD_REQUEST = 'RESTORE_THREAD_REQUEST';
export const RESTORE_THREAD_SUCCESS = 'RESTORE_THREAD_SUCCESS';
export const RESTORE_THREAD_FAILURE = 'RESTORE_THREAD_FAILURE';
export const TOGGLE_THREAD_PINNED_REQUEST = 'TOGGLE_THREAD_PINNED_REQUEST';
export const TOGGLE_THREAD_PINNED_SUCCESS = 'TOGGLE_THREAD_PINNED_SUCCESS';
export const TOGGLE_THREAD_PINNED_FAILURE = 'TOGGLE_THREAD_PINNED_FAILURE';
export const TOGGLE_THREAD_FAVOURITE_REQUEST = 'TOGGLE_THREAD_FAVOURITE_REQUEST';
export const TOGGLE_THREAD_FAVOURITE_SUCCESS = 'TOGGLE_THREAD_FAVOURITE_SUCCESS';
export const TOGGLE_THREAD_FAVOURITE_FAILURE = 'TOGGLE_THREAD_FAVOURITE_FAILURE';
export const COPY_THREAD_REQUEST = 'COPY_THREAD_REQUEST';
export const COPY_THREAD_SUCCESS = 'COPY_THREAD_SUCCESS';
export const COPY_THREAD_FAILURE = 'COPY_THREAD_FAILURE';
export const LIKE_THREAD_POST_REQUEST = 'LIKE_THREAD_POST_REQUEST';
export const LIKE_THREAD_POST_SUCCESS = 'LIKE_THREAD_POST_SUCCESS';
export const LIKE_THREAD_POST_FAILURE = 'LIKE_THREAD_POST_FAILURE';
export const TOGGLE_NOTIFICATIONS_THREAD_REQUEST = 'TOGGLE_NOTIFICATIONS_THREAD_REQUEST';
export const TOGGLE_NOTIFICATIONS_THREAD_SUCCESS = 'TOGGLE_NOTIFICATIONS_THREAD_SUCCESS';
export const TOGGLE_NOTIFICATIONS_THREAD_FAILURE = 'TOGGLE_NOTIFICATIONS_THREAD_FAILURE';
export const FORWARD_THREAD_REQUEST = 'FORWARD_THREAD_REQUEST';
export const FORWARD_THREAD_SUCCESS = 'FORWARD_THREAD_SUCCESS';
export const FORWARD_THREAD_FAILURE = 'FORWARD_THREAD_FAILURE';

export const CREATE_POST_REQUEST = 'CREATE_POST_REQUEST';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAILURE = 'CREATE_POST_FAILURE';
export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAILURE = 'DELETE_POST_FAILURE';
export const EDIT_POST_REQUEST = 'EDIT_POST_REQUEST';
export const EDIT_POST_SUCCESS = 'EDIT_POST_SUCCESS';
export const EDIT_POST_FAILURE = 'EDIT_POST_FAILURE';
export const EDIT_POST_CANCEL = 'EDIT_POST_CANCEL';
export const RESTORE_POST_REQUEST = 'RESTORE_POST_REQUEST';
export const RESTORE_POST_SUCCESS = 'RESTORE_POST_SUCCESS';
export const RESTORE_POST_FAILURE = 'RESTORE_POST_FAILURE';

export const FETCH_SEARCH_REQUEST = 'FETCH_SEARCH_REQUEST';
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS';
export const FETCH_SEARCH_FAILURE = 'FETCH_SEARCH_FAILURE';
export const CHANGE_SEARCH_RESULTS_PAGE = 'CHANGE_SEARCH_RESULTS_PAGE';
