import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckIcon from '@material-ui/icons/Check';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import parseLyricsFile from './LyricsFileParser.js'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  LblClass: {
    color: "#415EA2",
    fontWeight: "700"
  },
  workSelectionField: {
    width: '100%'
  },
  input: {
    display: 'none',
  },
  success: {
    color: 'green',
  },
}));

const AddLyricsForm = (props) => {
  const classes = useStyles();
  //const theme = useTheme();

  const { sectionId, works, errors } = props

  let defaultExpanded = []
  let defaultSelected = ''
  if (sectionId){
    //console.log(sectionId)
    const [workId, bookId] = sectionId.split('-')
    defaultExpanded = [workId]
    defaultSelected = workId + '-' + bookId
  }
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  const [selected, setSelected] = React.useState(defaultSelected)
  const [fileParseErrors, setFileParseErrors] = React.useState([])
  const [fileParseSuccess, setFileParseSuccess] = React.useState('')

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
    //console.log(nodeIds)
    //console.log('toggle fired')
  };

  const handleSelect = (event, nodeIds) => {
    //console.log('select fired')
    if (nodeIds.indexOf('-') >= 0){
      //only select if it is a leaf node (book)
      props.handleSelect(event, nodeIds)
      setSelected(nodeIds)
    }/* else {
      console.log("won't select "+nodeIds)
      event.preventDefault();
    }*/
  }

  const [file, setFile] = React.useState(null)
  const uploadFile = (e) => {
    //console.log('file upload')
    //console.log(e.target.files[0].name)
    const allowedExtensions = /(\.txt)$/i;
    /*
    // TODO: check for size???
    if (e.target.files[0].size > 1000){
      console.log('too big file')
    } else {*/
    if (!allowedExtensions.exec(e.target.files[0].name)) {
      setFileParseErrors(["Πρέπει να ανεβάσετε αρχείο .txt"])
    } else {
      setFile(e.target.files[0])
      setFileParseErrors([])
      setFileParseSuccess('')

      props.handleFileChange(e.target.files[0])
    }
  }

  const checkFile = () => {
    if (!window.FileReader){
      setFileParseErrors(['Ο browser σας δεν υποστηρίζει αυτή τη δυνατότητα'])
    } else {
      /*
      https://developer.mozilla.org/en-US/docs/Web/API/FileReader
      https://stackoverflow.com/questions/16505333/get-the-data-of-uploaded-file-in-javascript/39515846
      https://www.lambdatest.com/file-reader-api
      */
      const reader = new FileReader()
      reader.onload = function(e) {
        //console.log(reader.result)
        const errors = parseLyricsFile(reader.result)
        //console.log(errors)
        if (errors.length > 0){
          setFileParseErrors(errors)
        } else {
          setFileParseSuccess('Το αρχείο είναι έγκυρο')
        }
      };
      reader.readAsText(file, 'utf-8')
    }
  }

  return (
    <>
      <Grid item xs>
        <FormControl error={Boolean(errors.work)} className={classes.workSelectionField}>
          <Typography gutterBottom variant="body2" className={classes.LblClass}>
            Επιλογή Έργου-Βιβλίου:
          </Typography>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            disableSelection={(sectionId!==undefined && sectionId!=='')}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            style={{ paddingTop : '10px'}}
          >

            {works.map((work) => {
              return (
                <TreeItem key={work.id} classes={{ label: 'pateras' }} nodeId={work.id.toString()} label={work.name}>
                  {work.books && work.books.map((book) => {
                    const book_id = work.id + '-' + book.id
                    return (
                      <TreeItem key={book_id} classes={{ label: 'paidi' }}  nodeId={book_id} label={book.name}/>
                    )
                  })}
                </TreeItem>
              )
            })}

          </TreeView>
          <FormHelperText>{errors.work}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
       {/*
         https://reactjs.org/docs/refs-and-the-dom.html
         https://reactjs.org/docs/uncontrolled-components.html#the-file-input-tag
         https://material-ui.com/components/buttons/ Upload Button
         https://www.geeksforgeeks.org/file-uploading-in-react-js/

         Set input value = '' so that we can upload the same file 2 times in a row https://programmersought.com/article/47732427884/
         */}
        <input
          accept=".txt"
          className={classes.input}
          id="contained-button-file-lyrics"
          type="file"
          onChange={uploadFile}
          value={''}
        />
        <label htmlFor="contained-button-file-lyrics">
          <Button variant="contained" color="primary" component="span">
            Ανεβασμα αρχειου
          </Button>
        </label>
        <FormHelperText error>{errors.file}</FormHelperText>
      </Grid>
      <Grid item xs={6}>
        {file && file.name}
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={checkFile} disabled={!file}>
          Έλεγχος αρχείου&nbsp;<CheckIcon/>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <FormHelperText className={classes.success}>{fileParseSuccess}</FormHelperText>
        <FormHelperText error>{fileParseErrors.map((err,idx) => <span key={idx}>{err}<br/></span>)}</FormHelperText>
      </Grid>
    </>
)
}

const getValidationErrorsForLyrics = (work, file) => {
  //console.log('validating')

  let newErrors = {}
  //console.log(work)
  if (!Boolean(work)){
    newErrors.work = 'Υποχρεωτικό πεδίο'
  }
  if (!file) {
    newErrors.file = 'Υποχρεωτικό πεδίο'
  }
  /*if (lines.length===0){
    newErrors.lines = 'Υποχρεωτικό πεδίο'
  }
  if (sectionText.length===0){
    newErrors.sectionText = 'Υποχρεωτικό πεδίο'
  }*/

  return newErrors;
}



export { AddLyricsForm, getValidationErrorsForLyrics }
