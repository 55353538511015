import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'

import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import Grid from '@material-ui/core/Grid';
import { AddLyricsForm } from 'views/AddLyricsItem/AddLyricsForm.js'
import {EditLyricsForm} from './EditLyricsForm.js'


const AddWorkDialog = (props) => {
  const {handleClose, handleAdd, dialogTitle, dialogButtonLabel, open, workName, workTranslator,
    handleWorkNameChange, handleTranslatorChange, disableSubmit, errors} = props

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}
      maxWidth={"xs"}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent dividers>
          <CustomInput
            labelText={errors.workName ? errors.workName : "Όνομα Έργου"}
            id="WorkName"
            formControlProps={{
              fullWidth: true
            }}
            inputProps = {{
              value: workName,
              onChange: handleWorkNameChange,
            }}
            error={Boolean(errors.workName)}
            />
          <br/>

          <CustomInput
            labelText={errors.workTranslator ? errors.workTranslator : "Μεταφραστής"}
            id="Translator"
            formControlProps={{
              fullWidth: true
            }}
            inputProps = {{
              value: workTranslator || '',
              onChange: handleTranslatorChange,
            }}
            error={Boolean(errors.workTranslator)}
            />

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleAdd} color="primary" disabled={disableSubmit}>
            {dialogButtonLabel}
          </Button>
        </DialogActions>
    </Dialog>
  )
}


const AddBookDialog = (props) => {
  const {handleClose, handleAdd, dialogTitle, dialogButtonLabel, open,
    workId, bookId, bookName, handleWorkIdChange, handleBookNameChange, worksdata,
    classes, disableSubmit, errors} = props

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}
      maxWidth={"xs"}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent dividers>

        <FormControl error={Boolean(errors.workId)}>
           <Select
             MenuProps={{
               className: classes.selectMenu
             }}
             classes={{
               select: classes.select
             }}
             disabled={(bookId > 0)}
             value={workId}
             onChange={handleWorkIdChange}
             inputProps={{
               name: "simpleSelect",
               id: "simple-select"
             }}

             style={{ marginTop : '10px', marginBottom:'20px', width:'250px',}}

           >
             <MenuItem
               disabled
               classes={{
                 root: classes.selectMenuItem
               }}
               value='0'
             >
               Επιλογή Έργου
             </MenuItem>
             {
               worksdata.map((work) =>
                 <MenuItem
                   classes={{
                     root: classes.selectMenuItem,
                     selected: classes.selectMenuItemSelected
                   }}
                   key={work.id}
                   value={work.id}
                 >
                   {work.name}
                 </MenuItem>
              )
             }

           </Select>
           <FormHelperText>{errors.workId}</FormHelperText>
        </FormControl>


        <CustomInput
          labelText={errors.bookName ? errors.bookName : "Όνομα Βιβλίου"}
          id="GroupName"
          formControlProps={{
            fullWidth: true
          }}
          inputProps = {{
            value: bookName,
            onChange: handleBookNameChange
          }}
          error={Boolean(errors.bookName)}
        />

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleAdd} color="primary" disabled={disableSubmit}>
            {dialogButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
  )
}


const AddSectionDialog = (props) => {
  const {handleClose, handleAdd, handleSelectBook,
     dialogTitle, dialogButtonLabel, open, disableSubmit, ...rest} = props
  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}
      maxWidth={"sm"}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent dividers>
          <Grid item xs container direction="column" spacing={2}>
            <AddLyricsForm handleSelect={handleSelectBook} {...rest}
             />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleAdd} color="primary" disabled={disableSubmit}>
            {dialogButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
  )
}

const EditSectionDialog = (props) => {
  const {handleClose, handleEdit, open, disableSubmit, ...rest} = props
  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}
      maxWidth={"sm"}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          ΕΠΕΞΕΡΓΑΣΙΑ ΧΩΡΙΟΥ
        </DialogTitle>
        <DialogContent dividers>
          <Grid item xs container direction="column" spacing={2}>
            <EditLyricsForm {...rest}/>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleEdit} color="primary" disabled={disableSubmit}>
            ΕΠΕΞΕΡΓΑΣΙΑ
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export { AddWorkDialog, AddBookDialog, AddSectionDialog, EditSectionDialog }
