import React from 'react';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Assignment from "@material-ui/icons/Assignment";

import { addWork, editWork, deleteWork } from "clients/worksClient.js";
import { WorkActions } from "./AdminErgBooksHelpers.js"
import { AddWorkDialog } from "./AdminErgBookDialogForms.js"

import { checkNoErrors } from "utils.js"

const WorksTab = (props) => {

  const constructWorkdataRow = (work) => {
    //console.log(work)
    return {
      id: work.id,
      work: work.name,
      booknum: work.books,
      translator: work.translator,
      actions: (
        // we've added some custom button actions
        <WorkActions work={work} handleClickEditWork={handleClickEditWork} handleClickDeleteWork={handleClickDeleteWork}/>
      )
    };
  }

  const handleClickOpenWork = (dialogTitle, dialogButtonLabel) => {
    setDialogTitle(dialogTitle);
    setDialogButtonLabel(dialogButtonLabel);
    setOpenWork(true);
  };

  const handleCloseWork = () => {
    setOpenWork(false);
    setWorkName('')
    setWorkId(0)
    setWorkTranslator('')
    setErrorsWork({})
  };

  const handleAddWork = () => {
    //console.log(workId+bookId+bookName)
    if (validateWork()){
      //console.log('passed')
      setDisableSubmit(true)
      let params = {name: workName, translator: workTranslator}
      if (workId > 0){
        //edit
        params.id = workId;
        //console.log(params)
        editWork(params,
         (data) => {
           //show changes in Table
           let newData = [...Worksdata];
           const changeIndex = newData.findIndex((o) => (o.id === workId))
           const originalWork = newData[changeIndex]
           newData[changeIndex] = {id: workId, name: workName, books: originalWork.books, translator: workTranslator}
           updateWorksData(newData);
           handleCloseWork();
           handleShowSuccessMessage('H καταχώριση έγινε με επιτυχία!')
           setDisableSubmit(false)
         },
         (err) => {
           showErrorAlert(err.message)
           setDisableSubmit(false)
         }
       )
      } else {
        //add new
        addWork(params,
          (data) => {
            //add new work to table
            let newData = [...Worksdata];
            newData.push({id: data.inserted_id, name: workName, books: 0, translator: workTranslator})
            //console.log(newData)
            updateWorksData(newData)
            //close dialog and initialize workName
            handleCloseWork();
            handleShowSuccessMessage('H καταχώριση έγινε με επιτυχία!')
            setDisableSubmit(false)
          },
          (err) => {
            showErrorAlert(err.message)
            setDisableSubmit(false)
          }
        );
      }
    }
  }

  const validateWork = () => {
    let newErrors = {}

    if (workName.length===0){
      newErrors.workName = 'Υποχρεωτικό πεδίο'
    }
    setErrorsWork(newErrors)

    return checkNoErrors(newErrors)
  }

  const handleWorkNameChange = (event) => {
    setWorkName(event.target.value);
  };

  const handleTranslatorChange = (e) => {
    setWorkTranslator(e.target.value)
  }

  const handleClickEditWork = (id, name, translator) => {
     setWorkId(id)
     setWorkName(name)
     setWorkTranslator(translator)
     handleClickOpenWork('Επεξεργασία εργου', 'Επεξεργασία');
  }

  const handleClickDeleteWork = (id) => {
    showConfirmDelete(id, handleDeleteWork)
  }

  const handleDeleteWork = (id) => {
    deleteWork(id,
      () => {
        let newData = Worksdata;
        const pos = newData.findIndex(o => o.id === id);
        /*console.log(Worksdata.current)
        console.log(pos+' '+id)*/
        if (pos >= 0){
          newData.splice(pos, 1)
          //console.log(newData)
          updateWorksData([...newData]);
        }
        handleShowSuccessMessage('H διαγραφή έγινε με επιτυχία!')
      },
      (err) => showErrorAlert(err.message)
    )
  }

  const { Worksdata, updateWorksData, handleShowSuccessMessage, showErrorAlert, showConfirmDelete, classes } = props
  const WorksdataFormatted = Worksdata.map(work => constructWorkdataRow(work))

  const [workName, setWorkName] = React.useState('')
  const [workId, setWorkId] = React.useState(0);
  const [workTranslator, setWorkTranslator] = React.useState('')
  const [errorsWork, setErrorsWork] = React.useState({})
  const [openWork, setOpenWork] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false)

  const [dialogTitle, setDialogTitle] = React.useState('')
  const [dialogButtonLabel, setDialogButtonLabel] = React.useState('')

  return (
    <>
      <Card className={classes.cardFix}>
        <CardHeader className="AristosCardHeader" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4>Έργα</h4>
        </CardHeader>
        <CardBody>
          <ReactTable
            columns={[
              {
                Header: "Έργο",
                accessor: "work"
              },
              {
                Header: "# Βιβλίων",
                accessor: "booknum"
              },
              {
                Header: 'Μεταφραστής',
                accessor: 'translator',
              },
              {
                Header: "Ενέργειες",
                accessor: "actions"
              }
            ]}
            data={WorksdataFormatted}
          />

          <Button className={classes.buttonAdd} onClick={() => handleClickOpenWork('Προσθηκη εργου', 'Προσθηκη')}>Προσθηκη Εργου</Button>

        </CardBody>
      </Card>

      <AddWorkDialog
        handleClose={handleCloseWork}
        handleAdd={handleAddWork}
        dialogTitle={dialogTitle}
        dialogButtonLabel={dialogButtonLabel}
        open={openWork}
        workName={workName}
        handleWorkNameChange={handleWorkNameChange}
        errors={errorsWork}
        disableSubmit={disableSubmit}
        workTranslator={workTranslator} handleTranslatorChange={handleTranslatorChange}
      />
    </>
  )
}

export default WorksTab
