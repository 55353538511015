import React from 'react';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Assignment from "@material-ui/icons/Assignment";

import { addBook, editBook, deleteBook } from "clients/booksClient.js"
import { BookActions } from "./AdminErgBooksHelpers.js"
import { AddBookDialog } from "./AdminErgBookDialogForms.js"

import { checkNoErrors } from "utils.js"

const BooksTab = (props)=> {

   const constructBookdataRow = (book) => {
     return {
       id: book.id,
       work: book.work_name,
       book: book.name,
       sectionsnumber: book.sections,
       actions: (
         // we've added some custom button actions
         <BookActions book={book} handleClickEditBook={handleClickEditBook} handleClickDeleteBook={handleClickDeleteBook}/>
       )
     };
   }

   const handleClickopenBook = (dialogTitle, dialogButtonLabel) => {
     setDialogTitle(dialogTitle);
     setDialogButtonLabel(dialogButtonLabel);
     setopenBook(true);
   };

   const handleClickEditBook = (id, name) =>{
     const [workId, bookId] = id.split('-', 2)
     setWorkId(workId)
     setBookId(bookId)
     setBookName(name)
     handleClickopenBook('Επεξεργασία βιβλίου', 'Επεξεργασία');
   }

   const handleCloseBook = () => {
     setopenBook(false);
     setBookName('')
     setWorkId(0)
     setBookId(0)
     setErrorsBook({})
   };

   const handleAddBook = () => {
     //console.log(workId+bookId+bookName)
     if (validateBook()){
       //console.log('passed')
       setDisableSubmit(true)
       let params = {name: bookName, work_id: workId}
       if (bookId > 0){
         //edit
         params.id = bookId;
         //console.log(params)
         editBook(params,
          (data) => {
            //show changes in Table
            const full_id = workId + "-" + bookId
            let newData = Booksdata;
            const changeIndex = newData.findIndex((o) => (o.id === full_id))
            const originalBook = newData[changeIndex]
            newData[changeIndex] = {id: full_id, name: bookName, work_name: originalBook.work_name, sections: originalBook.sections}
            //console.log(newData)
            updateBooksData(newData);
            handleCloseBook();
            handleShowSuccessMessage('H καταχώριση έγινε με επιτυχία!')
            setDisableSubmit(false)
          },
          (err) => {
            showErrorAlert(err.message)
            setDisableSubmit(false)
          }
        )
       } else {
         //add new
         //console.log(params)
         addBook(params,
           (data) => {
             //add new work to table
             const full_id = workId + "-" + data.inserted_id
             const originalWork = Worksdata.find((w) => (w.id===workId))
             let newData = Booksdata;
             newData.push({id: full_id, name: bookName, work_name: originalWork.name, sections: 0})
             //console.log(newData)
             updateBooksData(newData)
             //close dialog and initialize workName
             handleCloseBook();
             handleShowSuccessMessage('H καταχώριση έγινε με επιτυχία!')
             setDisableSubmit(false)
           },
           (err) => {
             showErrorAlert(err.message)
             setDisableSubmit(false)
           }
         );
       }
     }
   }

   const validateBook = () => {
     let newErrors = {}

     if (workId===0){
       newErrors.workId = 'Υποχρεωτικό πεδίο'
     }
     if (bookName.length===0){
       newErrors.bookName = 'Υποχρεωτικό πεδίο'
     }
     setErrorsBook(newErrors)

     return checkNoErrors(newErrors)
   }

   const handleClickDeleteBook = (id) => {
     showConfirmDelete(id, handleDeleteBook)
   }

   const handleDeleteBook = (id) => {
     const [workId, bookId] = id.split('-', 2)
     const params = { work_id: workId, book_id: bookId }

     deleteBook(params,
       () => {
         let newData = Booksdata;
         const pos = newData.findIndex(o => o.id === id);
         //console.log(pos+' '+id)
         if (pos >= 0){
           newData.splice(pos, 1)
           //console.log(newData)
           updateBooksData([...newData]);
         }
         handleShowSuccessMessage('H διαγραφή έγινε με επιτυχία!')
       },
       (err) => showErrorAlert(err.message)
     )
   }

   const handleBookNameChange = (event) => {
     setBookName(event.target.value);
   };

   const handleWorkIdChange = (event) => {
     setWorkId(event.target.value)
   }

  const { Booksdata, Worksdata, updateBooksData, handleShowSuccessMessage, showErrorAlert, showConfirmDelete, classes } = props
  const BooksdataFormatted = Booksdata.map(book => constructBookdataRow(book))

  const [workId, setWorkId] = React.useState(0);
  const [bookName, setBookName] = React.useState('')
  const [bookId, setBookId] = React.useState(0)
  const [errorsBook, setErrorsBook] = React.useState({})
  const [openBook, setopenBook] = React.useState(false)
  const [disableSubmit, setDisableSubmit] = React.useState(false)

  const [dialogTitle, setDialogTitle] = React.useState('')
  const [dialogButtonLabel, setDialogButtonLabel] = React.useState('')

  return (
    <>
      <Card className={classes.cardFix}>
        <CardHeader className="AristosCardHeader" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4>Βιβλια</h4>
        </CardHeader>
        <CardBody>


          <ReactTable
            columns={[
              {
                Header: "Έργο",
                accessor: "work"
              },
              {
                Header: "Βιβλίο",
                accessor: "book"
              },
              {
                Header: "# Χωρίων",
                accessor: "sectionsnumber"
              },


              {
                Header: "Actions",
                accessor: "actions"
              }
            ]}
            data={BooksdataFormatted}
          />
          <Button className={classes.marginRight} onClick={() => handleClickopenBook('Προσθηκη Βιβλιου', 'Προσθηκη')}>Προσθηκη Βιβλιου</Button>


        </CardBody>
      </Card>

      <AddBookDialog
        handleClose={handleCloseBook}
        handleAdd={handleAddBook}
        dialogTitle={dialogTitle}
        dialogButtonLabel={dialogButtonLabel}
        open={openBook}
        workId={workId}
        handleWorkIdChange={handleWorkIdChange}
        bookId={bookId}
        bookName={bookName}
        handleBookNameChange={handleBookNameChange}
        worksdata={Worksdata}
        classes={classes}
        errors={errorsBook}
        disableSubmit={disableSubmit}
      />

    </>
  )
}

export default BooksTab
