import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  main_body: {
    padding: 20,
    marginTop: 20,
  }
}))

export default function EntryNotFound(){
  const classes = useStyles()

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <GridContainer className={classes.root} spacing={2}>
        <GridItem xs={12} >
          <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color="inherit" href="/admin/Lexicon">
                Λεξικό εννοιών
              </Link>
          </Breadcrumbs>
          </div>
        </GridItem>
        <GridItem  xs={12} style={{ paddingTop : '20px'}}>
          <Paper variant='outlined' className={classes.main_body}>
            <Typography variant='body1'>
              Το λήμμα που ζητήσατε δε βρέθηκε. Είτε δώσατε λάθος διεύθυνση είτε το συγκεκριμένο λήμμα έχει πλέον διαγραφεί.
            </Typography>
          </Paper>
        </GridItem>
      </GridContainer>
    </div>
  )
}
