import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  paper: {
    textAlign: 'center',
    padding: 20
  },
}))

const EduLabSignupResults = (props) => {
  const classes = useStyles()

  let resultsMessage = ''
  let buttonText = ''
  if (props.match.params.status == 1){
    resultsMessage = 'Η εγγραφή σας στο εργαστήριο εγκρίθηκε. Μπορείτε πλέον να μπείτε στο εργαστήριο'
    buttonText = 'Είσοδος στο εργαστήριο'
  } else {
    resultsMessage = 'Η εγγραφή σας έγινε με επιτυχία και είναι σε Εκκρεμή κατάσταση. Θα ενημερωθείτε όταν εγκριθεί'
    buttonText = 'Επιστροφή στη σελίδα του εργαστηρίου'
  }

  const onButtonClick = () => {
    if (props.match.params.status == 1){
      window.location.href = '/admin/EduLabFullView/' + props.match.params.lab_id
    } else {
      window.location.href = '/admin/EduLabPreview/' + props.match.params.lab_id
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} >
          <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link color="inherit" href="/admin/SelectTransSem">
                  Main
                </Link>
                <Typography color="textPrimary">Επιτυχής Εγγραφή</Typography>
            </Breadcrumbs>
          </div>
        </Grid>

       <Grid item xs={12} style={{ paddingTop : '20px'}}>
         <Container maxWidth="md">

          <Paper className={classes.paper}>
            <Typography variant='body1' align='center'>{resultsMessage}</Typography>
            <Button color='primary' onClick={onButtonClick}>
              {buttonText}
            </Button>
          </Paper>

        </Container>
      </Grid>
    </Grid>
    </div>
  )
}

export default EduLabSignupResults
