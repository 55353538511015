import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Typography from '@material-ui/core/Typography';
import SidebarShort from "components/Sidebar/SidebarShort.js";
import CssBaseline from '@material-ui/core/CssBaseline';
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { Copyright } from 'views/StandardIncludes/Footer.js'

import { requestPasswordReset } from 'clients/passwordResetClient.js'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const PasswordReset = () => {
  const classes = useStyles();
  const [email, setEmail] = React.useState('')
  const [emailError, setEmailError] = React.useState('')

  const onEmailChange = (e) => {
    setEmail(e.target.value)

    const email_pattern = /([\w-.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w-]+\.)+)([a-zA-Z]{2,4}))/;
    if (email_pattern.test(e.target.value)){
      setEmailError('')
    } else {
      setEmailError('Μη έγκυρο email')
    }
  }

  const handleResetPassword = () => {
    if (email.length===0){
      setEmailError('Υποχρεωτικό πεδίο')
    } else if (emailError===''){
      //console.log('resetting'+email)
      requestPasswordReset({username: email},
        () => {
          setEmail('')
          handleShowMessage('Η επαναφορά έγινε με επιτυχία! Αναμένεται το σχετικό email', true)
        },
        (err) => handleShowMessage('Παρουσιάστηκε σφάλμα: ' + err.message, false)
      )
    }
  }

  //Snackbar messages (to inform user of success)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success')

  const handleShowMessage = (message, success) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message)
    setSnackbarSeverity(success ? 'success': 'error')
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return(
    <Container component="main" maxWidth="xs">
       <CssBaseline />
       <SidebarShort/>
       <div className={classes.paper}>

       <GridContainer justify="center">
         <GridItem xs={12} sm={12} >

           <Card login className={classes["cardHidden"]}>
               <CardHeader
		className="AristosCardHeader"
                 color="primary"
               >
                  <h4 className={classes.cardTitle}>Επαναφορά password</h4>
               </CardHeader>
             <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      Πληκτρολογήστε το email (username) σας για να σας αποσταλεί ο σύνδεσμος αλλαγής password.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>

                    <CustomInput
                       error={emailError!==''}
                       labelText="Email Address *"
                       id="email"
                       formControlProps={{
                         fullWidth: true
                       }}
                       inputProps={{
                         value: email,
                         onChange: event => onEmailChange(event),
                         type: "email",
                       }}
                   />
                     <FormHelperText error>{emailError}</FormHelperText>
                 </Grid>
               </Grid>

               <Button
                 fullWidth
                 variant="contained"
                 color="primary"
                 onClick={handleResetPassword}
               >
                Επαναφορά password
               </Button>
            </CardBody>
          </Card>

         </GridItem>
        </GridContainer>

        </div>
        

        <Snackbar anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

    </Container>

  )
}

export default PasswordReset
