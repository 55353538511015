import { fetchWithGET, fetchWithFile } from './clientUtils.js'

function getAllEduSources(success, errorFunc){
  return fetchWithGET('edu_sources', '', success, errorFunc)
}

function getOneEduSource(id, success, errorFunc){
  return fetchWithGET('edu_sources/' + id, '', success, errorFunc)
}

function addEduSource(files, vals, success, errorFunc){
  return fetchWithFile('edu_sources', 'POST', files, vals, success, errorFunc)
}

function editEduSource(files, vals, success, errorFunc){
  return fetchWithFile('edu_sources', 'PUT', files, vals, success, errorFunc)
}

export { getAllEduSources, getOneEduSource, addEduSource, editEduSource }
