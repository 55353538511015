import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';

import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";
import FormLabel from '@material-ui/core/FormLabel';
import { Editor } from '@tinymce/tinymce-react';
import TagsInput from "react-tagsinput";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'
import { ConfirmCancelMessage } from 'views/CustomAlerts/ConfirmationDialog.js'

import ImagesForm from 'views/Images/ImagesForm.js'

import { checkNoErrors, addHttpToLink, replaceFileObjects } from 'utils.js'
import { validUrlPattern } from 'variables/general.js'
import { addEduSource, editEduSource, getOneEduSource } from 'clients/eduSourcesClient.js'
import { eduSourcesFilesURL } from 'clients/clientConfig.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  fileinput: {
    display: 'none',
  },
  edusrcImage: {
    height: 60,
    width: 90,
  },
  inner_form: {
    align: 'left',
    margin: '20px'
  },
}))

const EduSourceEdit = (props) => {
  const classes = useStyles()

  const [esID, setEsID] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [order, setOrder] = React.useState('')
  const [image, setImage] = React.useState(null)
  const [creators, setCreators] = React.useState('')
  const [intro, setIntro] = React.useState('')
  const [tags, setTags] = React.useState([])
  const [links_arr, setLinks_arr] = React.useState([])
  const [linkHref, setLinkHref] = React.useState('')
  const [linkTitle, setLinkTitle] = React.useState('')
  const [editingLinkIdx, setEditingLinkIdx] = React.useState(-1)
  const [multimedia, setMultimedia] = React.useState([])
  const [mediaHref, setMediaHref] = React.useState('')
  const [editingMediaIdx, setEditingMediaIdx] = React.useState(-1)
  const [content, setContent] = React.useState('')
  const [extra, setExtra] = React.useState([])
  const [images, setImages] = React.useState([])

  const introHtmlEditorRef = React.useRef(null)
  const contentHtmlEditorRef = React.useRef(null)

  const [saving, setSaving] = React.useState(false)
  const [errors, setErrors] = React.useState({})
  const [fileError, setFileError] = React.useState('')

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  React.useEffect(() => {
    if (props.match.params.edusourceid){
      getOneEduSource(props.match.params.edusourceid,
        (data) => {
          if (data.edu_sources.length > 0){
            setEsID(data.edu_sources[0].id)
            setTitle(data.edu_sources[0].title)
            setOrder(String(data.edu_sources[0].order))
            if (data.edu_sources[0].image!==null && data.edu_sources[0].image!==''){
              setImage({name: data.edu_sources[0].image})
            }
            setCreators(data.edu_sources[0].creators)
            setIntro(data.edu_sources[0].intro)
            setTags(data.edu_sources[0].tags)
            setLinks_arr(data.edu_sources[0].links_arr)
            setMultimedia(data.edu_sources[0].multimedia)
            setContent(data.edu_sources[0].content)
            setExtra(data.edu_sources[0].extra.map(f => {return {name: f}}))
            setImages(data.edu_sources[0].images ?
              data.edu_sources[0].images.map(i => {return {...i, file: i.filename}})
              : [])
          }
        },
        (err) => console.log(err)
      )
    }
  }, [props.match.params.edusourceid])

  const handleTitleChange = (e) => {
    setErrors({...errors, title: validate('title', e.target.value)})
    setTitle(e.target.value)
  }

  const handleOrderChange = (e) => {
    setErrors({...errors, order: validate('order', e.target.value)})
    setOrder(e.target.value)
  }

  const handleCreatorsChange = (e) => {
    setErrors({...errors, creators: validate('creators', e.target.value)})
    setCreators(e.target.value)
  }

  const handleChangeTags = l => {
    setTags(l)
  }

  const handleChangeLinkTitle = e => {
    setLinkTitle(e.target.value)
  }

  const handleChangeLinkHref = e => {
    setLinkHref(e.target.value)
  }

  const handleChangeMediaHref = e => {
    setMediaHref(e.target.value)
  }

  //upload functions
  const uploadImage = (e) => {
    const allowedExtensions = /(\.(jpg|jpeg|gif|png|bmp|tiff|pjp|jfif|svg|svgz|webp|ico|xbm|dib|tif|pjpeg|avif))$/i;
    let newErrors = Object.assign({}, errors)

    if (!allowedExtensions.exec(e.target.files[0].name)) {
      newErrors.image = "Πρέπει να ανεβάσετε αρχείο εικόνας"
      if (!image){
        setImage(null)
      }
    } else {
      setImage(e.target.files[0])
      newErrors.image = ''
    }

    setErrors(newErrors)
  }

  const uploadFile = (e) => {
    const allowedExtensions = /(\.(jpg|jpeg|gif|png|bmp|tiff|pjp|jfif|svg|svgz|webp|ico|xbm|dib|tif|pjpeg|avif|doc|docx|pdf))$/i;

    if (!allowedExtensions.exec(e.target.files[0].name)) {
      setFileError("Πρέπει να ανεβάσετε αρχείο εικόνας ή Word ή pdf")
    } else {
      setExtra([...extra, e.target.files[0]])
      setFileError('')
    }
  }

  const removeExtra = (idx) => {
    let temp = [...extra]
    temp.splice(idx, 1)
    setExtra(temp)
  }

  //functions for link addition/removal
  const addOrEditLink = () => {
    let errMsg = validate('link', linkHref)
    setErrors({...errors, linkHref: errMsg})

    if (errMsg===''){
      const newLink = {link: addHttpToLink(linkHref),
        title: (linkTitle==='') ? linkHref : linkTitle}

      if (editingLinkIdx < 0){
        const tempLinks = [...links_arr, newLink]
        setLinks_arr(tempLinks)
      } else {
        let tempLinks = [...links_arr]
        tempLinks[editingLinkIdx] = newLink
        setLinks_arr(tempLinks)
      }

      resetLinkFields()
    }
  }

  const resetLinkFields = () => {
    setLinkHref('')
    setLinkTitle('')
    setEditingLinkIdx(-1)
  }

  const prepareToEditLink = (link, idx) => {
    setLinkHref(link.link)
    setLinkTitle(link.title)
    setEditingLinkIdx(idx)
  }

  const removeLink = (idx) => {
    let temp = [...links_arr]
    temp.splice(idx, 1)
    setLinks_arr(temp)
  }

  //functions for multimedia link addition/removal
  const addOrEditMediaLink = () => {
    let errMsg = validate('link', mediaHref)
    setErrors({...errors, mediaHref: errMsg})

    if (errMsg===''){
      if (editingMediaIdx < 0){
        setMultimedia([...multimedia, addHttpToLink(mediaHref)])
      } else {
        let tempMedia = [...multimedia]
        tempMedia[editingMediaIdx] = addHttpToLink(mediaHref)
        setMultimedia(tempMedia)
      }

      resetMediaFields()
    }
  }

  const resetMediaFields = () => {
    setMediaHref('')
    setEditingMediaIdx(-1)
  }

  const prepareToEditMedia = (media, idx) => {
    setMediaHref(media)
    setEditingMediaIdx(idx)
  }

  const removeMedia = (idx) => {
    let temp = [...multimedia]
    temp.splice(idx, 1)
    setMultimedia(temp)
  }

  //validation
  const validate = (name, value) => {
    switch (name) {
      case 'title':
      case 'intro':
      case 'content':
        if (value.trim()===''){
          document.getElementById(name).focus()
          return  'Υποχρεωτικό πεδίο'
        } else {
          return ''
        }

      case 'order':
        if (value.trim()===''){
          document.getElementById(name).focus()
          return  'Υποχρεωτικό πεδίο'
        } else {
          const pattern = /[^0-9]/;
          return pattern.test(value) ? 'Επιτρέπονται μόνο ακέραιοι αριθμοί' : ''
        }

      case 'link':
        if (value.trim()===''){
          return 'Υποχρεωτικό πεδίο'
        } else if (!validUrlPattern.test(value)){
          return 'Μη έγκυρο link'
        } else {
          return ''
        }

      default:
        return ''
    }
  }

  const handleSubmit = () => {
    const newIntro = (introHtmlEditorRef.current) ? introHtmlEditorRef.current.getContent() : ''
    const newContent = (contentHtmlEditorRef.current) ? contentHtmlEditorRef.current.getContent() : ''

    let newErrors = Object.assign({}, errors)
    newErrors.title = validate('title', title)
    newErrors.order = validate('order', order)
    newErrors.intro = validate('intro', newIntro)
    newErrors.content = validate('content', newContent)
    //the following errors should be disregarded
    newErrors.image = ''
    newErrors.linkHref = ''
    newErrors.mediaHref = ''
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      setSaving(true)

      let params = {title: title, order: order, creators: creators, intro: newIntro,
          tags: tags, links_arr: links_arr, content: newContent, image: (image!=null) ? image.name : '',
          extra: extra.map(f => f.name), multimedia: multimedia, images: replaceFileObjects(images)
      }
      let newFiles = extra.
        filter(f => (f instanceof File)).
        concat(images
          .filter(i => (i.file instanceof File))
          .map(i => i.file))
      if (image instanceof File){
        newFiles.push(image)
      }
      //console.log(newFiles)

      if (esID){
        params.id = esID
        //console.log(params)

        editEduSource(newFiles, params,
          (data) => window.location.href = "/admin/EduSourceMain/" + esID,
          (err) => {
            setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
            setSaving(false)
          }
        )

      } else {
        //console.log(params)

        addEduSource(newFiles, params,
          (data) => window.location.href = "/admin/EduSourceMain/" + data.inserted_id,
          (err) => {
            setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
            setSaving(false)
          }
        )
      }
    }
  }

  const handleCancel = () => {
    setAlert(<ConfirmCancelMessage onConfirm={redirectToMain} onCancel={hideAlert} />)
  }

  const redirectToMain = () => {
    window.location.href =  '/admin/EduSources'
  }

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} >
        <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color='inherit' href='/admin/EduSources'>
                Εκπαιδευτικοί-Πολυμεσικοί Πόροι
              </Link>
              <Typography color="textPrimary">
                {esID ? 'Επεξεργασία πόρου' : 'Προσθήκη νέου πόρου'}
              </Typography>
          </Breadcrumbs>
        </div>
      </Grid>

     <Grid item xs={12} style={{ paddingTop : '20px'}}>
      <Container maxWidth="md">

        <Typography variant='h4'>{esID ? 'Επεξεργασία πόρου' : 'Προσθήκη νέου πόρου'}</Typography>

        <TextField id='order'
         error={Boolean(errors.order)}
         label='Σειρά εμφάνισης'
         style = {{width:100, paddingTop:10, marginTop:0}}
         value={order}
         onChange={handleOrderChange}
         />
         <FormHelperText error>{errors.order}</FormHelperText>

        <TextField id='title'
         error={Boolean(errors.title)}
         label='Τίτλος'
         style = {{width:500, paddingTop:10, marginTop:0}}
         value={title}
         onChange={handleTitleChange}
         />
         <FormHelperText error>{errors.title}</FormHelperText>

         <Grid container spacing={2}>
           <Grid item xs={12} sm={4}>
             <FormLabel>Εικόνα</FormLabel>
             <br/>
             <input
               accept="image/*"
               className={classes.fileinput}
               id="contained-button-image"
               type="file"
               onChange={uploadImage}
             />
             <label htmlFor="contained-button-image">
               <Button variant="contained" color="primary" component="span">
                 Ανεβασμα εικονας
               </Button>
             </label>
             <FormHelperText error>{errors.image}</FormHelperText>
           </Grid>
           <Grid item xs={12} sm={8}>
             {
               image && !(image instanceof File) &&
               <img src={eduSourcesFilesURL+esID+'/'+image.name} className={classes.edusrcImage} alt='Εικόνα'/>
             }
             {image && image instanceof File && image.name}
           </Grid>
         </Grid>

         <TextField id='creators'
          error={Boolean(errors.creators)}
          label='Στοιχεία δημιουργού-επιμελητή'
          style = {{width:500, paddingTop:10, marginTop:0}}
          value={creators}
          onChange={handleCreatorsChange}
          multiline
          rows={3}
          variant='outlined'
          />
          <FormHelperText error>{errors.creators}</FormHelperText>

          <FormLabel>Εισαγωγικό σημείωμα</FormLabel>
          <Editor id="intro" tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
           onInit={(evt, editor) => introHtmlEditorRef.current = editor}
           initialValue={intro}
           init={{
             height: 150,
             entity_encoding : "raw",
             plugins: [
               'advlist autolink lists link image charmap print preview anchor',
               'searchreplace visualblocks code fullscreen',
               'media table paste code help wordcount imagetools'
             ],
             menubar: false,
             toolbar: 'undo redo | formatselect | ' +
             'bold italic underline | alignleft aligncenter ' +
             'alignright alignjustify | outdent indent | ' +
             'removeformat | link | help',
           }}
         />
        <FormHelperText error>{errors.intro}</FormHelperText>

        <FormLabel error={Boolean(errors.tags)}>Λέξεις-κλειδιά (πληκτρολογήστε και πατήστε Enter)</FormLabel>
        <br/>
        <TagsInput
          value={tags}
          onChange={handleChangeTags}
          tagProps={{ className: "react-tagsinput-tag info"}}
          inputProps={{placeholder: 'Προσθήκη'}}
        />
        <FormHelperText error>{errors.tags}</FormHelperText>

        <FormLabel>Ηλεκτρονική διεύθυνση</FormLabel>
        <br/>
        <Container maxWidth='xs' className={classes.inner_form}>
          <TextField label="Τίτλος" id="linkTitle"
            style={{width: 400}}
            value={linkTitle}
            onChange={handleChangeLinkTitle}
            error={Boolean(errors.linkTitle)}/>
          <FormHelperText error>{errors.linkTitle}</FormHelperText>

          <TextField label="Link" id="linkHref"
            style={{width: 400}}
            value={linkHref}
            onChange={handleChangeLinkHref}
            error={Boolean(errors.linkHref)}/>
          <FormHelperText error>{errors.linkHref}</FormHelperText>

          <Button onClick={addOrEditLink} size='sm'>Προσθηκη συνδεσμου</Button>
          <Button onClick={resetLinkFields} size='sm'>Καθαρισμος</Button>
        </Container>

        <List dense>
          {links_arr.map((link, idx) =>
            <ListItem key={idx}>
              <Link href={link.link}>{link.title}</Link>
              <IconButton aria-label="edit" onClick={() => prepareToEditLink(link, idx)}>
                <EditIcon fontSize="small"/>
              </IconButton>
              <IconButton aria-label="delete" onClick={() => removeLink(idx)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </ListItem>
          )}
        </List>
        <br clear='all'/>

        <FormLabel>Πολυμέσα</FormLabel>
        <br/>
        <Container maxWidth='xs' className={classes.inner_form}>
          <TextField label="Link" id="mediaHref"
            style={{width: 400}}
            value={mediaHref}
            onChange={handleChangeMediaHref}
            error={Boolean(errors.mediaHref)}/>
          <FormHelperText error>{errors.mediaHref}</FormHelperText>

          <Button onClick={addOrEditMediaLink} size='sm'>Προσθηκη συνδεσμου</Button>
          <Button onClick={resetMediaFields} size='sm'>Καθαρισμος</Button>
        </Container>

        <List dense>
          {multimedia.map((link, idx) =>
            <ListItem key={idx}>
              <Link href={link}>{link}</Link>
              <IconButton aria-label="edit" onClick={() => prepareToEditMedia(link, idx)}>
                <EditIcon fontSize="small"/>
              </IconButton>
              <IconButton aria-label="delete" onClick={() => removeMedia(idx)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </ListItem>
          )}
        </List>
        <br clear='all'/>

        <FormLabel>Συστατικά στοιχεία</FormLabel>
        <Editor id="content" tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
         onInit={(evt, editor) => contentHtmlEditorRef.current = editor}
         initialValue={content}
         init={{
           height: 300,
           entity_encoding : "raw",
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'media table paste code help wordcount imagetools'
           ],
           menubar: false,
           toolbar: 'undo redo | formatselect | ' +
           'bold italic underline strikethrough | alignleft aligncenter ' +
           'alignright alignjustify | outdent indent | numlist bullist checklist | ' +
           'forecolor backcolor casechange permanentpen formatpainter ' +
           'removeformat | link anchor | help',
         }}
         />
        <FormHelperText error>{errors.content}</FormHelperText>

        <FormLabel>Πρόσθετο υλικό</FormLabel>
        <br/>
        <input
          accept=".pdf, .doc, .docx, image/*"
          className={classes.fileinput}
          id="contained-button-filenames_arr"
          type="file"
          onChange={uploadFile}
        />
        <label htmlFor="contained-button-filenames_arr">
          <Button variant="contained" color="primary" component="span">
            Ανεβασμα αρχειου
          </Button>
        </label>
        <FormHelperText error>{fileError}</FormHelperText>
        <List dense>
          {extra.map((f, idx) =>
            <ListItem key={idx}>{f.name}
              <IconButton aria-label="delete" onClick={() => removeExtra(idx)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </ListItem>
          )}
        </List>

        <ImagesForm baseUrl={eduSourcesFilesURL + '/' + esID + '/'} images={images} onSave={setImages}/>

        <Button color="rose" onClick={handleSubmit} disabled={saving}>Καταχωριση</Button>
        <Button onClick={handleCancel}>Ακυρωση</Button>
      </Container>
    </Grid>

    {alert}

  </Grid>
  </div>

  )
}

export default EduSourceEdit
