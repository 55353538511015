import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

export default function Notification(props){
  const { notification } = props

  const description = () => {
    if (notification.date_edited){
      return 'Επεξεργασία Δημοσίευσης'
    } else {
      switch(notification.notif_type){
        case 'thread':
          return 'Νέα Συζήτηση'
        case 'post':
          return 'Νέα Δημοσίευση'
        case 'tag':
          return 'Προώθηση Συζήτησης'
        default:
          return ''
      }
    }
  }

  return(
    <ListItem>
      <ListItemLink href={'/admin/Thread/'+notification.thread_id}>
      <ListItemText>
        {description()} από {notification.user} στις {notification.date_edited || notification.date}:
        Κατηγορία <b>{notification.forum}</b>, Συζήτηση <b><i>{notification.thread}</i></b>
      </ListItemText>
      {notification.is_new && <NewReleasesIcon/>}
      </ListItemLink>
    </ListItem>
  )
}

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}
