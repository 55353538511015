import React from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";
import FormLabel from '@material-ui/core/FormLabel';
import { Editor } from '@tinymce/tinymce-react';

import LinkToLexiconEntryForm from 'views/Editor/LinkToLexiconEntryForm.js'
import LinkToCommentForm from 'views/Editor/LinkToCommentForm.js'
import LinkToSectionForm from 'views/Editor/LinkToSectionForm.js'

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'

const MaterialTextForm = (props) => {
  const { onSave, onClose, open, errors,
    title, handleTitleChange, text, availableSections } = props

  const textHtmlEditorRef = React.useRef(null);

  //popup for custom buttons of editor
  const [showPopupLinkToEntry, setShowPopupLinkToEntry] = React.useState(false)
  const [showPopupLinkToComment, setShowPopupLinkToComment] = React.useState(false)
  const [showPopupLinkToSection, setShowPopupLinkToSection] = React.useState(false)

  const insertLinkToOtherEntry = (value) => {
    setShowPopupLinkToEntry(false)
    //console.log(value)
    textHtmlEditorRef.current.insertContent(value)
  }

  const insertLinkToComment = (value) => {
    setShowPopupLinkToComment(false)
    textHtmlEditorRef.current.insertContent(value)
  }

  const insertLinkToSeciton = (value) => {
    setShowPopupLinkToSection(false)
    textHtmlEditorRef.current.insertContent(value)
  }

  const handleSubmit = () => {
    const newText = (textHtmlEditorRef.current) ? textHtmlEditorRef.current.getContent() : ''

    onSave(newText);
  }

  return(
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth
      maxWidth={"sm"}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Προσθήκη Κειμένου
        </DialogTitle>

        <DialogContent>
          <TextField
            label='Τίτλος'
            value={title}
            error={Boolean(errors.title)}
            onChange={handleTitleChange}
            style = {{width:500, paddingTop:10, marginTop:0}}
          />
          <FormHelperText error>{errors.title}</FormHelperText>

          <FormLabel>Κείμενο</FormLabel>
          <Editor id="text" tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
           onInit={(evt, editor) => textHtmlEditorRef.current = editor}
           initialValue={text}
           init={{
             height: 300,
             entity_encoding : "raw",
             plugins: [
               'advlist autolink lists link image charmap print preview anchor',
               'searchreplace visualblocks code fullscreen',
               'media table paste code help wordcount imagetools'
             ],
             menubar: false,
             toolbar: 'undo redo | formatselect | ' +
             'bold italic underline | alignleft aligncenter ' +
             'alignright alignjustify | outdent indent | ' +
             'removeformat | addlinktolemma | addlinktocomment | addlinktosection | help',
             //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
             setup: function(editor) {
                editor.ui.registry.addButton('addlinktolemma', {
                  text: 'Link σε λήμμα',
                  onAction: () => setShowPopupLinkToEntry(true),
                });
                editor.ui.registry.addButton('addlinktocomment', {
                  text: 'Link σε σχόλιο',
                  onAction: () => setShowPopupLinkToComment(true),
                });
                editor.ui.registry.addButton('addlinktosection', {
                  text: 'Link σε χωρίο',
                  onAction: () => setShowPopupLinkToSection(true),
                });
              }
           }}
           />
          <FormHelperText error>{errors.text}</FormHelperText>

          <LinkToLexiconEntryForm onClose={()=>setShowPopupLinkToEntry(false)}
             onSave={insertLinkToOtherEntry} open={showPopupLinkToEntry}/>

          <LinkToCommentForm onClose={()=>setShowPopupLinkToComment(false)}
             onSave={insertLinkToComment} open={showPopupLinkToComment}/>

          <LinkToSectionForm onClose={()=>setShowPopupLinkToSection(false)}
             onSave={insertLinkToSeciton} open={showPopupLinkToSection} sections={availableSections}/>
        </DialogContent>

        <DialogActions>
            <Button onClick={handleSubmit}>Καταχωριση</Button>
            <Button onClick={onClose}>Κλεισιμο</Button>
        </DialogActions>
     </Dialog>
  )
}

export default MaterialTextForm
