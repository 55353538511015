import {
  FETCH_THREAD_REQUEST,
  FETCH_THREAD_SUCCESS,
  FETCH_THREAD_FAILURE,
  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  CREATE_POST_FAILURE,
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILURE,
  DELETE_THREAD_REQUEST,
  DELETE_THREAD_SUCCESS,
  DELETE_THREAD_FAILURE,
//  EDIT_POST_START,
  EDIT_POST_REQUEST,
  EDIT_POST_SUCCESS,
  EDIT_POST_FAILURE,
  EDIT_POST_CANCEL,
  EDIT_THREAD_REQUEST,
  EDIT_THREAD_SUCCESS,
  EDIT_THREAD_FAILURE,
  EDIT_THREAD_CANCEL,
  CREATE_THREAD_SAVE,
  CREATE_THREAD_TOGGLE,
  CHANGE_THREAD_PAGE,
  RESTORE_POST_REQUEST,
  RESTORE_POST_SUCCESS,
  RESTORE_POST_FAILURE,
  RESTORE_THREAD_REQUEST,
  RESTORE_THREAD_SUCCESS,
  RESTORE_THREAD_FAILURE,
  TOGGLE_THREAD_PINNED_REQUEST,
  TOGGLE_THREAD_PINNED_SUCCESS,
  TOGGLE_THREAD_PINNED_FAILURE,
  TOGGLE_THREAD_FAVOURITE_REQUEST,
  TOGGLE_THREAD_FAVOURITE_SUCCESS,
  TOGGLE_THREAD_FAVOURITE_FAILURE,
  COPY_THREAD_REQUEST,
  COPY_THREAD_SUCCESS,
  COPY_THREAD_FAILURE,
  LIKE_THREAD_POST_REQUEST,
  LIKE_THREAD_POST_SUCCESS,
  LIKE_THREAD_POST_FAILURE,
  TOGGLE_NOTIFICATIONS_THREAD_REQUEST,
  TOGGLE_NOTIFICATIONS_THREAD_SUCCESS,
  TOGGLE_NOTIFICATIONS_THREAD_FAILURE,
  FORWARD_THREAD_REQUEST,
  FORWARD_THREAD_SUCCESS,
  FORWARD_THREAD_FAILURE,
} from '../actions/types';

const threadInitialState = {
  isLoading: false,
  name: null,
  content: null,
  file: null,
  pinned: false,
  keywords: [],
  user_id: null,
  user_fullname: null,
  date: null,
  posts: [],
  parents: [],
  forum_id: null,
  forum_name: null,
  error: null,
  page: 1,
  date_edited: null,
  edited_by: '',
  favourite_admin: false,
  favourite_user: [],
  likes: [],
};

const newPostInitialState = {
  newPostSuccess: false,
  newPostLoading: false,
  newPostError: null,
};

const editThreadInitialState = {
  editThreadLoading: false,
  editThreadSuccess: false,
  editThreadName: '',
  editThreadContent: '',
  //editThreadId: null,
  editThreadError: null,
  editThreadShow: false,
};

const deletePostInitialState = {
  deletePostList: [],
  deletePostSuccess: false,
  deletePostError: null,
};

const deleteThreadInitialState = {
  isDeleting: false,
  deleteError: null,
};

const restorePostInitialState = {
  restorePostSuccess: false,
  restorePostError: null,
}

const restoreThreadInitialState = {
  restoreThreadSuccess: false,
  restoreThreadError: null,
}

const toggleThreadInitialState = {
  togglePinnedSuccess: false,
  toggleFavouriteSuccess: false,
  toggleNotificationsSuccess: false,
  toggleError: null,
  togglePrevState: false,
}

const copyThreadInitialState = {
  isCopying: false,
  copyThreadSuccess: false,
  copyThreadError: null,
}

const likeInitialState = {
  isLikingID: '',
  likeSuccess: false,
  likeError: null,
}

const forwardThreadInitialState = {
  isForwarding: false,
  forwardThreadSuccess: false,
  forwardThreadError: null,
}

const initialState = {
  ...threadInitialState,
  ...newPostInitialState,
  ...deletePostInitialState,
  ...deleteThreadInitialState,
  ...editThreadInitialState,
  ...restorePostInitialState,
  ...restoreThreadInitialState,
  ...toggleThreadInitialState,
  ...copyThreadInitialState,
  ...likeInitialState,
  ...forwardThreadInitialState,
};

const thread = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_THREAD_REQUEST:
      return {
        ...initialState,
        isLoading: true,
        error: null,
      };
    case FETCH_THREAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        name: action.thread.name,
        content: action.thread.content,
        file: action.thread.file || null,
        pinned: action.thread.pinned,
        user_id: action.thread.user_id,
        user_fullname: action.thread.user_fullname,
        date: action.thread.date,
        posts: action.thread.posts,
        parents: action.parents,
        forum_id: action.forum_id,
        forum_name: action.forum_name,
        date_edited: action.thread.date_edited || null,
        edited_by: action.thread.edited_by || '',
        keywords: action.thread.keywords || [],
        favourite_admin: action.thread.favourite_admin || false,
        favourite_user: action.thread.favourite_user || [],
        likes: action.thread.likes || [],
        error: null,
      };
    case FETCH_THREAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case DELETE_THREAD_REQUEST:
      return {
        ...state,
        isDeleting: true,
        deleteError: null,
      };
    case DELETE_THREAD_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteError: null,
      };
    case DELETE_THREAD_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleteError: action.error,
      };
    case CREATE_POST_REQUEST:
      return {
        ...state,
        newPostLoading: true,
        newPostError: null,
        newPostSuccess: false,
      };
    case CREATE_POST_SUCCESS:
      return {
        ...state,
        newPostLoading: false,
        newPostError: null,
        newPostSuccess: true,
      };
    case CREATE_POST_FAILURE:
      return {
        ...state,
        newPostLoading: false,
        newPostError: action.error,
        newPostSuccess: false,
      };
    case DELETE_POST_REQUEST:
      return {
        ...state,
        deletePostList: [...state.deletePostList, action.id],
        deletePostSuccess: false,
        deletePostError: null,
      };
    case DELETE_POST_SUCCESS:
      return {
        ...state,
        deletePostList: state.deletePostList.filter(id => id !== action.id),
        deletePostSuccess: true,
        deletePostError: null,
      };
    case DELETE_POST_FAILURE:
      return {
        ...state,
        deletePostList: state.deletePostList.filter(id => id !== action.id),
        deletePostSuccess: false,
        deletePostError: action.error,
      };
    /*case EDIT_POST_START:
      return {
        ...state,
        newPostError: null,
        newPostSuccess: false,
      };*/
    case EDIT_POST_REQUEST:
      return {
        ...state,
        newPostLoading: true,
        newPostError: null,
        newPostSuccess: false,
      };
    case EDIT_POST_SUCCESS:
      return {
        ...state,
        newPostLoading: false,
        newPostError: null,
        newPostSuccess: true,
      };
    case EDIT_POST_FAILURE:
      return {
        ...state,
        newPostLoading: false,
        newPostError: action.error,
        newPostSuccess: false,
      };
    case EDIT_POST_CANCEL:
      return {
        ...state,
        newPostSuccess: false,
        newPostError: null,
      }
    case EDIT_THREAD_REQUEST:
      return {
        ...state,
        editThreadLoading: true,
        editThreadSuccess: false,
        editThreadError: null,
        editThreadName: action.newThread.name,
        editThreadContent: action.newThread.content,
      };
    case EDIT_THREAD_SUCCESS:
      return {
        ...state,
        editThreadLoading: false,
        editThreadSuccess: true,
        editThreadShow: false,
        editThreadError: null,
        name: action.newThread.name,
        content: action.newThread.content,
        file: action.newFile,
        date_edited: action.date_edited,
        edited_by: action.edited_by,
        keywords: action.newThread.keywords || [],
      };
    case EDIT_THREAD_FAILURE:
      return {
        ...state,
        editThreadLoading: false,
        editThreadSuccess: false,
        //editThreadId: null,
        editThreadShow: true,
        editThreadError: action.error,
      };
    case CREATE_THREAD_SAVE:
      return {
        ...state,
        editThreadName: action.name,
        editThreadContent: action.content,
      };
    case CREATE_THREAD_TOGGLE:
      return {
        ...state,
        editThreadShow: !state.editThreadShow,
        editThreadSuccess: false,
        editThreadError: null,
      };
    case EDIT_THREAD_CANCEL:
      return {
        ...state,
        editThreadSuccess: false,
        editThreadError: null,
      }
    case CHANGE_THREAD_PAGE:
      return{
        ...state,
        page: action.page,
      }
    case RESTORE_POST_REQUEST:
      return {
        ...state,
        restorePostSuccess: false,
        restorePostError: null,
      }
    case RESTORE_POST_SUCCESS:
      return {
        ...state,
        restorePostSuccess: true,
        restorePostError: null,
      }
    case RESTORE_POST_FAILURE:
      return {
        ...state,
        restorePostSuccess: false,
        restorePostError: action.error,
      }
    case RESTORE_THREAD_REQUEST:
      return {
        ...state,
        restoreThreadSuccess: false,
        restoreThreadError: null,
      }
    case RESTORE_THREAD_SUCCESS:
      return {
        ...state,
        restoreThreadSuccess: true,
        restoreThreadError: null,
      }
    case RESTORE_THREAD_FAILURE:
      return {
        ...state,
        restoreThreadSuccess: false,
        restoreThreadError: action.error,
      }
    case TOGGLE_THREAD_PINNED_REQUEST:
      return {
        ...state,
        togglePinnedSuccess: false,
        toggleError: null,
        togglePrevState: action.togglePrevState,
      }
    case TOGGLE_THREAD_PINNED_SUCCESS:
      return {
        ...state,
        togglePinnedSuccess: true,
        toggleError: null,
        pinned: !state.pinned,
      }
    case TOGGLE_THREAD_PINNED_FAILURE:
      return{
        ...state,
        togglePinnedSuccess: false,
        toggleError: action.error,
      }
    case TOGGLE_THREAD_FAVOURITE_REQUEST:
      return {
        ...state,
        toggleFavouriteSuccess: false,
        toggleError: null,
        togglePrevState: action.togglePrevState,
      }
    case TOGGLE_THREAD_FAVOURITE_SUCCESS:
      return {
        ...state,
        toggleFavouriteSuccess: true,
        toggleError: null,
        favourite_admin: action.favourite_admin,
        favourite_user: action.favourite_user,
      }
    case TOGGLE_THREAD_FAVOURITE_FAILURE:
      return{
        ...state,
        toggleFavouriteSuccess: false,
        toggleError: action.error,
      }
    case TOGGLE_NOTIFICATIONS_THREAD_REQUEST:
      return {
        ...state,
        toggleNotificationsSuccess: false,
        toggleError: null,
      }
    case TOGGLE_NOTIFICATIONS_THREAD_SUCCESS:
      return {
        ...state,
        toggleNotificationsSuccess: true,
        toggleError: null,
      }
    case TOGGLE_NOTIFICATIONS_THREAD_FAILURE:
      return{
        ...state,
        toggleNotificationsSuccess: false,
        toggleError: action.error,
      }
    case COPY_THREAD_REQUEST:
      return {
        ...state,
        isCopying: true,
        copyThreadSuccess: false,
        copyThreadError: null,
      }
    case COPY_THREAD_SUCCESS:
      return {
        ...state,
        isCopying: false,
        copyThreadSuccess: true,
        copyThreadError: null,
      }
    case COPY_THREAD_FAILURE:
      return {
        ...state,
        isCopying: false,
        copyThreadSuccess: false,
        copyThreadError: action.error,
      }
    case LIKE_THREAD_POST_REQUEST:
      return {
        ...state,
        isLikingID: action.likingID,
        likeSuccess: false,
        likeError: null,
      }
    case LIKE_THREAD_POST_SUCCESS:
      return {
        ...state,
        isLikingID: '',
        likeSuccess: true,
        likeError: null,
      }
    case LIKE_THREAD_POST_FAILURE:
      return {
        ...state,
        isLikingID: '',
        likeSuccess: false,
        likeError: action.error,
      }
    case FORWARD_THREAD_REQUEST:
      return {
        ...state,
        isForwarding: true,
        forwardThreadSuccess: false,
        forwardThreadError: null,
      }
    case FORWARD_THREAD_SUCCESS:
      return {
        ...state,
        isForwarding: false,
        forwardThreadSuccess: true,
        forwardThreadError: null,
      }
    case FORWARD_THREAD_FAILURE:
      return {
        ...state,
        isForwarding: false,
        forwardThreadSuccess: false,
        forwardThreadError: action.error,
      }
    default:
      return state;
  }
};

export default thread;
