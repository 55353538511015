import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from "components/CustomButtons/Button.js";
import AddIcon from '@material-ui/icons/Add';
import LexiconCatsGrid from './LexiconCatsGrid.js'
import AddLexiconCatDialog from './AddLexiconCatDialog.js'

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'

import { getAllLexiconCategories, addLexiconCategory, editLexiconCategory } from 'clients/lexiconClient.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(5),
    margin: '15px',
  },
}))

const LexiconCats = (props) => {
  const classes = useStyles()

  const [allCategories, setAllCategories] = React.useState([])

  const [open, setOpen] = React.useState(false)
  const [cat_id, setCat_id] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [order, setOrder] = React.useState('')
  const [dialogTitle, setDialogTitle] = React.useState('')

  React.useEffect(() => {
    getAllLexiconCategories(
      (data) => setAllCategories(data.categories),
      (err) => console.log(err)
    )
  }, [])

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  //Snackbar messages (to inform user of success)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const handleShowSuccessMessage = (message) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message)
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const onAddClick = () => {
    setOpen(true)
    setCat_id('')
    setTitle('')
    setOrder('')
    setDialogTitle('Προσθήκη Θεματικής Ενότητας')
  }

  const onEditClick = (category) => {
    setOpen(true)
    setCat_id(category.category_id)
    setTitle(category.title)
    setOrder(category.cat_order)
    setDialogTitle('Επεξεργασία Θεματικής Ενότητας')
  }

  const onCloseClick = () => {
    setOpen(false)
  }

  const handleSubmit = (params) => {
    if (cat_id){
      params.category_id = cat_id
    }
    //console.log(params)

    if (params.category_id){
      editLexiconCategory(params,
        () => {
          let newCats = [...allCategories];
          const changeIndex = newCats.findIndex((c) => (c.category_id === params.category_id))
          newCats[changeIndex] = params
          setAllCategories(newCats)
          setOpen(false)
          handleShowSuccessMessage('Η καταχώριση έγινε με επιτυχία')
        },
        (err) => setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
      )

    } else {
      addLexiconCategory(params,
        (data) => {
          params.category_id = data.inserted_id
          setAllCategories([...allCategories, params])
          setOpen(false)
          handleShowSuccessMessage('Η καταχώριση έγινε με επιτυχία')
        },
        (err) => setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
      )
    }
  }

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <GridContainer className={classes.root} spacing={2}>
        <GridItem xs={12} >
          <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color="inherit" href="/admin/Lexicon">
                Λεξικό εννοιών
              </Link>
              <Typography color="textPrimary">Διαχείριση Θεματικών Ενοτήτων</Typography>
          </Breadcrumbs>
          </div>
        </GridItem>
        <GridItem  xs={12} style={{ paddingTop : '20px'}}>

          <Container maxWidth='lg'>
            <Paper className={classes.paper}>
              <div align='right'>
                <Button justIcon round onClick={onAddClick} color="primary"><AddIcon/></Button>
              </div>
              <LexiconCatsGrid categories={allCategories} onEditClick={onEditClick}/>
            </Paper>
          </Container>

        </GridItem>
      </GridContainer>

      <AddLexiconCatDialog open={open} handleSubmit={handleSubmit} dialogTitle={dialogTitle}
        handleClose={onCloseClick} title={title} order={order}/>

      {alert}

      <Snackbar anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default LexiconCats
