import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Sidebar from "components/Sidebar/Sidebar.js";


import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import BlogIcon from '@material-ui/icons/FormatBold';
import WikiIcon from '@material-ui/icons/Search';
import SeminarIcon from '@material-ui/icons/FileCopy';

import Container from '@material-ui/core/Container';
import GitHubIcon from '@material-ui/icons/GitHub';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Footer from './Footer';

import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },


    exampleWrapper: {
      position: 'relative',
      marginTop: theme.spacing(0),
      height: 80,
    },

    speedDial: {
      position: 'absolute',
      '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
      },
    },



}));


const actions = [
  { icon: <BlogIcon />, name: 'Προσθήκη Blog', id:1 },
  { icon: <WikiIcon />, name: 'Προσθήκη Wiki', id:2 },
  { icon: <SeminarIcon />, name: 'Προσθήκη Σεμιναρίου', id:3 },
];

export default function Blog() {

  const [direction, setDirection] = React.useState('right');
  const [hidden, setHidden] = React.useState(false);

  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const handleHiddenChange = (event) => {
    setHidden(event.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const NewBlogWiki = (e, id) => {

    e.preventDefault();
    if(id=="1"){
      // do something
    }else if(id=="2"){
      //do something else
    }else if(id=="3"){
      window.location.href = "./AddSeminar";
    }
    setOpen(!open);
  };



  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const mainFeaturedPost = {
    title: 'Περὶ γενέσεως καὶ φθορᾶς',
    date:  '30 Νοεμ. 2020, από τον Γιώργο Βάρσο',
    description:
      "Οι σχολιαστές συμφωνούν ότι εδώ έχουμε σε συμπυκνωμένη μορφή την κριτική των επιχειρημάτων των Ατομικών για το φαινόμενο της αύξησης, το οποίο αποδίδουν στην ύπαρξη του κενού. Η θέση του Αριστοτέλη είναι ότι, για να σταθεί η θεωρία τους, παραβιάζουν κάποια από τις τέσσερις συνθήκες που απαριθμεί. Το χωρίο πρέπει να διαβαστεί υπό το φως των αναλύσεων του Αριστοτέλη",
    image: 'https://source.unsplash.com/random',
    imgText: 'main image description',
    linkText: 'Περισσότερα…',
  };

  const featuredPosts = [
    {
      title: 'Απάντηση 1',
      date: '01 Δεκ 2020, από τον Γιώργο Βάρσο',
      description:
        'Οι σχολιαστές συμφωνούν ότι εδώ έχουμε σε συμπυκνωμένη μορφή την κριτική των επιχειρημάτων των Ατομικών για το φαινόμενο της αύξησης, το οποίο αποδίδουν στην ύπαρξη του κενού. Η θέση του Αριστοτέλη είναι ότι, για να σταθεί η θεωρία τους, παραβιάζουν κάποια από τις τέσσερις συνθήκες που απαριθμεί. Το χωρίο πρέπει να διαβαστεί υπό το φως των αναλύσεων του Αριστοτέλη',
      image: 'https://source.unsplash.com/random',
      imageText: 'Image Text',
	  linkText: 'Περισσότερα…',
    },
    {
      title: 'Απάντηση 2',
      date: '04 Δεκ 2020, από τον Ηλία Γεωργούλα',
      description:
        'Οι σχολιαστές συμφωνούν ότι εδώ έχουμε σε συμπυκνωμένη μορφή την κριτική των επιχειρημάτων των Ατομικών για το φαινόμενο της αύξησης, το οποίο αποδίδουν στην ύπαρξη του κενού. Η θέση του Αριστοτέλη είναι ότι, για να σταθεί η θεωρία τους, παραβιάζουν κάποια από τις τέσσερις συνθήκες που απαριθμεί. Το χωρίο πρέπει να διαβαστεί υπό το φως των αναλύσεων του Αριστοτέλη',
      image: 'https://source.unsplash.com/random',
      imageText: 'Image Text',
	  linkText: 'Περισσότερα…',
    },
	    {
      title: 'Απάντηση 3',
      date: '05 Δεκ 2020, από τον Γιώργο Βάρσο',
      description:
        'Οι σχολιαστές συμφωνούν ότι εδώ έχουμε σε συμπυκνωμένη μορφή την κριτική των επιχειρημάτων των Ατομικών για το φαινόμενο της αύξησης, το οποίο αποδίδουν στην ύπαρξη του κενού. Η θέση του Αριστοτέλη είναι ότι, για να σταθεί η θεωρία τους, παραβιάζουν κάποια από τις τέσσερις συνθήκες που απαριθμεί. Το χωρίο πρέπει να διαβαστεί υπό το φως των αναλύσεων του Αριστοτέλη',
      image: 'https://source.unsplash.com/random',
      imageText: 'Image Text',
	  linkText: 'Περισσότερα…',
    },
    {
      title: 'Απάντηση 4',
      date: '10 Δεκ 2020, από την Μυτρώ Γκαράνη',
      description:
        'Οι σχολιαστές συμφωνούν ότι εδώ έχουμε σε συμπυκνωμένη μορφή την κριτική των επιχειρημάτων των Ατομικών για το φαινόμενο της αύξησης, το οποίο αποδίδουν στην ύπαρξη του κενού. Η θέση του Αριστοτέλη είναι ότι, για να σταθεί η θεωρία τους, παραβιάζουν κάποια από τις τέσσερις συνθήκες που απαριθμεί. Το χωρίο πρέπει να διαβαστεί υπό το φως των αναλύσεων του Αριστοτέλη',
      image: 'https://source.unsplash.com/random',
      imageText: 'Image Text',
	  linkText: 'Περισσότερα…',
    },
		    {
      title: 'Απάντηση 5',
      date: '11 Δεκ 2020, από τον Ηλία Γεωργούλα',
      description:
        'Οι σχολιαστές συμφωνούν ότι εδώ έχουμε σε συμπυκνωμένη μορφή την κριτική των επιχειρημάτων των Ατομικών για το φαινόμενο της αύξησης, το οποίο αποδίδουν στην ύπαρξη του κενού. Η θέση του Αριστοτέλη είναι ότι, για να σταθεί η θεωρία τους, παραβιάζουν κάποια από τις τέσσερις συνθήκες που απαριθμεί. Το χωρίο πρέπει να διαβαστεί υπό το φως των αναλύσεων του Αριστοτέλη',
      image: 'https://source.unsplash.com/random',
      imageText: 'Image Text',
	  linkText: 'Περισσότερα…',
    },
    {
      title: 'Απάντηση 6',
      date: '13 Δεκ 2020, από τον Γιώργο Βάρσο',
      description:
        'Οι σχολιαστές συμφωνούν ότι εδώ έχουμε σε συμπυκνωμένη μορφή την κριτική των επιχειρημάτων των Ατομικών για το φαινόμενο της αύξησης, το οποίο αποδίδουν στην ύπαρξη του κενού. Η θέση του Αριστοτέλη είναι ότι, για να σταθεί η θεωρία τους, παραβιάζουν κάποια από τις τέσσερις συνθήκες που απαριθμεί. Το χωρίο πρέπει να διαβαστεί υπό το φως των αναλύσεων του Αριστοτέλη',
      image: 'https://source.unsplash.com/random',
      imageText: 'Image Text',
	  linkText: 'Περισσότερα…',
    },
  ];

  const post1 = "<h1> Sample blog post</h1><h4> April 1, 2020 by [Olivier](/)</h4><h3>This blog post shows a few different types of content that are supported and styled with Material styles. Basic typography, images, and code are all supported. You can extend these by modifying `Markdown.js`.</h3>";


  const post2 = "<h1>Heading</h1><h4> April 17, 2020 by [Olivier](/)</h4><h2>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Sed posuere consectetur est at lobortis. Cras mattis consectetur purus sit amet fermentum.</h2>";

  const post3 = "<h1> Sample blog post</h1><h4> April 1, 2020 by [Olivier](/)</h4><h2>This blog post shows a few different types of content that are supported and styled with Material styles. Basic typography, images, and code are all supported. You can extend these by modifying `Markdown.js`.</h2>";

  const posts = [post2, post2, post3];


  return (





    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <main className={classes.content}>
      <React.Fragment>
        <CssBaseline />

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}  style={{ paddingTop : '10px'}}>
            <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link color="inherit" href="/admin/SelectTransSem">
                  Main
                </Link>
                <Link color="inherit" href="/admin/Blog">
                  Blogs
                </Link>
                <Typography color="textPrimary">Περὶ γενέσεως καὶ φθορᾶς</Typography>
            </Breadcrumbs>
            </div>
            </GridItem>
        </GridContainer>


        <Container maxWidth="lg">
        {/*<Header title="Blog" sections={sections} />*/}
        <Header title="Περὶ γενέσεως καὶ φθορᾶς" />

            <MainFeaturedPost post={mainFeaturedPost} />
            <Grid container spacing={4}>
              {featuredPosts.map((post) => (
                <FeaturedPost key={post.title} post={post} />
              ))}
            </Grid>

            {/*<Grid container spacing={5} className={classes.mainGrid}>
              <Main title="From the firehose" posts={posts} />
				     <Sidebar
                title={sidebar.title}
                description={sidebar.description}
                archives={sidebar.archives}
                social={sidebar.social}
				  />
            </Grid>*/}

        </Container>
        {/*<Footer title="Footer" description="Something here to give the footer a purpose!" />*/}
      </React.Fragment>

      </main>
    </div>


  );
}
