import React from 'react';

import Button from "components/CustomButtons/Button.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const LabPagesNavigationFooter = (props) => {
  const { hierarchy, current_page } = props

  const LAB_VIEW_PAGE = '/admin/EduLabViewPage/'
  const LAB_PREVIEW_PAGE = '/admin/EduLabPreview/'
  const LAB_FULLVIEW_PAGE = '/admin/EduLabFullView/'

  /*const hierarchy = [{
    id: 100,
    units: [{
      id: 0, title: 'Φάκελος',
      chapters: [
      ]
    }, {id: 3, title: 'Unit 1', chapters: [
      {id: 5, title: 'Chapter 1.1.', pages: [
        {id: 6, title: 'Page 1.1.1'},
        {id: 7, title: 'Page 1.1.2'}
      ]}
    ]},
    {id: 8, title: 'Unit 2', chapters: [
      {id: 9, title: 'Chapter 2.1.', pages: [
        {id: 10, title: 'Page 2.1.1'},
        {id: 11, title: 'Page 2.1.2'}
      ]}
    ]}
    ]
  }]
  const current_page = 0*/

  const getPreviousLabPage = () => {
    const lab_id = hierarchy[0].id
    let previous = ''

    for (const [unit_idx, unit] of Object.entries(hierarchy[0].units)){
      //console.log('unit'+unit_idx)
      if (unit.id==current_page){
        previous = (unit_idx==='0') ? LAB_PREVIEW_PAGE + lab_id :
          getUnitLastPage(lab_id, hierarchy[0].units[unit_idx-1])
        break
      } else {
        //console.log('ch')
        for (const [chapter_idx, chapter] of Object.entries(unit.chapters)){
          if (chapter.id==current_page){
            previous = (chapter_idx==='0') ? getUnitPage(lab_id, unit) :
              getChapterLastPage(unit.chapters[chapter_idx-1])
            break
          } else {
            //console.log('pg')
            for (const [page_idx, page] of Object.entries(chapter.pages)){
              if (page.id==current_page){
                previous = (page_idx==='0') ? LAB_VIEW_PAGE + chapter.id :
                  LAB_VIEW_PAGE + chapter.pages[page_idx-1].id
                break
              }
            }
          }
        }
      }
    }

    //console.log(previous)
    return previous
  }

  const getUnitLastPage = (lab_id, unit) => {
    const numChapters = unit.chapters.length
    if (numChapters > 0){
      return getChapterLastPage(unit.chapters[numChapters - 1])
    } else {
      return getUnitPage(lab_id, unit)
    }
  }

  const getUnitPage = (lab_id, unit) => {
    if (unit.id===0) {
      return LAB_FULLVIEW_PAGE + lab_id
    } else {
      return LAB_VIEW_PAGE + unit.id
    }
  }

  const getChapterLastPage = (chapter) => {
    const numPages = chapter.pages.length
    if (numPages > 0){
      return LAB_VIEW_PAGE + chapter.pages[numPages-1].id
    } else {
      return LAB_VIEW_PAGE + chapter.id
    }
  }

  const getNextLabPage = () => {
    //const lab_id = hierarchy[0].id
    let next = ''

    for (const [unit_idx, unit] of Object.entries(hierarchy[0].units)){
      //console.log('unit'+unit_idx)
      if (unit.id==current_page){
        next = (unit.chapters.length > 0) ? LAB_VIEW_PAGE + unit.chapters[0].id :
          getNextUnit(hierarchy[0].units, unit_idx)
        break
      } else {
        //console.log('ch')
        for (const [chapter_idx, chapter] of Object.entries(unit.chapters)){
          if (chapter.id==current_page){
            next = (chapter.pages.length > 0 ) ? LAB_VIEW_PAGE + chapter.pages[0].id :
              getNextChapter(hierarchy[0].units, unit_idx, unit.chapters, chapter_idx)
            break
          } else {
            //console.log('pg')
            for (const [page_idx, page] of Object.entries(chapter.pages)){
              if (page.id==current_page){
                next = (page_idx < chapter.pages.length - 1) ? LAB_VIEW_PAGE + chapter.pages[parseInt(page_idx)+1].id :
                  getNextChapter(hierarchy[0].units, unit_idx, unit.chapters, chapter_idx)
                break
              }
            }
          }
        }
      }
    }

    //console.log(next)
    return next
  }

  const getNextChapter = (units, unit_idx, chapters, chapter_idx) => {
    if (chapter_idx < chapters.length - 1) {
      return LAB_VIEW_PAGE + chapters[parseInt(chapter_idx) + 1].id
    } else {
      return getNextUnit(units, unit_idx)
    }
  }

  const getNextUnit = (units, unit_idx) => {
    if (unit_idx < units.length - 1) {
      return LAB_VIEW_PAGE + units[parseInt(unit_idx) + 1].id
    } else {
      return ''
    }
  }

  const onPreviousClick = () => {
    window.location.href = getPreviousLabPage()
  }

  const onNextClick = (nextPage) => {
    //console.log(getNextLabPage())
    window.location.href = nextPage
  }

  const nextPage = hierarchy[0] ? getNextLabPage() : ''

  return (
    <div style={{paddingBottom: 20}}>
      <Button style={{float: 'left'}} color="primary" onClick={onPreviousClick}>
        <ArrowBackIcon/>
      </Button>
      {
        nextPage &&
        <Button style={{float: 'right'}} color="primary" onClick={() => onNextClick(nextPage)}>
          <ArrowForwardIcon/>
        </Button>
      }
    </div>
  )
}

export default LabPagesNavigationFooter
