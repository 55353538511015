export const hasDeleteRightsForParents = (parents, father, access_rights_forums_d) => {
  let all_parents = parents ? [...parents] : []
  all_parents.push({id: father})
  //console.log(all_parents)
  const included = all_parents.filter(p => access_rights_forums_d.includes(p.id))
  //console.log(included)
  return (included.length > 0)
}

export const filterVisible = (item) => {
  return !item.hidden
}

export const saveDraft = (name, content, keywords) => {
  const post_draft = {name: name, content: content, keywords: keywords}
  /*localStorage.setItem('name_draft', name)
  localStorage.setItem('keywords_draft', key)*/
  localStorage.setItem('post_draft', JSON.stringify(post_draft))
}

export const getDraft = () => {
  const draft = localStorage.getItem('post_draft')
  if (draft){
    return JSON.parse(draft)
  } else {
    return null
  }
}
