import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import { makeStyles } from '@material-ui/core/styles';
import { CloseSquare, PlusSquare, MinusSquare, CheckboxTreeItem } from 'views/CollTranslationREADONLY/TreeUtils.js'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    //maxWidth: 500,
  },
});

export default function LabPageLevelSelectionTree(props) {
  const classes = useStyles();

  const { hierarchy, defaultSelected, onSelect, element_id, page_id } = props
  const [selected, setSelected] = React.useState('')
  const [defaultExpanded, setDefaultExpanded] = React.useState([])

  const handleSelect = (event, nodeId) => {
    //console.log(nodeId)
    setSelected(nodeId)
    onSelect(nodeId)
  }

  React.useEffect(() => {
    //expand all by default
      let expanded = []
      hierarchy && hierarchy.map((lab_level) => {
        //defaultExpanded.push('-1')
        lab_level.units && lab_level.units.map((unit_level) => {
          expanded.push(unit_level.id.toString())
          unit_level.chapters && unit_level.chapters.map((chapter_level) => {
            expanded.push(chapter_level.id.toString())
          })
        })
      })

      setDefaultExpanded(expanded)
  }, [hierarchy])

  React.useEffect(() => {
    setSelected(defaultSelected ? defaultSelected.toString() : '')
  }, [defaultSelected])

  return (
    <TreeView id={element_id || ''}
      className={classes.root}
      expanded={defaultExpanded}
      selected={selected}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
    >
    {
      hierarchy && hierarchy.map((lab_level) =>
        <CheckboxTreeItem key={lab_level.id} nodeId={'0'} label='Εργαστήριο'
          handleSelect={handleSelect} checked={selected==='0'}>
        {
          lab_level.units && lab_level.units.filter(u => (u.id > 0)).map((unit_level) =>
              <CheckboxTreeItem key={lab_level.id+'-'+unit_level.id} nodeId={unit_level.id.toString()}
                label={unit_level.title} handleSelect={handleSelect} checked={selected===unit_level.id.toString()}
                disabled={unit_level.id==page_id}>
                {
                  unit_level.chapters && unit_level.chapters.map((chapter_level) =>
                  <CheckboxTreeItem key={lab_level.id+'-'+chapter_level.id}
                    nodeId={chapter_level.id.toString()} label={chapter_level.title}
                    handleSelect={handleSelect} checked={selected===chapter_level.id.toString()}
                    disabled={chapter_level.id==page_id}>
                  </CheckboxTreeItem>
                )
                }
              </CheckboxTreeItem>
          )
        }
        </CheckboxTreeItem>
      )
    }
    </TreeView>
  );
}
