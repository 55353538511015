import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Assignment from "@material-ui/icons/Assignment";

import Button from "components/CustomButtons/Button.js";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import ListSubheader from '@material-ui/core/ListSubheader';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { getAllLabs, uploadCodes } from "clients/labEducationEtcClient.js"
import { checkNoErrors } from 'utils.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const useStyles = makeStyles((theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardFix: {
      fontSize: "1em!important",
  },
  fileinput: {
    display: 'none',
  },
}))

const CodesUploadTab = (props) => {
  const classes = useStyles()
  const { showError, showSuccess } = props

  const [education_labs, setEducation_labs] = React.useState([])
  const [tour_labs, setTour_labs] = React.useState([])
  const [summer_schools, setSummer_schools] = React.useState([])

  const [lab_id, setLab_id] = React.useState('')
  const [file, setFile] = React.useState(null)
  const [errors, setErrors] = React.useState({})
  const [saving, setSaving] = React.useState(false)

  //fields changes
  const handleLabChange = (e) => {
    const newVal = (e.target.value===undefined) ? '' : e.target.value
    let newErrors = Object.assign({}, errors)
    newErrors.lab_id = validate('lab_id', newVal)
    setErrors(newErrors)

    setLab_id(newVal)
  }

  //file upload function
  const uploadFile = (e) => {
    if (e.target.files[0]){
      const allowedExtensions = /(\.(txt))$/i;
      let newErrors = Object.assign({}, errors)

      if (!allowedExtensions.exec(e.target.files[0].name)) {
        newErrors.file = "Πρέπει να ανεβάσετε αρχείο txt"
        setFile(null)
      } else {
        setFile(e.target.files[0])
        newErrors.file = ''
      }

      setErrors(newErrors)
    }
  }

  const validate = (name, value) => {
    switch (name) {
      case 'lab_id':
        return (value==='') ? 'Υποχρεωτικό πεδίο' : ''

      default:
        return ''
    }
  }

  const handleSubmit = () => {
    let newErrors = Object.assign({}, errors)
    newErrors.lab_id = validate('lab_id', lab_id)
    newErrors.file = file ? '' : 'Υποχρεωτικό πεδίο'
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      setSaving(true)
      const params = {lab_id: lab_id}
      //console.log(params)
      //console.log(file)

      uploadCodes(file, params,
        () => {
          showSuccess('Η καταχώριση έγινε με επιτυχία')
          setSaving(false)
          //remove lab from available list
          setEducation_labs(labListWithoutLabId(education_labs))
          setTour_labs(labListWithoutLabId(tour_labs))
          setSummer_schools(labListWithoutLabId(summer_schools))
          //reset fields
          setFile(null)
          setLab_id('')
        },
        (err) => {
          showError(err.message)
          setSaving(false)
        }
      )
    }
  }

  const labListWithoutLabId = (labList) => {
    let temp = [...labList]
    const pos = temp.findIndex(lab => lab.id === lab_id)
    if (pos >= 0){
      temp.splice(pos, 1)
    }
    return temp
  }

  React.useEffect(() => {
    getAllLabs('?purchase_codes=false',
      (data) => {
        setEducation_labs(data.education_labs)
        setTour_labs(data.tour_labs)
        setSummer_schools(data.summer_schools)
      },
      (err) => console.log(err)
    )
  }, [])

  return(
     <Container maxWidth='lg'>

       <Card className={classes.cardFix}>
         <CardHeader className="AristosCardHeader" icon>
           <CardIcon color="primary">
             <Assignment />
           </CardIcon>
           <h4>Ανέβασμα κωδικών</h4>
         </CardHeader>

         <CardBody>
           <FormControl sx={{ m: 1, minWidth: 200 }} error={Boolean(errors.lab_id)}>
             <InputLabel id="lab-select">Επιλογή εργαστηρίου</InputLabel>
             <Select
               style={{ marginTop : '10px', marginBottom:'20px', minWidth: 200}}
               value={lab_id}
               onChange={handleLabChange}
               label="Επιλογή εργαστηρίου"
               labelId="lab-select"
             >
               {
                 access_rights && access_rights.labs_edu.w &&
                  <ListSubheader>Εκπαιδευτικά-περιηγητικά εργαστήρια</ListSubheader>
                }
                {
                  education_labs.map(lab =>
                    <MenuItem key={lab.id} value={lab.id}>{lab.title}</MenuItem>
                  )
                }
               {
                 /*access_rights && access_rights.labs_tour.w &&
                  <ListSubheader>Περιηγητικά εργαστήρια</ListSubheader>
                }
                {
                  tour_labs.map(lab =>
                    <MenuItem key={lab.id} value={lab.id}>{lab.title}</MenuItem>
                  )
                }
                {
                 access_rights && access_rights.labs_summer.w &&
                  <ListSubheader>Summer schools</ListSubheader>
                }
                {
                  summer_schools.map(lab =>
                    <MenuItem key={lab.id} value={lab.id}>{lab.title}</MenuItem>
                  )*/
                }
              </Select>
             <FormHelperText>{errors.lab_id}</FormHelperText>
           </FormControl>

           <Grid container spacing={2}>
             <Grid item xs={12} sm={4}>
               <FormLabel>Αρχείο</FormLabel>
               <br/>
               <input
                 accept=".txt"
                 className={classes.fileinput}
                 id="contained-button-file"
                 type="file"
                 onChange={uploadFile}
               />
               <label htmlFor="contained-button-file">
                 <Button variant="contained" color="primary" component="span">
                   Ανεβασμα αρχείου
                 </Button>
               </label>
               <FormHelperText error>{errors.file}</FormHelperText>
             </Grid>
             <Grid item xs={12} sm={8}>
               {file && file.name}
             </Grid>
           </Grid>

           <Button color='primary' disabled={saving} onClick={handleSubmit}>Ανεβασμα</Button>
         </CardBody>
       </Card>

     </Container>
  )
}

export default CodesUploadTab
