import { fetchWithGET, fetchWithMethod } from './clientUtils.js'

function getAllRoles(success, errorFunc){
  return fetchWithGET('available_roles', '', success, errorFunc)
}

function addUsersToRole(vals, success, errorFunc){
  return fetchWithMethod('user_roles', 'POST', vals, success, errorFunc)
}

function editUserRole(vals, success, errorFunc){
  return fetchWithMethod('user_roles', 'PUT', vals, success, errorFunc)
}

function delUsersFromRole(vals, success, errorFunc){
  return fetchWithMethod('user_roles', 'DELETE', vals, success, errorFunc)
}

export { getAllRoles, addUsersToRole, editUserRole, delUsersFromRole }
