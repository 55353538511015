import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import Grid from '@material-ui/core/Grid';
import InputLabel from "@material-ui/core/InputLabel";

import LyricsDisplay from 'views/Lyrics/LyricsDisplay.js'
import LyricsOriginalComments from 'views/Lyrics/LyricsOriginalComments.js'
import LyricsTranslationComments from 'views/Lyrics/LyricsTranslationComments.js'
import WorksNavigationTree from './WorksNavigationTree.js'

import { getOneSection, getAllSections, getLabsForSection, getForumsForSection }
  from 'clients/sectionsClient.js'
import { getComments } from 'clients/commentsClient.js'
import { getLemmataForSection } from 'clients/lexiconClient.js'
import { getTranslationNotesForSection } from 'clients/translationNotesClient.js'
import transformSectionsForTree from './transformSectionsForTree.js'
import { disableEvent } from 'utils.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },


    exampleWrapper: {
      position: 'relative',
      marginTop: theme.spacing(0),
      height: 80,
    },


}));


export default function CollTranslationREADONLY(props) {

  const classes = useStyles();
  const spacing = 2;

  const formatTranslationText = (text) => {
    //replace newlines from file upload
    text = text.replace(/\n/g, '<br/>')

    //replace comments with link
    const matches = text.match(/\{\d+\}/g)
    if (matches){
      matches.forEach(m => {
        const num = /\d+/.exec(m)
        //console.log(num)
        text = text.replace(m, '<a href="#note' + num + '"><sup>' + num + '</sup></a>')
      })
    }

    return text
  }
  /*const addCommentsToText = (text, comments, boolView)  => {
    if (comments){
      let addedLength = 0
      comments.sort(sortCommentsByPosition).forEach((c, idx) => {
        const commentName = '[Σ' + (boolView ? (idx+1) : c.id) + ']'
        text = (c.position===0) ?
           commentName + text :
           text.slice(0, c.position + addedLength) + commentName + text.slice(c.position + addedLength)
        addedLength += commentName.length
      })
      return text
    } else {
      return text
    }
  }

  const sortCommentsByPosition = (a,b) => {
    return a.position-b.position
  }*/

  const [section, setSection] = React.useState({})
  const [allWorks, setAllWorks] = React.useState([])
  const [scholarComments, setScholarComments] = React.useState([])
  const [lemmata, setLemmata] = React.useState([])
  const [translationNotes, setTranslationNotes] = React.useState([])
  const [labs, setLabs] = React.useState([])
  const [forums, setForums] = React.useState([])
  const [isFirstSectionInWork, setIsFirstSectionInWork] = React.useState(false)

  React.useEffect(()=>{
    if (props.match.params.section_id){

      getOneSection(props.match.params.section_id,
        (data) => {
          //console.log(data.documents[0])
          setSection(data.documents[0])
        },
        (err) => window.location.href = '/admin/bookSelection'
      )

      getAllSections(
        (data) => setAllWorks(transformSectionsForTree(data.documents)),
        (err) => console.log(err.message)
      )

      getComments({doc_id: props.match.params.section_id},
        (data) => setScholarComments(data.comments),
        (err) => console.log(err.message)
      )

      getLemmataForSection(props.match.params.section_id,
        (data) => setLemmata(data.lemmata),
        (err) => console.log(err.message)
      )

      if (access_rights.translation.r){
        getTranslationNotesForSection(props.match.params.section_id,
          (data) => setTranslationNotes(data.comments),
          (err) => console.log(err.message)
        )
      }

      getLabsForSection(props.match.params.section_id,
        (data) => setLabs(data.labs),
        (err) => console.log(err.message)
      )

      getForumsForSection(props.match.params.section_id,
        (data) => setForums(data.forums),
        (err) => console.log(err.message)
      )
    }
  }, [props.match.params.section_id])

  React.useEffect(() => {
    const sectionWork = allWorks.filter(w => w.id === section.work_id)
    //console.log(sectionWork)
    if (sectionWork.length > 0){
      const firstSectionInWork = sectionWork[0].books[0].sections[0]
      /*console.log(firstSectionInWork.lines)
      console.log(firstSectionInWork.id)
      console.log(section.id)*/
      setIsFirstSectionInWork(firstSectionInWork.id===section.id)
    }
  }, [allWorks, section])


  const parse = require('html-react-parser');
  const forumsToShow = isFirstSectionInWork ?
    forums.filter(f => !f.section || f.section===section.id) :
    forums.filter(f => f.section===section.id)

  return (

    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} >
        <div>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" href="/admin/SelectTransSem">
              Main
            </Link>
            <Link color="inherit" href="/admin/bookSelection">
              Αριστοτελικά έργα, Μεταφράσεις, Σχόλια
            </Link>
            <Typography color="textPrimary">{section.work_name}</Typography>
            <Link color="inherit" href={"/admin/SelectLyrics/"+section.work_id+'-'+section.book_id}>
              {section.book_name || ''}
            </Link>
            <Typography color="textPrimary">Στίχοι {section.lines}</Typography>

        </Breadcrumbs>
        </div>
      </Grid>
     <Grid item xs={12} style={{ paddingTop : '20px'}}>
       <Grid container justify="center" spacing={spacing}>

           <Grid className={classes.root}>{/*// TODO: Here maybe change the size, so it doesn't take up the entire page, just a maximum*/}
               <Grid container spacing={2}>
                 <Grid item xs={12} sm container>
                   <Grid item xs container direction="row" spacing={2}>

                     <Grid item xs={12} sm={2}>
                      <WorksNavigationTree allWorks={allWorks} selectedSection={section.id}/>
                     </Grid>

                     <Grid item xs={12} sm={10}>

                     <Card id="mainTextsViewer">
                       <CardHeader className="sectionWorkAndBookHeader AristosCardHeader">
                          <span className="archaic_text">{section.work_name}</span> / {section.book_name}<br/><span class='smalltitle'>(Μεταφραστής: {section.translator})</span>
                       </CardHeader>
                       <CardBody>
                          <GridContainer spacing={2} onCut={disableEvent} onCopy={disableEvent}>
                            <GridItem xs={12} sm={12} md={6}>
                                <InputLabel style={{ color: "#AAAAAA", paddingBottom:"20px", marginTop:"20px" }}>Αρχαίο κείμενο</InputLabel>
                                <LyricsDisplay text={section.original_text}/>

                             </GridItem>

                             <GridItem xs={12} sm={12} md={6}>
                               <InputLabel style={{ color: "#AAAAAA", paddingBottom:"20px", marginTop:"20px" }}>Μετάφραση</InputLabel>
                               <Typography> {parse(section.translation_text ? formatTranslationText(section.translation_text) : '')}</Typography>
                               {/* TODO: this is a sample
                               <a href="#note1">Go to note1</a><br/>
                               <a href="#note2">Go to note2</a>
                               */}

                             </GridItem>

							</GridContainer>
                        </CardBody>
                        </Card>
                        <Card id="belowMainTextsViewer">
	                        <CardBody>                             
                              <GridContainer spacing={2} onCut={disableEvent} onCopy={disableEvent}>

                             <GridItem xs={12} sm={12} md={6}>
                                <LyricsOriginalComments scholarComments={scholarComments} lemmata={lemmata} labs={labs}
                                  forums={forumsToShow.filter(f => f.originalOrTranslation.includes('original'))}
                                  />
                             </GridItem>
                             <GridItem xs={12} sm={12} md={6}>
                                <LyricsTranslationComments translationNotes={translationNotes}
                                  forums={forumsToShow.filter(f => f.originalOrTranslation.includes('translation'))}
                                  />
                             </GridItem>
                         </GridContainer>
                       </CardBody>
                     </Card>


                   </Grid>

                 </Grid>

               </Grid>
           </Grid>
       </Grid>
     </Grid>
    </Grid>
    </Grid>
  </div>

  );
}
