import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';

const SelectWorksForRole = (props) => {
  const { work, allWorks, handleChange, error } = props
  return(
    <FormControl error={Boolean(error)}>
       <Select
         value={work}
         onChange={handleChange}
         multiple
         displayEmpty
         renderValue={(selected) => {
           if (selected.length === 0) {
              return <em>Επιλογή Έργων για πρόσβαση</em>;
            } else {
              const selNames = allWorks.filter((w) => selected.indexOf(w.id) > -1)
                .map((w) => w.name)
              //console.log(selNames)
              return selNames.join(', ');
            }
         }}
         style={{ marginTop : '10px', marginBottom:'20px'}}
       >
         {
           allWorks.map((w) =>
             <MenuItem
               key={w.id}
               value={w.id}
             >
               <Checkbox checked={work.indexOf(w.id) > -1} />
               {w.name}
             </MenuItem>
          )
         }

       </Select>
       <FormHelperText>{error}</FormHelperText>
    </FormControl>
  )
}

export default SelectWorksForRole
