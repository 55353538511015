import React, {Component} from 'react';
import {getSelectedBlock} from 'draftjs-utils';
import htmlToDraft from 'html-to-draftjs';
import {List} from 'immutable';
import {EditorState, ContentState, convertToRaw, convertFromRaw, Modifier} from 'draft-js';
import {Form, Icon} from 'semantic-ui-react';
import './styles.css';
import RichEditor from '../richeditor';
//import StatusMessage from '../statusmessage';
import FileUpload from '../fileupload';
import KeywordsInput from '../keywordsinput';

import Button from "components/CustomButtons/Button.js";
import FormHelperText from '@material-ui/core/FormHelperText';
import { checkNoErrors, arraysHaveSameContents } from 'utils.js'
import { maxCharsInPost } from '../../utils/config.js'
import { saveDraft, getDraft } from '../../utils/utils.js'

export default class NewPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      errors: {},
      file: null,
      fileError: '',
      keywords: [],
      keywordInput: '',
      //use this to enable Restore Draft button after a Draft Save, as localStorage changes don't cause a React re-render
      draftExists: false,
    };
  }

  componentDidUpdate(prevProps, prevState){
    const {success, error, postID, postContent, postFile, postKeywords} = this.props;
    if (prevProps.postID !== postID){
      if (postID){
        //const content  = convertFromRaw(JSON.parse(postContent));
        const editorState = this.convertToEditorState(postContent);
        this.setState({
          editorState,
          file: postFile ? {name: postFile} : null,
          fileError: '',
          keywords: postKeywords,
          keywordInput: '',
        });
      } else {
        this.setState({
          editorState: EditorState.createEmpty(),
          file: null,
          fileError: '',
          keywords: [],
          keywordInput: '',
        });
      }
    } else if (success && (prevProps.success !== success)){//} || prevState.editorState === this.state.editorState)) {
      //console.log('success')
      this.setState({
        editorState: EditorState.createEmpty(),
        file: null,
        fileError: '',
        keywords: [],
        keywordInput: '',
      });
    }
  }

  componentDidMount(){
    window.addEventListener('beforeunload', (e) => {
      const content = this.state.editorState.getCurrentContent().getPlainText();
      if (this.hasUnsavedContentInForm()){
        console.log('post')
        console.log(content)
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    });
  }

  hasUnsavedContentInForm = () => {
    const draft = getDraft()
    let savedContent = ''
    let savedKeywords = []

    if (draft){
      const { content, keywords } = draft
      const editorState = this.convertToEditorState(content);
      savedContent = editorState.getCurrentContent().getPlainText();
      savedKeywords = keywords
    }

    const formContent = this.state.editorState.getCurrentContent().getPlainText();
    if (formContent.trim().length > 0 && formContent !== savedContent ||
      this.state.keywords.length > 0 && !arraysHaveSameContents(this.state.keywords, savedKeywords) ||
      this.state.file != null
    ){
      /*console.log(formContent)
      console.log(savedContent)
      console.log(this.state.keywords)
      console.log(savedKeywords)*/
      return true
    } else {
      return false
    }
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
    });
  };

  onKeywordsInputChange = (e, newInputValue) => {
    /*console.log('input')
    console.log(newInputValue)*/
    this.setState({keywordInput: newInputValue})
  }

  onKeywordsValueChange = (e, newValue) => {
    /*console.log('value')
    console.log(newValue)*/
    this.setState({keywords: newValue})
  }

  onSave = () => {
    // save to localStorage
    const {editorState, keywords} = this.state;
    const content = JSON.stringify(
      convertToRaw(editorState.getCurrentContent()),
    );
    saveDraft('', content, keywords)
    this.setState({draftExists: true})
  }

  onRestore = () => {
    //restore from localStorage
    const draft = getDraft()
    if (draft){
      const { content, keywords } = draft
      /*console.log(content)
      console.log(keywords)*/
      const editorState = this.convertToEditorState(content);
      this.setState({
        keywords,
        editorState,
        keywordInput: '',
        //update errors with new values
        errors: {...this.state.errors, content: this.validateContent(editorState.getCurrentContent().getPlainText())}
      })
    }
  }

  onSubmit = () => {
    if (this.isFormValid()){
      const {editorState, file, keywords} = this.state;
      const {postID, threadID, createPost, editPost} = this.props;
      const content = JSON.stringify(
        convertToRaw(editorState.getCurrentContent()),
      );
      let newPost = {
        thread_id: threadID,
        content: content,
        file: file ? file.name : null,
        keywords: keywords,
      };
      //console.log(newPost)
      const newFile = file && file instanceof File ? file : null
      //console.log(newFile)
      if (postID){
        newPost.id = postID
        //console.log('editing')
        editPost(newPost, newFile)
      } else {
        //console.log('posting')
        createPost(newPost, newFile);
      }
    }
  };

  isFormValid = () => {
    const {editorState} = this.state;
    let errors = {...this.state.errors}
    errors.content = this.validateContent(editorState.getCurrentContent().getPlainText())
    this.setState({errors})
    return checkNoErrors(errors);
  }

  validateContent = (value) => {
    return value.trim().length > 0 ? '' : 'Υποχρεωτικό πεδίο'
  }

  isValidLength = contentState => {
    //const maxLength = this.props.maxLength || 1000;
    return contentState.getPlainText('').length < maxCharsInPost;
  };

  handleBeforeInput = input => {
    const {editorState} = this.state;
    if (!this.isValidLength(editorState.getCurrentContent())) {
      return 'handled';
    }
  };

  handlePastedText = (text, html, editorState, onChange) => {
    if (html) {
      const contentBlock = htmlToDraft(html);
      let contentState = editorState.getCurrentContent();
      contentBlock.entityMap.forEach((value, key) => {
        contentState = contentState.mergeEntityData(key, value);
      });
      contentState = Modifier.replaceWithFragment(
        contentState,
        editorState.getSelection(),
        new List(contentBlock.contentBlocks),
      );
      if (!this.isValidLength(contentState)) {
        return 'handled';
      }
      onChange(
        EditorState.push(editorState, contentState, 'insert-characters'),
      );
      return true;
    }
    const selectedBlock = getSelectedBlock(editorState);
    const newState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text,
      editorState.getCurrentInlineStyle(),
    );
    if (!this.isValidLength(newState)) {
      return 'handled';
    }
    onChange(EditorState.push(editorState, newState, 'insert-characters'));
    if (selectedBlock && selectedBlock.type === 'code') {
      return true;
    }
    return false;
  };

  onFileUpdate = (file, err) => {
    this.setState({file: file, fileError: err})
  }

  convertToEditorState = content => {
    let editorState = EditorState.createEmpty();
    if (content) {
      try {
        const contentState = convertFromRaw(JSON.parse(content));
        editorState = EditorState.createWithContent(contentState);
      } catch (error) {
        const contentState = ContentState.createFromText(content);
        editorState = EditorState.createWithContent(contentState);
      }
    }
    return editorState;
  };

  render() {
    const {isAuthenticated, isLoading, postID, postContent} = this.props;

    if (!isAuthenticated) {
      return <Button color='primary' onClick={() => window.location.href='/admin/SignIn'}>
        Συνδεθειτε για να απαντήσετε
        </Button>
      ;
    }

    const {editorState} = this.state;
    const draftExists = (getDraft() !== null) || this.state.draftExists

    return (
      <div className="newPost-show">
        <Form loading={isLoading} className="attached fluid segment">
          <RichEditor
            placeholder="Start typing your post content here..."
            editorState={editorState}
            wrapperClassName="newPost-wrapper"
            toolbarClassName="newPost-toolbar"
            editorClassName="newPost-editor"
            onEditorStateChange={this.onEditorStateChange}
            handleBeforeInput={this.handleBeforeInput}
            handlePastedText={this.handlePastedText}
          />
          <FormHelperText error>{this.state.errors.content}</FormHelperText>
          <FormHelperText>
            Αριθμός χαρακτήρων: {this.state.editorState.getCurrentContent().getPlainText('').length}
            &nbsp;(max. {maxCharsInPost})
          </FormHelperText>

          <FileUpload file={this.state.file} onFileUpdate={this.onFileUpdate}
            fileError={this.state.fileError}/>

          <KeywordsInput keywords={this.state.keywords}
            keywordInput={this.state.keywordInput} error=''
            handleInputChange={this.onKeywordsInputChange}
            handleValueChange={this.onKeywordsValueChange}/>

          <Button
            color="rose"
            size="sm"
            disabled={isLoading}
            onClick={this.onSubmit}>
            <Icon name="write" />
            {postID ? 'Καταχωριση' : 'Απάντηση'}
          </Button>
          <Button
            color="primary"
            size="sm"
            disabled={isLoading}
            onClick={this.onSave}>
            <Icon name="save" />
            Αποθήκευση Προσχεδίου
          </Button>
          <Button
            color='primary'
            size="sm"
            disabled={isLoading || !draftExists}
            onClick={this.onRestore}>
            Επαναφορά Προσχεδίου
          </Button>
          {
            postID &&
            <Button
              color="primary"
              size="sm"
              onClick={this.props.cancelEditPost}>
              <Icon name="write" />
              Ακύρωση
            </Button>
          }
        </Form>
      </div>
    );
  }
}
