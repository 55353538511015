import React from 'react';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Assignment from "@material-ui/icons/Assignment";

import { UsergroupActions } from "./AdminUsersGroupsButtonsActions.js"
import { AddUsergroupDialog } from "./AdminUsersGroupsDialogForms.js"
import { addUsergroup, editUsergroup, deleteUsergroup } from "clients/usergroupsClient.js"
import { getAllRoles } from 'clients/rolesClient.js'
import { checkNoErrors } from "utils.js"

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },

  LabelCol: {
    color:"#3f51b5",
    fontweight: "bold"
  },

  cardFix: {
      fontSize: "1em!important",
  },
}))

const UsergroupsTab = (props) => {

  const constructGroupsdataRow = (usergroup) => {
    return {
      id: usergroup.usergroup_id,
      usergroup_name: usergroup.usergroup_name,
      num_of_users: usergroup.num_of_users,
      actions: (
        <UsergroupActions usergroup={usergroup} handleClickEdit={handleClickEditUsergroup}
           handleClickDelete={(id) => showConfirmDelete(id, handleDeleteUsergroup)}
           />
      )
    };
  }

  const handleClickOpenGroup = (dialogTitle, dialogButtonLabel) => {
    setDialogTitle(dialogTitle);
    setDialogButtonLabel(dialogButtonLabel);
    setOpenGroup(true);
    setDisableGroupSubmit(false)
  };

  const handleCloseGroup = () => {
    setOpenGroup(false);

    setGroupId(0)
    setGroupName('')
    setSelectedRoles([])
    setWorksForRole({})
    setLabsForRole({})
    setForumsForRole({})

    setErrorsGroup({})
  };

  const handleClickEditUsergroup = (usergroup) => {
    setGroupId(usergroup.usergroup_id)
    //if we remove access rights data from getAllUsergroups, call getOneUsergroup here to get from db
    setGroupName(usergroup.usergroup_name)
    //console.log(usergroup)
    setSelectedRoles(usergroup.roles.map(r => r.id.toString()))
    let temp = {}
    let tempLabs = {}
    let tempForums = {}
    usergroup.roles.forEach(r => {
      temp[r.id] = r.works || []

      let labs_arr = []
      r.labs_tr && r.labs_tr.forEach(lab => labs_arr.push('tr_'+lab))
      //r.labs_edu && r.labs_edu.forEach(lab => labs_arr.push('edu_'+lab))
      tempLabs[r.id] = labs_arr

      tempForums[r.id] = r.forums || []
    })
    setWorksForRole(temp)
    setLabsForRole(tempLabs)
    setForumsForRole(tempForums)

    handleClickOpenGroup('Επεξεργασία Ομάδας', 'Επεξεργασία');
  }

  const handleAddUsergroup = () => {
    if (validateUsergroup()){
      //setDisableGroupSubmit(true)

      const rolesForParams = selectedRoles.map(r => {
        let rparams = {id: r, works: worksForRole[r] || [],
          forums: forumsForRole[r] || []}

        if (labsForRole[r]){
          let labs_tr = []
          //let labs_edu = []
          labsForRole[r].forEach(lab => {
            if (lab.startsWith('tr_')){
              labs_tr.push(parseInt(lab.substring(3)))
            }/* else if (lab.startsWith('edu_')){
              labs_edu.push(parseInt(lab.substring(4)))
            }*/
          })
          rparams.labs_tr = labs_tr
          //rparams.labs_edu = labs_edu
        }

        return rparams
      })

      let params = {usergroup_name: groupName,
        roles: rolesForParams
      }
      //console.log(params)

      if (groupId > 0){
        //edit
        params.usergroup_id = groupId
        //console.log(params)

        editUsergroup(params,
          (data) => {
              let newData = Groupsdata;
              const changeIndex = newData.findIndex((o) => o.usergroup_id===groupId);
              const updatedGroup = Object.assign(newData[changeIndex], params)
              newData[changeIndex] = updatedGroup
              updateUsergroupData(newData)

              handleCloseGroup()
              handleShowSuccessMessage('H καταχώριση έγινε με επιτυχία!')
              setDisableGroupSubmit(false)
          },
          (err) => {
            showErrorAlert(err.message)
            setDisableGroupSubmit(false)
          }
        )
      } else {
        //add
        //console.log(params)

        addUsergroup(params,
          (data) => {
              let newData = Groupsdata;
              newData.push(Object.assign(params, {usergroup_id: data.inserted_id, num_of_users: 0}))
              updateUsergroupData(newData)

              handleCloseGroup()
              handleShowSuccessMessage('H καταχώριση έγινε με επιτυχία!')
              setDisableGroupSubmit(false)
          },
          (err) => {
            showErrorAlert(err.message)
            setDisableGroupSubmit(false)
          }
        )
      }
    }
  }

  const handleDeleteUsergroup = (id) => {
    deleteUsergroup({usergroup_id: id},
      () => {
        let newData = Groupsdata;
        const pos = newData.findIndex((o) => (o.usergroup_id===id))
        if (pos >= 0){
          newData.splice(pos, 1)
          //console.log(newData)
          updateUsergroupData([...newData]);
        }
        handleShowSuccessMessage('Η διαγραφή έγινε με επιτυχία!')
      },
      (err) => showErrorAlert(err.message)
    )
  }

  const validateUsergroup = () => {
    let newErrors = {}
    newErrors.groupName = validate('groupName', groupName)
    newErrors.roles = validate('roles', selectedRoles)
    selectedRoles.forEach(r => {
      newErrors['works'+r] = validate('works', worksForRole[r] || '', r)
      newErrors['labs'+r] = validate('labs', labsForRole[r] || '', r)
      newErrors['forums'+r] = validate('forums', forumsForRole[r] || '', r)
    })
    setErrorsGroup(newErrors)

    return checkNoErrors(newErrors)
  }

  const validate = (name, value, roleid=0) => {
    switch (name) {
      case 'groupName':
        return (value==='') ? 'Υποχρεωτικό πεδίο' : ''

      case 'roles':
        return (value.length===0) ? 'Υποχρεωτική επιλογή ενός τουλάχιστον ρόλου' : ''

      case 'works':
        return (selectedRoles.includes(roleid) && roles[roleid].define_works && value.length===0) ? 'Υποχρεωτικό πεδίο' : ''

      case 'labs':
        return (selectedRoles.includes(roleid) && roles[roleid].define_labs && value.length===0) ? 'Υποχρεωτικό πεδίο' : ''

      case 'forums':
        return (selectedRoles.includes(roleid) && roles[roleid].define_forums && value.length===0) ? 'Υποχρεωτικό πεδίο' : ''

      default:
        return ''
    }
  }

  const handleRoleSelect = (e, roleid) => {
    let newRoles = selectedRoles
    if (e.target.checked){
      newRoles.push(roleid)
    } else {
      const pos = newRoles.indexOf(roleid);
      //console.log(pos+' '+id)
      if (pos >= 0){
        newRoles.splice(pos, 1)
      }
    }
    //console.log(newRoles)
    setSelectedRoles([...newRoles]);
    setErrorsGroup({...errorsGroup, roles: validate('roles', newRoles)})
  }

  const handleWorkSelect = (e, roleid) => {
    let temp = worksForRole
    temp[roleid] = e.target.value
    setWorksForRole(temp)
    //console.log(temp)
    setErrorsGroup({...errorsGroup, ['works'+roleid]: validate('works', e.target.value, roleid)})
  }

  const handleLabSelect = (e, roleid) => {
    let temp = labsForRole
    temp[roleid] = e.target.value
    setLabsForRole(temp)
    //console.log(temp)
    setErrorsGroup({...errorsGroup, ['labs'+roleid]: validate('labs', e.target.value, roleid)})
  }

  const handleForumSelect = (e, roleid) => {
    let temp = forumsForRole
    temp[roleid] = e.target.value
    setForumsForRole(temp)
    //console.log(temp)
    setErrorsGroup({...errorsGroup, ['forums'+roleid]: validate('forums', e.target.value, roleid)})
  }

  const handleChangeName = (e) => {
    setGroupName(e.target.value)
    setErrorsGroup({...errorsGroup, groupName: validate('groupName', e.target.value)})
  }

  const { Groupsdata, labsAllTypes, updateUsergroupData, handleShowSuccessMessage, showErrorAlert, showConfirmDelete } = props
  const GroupsdataFormatted = Groupsdata.map(group => constructGroupsdataRow(group))
  const classes = useStyles()

  const [groupId, setGroupId] = React.useState(0)
  const [groupName, setGroupName] = React.useState('')
  const [selectedRoles, setSelectedRoles] = React.useState([])
  const [worksForRole, setWorksForRole] = React.useState({})
  const [labsForRole, setLabsForRole] = React.useState({})
  const [forumsForRole, setForumsForRole] = React.useState({})

  const [errorsGroup, setErrorsGroup] = React.useState({})
  const [disableGroupSubmit, setDisableGroupSubmit] = React.useState(false)
  const [openGroup, setOpenGroup] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState('')
  const [dialogButtonLabel, setDialogButtonLabel] = React.useState('')

  const [roles, setRoles] = React.useState({})

  React.useEffect(()=>{
      getAllRoles(
        (data) => setRoles(data.roles),
        (err) => console.log(err)
      )
  }, [])

  return (
    <>
      <Card className={classes.cardFix}>
        <CardHeader className="AristosCardHeader" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4>Ομάδες χρηστών</h4>
        </CardHeader>
        <CardBody>

        <h4 className={classes.LabelCol}>Ομάδες</h4>

          <ReactTable
            columns={[
              {
                Header: "Ομάδα",
                accessor: "usergroup_name"
              },
              {
                Header: "Αριθμός μελών",
                accessor: "num_of_users"
              },

              {
                Header: "Ενέργειες",
                accessor: "actions"
              }
            ]}
            data={GroupsdataFormatted}
          />
          <Button className={classes.marginRight} onClick={() => handleClickOpenGroup('Προσθήκη Ομάδας', 'Προσθηκη')}>Προσθήκη Ομάδας</Button>

        </CardBody>
      </Card>

      <AddUsergroupDialog
        handleAdd={handleAddUsergroup} handleClose={handleCloseGroup} open={openGroup}
        dialogTitle={dialogTitle} dialogButtonLabel={dialogButtonLabel} errors={errorsGroup}
        groupName={groupName} selectedRoles={selectedRoles} worksForRole={worksForRole} roles={roles}
        handleChangeName={handleChangeName} handleRoleSelect={handleRoleSelect} handleWorkSelect={handleWorkSelect}
        labsForRole={labsForRole} handleLabSelect={handleLabSelect} labsAllTypes={labsAllTypes}
        forumsForRole={forumsForRole} handleForumSelect={handleForumSelect}
        disableButton={disableGroupSubmit}
      />

    </>
  )
}

export default UsergroupsTab
