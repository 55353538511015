import React from 'react';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  LblClass: {
    color: "#415EA2",
    fontWeight: "700"
  },
}));

const EditLyricsForm = (props) => {
  const classes = useStyles();
  const { handleLinesChange, handleTextChange,
     lines, original_text, errors } = props

  return (
    <>
        <Typography style = {{width:500, paddingTop:20}} variant="body2" className={classes.LblClass}>
          Στίχοι αναφοράς - χωρίο (πχ. 185a1-30):
        </Typography>
        <TextField
         id="outlined-helperText"
         error={Boolean(errors.lines)}
         helperText={errors.lines}
         style = {{width:500, paddingTop:10, marginTop:0}}
         value={lines}
         onChange={handleLinesChange}
       />

        <Typography variant="body2" style = {{width:500, paddingTop:30}} className={classes.LblClass}>
          Αρχαίο κείμενο προς μετάφραση:
        </Typography>

        <TextField
         id="filled-multiline-static"
         label=""
         variant='outlined'
         multiline
         rows={24}
         error={Boolean(errors.original_text)}
         helperText={errors.original_text}
         style = {{width:500, paddingTop:20}}
         value={original_text}
         onChange={handleTextChange}
       />
    </>
  )
}

const getValidationErrorsForLyricsEdit = (lines, sectionText) => {
  //console.log('validating')

  let newErrors = {}
  if (lines.length===0){
    newErrors.lines = 'Υποχρεωτικό πεδίο'
  }
  if (sectionText.length===0){
    newErrors.original_text = 'Υποχρεωτικό πεδίο'
  }

  return newErrors;
}

export {EditLyricsForm, getValidationErrorsForLyricsEdit}
