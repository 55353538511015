import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Button from "components/CustomButtons/Button.js";
import AddIcon from '@material-ui/icons/Add';

import EntryDisplay from './EntryDisplay.js'
import AllEntriesDisplay from './AllEntriesDisplay.js'

import { getAllLemmata, getOneLemma, getAllLexiconCategories } from 'clients/lexiconClient.js'
import { replace_diacretics_with_plain_letter } from 'utils.js'
import { sortLexicon } from 'sorting.js'
import queryString from 'query-string'

import get_rights_from_session from 'rights_from_session.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
}))

//access rights
const access_rights = get_rights_from_session()

const Lexicon = (props) => {
  const classes = useStyles()

  const [entry, setEntry] = React.useState({})
  const [entries, setEntries] = React.useState([])
  const [allCategories, setAllCategories] = React.useState([])
  //const [availableKeywords, setAvailableKeywords] = React.useState([])
  const [keywordToFilterBy, setKeywordToFilterBy] = React.useState('')

  const getPlainTitle = (title) => {
    return replace_diacretics_with_plain_letter(title.trim()).
        replace(/^«/, '').
        replace(/»$/, '')
  }

  React.useEffect(() => {
    //console.log(props.match.params.entry_id)
    if (props.match.params.entry_id){
      getOneLemma(props.match.params.entry_id,
        (data) => {
          if (data.lemmata[0]){
            if (data.lemmata[0].is_link){
              //links to other lemma - redirect to that
              window.location.href = '/admin/Lexicon/' + data.lemmata[0].link_to.lemma_id
            } else {
              setEntry(data.lemmata[0])
            }
          } else {
            window.location.href = '/admin/LexiconEntryNotFound'
          }
        },
        (err) => console.log(err)//window.location.href = '/admin/Lexicon'
      )
    } else {
      const qry_params = queryString.parse(props.location.search)
      //console.log(qry_params.keyword)
      setKeywordToFilterBy(qry_params.keyword)

      getAllLemmata(
        (data) => {
          const results = data.lemmata.map(l => {
            return {...l, plain_title: getPlainTitle(l.title)}
          })
          .sort(sortLexicon)
          setEntries(results)
        },
        (err) => console.log(err)
      )

      getAllLexiconCategories(
        (data) => setAllCategories(data.categories),
        (err) => console.log(err)
      )
    }
  }, [props.match.params.entry_id, props.location.search])

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <GridContainer className={classes.root} spacing={2}>
        <GridItem xs={12} >
          <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color="inherit" href="/admin/Lexicon">
                Λεξικό εννοιών
              </Link>
              {
                entry && entry.lemma_id &&
                <Link className="archaic_text" color="inherit" href={"/admin/Lexicon/"+entry.lemma_id}>
                  {entry.title}
                </Link>

              }
          </Breadcrumbs>
          </div>
        </GridItem>
        <GridItem  xs={12} style={{ paddingTop : '20px'}}>
        {
          access_rights.lexicon && access_rights.lexicon.w &&
          <div align='right'>
            <Button justIcon round color="primary" onClick={() => window.location.href='/admin/AddLexiconEntry/'}><AddIcon/></Button>
          </div>
        }

          {
            entry && entry.lemma_id ?
            <EntryDisplay entry={entry} access_rights={access_rights.lexicon}/> :
            <AllEntriesDisplay entries={entries} categories={allCategories}
              keywordToFilterBy={keywordToFilterBy}/>
          }
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default Lexicon
