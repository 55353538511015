import React from 'react';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Container from '@material-ui/core/Container';

import { Editor } from '@tinymce/tinymce-react';
import LinkToLexiconEntryForm from 'views/Editor/LinkToLexiconEntryForm.js'
import LinkToCommentForm from 'views/Editor/LinkToCommentForm.js'
import LinkToSectionForm from 'views/Editor/LinkToSectionForm.js'
import { apiURL } from 'clients/clientConfig.js'
import { editor_image_upload_handler } from 'clients/clientUtils.js'
import { addTranslationNote, editTranslationNote } from 'clients/translationNotesClient.js'
import { getAllSections } from 'clients/sectionsClient.js'

import { checkNoErrors } from 'utils.js'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  inner_form: {
    align: 'left',
    margin: '20px'
  }
}))

export default function TranslationNoteForm(props) {
    const classes = useStyles()

    const { handleSubmit, handleClose, handleError, note, section_id } = props
    const htmlEditorRef = React.useRef(null);

    const text = note.text || ''
    const [order, setOrder] = React.useState(note.order || '')
    const [sectionsRef, setSectionsRef] = React.useState(note.sectionsRef || [])
    const [errors, setErrors] = React.useState({})
    const [saving, setSaving] = React.useState(false)

    const [availableSections, setAvailableSections] = React.useState([])
    const [section, setSection] = React.useState({})
    const [refLine, setRefLine] = React.useState('')
    const [sectionErrors, setSectionErrors] = React.useState({})
    const [editingRefIdx, setEditingRefIdx] = React.useState(-1)

    //popup for custom buttons of editor
    const [showPopupLinkToEntry, setShowPopupLinkToEntry] = React.useState(false)
    const [showPopupLinkToComment, setShowPopupLinkToComment] = React.useState(false)
    const [showPopupLinkToSection, setShowPopupLinkToSection] = React.useState(false)

    const insertLinkToOtherEntry = (value) => {
      setShowPopupLinkToEntry(false)
      //console.log(value)
      htmlEditorRef.current.insertContent(value)
    }

    const insertLinkToComment = (value) => {
      setShowPopupLinkToComment(false)
      htmlEditorRef.current.insertContent(value)
    }

    const insertLinkToSeciton = (value) => {
      setShowPopupLinkToSection(false)
      htmlEditorRef.current.insertContent(value)
    }

    React.useEffect(() => {
      getAllSections(
        (data) => setAvailableSections(data.documents),
        (err) => console.log(err)
      )
    }, [])

    //functions for handling changesi in form fields
    const handleChangeOrder = e => {
      let newErrors = Object.assign(errors, {})
      newErrors.order = validate(e.target.value, 'order')
      setErrors(newErrors)

      setOrder(e.target.value)
    }

    const handleSectionChange = (e, value) => {
      let newErrors = Object.assign({}, sectionErrors)
      newErrors.section = !value || !value.id ? 'Υποχρεωτικό πεδίο' : ''
      setSectionErrors(newErrors)
      //console.log(value)

      setSection(value)
    }

    const handleRefLineChange = (e) => {
      setRefLine(e.target.value)
    }

    //functions for section reference addition/removal
    const addOrEditSectionRef = () => {
      let newErrors = Object.assign({}, sectionErrors)
      newErrors.section = !section || !section.id ? 'Υποχρεωτικό πεδίο' : ''
      setSectionErrors(newErrors)

      if (checkNoErrors(newErrors)){
        const newSectionRef = {id: section.id, work_id: section.work_id, work_name: section.work_name,
          lines: section.lines, refLine: refLine}
        //console.log(newSectionRef)

        if (editingRefIdx < 0){
          const tempSectionRefs = [...sectionsRef, newSectionRef]
          setSectionsRef(tempSectionRefs)
        } else {
          let tempSectionRefs = [...sectionsRef]
          tempSectionRefs[editingRefIdx] = newSectionRef
          setSectionsRef(tempSectionRefs)
        }

        resetSectionRefsFields()

      }
    }

    const resetSectionRefsFields = () => {
      setSection({})
      setRefLine('')
      setEditingRefIdx(-1)
    }

    const prepareToEditSectionRef = (sectionRef, idx) => {
      setSection(sectionRef)
      setRefLine(sectionRef.refLine)
      setEditingRefIdx(idx)
    }

    const removeSectionRef = (idx) => {
      let temp = [...sectionsRef]
      temp.splice(idx, 1)
      setSectionsRef(temp)
    }

    const validate = (value, fieldname) => {
      switch (fieldname) {
        case 'text':
          if (value.trim().length===0){
            return 'Υποχρεωτικό πεδίο'
          }
          break;

        case 'order':
          const pattern = /^[1-9][0-9]*$/
          if (value.toString().trim().length===0){
            return 'Υποχρεωτικό πεδίο'
          } else if (!pattern.test(value)){
            return 'Επιτρέπονται μόνο αριθμοί μεγαλύτεροι του 0'
          }
          break;

        default:
          return ''
      }

      return ''
    }

    //form submission
    const onSubmit = () => {
      const newText = (htmlEditorRef.current) ? htmlEditorRef.current.getContent() : ''

      let newErrors = {}
      newErrors.text = validate(newText, 'text')
      newErrors.order = validate(order, 'order')
      setErrors(newErrors)

      if (checkNoErrors(newErrors)){
        setSaving(true)
        let newNote = {text: newText, order: order, doc_id: section_id, sectionsRef: sectionsRef}

        if (note.note_id){
          newNote.note_id = note.note_id

          editTranslationNote(newNote,
            () => {
              setSaving(false)
              handleSubmit(newNote)
            },
            (err) => {
              setSaving(false)
              handleError(err.message)
            }
          )

        } else {
          addTranslationNote(newNote,
            (data) => {
              setSaving(false)
              newNote.note_id = data.inserted_id
              handleSubmit(newNote)
              //console.log(newNote)
            },
            (err) => {
              setSaving(false)
              handleError(err.message)
            }
          )
        }

      }
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          <FormLabel>Κείμενο</FormLabel>
          <Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
             onInit={(evt, editor) => htmlEditorRef.current = editor}
             initialValue={text}
             init={{
               height: 300,
               menubar: 'file edit table help',
               entity_encoding : "raw",
               plugins: [
                 'advlist autolink lists link image charmap print preview anchor',
                 'searchreplace visualblocks code fullscreen',
                 'insertdatetime media table paste code help wordcount imagetools'
               ],
               toolbar: 'undo redo | formatselect | ' +
               'bold italic underline | alignleft aligncenter ' +
               'alignright alignjustify | bullist numlist outdent indent | ' +
               'removeformat | addlinktolemma | addlinktocomment | addlinktosection | image | help',
               //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
               contextmenu: 'image imagetools table',
               //images
               /*
               https://www.tiny.cloud/docs/configure/file-image-upload/
               https://www.tiny.cloud/docs/advanced/php-upload-handler/
               */
               images_upload_url: apiURL + 'editor_image_upload',
               images_upload_handler: editor_image_upload_handler,
               automatic_uploads: true,
               block_unsupported_drop: true,
               file_picker_types: 'image',
               setup: function(editor) {
                  editor.ui.registry.addButton('addlinktolemma', {
                    text: 'Link σε λήμμα',
                    onAction: () => setShowPopupLinkToEntry(true),
                  });
                  editor.ui.registry.addButton('addlinktocomment', {
                    text: 'Link σε σχόλιο',
                    onAction: () => setShowPopupLinkToComment(true),
                  });
                  editor.ui.registry.addButton('addlinktosection', {
                    text: 'Link σε χωρίο',
                    onAction: () => setShowPopupLinkToSection(true),
                  });
                }
             }}
           />
           <FormHelperText error>{errors.text}</FormHelperText>
        </GridItem>

        <GridItem xs={12}>
          <TextField
           label="Σειρά εμφάνισης"
           error={Boolean(errors.order)}
           helperText={errors.order}
           value={order}
           onChange={(e) => handleChangeOrder(e)}
         />
        </GridItem>

        <GridItem xs={12}>
          <FormLabel>Χωρία</FormLabel>
          <Container maxWidth='xs' className={classes.inner_form}>
            <Autocomplete
              id="sections"
              options={availableSections.sort((a,b) => -b.work_name.localeCompare(a.work_name))}
              groupBy={(option) => option.work_name}
              getOptionLabel={(option) => option.lines || ''}
              getOptionSelected={(option, value) => option.id === value.id}
              style={{ width: 300 }}
              value={section}
              onChange={handleSectionChange}
              renderInput={(params) =>
                <TextField {...params}
                  label="Αριθμός χωρίου"
                  variant="standard"
                  error={Boolean(sectionErrors.section)}
                  helperText={sectionErrors.section}
                />}
            />

            <TextField
              label="Στίχος/οι"
              variant="standard"
              error={Boolean(sectionErrors.refLine)}
              style = {{paddingTop:10, marginTop:0}}
              value={refLine}
              onChange={handleRefLineChange}
            />
            <FormHelperText error>{sectionErrors.refLine}</FormHelperText>

            <Button onClick={addOrEditSectionRef} size='sm'>Προσθηκη</Button>
            <Button onClick={resetSectionRefsFields} size='sm'>Καθαρισμος</Button>
          </Container>

          <List dense>
            {sectionsRef && sectionsRef.map((section, idx) =>
              <ListItem key={idx}>
                {section.work_name + ', ' + section.lines + ' ' + section.refLine}
                <IconButton aria-label="edit" onClick={() => prepareToEditSectionRef(section, idx)}>
                  <EditIcon fontSize="small"/>
                </IconButton>
                <IconButton aria-label="delete" onClick={() => removeSectionRef(idx)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </ListItem>
            )}
          </List>
        </GridItem>

        <GridItem xs={6}>
          <Button onClick={onSubmit} color="rose" disabled={saving}>καταχώριση</Button>
        </GridItem>
        <GridItem xs={6}>
          <Button onClick={handleClose}>Ακύρωση</Button>

          <LinkToLexiconEntryForm onClose={()=>setShowPopupLinkToEntry(false)}
             onSave={insertLinkToOtherEntry} open={showPopupLinkToEntry}/>

          <LinkToCommentForm onClose={()=>setShowPopupLinkToComment(false)}
             onSave={insertLinkToComment} open={showPopupLinkToComment}/>

          <LinkToSectionForm onClose={()=>setShowPopupLinkToSection(false)}
             onSave={insertLinkToSeciton} open={showPopupLinkToSection} sections={availableSections}/>
        </GridItem>
      </GridContainer>
    )
}
