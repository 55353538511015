import invalidateSessionAndRedirectToSign from './logout.js'
import { apiURL, editorImagesURL, labsEduImagesURL, forumImagesURL } from './clientConfig.js'
//const apiURL = "http://localhost:5000/api/";
//const token = localStorage.getItem('token')
const credentialsMode = 'include'
/*
Refresh cookies:
https://medium.com/swlh/authentication-using-jwt-and-refresh-token-part-2-a86150d25152
https://www.npmjs.com/package/refresh-fetch
*/

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}


function fetchWithGET(path, querystring, success, errorFunc){
  //console.log(headers)
  return fetch(apiURL + path + querystring, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    credentials: credentialsMode,
  })

  .then(function(data){
    if (data.ok){
      return data.json();
    } else if (data.status===401 || data.status===422){
      //access forbidden - invalidate tokens and redirect to login
      invalidateSessionAndRedirectToSign()
    } else if (data.status===403) {
      window.location.href = '/admin/ForbiddenAccess'
    } else {
      throw(new Error(data.status+' - '+data.statusText));
    }
  })

  .then((data) => {
    if (data.result===1){
      return success(data);
    } else {
      throw(new Error(data.message))
    }
  })

  .catch((error) => {
    errorFunc(error);
  })
}

function fetchWithMethod(path, method, vals, success, errorFunc){
  //console.log(headers)
  return fetch(apiURL + path, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': getCookie('csrf_access_token')
    },
    method: method,
    body: JSON.stringify(vals),
    credentials: credentialsMode,
  })

  .then(function(data){
    if (data.ok){
      return data.json();
    } else if (data.status===401 || data.status===422){
      //access forbidden - invalidate tokens and redirect to login
      invalidateSessionAndRedirectToSign()
      //console.log(data)
    } else if (data.status===403) {
      window.location.href = '/admin/ForbiddenAccess'
    } else {
      throw(new Error(data.status+' - '+data.statusText));
    }
  })

  .then((data) => {
    if (data.result===1){
      success(data);

    } else {
      throw(new Error(data.message));
    }
  })

  .catch((error) => {
    errorFunc(error);
  })
}

function fetchWithFile(path, method, files, otherVals, success, errorFunc){
  // Create the formData object
  let formData = new FormData();

  //file(s)
  if (Array.isArray(files)){
    files.forEach((file, idx) =>
      formData.append(
        'file'+idx,
        file,
        file.name
      )
    )
  } else if (files!=null) {
    formData.append(
      "file",
      files,
      files.name
    )
  }

  //console.log(formData.get('file[]'))
  //non file parameters
  for (const [key, value] of Object.entries(otherVals)) {
    formData.append(key, (typeof value === 'object') ? JSON.stringify(value) : value)
  }
  /*console.log(file)
  console.log(otherVals)*/
  //console.log(headers)
  return fetch(apiURL + path, {
    headers: {
      'Accept': 'application/json',
      'X-CSRF-TOKEN': getCookie('csrf_access_token')
    },
    method: method,
    body: formData,
    credentials: credentialsMode,
  })

  .then(function(data){
    if (data.ok){
      return data.json();
    } else if (data.status===401 || data.status===422){
      //access forbidden - invalidate tokens and redirect to login
      invalidateSessionAndRedirectToSign()
      //console.log(data)
    } else if (data.status===403) {
      window.location.href = '/admin/ForbiddenAccess'
    } else {
      throw(new Error(data.status+' - '+data.statusText));
    }
  })

  .then((data) => {
    if (data.result===1){
      success(data);

    } else {
      throw(new Error(data.message));
    }
  })

  .catch((error) => {
    errorFunc(error);
  })
}

function fetchAllWithGET(paths_array, success, errorFunc){
  if (!Array.isArray(paths_array)){
    //only one request - return error
    errorFunc(new Error("Parameter paths_array of this function must be an array"))

  } else {
    //many requests - use Promise to wait for all of them to be resolved
    const fetches_array = paths_array.map(path => fetch(apiURL + path, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
      credentials: credentialsMode,
    }))

    Promise.all(fetches_array)
    .then(data_array =>
      Promise.all(
        data_array.map(data => {
          if (data.ok){
            return data.json();
          } else if (data.status===401 || data.status===422){
            //access forbidden - invalidate tokens and redirect to login
            invalidateSessionAndRedirectToSign()
            //console.log(data)
          } else if (data.status===403) {
            window.location.href = '/admin/ForbiddenAccess'
          } else {
            throw(new Error(data.status+' - '+data.statusText));
          }
        })
      )
    )
    .then(data_array => {
      const results = data_array.map(data => {
        if (data.result===1){
          return data
        } else {
          throw(new Error(data.message));
        }
      })

      //console.log(results)
      success(results)
    })
    .catch(error => {
      errorFunc(error)
    })
  }
}

function fetchBlob(path, success, errorFunc){
  //console.log(headers)
  return fetch(apiURL + path, {
    headers: {
      'Accept': 'application/json',
      //Content type not needed, get from blob
      //'Content-Type': 'application/msword'
    },
    method: 'GET',
    credentials: credentialsMode,
  })

  .then(function(data){
    if (data.ok){
      return data.blob();
    } else if (data.status===401 || data.status===422){
      //access forbidden - invalidate tokens and redirect to login
      invalidateSessionAndRedirectToSign()
    } else if (data.status===403) {
      window.location.href = '/admin/ForbiddenAccess'
    } else if (data.status===404) {
      return data.json()
    } else {
      throw(new Error(data.status+' - '+data.statusText));
    }
  })

  .then((data) => {
    if (data.message){
      //json error message
      return errorFunc(data)
    } else {
      //blob - success
      return success(data)
    }
  })

  .catch((error) => {
    errorFunc(error);
  })
}

function editor_image_upload_handler(blobInfo, success, failure) {
  generic_editor_image_upload_handler(blobInfo, 'editor_image_upload',
    editorImagesURL, success, failure)
}

function lab_editor_image_upload_handler(blobInfo, success, failure){
  generic_editor_image_upload_handler(blobInfo, 'labs_editor_image_upload',
    labsEduImagesURL, success, failure)
}

//this will get replaced if we end up using tinymce
function forum_image_upload_handler(file, success, failure){
  let formData = new FormData();
  formData.append('file', file);

  return fetch(apiURL + 'forum_image_upload', {
    headers: {
      'Accept': 'application/json',
      'X-CSRF-TOKEN': getCookie('csrf_access_token')
    },
    method: 'POST',
    body: formData,
    credentials: credentialsMode,
  })

  .then(function(data){
    //console.log('returned')
    if (data.ok){
      return data.json();
    } else {
      //access forbidden
      throw(new Error('HTTP Error: ' + data.status+' - '+data.statusText))
    }
  })

  .then((data) => {
    //console.log(data)
    if (!data || typeof data.location != 'string') {
      throw(new Error('Invalid JSON returned'));
    } else {
      success(forumImagesURL + data.location)
    }
  })

  .catch((error) => {
    failure(error);
  })
}

function generic_editor_image_upload_handler(blobInfo, endpoint, baseLocation, success, failure){
  let formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());

  return fetch(apiURL + endpoint, {
    headers: {
      'Accept': 'application/json',
      'X-CSRF-TOKEN': getCookie('csrf_access_token')
    },
    method: 'POST',
    body: formData,
    credentials: credentialsMode,
  })

  .then(function(data){
    //console.log('returned')
    if (data.ok){
      return data.json();
    } else {
      //access forbidden
      throw(new Error('HTTP Error: ' + data.status+' - '+data.statusText))
    }
  })

  .then((data) => {
    //console.log(data)
    if (!data || typeof data.location != 'string') {
      throw(new Error('Invalid JSON returned'));
    } else {
      success(baseLocation + data.location)
    }
  })

  .catch((error) => {
    failure(error.message, { remove: true });
  })
}

export { fetchWithGET, fetchWithMethod, fetchWithFile, fetchAllWithGET, fetchBlob,
  editor_image_upload_handler, lab_editor_image_upload_handler, forum_image_upload_handler }
