import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import EditIcon from '@material-ui/icons/Edit';
import Button from "components/CustomButtons/Button.js";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import LabPagesNavigationFooter from './LabPagesNavigationFooter.js'
import LabHierarchyTree from './LabHierarchyTree.js'
import ContentSearch from './ContentSearch.js'
import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'

import { getLabMainPage, getLabTypeName, getAccessRightsFieldForLabType } from './utils.js'
import { getOnePage, getLabFile } from 'clients/labEducationEtcClient.js'
import { downloadAndOpenBlob } from 'utils.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  paper: {
    padding: 20
  },
  right: {
    float: 'right',
  },
}))

const EduLabViewPage = (props) => {
  const classes = useStyles()
  const parse = require('html-react-parser');

  const [lab, setLab] = React.useState({})
  const [page, setPage] = React.useState({})
  const [hierarchy, setHierarchy] = React.useState([])

  const labs_type_access = getAccessRightsFieldForLabType('edu')

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  const downloadFile = (file_id, filename) => {
    getLabFile(file_id,
      (data) => downloadAndOpenBlob(data, filename),
      (err) => setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
    )
  }

  React.useEffect(()=>{
    if (props.match.params.page_id){
      getOnePage(props.match.params.page_id, '',
        (data) => {
          if (data.page){
            try {
              setPage(data.page)
              setLab(data.lab)
              setHierarchy(data.hierarchy)
            } catch (err){
              if (access_rights && labs_type_access && access_rights[labs_type_access].w){
                window.location.href = '/admin/EduLabEditPage/' + data.lab.id + '/' + data.page.id
              } else {
                throw(err)
              }
            }
          } else {
            window.location.href = '/admin/PageNotFound'
          }
        },
        (err) => window.location.href = '/admin/PageNotFound'
      )
    }
  }, [props.match.params.page_id])

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} >
          <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color='inherit' href={getLabMainPage(lab.lab_type || '')}>
                {getLabTypeName(lab.lab_type || '')}
              </Link>
              {lab.id &&
                <Link color='inherit' href={"/admin/EduLabFullView/"+lab.id}>{lab.title}</Link>
              }
              <Typography color="textPrimary">
                {page.title || ''}
              </Typography>
            </Breadcrumbs>
          </div>
        </Grid>

        <Grid container item xs={12} style={{ paddingTop : '20px'}}>
          <Grid item xs={12}>
            <ContentSearch lab_id={lab.id}/>
          </Grid>

          <Grid id="labhierarchytree" item xs={12} sm={3} md={2} xl={1}>
            <LabHierarchyTree hierarchy={hierarchy} defaultSelected={lab.id + '-' + page.id}/>
          </Grid>

          <Grid item xs={12} sm={9} md={10} xl={11}>
            <Container id="labviewpagecontainer" maxWidth='lg'>
              <Paper className={classes.paper}>
                <Typography variant='h4'>
                  {page.title || ''}&nbsp;
                  {!page.visible && <VisibilityOffIcon color='disabled'/>}
                  {
                    access_rights && labs_type_access && access_rights[labs_type_access].w &&
                    <Button justIcon round className={classes.right} color='primary'
                      onClick={() => window.location.href='/admin/EduLabEditPage/'+lab.id+'/'+page.id}><EditIcon/></Button>
                  }
                </Typography>

                {parse(page.content || '')}

                {
                  page.files && page.files.length > 0 &&
                  <>
                    <Typography variant='h6' component='div'>Συνημμένα αρχεία</Typography>
                    <List disablePadding>
                    {
                      page.files.map((file, idx) =>
                        <ListItem key={idx} button onClick={() => downloadFile(file.id, file.name)}>
                            <ListItemIcon><CloudDownloadIcon/></ListItemIcon>
                            <ListItemText primary={file.name} secondary={file.description || ''}/>
                          </ListItem>
                      )
                    }
                    </List>
                  </>
                }

                <Divider className={classes.divider}/>
                <LabPagesNavigationFooter hierarchy={hierarchy} current_page={page.id || ''}/>
                <br/>
              </Paper>
            </Container>
          </Grid>
        </Grid>
      </Grid>

      {alert}
    </div>
  )
}

export default EduLabViewPage
