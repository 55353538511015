import { fetchWithGET, fetchWithMethod } from './clientUtils.js'

function getAllUsers(success, errorFunc){
  return fetchWithGET('allusers', '', success, errorFunc)
}

function getUsersByGroup(group_id, boolInGroup, success, errorFunc){
  let querystring = '?group_id=' + group_id
  if (!boolInGroup){
    querystring += '&selection=out'
  }

  return fetchWithGET('allusers', querystring, success, errorFunc)
}

function getUsersByRole(role_id, boolHasRole, success, errorFunc){
  let querystring = '?role_id=' + role_id
  if (!boolHasRole){
    querystring += '&selection=out'
  }

  return fetchWithGET('users_from_role', querystring, success, errorFunc)
}

function getOneUser(id, success, errorFunc){
  return fetchWithGET('user/'+id, '', success, errorFunc)
}

function addUser(vals, success, errorFunc){
  return fetchWithMethod('register', 'POST', vals, success, errorFunc);
}

function editUser(vals, success, errorFunc){
  //console.log(vals)
  return fetchWithMethod('update_user_info', 'PUT', vals, success, errorFunc);
}

function changeUserPassword(vals, success, errorFunc){
  return fetchWithMethod('change_pass', 'PUT', vals, success, errorFunc);
}

function toggleActiveUser(vals, success, errorFunc){
  return fetchWithMethod('activate', 'PUT', vals, success, errorFunc);
}

function getOnlineUsers(success, errorFunc){
  return fetchWithGET('info_online_users', '', success, errorFunc)
}

function countOnlineUsers(success, errorFunc){
  return fetchWithGET('count_online_users', '', success, errorFunc)
}

function getUserNotifications(success, errorFunc){
  return fetchWithGET('user_notifications', '', success, errorFunc)
}

export { getAllUsers, getUsersByGroup, getUsersByRole, getOneUser,
  addUser, editUser, changeUserPassword, toggleActiveUser, getOnlineUsers,
  countOnlineUsers, getUserNotifications }
