import React from 'react'

import Typography from '@material-ui/core/Typography';
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'

import { getLemmataStartingWith } from 'clients/lexiconClient.js'

const LinkToLexiconEntryForm = (props) => {
  const { onSave, onClose, open } = props

  const [list, setList] = React.useState([])
  const [error, setError] = React.useState('')
  const [searchStr, setSearchStr] = React.useState('')
  const [searching, setSearching] = React.useState(false)

  const handleSearchChange = (e) => {
    setError(e.target.value==='' ? 'Δεν έχει συμπληρωθεί κείμενο αναζήτησης' : '')
    setSearchStr(e.target.value)
  }

  const handleSearch = () => {
    if (searchStr!==''){
      setSearching(true)

      getLemmataStartingWith(searchStr,
        (data) => {
          setList(data.lemmata.map((lemma) => {
            const temp = {name: lemma.title, link: '/admin/Lexicon/' + lemma.lemma_id};
            return temp
          }))
          setSearching(false)
        },
        (err) => {
          setError(err.message)
          setSearching(false)
        }
      )
    }
  }

  const handleSelect = (selected) => {
    if (props.return_unformatted){
      onSave(selected)
    } else {
      onSave("<a href='" + selected.link + "'>" + selected.name + "</a>")
    }
  }

  return(
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth
      maxWidth={"xs"}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Εισαγωγή link σε λήμμα του Λεξικού
        </DialogTitle>

        <DialogContent>
         <TextField
          id="outlined-search"
          helperText='Πληκτρολογήστε τα πρώτα γράμματα του λήμματος'
          value={searchStr}
          onChange={handleSearchChange}
          />
         <Button onClick={handleSearch} disabled={searchStr===''}>Αναζήτηση</Button>
         <FormHelperText error>{error}</FormHelperText>
         {
           list.length===0 && !searching && <Typography variant='body2'>Δε βρέθηκαν αποτελέσματα</Typography>
         }
         {
           searching && <CircularProgress/>
         }
         <List>
            {
              list.map((item) =>
               <ListItem key={item.name} value={item.name}>
                {item.name}&nbsp;&nbsp;<Link onClick={() => handleSelect(item)} href='#'>Επιλογή</Link>
              </ListItem>)
            }
         </List>
         </DialogContent>

         <DialogActions>
          <Button onClick={onClose}>Κλεισιμο</Button>
         </DialogActions>
      </Dialog>
  )
}

export default LinkToLexiconEntryForm
