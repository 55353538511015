import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';

import EditIcon from '@material-ui/icons/Edit';
import ReadIcon from '@material-ui/icons/EventNote'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Button from "components/CustomButtons/Button.js";

import { getAllLabs } from 'clients/labTranslationReceptionClient.js'
import { labsTRImagesURL } from 'clients/clientConfig.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  intro: {
    textAlign: 'justify',
  },
  centered: {
    textAlign: 'center',
  },
  labImage: {
    height: 120,
    width: 180,
    margin: 'auto',
    display: 'block'
  }
}))

const TranslationReceptionLabs = () => {
  const classes = useStyles()
  const parse = require('html-react-parser');

  const [labs, setLabs] = React.useState([])

  /*const labs = [
    {id: 1, title: 'Εργαστήριο 1', image: 'under_construction.png', coordinator: 'Β. Κάλφας', info: 'Πληροφορίες για το Εργαστήριο 1'},
    {id: 2, title: 'Εργαστήριο 2', image: 'under_construction.png', coordinator: 'Χ. Γρηγορίου', info: 'Πληροφορίες για το Εργαστήριο 2'},
    {id: 3, title: 'Εργαστήριο 3', image: 'under_construction.png', coordinator: 'Β. Κάλφας', info: 'Πληροφορίες για το Εργαστήριο 3'},
  ]*/

  React.useEffect(() => {
    getAllLabs(
      (data) => setLabs(data.translation_labs),
      (err) => console.log(err)
    )
  }, [])

  const allowedLabView = (lab_id) => {
    return access_rights.labs_tr && (access_rights.labs_tr.w || access_rights.labs_tr.r.includes(lab_id))
  }

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} >
        <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Typography color="textPrimary">Εργαστήρια μετάφρασης και πρόσληψης</Typography>
          </Breadcrumbs>
        </div>
      </Grid>

     <Grid item xs={12} style={{ paddingTop : '20px'}}>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.centered}>

            <p className={classes.intro}>
            Τα Εργαστήρια μετάφρασης και πρόσληψης οργανώνονται σε μια ανοικτή βάση διαρκούς έρευνας, με στόχο τον εμπλουτισμό των εργαλείων για την προσέγγιση των αρχαίων ελληνικών
            φιλοσοφικών κειμένων βάσει νέων φιλοσοφικών προσεγγίσεων του πρωτογενούς υλικού ή και της δευτερογενούς βιβλιογραφίας.
            </p>
            <p className={classes.intro}>
            Τα <b>εργαστήρια μετάφρασης</b> στεγάζουν την πολυετή συνεργασία ειδικών που αναλαμβάνουν να μεταφράσουν εκ νέου τα αριστοτελικά έργα. Στόχος της ερευνητικής ομάδας είναι να
            εξασφαλίσουν στους συμμετέχοντες τον απαραίτητο για την αποτελεσματική ανάγνωση σχολιασμό μαζί με χρήσιμα κριτικά εργαλεία για την ιδιοποίηση των κειμένων. Η νεοελληνική
            μετάφραση που αναρτάται στην πλατφόρμα ενσωματώνει τα πορίσματα των συζητήσεων αλλά και παραμένει ανοικτή σε κατοπινές επεξεργασίες και αναθεωρήσεις από το ειδικό και
            ενδιαφερόμενο κοινό.
            </p>
            <p className={classes.intro}>
            Τα <b>εργαστήρια πρόσληψης</b> εστιάζουν στον διαχρονικό διάλογο με το Αριστοτελικό κείμενο, που προσδίδει στις μεταφράσεις ερμηνευτικό βάθος. Καθώς το αριστοτελικό έργο
            αποτελεί έναν γνώμονα για τη διαμόρφωση ολόκληρης της φιλοσοφικής (αλλά σε μεγάλο βαθμό και της επιστημονικής) παράδοσης από την αρχαιότητα μέχρι σήμερα, συγκροτεί ένα
            ζωντανό υλικό που αναπλάθεται με κάθε νέα επανεξέταση και κάθε νέο σχολιασμό. Η πρόσληψη του Αριστοτέλη παράγει έτσι αφενός ένα νέο σώμα αναφοράς (τον «προσλαμβανόμενο
              Αριστοτέλη»), αφετέρου μια πλούσια ιστορία των ιδεών, εντός της οποίας η ερμηνεία συγκεκριμένων έργων ή εννοιών αποτυπώνει το πνεύμα της εποχής ή μια ισχυρή τάση του.
              Τα εργαστήρια πρόσληψης έρχονται να καλύψουν την ανάγκη συμπερίληψης του «προσλαμβανόμενου Αριστοτέλη» στην πλατφόρμα, ώστε να συνδεθεί η μεταφραστική παραγωγή με
              τη διεθνή ερευνητική κοινότητα.
            </p>
            <p className={classes.intro}>
            Τα εργαστήρια υλοποιούνται σε μεικτή βάση, δια ζώσης με δυνατότητα σύγχρονης εξ αποστάσεως διαδικτυακής σύνδεσης. Βλ. πληροφορίες συμμετοχής.
            </p>
            {
              access_rights.labs_tr && access_rights.labs_tr.w &&
              <Button justIcon round color="primary" onClick={() => window.location.href='/admin/TRLabEdit/'}><AddIcon/></Button>
            }
          </Grid>
          {labs && labs.map((lab) =>
            <Grid key={lab.id} item xs={12} sm={4}>
              <Card>

                <CardActionArea href={allowedLabView(lab.id) ? "/admin/TRLabView/"+lab.id : ""}>
                  <CardHeader title={lab.title} className="AristosCardHeader"/>
                  <CardMedia className="AristosLabImage" image={labsTRImagesURL+lab.id+'/'+lab.image} title={lab.title}/>
                  <CardContent>
                    <Typography variant='body2' component='div'>
                      Συντονιστής: {lab.coordinator}<br/>
                      {parse(lab.info || '')}
                    </Typography>
                  </CardContent>
                </CardActionArea>

                <CardActions>
                  {
                     allowedLabView(lab.id) &&
                    <Tooltip title='Προβολή'>
                      <IconButton aria-label="go to view" href={"/admin/TRLabView/"+lab.id}><ReadIcon style={{color:"#387BE1", marginTop:"2px"}}/></IconButton>
                    </Tooltip>
                  }
                  {
                    access_rights.labs_tr && access_rights.labs_tr.w &&
                    <Tooltip title='Επεξεργασία'>
                      <IconButton aria-label="go to edit" href={"/admin/TRLabEdit/"+lab.id}><EditIcon style={{color:"#EBBA1A"}}/></IconButton>
                    </Tooltip>
                  }
                </CardActions>
              </Card>
            </Grid>
          )}
          </Grid>
        </Container>
     </Grid>
  </Grid>
  </div>
  )
}

export default TranslationReceptionLabs
