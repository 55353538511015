import React from 'react';
import CustomInput from "components/CustomInput/CustomInput.js";
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from "components/CustomButtons/Button.js";

import { makeStyles } from '@material-ui/core/styles';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

//import Grid from '@material-ui/core/Grid';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import PermIdentity from "@material-ui/icons/PermIdentity";
import Clearfix from "components/Clearfix/Clearfix.js";

import { changeUserPassword } from "clients/usersClient.js"
import { checkNoErrors } from 'utils.js'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardFix: {
    fontSize: "1em!important",
    width: '600px',
  },
});

const PasswordForm = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { onSuccess, onError } = props

  const [oldpassword, setOldPassword] = React.useState('')
  const [newpassword, setNewPassword] = React.useState('')
  const [newpassword2, setNewPassword2] = React.useState('')
  const [errors, setErrors] = React.useState({})
  const [disableSubmit, setDisableSubmit] = React.useState(false)

  const handleChange = (e, fieldname) => {
     switch (fieldname){
       case 'oldpassword':
          setOldPassword(e.target.value)
          break;

      case 'newpassword':
          setNewPassword(e.target.value)
          break;

      case 'newpassword2':
          setNewPassword2(e.target.value)
          break;

     default:
         ;
       };
  }

  const handleSubmit = () => {
    const params = {oldpassword: oldpassword, newpassword: newpassword}

    if (validateAll()){
      setDisableSubmit(true)
      //console.log(params)

      changeUserPassword(params,
        () => {
          //show message
          onSuccess('H αλλαγή password έγινε με επιτυχία!')
          setDisableSubmit(false)
        },
        (err) => {
          onError(err.message)
          setDisableSubmit(false)
        }
      )
    }
  }

  const validateAll = () => {
    let newErrors = {}

    if (oldpassword.length===0){
      newErrors.oldpassword = 'Υποχρεωτικό πεδίο'
    }

    newErrors.newpassword = validateNewPassword(newpassword)
    newErrors.newpassword2 = validateNewPassword(newpassword2)

    if (newpassword!==newpassword2){
      newErrors.newpassword = 'Οι κωδικοί δεν ταυτίζονται'
      newErrors.newpassword2 = 'Οι κωδικοί δεν ταυτίζονται'
    }
    /*if (userId==='' || userId < 0){
      newErrors.userId = 'Δε βρέθηκε ο χρήστης'
    }*/
    //console.log(newErrors)
    setErrors(newErrors)

    return checkNoErrors(newErrors);
  }

  const validateNewPassword = (password) => {
    if (password.length===0){
      return 'Υποχρεωτικό πεδίο'
    } else if (password.length < 8){
      return 'Το password πρέπει να είναι τουλάχιστον 8 χαρακτήρες'
    } else {
      return ''
    }
  }

  return (
    <div className={classes.root}>
      <GridItem xs={12}>
        <Card className={classes.cardFix}>
          <CardHeader icon className="AristosCardHeader">
            <CardIcon color="rose">
              <PermIdentity />
            </CardIcon>
            <h4>
              Αλλαγή Κωδικού
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  labelText="Παλιό Password"
                  id="oldpassword"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: oldpassword,
                    onChange: (e) => handleChange(e, 'oldpassword'),
                    type: "password",
                    autoComplete: "off",
                  }}
                  error={Boolean(errors.oldpassword)}
                />
                <FormHelperText error>{errors.oldpassword}</FormHelperText>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Νέο Password"
                  id="newpassword"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: newpassword,
                    onChange: (e) => handleChange(e, 'newpassword'),
                    type: "password",
                    autoComplete: "off",
                  }}
                  error={Boolean(errors.newpassword)}
                />
                <FormHelperText error>{errors.newpassword}</FormHelperText>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Επιβεβαίωση νέου Password"
                  id="newpassword2"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: newpassword2,
                    onChange: (e) => handleChange(e, 'newpassword2'),
                    type: "password",
                    autoComplete: "off",
                  }}
                  error={Boolean(errors.newpassword2)}
                />
                <FormHelperText error>{errors.newpassword2}</FormHelperText>
              </GridItem>
            </GridContainer>
            <Clearfix />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem align='right'>
        <Button color='primary' onClick={handleSubmit} disabled={disableSubmit}>Καταχωριση</Button>
      </GridItem>
    </div>
  )
}

export default PasswordForm
