import React from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from "components/CustomButtons/Button.js";

import { checkNoErrors } from 'utils.js'

const AddLexiconCatForm = (props) => {
  const { handleSubmit, handleClose } = props

  const [title, setTitle] = React.useState('')
  const [order, setOrder] = React.useState('')
  const [errors, setErrors] = React.useState({})

  React.useEffect(() => {
    setTitle(props.title)
    setOrder(props.order)
    setErrors({})
  }, [props.title, props.order])

  const handleTitleChange = (e) => {
    let newErrors = Object.assign(errors, {})
    newErrors.title = validate('title', e.target.value)
    setErrors(newErrors)

    setTitle(e.target.value)
  }

  const handleOrderChange = (e) => {
    let newErrors = Object.assign(errors, {})
    newErrors.order = validate('order', e.target.value)
    setErrors(newErrors)

    setOrder(e.target.value)
  }

  const onSubmit = () => {
    let newErrors = Object.assign(errors, {})
    newErrors.title = validate('title', title)
    newErrors.order = validate('order', order)
    setErrors({...newErrors})

    if (checkNoErrors(newErrors)){
      const params = {title: title, cat_order: parseInt(order)}
      handleSubmit(params)
    }
  }

  const validate = (field, value) => {
    switch (field){
      case 'title':
        return value.trim().length===0 ? 'Υποχρεωτικό πεδίο' : ''

      case 'order':
        const pattern = new RegExp(/[^0-9]/)
        if (value.length==0){
          return 'Υποχρεωτικό πεδίο'
        } else if (pattern.test(value)){
          return 'Επιτρέπονται μόνο αριθμοί'
        } else {
          return ''
        }

      default:
        return ''
    }
  }

  return(
    <GridContainer>
      <GridItem xs={12}>

      <CustomInput
        labelText={errors.title || "Τίτλος"}
        id="title"
        formControlProps={{
          fullWidth: true
        }}
        inputProps = {{
          value: title,
          onChange: handleTitleChange,
        }}
        error={Boolean(errors.title)}
        />
      <br/>

      <CustomInput
        labelText={errors.order || "Σειρά αρίθμησης"}
        id="order"
        formControlProps={{
          fullWidth: true
        }}
        inputProps = {{
          value: order,
          onChange: handleOrderChange,
        }}
        error={Boolean(errors.order)}
        />

      </GridItem>

      <GridItem xs={6}>
        <Button onClick={onSubmit} color="rose">καταχώριση</Button>
      </GridItem>
      <GridItem xs={6}>
        <Button onClick={handleClose}>Ακύρωση</Button>
      </GridItem>
    </GridContainer>
  )
}

export default AddLexiconCatForm
