import { replace_diacretics_with_plain_letter } from './utils.js'

function sortAlphabetically(a1, b1){
  const a = (typeof a1 === 'string') ? a1.toLowerCase() : a1
  const b = (typeof b1 === 'string') ? b1.toLowerCase() : b1
  if (a < b){
    return -1
  } else if (a > b){
    return 1
  } else {
    return 0
  }
}

function sortWorksAndBooks(works){
  works.forEach(w => {
    w.plain_name = replace_diacretics_with_plain_letter(w.name)
    if(w.books && Array.isArray(w.books)) {
      w.books.sort(sortBooks)
    }
  })

  works.sort(sortWorks)
  return works
}

function sortWorks(a, b){
  return sortAlphabetically(a.plain_name, b.plain_name)
}

function sortBooks(a, b){
  const aNumbering = /\d+\-\d+/.exec(a.name)
  const bNumbering = /\d+\-\d+/.exec(b.name)

  if (aNumbering ===null || bNumbering  === null){
    	return sortAlphabetically(a.name, b.name)
  } else {
    const aNum = /^\d+/.exec(aNumbering)
    const bNum = /^\d+/.exec(bNumbering)

    return aNum - bNum
  }
}

function sortSectionLines(a, b){
	  const aPrefix = /^\d+\w+\d+/.exec(a)
    const bPrefix = /^\d+\w+\d+/.exec(b)
    //console.log(aNum)
    if (aPrefix ===null || bPrefix  === null){
    	return sortAlphabetically(a,b)
    } else {
    	const aNum = /^\d+/.exec(aPrefix)
      const bNum = /^\d+/.exec(bPrefix)
    	const numDiff = aNum - bNum

      if (numDiff!==0){
      	return numDiff
      } else {
      	const restA = aPrefix[0].substring(aNum.toString().length)
        const restB = bPrefix[0].substring(bNum.toString().length)
        //console.log(restA)

        const aTxt = /^\D+/.exec(restA)
        const bTxt = /^\D+/.exec(restB)
        //console.log(aTxt)
      	const txtDiff = sortAlphabetically(aTxt, bTxt)
        if (txtDiff!==0){
        	return txtDiff
        } else {
        	return restA.substring(aTxt.toString().length) - restB.substring(bTxt.toString().length)
        }
      }
    }
}

function sortLexicon(a, b){
  return sortAlphabetically(a.plain_title, b.plain_title)
}

export { sortAlphabetically, sortSectionLines, sortBooks, sortWorksAndBooks, sortLexicon }
