import { fetchWithGET } from './clientUtils.js'

function searchConcordance(params, success, errorFunc){
  let querystring = '?'
  for(const key in params){
    if (!Array.isArray(params[key])){
      let val = params[key]
      if (typeof val === 'string'){
        val = val.replace(/&/g, 'and')
      }
      querystring += key + '=' + val + '&'
    } else {
      querystring += params[key].map(val => key + '=' + val).join('&') + '&'
    }
  }
  //console.log(querystring)

  fetchWithGET('concordance', querystring, success, errorFunc)
}


function searchAutocomplete(search, success, errorFunc){
  fetchWithGET('concordance/autocomplete/' + search, '',  success, errorFunc)
}

export { searchConcordance, searchAutocomplete }
