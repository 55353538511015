import { fetchWithGET, fetchWithFile } from './clientUtils.js'

function getAllLabs(success, errorFunc){
  return fetchWithGET('translation_labs', '', success, errorFunc)
}

function getOneLab(id, querystring, success, errorFunc){
  return fetchWithGET('translation_labs/' + id, querystring, success, errorFunc)
}

function addLab(files, vals, success, errorFunc){
  //return fetchWithMethod('translation_labs', 'POST', vals, success, errorFunc)
  return fetchWithFile('translation_labs', 'POST', files, vals, success, errorFunc)

}

function editLab(files, vals, success, errorFunc){
  //return fetchWithMethod('translation_labs', 'PUT', vals, success, errorFunc)
  return fetchWithFile('translation_labs', 'PUT', files, vals, success, errorFunc)
}

function getOneSeminar(id, success, errorFunc){
  return fetchWithGET('seminars/' + id, '', success, errorFunc)
}

function addSeminar(files, vals, success, errorFunc){
  return fetchWithFile('seminars', 'POST', files, vals, success, errorFunc)
}

function editSeminar(files, vals, success, errorFunc){
  return fetchWithFile('seminars', 'PUT', files, vals, success, errorFunc)
}

function getLabSeminars(lab_id, querystring, success, errorFunc){
  return fetchWithGET('translation_lab_seminars/' + lab_id, querystring, success, errorFunc)
}

export { getAllLabs, getOneLab, addLab, editLab, getOneSeminar, addSeminar, editSeminar, getLabSeminars }
