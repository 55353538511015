
import { fetchWithMethod } from './clientUtils.js'

function UserReg(vals, success, errorFunc){
  return fetchWithMethod('register', "POST", vals,
    (data) => {
      success(data)
    },
    (err) => {
      errorFunc(err)
    }
  )
}

function UserLogin(vals, UserLogin, success, errorFunc){
  //console.log(JSON.stringify(vals));
  //console.log(vals);
//  alert(JSON.stringify(vals));
  window.$userLogin = UserLogin;
  return fetchWithMethod('login', "POST", vals,
    (data) => {
      localStorage.setItem('UserName', data.user_logged_in.email);
      localStorage.setItem('UserFName', data.user_logged_in.firstname);
      localStorage.setItem('UserLName', data.user_logged_in.lastname);
      localStorage.setItem('UserId', data.user_logged_in.user_id);
      localStorage.setItem('token', data.token);
      localStorage.setItem('access_rights', JSON.stringify(data.access_rights));
      localStorage.setItem("thread_subscriptions", JSON.stringify(data.thread_subscriptions || []))
      //console.log(data)

      window.location.href = "./SelectTransSem";
      success(data)
    },
    (err) => {
      errorFunc(err);
    }
  )
}

function userLogout(success, errorFunc){
  return fetchWithMethod('logout', 'POST', {}, success, errorFunc)
}

export {UserReg, UserLogin, userLogout}
