import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import { makeStyles } from '@material-ui/core/styles';
import { CloseSquare, PlusSquare, MinusSquare, StyledTreeItem } from './TreeUtils.js'

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

export default function WorksNavigationTree(props) {
  const classes = useStyles();

  const { allWorks, selectedSection } = props
  const [expanded, setExpanded] = React.useState([])
  const [selected, setSelected] = React.useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    const parts = nodeIds.split('-')
    if (parts.length===3){
      window.location.href = '/admin/CollTranslationREADONLY/' + nodeIds
    }
  };

  React.useEffect(() => {
    if (selectedSection){
      const parts = selectedSection.split('-')
      setExpanded([parts[0], parts[0]+'-'+parts[1]])
      setSelected(selectedSection)
    }
  }, [selectedSection])

  return (
    <TreeView
      className={classes.root}
      expanded={expanded}
      selected={selected}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
      onNodeSelect={handleSelect}
      onNodeToggle={handleToggle}
    >
    {
      allWorks && allWorks.map((work) =>
        <StyledTreeItem key={work.id} nodeId={work.id.toString()} label={work.name}>
        {
          work.books.map((book) =>
            <StyledTreeItem key={work.id+'-'+book.id} nodeId={work.id+'-'+book.id} label={book.name}>
            {
              book.sections.map((section) =>
              <StyledTreeItem key={section.id} nodeId={section.id} label={section.lines}></StyledTreeItem>
            )
            }
            </StyledTreeItem>
          )
        }
        </StyledTreeItem>
      )
    }
    </TreeView>
  );
}
