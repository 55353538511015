import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SidebarShort from "components/Sidebar/SidebarShort.js";

import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import { UserLogin} from "clients/userRegisterAndAuth.js";
import { Copyright } from 'views/StandardIncludes/Footer.js'
import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'

import { emailRex } from 'variables/general.js'
// set UserName !=null to state that this page is the Login (to avoid loop from )
//localStorage.setItem('UserName', 'tmpUserName');

/*const RedirectToCollTrans = () => {

 window.location.href = "./SelectTransSem";

};*/


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };



  // register form

  const [LoginEmail, setLoginEmail] = React.useState("");
  const [LoginEmailState, setLoginEmailState] = React.useState("");
  const [RegisterPassword, setRegisterPassword] = React.useState("");
  const [RegisterPasswordState, setRegisterPasswordState] = React.useState("");




  // function that returns true if value is email, false otherwise
  const verifyEmail = value => {
    //var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };


  const loginClick = () =>{



    if (LoginEmailState === "") {
      setLoginEmailState("error");
    }
    if (RegisterPasswordState === "") {
      setRegisterPasswordState("error");
    }


  if ((LoginEmailState === "success") && (RegisterPasswordState === "success"))  {
        UserLogin({"username": LoginEmail, "password" : RegisterPassword}, LoginEmail,
          (success) =>{
            //console.log(success)
          },
          (err) => setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
        );
  }
  else {
    setAlert(<ErrorAlert message='Λάθος/Ελλιπείς παράμετροι εισόδου!' hideAlert={hideAlert}/>)
  }

  };


  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });


  return (

    <Container component="main" maxWidth="xs">
         <CssBaseline />
         <SidebarShort/>
         <div className={classes.paper}>

         <GridContainer justify="center">
           <GridItem xs={12} sm={12} >
             <form>
               <Card login className={classes[cardAnimaton]}>
                 <CardHeader
                   className="AristosCardHeader"
                 >
                   <h4 className={classes.cardTitle}>Είσοδος Χρήστη</h4>
                 </CardHeader>
                 <CardBody>
                 <Grid container spacing={2}>
                   <Grid item xs={12}>

                   <CustomInput
                   success={LoginEmailState === "success"}
                   error={LoginEmailState === "error"}

                     labelText="Email Address *"
                     id="email"
                     defaultValue=""
                     Value=""
                     value=""
                     text=""
                     formControlProps={{
                       fullWidth: true
                     }}
                     inputProps={{
                   onChange: event => {
                         if (verifyEmail(event.target.value)) {
                           setLoginEmailState("success");
                         } else {
                           setLoginEmailState("error");
                         }
                         setLoginEmail(event.target.value);
                       },
                       type: "email",
                       defaultValue :"",

                     }}
                   />
                   <CustomInput
                     success={RegisterPasswordState === "success"}
                     error={RegisterPasswordState === "error"}
                     labelText="Password *"
                     id="RegisterPassword"
                     defaultValue="testpass"
                     formControlProps={{
                       fullWidth: true
                     }}
                     inputProps={{
                       onChange: event => {
                         if (verifyLength(event.target.value, 1)) {
                           setRegisterPasswordState("success");
                         } else {
                           setRegisterPasswordState("error");
                         }
                         setRegisterPassword(event.target.value);
                       },
                       type: "password",
                       autoComplete: "off",
                       defaultValue :"",

                     }}
                   />

                   </Grid>


                 </Grid>


                 <Button
                //   type="submit"
                   fullWidth
                   variant="contained"
                   color="primary"
                //   className={classes.submit}
                   onClick={loginClick}

                 >

                  Είσοδος Χρήστη
                 </Button>
                 <Grid container justify="flex-end">
                   <Grid item>
                     <Link href="./SignUp" variant="body2">
                      Δεν έχετε εγγραφεί; Επιλέξτε Εγγραφή...
                     </Link>
                   </Grid>
                   <Grid item>
                     <Link href="./PasswordReset" variant="body2">
                      Ξέχασα τον κωδικό μου
                     </Link>
                   </Grid>
                 </Grid>
                 </CardBody>
                 <CardFooter className={classes.justifyContentCenter}>

                 </CardFooter>
               </Card>
             </form>
           </GridItem>
         </GridContainer>

         {alert}

         </div>
         
         
         
         
       </Container>


  );
}
