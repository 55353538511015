import {
  FETCH_SEARCH_REQUEST,
  FETCH_SEARCH_SUCCESS,
  FETCH_SEARCH_FAILURE,
  CHANGE_SEARCH_RESULTS_PAGE,
} from '../actions/types';

const initialState = {
  isLoading: false,
  results: null,
  error: null,
  page: 1,
};

const search = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_SEARCH_SUCCESS:
      return {
        isLoading: false,
        results: action.results,
        error: null,
        page: 1,
      };
    case FETCH_SEARCH_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case CHANGE_SEARCH_RESULTS_PAGE:
      return {
        ...state,
        page: action.page
      }
    default:
      return state;
  }
};

export default search;
