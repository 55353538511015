import React from 'react';
import ReactTable from "components/ReactTable/ReactTable.js";
import Container from '@material-ui/core/Container';

import EditIcon from '@material-ui/icons/Edit';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const TableView = (props) => {
  const { lyrics, translator, classes, canEdit } = props

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <ReactTable data={lyrics.map((section) => {
        return {
          id: section.id,
          lines: section.lines,
          translator: translator,
          actions: (
            <div className="actions-right">
              {
                canEdit() &&
                <Tooltip title='Επεξεργασία'>
                  <IconButton aria-label="go to edit" href={"/admin/CollTranslation/"+section.id} className="IconButtonLyrics">
                    <EditIcon style={{color:"#EBBA1A"}}/>
                  </IconButton>
                </Tooltip>
              }
              <Tooltip title='Ανάγνωση'>
                <IconButton aria-label="go to view" href={"/admin/CollTranslationREADONLY/"+section.id} className="IconButtonLyrics">
                  <ViewHeadlineIcon style={{color:"#EBBA1A"}}/>
                </IconButton>
              </Tooltip>
            </div>
          )
        }
      })}
      columns={[
        {
          Header: "Στίχοι",
          accessor: "lines"
        },
        {
          Header: "Μεταφραστής",
          accessor: 'translator'
        },
        {
          Header: "Ενέργειες",
          accessor: "actions",
          sortable: false,
          filterable: false
        }
      ]}
      />
    </Container>
  )
}

export default TableView
