import { fetchWithGET, fetchWithMethod } from './clientUtils.js'

function getTranslationNotesForSection(doc_id, success, errorFunc){
  return fetchWithGET('comments_on_translation', '?doc_id=' + doc_id, success, errorFunc)
}

function addTranslationNote(vals, success, errorFunc){
  return fetchWithMethod('comments_on_translation', 'POST', vals, success, errorFunc)
}

function editTranslationNote(vals, success, errorFunc){
  return fetchWithMethod('comments_on_translation', 'PUT', vals, success, errorFunc)
}

function deleteTranslationNote(vals, success, errorFunc){
  return fetchWithMethod('comments_on_translation', 'DELETE', vals, success, errorFunc)
}

export { getTranslationNotesForSection, addTranslationNote, editTranslationNote, deleteTranslationNote }
