import { fetchWithGET, fetchWithMethod, fetchAllWithGET } from 'clients/clientUtils.js'

export const fetchForumsApi = (fatherid, success, errorFunc) => {
  //return axios.get(FORUM_URL, getConfig());
  return fetchWithGET('forums/' + fatherid, '', success, errorFunc)
};

export const fetchForumApi = (forum, success, errorFunc) => {
  //return axios.get(FORUM_URL + forum, getConfig());
  return fetchWithGET('forum/' + forum, '', success, errorFunc)
};

export const createForumApi = (forum, success, errorFunc) => {
  //console.log(forum)
  return fetchWithMethod('forum', 'POST', forum, success, errorFunc)
}

export const editForumApi = (forum, success, errorFunc) => {
  //console.log(forum)
  return fetchWithMethod('forum', 'PUT', forum, success, errorFunc)
}

export const deleteForumApi = (forum, success, errorFunc) => {
  return fetchWithMethod('forum/' + forum, 'DELETE', {}, success, errorFunc)
}

export const massCreateForumsApi = (forumdata, success, errorFunc) => {
  return fetchWithMethod('forum/masscreate', 'POST', forumdata, success, errorFunc)
}

export const getAllLabsApi = (paths_array, success, errorFunc) => {
  return fetchAllWithGET(paths_array,
    (data_array) => {
      let newLabs = {}
      data_array.forEach(data => {
        if (data.translation_labs){
          newLabs.tr = data.translation_labs
        }
        if (data.education_labs){
          newLabs.edu = data.education_labs
        }
        if (data.tour_labs){
          newLabs.tour = data.tour_labs
        }
        if (data.summer_schools){
          newLabs.summer = data.summer_schools
        }
      })

      success(newLabs)
    },
    errorFunc
  )
}
