import React from 'react';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from "components/CustomButtons/Button.js";
import TagsInput from "react-tagsinput";
import FormLabel from '@material-ui/core/FormLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import { Editor } from '@tinymce/tinymce-react';

import { addScholarComment, editScholarComment } from 'clients/commentsClient.js'
import { checkNoErrors, addHttpToLink } from 'utils.js'
import { validUrlPattern } from 'variables/general.js'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fileinput: {
    display: 'none',
  },
  inner_form: {
    //// TODO:
  }
}));

export default function ScholarCommentForm(props) {
    const classes = useStyles()
    const textHtmlEditorRef = React.useRef(null);
    const translationGrHtmlEditorRef = React.useRef(null);
    const translationEngHtmlEditorRef = React.useRef(null);

    const { handleSubmit, handleClose, handleError, comment, section_id } = props

    const [scholar, setScholar] = React.useState(comment.scholar || '')
    const [scholar_reference, setScholar_reference] = React.useState(comment.scholar_reference || '')
    const [lines, setLines] = React.useState(comment.lines || '')
    const [phrase, setPhrase] = React.useState(comment.phrase || '')
    const text  = comment.text || ''
    const translationGr = comment.translationGr || ''
    const translationEng = comment.translationEng || ''
    const [tags_arr, setTags_arr] = React.useState(comment.tags_arr || [])
    const [links_arr, setLinks_arr] = React.useState(comment.links_arr || [])
    const [linkHref, setLinkHref] = React.useState('')
    const [editingLinkIdx, setEditingLinkIdx] = React.useState(-1)
    const [linkTitle, setLinkTitle] = React.useState('')
    const [filenames_arr, setFilenames_arr] = React.useState(comment.filenames_arr ?
      comment.filenames_arr.map(fname => {return {name: fname}}) :
      []);

    const [errors, setErrors] = React.useState({})
    const [fileError, setFileError] = React.useState('')

    const [saving, setSaving] = React.useState(false)

    //functions for handling changesi in form fields
    const handleChangeScholar = e => {
      let newErrors = Object.assign(errors, {})
      newErrors.scholar = validate(e.target.value, 'scholar')
      setErrors(newErrors)

      setScholar(e.target.value)
    }

    const handleChangeScholar_reference = e => {
      let newErrors = Object.assign(errors, {})
      newErrors.scholar_reference = validate(e.target.value, 'scholar_reference')
      setErrors(newErrors)

      setScholar_reference(e.target.value)
    }

    const handleChangeLines = e => {
      let newErrors = Object.assign(errors, {})
      newErrors.lines = validate(e.target.value, 'lines')
      setErrors(newErrors)

      setLines(e.target.value)
    }

    const handleChangePhrase = e => {
      setPhrase(e.target.value)
    }

    /*const handleChangeText = e => {
      let newErrors = Object.assign(errors, {})
      newErrors.text = validate(e.target.value, 'text')
      setErrors(newErrors)

      setText(e.target.value)
    }*/

    const handleChangeTags_arr = l => {
      setTags_arr(l)
    }

    const handleChangeLinkTitle = e => {
      setLinkTitle(e.target.value)
    }

    const handleChangeLinkHref = e => {
      setLinkHref(e.target.value)
    }

    const validate = (value, fieldname) => {
      switch (fieldname) {
        case 'scholar':
        case 'scholar_reference':
        case 'lines':
        case 'text':
          if (value.trim().length===0){
            document.getElementById(fieldname).focus()
            return  'Υποχρεωτικό πεδίο'
          } else {
            return
          }

        default:
          return '';
      }
    }

    //functions for link addition/removal
    const addOrEditLink = () => {
      let newErrors = Object.assign({}, errors)

      if (linkHref===''){
        newErrors.linkHref = 'Υποχρεωτικό πεδίο'
      } else if (!validUrlPattern.test(linkHref)){
        newErrors.linkHref = 'Μη έγκυρο link'
      } else {
        newErrors.linkHref =  ''
      }
      setErrors(newErrors)

      if (newErrors.linkHref===''){
        const newLink = {link: addHttpToLink(linkHref),
          title: (linkTitle==='') ? linkHref : linkTitle}

        if (editingLinkIdx < 0){
          const tempLinks = [...links_arr, newLink]
          setLinks_arr(tempLinks)
        } else {
          let tempLinks = [...links_arr]
          tempLinks[editingLinkIdx] = newLink
          setLinks_arr(tempLinks)
        }

        resetLinkFields()
      }
    }

    const resetLinkFields = () => {
      setLinkHref('')
      setLinkTitle('')
      setEditingLinkIdx(-1)
    }

    const prepareToEditLink = (link, idx) => {
      setLinkHref(link.link)
      setLinkTitle(link.title)
      setEditingLinkIdx(idx)
    }

    const removeLink = (idx) => {
      let temp = [...links_arr]
      temp.splice(idx, 1)
      setLinks_arr(temp)
    }

    //form submission
    const onSubmit = () => {
      const newText = (textHtmlEditorRef.current) ? textHtmlEditorRef.current.getContent() : ''
      const newTranslationGr = (translationGrHtmlEditorRef.current) ? translationGrHtmlEditorRef.current.getContent() : ''
      const newTranslationEng = (translationEngHtmlEditorRef.current) ? translationEngHtmlEditorRef.current.getContent() : ''

      let newErrors = {}
      newErrors.scholar = validate(scholar, 'scholar')
      newErrors.scholar_reference = validate(scholar_reference, 'scholar_reference')
      newErrors.lines = validate(lines, 'lines')
      newErrors.text = validate(newText, 'text')
      setErrors(newErrors)

      if (checkNoErrors(newErrors)){
        setSaving(true)
        let newComment = {scholar: scholar, scholar_reference: scholar_reference, lines: lines,
          phrase: phrase, text: newText, translationGr: newTranslationGr, translationEng: newTranslationEng,
          tags_arr: tags_arr, links_arr: links_arr, filenames_arr: filenames_arr.map(f => f.name)}
        //filenames_arr.forEach((f) => {console.log(f instanceof File);console.log(f.name)})
        //const existingFiles = filenames_arr.filter(f => !(f instanceof File))
        const newFiles = filenames_arr.filter(f => (f instanceof File))
        /*console.log(newComment)
        console.log(existingFiles)
        console.log(newFiles)*/

        if (comment.comment_id){
          newComment.comment_id = comment.comment_id
          //console.log(newComment)

          editScholarComment(newFiles, newComment,
            (data) => {
              setSaving(false)
              newComment.filenames_arr = data.filenames_arr
              handleSubmit(newComment)
            },
            (err) => {
              setSaving(false)
              handleError(err.message)
            }
          )
        } else {
          const [work_id, book_id] = section_id.split('-')
          newComment.work_id = parseInt(work_id)
          newComment.book_id = parseInt(book_id)
          newComment.doc_id = section_id

          addScholarComment(newFiles, newComment,
            (data) => {
              setSaving(false)
              newComment.comment_id = data.inserted_id
              newComment.filenames_arr = data.filenames_arr
              handleSubmit(newComment)
            },
            (err) => {
              setSaving(false)
              handleError(err.message)
            }
          )

        }

      }
    }

    //file handling functions
    const uploadFile = (e) => {
      //console.log('file upload')
      //console.log(e.target.filenames_arr[0].name)
      const allowedExtensions = /(\.(pdf|jpg|jpeg|gif|png|bmp|tiff|pjp|jfif|svg|svgz|webp|ico|xbm|dib|tif|pjpeg|avif))$/i;
      /*
      // TODO: check for size???
      if (e.target.filenames_arr[0].size > 1000){
        console.log('too big file')
      } else {*/
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        //console.log('error')
        setFileError("Πρέπει να ανεβάσετε αρχείο .pdf ή εικόνα")
      } else {
        //console.log('success')
        const temp = [...filenames_arr, e.target.files[0]]
        setFilenames_arr(temp)
        setFileError('')
      }
    }

    const removeFile = idx => {
      let temp = [...filenames_arr]
      temp.splice(idx, 1)
      setFilenames_arr(temp)
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          <CustomInput labelText="Υπομνηματιστής/Μελετητής" id="scholar"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: scholar,
              onChange: (e) => handleChangeScholar(e)
            }}
            error={Boolean(errors.scholar)}/>
          <FormHelperText error>{errors.scholar}</FormHelperText>
        </GridItem>

        <GridItem xs={12}>
          <CustomInput labelText="Βιβλιογραφική παραπομπή στο έργο του μελετητή" id="scholar_reference"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: scholar_reference,
              onChange: (e) => handleChangeScholar_reference(e)
            }}
            error={Boolean(errors.scholar_reference)}/>
          <FormHelperText error>{errors.scholar_reference}</FormHelperText>
        </GridItem>

        {/*
          Τα παρακάτω μπορεί να μπουν μετά στη φόρμα, για την ώρα τα παίρνει κατευθείαν από το χωρίο που άνοιξε τη φόρμα:
          - Έργο Αριστοτέλη
          - Βιβλίο Αριστοτέλη
          - Χωρίο Αριστοτέλη
        */}

        <GridItem xs={12}>
          <CustomInput labelText="Συγκεκριμένη παραπομπή στο έργο του Αριστοτέλη" id="lines"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: lines,
              onChange: (e) => handleChangeLines(e)
            }}
            error={Boolean(errors.lines)}/>
          <FormHelperText error>{errors.lines}</FormHelperText>
        </GridItem>

        <GridItem xs={12}>
          <CustomInput labelText="Λέξεις ή φράσεις του αρχαίου κειμένου όπου αναφέρεται το σχόλιο" id="phrase"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: phrase,
              onChange: (e) => handleChangePhrase(e)
            }}
            error={Boolean(errors.phrase)}/>
          <FormHelperText error>{errors.phrase}</FormHelperText>
        </GridItem>

        <GridItem xs={12}>
          <FormLabel>Κείμενο</FormLabel>
          <Editor id="text" tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
             onInit={(evt, editor) => textHtmlEditorRef.current = editor}
             initialValue={text}
             init={{
               height: 300,
               entity_encoding : "raw",
               plugins: [
                 'advlist autolink lists link image charmap print preview anchor',
                 'searchreplace visualblocks code fullscreen',
                 'insertdatetime media table paste code help wordcount imagetools'
               ],
               menubar: false,
               toolbar: 'undo redo | formatselect | ' +
               'bold italic underline | alignleft aligncenter ' +
               'alignright alignjustify | outdent indent | ' +
               'removeformat | help',
               //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
             }}
           />
           <FormHelperText error>{errors.text}</FormHelperText>
        </GridItem>

        <GridItem xs={12}>
          <FormLabel>Ελληνική μετάφραση</FormLabel>
          <Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
             onInit={(evt, editor) => translationGrHtmlEditorRef.current = editor}
             initialValue={translationGr}
             init={{
               height: 300,
               entity_encoding : "raw",
               plugins: [
                 'advlist autolink lists link image charmap print preview anchor',
                 'searchreplace visualblocks code fullscreen',
                 'insertdatetime media table paste code help wordcount imagetools'
               ],
               menubar: false,
               toolbar: 'undo redo | formatselect | ' +
               'bold italic underline | alignleft aligncenter ' +
               'alignright alignjustify | outdent indent | ' +
               'removeformat | help',
               //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
             }}
           />
          <FormHelperText error>{errors.translationGr}</FormHelperText>
        </GridItem>

        <GridItem xs={12}>
          <FormLabel>Αγγλική μετάφραση</FormLabel>
          <Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
             onInit={(evt, editor) => translationEngHtmlEditorRef.current = editor}
             initialValue={translationEng}
             init={{
               height: 300,
               entity_encoding : "raw",
               plugins: [
                 'advlist autolink lists link image charmap print preview anchor',
                 'searchreplace visualblocks code fullscreen',
                 'insertdatetime media table paste code help wordcount imagetools'
               ],
               menubar: false,
               toolbar: 'undo redo | formatselect | ' +
               'bold italic underline | alignleft aligncenter ' +
               'alignright alignjustify | outdent indent | ' +
               'removeformat | help',
               //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
             }}
           />
           <FormHelperText error>{errors.translationEng}</FormHelperText>
        </GridItem>

        <GridItem xs={12}>
          <FormLabel>Εξωτερικοί σύνδεσμοι</FormLabel>
          <Container maxWidth='xs' className={classes.inner_form}>
            <CustomInput labelText="Τίτλος" id="linkTitle"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: linkTitle,
                onChange: (e) => handleChangeLinkTitle(e)
              }}
              error={Boolean(errors.linkTitle)}/>
            <FormHelperText error>{errors.linkTitle}</FormHelperText>

            <CustomInput labelText="Link" id="linkHref"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: linkHref,
                onChange: (e) => handleChangeLinkHref(e),
              }}
              error={Boolean(errors.linkHref)}/>
            <FormHelperText error>{errors.linkHref}</FormHelperText>

            <Button onClick={addOrEditLink} size='sm'>Προσθηκη συνδεσμου</Button>
            <Button onClick={resetLinkFields} size='sm'>Καθαρισμος</Button>
          </Container>
          <List dense>
            {links_arr.map((link, idx) =>
              <ListItem key={idx}>
                <Link href={link.link}>{link.title}</Link>
                <IconButton aria-label="edit" onClick={() => prepareToEditLink(link, idx)}>
                  <EditIcon fontSize="small"/>
                </IconButton>
                <IconButton aria-label="delete" onClick={() => removeLink(idx)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </ListItem>
            )}
          </List>
        </GridItem>

        <GridItem xs={12}>
          <FormLabel>Ανάρτηση συνοδευτικού υλικού</FormLabel>
          <br/>
          <input
            accept=".pdf, image/*"
            className={classes.fileinput}
            id="contained-button-filenames_arr"
            type="file"
            onChange={uploadFile}
          />
          <label htmlFor="contained-button-filenames_arr">
            <Button variant="contained" color="primary" component="span">
              Ανεβασμα αρχειου
            </Button>
          </label>
          <FormHelperText error>{fileError}</FormHelperText>
          <List dense>
            {filenames_arr.map((f, idx) =>
              <ListItem key={idx}>{f.name}
                <IconButton aria-label="delete" onClick={() => removeFile(idx)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </ListItem>
            )}
          </List>
        </GridItem>

        <GridItem xs={12}>
          <FormLabel error={Boolean(errors.tags_arr)}>Λέξεις-κλειδιά (πληκτρολογήστε και πατήστε Enter)</FormLabel>
          <br/>
          <TagsInput
            value={tags_arr}
            onChange={handleChangeTags_arr}
            tagProps={{ className: "react-tagsinput-tag info"}}
            inputProps={{placeholder: 'Προσθήκη'}}
          />
          <FormHelperText error>{errors.tags_arr}</FormHelperText>
        </GridItem>

        <GridItem xs={6}>
          <Button onClick={onSubmit} color="rose" disabled={saving}>καταχώριση</Button>
        </GridItem>
        <GridItem xs={6}>
          <Button onClick={handleClose}>Ακύρωση</Button>
        </GridItem>
      </GridContainer>
    )
}
