import { fetchWithGET, fetchWithMethod } from './clientUtils.js'

function getAllUsergroups(success, errorFunc){
  return fetchWithGET('usergroups', '', success, errorFunc)
}

function getOneUsergroup(id, success, errorFunc){
  return fetchWithGET('usergroups/'+id, '', success, errorFunc)
}

function addUsergroup(vals, success, errorFunc){
  return fetchWithMethod('usergroups', 'POST', vals, success, errorFunc)
  //success({inserted_id: 100})
  //errorFunc({message: 'errMessages'})
}

function editUsergroup(vals, success, errorFunc){
  return fetchWithMethod('usergroups', 'PUT', vals, success, errorFunc)
  //success()
  //errorFunc({message: 'errMessages'})
}

function deleteUsergroup(vals, success, errorFunc){
  return fetchWithMethod('usergroups', 'DELETE', vals, success, errorFunc)
  //success()
  //errorFunc({message: 'errMessages'})
}

function addUsersToGroup(vals, success, errorFunc){
  return fetchWithMethod('usergroup_users', 'PUT', vals, success, errorFunc)
}

function delUsersFromGroup(vals, success, errorFunc){
  return fetchWithMethod('usergroup_users', 'DELETE', vals, success, errorFunc)
}

export { getAllUsergroups, getOneUsergroup, addUsergroup, editUsergroup, deleteUsergroup, addUsersToGroup, delUsersFromGroup }
