import React from 'react'

import Typography from '@material-ui/core/Typography';
import Button from "components/CustomButtons/Button.js";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'

import LyricsSelectionTree from 'views/LyricsSelectionTree/LyricsSelectionTree.js'

import { getComments } from 'clients/commentsClient.js'
import { truncateText } from 'utils.js'

const LinkToComment = (props) => {
  const { onSave, onClose, open } = props

  const [list, setList] = React.useState([])
  const [title, setTitle] = React.useState('')
  const [errorSearch, setErrorSearch] = React.useState('')
  const [errorTitle, setErrorTitle] = React.useState('')
  const [searching, setSearching] = React.useState(false)

  const [selected, setSelected] = React.useState('');

  /*const handleSelect = (nodeIds) => {
    //console.log(nodeIds)
    setSelected(nodeIds)
  };*/

  const handleSearch = () => {
    setErrorSearch(selected ? '' : 'Πρέπει να επιλέξετε έργο, βιβλίο ή χωρίο')

    if (selected){
      const parts = selected.split('-')
      const numParts = parts.length
      //console.log(numParts)
      let params = {}
      let invalidSelection = false

      switch(numParts){
        case 1:
          params.work_id = selected
          break;
        case 2:
          params.work_id = parts[0]
          params.book_id = parts[1]
          break;
        case 3:
          params.doc_id = selected
          break;
        default:
          invalidSelection = true
      }

      if (!invalidSelection){
        setSearching(true)
        //console.log(params)

        getComments(params,
          (data) => {
            setList(data.comments)
            setSearching(false)
          },
          (err) => {
            setErrorSearch(err.message)
            setList([])
            setSearching(false)
          }
        )
      }
    }
  }

  const handleTitleChange = (e) => {
    setErrorTitle(e.target.value==='' ? 'Υποχρεωτικό πεδίο' : '')
    setTitle(e.target.value)
  }

  const handleSelectComment = (comment) => {
    if (title===''){
      setErrorTitle('Υποχρεωτικό πεδίο')
    } else {
      resetFields()
      onSave({title: title, link: '/admin/CollTranslationREADONLY/' + comment.doc_id})
    }
  }

  const handleClose = () => {
    resetFields()
    onClose()
  }

  const resetFields = () => {
    setSelected('')
    setTitle('')
    setList([])
  }

  return(
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth
      maxWidth={"md"}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Εισαγωγή link σε σχόλιο
        </DialogTitle>

        <DialogContent>
          <Grid item xs container direction="row" spacing={2}>

            <Grid item xs={6}>
              <LyricsSelectionTree setSelected={setSelected}/>
           </Grid>

           <Grid item container xs={6} alignContent='flex-start'>
              <Grid item xs={12}>
                <Button onClick={handleSearch}>Αναζήτηση σχολίων</Button>
                <FormHelperText error>{errorSearch}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(errorTitle)}
                  label='Τίτλος'
                  value={title}
                  onChange={handleTitleChange}
                />
                <FormHelperText error>{errorTitle}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                {
                  list.length===0 && !searching && <Typography variant='body2'>Δε βρέθηκαν αποτελέσματα</Typography>
                }
                {
                  searching && <CircularProgress/>
                }
                <List>
                   {
                     list.map((item) =>
                      <ListItem key={item.comment_id} value={item.comment_id}>
                       {item.scholar +', ' + item.scholar_reference + ':' + truncateText(item.text, 100)}&nbsp;&nbsp;
                       <Link onClick={() => handleSelectComment(item)} href='#'>Επιλογή</Link>
                     </ListItem>)
                   }
                </List>
              </Grid>
           </Grid>
         </Grid>
       </DialogContent>

       <DialogActions>
          <Button onClick={handleClose}>Κλεισιμο</Button>
       </DialogActions>
    </Dialog>
  )
}

export default LinkToComment
