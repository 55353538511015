import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import EditIcon from '@material-ui/icons/Edit';
import ReadIcon from '@material-ui/icons/EventNote'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Button from "components/CustomButtons/Button.js";

import { getOneLab } from 'clients/labTranslationReceptionClient.js'
import { labsTRImagesURL } from 'clients/clientConfig.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  paper: {
    padding: 20
  },
  centered: {
    textAlign: 'center',
  },
  labImageCell: {
    border: '1px #808080 solid',
    minWidth: 380,
  },
  labImage: {
    height: 240,
    width: 360,
  },
  seminarImage: {
    height: 120,
    width: 180,
  }
}))

const LabView = (props) => {
  const classes = useStyles()

  const [lab, setLab] = React.useState({})
  const [forum_links, setForum_links] = React.useState([])

  const parse = require('html-react-parser');

  React.useEffect(()=> {
    if (props.match.params.lab_id){
      getOneLab(props.match.params.lab_id, '?seminars=yes',
        (data) => {
          if (data.translation_labs.length > 0){
            //console.log(data.translation_labs[0])
            setLab(data.translation_labs[0])
            setForum_links(data.forum_links)
          } else {
            window.location.href = '/admin/TranslationReceptionLabs'
          }
        },
        (err) => window.location.href = '/admin/TranslationReceptionLabs'
      )
    }
  }, [props.match.params.lab_id])

  const allowedSeminarView = () => {
    return access_rights.labs_tr &&
      (access_rights.labs_tr.r.includes(lab.id) || access_rights.labs_tr.w)
  }

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} >
          <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color="inherit" href="/admin/TranslationReceptionLabs">
                Εργαστήρια μετάφρασης και πρόσληψης
              </Link>
              <Typography color="textPrimary">{lab.title}</Typography>
            </Breadcrumbs>
          </div>
        </Grid>

       <Grid item xs={12} style={{ paddingTop : '20px'}}>
        <Container maxWidth="lg">
          <Paper className={classes.paper}>
            

            <Grid container spacing={2}>
              <Grid item xs={3} >
                <img className={classes.labImage} alt='' src={labsTRImagesURL+lab.id+'/'+lab.image}/>
              </Grid>
              <Grid item xs={8} >
                <Typography variant='h4'>
                  {lab.title}&nbsp;
                  {
                    access_rights.labs_tr && access_rights.labs_tr.w &&
                    <Button justIcon round color="primary" onClick={() => window.location.href='/admin/TRLabEdit/'+lab.id}><EditIcon/></Button>
                  }
                </Typography>
              </Grid>

              <Grid item xs={12} >
                <Typography variant="body1" component='div'>
                  Συντονιστής: {lab.coordinator}<br/>
                  {parse(lab.info || '')}<br/>
                  {lab.timeframe}
                </Typography>
                {
                  access_rights.labs_tr && access_rights.labs_tr.w &&
                  <Button round color="primary" onClick={() => window.location.href='/admin/TRSeminarEdit/'+lab.id}><AddIcon/>Προσθήκη Σεμιναρίου</Button>
                }
              </Grid>
              {lab.seminars && lab.seminars.map((s) =>
                <Grid key={s.id} item xs={12} sm={4}>
                  <Card>

                    {/*// TODO: link to subscribe if he doesn't have read rights*/}
                    <CardActionArea href={allowedSeminarView() ? "/admin/TRSeminarView/"+s.id : ""}>
                      <CardHeader title={s.title} className="AristosCardHeader"/>
                      <CardMedia className={classes.seminarImage} image={labsTRImagesURL+lab.id+'/'+s.image} title={s.title}/>
                      <CardContent>
                        <Typography variant='body2' component='div'>
                          <b>Εισηγητής: {s.speaker}</b><br/>
                          Συντονιστής: {s.coordinator}<br/>
                          {parse(s.info || '')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>

                    <CardActions>
                      {/*// TODO: link to subscribe if he doesn't have read rights*/}
                      {
                        allowedSeminarView() &&
                        <Tooltip title='Προβολή'>
                          <IconButton aria-label="go to view" href={"/admin/TRSeminarView/"+s.id}><ReadIcon style={{color:"#387BE1", marginTop:"2px"}}/></IconButton>
                        </Tooltip>
                      }
                      {
                        access_rights.labs_tr && access_rights.labs_tr.w &&
                        <Tooltip title='Επεξεργασία'>
                          <IconButton aria-label="go to edit" href={"/admin/TRSeminarEdit/"+lab.id+"/"+s.id}><EditIcon style={{color:"#EBBA1A"}}/></IconButton>
                        </Tooltip>
                      }
                    </CardActions>
                  </Card>
                </Grid>
              )}

              {
                forum_links && forum_links.length > 0 &&
                <Grid item xs={12}>
                  <Typography variant='h4'>Συζητήσεις σε Φόρουμ</Typography>
                  <List>
                  {
                    forum_links.map(fl =>
                      <ListItem key={fl.id}>
                        <Link href={'/admin/Forum/'+fl.id}>{fl.name}</Link>
                      </ListItem>
                    )
                  }
                  </List>
                </Grid>
              }
              </Grid>
            </Paper>
        </Container>
       </Grid>
      </Grid>
    </div>
  )
}

export default LabView
