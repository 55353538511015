import React from 'react';

import NavPills from "components/NavPills/NavPills.js";

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Link from '@material-ui/core/Link';
import ForumsTab from './ForumsTab.js'
import { useLocation } from "react-router-dom";

const LyricsTranslationComments = (props) => {
  const { translationNotes, forums, onEditTranslationNote, onDeleteTranslationNote, edit } = props

  //scroll to note if there is a hash specifying that note
  const { hash } = useLocation();
  //console.log(hash)
  const ref = React.useRef(null);
  const setRef = React.useCallback((node) => {
     if (node) {
       //console.log(node)
       node.scrollIntoView()
     }
     ref.current = node;
  },[])

  const parse = require('html-react-parser');

  const content = translationNotes ? translationNotes.sort(sortNotesByOrder).map((note, i) => {
    return (
      <Typography id={"note"+note.note_id} ref={("#note"+note.order===hash) ? setRef : null} key={note.note_id} style = {{width:'100%', paddingTop:"10px"}}
        variant="body1" component="div">
        Σ{note.order}: {edit && <IconButton size='small' onClick={() => onEditTranslationNote(note)}><EditIcon/></IconButton>}
        {edit && <IconButton size='small' onClick={() => onDeleteTranslationNote(note.note_id)}><DeleteIcon/></IconButton>}
        {parse(note.text)}
        {note.sectionsRef && note.sectionsRef.length > 0 &&
          <Typography variant="body1">
            Χωρία:&nbsp;
            {note.sectionsRef.map((s, idx) =>
              <span key={s.id}>
                {' ' + s.work_name + ', '}
                <Link href={'/admin/CollTranslationREADONLY/'+s.id}>{s.lines}</Link>
                 &nbsp;{s.refLine} |
              </span>
            )}
          </Typography>
        }
      </Typography>
    )
  }) : ''

  const tabs = [
    {
      tabButton: 'ΣΗΜΕΙΩΣΕΙΣ ΣΤΗ ΜΕΤΑΦΡΑΣΗ',
      tabContent: content,
    },
  ]

  if (forums.length > 0){
    tabs.push({
      tabButton: 'Φόρουμ',
      tabContent: <ForumsTab forums={forums}/>,
    })
  }

  return (
    <NavPills
      color="primary"
      tabs={tabs}
    />
  )
}

function sortNotesByOrder(a, b){
  return a.order - b.order
}

export default LyricsTranslationComments
