
export default function parseTranslationsFile(file) {
  const lines = file.split('\n')
  const errors = []

  lines.forEach((line, idx) => {
    const line_num = idx + 1

    if (idx===0 || line.startsWith('##')){
      //line starts with section title
      const titlePos = line.search(/##\w+/i)
      if (titlePos!==0){
        //console.log(line)
        errors.push('Πρόβλημα σάρωσης αρχείου στη γραμμή: ' + line_num + ' - λείπει ο τίτλος του χωρίου')
      }
    }
  })

  return errors
}
