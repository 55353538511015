import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip'

import { replace_simple_accents_with_regexp, disableEvent } from 'utils.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  term: {
    fontWeight: 'bold',
  },
  section: {
    color: '#3486eb',
  },
  highlighted: {
    fontWeight: 'bold',
    backgroundColor: '#f2fc2b',
  },
  link_back: {
    float: 'right'
  },
}))

const SearchResults = (props) => {
  const classes = useStyles()
  const { results } = props

  const [page, setPage] = React.useState(1);
  const [showAllForTerm, setShowAllForTerm] = React.useState('')
  const pageSize = 10;
  const maxOccurencesNumber = 10;

  const pageStart = (page - 1) * pageSize
  const pageEnd = pageStart + pageSize

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  React.useEffect(() => {
    setPage(1)
  }, [results])


  return(
    <div id="mainsearchresults">
      {
        (showAllForTerm === '') ?
        <>
          <Typography variant='body1'>Βρέθηκαν {results.length} λημματικοί τύποι</Typography>
          <br/>
          {
            results && results.slice(pageStart, pageEnd).map((res, index) => (
              <Grid container key={index} className={classes.grid} spacing={2}>
                <Grid item xs={12} className={classes.term}>{res.term} ({res.occurences.length})</Grid>

                  <OccurencesList occurences={res.occurences.slice(0, maxOccurencesNumber)}
                    classes={classes} term={res.term}/>
                  {
                    res.occurences.length > maxOccurencesNumber &&
                      <Grid item xs={12}>
                        <Link color='primary' href='#' onClick={() => setShowAllForTerm(res.term)}>Όλες οι εμφανίσεις</Link>
                      </Grid>
                  }
              </Grid>
            ))
          }
          <br/>
          <Pagination count={Math.ceil(results.length/pageSize)} color="primary" page={page} style={{marginTop:"0px"}} onChange={handlePageChange}/>
        </>
        :
        <>
          <Typography>Όλες οι εμφανίσεις για: <b><i>{showAllForTerm}</i></b></Typography>
        {
          results && results.filter(res => res.term === showAllForTerm).map((res, index) =>  (
            <Grid container key={index} className={classes.grid} spacing={2}>
              <Grid item xs={12} className={classes.term}>{res.term} ({res.occurences.length})</Grid>

                <OccurencesList occurences={res.occurences}
                  classes={classes} term={res.term}/>

                <Grid item xs={8}>
                  <Link color='primary' href='#' onClick={() => setShowAllForTerm('')}
                    className={classes.link_back}>&lt;&lt;Πίσω σε συνοπτική εμφάνιση</Link>
                </Grid>
                <Grid item xs={4}>
                </Grid>
            </Grid>
          ))
        }
        </>
      }
    </div>
  )
}

const OccurencesList = (props) => {
  const { occurences, term, classes } = props
  const parse = require('html-react-parser');

  const highlightTermInText = (text, term) => {
    const re = replace_simple_accents_with_regexp(term)
    //console.log(re)
    const matches = text.match(re)
    if (matches){
      matches.forEach(m => {
        text = text.replace(m, '<span class="'+classes.highlighted+'">' + m + "</span>")
      })
    }
    return text
  }

  return(
    <>
      {
        occurences && occurences.map((occ, idx) =>
        <Grid className='searchresultoccurence' container item xs={12} key={idx}>
          <Grid item xs={3} className={classes.section}>
            <Tooltip arrow title={occ.work_name}>
              <Link color="inherit" href={"/admin/CollTranslationREADONLY/"+occ.section_id}>{occ.section_code}</Link>
            </Tooltip>
          </Grid>
          <Grid className="archaic_text" item xs={9} onCopy={disableEvent} onCut={disableEvent}>
            {parse(highlightTermInText(occ.text, term))}
          </Grid>
        </Grid>
        )
      }
    </>
  )
}

export default SearchResults
