import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";

import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Button from "components/CustomButtons/Button.js";
import { Editor } from '@tinymce/tinymce-react';

import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'
import { ConfirmCancelMessage } from 'views/CustomAlerts/ConfirmationDialog.js'

import { addLab, editLab, getOneLab } from 'clients/labTranslationReceptionClient.js'
import { labsTRImagesURL } from 'clients/clientConfig.js'
import { checkNoErrors } from 'utils.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  fileinput: {
    display: 'none',
  },
  labImage: {
    height: 60,
    width: 90,
  }
}))

const LabEdit = (props) => {
  const classes = useStyles()

  const [lab, setLab] = React.useState({})

  const [title, setTitle] = React.useState('')
  const [image, setImage] = React.useState(null)
  const [coordinator, setCoordinator] = React.useState('')
  const [timeframe, setTimeframe] = React.useState('')
  const [info, setInfo] = React.useState('')
  const infoHtmlEditorRef = React.useRef(null);

  const [saving, setSaving] = React.useState(false)
  const [errors, setErrors] = React.useState({})

  React.useEffect(()=> {
    if (props.match.params.lab_id){
      getOneLab(props.match.params.lab_id, '',
        (data) => {
          setLab(data.translation_labs[0])

          setTitle(data.translation_labs[0].title)
          setCoordinator(data.translation_labs[0].coordinator)
          setTimeframe(data.translation_labs[0].timeframe || '')
          setInfo(data.translation_labs[0].info)

          if (data.translation_labs[0].image!==null && data.translation_labs[0].image!==''){
            setImage({name: data.translation_labs[0].image})
          }
        },
        (err) => console.log(err)
      )
    }
  }, [props.match.params.lab_id])

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  //field changes
  const handleTitleChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.title = validate('title', e.target.value)
    setErrors(newErrors)

    setTitle(e.target.value)
  }

  const handleCoordinatorChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.coordinator = validate('coordinator', e.target.value)
    setErrors(newErrors)

    setCoordinator(e.target.value)
  }

  const handleTimeframeChange = (e) => {
    setTimeframe(e.target.value)
  }

  /*const handleInfoChange = (e) => {
    setInfo(e.target.value)
  }*/

  //image upload functions
  const uploadImage = (e) => {
    const allowedExtensions = /(\.(jpg|jpeg|gif|png|bmp|tiff|pjp|jfif|svg|svgz|webp|ico|xbm|dib|tif|pjpeg|avif))$/i;
    let newErrors = Object.assign({}, errors)

    if (!allowedExtensions.exec(e.target.files[0].name)) {
      newErrors.image = "Πρέπει να ανεβάσετε αρχείο εικόνας"
      if (!lab.image){
        setImage(null)
      }
    } else {
      setImage(e.target.files[0])
      newErrors.image = ''
    }

    setErrors(newErrors)
  }

  //validation
  const validate = (name, value) => {
    switch (name) {
      case 'title':
      case 'coordinator':
        return (value==='') ? 'Υποχρεωτικό πεδίο' : ''

      default:
        return ''
    }
  }

  const handleSubmit = () => {
    //setSaving(true)
    const newInfo = (infoHtmlEditorRef.current) ? infoHtmlEditorRef.current.getContent() : ''

    let newErrors = Object.assign({}, errors)
    newErrors.title = validate('title', title)
    newErrors.coordinator = validate('coordinator', coordinator)
    //the following errors should be disregarded
    newErrors.image = ''
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      let params = {title: title, coordinator: coordinator, info: newInfo, image: (image!=null) ? image.name : '',
        timeframe: timeframe}
      const newImage = (image instanceof File) ? image : null;

      if (lab.id){
        params.id = lab.id
        //console.log(params)

        editLab(newImage, params,
          () => window.location.href='/admin/TRLabView/' + lab.id,
          (err) => {
            setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
            setSaving(false)
          }
        )
      } else {
        //console.log(params)
        addLab(newImage, params,
          (data) => window.location.href='/admin/TRLabView/' + data.inserted_id,
          (err) => {
            setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
            setSaving(false)
          }
        )
      }
    } else {
      setSaving(false)
    }
  }

  const handleCancel = () => {
    setAlert(<ConfirmCancelMessage onConfirm={redirectToMain} onCancel={hideAlert} />)
  }

  const redirectToMain = () => {
    window.location.href="/admin/TranslationReceptionLabs"
  }

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} >
          <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color="inherit" href="/admin/TranslationReceptionLabs">
                Εργαστήρια μετάφρασης και πρόσληψης
              </Link>
              {lab.id &&
                <Link color="inherit" href={'/admin/TRLabView/' + lab.id}>{lab.title}</Link>
              }
              <Typography color="textPrimary">
                {lab.id ? 'Επεξεργασία εργαστηρίου' : 'Προσθήκη εργαστηρίου'}
              </Typography>
            </Breadcrumbs>
          </div>
        </Grid>

       <Grid item xs={12} style={{ paddingTop : '20px'}}>
         <Typography variant='h4'>{lab.id ? 'Επεξεργασία εργαστηρίου' : 'Προσθήκη νέου εργαστηρίου'}</Typography>

         <TextField
          error={Boolean(errors.title)}
          label='Τίτλος'
          style = {{width:500, paddingTop:10, marginTop:0}}
          value={title}
          onChange={handleTitleChange}
          />
          <FormHelperText error>{errors.title}</FormHelperText>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormLabel>Εικόνα</FormLabel>
              <br/>
              <input
                accept="image/*"
                className={classes.fileinput}
                id="contained-button-image"
                type="file"
                onChange={uploadImage}
              />
              <label htmlFor="contained-button-image">
                <Button variant="contained" color="primary" component="span">
                  Ανεβασμα εικονας
                </Button>
              </label>
              <FormHelperText error>{errors.image}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={8}>
              {
                image && !(image instanceof File) &&
                <img src={labsTRImagesURL+lab.id+'/'+image.name} className={classes.labImage} alt='Εικόνα εργαστηρίου'/>
              }
              {image && image instanceof File && image.name}
            </Grid>
          </Grid>

          <TextField
           error={Boolean(errors.coordinator)}
           label='Συντονιστής'
           style = {{width:500, paddingTop:10, marginTop:0}}
           value={coordinator}
           onChange={handleCoordinatorChange}
           />
          <FormHelperText error>{errors.coordinator}</FormHelperText>

          <TextField
           error={Boolean(errors.timeframe)}
           label='Χρόνος διεξαγωγής'
           style = {{width:500, paddingTop:10, marginTop:0}}
           value={timeframe}
           onChange={handleTimeframeChange}
           />
          <FormHelperText error>{errors.timeframe}</FormHelperText>

          <FormLabel>Πληροφορίες</FormLabel>
          <Editor id="info" tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
           onInit={(evt, editor) => infoHtmlEditorRef.current = editor}
           initialValue={info}
           init={{
             height: 300,
             entity_encoding : "raw",
             plugins: [
               'advlist autolink lists link image charmap print preview anchor',
               'searchreplace visualblocks code fullscreen',
               'media table paste code help wordcount imagetools'
             ],
             menubar: false,
             toolbar: 'undo redo | formatselect | ' +
             'bold italic underline | alignleft aligncenter ' +
             'alignright alignjustify | outdent indent | ' +
             'removeformat | help',
             //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
           }}
         />
        <FormHelperText error>{errors.info}</FormHelperText>

        <Button color="rose" onClick={handleSubmit} disabled={saving}>Καταχωριση</Button>
        <Button onClick={handleCancel}>Ακυρωση</Button>
       </Grid>
      </Grid>

      {alert}

    </div>
  )
}

export default LabEdit
