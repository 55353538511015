import React from 'react'

//import FormHelperText from '@material-ui/core/FormHelperText';
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'

import { getAllSections } from 'clients/sectionsClient.js'

const LinkToSection = (props) => {
  const { onSave, onClose, open } = props

  const [sections, setSections] = React.useState([])
  const [section, setSection] = React.useState({})
  const [title, setTitle] = React.useState('')
  const [error, setError] = React.useState('')

  React.useEffect(() => {
    getAllSections(
      (data) => setSections(data.documents),
      (err) => console.log(err)
    )
  }, [])

  const handleSectionChange = (e, value) => {
    setError(!value || !value.id ? 'Υποχρεωτικό πεδίο' : '')
    setSection(value)
  }

   const handleTitleChange = (e) => {
     setTitle(e.target.value)
   }

  const handleSelect = () => {
    if (!section || !section.id){
      setError('Υποχρεωτικό πεδίο')
    } else {
      setError('')

      const link = "/admin/CollTranslationREADONLY/"+ section.id
      const sectionTitle = title ? title : section.work_name + ", " + section.lines

      onSave({title: sectionTitle, link: link})
      resetFields()
    }
  }

  const handleClose = () => {
    resetFields()
    onClose()
  }

  const resetFields = () => {
    setSection({})
    setTitle('')
    setError('')
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth
      maxWidth={"xs"}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Εισαγωγή link σε χωρίο
        </DialogTitle>

        <DialogContent>
          <FormLabel>Επιλέξτε Χωρίο</FormLabel>
          <Autocomplete
            id="sections"
            options={sections.sort((a,b) => -b.work_name.localeCompare(a.work_name))}
            groupBy={(option) => option.work_name}
            getOptionLabel={(option) => option.lines || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            style={{ width: 300 }}
            value={section}
            onChange={handleSectionChange}
            renderInput={(params) =>
              <TextField {...params}
                label="Αριθμός χωρίου"
                variant="standard"
                error={Boolean(error)}
                helperText={error}
              />}
          />

          <TextField
            label='Τίτλος'
            value={title}
            onChange={handleTitleChange}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSelect}>Προσθηκη</Button>
          <Button onClick={handleClose}>Κλεισιμο</Button>
        </DialogActions>
      </Dialog>
    )
}

export default LinkToSection
