import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'

import Button from "components/CustomButtons/Button.js";
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import Checkbox from '@material-ui/core/Checkbox';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';

import LabsSelection from '../labsselection';

import { checkNoErrors } from 'utils.js'
import { getLabSeminars } from 'clients/labTranslationReceptionClient.js'
import { getSectionsForWork } from 'clients/sectionsClient.js'
import { getLemmataStartingWith } from 'clients/lexiconClient.js'
import { sortSectionLines } from 'sorting.js'

const useStyles = makeStyles((theme) => ({
  formElement: {
    width:500,
    paddingTop:10,
    marginTop:0
  },
  emptyElement: {
    color: '#808080',
    fontStyle: 'italic'
  }
}))

const NewForum = (props) => {
  const classes = useStyles()
  const { forum, father, open, handleClose, onSubmit, isLoading, allLabs,
    fatherIsPrivate, fatherLab, allWorks } = props
  //console.log(forum)

  const [name, setName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [isPrivate, setIsPrivate] = React.useState(false)
  const [lab, setLab] = React.useState('')
  const [seminar, setSeminar] = React.useState('')
  const [work, setWork] = React.useState('')
  const [section, setSection] = React.useState('')
  const [originalOrTranslation, setOriginalOrTranslation] = React.useState([])
  const [lexicon, setLexicon] = React.useState([])
  const [lexiconInput, setLexiconInput] = React.useState('')
  const [errors, setErrors] = React.useState({})

  const [workSections, setWorkSections] = React.useState([])
  const [lemmataList, setLemmataList] = React.useState([])
  const [labSeminars, setLabSeminars] = React.useState([])

  const handleNameChange = (e) => {
    setErrors({...errors, name: validate('name', e.target.value)})
    setName(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setErrors({...errors, description: validate('description', e.target.value)})
    setDescription(e.target.value)
  }

  const handleIsPrivateToggle = (e) => {
    setIsPrivate(e.target.checked)
  }

  const handleLabChange = (e) => {
    setErrors({...errors, lab: validate('lab', e.target.value)})
    setLab(e.target.value)
    setSeminar('')
  }

  const handleSeminarChange = (e) => {
    //setErrors({...errors, seminar: validate('seminar', e.target.value)})
    setSeminar(e.target.value)
  }

  const handleWorkChange = (e) => {
    setWork(e.target.value)
  }

  const handleSectionChange = (e) => {
    setSection(e.target.value)
  }

  const handleLexiconInputChange = (e, newInputValue) => {
    setLexiconInput(newInputValue)
  }

  const handleLexiconValueChange = (e, newValue) => {
    //console.log(newValue)
    setLexicon(newValue)
  }

  const handleOriginalOrTranslationChange = (e) => {
    let newValue;
    if (e.target.checked){
      newValue = [...originalOrTranslation, e.target.name]
    } else {
      newValue = originalOrTranslation.filter(val => val!==e.target.name)
    }
    //console.log(newValue)
    setErrors({...errors, originalOrTranslation: validate('originalOrTranslation', newValue)})
    setOriginalOrTranslation(newValue)
  }

  const validate = (field, value) => {
    switch (field) {
      case 'name':
        return (value.trim()==='') ? 'Υποχρεωτικό πεδίο' : ''

      case 'description':
        return value.length > 200 ? 'Επιτρέπονται μέχρι 200 χαρακτήρες' : ''

      case 'lab':
        return isPrivate && !value ? 'Υποχρεωτικό πεδίο για κλειστές κατηγορίες' : ''

      case 'originalOrTranslation':
        return work && value.length === 0 ? 'Υποχρεωτική επιλογή τουλάχιστον ενός όταν έχει επιλεγεί έργο' : ''

      default:
        return ''
    }
  }

  const handleSubmit = () => {
    let newErrors = {...errors}
    newErrors.name = validate('name', name)
    newErrors.description = validate('description', description)
    newErrors.lab = validate('lab', lab)
    newErrors.originalOrTranslation = validate('originalOrTranslation', originalOrTranslation)
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      let newforum = {name: name, description: description, isPrivate: isPrivate,
        father: father, lab: isPrivate ? lab : '',
        seminar: (isPrivate && lab && lab.startsWith('tr_')) ? seminar : '',
        work: work || null, section: section, lexicon: lexicon.map(l => l.lemma_id),
        originalOrTranslation: work ? originalOrTranslation : []
      }
      if (forum.id){
        newforum.id = forum.id
      }
      //console.log(newforum)
      onSubmit(newforum)
    }
  }

  //get forum previous values if it is an edit
  React.useEffect(() => {
    setName(forum.name || '')
    setDescription(forum.description || '')
    setIsPrivate(forum.isPrivate || fatherIsPrivate || false)
    setLab(forum.lab || fatherLab || '')
    setSeminar(forum.seminar || '')
    setWork(forum.work || '')
    setSection(forum.section || '')
    setLexicon(forum.lexicon || [])
    setOriginalOrTranslation(forum.originalOrTranslation || [])
  }, [forum, fatherIsPrivate, fatherLab])

  //get seminars when lab changes
  React.useEffect(() => {
    if (lab && lab.startsWith('tr_')){
      const lab_id = lab.substring(3)
      //console.log('get seminars for '+lab_id)
      getLabSeminars(lab_id, '',
        (data) => setLabSeminars(data.seminars),
        (err) => console.log(err)
      )
      //setLabSeminars([{id: 1, title: 'sem1'+lab}, {id: 2, title: 'sem2'+lab}])
    } else {
      //console.log('no lab')
      setLabSeminars([])
    }
  }, [lab])

  //get sections when work changes
  React.useEffect(() => {
    if (work){
      getSectionsForWork(work,
        (data) => setWorkSections(data.sections.sort(sortSections)),
        (err) => console.log(err)
      )
    } else {
      setWorkSections([])
      setSection('')
    }
  }, [work])

  //get lexicon lemmata as user types
  React.useEffect(() => {
    const last_keyword = lexiconInput ? lexiconInput.trim() : ''

    if (last_keyword.length > 0){
      //console.log('search for '+last_keyword)
      getLemmataStartingWith(last_keyword,
        (data) => setLemmataList(data.lemmata),
        (err) => console.log(err)
      )
    } else {
      setLemmataList([])
    }
  }, [lexiconInput])

  function sortSections(a, b){
    return sortSectionLines(a.lines, b.lines)
  }

  return(
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}
      maxWidth={"sm"}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {forum.id ? 'Επεξεργασία Κατηγορίας Συζητήσεων' : 'Προσθήκη Κατηγορίας Συζητήσεων'}
        </DialogTitle>

        <DialogContent dividers>
          <TextField id='name'
           error={Boolean(errors.name)}
           label='Τίτλος'
           className={classes.formElement}
           value={name}
           onChange={handleNameChange}
           />
          <FormHelperText error>{errors.name}</FormHelperText>

         <TextField id='description'
          error={Boolean(errors.description)}
          label='Περιγραφή'
          className={classes.formElement}
          value={description}
          onChange={handleDescriptionChange}
          multiline rows={4}
          variant='outlined'
          />
          <FormHelperText error>{errors.description}</FormHelperText>

          <FormControlLabel control={
            <Checkbox size='small' checked={isPrivate} onChange={handleIsPrivateToggle} disabled={fatherIsPrivate} />
          } label='Κλειστή κατηγορία (μόνο για επιλεγμένους χρήστες)'/>

          <br/>
          <LabsSelection allLabs={allLabs} selected={lab} error={errors.lab}
            handleChange={handleLabChange} disabled={!isPrivate || fatherIsPrivate}/>

          <FormControl className={classes.formElement}>
            <InputLabel htmlFor="seminar-select">Σεμινάριο που αφορά (μόνο για κλειστές κατηγορίες)</InputLabel>
            <Select
              id="seminar-select"
              value={seminar}
              onChange={handleSeminarChange}
              disabled={!isPrivate || !lab || !lab.startsWith('tr_')}
             >
             <MenuItem value='' className={classes.emptyElement}>Κανένα</MenuItem>
             {labSeminars && labSeminars.map(s =>
               <MenuItem key={s.id}  value={s.id}>{s.title}</MenuItem>
             )}
            </Select>
            <FormHelperText>Επιλέξτε πρώτα εργαστήριο</FormHelperText>
          </FormControl>

          <br/>
          <Typography variant='h6'>Συσχετίσεις</Typography>

          <FormControl className={classes.formElement}>
            <InputLabel htmlFor="work-select">Έργο</InputLabel>
            <Select id="work-select"
              value={work}
              onChange={handleWorkChange}>
              <MenuItem value='' className={classes.emptyElement}>Κανένα</MenuItem>
              {
                allWorks.map(w =>
                  <MenuItem key={w.id} value={w.id}>{w.name}</MenuItem>
                )
              }
            </Select>
          </FormControl>

          <FormControl className={classes.formElement}>
            <InputLabel htmlFor="section-select">Χωρίο</InputLabel>
            <Select id="section-select"
              value={section}
              onChange={handleSectionChange}>
              <MenuItem value='' className={classes.emptyElement}>Κανένα</MenuItem>
              {
                workSections.map(sec =>
                  <MenuItem key={sec.id} value={sec.id}>{sec.lines}</MenuItem>
                )
              }
            </Select>
            <FormHelperText>Επιλέξτε πρώτα έργο</FormHelperText>
          </FormControl>

          <FormControl className={classes.formElement} variant="standard"
            error={Boolean(work && errors.originalOrTranslation)}>
            <FormLabel>Αφορά</FormLabel>
            <FormGroup id="origOrTrans-group" row>
              <FormControlLabel
                control={
                  <Checkbox size='small' checked={originalOrTranslation.includes('original')}
                    onChange={handleOriginalOrTranslationChange} name="original" disabled={!work} />
                }
                label="Αρχαίο κείμενο"
              />
              <FormControlLabel
                control={
                  <Checkbox size='small' checked={originalOrTranslation.includes('translation')}
                    onChange={handleOriginalOrTranslationChange} name="translation" disabled={!work} />
                }
                label="Μετάφραση"
              />
            </FormGroup>
            <FormHelperText error>{work && errors.originalOrTranslation}</FormHelperText>
          </FormControl>

          <Autocomplete
            id="lexicon-search"
            className={classes.formElement}
            options={lemmataList}
            autoComplete
            multiple
            value={lexicon}
            inputValue={lexiconInput}
            onChange={handleLexiconValueChange}
            onInputChange={handleLexiconInputChange}
            getOptionLabel={(option) => option.title || ''}
            getOptionSelected={(option, value) => value.lemma_id===option.lemma_id}
            filterOptions={(x) => x}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label='Λήμματα λεξικού'
              />
            )}
          />
          <FormHelperText>Πληκτρολογήστε τα πρώτα γράμματα και πατήστε Enter για να επιλέξετε λήμμα</FormHelperText>

        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleSubmit} color="primary" disabled={isLoading}>
            Καταχωριση
          </Button>
        </DialogActions>
    </Dialog>
  )
}

export default NewForum
