import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';

import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Editor } from '@tinymce/tinymce-react';
import TagsInput from "react-tagsinput";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import elLocale from "date-fns/locale/el";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import LabPersonForm from './LabPersonForm.js'

import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'
import { ConfirmCancelMessage } from 'views/CustomAlerts/ConfirmationDialog.js'

import { checkNoErrors, addHttpToLink, truncateText } from 'utils.js'
import { validUrlPattern, edu_lab_types } from 'variables/general.js'
import { getLabMainPage, getLabTypeName } from './utils.js'
import { getOneLab, addLab, editLab } from 'clients/labEducationEtcClient.js'
import { labsEduImagesURL } from 'clients/clientConfig.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  fileinput: {
    display: 'none',
  },
  labImage: {
    height: 60,
    width: 90,
  },
  personImage: {
    height: 60,
    width: 60,
  },
  left: {
    float: 'left'
  }
}))


const EduLabEdit = (props) => {
  const classes = useStyles()

  const [lab, setLab] = React.useState({})
  const [lab_type, setLab_type] = React.useState('')

  const [title, setTitle] = React.useState('')
  const [image, setImage] = React.useState(null)
  const [responsible, setResponsible] = React.useState({})
  const [info, setInfo] = React.useState('')
  const [language, setLanguage] = React.useState('')
  const [tags, setTags] = React.useState([])
  const [description, setDescription] = React.useState('')
  const [labDate, setLabDate] = React.useState(null)
  const [duration, setDuration] = React.useState('')
  const [location, setLocation] = React.useState('')
  const [requirements, setRequirements] = React.useState('')
  const [cost, setCost] = React.useState('')
  const [learning, setLearning] = React.useState('')
  const [speakers, setSpeakers] = React.useState([])
  const [introVideo, setIntroVideo] = React.useState('')
  const [visible, setVisible] = React.useState(false)
  const [freeAccess, setFreeAccess] = React.useState(false)
  const [maxAttendants, setMaxAttendants] = React.useState('')
  const [welcomeVideo, setWelcomeVideo] = React.useState('')
  const [structureVideo, setStructureVideo] = React.useState('')
  const [attendanceVideo, setAttendanceVideo] = React.useState('')
  const [eshop_link, setEshop_link] = React.useState('')

  const infoHtmlEditorRef = React.useRef(null);
  const descrHtmlEditorRef = React.useRef(null);
  const reqHtmlEditorRef = React.useRef(null)
  const learnHtmlEditorRef = React.useRef(null)

  const [saving, setSaving] = React.useState(false)
  const [errors, setErrors] = React.useState({})
  const [dateErr, setDateErr] = React.useState('')

  const [openPersonForm, setOpenPersonForm] = React.useState(false)
  const [personToEdit, setPersonToEdit] = React.useState({})
  const [personToEditIsResp, setPersonToEditIsResp] = React.useState(false)
  const [personToEditIdx, setPersonToEditIdx] = React.useState(-1)

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  //field changes
  const handleTitleChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.title = validate('title', e.target.value)
    setErrors(newErrors)

    setTitle(e.target.value)
  }

  const handleLanguageChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.language = validate('language', e.target.value)
    setErrors(newErrors)

    setLanguage(e.target.value)
  }

  const handleChangeTags = l => {
    setTags(l)
  }

  const handleDateChange = (date) => {
    /*let newErrors = Object.assign({}, errors)
    newErrors.labDate = validate('labDate', e.target.value)
    setErrors(newErrors)*/

    setLabDate(date)
  }

  const handleDurationChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.duration = validate('duration', e.target.value)
    setErrors(newErrors)

    setDuration(e.target.value)
  }

  const handleLocationChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.location = validate('location', e.target.value)
    setErrors(newErrors)

    setLocation(e.target.value)
  }

  const handleCostChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.cost = validateCost(e.target.value, freeAccess)
    setErrors(newErrors)

    setCost(e.target.value)
  }

  const handleVisibleChange = (e) => {
    setVisible(e.target.checked)
  }

  const handleFreeAccessChange = (e) => {
    setFreeAccess(e.target.checked)
    //if access is free, cost must be 0 and maxAttendants empty
    let costValue = cost
    let maxAttendValue = maxAttendants
    if (e.target.checked){
      setCost(0)
      setMaxAttendants('')
      costValue = 0
      maxAttendValue = ''
    }
    //update cost + maxAttendants errors
    let newErrors = Object.assign({}, errors)
    newErrors.cost = validateCost(costValue, e.target.checked)
    newErrors.maxAttendants = validateMaxAttendants(maxAttendValue, e.target.checked)
    setErrors(newErrors)
  }

  const handleMaxAttendantsChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.maxAttendants = validateMaxAttendants(e.target.value, freeAccess)
    setErrors(newErrors)

    setMaxAttendants(e.target.value)
  }

  const handleIntroVideoChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.introVideo = validate('introVideo', e.target.value)
    setErrors(newErrors)

    setIntroVideo(e.target.value)
  }

  const handleWelcomeVideoChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.welcomeVideo = validate('welcomeVideo', e.target.value)
    setErrors(newErrors)

    setWelcomeVideo(e.target.value)
  }

  const handleStructureVideoChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.structureVideo = validate('structureVideo', e.target.value)
    setErrors(newErrors)

    setStructureVideo(e.target.value)
  }

  const handleAttendanceVideoChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.attendanceVideo = validate('attendanceVideo', e.target.value)
    setErrors(newErrors)

    setAttendanceVideo(e.target.value)
  }

  const handleEshop_linkChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.eshop_link = validate('eshop_link', e.target.value)
    setErrors(newErrors)

    setEshop_link(e.target.value)
  }

  //image upload functions
  const uploadImage = (e) => {
    const allowedExtensions = /(\.(jpg|jpeg|gif|png|bmp|tiff|pjp|jfif|svg|svgz|webp|ico|xbm|dib|tif|pjpeg|avif))$/i;
    let newErrors = Object.assign({}, errors)

    if (!allowedExtensions.exec(e.target.files[0].name)) {
      newErrors.image = "Πρέπει να ανεβάσετε αρχείο εικόνας"
      if (!lab.image){
        setImage(null)
      }
    } else {
      setImage(e.target.files[0])
      newErrors.image = ''
    }

    setErrors(newErrors)
  }

  //validation
  const validate = (name, value) => {
    switch (name) {
      case 'title':
        if (value.trim()===''){
          document.getElementById(name).focus()
          return  'Υποχρεωτικό πεδίο'
        } else {
          return ''
        }

      case 'introVideo':
      case 'welcomeVideo':
      case 'structureVideo':
      case 'attendanceVideo':
      case 'eshop_link':
        if (value !== '' && !validUrlPattern.test(value)){
          document.getElementById(name).focus()
          return  'Μη έγκυρο link'
        } else {
          return ''
        }

      default:
        return ''
    }
  }

  const validateCost = (value, freeAccessValue) => {
    const pattern = /[^0-9,]/;
    const patternFloat = /^[0-9]+(,{1}[0-9]{1,2})?$/;
    if (value === ''){
      return ''
    } else if (pattern.test(value)){
      document.getElementById('cost').focus()
      return 'Επιτρέπονται μόνο αριθμοί και υποδιαστολή (,)'
    } else if (!patternFloat.test(value))  {
      document.getElementById('cost').focus()
      return 'Μη έγκυρος αριθμός - πρέπει να έχει τουλάχιστον ένα ψηφίο πριν την υποδιαστολή και μέχρι δύο μετά'
    } else {
      return (freeAccessValue && parseInt(value) !== 0) ? 'Όταν η πρόσβαση είναι ελεύθερη, το κόστος πρέπει να είναι 0' : ''
    }
  }

  const validateMaxAttendants = (value, freeAccessValue) => {
    const pattern = /[^0-9]/;
    if (pattern.test(value)){
      document.getElementById('maxAttendants').focus()
      return 'Επιτρέπονται μόνο ακέραιοι αριθμοί'
    } else {
      return (freeAccessValue && value!=='' && parseInt(value)!==0) ?
        'Όταν η πρόσβαση είναι ελεύθερη, δεν πρέπει να υπάρχει μέγιστος αριθμός συμμετεχόντων' : ''
    }
  }

  const validateLabDate = () => {
    if (dateErr!==''){
      document.getElementById('labDate').focus()
    }
    return dateErr
  }

  const setLabDateError = (err, value) => {
    //If we set the errors variable, there is an infinite recursion on load - don't know why. So use separate error variable dateErr
    if (value===null || err===''){
      setDateErr('')
    } else {
      setDateErr(err)
    }
    /*console.log(err)
    console.log(value)*/
  }

  //person add/edit
  const prepareToEditPerson = (isResp, idx) => {
    setPersonToEditIsResp(isResp)
    setPersonToEditIdx(idx)

    if (isResp){
      setPersonToEdit(Object.assign({}, responsible))
    } else if (idx >= 0){
      setPersonToEdit(Object.assign({}, speakers[idx]))
    } else {
      setPersonToEdit({})
    }

    setOpenPersonForm(true)
  }

  const addOrEditPerson = (person) => {
    let newSpeakers = [...speakers]

    if (personToEditIsResp){
      setResponsible(person)
    } else if (personToEditIdx >= 0){
      newSpeakers[personToEditIdx] = person
      setSpeakers(newSpeakers)
    } else {
      newSpeakers.push(person)
      setSpeakers(newSpeakers)
    }

    setOpenPersonForm(false)
  }

  const deleteSpeaker = (idx) => {
    let newSpeakers = [...speakers]
    newSpeakers.splice(idx, 1)
    setSpeakers(newSpeakers)
  }

  //submit and cancel
  const handleSubmit = () => {
    let newErrors = Object.assign({}, errors)
    newErrors.title = validate('title', title)
    newErrors.cost = validateCost(cost, freeAccess)
    newErrors.maxAttendants = validateMaxAttendants(maxAttendants, freeAccess)
    newErrors.introVideo = validate('introVideo', introVideo)
    newErrors.welcomeVideo = validate('welcomeVideo', welcomeVideo)
    newErrors.structureVideo = validate('structureVideo', structureVideo)
    newErrors.attendanceVideo = validate('attendanceVideo', attendanceVideo)
    newErrors.eshop_link = validate('eshop_link', eshop_link)
    newErrors.labDate = validateLabDate()
    //the following errors should be disregarded
    newErrors.image = ''
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      setSaving(true)

      const newInfo = (infoHtmlEditorRef.current) ? infoHtmlEditorRef.current.getContent() : ''
      const newDescription = (descrHtmlEditorRef.current) ? descrHtmlEditorRef.current.getContent() : ''
      const newRequirements = reqHtmlEditorRef.current ? reqHtmlEditorRef.current.getContent() : ''
      const newLearning = learnHtmlEditorRef.current ? learnHtmlEditorRef.current.getContent() : ''

      let params = {title: title, responsible: replaceImageFileWithImageName(responsible),
        info: newInfo, language: language, tags: tags, lab_type: lab_type,
        description: newDescription, labDate: labDate, duration: duration, location: location,
        requirements: newRequirements, cost: cost, learning: newLearning,
        speakers: speakers.map(s => replaceImageFileWithImageName(s)),
        introVideo: addHttpToLink(introVideo), image: (image!=null) ? image.name : '',
        welcomeVideo: addHttpToLink(welcomeVideo), structureVideo: addHttpToLink(structureVideo),
        attendanceVideo: addHttpToLink(attendanceVideo), visible: visible, freeAccess: freeAccess,
        maxAttendants: maxAttendants || 0, eshop_link: addHttpToLink(eshop_link)
      }
      let newFiles = []
      if (image instanceof File){
        newFiles.push(image)
      }
      if (responsible && responsible.image && responsible.image instanceof File){
        newFiles.push(responsible.image)
      }
      speakers.forEach(s => {
        if (s.image && s.image instanceof File){
          newFiles.push(s.image)
        }
      })

      if (lab.id){
        params.id = lab.id
      }

      console.log(params)
      console.log(newFiles)
      if (lab.id){
        editLab(newFiles, params,
          (data) => window.location.href = "/admin/EduLabFullView/" + lab.id,
          (err) => {
            setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
            setSaving(false)
          }
        )
      } else {
        addLab(newFiles, params,
          (data) => window.location.href = "/admin/EduLabFullView/" + data.inserted_id,
          (err) => {
            setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
            setSaving(false)
          }
        )
      }
    }
  }

  const replaceImageFileWithImageName = (person) => {
    let newPerson = Object.assign({}, person)
    if (newPerson.image && newPerson.image instanceof File){
      newPerson.image = newPerson.image.name
    }
    return newPerson
  }

  const handleCancel = () => {
    setAlert(<ConfirmCancelMessage onConfirm={redirectToMain} onCancel={hideAlert} />)
  }

  const redirectToMain = () => {
    window.location.href =  getLabMainPage(lab_type)
  }

  const redirectToHomePage = () => {
    window.location.href="/admin/SelectTransSem"
  }

  React.useEffect(() => {
    if (props.match.params.lab_type){
      if (!edu_lab_types.includes(props.match.params.lab_type)){
        //wrong lab type
        redirectToHomePage()

      } else {
        //get lab type from params
        setLab_type(props.match.params.lab_type)

        if (props.match.params.lab_id){
          //get lab info from database
          getOneLab(props.match.params.lab_id, '?get_full=yes',
            (data) => {
              if (data.education_labs.length > 0){
                setLab(data.education_labs[0])

                setLab_type(data.education_labs[0].lab_type)
                setTitle(data.education_labs[0].title)
                if (data.education_labs[0].image!==null && data.education_labs[0].image!==''){
                  setImage({name: data.education_labs[0].image})
                }
                setResponsible(data.education_labs[0].responsible)
                setInfo(data.education_labs[0].info)
                setLanguage(data.education_labs[0].language)
                setTags(data.education_labs[0].tags)
                setDescription(data.education_labs[0].description)
                setLabDate(data.education_labs[0].labDate ? new Date(data.education_labs[0].labDate) : null)
                setDuration(data.education_labs[0].duration)
                setLocation(data.education_labs[0].location)
                setRequirements(data.education_labs[0].requirements)
                setCost(data.education_labs[0].cost)
                setLearning(data.education_labs[0].learning)
                setSpeakers(data.education_labs[0].speakers)
                setIntroVideo(data.education_labs[0].introVideo)
                setVisible(data.education_labs[0].visible)
                setFreeAccess(data.education_labs[0].freeAccess)
                setMaxAttendants(data.education_labs[0].maxAttendants)
                setWelcomeVideo(data.education_labs[0].welcomeVideo)
                setStructureVideo(data.education_labs[0].structureVideo)
                setAttendanceVideo(data.education_labs[0].attendanceVideo)
                setEshop_link(data.education_labs[0].eshop_link || '')
              }
            },
            (err) => console.log(err)
          )
        }
      }
    } else {
      redirectToHomePage()
    }
  }, [props.match.params.lab_type, props.match.params.lab_id])

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} >
          <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color='inherit' href={getLabMainPage(lab_type)}>
                {getLabTypeName(lab_type)}
              </Link>
              {lab.id &&
                <Link color='inherit' href={"/admin/EduLabFullView/"+lab.id}>{lab.title}</Link>
              }
              <Typography color="textPrimary">
                {lab.id ? 'Επεξεργασία εργαστηρίου' : 'Προσθήκη εργαστηρίου'}
              </Typography>
            </Breadcrumbs>
          </div>
        </Grid>

        <Grid item xs={12} style={{ paddingTop : '20px'}}>
          <Container maxWidth='lg' className={classes.left}>
            <Typography variant='h4'>{lab.id ? 'Επεξεργασία εργαστηρίου' : 'Προσθήκη νέου εργαστηρίου'}</Typography>

            <TextField id='title'
             error={Boolean(errors.title)}
             label='Τίτλος'
             style = {{width:500, paddingTop:10, marginTop:0}}
             value={title}
             onChange={handleTitleChange}
             />
             <FormHelperText error>{errors.title}</FormHelperText>

             <Grid container spacing={2}>
               <Grid item xs={12} sm={4}>
                 <FormLabel>Εικόνα</FormLabel>
                 <br/>
                 <input
                   accept="image/*"
                   className={classes.fileinput}
                   id="contained-button-image"
                   type="file"
                   onChange={uploadImage}
                 />
                 <label htmlFor="contained-button-image">
                   <Button variant="contained" color="primary" component="span">
                     Ανεβασμα εικονας
                   </Button>
                 </label>
                 <FormHelperText error>{errors.image}</FormHelperText>
               </Grid>
               <Grid item xs={12} sm={8}>
                 {
                   image && !(image instanceof File) &&
                   <img src={labsEduImagesURL+lab.id+'/'+image.name} className={classes.labImage} alt='Εικόνα εργαστηρίου'/>
                 }
                 {image && image instanceof File && image.name}
               </Grid>
             </Grid>

             <FormControlLabel control={
               <Checkbox size='small' checked={visible} onChange={handleVisibleChange} />
             } label='Ορατό σε απλούς χρήστες' />
             <br/>

             <FormLabel>Σύντομη Περιγραφή</FormLabel>
             <Editor id="info" tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
              onInit={(evt, editor) => infoHtmlEditorRef.current = editor}
              initialValue={info}
              init={{
                height: 150,
                entity_encoding : "raw",
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'media table paste code help wordcount imagetools'
                ],
                menubar: false,
                toolbar: 'undo redo | formatselect | ' +
                'bold italic underline | alignleft aligncenter ' +
                'alignright alignjustify | outdent indent | ' +
                'removeformat | help',
              }}
            />
           <FormHelperText error>{errors.info}</FormHelperText>

           <TextField
            error={Boolean(errors.language)}
            label='Γλώσσα διεξαγωγής'
            style = {{width:500, paddingTop:10, marginTop:0}}
            value={language}
            onChange={handleLanguageChange}
            />
           <FormHelperText error>{errors.language}</FormHelperText>
           <br/>

           <FormLabel error={Boolean(errors.tags)}>Λέξεις - κλειδιά (πληκτρολογήστε και πατήστε Enter)</FormLabel>
           <br/>
           <TagsInput
             value={tags}
             onChange={handleChangeTags}
             tagProps={{ className: "react-tagsinput-tag info"}}
             inputProps={{placeholder: 'Προσθήκη'}}
           />
           <FormHelperText error>{errors.tags}</FormHelperText>

           <FormLabel>Επιστημονικός Υπεύθυνος</FormLabel>
           {
             (Object.keys(responsible).length > 0) ?
             <PersonPreview person={responsible} lab_id={lab.id || ''} onEditClick={() => prepareToEditPerson(true, -1)}/> :
             <IconButton onClick={() => prepareToEditPerson(true, -1)}><AddIcon/></IconButton>
           }
           <br/>

           <FormLabel>Λεπτομερής Περιγραφή</FormLabel>
           <Editor id="description" tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            onInit={(evt, editor) => descrHtmlEditorRef.current = editor}
            initialValue={description}
            init={{
              height: 300,
              entity_encoding : "raw",
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'media table paste code help wordcount imagetools'
              ],
              menubar: false,
              toolbar: 'undo redo | formatselect | ' +
              'bold italic underline strikethrough | alignleft aligncenter ' +
              'alignright alignjustify | outdent indent | numlist bullist checklist | ' +
              'forecolor backcolor casechange permanentpen formatpainter ' +
              'removeformat | help',
            }}
            />
           <FormHelperText error>{errors.description}</FormHelperText>

           {/*<TextField
            error={Boolean(errors.labDate)}
            label='Ημερομηνία διεξαγωγής'
            style = {{width:500, paddingTop:10, marginTop:0}}
            value={labDate}
            onChange={handleDateChange}
            />*/}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={elLocale}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="labDate"
                label="Ημερομηνία διεξαγωγής"
                value={labDate}
                onChange={handleDateChange}
                invalidDateMessage='Μη έγκυρη ημερομηνία'
                  onError={(err, value) => setLabDateError(err, value)}
              />
          </MuiPickersUtilsProvider>
          <br/>

           <TextField
            error={Boolean(errors.duration)}
            label='Διάρκεια παρακολούθησης'
            style = {{width:500, paddingTop:10, marginTop:0}}
            value={duration}
            onChange={handleDurationChange}
            />
           <FormHelperText error>{errors.duration}</FormHelperText>

           <TextField
            error={Boolean(errors.location)}
            label='Τόπος διεξαγωγής'
            style = {{width:500, paddingTop:10, marginTop:0}}
            value={location}
            onChange={handleLocationChange}
            />
           <FormHelperText error>{errors.location}</FormHelperText>
           <br/>

           <FormLabel>Απαιτήσεις για την παρακολούθηση</FormLabel>
           <Editor id="requirements" tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            onInit={(evt, editor) => reqHtmlEditorRef.current = editor}
            initialValue={requirements}
            init={{
              height: 300,
              entity_encoding : "raw",
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'media table paste code help wordcount imagetools'
              ],
              menubar: false,
              toolbar: 'undo redo | formatselect | ' +
              'bold italic underline strikethrough | alignleft aligncenter ' +
              'alignright alignjustify | outdent indent | numlist bullist checklist | ' +
              'forecolor backcolor casechange permanentpen formatpainter ' +
              'removeformat | help',
            }}
           />
           <FormHelperText error>{errors.requirements}</FormHelperText>

           <FormControlLabel control={
             <Checkbox size='small' checked={freeAccess} onChange={handleFreeAccessChange} />
           } label='Ελεύθερη πρόσβαση (μηδενικό κόστος και χωρίς περιορισμό στα άτομα)' />
           <br/>

           <TextField id='cost'
            error={Boolean(errors.cost)}
            label='Κόστος συμμετοχής'
            style = {{width:160, paddingTop:10, marginTop:0}}
            value={cost}
            onChange={handleCostChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">&euro;</InputAdornment>,
            }}
            />
           <FormHelperText error>{errors.cost}</FormHelperText>
           <br/>

           <TextField id='maxAttendants'
            error={Boolean(errors.maxAttendants)}
            label='Μέγιστος αριθμός συμμετεχόντων (0 ή κενό = δεν υπάρχει περιορισμός)'
            style = {{width:500, paddingTop:10, marginTop:0}}
            value={maxAttendants}
            onChange={handleMaxAttendantsChange}
            />
            <FormHelperText error>{errors.maxAttendants}</FormHelperText>
            <br/>

            <TextField id='eshop_link'
             error={Boolean(errors.eshop_link)}
             label='Link σε e-shop (για εργαστήρια με κόστος > 0)'
             style = {{width:500, paddingTop:10, marginTop:0}}
             value={eshop_link}
             onChange={handleEshop_linkChange}
             />
             <FormHelperText error>{errors.eshop_link}</FormHelperText>
             <br/>

           <FormLabel>Τι θα μάθει ο συνδρομητής</FormLabel>
           <Editor id="learning" tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            onInit={(evt, editor) => learnHtmlEditorRef.current = editor}
            initialValue={learning}
            init={{
              height: 300,
              entity_encoding : "raw",
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'media table paste code help wordcount imagetools'
              ],
              menubar: false,
              toolbar: 'undo redo | formatselect | ' +
              'bold italic underline strikethrough | alignleft aligncenter ' +
              'alignright alignjustify | outdent indent | numlist bullist checklist | ' +
              'forecolor backcolor casechange permanentpen formatpainter ' +
              'removeformat | help',
            }}
            />
           <FormHelperText error>{errors.learning}</FormHelperText>

           <FormLabel>Εισηγητές</FormLabel>
           <IconButton onClick={() => prepareToEditPerson(false, -1)}><AddIcon/></IconButton>
           {
             speakers && speakers.map((speaker, idx) =>
              <PersonPreview key={idx} person={speaker} onEditClick={() => prepareToEditPerson(false, idx)}
                onDeleteClick={() => deleteSpeaker(idx)} lab_id={lab.id || ''}/>
            )
           }
           <br/>

           <TextField id='introVideo'
            error={Boolean(errors.introVideo)}
            label='Link σε Βίντεο του επιστημονικού υπεύθυνου – σύνοψη εργαστηρίου'
            style = {{width:500, paddingTop:10, marginTop:0}}
            value={introVideo}
            onChange={handleIntroVideoChange}
            />
           <FormHelperText error>{errors.introVideo}</FormHelperText>
           <br/>

           <FormLabel>Περιεχόμενα</FormLabel>
           <br/>

           <TextField id='welcomeVideo'
            error={Boolean(errors.welcomeVideo)}
            label='Link σε Βίντεο Καλωσορίσματος'
            style = {{width:500, paddingTop:10, marginTop:0}}
            value={welcomeVideo}
            onChange={handleWelcomeVideoChange}
            />
           <FormHelperText error>{errors.welcomeVideo}</FormHelperText>

           <TextField id='structureVideo'
            error={Boolean(errors.structureVideo)}
            label='Link σε Βίντεο Δομής εργαστηρίου'
            style = {{width:500, paddingTop:10, marginTop:0}}
            value={structureVideo}
            onChange={handleStructureVideoChange}
            />
           <FormHelperText error>{errors.structureVideo}</FormHelperText>

           <TextField id='attendanceVideo'
            error={Boolean(errors.attendanceVideo)}
            label='Link σε Βίντεο Τρόπου παρακολούθησης - συμμετοχής'
            style = {{width:500, paddingTop:10, marginTop:0}}
            value={attendanceVideo}
            onChange={handleAttendanceVideoChange}
            />
           <FormHelperText error>{errors.attendanceVideo}</FormHelperText>

           <Button color="rose" onClick={handleSubmit} disabled={saving}>Καταχωριση</Button>
           <Button onClick={handleCancel}>Ακυρωση</Button>
         </Container>
        </Grid>

      </Grid>

      {alert}

      <LabPersonForm open={openPersonForm} onSave={addOrEditPerson} onClose={() => setOpenPersonForm(false)}
        person={personToEdit} isResponsible={personToEditIsResp} lab_id={lab.id || ''}/>
    </div>
  )
}

const PersonPreview = (props) => {
  const classes = useStyles()
  const { person, lab_id, onEditClick, onDeleteClick } = props

  return (
    <>
      <Typography variant='subtitle2' component='div'>
        {person.fullname}
        <IconButton onClick={onEditClick}><EditIcon/></IconButton>
        {
          onDeleteClick &&
          <IconButton onClick={onDeleteClick}><DeleteIcon/></IconButton>
        }
      </Typography>
      {
        person.image && (
          !(person.image instanceof File) ?
            <img src={labsEduImagesURL+lab_id+'/'+person.image} className={classes.personImage} alt={person.fullname}/> :
            <Typography variant='body2'>Εικόνα: {person.image.name}</Typography>
      )
      }
      {
        person.specialty &&
        <Typography variant='body2'>Ιδιότητα: {person.specialty}</Typography>
      }
      <Typography variant='body2'>{truncateText(person.info || '', 100)}</Typography>
    </>
  )
}

export default EduLabEdit
