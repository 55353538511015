import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import PostAddIcon from '@material-ui/icons/PostAdd';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from "components/CustomButtons/Button.js";
import IconButton from '@material-ui/core/IconButton';
import Rating from '@material-ui/lab/Rating';
import Tooltip from '@material-ui/core/Tooltip';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'

import LabThumbnail from './LabThumbnail.js'
import LabUserCommentForm from './LabUserCommentForm.js'
import LabHierarchyTree from './LabHierarchyTree.js'
import LabPagesNavigationFooter from './LabPagesNavigationFooter.js'
import ContentSearch from './ContentSearch.js'

import { labsEduImagesURL } from 'clients/clientConfig.js'
import { getLabMainPage, getLabTypeName, getAccessRightsFieldForLabType,
  calcLabRating } from './utils.js'
import { getOneLab, rateLab, copyLab, addLabComment } from 'clients/labEducationEtcClient.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  paper: {
    padding: 20
  },
  right: {
    float: 'right',
  },
  bordered: {
    border: '1px #808080 solid',
    padding: 10,
  },
  labImage: {
    height: 120,
    width: 180,
    margin: 'auto',
    display: 'block'
  },
  personImage: {
    height: 100,
    width: 100,
    margin: 'auto',
    display: 'block'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  }
}))

const EduLabFullView = (props) => {
  const classes = useStyles()
  const parse = require('html-react-parser');

  const [lab, setLab] = React.useState({})
  const [myRating, setMyRating] = React.useState(null)
  const [hierarchy, setHierarchy] = React.useState([])
  const [forum_links, setForum_links] = React.useState([])
  /*const [commentIdx, setCommentIdx] = React.useState(-1)
  const [commentText, setCommentText] = React.useState('')*/
  const [openCommentForm, setOpenCommentForm] = React.useState(false)
  const [disableCopy, setDisableCopy] = React.useState(false)
  const [disableCommentSubmit, setDisableCommentSubmit] = React.useState(false)

  const labs_type_access = getAccessRightsFieldForLabType(lab.lab_type)
  const ratings = calcLabRating(lab)

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  const showErrorAlert = (msg) => {
    setAlert(<ErrorAlert message={msg} hideAlert={hideAlert}/>)
  }

  //Snackbar messages (to inform user of success)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const handleShowSuccessMessage = (message) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message)
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleRatingChange = (e, newValue) => {
    const params = {'lab_id': lab.id, 'rating': newValue}

    rateLab(params,
      (data) => {
        setMyRating(newValue)
        handleShowSuccessMessage('Η αξιολόγησή σας καταχωρίστηκε με επιτυχία!')

        //update lab.ratings
        const tempLab = {...lab}
        if (tempLab.ratings && tempLab.ratings.length != 0){
          const pos = tempLab.ratings.findIndex(r => r.user_id == data.rating.user_id)
          if (pos >= 0){
            tempLab.ratings[pos] = data.rating
          } else {
            tempLab.ratings.push(data.rating)
          }
        } else {
          tempLab.ratings = [data.rating]
        }
        //console.log(tempLab.ratings)
        setLab(tempLab)
      },
      (err) => showErrorAlert(err.message)
    )
  }

  const addComment = (text) => {
    setDisableCommentSubmit(true)
    const params = {lab_id: lab.id, text: text}
    //console.log(params)

    addLabComment(params,
      () => {
        setDisableCommentSubmit(false)
        setOpenCommentForm(false)
        handleShowSuccessMessage('Το σχόλιό σας καταχωρίστηκε με επιτυχία και θα εμφανιστεί αν εγκριθεί από τον υπεύθυνο!')
      },
      (err) => {
        setDisableCommentSubmit(false)
        showErrorAlert(err.message)
      }
    )
  }

  const goToAddPage = () => {
    window.location.href = '/admin/EduLabEditPage/' + lab.id
  }

  const handleCopyLab = () => {
    setDisableCopy(true)
    const params = {lab_id: lab.id}
    //console.log(params)
    copyLab(params,
      (data) => window.location.href = '/admin/EduLabFullView/' + data.inserted_id,
      (err) => {
        setDisableCopy(false)
        showErrorAlert(err.message)
      }
    )
  }

  React.useEffect(() => {
    if (props.match.params.lab_id){
      getOneLab(props.match.params.lab_id, '?get_full=yes',
        (data) => {
          if (data.education_labs.length > 0){
            setLab(data.education_labs[0])
            setHierarchy(data.hierarchy)
            setForum_links(data.forum_links)

            //get user rating
            if (data.education_labs[0].ratings){
              const userId = localStorage.getItem('UserId')
              //console.log(userId)
              data.education_labs[0].ratings.forEach(r => {
                //console.log(r)
                if (r.user_id == userId){
                  setMyRating(r.rating)
                }
              })
            }
            /*setHierarchy([
              {
                id: props.match.params.lab_id,
                units: [{
                  id: 0, title: 'Φάκελος',
                  chapters: [
                    {id: 1, title: 'Κεφάλαιο 1', pages: [{id: 4, title: 'Σελίδα 4'}]},
                    {id: 2, title: 'Κεφάλαιο 2'},
                  ]
                }, {id: 1, title: 'Unit 1', chapters: []}]
              }
            ])*/
          } else {
            window.location.href = '/admin/LabNotFound'
          }
        },
        (err) => window.location.href = '/admin/LabNotFound'
      )
    }
  }, [props.match.params.lab_id])

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} >
        <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color='inherit' href={getLabMainPage(lab.lab_type || '')}>
                {getLabTypeName(lab.lab_type || '')}
              </Link>
              <Link color='inherit' href={"/admin/EduLabPreview/"+lab.id}>{lab.title || ''}</Link>
              <Typography color="textPrimary">Φάκελος εργαστηρίου</Typography>
          </Breadcrumbs>
        </div>
      </Grid>

     <Grid item xs={12} style={{ paddingTop : '20px'}}>
       <Container id="labfullview" maxWidth="md">
        <ContentSearch lab_id={lab.id}/>
        <Paper className={classes.paper}>

          <Typography variant='h4'>
            {lab.title}
            {
              access_rights && labs_type_access && access_rights[labs_type_access].w &&
              <span className={classes.right}>
                <Button justIcon round color="primary"
                  onClick={() => window.location.href='/admin/EduLabEdit/'+lab.lab_type+"/"+lab.id}><EditIcon/></Button>
                <Button justIcon round color="primary"
                  onClick={handleCopyLab} disabled={disableCopy}><FileCopyIcon/></Button>
              </span>
            }
          </Typography>

          {lab.image && <img alt='' src={labsEduImagesURL+lab.id+'/'+lab.image} className={classes.labImage}/>}

          <Typography variant='h5'>Τι θα μάθεις σε αυτό το εργαστήριο:</Typography>
          <Box className={classes.bordered}>
            <Typography variant='body1' component='div'>{parse(lab.learning || '')}</Typography>
          </Box>
          <br/>

          <Typography variant='h5'>Η ομάδα εισηγητών:</Typography>
          {
            lab.speakers && lab.speakers.map((speaker, idx) =>
              <Grid container spacing={2} key={idx}>
                <Grid item xs={3}>
                  {
                    speaker.image &&
                    <img alt={speaker.fullname} src={labsEduImagesURL+lab.id+'/'+speaker.image} className={classes.personImage}/>
                  }
                </Grid>
                <Grid item xs={8}>
                  <Typography variant='body1'>
                    {speaker.fullname}<br/>
                    Λίγα λόγια: {speaker.info}
                  </Typography>
                </Grid>
              </Grid>
            )
          }
          <br/>

          {lab.introVideo &&
            <Typography variant='h5'>
              Βίντεο του επιστημονικού υπεύθυνου – σύνοψη εργαστηρίου
              <Link href={lab.introVideo} target="_blank"><VideoLibraryIcon/></Link>
            </Typography>
          }
          <br/>

          <Typography variant='h5'>Περιεχόμενα εργαστηρίου</Typography>
          {
            lab.welcomeVideo &&
            <Typography variant='body1'><Link href={lab.welcomeVideo} target="_blank">Καλωσόρισμα (βιντεο)<VideoLibraryIcon/></Link></Typography>
          }
          {
            lab.structureVideo &&
            <Typography variant='body1'><Link href={lab.structureVideo} target="_blank">Δομή εργαστηρίου <VideoLibraryIcon/></Link></Typography>
          }
          {
            lab.attendanceVideo &&
            <Typography variant='body1'><Link href={lab.attendanceVideo} target="_blank">Τρόπος παρακολούθησης / συμμετοχής <VideoLibraryIcon/></Link></Typography>
          }
          <br/>

          <Typography variant='h5'>Ιεραρχία</Typography>
          <LabHierarchyTree hierarchy={hierarchy}/>
          {
            access_rights && labs_type_access && access_rights[labs_type_access].w &&
            <Tooltip title='Προσθήκη σελίδας'>
              <Button color='primary' round justIcon onClick={goToAddPage}><PostAddIcon/></Button>
            </Tooltip>
          }
          <br/><br/>

          {
            forum_links && forum_links.length > 0 &&
            <>
              <Typography variant='h5'>Συζητήσεις σε Φόρουμ</Typography>
              <List>
              {
                forum_links.map(fl =>
                  <ListItem key={fl.id}>
                    <Link href={'/admin/Forum/'+fl.id}>{fl.name}</Link>
                  </ListItem>
                )
              }
              </List>
            </>
          }

          <Typography variant='h5'>
            Σχόλια χρηστών
          </Typography>
          {
            (localStorage.getItem('UserName')===null || localStorage.getItem('UserName')==='null') ?
            <Link href="/admin/SignIn">Συνδεθείτε για να καταχωρίσετε σχόλια</Link> :
            <Button color='primary' className={classes.right} round onClick={() => setOpenCommentForm(true)}>Προσθηκη σχολίου<AddIcon/></Button>
          }
          <br/>
          <Typography variant='body1'>Ένα δείγμα από τα σχόλια των συμμετεχόντων</Typography>
          <br/>
          {
              lab.comments && lab.comments.map((c, idx) =>
              <div key={idx}>
                <Typography variant='subtitle2' component='div'>
                  {c.user_fullname}
                </Typography>

                <Typography variant='body2' component='div'>{parse(c.text)}</Typography>
              </div>
          )}
          {
            access_rights && labs_type_access && access_rights[labs_type_access].w &&
            <Button color='primary' onClick={() => window.location.href='/admin/EduLabSelectComments/' + lab.id}>
              Επιλογή σχολίων για προβολή
            </Button>
          }
          <br/><br/>

          <Typography variant="body2">
            <Rating name="read-only" value={ratings} readOnly precision={0.1} />
            {ratings} Αξιολόγηση χρηστών
          </Typography>
          <br/>

          {
            access_rights && labs_type_access && !access_rights[labs_type_access].w &&
            (
              (localStorage.getItem('UserName')===null || localStorage.getItem('UserName')==='null') ?
              <Link href="/admin/SignIn">Συνδεθείτε για να καταχωρίσετε τη δική σας αξιολόγηση</Link> :
              <Typography variant="body2">
                <Rating name="my_rating" value={myRating || null} onChange={handleRatingChange}/>
                Η αξιολόγησή μου
              </Typography>
            )
          }

          <Divider className={classes.divider}/>
          <LabPagesNavigationFooter hierarchy={hierarchy} current_page='0'/>
          <br/>
         </Paper>
       </Container>

      </Grid>
  </Grid>

    {alert}

    <Snackbar anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={openSnackbar}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
      >
      <Alert onClose={handleCloseSnackbar} severity="success">
        {snackbarMessage}
      </Alert>
    </Snackbar>

    <LabUserCommentForm open={openCommentForm} disableSubmit={disableCommentSubmit}
      onSave={addComment} onClose={() => setOpenCommentForm(false)}/>
  </div>
  )
}

export default EduLabFullView
