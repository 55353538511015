const mappings_small_letters = [
  {findStr: '[ἀἁἂἃἄἅἆἇὰάᾀᾁᾂᾃᾄᾅᾆᾇᾲᾳᾴᾶᾷᾰᾱά]', replaceStr: 'α'},
  {findStr: '[ἐἑἒἓἔἕὲέέ]', replaceStr: 'ε'},
  {findStr: '[ἠἡἢἣἤἥἦἧὴήᾐᾑᾒᾔᾕᾖᾗῂῃῄῆῇή]', replaceStr: 'η'},
  {findStr: '[ἰἱἲἳἴἵἶἷὶίῖῒΐῗῐῑίϊΐ]', replaceStr: 'ι'},
  {findStr: '[ὀὁὂὃὄὅὸόό]', replaceStr: 'ο'},
  {findStr: '[ῤῥ]', replaceStr: 'ρ'},
  {findStr: '[ὐὑὒὓὔὕὖὗὺύῦῢΰῧῠῡύϋΰ]', replaceStr: 'υ'},
  {findStr: '[ὠὡὢὣὤὥὦὧὼώᾠᾡᾢᾣᾤᾥᾦᾧῲῳῴῶῷώ]', replaceStr: 'ω'}
]

const mappings_for_simple_accents = [
    {replaceStr: '[ὰάά]', findStr: 'ά'},
    {replaceStr: '[ἂἄ]', findStr: 'ἄ'},
    {replaceStr: '[ἃἅ]', findStr: 'ἅ'},
    {replaceStr: '[ᾲᾴ]', findStr: 'ᾴ'},
    {replaceStr: '[ᾂᾄ]', findStr: 'ᾄ'},
    {replaceStr: '[ᾃᾅ]', findStr: 'ᾅ'},
    {replaceStr: '[ΆᾺΆ]', findStr: 'Ά'},
    {replaceStr: '[ἊἌ]', findStr: 'Ἄ'},
    {replaceStr: '[ἋἍ]', findStr: 'Ἅ'},
    {replaceStr: '[ᾊᾌ]', findStr: 'ᾌ'},
    {replaceStr: '[ᾋᾍ]', findStr: 'ᾍ'},
    {replaceStr: '[έὲέ]', findStr: 'έ'},
    {replaceStr: '[ἒἔ]', findStr: 'ἔ'},
    {replaceStr: '[ἓἕ]', findStr: 'ἕ'},
    {replaceStr: '[ΈῈΈ]', findStr: 'Έ'},
    {replaceStr: '[ἚἜ]', findStr: 'Ἔ'},
    {replaceStr: '[ἛἝ]', findStr: 'Ἕ'},
    {replaceStr: '[ήὴή]', findStr: 'ή'},
    {replaceStr: '[ἢἤ]', findStr: 'ἤ'},
    {replaceStr: '[ἣἥ]', findStr: 'ἥ'},
    {replaceStr: '[ᾒᾔ]', findStr: 'ᾔ'},
    {replaceStr: '[ᾓᾕ]', findStr: 'ᾕ'},
    {replaceStr: '[ῂῄ]', findStr: 'ῄ'},
    {replaceStr: '[ΉῊΉ]', findStr: 'Ή'},
    {replaceStr: '[ἪἬ]', findStr: 'Ἤ'},
    {replaceStr: '[ἫἭ]', findStr: 'Ἥ'},
    {replaceStr: '[ᾚᾜ]', findStr: 'ᾜ'},
    {replaceStr: '[ᾛᾝ]', findStr: 'ᾝ'},
    {replaceStr: '[ίὶί]', findStr: 'ί'},
    {replaceStr: '[ἲἴ]', findStr: 'ἴ'},
    {replaceStr: '[ἳἵ]', findStr: 'ἵ'},
    {replaceStr: '[ΐῒΐ]', findStr: 'ΐ'},
    {replaceStr: '[ΊῚΊ]', findStr: 'Ί'},
    {replaceStr: '[ἺἼ]', findStr: 'Ἴ'},
    {replaceStr: '[ἻἽ]', findStr: 'Ἵ'},
    {replaceStr: '[όὸό]', findStr: 'ό'},
    {replaceStr: '[ὂὄ]', findStr: 'ὄ'},
    {replaceStr: '[ὃὅ]', findStr: 'ὅ'},
    {replaceStr: '[ΌῸΌ]', findStr: 'Ό'},
    {replaceStr: '[ὊὌ]', findStr: 'Ὄ'},
    {replaceStr: '[ὋὍ]', findStr: 'Ὅ'},
    {replaceStr: '[ύὺύ]', findStr: 'ύ'},
    {replaceStr: '[ὒὔ]', findStr: 'ὔ'},
    {replaceStr: '[ὓὕ]', findStr: 'ὕ'},
    {replaceStr: '[ΰῢΰ]', findStr: 'ΰ'},
    {replaceStr: '[ΎῪΎ]', findStr: 'Ύ'},
    {replaceStr: '[ὛὝ]', findStr: 'Ὕ'},
    {replaceStr: '[ώὼώ]', findStr: 'ώ'},
    {replaceStr: '[ὢὤ]', findStr: 'ὤ'},
    {replaceStr: '[ὣὥ]', findStr: 'ὥ'},
    {replaceStr: '[ᾢᾤ]', findStr: 'ᾤ'},
    {replaceStr: '[ᾣᾥ]', findStr: 'ᾥ'},
    {replaceStr: '[ῲῴ]', findStr: 'ῴ'},
    {replaceStr: '[ΏῺΏ]', findStr: 'Ώ'},
    {replaceStr: '[ὪὬ]', findStr: 'Ὤ'},
    {replaceStr: '[ὫὭ]', findStr: 'Ὥ'},
    {replaceStr: '[ᾪᾬ]', findStr: 'ᾬ'},
    {replaceStr: '[ᾫᾭ]', findStr: 'ᾭ'},
]

const mappings_case_insensitive = [
  {findStr: 'α', replaceStr: 'αἀἁἂἃἄἅἆἇὰάᾀᾁᾂᾃᾄᾅᾆᾇᾲᾳᾴᾶᾷᾰᾱάΑἈἉἊἋἌἍἎἏᾺΆᾈᾉᾊᾋᾌᾍᾎᾏᾼᾸᾹΆ'},
  {findStr: 'ε', replaceStr: 'εἐἑἒἓἔἕὲέέΕἘἙἚἛἜἝῈΈΈ'},
  {findStr: 'η', replaceStr: 'ηἠἡἢἣἤἥἦἧὴήᾐᾑᾒᾔᾕᾖᾗῂῃῄῆῇήΗἨἩἪἫἬἭἮἯῊΉᾘᾙᾚᾛᾜᾝᾞᾟῌΉ'},
  {findStr: 'ι', replaceStr: 'ιἰἱἲἳἴἵἶἷὶίῖῒΐῗῐῑίΐΙἸἹἺἻἼἽἾἿῚΊῘῙΊ'},
  {findStr: 'ο', replaceStr: 'οὀὁὂὃὄὅὸόόΟὈὉὊὋὌὍῸΌΌ'},
  {findStr: 'ρ', replaceStr: 'ρῤῥΡῬ'},
  {findStr: 'υ', replaceStr: 'υὐὑὒὓὔὕὖὗὺύῦῢΰῧῠῡύΥὙὛὝὟῪΎῨῩΎ'},
  {findStr: 'ω', replaceStr: 'ωὠὡὢὣὤὥὦὧὼώᾠᾡᾢᾣᾤᾥᾦᾧῲῳῴῶῷώΩὨὩὪὫὬὭὮὯῺΏᾨᾩᾪᾫᾬᾭᾮᾯῼΏ'}
]


export { mappings_small_letters, mappings_for_simple_accents, mappings_case_insensitive }
