import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from '@material-ui/core/styles';
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStylesAlert = makeStyles(stylesAlert);

const ErrorAlert = (props) => {
    const { message, hideAlert } = props
    const classesAlert = useStylesAlert();

    return(
     <SweetAlert
       error
       style={{ display: "block", marginTop: "-100px" }}
       title="Παρουσιάστηκε σφάλμα"
       onConfirm={hideAlert}
       onCancel={hideAlert}
       confirmBtnCssClass={
         classesAlert.button + " " + classesAlert.info
       }
     >
       {message}
     </SweetAlert>
   );
}

const WarningAlert = (props) => {
    const { message, hideAlert } = props
    const classesAlert = useStylesAlert();

    return(
     <SweetAlert
       warning
       style={{ display: "block", marginTop: "-100px" }}
       title=''
       onConfirm={hideAlert}
       onCancel={hideAlert}
       confirmBtnCssClass={
         classesAlert.button + " " + classesAlert.info
       }
     >
       {message}
     </SweetAlert>
   );
}

export { ErrorAlert, WarningAlert }
