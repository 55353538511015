import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Button from "components/CustomButtons/Button.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import { AddTranslationForm, getValidationErrorsForTranslation } from './AddTranslationForm.js'
import { getAllWorksWithBooks } from "clients/worksClient.js";
import { uploadTranslation } from "clients/translationsClient.js"
import { checkNoErrors } from 'utils.js'
import { sortWorksAndBooks } from 'sorting.js'

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ErrorAlert, WarningAlert } from 'views/CustomAlerts/CustomAlerts.js'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({

   control: {
     padding: theme.spacing(2),
   },



 paper: {
   padding: theme.spacing(5),
   margin: '15px',
   maxWidth: 800,
  // height: 300,
   width: 600,
 },
 image: {
   width: 128,
   height: 128,
 },
 img: {
   margin: 'auto',
   display: 'block',
   maxWidth: '100%',
   maxHeight: '100%',
 },


  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },



}));

export default function AddTranslation() {

  const classes = useStyles();
  //const theme = useTheme();


  const spacing = 2;

   /*const handleChange = (event) => {
     setSpacing(Number(event.target.value));
   };*/


  const [selected, setSelected] = React.useState('');
  /*const [sectionLines, setSectionLines] = React.useState('')
  const [sectionText, setSectionText] = React.useState('')*/

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  //Snackbar messages (to inform user of success)
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
    //console.log(nodeIds);
  };

  const handleFileChange = (f) => {
    setFile(f)
  }
  /*const handleLinesChange = (e) => {
    setSectionLines(e.target.value)
  }

  const handleTextChange = (e) => {
    setSectionText(e.target.value)
  }*/

  const handleAdd = () => {
    const newErrors = getValidationErrorsForTranslation(selected, file)
    //console.log(newErrors)
    setErrors(newErrors)
    if (checkNoErrors(newErrors)){
      //console.log('passed validation')
      /*console.log(selected)
      console.log(file)*/
      const [work_id, book_id] = selected.split('-')
      const params = {work_id: work_id, book_id: book_id}
      //console.log(params)*/

      uploadTranslation(file, params,
        (data) => {
          //show messages
          if (data.message===''){
            setOpenSnackbar(true);
          } else {
            const message = 'Η καταχώριση έγινε με επιτυχία, με εξαίρεση τα εξής:' + data.message
            setAlert(<WarningAlert message={message} hideAlert={hideAlert}/>)
          }
        },
        (err) => setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
      )
    }
  }

  const [works, setWorks] = React.useState([])
  const [file, setFile] = React.useState(null)
  const [errors, setErrors] = React.useState({})

  React.useEffect(() => {
    getAllWorksWithBooks('',
      (data) => setWorks(sortWorksAndBooks(data.works)),
      (err) => console.log(err)
    );
  }, [])

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} >
          <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link color="inherit" href="/admin/SelectTransSem">
                  Main
                </Link>

                <Link color="inherit" href="/admin/bookSelection">
                  Αριστοτελικά έργα, Μεταφράσεις, Σχόλια
                </Link>
                <Typography color="textPrimary">Προσθήκη μεταφράσεων</Typography>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid item xs={12} style={{ paddingTop : '20px'}}>
          <Grid container justify="center" spacing={spacing} >
            <Grid>
              <Paper className={classes.paper}  >
                <Grid container spacing={2}>

                  <Grid item xs={12} sm container>
                    <Grid item xs={12} container spacing={2}>
                      <AddTranslationForm
                        handleSelect={handleSelect}
                        handleFileChange={handleFileChange}
                        works={works}
                        errors={errors}/>
                    </Grid>
                    <Grid item xs={12} style = {{paddingTop:20}}>
                      <Button color="primary" onClick={handleAdd} >Προσθήκη</Button>
                    </Grid>


                  </Grid>
                </Grid>
              </Paper>
            </Grid>
        </Grid>
      </Grid>
      </Grid>

      {alert}

      <Snackbar anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        >
        <Alert onClose={handleCloseSnackbar} severity="success">
          H καταχώριση έγινε με επιτυχία
        </Alert>
      </Snackbar>

    </div>
  );
}
