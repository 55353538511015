import React from 'react'

import FormControl from '@material-ui/core/FormControl';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { makeStyles } from '@material-ui/core/styles';

import { getAllSections } from 'clients/sectionsClient.js'
import transformSectionsForTree from 'views/CollTranslationREADONLY/transformSectionsForTree.js'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 600,
    flexGrow: 1,
  },
}))

const LyricsSelectionTree = (props) => {
  const classes = useStyles()

  const [works, setWorks] = React.useState([])
  const [expanded, setExpanded] = React.useState([])
  const [selected, setSelected] = React.useState('');

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    //console.log(nodeIds)
    setSelected(nodeIds)
    props.setSelected(nodeIds)
  };

  React.useEffect(() => {
    getAllSections(
      (data) => setWorks(transformSectionsForTree(data.documents)),
      (err) => console.log(err.message)
    )
  }, [])

  return(
    <FormControl>
     <TreeView
       className={classes.root}
       expanded={expanded}
       selected={selected}
       defaultCollapseIcon={<ExpandMoreIcon />}
       defaultExpandIcon={<ChevronRightIcon />}
       onNodeSelect={handleSelect}
       onNodeToggle={handleToggle}
     >
       {works.map((work) => {
         return (
           <TreeItem key={work.id} classes={{ label: 'pateras' }} nodeId={work.id.toString()} label={work.name}>
             {work.books && work.books.map((book) => {
               const book_id = work.id + '-' + book.id
               return (
                 <TreeItem key={book_id} classes={{ label: 'pateras' }}  nodeId={book_id} label={book.name}>
                 {book.sections.map((section) => {
                   return (
                     <TreeItem key={section.id} nodeId={section.id} label={section.lines} classes={{ label: 'paidi' }}/>
                   )
                 })}
                 </TreeItem>
               )
             })}
           </TreeItem>
         )
       })}
     </TreeView>
   </FormControl>
  )
}

export default LyricsSelectionTree
