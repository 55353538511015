import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";
import { Editor } from '@tinymce/tinymce-react';
import Checkbox from '@material-ui/core/Checkbox';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'
import { ConfirmCancelMessage } from 'views/CustomAlerts/ConfirmationDialog.js'

import LabPageLevelSelectionTree from "./LabPageLevelSelectionTree.js"
import LabPageOrderSelection from './LabPageOrderSelection.js'

import { checkNoErrors } from 'utils.js'
import { getLabMainPage, getLabTypeName } from './utils.js'
import { getOneLab, getOnePage, addPage, editPage } from 'clients/labEducationEtcClient.js'
import { apiURL } from 'clients/clientConfig.js'
import { lab_editor_image_upload_handler } from 'clients/clientUtils.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  fileinput: {
    display: 'none',
  },
  left: {
    float: 'left'
  }
}))

const EduLabEditPage = (props) => {
  const classes = useStyles()

  const [lab, setLab] = React.useState({})
  const [page, setPage] = React.useState({})
  const [hierarchy, setHierarchy] = React.useState([])

  const [title, setTitle] = React.useState('')
  const [visible, setVisible] = React.useState(false)
  const [father, setFather] = React.useState('')
  const [order, setOrder] = React.useState('')
  const [content, setContent] = React.useState('')
  const [files_arr, setFiles_arr] = React.useState([])
  const [file, setFile] = React.useState(null)
  const [fileDescription, setFileDescription] = React.useState('')
  const [editingFileIdx, setEditingFileIdx] = React.useState(-1)
  const [editingFilename, setEditingFilename] = React.useState('')

  const contentHtmlEditorRef = React.useRef(null);

  const [saving, setSaving] = React.useState(false)
  const [errors, setErrors] = React.useState({})
  const [fileErrors, setFileErrors] = React.useState({})

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  //field changes
  const handleTitleChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.title = validate('title', e.target.value)
    setErrors(newErrors)

    setTitle(e.target.value)
  }

  const handleVisibleChange = (e) => {
    setVisible(e.target.checked)
  }

  const handleFatherChange = (nodeId) => {
    let newErrors = Object.assign({}, errors)
    newErrors.father = validate('father', nodeId)
    setErrors(newErrors)

    setFather(nodeId)
    //reset order
    setOrder('')
  }

  const handleOrderChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.order = validate('order', e.target.value)
    setErrors(newErrors)

    setOrder(e.target.value)
  }

  const handleFileDescriptionChange = (e) => {
    let newErrors = Object.assign({}, fileErrors)
    newErrors.fileDescription = validate('fileDescription', e.target.value)
    setFileErrors(newErrors)

    setFileDescription(e.target.value)
  }

  const uploadFile = (e) => {
    const allowedExtensions = /(\.(pdf|doc|docx|odf|rtf|html|xml|ppt|pptx|txt|ods|xls|xlsx|jpg|jpeg|gif|png|bmp|tiff|pjp|jfif|svg|svgz|webp|ico|xbm|dib|tif|pjpeg|avif))$/i;
    let newErrors = Object.assign({}, fileErrors)

    if (e.target.files[0].size > 5000000){
      newErrors.file = "Πρέπει να ανεβάσετε αρχείο μέχρι 5ΜΒ"
      setFile(null)
    } else if (!allowedExtensions.exec(e.target.files[0].name)) {
      newErrors.file = "Πρέπει να ανεβάσετε είτε αρχείο είκονας είτε αρχείο με τις εξής καταλήξεις: " +
        "pdf, doc, docx, odf, rtf, html, xml, ppt, pptx, txt, ods, xls, xlsx"
      setFile(null)
    } else {
      setFile(e.target.files[0])
      newErrors.file = ''
    }

    setFileErrors(newErrors)
  }

  const prepareToEditFile = (idx) => {
    setEditingFileIdx(idx)
    setEditingFilename(files_arr[idx].name)
    setFileDescription(files_arr[idx].description)
    setFileErrors({})
  }

  const removeFile = (idx) => {
    let temp = [...files_arr]
    temp.splice(idx, 1)
    setFiles_arr(temp)
  }

  const addOrEditFile = () => {
    let newErrors = Object.assign({}, fileErrors)
    newErrors.fileDescription = validate('fileDescription', fileDescription)
    newErrors.file = (editingFileIdx >= 0) ? '' : validate('file', file)
    setFileErrors(newErrors)

    if (checkNoErrors(newErrors)){
      if (editingFileIdx >= 0){
        let tempFiles = [...files_arr]
        tempFiles[editingFileIdx].description = fileDescription
        setFiles_arr(tempFiles)
      } else {
        setFiles_arr([...files_arr, {description: fileDescription, name: file.name, file: file}])
      }

      resetFileFormFields()
    }
  }

  const resetFileFormFields = () => {
    setEditingFileIdx(-1)
    setEditingFilename('')
    setFileDescription('')
    setFile(null)
    setFileErrors({})
  }

  //validation
  const validate = (name, value) => {
    switch (name) {
      case 'title':
      case 'content':
      case 'father':
      case 'order':
        if (value.toString().trim()===''){
          document.getElementById(name).focus()
          return  'Υποχρεωτικό πεδίο'
        } else {
          return ''
        }

      case 'fileDescription':
        if (value.length > 100){
          document.getElementById(name).focus()
          return 'Επιτρέπονται μέχρι 100 χαρακτήρες'
        } else {
          return ''
        }

      case 'file':
        return (value===null) ? 'Υποχρεωτικό ανέβασμα αρχείου' : ''

      default:
        return ''
    }
  }

  const redirectToMain = () => {
    window.location.href =  '/admin/EduLabFullView/' + lab.id
  }

  const handleSubmit = () => {
    const newContent = (contentHtmlEditorRef.current) ? contentHtmlEditorRef.current.getContent() : ''

    let newErrors = Object.assign({}, errors)
    newErrors.title = validate('title', title)
    newErrors.content = validate('content', newContent)
    newErrors.father = validate('father', father)
    newErrors.order = validate('order', order)
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      setSaving(true)
      let params = {title: title, content: newContent, visible: visible, lab_id: lab.id,
        father: father, order: order,
        files: files_arr.map(f => {
          let ret = {name: f.name, description: f.description};
          if (f.id){
            ret.id = f.id
          }
          return ret
        })
      }

      const newFiles = files_arr.filter(f => (f.file && f.file instanceof File))
          .map(f => f.file)
      //console.log(newFiles)

      if (page.id){
        params.id = page.id
        //console.log(params)

        editPage(newFiles, params,
          () => window.location.href = '/admin/EduLabViewPage/' + page.id,
          (err) => {
            setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
            setSaving(false)
          }
        )
      } else {
        //console.log(params)
        addPage(newFiles, params,
          (data) => window.location.href = '/admin/EduLabViewPage/' + data.inserted_id,
          (err) => {
            setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
            setSaving(false)
        })
      }
    }
  }

  const handleCancel = () => {
    setAlert(<ConfirmCancelMessage onConfirm={redirectToMain} onCancel={hideAlert} />)
  }

  React.useEffect(()=>{
    if (props.match.params.page_id){
      //get page + lab info
      getOnePage(props.match.params.page_id, '',
        (data) => {
          if (data.page){
            setPage(data.page)
            setTitle(data.page.title)
            setVisible(data.page.visible)
            setFather(data.page.father)
            setOrder(data.page.order - 1)
            setContent(data.page.content)
            setFiles_arr(data.page.files)

            setLab(data.lab)
            setHierarchy(data.hierarchy)
          } else {
            window.location.href = '/admin/PageNotFound'
          }
        },
        (err) => console.log(err)//window.location.href = '/admin/PageNotFound'
      )

    } else if (props.match.params.lab_id) {
      //get only lab info
      getOneLab(props.match.params.lab_id, '?get_full=yes',
        (data) => {
          if (data.education_labs.length > 0){
            setLab(data.education_labs[0])
            setHierarchy(data.hierarchy)
            /*setHierarchy([
              {
                id: 1,
                units: [{
                  id: 0, title: 'Φάκελος', order: 1, chapters: []
                }, {id: 3, title: 'Unit 1', order: 2,
                  chapters: [
                    {id: 1, title: 'Κεφάλαιο 1', order: 1, pages: [{id: 4, title: 'Σελίδα 4', order: 1}]},
                    {id: 2, title: 'Κεφάλαιο 2', order: 2},
                  ]
                }]
              }
            ])*/
          } else {
            window.location.href = '/admin/LabNotFound'
          }
        },
        (err) => window.location.href = '/admin/LabNotFound'
      )
    }
  }, [props.match.params.lab_id, props.match.params.page_id])

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} >
          <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color='inherit' href={getLabMainPage(lab.lab_type || '')}>
                {getLabTypeName(lab.lab_type || '')}
              </Link>
              {lab.id &&
                <Link color='inherit' href={"/admin/EduLabFullView/"+lab.id}>{lab.title}</Link>
              }
              {page.id &&
                <Link color='inherit' href={"/admin/EduLabViewPage/"+page.id}>{page.title}</Link>
              }
              <Typography color="textPrimary">
                {page.id ? 'Επεξεργασία σελίδας' : 'Προσθήκη σελίδας'}
              </Typography>
            </Breadcrumbs>
          </div>
        </Grid>

        <Grid item xs={12} style={{ paddingTop : '20px'}}>
          <Container maxWidth='lg' className={classes.left}>
            <Typography variant='h4'>{page.id ? 'Επεξεργασία σελίδας' : 'Προσθήκη νέας σελίδας'}</Typography>

            <TextField id='title'
             error={Boolean(errors.title)}
             label='Τίτλος'
             style = {{width:500, paddingTop:10, marginTop:0}}
             value={title}
             onChange={handleTitleChange}
             />
             <FormHelperText error>{errors.title}</FormHelperText>

             <FormControlLabel control={
               <Checkbox size='small' checked={visible} onChange={handleVisibleChange} />
             } label='Ορατή σε απλούς χρήστες' />
             <br/>

             <FormLabel>Πατέρας (στην ιεραρχία)</FormLabel>
             <LabPageLevelSelectionTree element_id='father' hierarchy={hierarchy}
              defaultSelected={father.toString()} onSelect={handleFatherChange}
              page_id={page.id || ''}/>
             <FormHelperText error>{errors.father}</FormHelperText>

             <LabPageOrderSelection hierarchy={hierarchy} order={order} father={father}
              handleOrderChange={handleOrderChange} page_id={page.id || ''}/>
             <FormHelperText error>{errors.order}</FormHelperText>

             <FormLabel>Περιεχόμενο</FormLabel>
             <Editor id="content" tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
              onInit={(evt, editor) => contentHtmlEditorRef.current = editor}
              initialValue={content}
              init={{
                height: 600,
                entity_encoding : "raw",
                plugins: 'print preview paste importcss searchreplace autolink save ' +
                  'directionality code visualblocks visualchars image link media ' +
                  ' codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime ' +
                  'advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                menubar: 'file edit view insert format tools table help',
                toolbar: 'undo redo | bold italic underline strikethrough | ' +
                  'fontselect fontsizeselect formatselect | alignleft aligncenter ' +
                  'alignright alignjustify | outdent indent |  numlist bullist | ' +
                  'forecolor backcolor removeformat | pagebreak | charmap emoticons | ' +
                  ' preview print | image media link ' +
                  'anchor codesample | ltr rtl',
                // TODO: removed fullscreen from toolbar because it interferes with top black bar - see later
                //removed insertfile, template (we don't have templates), save (may bring save back if they want it)
                toolbar_mode: 'sliding',
                contextmenu: 'link image imagetools table',
                //images
                /*
                https://www.tiny.cloud/docs/configure/file-image-upload/
                https://www.tiny.cloud/docs/advanced/php-upload-handler/
                */
                images_upload_url: apiURL + 'labs_editor_image_upload',
                images_upload_handler: lab_editor_image_upload_handler,
                automatic_uploads: true,
                block_unsupported_drop: true,
                file_picker_types: 'image',
              }}
            />
           <FormHelperText error>{errors.content}</FormHelperText>
           <br/>

           <FormLabel>Επισύναψη αρχείων</FormLabel>
           <br/>

           <Container>
            {
              editingFilename && <FormLabel>Επεξεργασία περιγραφής αρχείου {editingFilename}<br/></FormLabel>
            }
             <TextField id='fileDescription'
              error={Boolean(fileErrors.fileDescription)}
              label='Περιγραφή/Τίτλος αρχείου (προαιρετικό)'
              style = {{width:500, paddingTop:10, marginTop:0}}
              value={fileDescription}
              onChange={handleFileDescriptionChange}
              />
              <FormHelperText error>{fileErrors.fileDescription}</FormHelperText>

             <input
               accept=".pdf,.doc,.docx,.odf,.rtf,.html,.xml,.ppt,.pptx,.txt,.ods,.xls,.xlsx, image/*"
               className={classes.fileinput}
               id="contained-button-filenames_arr"
               type="file"
               onChange={uploadFile}
               disabled={editingFileIdx>=0}
             />
             <label htmlFor="contained-button-filenames_arr">
               <Button variant="contained" color="primary" component="span" size='sm' disabled={editingFileIdx>=0}>
                 Ανεβασμα αρχειου
               </Button>
             </label>
             { file && file.name}
             <FormHelperText error>{fileErrors.file}</FormHelperText>
             <Button size='sm' color='primary' onClick={addOrEditFile}>Προσθήκη</Button>
             <Button size='sm' color='primary' onClick={resetFileFormFields}>Καθαρισμος</Button>
           </Container>

           <List dense>
             {files_arr.map((f, idx) =>
               <ListItem key={idx}>
                {f.name} {f.description && '(' + f.description + ')'}
                <IconButton onClick={() => prepareToEditFile(idx)}><EditIcon/></IconButton>
                {
                  f.file && f.file instanceof File &&
                  <IconButton onClick={() => removeFile(idx)}><DeleteIcon/></IconButton>
                }
               </ListItem>
             )}
           </List>

           <Button color="rose" onClick={handleSubmit} disabled={saving}>Καταχωριση</Button>
           <Button onClick={handleCancel}>Ακυρωση</Button>
          </Container>
        </Grid>
      </Grid>

      {alert}
    </div>
  )
}

export default EduLabEditPage
