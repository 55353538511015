import { fetchWithGET, fetchWithMethod, fetchWithFile } from './clientUtils.js'

function getAllLemmata(success, errorFunc){
  return fetchWithGET('lemmata', '', success, errorFunc)
}

function getOneLemma(id, success, errorFunc){
  return fetchWithGET('lemmata/' + id, '', success, errorFunc)
}

function getLemmataStartingWith(startWith, success, errorFunc){
  return fetchWithGET('lemmata_search_from_start/' + startWith, '', success, errorFunc)
}

function getLemmataForSection(section_id, success, errorFunc){
  return fetchWithGET('lemmata_for_section/' + section_id, '', success, errorFunc)
}

function addLemma(files, vals, success, errorFunc){
  return fetchWithFile('lemmata', 'POST', files, vals, success, errorFunc)
}

function editLemma(files, vals, success, errorFunc){
  return fetchWithFile('lemmata', 'PUT', files, vals, success, errorFunc)
}

function deleteLemma(vals, success, errorFunc){
  return fetchWithMethod('lemmata', 'DELETE', vals, success, errorFunc)
}

function addLemmaLink(vals, success, errorFunc){
  return fetchWithMethod('lemmata_link', 'POST', vals, success, errorFunc)
}

function editLemmaLink(vals, success, errorFunc){
  return fetchWithMethod('lemmata_link', 'PUT', vals, success, errorFunc)
}

function getAllLexiconCategories(success, errorFunc){
  return fetchWithGET('lexicon_categories', '', success, errorFunc)
}

function addLexiconCategory(vals, success, errorFunc){
  return fetchWithMethod('lexicon_categories', 'POST', vals, success, errorFunc)
}

function editLexiconCategory(vals, success, errorFunc){
  return fetchWithMethod('lexicon_categories', 'PUT', vals, success, errorFunc)
}

export { getAllLemmata, getOneLemma, getLemmataStartingWith, getLemmataForSection,
  addLemma, editLemma, deleteLemma, getAllLexiconCategories, addLexiconCategory,
  editLexiconCategory, addLemmaLink, editLemmaLink }
