import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from '@material-ui/core/styles';
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStylesAlert = makeStyles(stylesAlert);

const ConfirmDeleteMessage = (props) => {
  const classesAlert = useStylesAlert();

  const { onConfirm, onCancel, extraText } = props
  return (
    <SweetAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Επιβεβαίωση"
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
      cancelBtnCssClass={classesAlert.button + " " + classesAlert.danger}
      confirmBtnText="ΟΚ"
      cancelBtnText="Ακύρωση"
      showCancel
    >
      Είστε σίγουρος ότι θέλετε να κάνετε διαγραφή;
      {extraText && <div>{extraText}</div>}
    </SweetAlert>
  );
};

const ConfirmCancelMessage = (props) => {
  const classesAlert = useStylesAlert();

  const { onConfirm, onCancel } = props
  return (
    <SweetAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Επιβεβαίωση"
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
      cancelBtnCssClass={classesAlert.button + " " + classesAlert.danger}
      confirmBtnText="ΟΚ"
      cancelBtnText="Επιστροφη"
      showCancel
    >
      Είστε σίγουρος ότι θέλετε να κάνετε ακύρωση; Οι αλλαγές σας δε θα αποθηκευτούν
    </SweetAlert>
  );
};

const GenericConfirmMessage = (props) => {
  const classesAlert = useStylesAlert();

  const { message, onConfirm, onCancel } = props
  return (
    <SweetAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Επιβεβαίωση"
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
      cancelBtnCssClass={classesAlert.button + " " + classesAlert.danger}
      confirmBtnText="ΟΚ"
      cancelBtnText="Επιστροφη"
      showCancel
    >
      {message}
    </SweetAlert>
  );
};

export { ConfirmDeleteMessage, ConfirmCancelMessage, GenericConfirmMessage }
