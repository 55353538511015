import React, {Component} from 'react';
import './styles.css';

import DeleteIcon from '@material-ui/icons/Delete';
import Button from "components/CustomButtons/Button.js";
import FormHelperText from '@material-ui/core/FormHelperText';

export default class FileUpload extends Component {
  constructor(props) {
    super(props);
    //console.log('const')
    this.state = {
      file: this.props.file || null,
      fileError: '',
    };
  }

  componentDidUpdate(prevProps, prevState){
    const { file, fileError } = this.props;
    //console.log('update')
    if (prevProps.file !== file){
      //console.log('change')
      this.setState({file: file, fileError: ''})
    }
    if (prevProps.fileError !== fileError){
      this.setState({fileError: fileError})
    }
  }

  uploadFile = (e) => {
    if (!e.target.files[0]){
      //console.log('no file')
      return;
    }

    const allowedExtensions = /(\.pdf)$/i;
    let err = ''

    if (e.target.files[0].size > 5000000){
      err = "Πρέπει να ανεβάσετε αρχείο μέχρι 5ΜΒ"
    } else if (!allowedExtensions.exec(e.target.files[0].name)) {
      err = "Πρέπει να ανεβάσετε αρχείο pdf"
    }
    //console.log(err)

    if (err===''){
      this.props.onFileUpdate(e.target.files[0], '')
    } else {
      //console.log(this.state.file)
      this.props.onFileUpdate(this.state.file || null, err)
    }
  }

  cancelFileUpload = () => {
    //this.setState({file: null})
    this.props.onFileUpdate(null, '')
  }

  render(){
    const { disabled } = this.props

    return(
      <div>
        <input
          accept=".pdf"
          className='fileupload-fileinput'
          id="contained-button-file"
          type="file"
          onChange={this.uploadFile}
          disabled={disabled}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component='span' size='sm' disabled={disabled}>
            Ανεβασμα αρχειου
          </Button>
        </label>
        { this.state.file && this.state.file.name}
        { this.state.file && <Button size='sm' justIcon onClick={this.cancelFileUpload}><DeleteIcon/></Button>}
        <FormHelperText error>{this.state.fileError}</FormHelperText>
      </div>
    )
  }
}
