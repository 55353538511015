import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
//import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import AddCommentIcon from '@material-ui/icons/AddComment';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Tooltip from '@material-ui/core/Tooltip';

import BlogIcon from '@material-ui/icons/FormatBold';
import WikiIcon from '@material-ui/icons/Search';
import SeminarIcon from '@material-ui/icons/PeopleAlt';


import { green } from '@material-ui/core/colors';
import { blue } from '@material-ui/core/colors';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import Grid from '@material-ui/core/Grid';
import InputLabel from "@material-ui/core/InputLabel";
import TextField from '@material-ui/core/TextField';

import LyricsDisplay from 'views/Lyrics/LyricsDisplay.js'
import LyricsOriginalComments from 'views/Lyrics/LyricsOriginalComments.js'
import LyricsTranslationComments from 'views/Lyrics/LyricsTranslationComments.js'

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'
import { ConfirmDeleteMessage, GenericConfirmMessage } from 'views/CustomAlerts/ConfirmationDialog.js'
import ScholarCommentDialog from 'views/Comments/ScholarCommentDialog.js'
import TranslationNoteDialog from 'views/Comments/TranslationNoteDialog.js'

import { getOneSection, editSection, getLabsForSection, getForumsForSection, getSectionsForWork }
  from 'clients/sectionsClient.js'
import { deleteScholarComment, getComments } from 'clients/commentsClient.js'
import { getLemmataForSection } from 'clients/lexiconClient.js'
import { getTranslationNotesForSection, deleteTranslationNote } from 'clients/translationNotesClient.js'
import { sortSectionLines } from 'sorting.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },


    exampleWrapper: {
      position: 'relative',
      marginTop: theme.spacing(0),
      height: 80,
    },

    speedDial: {
      position: 'absolute',
      '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
}));


export default function MiniDrawer(props) {

  const classes = useStyles();
  //const [open, setOpen] = React.useState(false);
  const spacing = 2;
  const [editOriginal, setEditOriginal] = React.useState(false)
  const [original_text_to_edit, setOriginal_text_to_edit] = React.useState('')

  const handleOpenEditOriginalText = () => {
    //setOriginal_text_to_edit(addCommentsToText(section.original_text, section.comments_org, false))
    setOriginal_text_to_edit(section.original_text)
    setEditOriginal(true)
  }

  const handleChangeOriginalText = (e) => {
    setOriginal_text_to_edit(e.target.value)
  }

  const handleSubmitOriginalText = () => {
    const params = {id: section.id, work_id: section.work_id, book_id: section.book_id, lines: section.lines, original_text: original_text_to_edit}
    //console.log(params)
    editSection(params,
      () => {
        let newSection = section
        newSection.original_text = original_text_to_edit
        setSection(newSection)
        setEditOriginal(false)
        handleShowSuccessMessage('Η επεξεργασία έγινε με επιτυχία')
      },
      (err) => {
        showErrorAlert(err.message)
      }
    )
  }

  const handleDoneEditing = () => {
    setEditOriginal(false)
  }

  //getCaretPosition etc. for inputing comment in textarea were removed 9/6/2021

  //scholar comments
  const [openScholarCommentForm, setOpenScholarCommentForm] = React.useState(false)
  const [scholarCommentDialogTitle, setScholarCommentDialogTitle] = React.useState('')
  const [scholarComment, setScholarComment] = React.useState({})
  const [scholarComments, setScholarComments] = React.useState([])

  const handleOpenScholarCommentForm = (title, comment) => {
    setScholarComment(comment)
    setOpenScholarCommentForm(true)
    setScholarCommentDialogTitle(title)
  }

  const handleSubmitScholarComment = (comment) => {
    //console.log(comment)
    setOpenScholarCommentForm(false)
    handleShowSuccessMessage('Το σχόλιο καταχωρίστηκε με επιτυχία')

    const pos = scholarComments.findIndex(c => c.comment_id === comment.comment_id)
    /*console.log(pos)
    console.log(scholarComments)*/
    if (pos >= 0){
      let temp = [...scholarComments]
      temp[pos] = comment
      setScholarComments(temp)
    } else {
      const temp = [...scholarComments, comment]
      setScholarComments(temp)
    }
  }

  const onEditScholarComment = (comment) => {
    handleOpenScholarCommentForm('Επεξεργασία σχολίου', comment)
  }

  const onDeleteScholarComment = (id) => {
    setAlert(<ConfirmDeleteMessage onConfirm={() => handleScholarCommentDelete(id)} onCancel={hideAlert}/>)
  }

  const handleScholarCommentDelete = (id) => {
    //console.log(id)
    deleteScholarComment(id,
      () => {
        hideAlert()
        handleShowSuccessMessage('Το σχόλιο διαγράφηκε με επιτυχία')

        //remove from list
        let newComments = [...scholarComments]
        const pos = newComments.findIndex((c) => (c.comment_id===id))
        if (pos >= 0){
          newComments.splice(pos, 1)
          setScholarComments(newComments)
        }
      },
      (err) => showErrorAlert(err.message)
    )
  }

  //translation notes
  const [openTranslationNoteForm, setOpenTranslationNoteForm] = React.useState(false)
  const [translationNoteDialogTitle, setTranslationNoteDialogTitle] = React.useState('')
  const [translationNote, setTranslationNote] = React.useState({})
  const [translationNotes, setTranslationNotes] = React.useState([])

  const handleOpenTranslationNotesForm = (title, note) => {
    setTranslationNote(note)
    setTranslationNoteDialogTitle(title)
    setOpenTranslationNoteForm(true)
  }

  const onEditTranslationNote = (note) => {
    handleOpenTranslationNotesForm('Επεξεργασία σημείωσης', note)
  }

  const handleSubmitTranslationNote = (note) => {
    //console.log(note)
    setOpenTranslationNoteForm(false)
    handleShowSuccessMessage('Η σημείωση καταχωρίστηκε με επιτυχία')

    const pos = translationNotes.findIndex(tn => tn.note_id === note.note_id)
    /*console.log(pos)
    console.log(scholarComments)*/
    if (pos >= 0){
      let temp = [...translationNotes]
      temp[pos] = note
      setTranslationNotes(temp)
    } else {
      const temp = [...translationNotes, note]
      setTranslationNotes(temp)
    }
  }

  const onDeleteTranslationNote = (id) => {
    const message = 'Αν διαγράψετε μια σημείωση, θα πρέπει κατόπιν με το χέρι να ' +
                    'ενημερώσετε τη σειρά εμφάνισης των υπολοίπων, καθώς και τις παραπομπές ' +
                    'των σημειώσεων μέσα στη μετάφραση. Θέλετε σίγουρα να προχωρήσετε;'
    setAlert(<GenericConfirmMessage message={message} onConfirm={() => handleTranslationNoteDelete(id)} onCancel={hideAlert}/>)
  }

  const handleTranslationNoteDelete = (id) => {
    const params = {note_id: id, doc_id: section.id}
    //console.log(params)
    deleteTranslationNote(params,
      () => {
        hideAlert()
        handleShowSuccessMessage('Η σημείωση διαγράφηκε με επιτυχία')

        //remove from list
        let newNotes = [...translationNotes]
        const pos = newNotes.findIndex((n) => (n.note_id===id))
        if (pos >= 0){
          newNotes.splice(pos, 1)
          setTranslationNotes(newNotes)
        }
      },
      (err) => showErrorAlert(err.message)
    )
  }

  function sortSections(a, b){
    return sortSectionLines(a.lines, b.lines)
  }

  const [section, setSection] = React.useState({})
  //const [sessionID, setSessionID] = React.useState('')
  const [lemmata, setLemmata] = React.useState([])
  const [labs, setLabs] = React.useState([])
  const [forums, setForums] = React.useState([])
  const [isFirstSectionInWork, setIsFirstSectionInWork] = React.useState(false)

  React.useEffect(()=>{
    if (props.match.params.section_id){
      //console.log(props.match.params.section_id)
      //document.cookie = "sessionID=s.158059fea2b4c50e3a2dd6ea4231fe89; path=/;domain=cti.gr"

      getOneSection(props.match.params.section_id,
        (data) => {
          setSection(data.documents[0])

          //https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
          //https://github.com/ether/etherpad-lite/issues/3627
          //document.cookie = "sessionID="+data.session_id+"; domain=cti.gr; path=/; samesite=lax"
          //setSessionID(data.session_id)
        },
        (err) => window.location.href = '/admin/bookSelection'
      )

      getComments({doc_id: props.match.params.section_id},
        (data) => setScholarComments(data.comments),
        (err) => console.log(err.message)
      )

      getLemmataForSection(props.match.params.section_id,
        (data) => setLemmata(data.lemmata),
        (err) => console.log(err.message)
      )

      getTranslationNotesForSection(props.match.params.section_id,
        (data) => setTranslationNotes(data.comments),
        (err) => console.log(err.message)
      )

      getLabsForSection(props.match.params.section_id,
        (data) => setLabs(data.labs),
        (err) => console.log(err.message)
      )

      getForumsForSection(props.match.params.section_id,
        (data) => setForums(data.forums),
        (err) => console.log(err.message)
      )
    }
  }, [props.match.params.section_id])

  React.useEffect(() => {
    if (section.work_id){
      getSectionsForWork(section.work_id,
        (data) => {
          const sectionsForWork = data.sections.sort(sortSections)
          const firstSectionInWork = sectionsForWork[0]
          /*console.log(firstSectionInWork.lines)
          console.log(firstSectionInWork.id)
          console.log(section.id)*/
          setIsFirstSectionInWork(firstSectionInWork.id===section.id)
        },
        (err) => console.log(err.message)
      )
    }
  }, [section])

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };
  const showErrorAlert = (msg) => {
    setAlert(<ErrorAlert message={msg} hideAlert={hideAlert}/>)
  }

  //Snackbar messages (to inform user of success)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const handleShowSuccessMessage = (message) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message)
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  /*let groupID = ''
  let padName = ''
  if (section && section.translation_url){
    [groupID, padName] = section.translation_url.split('$')
    console.log(section.translation_url)
    console.log(sessionID)
    console.log(groupID)
    console.log(padName)
  }*/

  const forumsToShow = isFirstSectionInWork ?
    forums.filter(f => !f.section || f.section===section.id) :
    forums.filter(f => f.section===section.id)
  //console.log(forumsToShow)

  return (

    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} >
        <div>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" href="/admin/SelectTransSem">
              Main
            </Link>
            <Link color="inherit" href="/admin/bookSelection">
              Αριστοτελικά έργα, Μεταφράσεις, Σχόλια
            </Link>
            <Typography color="textPrimary">{section.work_name}</Typography>
            <Link color="inherit" href={"/admin/SelectLyrics/"+section.work_id+'-'+section.book_id}>
              {section.book_name || ''}
            </Link>
            <Typography color="textPrimary">Στίχοι {section.lines}</Typography>

        </Breadcrumbs>
        </div>
      </Grid>
     <Grid item xs={12} style={{ paddingTop : '20px'}}>
       <Grid container justify="center" spacing={spacing}>

           <Grid className={classes.root}>{/*// TODO: Here maybe change the size, so it doesn't take up the entire page, just a maximum*/}
               <Grid container spacing={2}>
                 <Grid item xs={12} sm container>
                   <Grid item xs container direction="column" spacing={2}>
                     <Grid item xs>

                     <Card id="mainTextsViewer">
                       <CardHeader className="AristosCardHeader">
                          Μετάφραση αρχαίου κειμένου (Μεταφραστής: {section.translator})
                       </CardHeader>
                       <CardBody>
                      <GridContainer spacing={2}>
                            <GridItem xs={12} sm={12} md={6}>
                                <InputLabel style={{ color: "#AAAAAA", paddingBottom:"20px", marginTop:"20px" }}>Αρχαίο κείμενο</InputLabel>
                                {
                                  access_rights.original_text && access_rights.original_text.w.includes(section.work_id) &&
                                  <Button justIcon round color="primary" onClick={handleOpenEditOriginalText} disabled={editOriginal}><EditIcon/></Button>
                                }

                                {
                                  editOriginal ?
                                  <>
                                    <TextField id='original_text_edit' variant='outlined'  value={original_text_to_edit} multiline fullWidth onChange={handleChangeOriginalText}/>
                                    <Button justIcon round color="primary" onClick={handleSubmitOriginalText}><SaveIcon/></Button>
                                    <Button justIcon round color="primary" onClick={handleDoneEditing}><CancelIcon/></Button>
                                  </> :
                                  <>
                                    <LyricsDisplay text={section.original_text}/>
                                    {
                                      access_rights.original_text && access_rights.original_text.w.includes(section.work_id) &&
                                      <Tooltip title='Εισαγωγή σχολίου'>
                                        <Button color="primary" justIcon round onClick={() => handleOpenScholarCommentForm('Εισαγωγή σχολίου', {})}><AddCommentIcon/></Button>
                                      </Tooltip>
                                    }
                                  </>
                                }
                             </GridItem>

                             <GridItem xs={12} sm={12} md={6}>
                               <InputLabel style={{ color: "#AAAAAA", paddingBottom:"20px", marginTop:"20px" }}>Μετάφραση</InputLabel>
                               {/*<iframe src="http://localhost:9001/p/6bnDW35LLYg8TsaGFsXxdfhdfhdfhhfdghdfh?UserName=Kouzelis" height="510" width="100%" title="Iframe Example"></iframe>*/}
                               {section.translation_url ?
                                 <>
                                    <iframe title='translation_editor' name="embed_readonly" src={"https://aristotelistes.cti.gr/pad/p/"+section.translation_url+"?showControls=true&showChat=true&showLineNumbers=true&useMonospaceFont=false"} width="100%" height="600" frameBorder="0"></iframe>
                                    <Tooltip title='Εισαγωγή σημείωσης'>
                                      <Button color="primary" justIcon round onClick={() => handleOpenTranslationNotesForm('Εισαγωγή σημείωσης', {})}><AddCommentIcon/></Button>
                                    </Tooltip>
                                  </>
                                 :
                                 <Typography color='error'>Για να είναι διαθέσιμη η επεξεργασία μετάφρασης, πρέπει πρώτα να <Link href='/admin/AddTranslation'>ανεβάσετε τη μετάφραση από αρχείο</Link></Typography>
                                }
                               {/*<iframe name="embed_readonly" src={"http://aristotelistes.cti.gr/pad/auth_session?sessionID="+sessionID+"&groupID="+groupID+"&padName="+padName} width="100%" height="600" frameBorder="0"></iframe>*/}

                             </GridItem>

							</GridContainer>
                             </CardBody>
                        </Card>
                        <Card id="belowMainTextsViewer">
                             <CardBody>
                             <GridContainer>

                             <GridItem xs={12} sm={12} md={6}>
                                <LyricsOriginalComments scholarComments={scholarComments} lemmata={lemmata} labs={labs}
                                  onEditScholarComment={onEditScholarComment} onDeleteScholarComment={onDeleteScholarComment}
                                  edit={access_rights.original_text && access_rights.original_text.w.includes(section.work_id)}
                                  forums={forumsToShow.filter(f => f.originalOrTranslation.includes('original'))}
                                  />
                             </GridItem>
                             <GridItem xs={12} sm={12} md={6}>
                                <LyricsTranslationComments translationNotes={translationNotes} onEditTranslationNote={onEditTranslationNote}
                                  edit={access_rights.original_text && access_rights.original_text.w.includes(section.work_id)}
                                  forums={forumsToShow.filter(f => f.originalOrTranslation.includes('translation'))}
                                  onDeleteTranslationNote={onDeleteTranslationNote}
                                  />
                             </GridItem>
                           </GridContainer>
                       </CardBody>
                     </Card>



                     </Grid>

                   </Grid>

                 </Grid>

               </Grid>
           </Grid>
       </Grid>
     </Grid>
    </Grid>

    {alert}

    <ScholarCommentDialog open={openScholarCommentForm} dialogTitle={scholarCommentDialogTitle}
        handleSubmit={handleSubmitScholarComment} handleClose={() => setOpenScholarCommentForm(false)}
        handleError={showErrorAlert} section_id={section.id}
        isEdit={Boolean(scholarComment.id)} comment={scholarComment}/>

    <TranslationNoteDialog open={openTranslationNoteForm} dialogTitle={translationNoteDialogTitle}
      handleSubmit={handleSubmitTranslationNote} handleClose={() => setOpenTranslationNoteForm(false)}
      handleError={showErrorAlert} section_id={section.id} note={translationNote}/>

    <Snackbar anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={openSnackbar}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
      >
      <Alert onClose={handleCloseSnackbar} severity="success">
        {snackbarMessage}
      </Alert>
    </Snackbar>
    </div>

  );
}
