const getLabMainPage = (lab_type) => {
  switch(lab_type){
    case "edu":
      return "/admin/EducationalLabs"

    case "tour":
      return "/admin/TouringLabs"

    case "summer":
      return "/admin/SummerSchools"

    default:
      return "/admin/SelectTransSem"
  }
}

const getLabTypeName = (lab_type) => {
  switch(lab_type){
    case "edu":
      return 'Εκπαιδευτικά-περιηγητικά εργαστήρια'

    case "tour":
      return 'Περιηγητικά εργαστήρια'

    case "summer":
      return 'Summer Schools'

    default:
      return ''
  }
}

const getAccessRightsFieldForLabType = (lab_type) => {
  switch(lab_type){
    case "edu":
      return 'labs_edu'

    case "tour":
      return 'labs_tour'

    case "summer":
      return 'labs_summer'

    default:
      return ''
  }
}

const calcLabRating = (lab) => {
  let ratingFromAttendants = null
  if (lab.ratings && lab.ratings.length > 0){
    const sum = lab.ratings.map(r => r.rating).reduce((total, num) => total + num)
    ratingFromAttendants = Number((sum / lab.ratings.length).toFixed(2))
  }
  //console.log(ratingFromAttendants)

  const ratingFromCopy = (lab.ratingAvg && lab.ratingAvg > 0) ? lab.ratingAvg : null
  //console.log(ratingFromCopy)

  if (!ratingFromAttendants && !ratingFromCopy){
    return null
  } else if (!ratingFromCopy){
    return ratingFromAttendants
  } else if (!ratingFromAttendants){
    return ratingFromCopy
  } else {
    return Number(((ratingFromCopy + ratingFromAttendants) / 2).toFixed(2))
  }
}


export { getLabMainPage, getLabTypeName, getAccessRightsFieldForLabType, calcLabRating }
