import React from 'react'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import DescriptionIcon from '@material-ui/icons/Description';

import { truncateText } from 'utils.js'

const parse = require('html-react-parser');

const TextMaterialEditList = (props) => {
  const { textList, textType, prepareToEditText, removeText } = props

  return (
    <List dense>
    {textList.map((text, idx) =>
      <ListItem key={idx}>
        <Typography variant='body1'>{text.title}</Typography>
        <IconButton aria-label="edit" onClick={() => prepareToEditText(text, idx, textType)}>
          <EditIcon fontSize="small"/>
        </IconButton>
        <IconButton aria-label="delete" onClick={() => removeText(idx, textType)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
        <Typography variant='body2' component='div'>{parse(truncateText(text.text, 40))}</Typography>
      </ListItem>
    )}
    </List>
  )
}

const FileMaterialEditList = (props) => {
  const { fileList , fileType, prepareToEditFile, prepareToEditUpload, removeFile } = props

  const prepareToEdit = (file, idx) => {
    if (file.file instanceof File || file.file === ''){
      prepareToEditUpload(file, idx, fileType)
    } else {
      prepareToEditFile(file, idx, fileType)
    }
  }

  return(
    <List dense>
    {fileList.map((file, idx) =>
      <ListItem key={idx}>
        {file.file instanceof File || file.file === '' ?
          <Typography>{file.title} ({file.file.name || file.filename})</Typography> :
          <Link href={file.file}>{file.title}</Link>
        }
        <IconButton aria-label="edit" onClick={() => prepareToEdit(file, idx)}>
          <EditIcon fontSize="small"/>
        </IconButton>
        <IconButton aria-label="delete" onClick={() => removeFile(idx, fileType)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </ListItem>
    )}
    </List>
  )
}

const TextMaterialViewList = (props) => {
  const { textList } = props

  return (
    <>
    {
      textList &&
      textList.map((mt, idx) => <div key={idx}>
          <Typography variant="h6" component='div'>{mt.title}</Typography>
          <Typography variant="body1" component='div'>{parse(mt.text)}</Typography>
        </div>)
    }
    </>
  )
}

const FileMaterialViewList = (props) => {
  const { fileList, fileUploadFolder } = props

  return(
    <>
    {
      fileList &&
      fileList.map((mf, idx) => <p key={idx}>
          {mf.title}: <Link href={mf.file || fileUploadFolder+'/'+mf.filename}><DescriptionIcon/></Link>
        </p>)
    }
    </>
  )
}

export { TextMaterialEditList, FileMaterialEditList, TextMaterialViewList, FileMaterialViewList }
