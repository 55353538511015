const apiURL = "http://aristotelistes.cti.gr/bridge/api/";
const streamURL = "http://aristotelistes.cti.gr/bridge/stream";
/*const apiURL = "http://localhost:5000/api/";
const streamURL = "http://localhost:5000/stream";*/
const editorImagesURL = "/files/"
const labsTRImagesURL = "/files/labsTR/"
const labsEduImagesURL = "/files/labsEdu/"
const forumImagesURL = "/files/forums/"
const eduSourcesFilesURL = "/files/eduSources/"
const lexiconImagesURL = "/files/lexicon/"

export {apiURL, streamURL, editorImagesURL, labsTRImagesURL, labsEduImagesURL,
  forumImagesURL, eduSourcesFilesURL, lexiconImagesURL}
