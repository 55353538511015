import React from 'react';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Assignment from "@material-ui/icons/Assignment";

import { getOneSection, uploadSection, editSection, deleteSection } from "clients/sectionsClient.js"
import { SectionActions } from "./AdminErgBooksHelpers.js"
import { AddSectionDialog, EditSectionDialog } from "./AdminErgBookDialogForms.js"

import { checkNoErrors } from "utils.js"
import { getValidationErrorsForLyrics } from 'views/AddLyricsItem/AddLyricsForm.js'
import { getValidationErrorsForLyricsEdit } from './EditLyricsForm.js'

const SectionsTab = (props) => {

  const constructSectionDataRow = (section) => {
    return {
      id: section.id,
      work_name: section.work_name,
      book_name: section.book_name,
      section: section.lines,
      actions: (
        // we've added some custom button actions
        <SectionActions section={section} handleClickEditSection={handleClickEditSection} handleClickDeleteSection={handleClickDeleteSection}/>
      )
    };
  }

  /*const findWorkAndBookNameFromIds = (work_id, book_id) => {
    const work = Worksdata.find((o) => o.id === Number(work_id))
    const book = work.books.find((o) => o.id === Number(book_id))

    return {work_name: work.name, book_name: book.name}
  }*/

  const handleClickOpenSection = (dialogTitle, dialogButtonLabel) => {
    setDialogTitle(dialogTitle);
    setDialogButtonLabel(dialogButtonLabel);
    setOpenSection(true);
  };

  const handleCloseSection = () => {
    setOpenSection(false);
    setSectionId('')
    /*setSectionLines('')
    setSectionText('')*/
    setFile(null)
    setBookId(0)
    setErrorsSection({})
  };

  const handleCloseEdit = () => {
    setOpenEdit(false)
    setSectionId('')
    setErrorsSection({})
  }

  const handleClickEditSection = (id) => {
    //console.log(id)
    getOneSection(id,
      (data) => {
        const section = data.documents[0]
        setSectionId(id)
        setSectionLines(section.lines)
        setSectionText(section.original_text ? section.original_text : '')
        setOpenEdit(true)
      },
      (err) => {showErrorAlert(err.message)}
    )
  }

  const handleAddSection = () => {
    const newErrors = sectionId ?
        getValidationErrorsForLyricsEdit(sectionLines, sectionText) :
        getValidationErrorsForLyrics(bookId, file)
    setErrorsSection(newErrors)
    //console.log(newErrors)

    if (checkNoErrors(newErrors)){
      //console.log('validation passed')
      setDisableSubmit(true)

      if (sectionId){
        //edit existing section
        const [work_id, book_id] = sectionId.split('-')
        const params = {work_id: parseInt(work_id), book_id: parseInt(book_id), id: sectionId, lines: sectionLines, original_text: sectionText}
        //console.log(params)

        editSection(params,
          (data) => {
            //show changes in Table
            let newData = Sectionsdata;
            const changeIndex = newData.findIndex((o) => (o.id === sectionId))
            const originalSection = newData[changeIndex]
            newData[changeIndex] = {id: sectionId, work_name: originalSection.work_name, book_name: originalSection.book_name, lines: sectionLines}
            //console.log(newData)
            updateSectionsData(newData);
            handleCloseEdit()
            handleShowSuccessMessage('H καταχώριση έγινε με επιτυχία!')
            setDisableSubmit(false)
          },
          (err) => {
            showErrorAlert(err.message);
            setDisableSubmit(false)
          }
        )
      } else {
        //add new section
        const [work_id, book_id] = bookId ? bookId.split('-') : ['', '']
        const params = {work_id: work_id, book_id: book_id}
        //console.log(params)

        uploadSection(file, params,
          (data) => {
            handleCloseSection();
            //show messages
            if (data.message===''){
              handleShowSuccessMessage('H καταχώριση έγινε με επιτυχία!')
            } else {
              const message = 'Η καταχώριση έγινε με επιτυχία, με εξαίρεση τα εξής:' + data.message
              showWarningAlert(message)
            }
            setDisableSubmit(false)
            props.onAddSection();
          },
          (err) => {
            showErrorAlert(err.message)
            setDisableSubmit(false)
           }
        )
      }
    }
  }

  const handleClickDeleteSection = (id) => {
    showConfirmDelete(id, handleDeleteSection)
  }

  const handleDeleteSection = (id) => {
    //console.log('deleting'+id)
    const params = {id: id}

    deleteSection(params,
      () => {
        let newData = Sectionsdata;
        const pos = newData.findIndex(o => o.id === id);
        //console.log(pos+' '+id)
        if (pos >= 0){
          newData.splice(pos, 1)
          //console.log(newData)
          updateSectionsData([...newData]);
        }
        handleShowSuccessMessage('H διαγραφή έγινε με επιτυχία!')
      },
      (err) => showErrorAlert(err.message)
    )
  }

  const handleSelectBook = (event, nodeIds) => {
    //console.log(nodeIds);
    setBookId(nodeIds)
  };

  const handleFileChange = (f) => {
    setFile(f)
  }

  const handleLinesChange = (e) => {
    setSectionLines(e.target.value)
  }

  const handleTextChange = (e) => {
    setSectionText(e.target.value)
  }

  const { Sectionsdata, Worksdata, updateSectionsData, handleShowSuccessMessage,
    showErrorAlert, showWarningAlert, showConfirmDelete, classes } = props
  const SectionsdataFormatted = Sectionsdata.map(section => constructSectionDataRow(section))

  const [sectionId, setSectionId] = React.useState('')
  const [sectionLines, setSectionLines] = React.useState('')
  const [sectionText, setSectionText] = React.useState('')
  const [file, setFile] = React.useState(null)
  const [bookId, setBookId] = React.useState(0)
  const [errorsSection, setErrorsSection] = React.useState({})
  const [openSection, setOpenSection] = React.useState(false)
  const [openEdit, setOpenEdit] = React.useState(false)
  const [disableSubmit, setDisableSubmit] = React.useState(false)

  const [dialogTitle, setDialogTitle] = React.useState('')
  const [dialogButtonLabel, setDialogButtonLabel] = React.useState('')

  return (
    <>
      <Card className={classes.cardFix}>
        <CardHeader className="AristosCardHeader" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4>Χωρία</h4>
        </CardHeader>
        <CardBody>
          <ReactTable
            columns={[
              {
                Header: "Έργο",
                accessor: "work_name"
              },
              {
                Header: "Βιβλίο",
                accessor: "book_name"
              },
              {
                Header: "Χωρίo",
                accessor: "section"
              },

              {
                Header: "Actions",
                accessor: "actions"
              }
            ]}
            data={SectionsdataFormatted}
          />

          <Button className={classes.buttonAdd} onClick={() => handleClickOpenSection('Προσθηκη Χωριων', 'Προσθηκη')}>Προσθηκη Χωριων</Button>

        </CardBody>
      </Card>

      <AddSectionDialog
        handleClose={handleCloseSection}
        handleAdd={handleAddSection}
        dialogTitle={dialogTitle}
        dialogButtonLabel={dialogButtonLabel}
        open={openSection}
        sectionId={sectionId}
        works={Worksdata}
        handleSelectBook={handleSelectBook}
        handleFileChange={handleFileChange}
        errors={errorsSection}
        disableSubmit={disableSubmit}
      />

      <EditSectionDialog
        handleClose={handleCloseEdit}
        handleEdit={handleAddSection}
        open={openEdit}
        handleLinesChange={handleLinesChange}
        handleTextChange={handleTextChange}
        lines={sectionLines}
        original_text={sectionText}
        errors={errorsSection}
        disableSubmit={disableSubmit}
        />
    </>
  )
}

export default SectionsTab
