import {
  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  CREATE_POST_FAILURE,
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILURE,
  //EDIT_POST_START,
  EDIT_POST_REQUEST,
  EDIT_POST_SUCCESS,
  EDIT_POST_FAILURE,
  EDIT_POST_CANCEL,
  RESTORE_POST_REQUEST,
  RESTORE_POST_SUCCESS,
  RESTORE_POST_FAILURE,
} from './types';
import {createPostApi, editPostApi, fetchThreadApi, deletePostApi,
  restorePostApi, likePostApi,
} from '../api';
import {fetchThreadSuccess, fetchThreadFailure, changeThreadPage,
  likeThreadPostRequest, likeThreadPostSuccess, likeThreadPostFailure} from './thread';
import {apiErrorHandler} from '../utils/errorhandler';
import { postPageSize } from '../utils/config.js'

export const createPost = (newPost, newFile) => dispatch => {
  dispatch(createPostRequest());

  createPostApi(newPost, newFile,
    (response) => {
      dispatch(createPostSuccess());

      // re-load thread page
      fetchThreadApi(newPost.thread_id,
        (response) => {
          dispatch(fetchThreadSuccess(response));
          //go to last page - that is where the post will appear
          const totalPostsWithThreadPost = response.thread.posts ? response.thread.posts.length + 1 : 1
          const lastPage = Math.ceil(totalPostsWithThreadPost/postPageSize)
          //console.log(lastPage)
          dispatch(changeThreadPage(lastPage))
        },
        (error) => {
          const errorMessage = apiErrorHandler(error);
          dispatch(fetchThreadFailure(errorMessage));
        });
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(createPostFailure(errorMessage));
    });
};

export const createPostRequest = () => {
  return {
    type: CREATE_POST_REQUEST,
  };
};

export const createPostSuccess = () => {
  return {
    type: CREATE_POST_SUCCESS,
  };
};

export const createPostFailure = error => {
  return {
    type: CREATE_POST_FAILURE,
    error,
  };
};

export const deletePost = (id, threadID) => dispatch => {
  dispatch(deletePostRequest(id));

  deletePostApi(id, threadID,
    (response) => {
      dispatch(deletePostSuccess(id));

      // re-load thread page
      fetchThreadApi(threadID,
        (response) => {
          dispatch(fetchThreadSuccess(response));
        },
        (error) => {
          const errorMessage = apiErrorHandler(error);
          dispatch(fetchThreadFailure(errorMessage));
        });
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(deletePostFailure(id, errorMessage));
    });
};

export const deletePostRequest = id => {
  return {
    type: DELETE_POST_REQUEST,
    id,
  };
};

export const deletePostSuccess = id => {
  return {
    type: DELETE_POST_SUCCESS,
    id,
  };
};

export const deletePostFailure = (id, error) => {
  return {
    type: DELETE_POST_FAILURE,
    id,
    error,
  };
};

export const editPost = (newPost, newFile) => dispatch => {
  dispatch(editPostRequest());

  editPostApi(newPost, newFile,
    (response) => {
      dispatch(editPostSuccess());

      // re-load thread page
      fetchThreadApi(newPost.thread_id,
        (data) => {
          dispatch(fetchThreadSuccess(data));
        },
        (error) => {
          const errorMessage = apiErrorHandler(error);
          dispatch(fetchThreadFailure(errorMessage));
        });
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(editPostFailure(errorMessage));
    });
};

export const editPostRequest = () => {
  return {
    type: EDIT_POST_REQUEST,
  };
};

export const editPostSuccess = () => {
  return {
    type: EDIT_POST_SUCCESS,
  };
};

export const editPostFailure = error => {
  return {
    type: EDIT_POST_FAILURE,
    error,
  };
};

export const editPostCancel = () => {
  return {
    type: EDIT_POST_CANCEL,
  }
}

/*export const editPostStart = () => {
  return {
    type: EDIT_POST_START,
  }
}*/
export const restorePost = (id, threadID) => dispatch => {
  dispatch(restorePostRequest(id));

  restorePostApi(id, threadID,
    (response) => {
      dispatch(restorePostSuccess(id));

      // re-load thread page
      fetchThreadApi(threadID,
        (response) => {
          dispatch(fetchThreadSuccess(response));
        },
        (error) => {
          const errorMessage = apiErrorHandler(error);
          dispatch(fetchThreadFailure(errorMessage));
        });
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(restorePostFailure(id, errorMessage));
    });
};

export const restorePostRequest = id => {
  return {
    type: RESTORE_POST_REQUEST,
    id,
  };
};

export const restorePostSuccess = id => {
  return {
    type: RESTORE_POST_SUCCESS,
    id,
  };
};

export const restorePostFailure = (id, error) => {
  return {
    type: RESTORE_POST_FAILURE,
    id,
    error,
  };
};

export const likePost = (params) => dispatch => {
  dispatch(likeThreadPostRequest(params.id))

  likePostApi(params,
    (response) => {
      //console.log('success')
      dispatch(likeThreadPostSuccess())

      fetchThreadApi(params.thread_id,
        (response) => {
          //console.log('refetched')
          dispatch(fetchThreadSuccess(response));
        },
        (error) => {
          const errorMessage = apiErrorHandler(error);
          dispatch(fetchThreadFailure(errorMessage));
        });
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(likeThreadPostFailure(errorMessage));
    });
}
