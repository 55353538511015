import React, {Component} from 'react';
import Link from '@material-ui/core/Link';
import {Segment, Grid} from 'semantic-ui-react';
import StatusMessage from '../statusmessage';
import './styles.css';
import {searchResultsPageSize } from '../../utils/config.js'
//import { filterVisible } from '../../utils/utils.js'*/

import Pagination from '@material-ui/lab/Pagination';
import LockIcon from '@material-ui/icons/Lock';
import FolderIcon from '@material-ui/icons/Folder';

export default class SearchResults extends Component {
  state = {
    //showHidden: false,
  }

  render() {
    const {isLoading, error, results, page, handleChangePage} = this.props;

    if (error || !results || isLoading || results.length === 0) {
      return (
        <StatusMessage
          error={error || !results}
          errorClassName="search-error"
          errorMessage={error}
          loading={isLoading}
          loadingMessage={`Περιμένετε όσο γίνεται φόρτωση αποτελεσμάτων`}
          nothing={results && results.length === 0}
          nothingMessage={`Δεν υπάρχουν αποτελέσματα`}
          nothingClassName="search-error"
          type="default"
        />
      );
    }

    const pageStart = (page - 1) * searchResultsPageSize
    const pageEnd = pageStart + searchResultsPageSize
    const totalResults = results.length || 0

    //const authenticatedUser = localStorage.getItem('UserId')

    const headerRow = (
      <Segment vertical className="grammi-titlos">
        <Grid textAlign="left" padded="horizontally">
          <Grid.Column width={6}>
            <Grid.Row>
              Κατηγορία
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={2}>
            <Grid.Row>
              <div className="search-row">
                <div className="search-column">
                  <div>
                    Συζήτηση
                  </div>
                </div>
              </div>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={2}>
            <Grid.Row>
              <div className="search-row">
                <div className="search-column">
                  <div>
                    Ανάρτηση
                  </div>
                </div>
              </div>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </Segment>
    )

    const resultsList = (results.length===0) ?
      <StatusMessage
        error={false}
        errorClassName="search-error"
        nothing={true}
        nothingMessage={`Δεν υπάρχουν αποτελέσματα`}
        nothingClassName="search-error"
        type="default"
      />
      :
      <>
        {headerRow}
        {
          results.slice(pageStart, pageEnd).map((res, idx) => {
          let {
            forum, thread, post,
          } = res;

          const forumName = forum.name.length > 57 ? forum.name.substring(0, 55) + '...' : forum.name;

          return (
            <Segment vertical key={idx}>
              <Grid textAlign="left" padded="horizontally">
                <Grid.Column width={6}>
                  <Grid.Row>
                    <FolderIcon/>
                    {
                      forum.isPrivate && <LockIcon/>
                    }
                    <Link href={`/admin/Forum/${forum.id}`}>{forumName}</Link>
                    <Grid.Row>{forum.description}</Grid.Row>
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Grid.Row>
                    <Link href={`/admin/Thread/${forum.id}-${thread.id}`}>{thread.name}</Link>
                  </Grid.Row>
                  {
                    !post &&
                    <Grid.Row>
                      <div className="search-meta">
                        {thread.user_fullname}
                        <b>{`  —  ${thread.date}`}</b>
                      </div>
                    </Grid.Row>
                  }
                </Grid.Column>
                <Grid.Column width={2}>
                  <Grid.Row>
                    <div className="search-meta">
                    {
                      post ?
                      <>
                        {post.user_fullname}
                        <b>{`  —  ${post.date}`}</b>
                      </>
                      :
                      <> - </>
                    }
                    </div>
                  </Grid.Row>
                </Grid.Column>
              </Grid>
            </Segment>
          )
        })
      }
    </>

    return (
      <div className="searchContainer">
        <Segment.Group className="search-list">{resultsList}</Segment.Group>
        <Pagination count={Math.ceil(totalResults/searchResultsPageSize)} color="primary" page={page} onChange={handleChangePage}/>
      </div>
    );
  }
}
