import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'

import Button from "components/CustomButtons/Button.js";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import Checkbox from '@material-ui/core/Checkbox';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from '@material-ui/core/InputLabel';

import { GenericConfirmMessage } from 'views/CustomAlerts/ConfirmationDialog.js'

import { checkNoErrors } from 'utils.js'

const useStyles = makeStyles((theme) => ({
  formElement: {
    width:500,
    paddingTop:10,
    marginTop:0
  },
  emptyElement: {
    color: '#808080',
    fontStyle: 'italic'
  }
}))

const NewForumsMass = (props) => {
  const classes = useStyles()
  const { open, handleClose, onSubmit, isLoading, fatherWork, fatherName,
    allWorks, father } = props
  //console.log(forum)

  const [work, setWork] = React.useState('')
  const [originalOrTranslation, setOriginalOrTranslation] = React.useState([])
  const [errors, setErrors] = React.useState({})

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  const handleWorkChange = (e) => {
    setErrors({...errors, work: validate(work, e.target.value)})
    setWork(e.target.value)
  }

  const handleOriginalOrTranslationChange = (e) => {
    let newValue;
    if (e.target.checked){
      newValue = [...originalOrTranslation, e.target.name]
    } else {
      newValue = originalOrTranslation.filter(val => val!==e.target.name)
    }
    //console.log(newValue)
    setErrors({...errors, originalOrTranslation: validate('originalOrTranslation', newValue)})
    setOriginalOrTranslation(newValue)
  }

  const validate = (field, value) => {
    switch (field) {
      case 'work':
        return !value ? 'Υποχρεωτικό πεδίο' : ''

      case 'originalOrTranslation':
        return value.length === 0 ? 'Υποχρεωτική επιλογή τουλάχιστον ενός' : ''

      default:
        return ''
    }
  }

  const confirmSubmit = () => {
    let newErrors = {...errors}
    newErrors.work = validate('work', work)
    newErrors.originalOrTranslation = validate('originalOrTranslation', originalOrTranslation)
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      const newforum = {work: work, father: father, originalOrTranslation: originalOrTranslation}
      //console.log(newforum)
      const workName = allWorks.filter(w => w.id===work).map(w => w.name).toString()
      //console.log(workName)
      const message = 'Θα δημιουργήσετε κατηγορίες συζητήσεων κάτω από την κατηγορία "' + fatherName +
        '", για όλα τα χωρία του έργου "' + workName + '", που αφορούν "' +
        pertinentAreasText() + '". Είστε σίγουρος/η;'
      setAlert(<GenericConfirmMessage message={message} onConfirm={() => handleSubmit(newforum)} onCancel={hideAlert}/>)
    }
  }

  const handleSubmit = (newforum) => {
    hideAlert()
    onSubmit(newforum)
  }

  const pertinentAreasText = () => {
    let areas = []
    if (originalOrTranslation.includes('original')){
      areas.push('Αρχαίο κείμενο')
    }
    if (originalOrTranslation.includes('translation')){
      areas.push('Μετάφραση')
    }
    return areas.join(', ')
  }

  //get forum previous values if it is an edit
  React.useEffect(() => {
    setWork(fatherWork || '')
  }, [fatherWork])

  return(
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}
      maxWidth={"sm"}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Μαζική δημιουργία κατηγοριών συζητήσεων για χωρία έργου
        </DialogTitle>

        <DialogContent dividers>
          <FormControl className={classes.formElement} error={Boolean(errors.work)}>
            <InputLabel htmlFor="work-select">Έργο</InputLabel>
            <Select id="work-select"
              value={work}
              onChange={handleWorkChange}>
              {
                /*<MenuItem value='' className={classes.emptyElement}>Κανένα</MenuItem>*/
                allWorks.map(w =>
                  <MenuItem key={w.id} value={w.id}>{w.name}</MenuItem>
                )
              }
            </Select>
            <FormHelperText error>{errors.work}</FormHelperText>
          </FormControl>

          <FormControl className={classes.formElement} variant="standard"
            error={Boolean(errors.originalOrTranslation)}>
            <FormLabel>Αφορά</FormLabel>
            <FormGroup id="origOrTrans-group" row>
              <FormControlLabel
                control={
                  <Checkbox size='small' checked={originalOrTranslation.includes('original')}
                    onChange={handleOriginalOrTranslationChange} name="original"/>
                }
                label="Αρχαίο κείμενο"
              />
              <FormControlLabel
                control={
                  <Checkbox size='small' checked={originalOrTranslation.includes('translation')}
                    onChange={handleOriginalOrTranslationChange} name="translation"/>
                }
                label="Μετάφραση"
              />
            </FormGroup>
            <FormHelperText error>{errors.originalOrTranslation}</FormHelperText>
          </FormControl>

          {alert}

        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={confirmSubmit} color="primary" disabled={isLoading}>
            Καταχωριση
          </Button>
        </DialogActions>
    </Dialog>
  )
}

export default NewForumsMass
