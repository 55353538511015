import { sortSectionLines, sortWorksAndBooks } from 'sorting.js'

export default function transformSectionsForTree(documents){
  let result = []

  documents.forEach((d) => {
    const posWork = result.findIndex((r) => (r.id===d.work_id))
    let work = (posWork === -1)  ?
      {id: d.work_id, name: d.work_name} :
      result[posWork]

    const books = work.books || []
    const posBook = books.findIndex((b) => (b.id===d.book_id))
    let book = (posBook === -1) ?
      {id: d.book_id, name: d.book_name, sections: []} :
      books[posBook]

    const section = {id: d.id, lines: d.lines}
    book.sections.push(section)
    book.sections.sort(sortSections)

    if (posBook === -1){
      books.push(book)
    } else {
      books[posBook] = book
    }
    work.books = books

    if (posWork === -1){
      result.push(work)
    } else {
      result[posWork] = work
    }

  })
  return sortWorksAndBooks(result)
}

function sortSections(a, b){
  return sortSectionLines(a.lines, b.lines)
}
