import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { keywordsAutocompleteApi } from '../../api';

const KeywordsInput = (props) => {
  const { keywords, keywordInput, handleValueChange, handleInputChange, error } = props

  const [keywordList, setKeywordList] = React.useState([])

  React.useEffect(() => {
    const last_keyword = keywordInput ? keywordInput.trim() : ''

    if (last_keyword.length > 0){
      //console.log('search for '+last_keyword)
      keywordsAutocompleteApi(last_keyword,
        (data) => setKeywordList(data.list),
        (err) => console.log(err)
      )
    } else {
      setKeywordList([])
    }
  }, [keywordInput])

  return(
    <>
      <Autocomplete
        id="keyword-search"
        options={keywordList}
        autoComplete freeSolo
        multiple
        value={keywords}
        inputValue={keywordInput}
        onChange={handleValueChange}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            error={Boolean(error)}
            label='Λέξεις-κλειδιά'
            placeholder="Πατήστε Enter για να εισάγετε νέα λέξη"
          />
        )}
      />
      <FormHelperText error>{error}</FormHelperText>
    </>
  )
}

export default KeywordsInput
