import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Sidebar from "components/Sidebar/Sidebar.js";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ButtonBase from '@material-ui/core/ButtonBase';

import TextsImg from "images/1_texts.jpg";
import ConcordanceImg from "images/2_pinakas-lexeon.jpg";
import LexiconImg from "images/3_lexico.jpg";
import ForumImg from 'images/4_forum.jpg';
import ErgMetImg from 'images/5_ERG-met.jpg'
import ErgEkpPerImg from 'images/6_ERG-ekp.jpg'
//import SchoolsImg from 'images/7_ERG-summer.jpg'
import EditionsImg from 'images/8_ekdoseis.jpg'
//import SignupImg from 'images/9_signup.jpg'
import EduSourcesImg from 'images/8_poroi.jpg'
import aristosIcon from "images/aristos.svg";

const images = [
  {
    url: "url(" + TextsImg + ")",
    title: 'Αριστοτελικά έργα, Μεταφράσεις, Σχόλια',
    subtitle: '1_Δείτε το σύνολο των διαθέσιμων έργων του Αριστοτέλη μαζί με μεταφράσεις και σχόλια των μελών της πλατφόρμας',
    width: '250px',
    redirect: "./bookSelection"
  },
  {
    url: "url(" + ConcordanceImg + ")",
    title: 'Αναζητήσεις λέξεων στα αρχαία έργα',
    subtitle: '1_Αναζητήστε στο σύνολο των έργων του Αριστοτέλη',
    width: '250px',
    redirect: "./Concordance"
  },
  {
    url: "url(" + LexiconImg + ")",
    title: 'Λεξικό εννοιών, έργων, ονομάτων',
    subtitle: '1_Δείτε το αναλυτικό λεξικό εννοιών, έργων και ονομάτων',
    width: '250px',
    redirect: "./Lexicon"
  },
  {
    url: "url(" + ForumImg + ")",
    title: 'Το φόρουμ των Αριστοτελιστών',
    subtitle: '2_Συζητήστε με τα υπόλοιπα μέλη της πλατφόρμας στο φόρουμ των Αριστοτελιστών',
    width: '250px',
    redirect: "./Forums"
  },
  {
    url: "url(" + ErgMetImg + ")",
    title: 'Εργαστήρια μετάφρασης-πρόσληψης',
    subtitle: '3_Μάθετε περισσότερα για τα διαθέσιμα εργαστήρια μετάφρασης και πρόσληψης',
    width: '250px',
    redirect: "./TranslationReceptionLabs"
  },
  {
    url: "url(" + ErgEkpPerImg + ")",
    title: 'Εργαστήρια εκπαιδευτικά-περιηγητικά',
    subtitle: '3_Μάθετε περισσότερα για τα διαθέσιμα εκπαιδευτικά και περιηγητικά εργαστήρια',
    width: '250px',
    redirect: "./EducationalLabs"
  },
  /*{
    url: "url(" + SchoolsImg + ")",
    title: 'Θερινά μαθήματα',
    subtitle: '(summer schools)',
    width: '250px',
    redirect: ""
  },*/
  {
    url: "url(" + EditionsImg + ")",
    title: 'Ψηφιακές εκδόσεις και υπηρεσίες',
    subtitle: '4_Δείτε τις διαθέσιμες εκδόσεις και εκδοτικές υπηρεσίες',
    width: '250px',
    redirect: "#"
  },
  {
    url: "url(" + EduSourcesImg + ")",
    title: 'Εκπαιδευτικοί-πολυμεσικοί πόροι',
    subtitle: '4_Δείτε τους διαθέσιμος εκπαιδευτικούς πολυμεσικούς πόρους',
    width: '250px',
    redirect: "./EduSources"
  },
  {
    url: "url(" + aristosIcon + ")",
    title: 'Εγγραφή',
    subtitle: '5_Εγγραφείτε στην πλατφόρμα των Αριστοτελιστών',
    width: '250px',
    redirect: "./SignUp"
  }
];


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({

  root: {
    //backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexGrow: 1,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },

  text: {
      padding: theme.spacing(2, 2, 0),
    },
  paper2: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar2: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },


   control: {
     padding: theme.spacing(2),
   },



 paper: {
   padding: theme.spacing(4),
   margin: '15px',
   maxWidth: 900,
   //height: 470,
   minWidth: 250,
 },

 img: {
   margin: 'auto',
   display: 'block',
   maxWidth: '100%',
   maxHeight: '100%',
 },


  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  Accordion2:{
    width:200,
    maxWidth:200,
    position: 'absolute',
    bottom: 5,
  },
  AccHeading:{
    width:200,
    maxWidth:200
  },

  image: {
   position: 'relative',
   height: 200,
   [theme.breakpoints.down('xs')]: {
     width: '100% !important', // Overrides inline-style
     height: 100,
   },
   '&:hover, &$focusVisible': {
     zIndex: 1,
     '& $imageBackdrop': {
       opacity: 0.15,
     },
     '& $imageMarked': {
       opacity: 0,
     },
     '& $imageTitle': {
       border: '4px solid currentColor',
     },
   },
 },
 imageInactive: {
   filter: 'grayscale(80%)',
 },
 focusVisible: {},
 imageButton: {
   position: 'absolute',
   left: 0,
   right: 0,
   top: 0,
   bottom: 0,
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   color: theme.palette.common.white,
 },
 imageSrc: {
   position: 'absolute',
   left: 0,
   right: 0,
   top: 0,
   bottom: 0,
   backgroundSize: 'cover',
   backgroundPosition: 'center 40%',
 },
 imageBackdrop: {
   position: 'absolute',
   left: 0,
   right: 0,
   top: 0,
   bottom: 0,
   backgroundColor: theme.palette.common.black,
   opacity: 0.3,
   transition: theme.transitions.create('opacity'),
 },
 imageTitle: {
   position: 'absolute',
   //padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
   paddingLeft: `${theme.spacing(2)}px`,
   paddingRight: `${theme.spacing(2)}px`,
   bottom: '5px',
 },
 imageMarked: {
   height: 3,
   width: 18,
   backgroundColor: theme.palette.common.white,
   position: 'absolute',
   bottom: -2,
   left: 'calc(50% - 9px)',
   transition: theme.transitions.create('opacity'),
 },




}));

export default function MiniDrawer() {
  const classes = useStyles();
  //const theme = useTheme();

  /*const handleClick = () => {
   setOpen(!open);
 };*/



  const [spacing, setSpacing] = React.useState(2);

   /*const handleChange = (event) => {
     setSpacing(Number(event.target.value));
   };


  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };*/

return (
	
		<div className={classes.root}>
	      <CssBaseline />
    	  <Sidebar/>
      		
      		<Paper id="mainmenu" className={classes.paper} justify="center" >
      		
      		<div id="intro_text">
      			<img id="intro_logo" src={aristosIcon} alt="..." />
      			<span>Καλωσήρθατε στους @ριστοτελιστές, το συνεργατικό corpus+ του Αριστοτέλη. Μια διαδικτυακή συμμετοχική πλατφόρμα παρουσίασης, μελέτης και ανάλυσης του έργου του Αριστοτέλη.</span>
      		</div>
      		
      		{images.map((image, idx) => (
                <a
                  className={(image.redirect==='') ? `${classes.image} ${classes.imageInactive}` : classes.image}
                  class="mainmenulink"  href={image.redirect}
                  disabled={image.redirect===''}
                >
                     <span>{image.title}</span>
                     <span class="mainmenulinkdescription">{image.subtitle || ''}</span>
                </a>
            ))}
      		
      		</Paper>
      		
       </div>
		
	);
	
  /* return (
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2} style={{ paddingTop : '0px'}} >
            <Grid item xs={12} >
              <div>
              <Breadcrumbs hidden separator="›" aria-label="breadcrumb">
                  <Link color="inherit" href="/admin/SelectTransSem">
                    Main
                  </Link>
              </Breadcrumbs>
              </div>
            </Grid>


             <Grid item xs={12} style={{paddingTop : '20px'}}>
               <Grid container justify="center" spacing={spacing}>
                   <Grid container justify="center" >

                         <Paper className={classes.paper}  justify="center" >
                             <Grid container spacing={2} justify="center">
                               <Grid item xs={12} sm container justify="center">
                                 <Grid item xs container direction="row" spacing={2}>

                                   {images.map((image, idx) => (
                                     <Grid item xs={12} sm={6} md={4} key={idx}>
                                      <ButtonBase
                                        focusRipple
                                        key={image.title}
                                        spacing={10}
                                        className={(image.redirect==='') ? `${classes.image} ${classes.imageInactive}` : classes.image}
                                        focusVisibleClassName={classes.focusVisible}
                                        style={{
                                          width: image.width,paddingRight:"10px"
                                        }}
                                        href={image.redirect}
                                        disabled={image.redirect===''}
                                      >
                                        <span
                                          className={classes.imageSrc}
                                          style={{
                                            backgroundImage: image.url,
                                          }}
                                        />
                                        <span className={classes.imageBackdrop} />
                                        <span className={classes.imageButton}>
                                          <Typography
                                            component="span"
                                            variant="subtitle1"
                                            className={classes.imageTitle}
                                          >
                                          <div
                                            style={{
                                              fontSize:"15px",
                                              textAlign: 'center',
                                              color: '#346eeb'
                                            }}>
                                            <b>{image.title}</b><br/>
                                            {image.subtitle || ''}
                                            </div>
                                          </Typography>
                                        </span>
                                      </ButtonBase>
                                    </Grid>

                                  ))}
                                 </Grid>
                               </Grid>
                             </Grid>
                   </Paper>
             </Grid>
             </Grid>
             </Grid>
    </Grid>



 </div>





  ); */
}
