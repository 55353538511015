import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from '@material-ui/core/ListSubheader';
import InputLabel from '@material-ui/core/InputLabel';
import './styles.css';

const LabsSelection = (props) => {
  const { selected, allLabs, handleChange, error, disabled } = props

  return(
    <FormControl error={Boolean(!disabled && error)} style={{minWidth: 500}}>
      <InputLabel htmlFor="grouped-select">Εργαστήριο που αφορά (μόνο για κλειστές κατηγορίες)</InputLabel>
      <Select
        id="grouped-select"
         value={selected}
         onChange={handleChange}
         disabled={disabled}
       >
        <ListSubheader>Εργαστήρια Μετάφρασης &amp; Πρόσληψης</ListSubheader>
         {
           allLabs.tr && allLabs.tr.map((lab) =>
             <MenuItem
               key={lab.id}
               value={'tr_'+lab.id}
             >
               {lab.title}
             </MenuItem>
          )
         }
        <ListSubheader>Εκπαιδευτικά-Περιηγητικά Εργαστήρια</ListSubheader>
         {
           allLabs.edu && allLabs.edu.map((lab) =>
             <MenuItem
               key={lab.id}
               value={'edu_'+lab.id}
               className={lab.visible ? '' : 'labsselection-invisible'}
             >
               {lab.title}
             </MenuItem>
          )
        }
       </Select>
       <FormHelperText>{!disabled && error}</FormHelperText>
    </FormControl>
  )
}

export default LabsSelection
