import React from 'react';

import NavPills from "components/NavPills/NavPills.js";

import LexiconTab from './LexiconTab.js'
import OriginalTextCommentsTab from './OriginalTextCommentsTab.js'
import LabsTab from './LabsTab.js'
import ForumsTab from './ForumsTab.js'

const LyricsOriginalComments = (props) => {
  const { scholarComments, lemmata, labs, forums, edit } = props

  let tabs = []
  if (lemmata.length > 0){
    tabs.push({
      tabButton: 'Λεξικό',
      tabContent: <LexiconTab lemmata={lemmata}/>,
    })
  }

  if (scholarComments.length > 0){
    tabs.push({
      tabButton: 'Σχόλια',
      tabContent: <OriginalTextCommentsTab scholarComments={scholarComments}
        onEditScholarComment={props.onEditScholarComment} onDeleteScholarComment={props.onDeleteScholarComment}
        edit={edit}/>,
    })
  }

  if (forums.length > 0){
    tabs.push({
      tabButton: 'Φόρουμ',
      tabContent: <ForumsTab forums={forums}/>,
    })
  }

  if (labs.labs_tr && labs.labs_tr.length > 0){
    tabs.push({
      tabButton: 'Εργαστήρια',
      tabContent: <LabsTab labs={labs}/>,
    })
  }

  return (
    <NavPills
      color="primary"
      tabs={tabs}
    />
  )
}

export default LyricsOriginalComments
