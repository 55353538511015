import React, {Component} from 'react';
import {Segment, Grid, Icon} from 'semantic-ui-react';
import RichEditor from '../richeditor';
import './styles.css';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from "components/CustomButtons/Button.js";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
// TODO: decide which
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

import { forumImagesURL } from 'clients/clientConfig.js'

const likeActionTypes = ['thumbup', 'thumbdown']

const iconByLikeType = (like_type, liked_by_user=false) => {
  const color = liked_by_user ? 'primary' : 'inherit'

  switch (like_type) {
    case 'thumbup':
      return <ThumbUpIcon color={color}/>

    case 'thumbdown':
      return <ThumbDownIcon color={color}/>

    default:
      return null
  }
}

export default class Post extends Component {
  state = {
    anchorEl: null,
  }

  onDelete = () => {
    const {deleteAction, id, threadID, isThread} = this.props;
    if (isThread) {
      deleteAction(threadID);
    } else {
      deleteAction(id, threadID);
    }
  };

  onEdit = () => {
    const { editAction, id, content, file, keywords } = this.props;
    editAction({id: id, content: content, file: file, keywords: keywords})
  }

  onRestore = () => {
    const { restoreAction, id, threadID } = this.props;
    restoreAction(id, threadID);
  }

  onLike = (like_type) => {
    const { likeAction, id, threadID, isThread } = this.props
    let params = {like_type: like_type}
    if (isThread){
      params.id = threadID
    } else {
      params.id = id
      params.thread_id = threadID
    }
    //console.log(params)
    likeAction(params)
  }

  detailedLikes = () => {
    const { likes } = this.props

    let details = []
    likeActionTypes.forEach(action => {
      const likes_for_action = likes.filter(l => l.like_type === action)
      if (likes_for_action.length > 0){
        details.push({num: likes_for_action.length, icon: iconByLikeType(action)})
      }
    })

    return details
  }

  handlePopoverOpen = (event) => {
    //console.log('open')
    this.setState({anchorEl: event.currentTarget});
  };

  handlePopoverClose = () => {
    //console.log('close')
    this.setState({anchorEl: null});
  };

  render() {
    const {
      id,
      isThread,
      content,
      file,
      date,
      user_fullname,
      user_id,
      authenticatedIsStaff,
      deletePostList,
      isLastPostInThread,
      hidden,
      showHidden,
      hidden_by,
      date_edited,
      edited_by,
      keywords,
      isAuthenticated,
      likes,
      isLiking,
    } = this.props;

    const authenticatedUser = localStorage.getItem('UserId')
    //console.log(authenticatedUser)
    const userPreviousLike = likes ? likes.filter(l => l.user_id == authenticatedUser) : []
    //console.log(userPreviousLike)

    const color = isThread ? 'black' : null;
    const deleteText = isThread ? 'Διαγραφή συζήτησης' : 'Διαγραφή ανάρτησης';
    const actions = hidden ?
     (
       <div className="post-dropdown">
         <Button size='sm' color='primary' onClick={this.onRestore}>
           Επαναφορά ανάρτησης
         </Button>
       </div>
     )
     :
     (
      <div className="post-dropdown">
        <Button size='sm' color='primary' onClick={this.onDelete}>
          {deleteText}<DeleteIcon/>
        </Button>
      </div>
    );
    const isLoading = !isThread && deletePostList.indexOf(id) >= 0;
    return (
      <Segment loading={isLoading} color={color} className={hidden ? 'post-hidden post-segment': 'post-segment'}>
        {
          (hidden && !showHidden) ?
          <div>
            Το περιεχόμενο της ανάρτησης δεν είναι διαθέσιμο, μετά από διαγραφή του
            από τον {hidden_by==='user' ? 'συντάκτη' : 'διαχειριστή'}
          </div>
          :
          <Grid textAlign="left" padded="horizontally">
            <Grid.Column width={4}>
              <Grid.Row>
                <div className="post-row">
                  <div className="post-column">
                    <div className="post-name">{user_fullname}</div>
                  </div>
                </div>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={12}>
              <div className="post-time">
                {date}
                {
                  (authenticatedIsStaff || (authenticatedUser == user_id && isLastPostInThread)) &&
                  <IconButton size='small' onClick={this.onEdit}><EditIcon/></IconButton>
                }
                {(authenticatedIsStaff || (authenticatedUser == user_id && isLastPostInThread)) &&
                  actions}
              </div>
              {
                date_edited &&
                <div className="post-time">
                  Επεξεργασία στις {date_edited} από {edited_by==='user' ? 'συντάκτη' : 'διαχειριστή'}
                </div>
              }
              <RichEditor
                readOnly={true}
                content={content}
                wrapperClassName={
                  false ? 'post-wrapper-edit' : 'post-wrapper-read'
                }
                editorClassName="post-editor"
                toolbarClassName="post-toolbar"
              />
            </Grid.Column>
            {file &&
              <Grid.Column width={12}>
                <i className='fa fa-paperclip' aria-hidden='true'></i>
				        <Link href={forumImagesURL+file} target='_blank' className='attachment'>{file}</Link>
              </Grid.Column>
            }
            {keywords && keywords.length > 0 &&
              <Grid.Column className="post-keywords">
                Λέξεις-κλειδιά:
                {keywords.map((kw, idx) => <Link key={idx} href={'/admin/ForumSearch/'+kw}>{kw}, </Link>)}
              </Grid.Column>
            }

            <Grid.Column width={12}>
              <Grid.Row>
              {likes && likes.length > 0 &&
                <span className="post-likes-list" onMouseEnter={this.handlePopoverOpen}>
                  {likes.length} {(likes.length===1) ? 'Αντίδραση' : 'Αντιδράσεις'}
                  ({this.detailedLikes().map((dl, idx) => (
                    <span key={idx}>
                      {dl.num} {dl.icon}
                    </span>
                  ))})
                </span>
              }

              <LikesPopover postID={id} open={Boolean(this.state.anchorEl)} likes={likes}
                anchorEl={this.state.anchorEl} handleClose={this.handlePopoverClose}/>

              {// TODO: why are they too low? Ask D.
                isAuthenticated &&
                <span className="post-likes-buttons">
                {
                  likeActionTypes.map((action, idx) =>
                    <IconButton key={idx} onClick={() => this.onLike(action)} disabled={isLiking}>
                      {iconByLikeType(action,
                        (userPreviousLike[0] && userPreviousLike[0].like_type===action))}
                    </IconButton>
                  )
                }
                </span>
              }
              </Grid.Row>
            </Grid.Column>
          </Grid>
      }
      </Segment>
    );
  }
}

const LikesPopover = (props) => {
  const { open, anchorEl, likes, handleClose, postID } = props

  return(
    <Popover
      id={"mouse-over-popover"+postID}
      sx={{
        pointerEvents: 'none',
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handleClose}
      disableRestoreFocus
    >
      {likes && likes.map((l, idx) =>
        <Typography variant='body2' key={idx}>{iconByLikeType(l.like_type)} από {l.user_fullname}</Typography>
      )}
    </Popover>
  )
}
