
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText'
import Sidebar from "components/Sidebar/Sidebar.js";
import CssBaseline from '@material-ui/core/CssBaseline';

import avatar from "assets/img/faces/marc.jpg";


import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function AddSeminar() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [state, setState] = React.useState({
    blog: true,
    wiki: true,
    mindmap: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>
      <main className={classes.content}>




      <GridContainer>

          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Προσθήκη σεμιναρίου</h4>
                <p className={classes.cardCategoryWhite}>
                  Προσθήκη σεμιναρίου
                </p>
              </CardHeader>
              <CardBody>

              <List component="nav" aria-label="mailbox folders">
                    <ListItem marginTop="10">
                    <CustomInput
                      labelText="Ονομασία Σεμιναρίου"
                      id="first-name"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    </ListItem>

                    <ListItem marginTop="10">
                    <TextField
                          id="date"
                          label="Ημερομ. Σεμιναρίου"
                          type="date"
                          defaultValue={new Date().toISOString().substring(0, 10)}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        </ListItem>
                        <ListItem marginTop="50">
                          </ListItem>
                    <ListItem marginTop="50">
                    <FormControl component="fieldset">
                         <FormLabel component="legend">Επιλογή εργαλείων σεμιναρίου</FormLabel>
                         <FormGroup>
                           <FormControlLabel
                             control={<Switch checked={state.wiki} onChange={handleChange} name="wiki" color="primary" />}
                             label="Wiki"

                           />
                           <FormControlLabel
                             control={<Switch checked={state.blog} onChange={handleChange} name="blog" color="primary"/>}
                             label="Blog"
                           />                           
                         </FormGroup>
                         <FormHelperText>Επιλέξτε ποια από τα παραπάνω εργαλεία θα είναι διαθέσιμα στο σεμινάριο.</FormHelperText>
                       </FormControl>
                    </ListItem>


                  </List>
                  </CardBody>
              <CardFooter>
                <Button color="primary">Προσθήκη</Button>
              </CardFooter>
            </Card>
          </GridItem>

        </GridContainer>

        <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Σεμινάρια που έχουν προγραμματιστεί/υλοποιηθεί</h4>
                  <p className={classes.cardCategoryWhite}>
                    Σεμινάρια Αριστοτελικών έργων
                  </p>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["Σεμινάρια", "Ημερομηνία"]}
                    tableData={[
                      [<a href= "./SeminarStruct"><u>Φυσική ακρόαση</u></a>, "19/10/2020"],
                      [<a href= "./SeminarStruct "><u>Περί γενέσεως και φθοράς</u></a>, "17/9/2020"],
                      [<a href= "./SeminarStruct "><u>Μετεωρολογικά</u></a>, "6/5/2020"],
                      [<a href= "./SeminarStruct "><u>Μετά τα φυσικά</u></a>, "16/4/2020"],
                      [<a href= "./SeminarStruct "><u>Περί κόσμου</u></a>, "22/3/2020"],
                    ]}
                  />

                </CardBody>
              </Card>
            </GridItem>

          </GridContainer>

      </main>
    </div>
  );
}
