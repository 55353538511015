import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'
import Button from "components/CustomButtons/Button.js";

export default function ImageViewDialog(props){
  const { image, baseUrl, open, onClose } = props

  return(
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}
      maxWidth={"xl"}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          {image.title || image.filename}
        </DialogTitle>

        <DialogContent>
          <img alt={image.title || ''} title={image.title || ''} src={baseUrl+image.filename}/>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Κλεισιμο</Button>
        </DialogActions>
     </Dialog>
  )
}
