import React from 'react';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  thumb: {
    height: 120,
    width: 180,
  }
}))

export default function ImageThumbnailGrid(props){
  const classes = useStyles();

  const { images, baseUrl, onClick } = props

  const breakpoint = (type) => {
    const size = props.size || "xl"
    const point = defaultBreakpointForXL(type);
    //console.log(size)
    const multiplier = breakpointMultiplier(size);
    let result = point * multiplier;
    if (result > 12){
      result = 12;
    }
    //console.log(result)
    return result;
  }

  const defaultBreakpointForXL = ( type) => {
    switch (type) {
      case "lg":
        return 2;

      case "md":
        return 4;

      case "sm":
        return 6;

      default:
        return 12;
    }
  }

  const breakpointMultiplier = (size) => {
    switch (size) {
      case "xs":
        return 6;

      case "sm":
        return 3;

      case "md":
        return 2;

      default:
        return 1;
    }
  }

  return(
    <GridContainer spacing={6}>
      {
        images.map((img, idx) =>
        <GridItem key={idx} xs={12} sm={breakpoint("sm")} md={breakpoint("md")} lg={breakpoint("lg")}>
          <Link onClick={() => onClick(img)} href='#'>
            <img alt={img.title || ''} title={img.title || ''} src={baseUrl+img.filename} className={classes.thumb}/>
          </Link>
          <br/>
          {img.title && <Typography align='center' variant='caption'>{img.title}</Typography>}
        </GridItem>
        )
      }
    </GridContainer>
  )
}
