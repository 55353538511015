import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import EditIcon from '@material-ui/icons/Edit';
import Button from "components/CustomButtons/Button.js";

import ImageThumbnailGrid from 'views/Images/ImageThumbnailGrid.js'
import ImageViewDialog from 'views/Images/ImageViewDialog.js'

import { getOneEduSource } from 'clients/eduSourcesClient.js'
import { eduSourcesFilesURL } from 'clients/clientConfig.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  paper: {
    padding: 20
  },
  edusrcImage: {
    // TODO:
    width: '90%'
  },
  creators: {
    fontWeight: 'bold',
    paddingTop: 10,
    paddingBottom: 10,
  }
}))

const EduSourceMain = (props) => {
  const classes = useStyles()
  const parse = require('html-react-parser');

  const [eduSource, setEduSource] = React.useState({})
  const [open, setOpen] = React.useState(false)
  const [imageToView, setImageToView] = React.useState({})

  const openImage = (img) => {
    setImageToView(img)
    setOpen(true)
  }


  React.useEffect(() => {
    if (props.match.params.edusourceid){
      getOneEduSource(props.match.params.edusourceid,
        (data) => {
          if (data.edu_sources.length > 0){
            setEduSource(data.edu_sources[0])
          } else {
            window.location.href = '/admin/EduSourceNotFound'
          }
        },
        (err) => console.log(err)
      )
    }
  }, [props.match.params.edusourceid])

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} >
        <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color='inherit' href='/admin/EduSources'>
                Εκπαιδευτικοί-Πολυμεσικοί Πόροι
              </Link>
              <Typography color="textPrimary">{eduSource.title}</Typography>
          </Breadcrumbs>
        </div>
      </Grid>

     <Grid item xs={12} style={{ paddingTop : '20px'}}>
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Typography variant="h4">
            {eduSource.title}&nbsp;
            {
              access_rights.edu_src && access_rights.edu_src.w &&
              <Button justIcon round color="primary" onClick={() => window.location.href='/admin/EduSourceEdit/'+eduSource.id}><EditIcon/></Button>
            }
          </Typography>

          {
            eduSource.image &&
              <img alt={eduSource.title} title={eduSource.title} className={classes.edusrcImage}
                src={eduSourcesFilesURL + eduSource.id + '/' + eduSource.image} />
          }

          <Typography variant='body1' className={classes.creators}>
            {eduSource.creators && parse(eduSource.creators.replace(/\n/g, '<br/>'))}
          </Typography>

          <Typography variant='body1' component='div'>
            {parse(eduSource.intro || '')}
          </Typography>
          <br/>

          {
            eduSource.links_arr && eduSource.links_arr.length > 0 &&
              <>
                <Typography variant='h5'>Ηλεκτρονική διεύθυνση</Typography>
                <List>
                {
                  eduSource.links_arr.map((link, idx) =>
                    <ListItem key={idx}>
                      <Link href={link.link} target="_blank">{link.title}</Link>
                    </ListItem>
                  )
                }
                </List>
                <br/>
              </>
          }

          {
            eduSource.multimedia && eduSource.multimedia.length > 0 &&
              <>
                <Typography variant='h5'>Πολυμέσα</Typography>
                <List>
                {
                  eduSource.multimedia.map((media, idx) =>
                    <ListItem key={idx}>
                      <Link href={media} target="_blank">{media}</Link>
                    </ListItem>
                  )
                }
                </List>
                <br/>
              </>
          }

          <Typography variant='body1' component='div'>
            {parse(eduSource.content || '')}
          </Typography>
          <br/>

          {
            eduSource.extra && eduSource.extra.length > 0 &&
              <>
                <Typography variant='h5'>Πρόσθετο υλικό</Typography>
                <List>
                {
                  eduSource.extra.map((filename, idx) =>
                    <ListItem key={idx}>
                      <Link href={eduSourcesFilesURL + eduSource.id + '/' + filename} target="_blank">{filename}</Link>
                    </ListItem>
                  )
                }
                </List>
                <br/>
              </>
          }

          {
            eduSource.tags && eduSource.tags.length > 0 &&
            <>
              <Typography variant='h5'>Λέξεις-κλειδιά</Typography>
              {eduSource.tags.map(t =>
                <span key={t}>
                  <Link variant='body1' href={'/admin/EduSources/?tag='+t}>{t}</Link> |&nbsp;
                </span>
              )}
              <br/>
            </>
          }

          {
            eduSource.images &&
              <>
                <br/>
                <ImageThumbnailGrid images={eduSource.images} onClick={openImage}
                  baseUrl={eduSourcesFilesURL+'/'+eduSource.id+'/'} size="md"/>
              </>
          }

          <ImageViewDialog image={imageToView} open={open} baseUrl={eduSourcesFilesURL+'/'+eduSource.id+'/'}
            onClose={() => setOpen(false)}/>

        </Paper>
      </Container>
    </Grid>

  </Grid>
  </div>

  )
}

export default EduSourceMain
