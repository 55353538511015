import React from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import Button from "components/CustomButtons/Button.js";
import FormLabel from '@material-ui/core/FormLabel';
import { Editor } from '@tinymce/tinymce-react';

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'

import { apiURL } from 'clients/clientConfig.js'
import { lab_editor_image_upload_handler } from 'clients/clientUtils.js'

const LabUserCommentForm = (props) => {
  const { onSave, onClose, open, disableSubmit } = props

  const [errorText, setErrorText] = React.useState('')

  const textHtmlEditorRef = React.useRef(null);

  const handleSubmit = () => {
    const newText = (textHtmlEditorRef.current) ? textHtmlEditorRef.current.getContent() : ''

    if (newText.length === 0){
      setErrorText('Υποχρεωτικό πεδίο')
    } else {
      setErrorText('')

      onSave(newText);
    }
  }

  const handleClose = () => {
    setErrorText('')
    onClose()
  }

  return(
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth
      maxWidth={"sm"}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Προσθήκη Σχολίου
        </DialogTitle>

        <DialogContent>
          <FormLabel>Σχόλιο</FormLabel>
          <Editor id="text" tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
           onInit={(evt, editor) => textHtmlEditorRef.current = editor}
           initialValue=""
           init={{
             height: 300,
             entity_encoding : "raw",
             plugins: [
               'advlist autolink lists link image charmap print preview anchor',
               'searchreplace visualblocks code fullscreen',
               'media table paste code help wordcount imagetools'
             ],
             menubar: false,
             toolbar: 'undo redo | formatselect | ' +
             'bold italic underline | alignleft aligncenter ' +
             'alignright alignjustify | outdent indent | ' +
             'removeformat | image | help',
             toolbar_mode: 'sliding',
             contextmenu: 'image imagetools',
             //images
             /*
             https://www.tiny.cloud/docs/configure/file-image-upload/
             https://www.tiny.cloud/docs/advanced/php-upload-handler/
             */
             images_upload_url: apiURL + 'labs_editor_image_upload',
             images_upload_handler: lab_editor_image_upload_handler,
             automatic_uploads: true,
             block_unsupported_drop: true,
             file_picker_types: 'image',
           }}
           />
          <FormHelperText error>{errorText}</FormHelperText>
        </DialogContent>

        <DialogActions>
            <Button onClick={handleSubmit} disabled={disableSubmit}>Καταχωριση</Button>
            <Button onClick={handleClose}>Κλεισιμο</Button>
        </DialogActions>
     </Dialog>
  )
}

export default LabUserCommentForm
