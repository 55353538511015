//import axios from 'axios';
/*import {
  THREAD_URL,
  THREAD_CREATE_URL,
  THREAD_EDIT_URL,
  THREAD_DELETE_URL,
} from './constants';
import {getConfig} from '../utils/config';*/
import { fetchWithGET, fetchWithMethod, fetchWithFile, fetchBlob } from
'clients/clientUtils.js'

export const fetchThreadApi = (thread, success, errorFunc) => {
  //return axios.get(THREAD_URL + thread, getConfig());
  return fetchWithGET('thread/' + thread, '', success, errorFunc)
};

export const createThreadApi = (newThread, newFile, success, errorFunc) => {
  //return axios.post(THREAD_CREATE_URL, newThread, getConfig());
  /*console.log(newThread)
  console.log(newFile)*/
  return fetchWithFile('thread', 'POST', newFile, newThread, () => success({data: newThread}), errorFunc)
  //return errorFunc({data: newThread})
};

export const deleteThreadApi = (id, success, errorFunc) => {
  //console.log(id)
  //return axios.delete(THREAD_URL + id + THREAD_DELETE_URL, getConfig());
  return fetchWithMethod('thread/' + id, 'DELETE', {}, success, errorFunc)
};

export const editThreadApi = (thread, file, success, errorFunc) => {
  /*console.log(thread)
  console.log(file)*/
  //return axios.put(THREAD_URL + id + THREAD_EDIT_URL, data, getConfig());
  return fetchWithFile('thread', 'PUT', file, thread, success, errorFunc)
};

export const restoreThreadApi = (id, success, errorFunc) => {
  return fetchWithMethod('thread/restore/' + id, 'PUT', {}, success, errorFunc)
};

export const togglePinnedThreadApi = (id, success, errorFunc) => {
  return fetchWithMethod('thread/togglepinned/' + id, 'PUT', {}, success, errorFunc)
}

export const toggleFavouriteThreadApi = (id, success, errorFunc) => {
  return fetchWithMethod('thread/togglefavourite/' + id, 'PUT', {}, success, errorFunc)
}

export const toggleNotificationsThreadApi = (id, success, errorFunc) => {
  return fetchWithMethod('thread/togglenotifications/' + id, 'PUT', {}, success, errorFunc)
}

export const copyThreadApi = (id, success, errorFunc) => {
  return fetchBlob('thread/copy/' + id, success, errorFunc)
}

export const likeThreadApi = (vals, success, errorFunc) => {
  return fetchWithMethod('thread/like', 'PUT', vals, success, errorFunc)
}

export const forwardThreadApi = (vals, success, errorFunc) => {
  return fetchWithMethod('thread/forward', 'POST', vals, success, errorFunc)
}
