import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'

import Button from "components/CustomButtons/Button.js";
import FormHelperText from '@material-ui/core/FormHelperText';

import UserSelectableTable from 'views/AdminUsersGroupsPrivs/UserSelectableTable.js'
import { getAllUsers } from 'clients/usersClient.js'

const useStyles = makeStyles((theme) => ({
  formElement: {
    width:500,
    paddingTop:10,
    marginTop:0
  },
  emptyElement: {
    color: '#808080',
    fontStyle: 'italic'
  }
}))

const SelectUserForm = (props) => {
  const classes = useStyles()
  const { open, handleClose, onSubmit, formTitle, submitLabel, isLoading } = props
  //console.log(forum)

  const [users, setUsers] = React.useState([])
  const [checked, setChecked] = React.useState([])
  const [error, setError] = React.useState('')

  React.useEffect(() => {
    if (open){
      //console.log('load users')
      getAllUsers(
        (data) => setUsers(data.users_all),
        (err) => console.log(err)
      )
    } else {
      setChecked([])
      setError('')
    }
  }, [open])

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    let newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    //console.log(newChecked);
  };

  const validate = () => {
    if (checked.length===0){
      setError('Δεν έχετε επιλέξει χρήστες!')
      return false;
    } else {
      setError('')
      return true;
    }
  }

  const handleSubmit = () => {
    if (validate()){
      onSubmit(checked)
    }
  }

  return(
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}
      maxWidth={"sm"}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {formTitle}
        </DialogTitle>

        <DialogContent dividers>
          <UserSelectableTable usersdata={users} checked={checked} handleToggle={handleToggle}/>
          <FormHelperText error>{error}</FormHelperText>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleSubmit} color="primary" disabled={isLoading}>
            {submitLabel}
          </Button>
        </DialogActions>
    </Dialog>
  )
}

export default SelectUserForm
