import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import { CloseSquare, PlusSquare, MinusSquare, StyledTreeItem, CheckboxTreeItem } from 'views/CollTranslationREADONLY/TreeUtils.js'
import Button from "components/CustomButtons/Button.js";
import Box from '@material-ui/core/Box';
import { sortWorksAndBooks } from 'sorting.js'

import { getAllWorks } from 'clients/worksClient.js'

const useStyles = makeStyles({
  root: {
    //height: 264,
    flexGrow: 1,
    //maxWidth: 400,
  },
});

const FilterTree = (props) => {
  const classes = useStyles();

  const { onApplyFilter } = props
  const [works, setWorks] = React.useState([])
  const [expanded, setExpanded] = React.useState([])
  const [selected, setSelected] = React.useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (e, nodeId) => {
    //console.log(e.target.checked+ ' '+nodeId)
    let tempSelected = [...selected]
    if (e.target.checked){
      tempSelected.push(nodeId)
    } else {
      const pos = tempSelected.findIndex(s => s === nodeId)
      if (pos >= 0)
        tempSelected.splice(pos, 1)
    }
    //console.log(tempSelected)
    setSelected(tempSelected)
  }

  const handleClear  = () => {
    setSelected([])
    onApplyFilter([])
  }

  const handleApplyFilter = () => {
    onApplyFilter(selected)
  }

  React.useEffect(() => {
    getAllWorks(
      (data) => setWorks(sortWorksAndBooks(data.works)),
      (err) => console.log(err)
    )
  }, [])

  return(
    <Box>
      <Button onClick={handleClear}>Καθαρισμος</Button>
      <Button onClick={handleApplyFilter}>Εφαρμογή</Button>
      <TreeView
        className={classes.root}
        expanded={expanded}
        selected={selected}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        onNodeToggle={handleToggle}
        multiSelect
      >
        <StyledTreeItem key={'roor'} nodeId='0' label='Έργα Αριστοτέλη'>
        {
          works && works.map((work) =>
            <CheckboxTreeItem key={work.id} nodeId={work.id.toString()} label={work.name}
              handleSelect={handleSelect} checked={selected.includes(work.id.toString())}>
            </CheckboxTreeItem>
          )
        }
        </StyledTreeItem>
      </TreeView>
    </Box>
  )
}

export default FilterTree
