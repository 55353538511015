import React from 'react';
import PreviewRoleRights from './PreviewRoleRights.js'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { getAllRoles } from 'clients/rolesClient.js'

const PreviewUsergroup = (props) => {
  const {usergroup, works, labs, forums} = props

  const [roles, setRoles] = React.useState([])
  const [worksForRole, setWorksForRole] = React.useState({})
  const [labsForRole, setLabsForRole] = React.useState({})
  const [forumsForRole, setForumsForRole] = React.useState({})

  React.useEffect(()=>{
    const rolesToShow = usergroup.roles.map(r => r.id.toString())
    //console.log(rolesToShow)
    getAllRoles(
      (data) => setRoles(rolesToShow.map(id => data.roles[id])),
      (err) => console.log(err)
    )

    //get works, forums and labs assigned to each role of usergroup
    const tmpWorksForRole = {}
    const tmpLabsForRole = {}
    const tmpForumsForRole = {}
    usergroup.roles.forEach(r => {
      if (r.works && r.works.length > 0){
        tmpWorksForRole[r.name] = works.filter(w => r.works.includes(w.id)).
            map(w => w.name).toString()
      }
      if (labs && r.labs_tr && r.labs_tr.length > 0){
        tmpLabsForRole[r.name] = labs.filter(l => {
          const ids = l.id.split("_")
          return ids[0]==='tr' && r.labs_tr.includes(parseInt(ids[1]))
        }).
          map(l => l.title).toString()
      }
      if (r.forums && r.forums.length > 0){
        tmpForumsForRole[r.name] = forums.filter(f => r.forums.includes(f.id)).
          map(f => f.name).toString()
      }
    })
    setWorksForRole(tmpWorksForRole)
    setLabsForRole(tmpLabsForRole)
    setForumsForRole(tmpForumsForRole)
  }, [usergroup])

  return(
    <>
      <Typography variant='h3'>{usergroup.usergroup_name}</Typography>
      <Typography variant='h4' color='textSecondary'>Ρόλοι</Typography>
      <List>
      {
        roles.map((r, idx) =>
          <div key={idx}>
          <ListItem>
            <PreviewRoleRights role={r} worksForRole={worksForRole[r.name]}
              labsForRole={labsForRole[r.name]} forumsForRole={forumsForRole[r.name]}/>
          </ListItem>
          <Divider/>
          </div>
        )
      }
      </List>
    </>
  )
}

export default PreviewUsergroup
