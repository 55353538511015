import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import { makeStyles } from '@material-ui/core/styles';
import { CloseSquare, PlusSquare, MinusSquare, LinkTreeItem } from 'views/CollTranslationREADONLY/TreeUtils.js'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    //maxWidth: 500,
  },
});

export default function LabHierarchyTree(props) {
  const classes = useStyles();

  const { hierarchy, defaultSelected } = props
  const [defaultExpanded, setDefaultExpanded] = React.useState([])

  const handleExpand = (e, nodeId) => {
    setDefaultExpanded(nodeId)
  }

  React.useEffect(() => {
    //expand all by default
      let expanded = []
      hierarchy && hierarchy.map((lab_level) => {
        expanded.push(lab_level.id.toString())
        lab_level.units && lab_level.units.map((unit_level) => {
          expanded.push(lab_level.id+'-'+unit_level.id)
          unit_level.chapters && unit_level.chapters.map((chapter_level) => {
            expanded.push(lab_level.id+'-'+chapter_level.id)
          })
        })
      })
      setDefaultExpanded(expanded)
  }, [hierarchy])

  return (
    <TreeView
      className={classes.root}
      expanded={defaultExpanded}
      selected={defaultSelected ? defaultSelected.toString() : ''}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
      onNodeToggle={handleExpand}
    >
    {
      hierarchy && hierarchy.map((lab_level) =>
        <LinkTreeItem key={lab_level.id} nodeId={lab_level.id.toString()} label='Εργαστήριο' href={'/admin/EduLabPreview/'+lab_level.id}>
        {
          lab_level.units && lab_level.units.map((unit_level) =>
            <LinkTreeItem key={lab_level.id+'-'+unit_level.id} nodeId={lab_level.id+'-'+unit_level.id}
              label={unit_level.title} href={(unit_level.id > 0) ? '/admin/EduLabViewPage/' + unit_level.id : '/admin/EduLabFullView/'+lab_level.id}>
            {
              unit_level.chapters && unit_level.chapters.map((chapter_level) =>
              <LinkTreeItem key={lab_level.id+'-'+chapter_level.id}
                nodeId={lab_level.id+'-'+chapter_level.id} label={chapter_level.title}
                href={'/admin/EduLabViewPage/' + chapter_level.id}>
                {
                  chapter_level.pages && chapter_level.pages.map(page_level =>
                    <LinkTreeItem key={lab_level.id+'-'+page_level.id}
                      nodeId={lab_level.id+'-'+page_level.id} label={page_level.title}
                      href={'/admin/EduLabViewPage/' + page_level.id}>
                    </LinkTreeItem>
                  )
                }
              </LinkTreeItem>
            )
            }
            </LinkTreeItem>
          )
        }
        </LinkTreeItem>
      )
    }
    </TreeView>
  );
}
