import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent } from 'views/Dialogs/Dialogs.js'
import ScholarCommentForm from './ScholarCommentForm.js'

export default function ScholarCommentDialog(props) {
  const { open, handleClose, dialogTitle, ...rest } = props

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth
      maxWidth={"sm"}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {dialogTitle}
        </DialogTitle>

        <DialogContent dividers>
          <ScholarCommentForm handleClose={handleClose} {...rest}/>
        </DialogContent>
    </Dialog>
  )
}
