import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from "components/CustomButtons/Button.js";
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'
import { checkNoErrors } from 'utils.js'
import { labsEduImagesURL } from 'clients/clientConfig.js'

const useStyles = makeStyles((theme) => ({
  fileinput: {
    display: 'none',
  },
  personImage: {
    height: 60,
    width: 60,
  }
}))

const LabPersonForm = (props) => {
  const { onSave, onClose, open, person, isResponsible, lab_id } = props
  const classes = useStyles()

  const [fullname, setFullname] = React.useState('')
  const [specialty, setSpecialty] = React.useState('')
  const [info, setInfo] = React.useState('')
  const [image, setImage] = React.useState(null)
  const [errors, setErrors] = React.useState({})

  React.useEffect(() => {
    setFullname(person.fullname || '')
    setSpecialty(person.specialty || '')
    setInfo(person.info || '')
    setImage(person.image || null)
  }, [person])

  const handleFullnameChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.fullname = validate('fullname', e.target.value)
    setErrors(newErrors)

    setFullname(e.target.value)
  }

  const handleSpecialtyChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.specialty = validate('specialty', e.target.value)
    setErrors(newErrors)

    setSpecialty(e.target.value)
  }

  const handleInfoChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.info = validate('info', e.target.value)
    setErrors(newErrors)

    setInfo(e.target.value)
  }

  //image upload functions
  const uploadImage = (e) => {
    const allowedExtensions = /(\.(jpg|jpeg|gif|png|bmp|tiff|pjp|jfif|svg|svgz|webp|ico|xbm|dib|tif|pjpeg|avif))$/i;
    let newErrors = Object.assign({}, errors)

    if (!allowedExtensions.exec(e.target.files[0].name)) {
      newErrors.image = "Πρέπει να ανεβάσετε αρχείο εικόνας"
      if (!image){
        setImage(null)
      }
    } else {
      //console.log(e.target.files[0])
      setImage(e.target.files[0])
      newErrors.image = ''
    }

    setErrors(newErrors)
  }

  const handleSubmit = () => {
    let newErrors = Object.assign({}, errors)
    newErrors.fullname = validate('fullname', fullname)
    newErrors.specialty = isResponsible ? validate('specialty', specialty) : ''
    newErrors.info = validate('info', info)
    //ignore image errors
    newErrors.image = ''
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      let params = {fullname: fullname, info: info, image: image}
      if (isResponsible){
        params.specialty = specialty
      }
      console.log(params)
      onSave(params);
    }
  }

  const validate = (name, value) => {
    switch (name) {
      case 'fullname':
        return (value.trim()==='') ? 'Υποχρεωτικό πεδίο' : ''

      /*case 'info':
        return (value.length > 300) ? 'Επιτρέπονται μέχρι 300 χαρακτήρες' : ''*/

      default:
        return ''
    }
  }

  const handleClose = () => {
    setErrors({})
    onClose()
  }

  return(
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth
      maxWidth={"sm"}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          {fullname ? 'Επεξεργασία' : 'Προσθήκη'} {isResponsible ? 'Επιστημονικού Υπευθύνου' : 'Εισηγητή'}
        </DialogTitle>

        <DialogContent>
          <TextField
           error={Boolean(errors.fullname)}
           label='Ονοματεπώνυμο'
           style = {{width:500, paddingTop:10, marginTop:0}}
           value={fullname}
           onChange={handleFullnameChange}
           />
          <FormHelperText error>{errors.fullname}</FormHelperText>
          <br/>

          <GridContainer spacing={2}>
            <GridItem xs={12} sm={4}>
              <FormLabel>Εικόνα</FormLabel>
              <br/>
              <input
                accept="image/*"
                className={classes.fileinput}
                id="person-image"
                type="file"
                onChange={uploadImage}
              />
              <label htmlFor="person-image">
                <Button variant="contained" color="primary" component="span">
                  Ανεβασμα εικονας
                </Button>
              </label>
              <FormHelperText error>{errors.image}</FormHelperText>
            </GridItem>
            <GridItem xs={12} sm={8}>
              {
                image && (
                  !(image instanceof File) ?
                  <img src={labsEduImagesURL+lab_id+'/'+image} className={classes.personImage} alt='Εικόνα'/> :
                  <>{image.name}</>
                )
              }
            </GridItem>
          </GridContainer>

          {
            isResponsible &&
            <>
              <TextField
               error={Boolean(errors.specialty)}
               label='Ιδιότητα'
               style = {{width:500, paddingTop:10, marginTop:0}}
               value={specialty}
               onChange={handleSpecialtyChange}
               />
              <FormHelperText error>{errors.specialty}</FormHelperText>
              <br/>
            </>
          }

          <TextField
           error={Boolean(errors.info)}
           label='Λίγα λόγια'
           style = {{width:500, paddingTop:10, marginTop:0}}
           value={info}
           multiline rows={4}
           variant='outlined'
           onChange={handleInfoChange}
           />
          <FormHelperText error>{errors.info}</FormHelperText>
          <br/>
        </DialogContent>

        <DialogActions>
            <Button onClick={handleSubmit}>Καταχωριση</Button>
            <Button onClick={handleClose}>Κλεισιμο</Button>
        </DialogActions>
     </Dialog>
  )
}

export default LabPersonForm
