import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import { grey } from '@material-ui/core/colors';
import { green } from '@material-ui/core/colors';


import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
/*import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';


import ExitToAppIcon from '@material-ui/icons/ExitToApp';*/
import TranslateIcon from '@material-ui/icons/Translate';
//import WebIcon from '@material-ui/icons/Web';
import AssignmentIcon from '@material-ui/icons/Assignment';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
/*import BookIcon from '@material-ui/icons/Book';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import GroupWorkIcon from '@material-ui/icons/GroupWork';*/
import HomeIcon from '@material-ui/icons/Home';
/*import AddLocationIcon from '@material-ui/icons/AddLocation';
import PostAddIcon from '@material-ui/icons/PostAdd';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';*/
import SubjectIcon from '@material-ui/icons/Subject';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import TableChartIcon from '@material-ui/icons/TableChart';

/*import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";*/

import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';

//import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountCircle from '@material-ui/icons/Person';

import PeopleCircle from '@material-ui/icons/PeopleAlt';

//import custom images';
import avatarArist from "images/aristotelistes_logo_gr_white.png";
/*import wikiIcon from "images/wikipedia.svg";
import blogIcon from "images/blog.svg";*/
import bibliographyIcon from "images/bibliography.svg";
//import translateIcon from "images/translate.svg";
import seminarIcon from "images/seminar.svg";
import adminIcon from "images/administrator.svg";
import adminusersIcon from "images/adminusers.svg";
import SchoolIcon from '@material-ui/icons/School';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import ForumIcon from '@material-ui/icons/Forum';
import MovieIcon from '@material-ui/icons/Movie';
import BookIcon from '@material-ui/icons/Book';

//import addBookIcon from "images/addbook.svg";
//import addErgoIcon from "images/addergo.svg";
//import addCategoryIcon from "images/addergo.svg";


import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import Grid from '@material-ui/core/Grid';
import Notification from 'views/Notification/Notification.js'

import { userLogout } from 'clients/userRegisterAndAuth.js'
import invalidateSessionAndRedirectToSign from 'clients/logout.js'
import { streamURL } from 'clients/clientConfig.js'
import { getOnlineUsers, countOnlineUsers, getUserNotifications } from 'clients/usersClient.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

export default function Sidebar() {

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

    getOnlineUsers(
      (data) => setOnlineUsers(data.users_info),
      (err) => {
        console.log(err)
        setOnlineUsers([])
      }
    )

  };

  const list = (anchor) => (

    <div position='fixed' bottom='5'        className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button>
          <ListItemText>Online</ListItemText>
        </ListItem>

        {onlineUsers.map((user) =>
          <ListItem button key={user.username}>
            <ListItemAvatar>
              <Avatar className={classes.avatarOn}>
                <AccountCircle />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>{user.firstname} {user.lastname}</ListItemText>
          </ListItem>
        )}

      </List>
    </div>
  );

  const drawerWidth = 450;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuId = 'primary-search-account-menu';

 const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

 const isMenuOpen = Boolean(anchorEl);
 const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
   setAnchorEl(null);
   handleMobileMenuClose();
 };

 const redirectUserProfil = () => {

   window.location.href = "/admin/UserProfile/UserProfile";

 };

 const redirectLogin = () => {
   window.location.href = "/admin/SignIn"
 }

 const redirectSignup = () => {
   window.location.href = "/admin/SignUp"
 }

 const performLogout = () => {
   userLogout(
     (data) => invalidateSessionAndRedirectToSign() ,
     (err) => console.log(err.message)
   )
 }

 const handleMobileMenuOpen = (event) => {
   setMobileMoreAnchorEl(event.currentTarget);
 };

 const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    appBar: {
	    backgroundColor: '#313A42',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    /*menuButton: {
      marginRight: 36,
    },*/
    hide: {
      display: 'none',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        //width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
      },
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      //width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: 0/*theme.spacing(9) + 1*/,
      },
      [theme.breakpoints.down('sm')]: {
        width: 0,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  grow: {
   flexGrow: 1,
 },

 list: {
   width: 250,
 },
 signIn: {
   minWidth: 80,
 },
 fullList: {
   width: 'auto',
 },
 avatarOn: {
   backgroundColor: green[100],
   color: green[600],
 },
 avatarOff: {
   backgroundColor: grey[500],
  // color: grey[500],
 },
 notifications: {
   width: 300,
 },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isLoggedIn = (localStorage.getItem('UserName')!==null && localStorage.getItem('UserName')!==''
    && localStorage.getItem('UserName')!=='null') ? true : false

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={redirectUserProfil}>Ο λογαριασμός μου</MenuItem>
      <MenuItem onClick={performLogout}>Αποσύνδεση</MenuItem>
    </Menu>
  );


  const [numOnlineUsers, setNumOnlineUsers] = React.useState('')
  const [onlineUsers, setOnlineUsers] = React.useState([])
  const [notifications, setNotifications] = React.useState([])

  React.useEffect(() => {
      //initialize with initial number, as onmessage only gets changes
      if (isLoggedIn){
        countOnlineUsers(
          (data) => {
            if (numOnlineUsers===''){
              //if it hasn't already been updated by stream
              setNumOnlineUsers(data.users_count)
            }
          },
          (err) => console.log(err)
        )

        getUserNotifications(
          (data) => setNotifications(data.notifications),
          (err) => console.log(err)
        )
      }
      /*
      Server-side events: https://medium.com/code-zen/python-generator-and-html-server-sent-events-3cdf14140e56
      https://medium.com/tokopedia-engineering/implementing-server-sent-events-in-reactjs-c36661d89468
      */
      /*
      https://stackoverflow.com/questions/62950458/how-to-make-a-react-component-update-when-a-self-updating-prop-changes
        https://hackernoon.com/using-mongodb-as-a-realtime-database-with-change-streams-213cba1dfc2a
      https://blog.bitsrc.io/how-to-render-streams-with-react-8986ad32fffa
      https://github.com/johnlindquist/react-streams
      https://itnext.io/react-push-notifications-with-hooks-d293d36f4836
        https://css-tricks.com/build-a-chat-app-using-react-hooks-in-100-lines-of-code/
      Socket.io or Pusher
      */
      /*const eventSource = new EventSource(streamURL)

      eventSource.onmessage = (e) => {
        //console.log('message')
        //console.log(e.data)
        setNumOnlineUsers(e.data)
      }

      eventSource.onerror = () => {
        eventSource.close()
      }

      return () => {
        eventSource.close()
      }*/
  }, [])

  const [anchorNotif, setAnchorNotif] = React.useState(null)
  const [mobileMoreAnchorNotif, setMobileMoreAnchorNotif] = React.useState(null);

  const toggleNotificationsDrawer = (event, open) => {
    if (notifications.length > 0 && open){
      //console.log('open')
      setAnchorNotif(event.currentTarget);
    } else {
      //console.log('close')
      setAnchorNotif(null);
      setMobileMoreAnchorNotif(null);
    }
  }

  const notificationsMenu = (
    <Menu
      anchorEl={anchorNotif}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id='notifications_list'
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorNotif)}
      onClose={(e) => toggleNotificationsDrawer(e, false)}
    >
      <List className={classes.notifications}>
      {
        notifications.map((n, idx) =>
          <Notification key={idx} notification={n}/>
        )
      }
      </List>
    </Menu>
  )

  const drawer = (
    <>
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
      {/*}  {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}   */}


        <ListItem button key='Main' onClick={() => { window.location.href = "/admin/SelectTransSem"; }} >
          <ListItemIcon> <HomeIcon style={{marginLeft:8}}/></ListItemIcon>
          <ListItemText primary={'Αρχική'} />
        </ListItem>
        {
          ((access_rights.original_text && access_rights.original_text.r) ||
            (access_rights.translation && access_rights.translation.r)) &&
          <ListItem button key='Αριστοτελικά έργα' onClick={() => { window.location.href = "/admin/bookSelection"; }} >
            <ListItemIcon><TranslateIcon style={{marginLeft:8}}/> </ListItemIcon>
            <ListItemText primary={'Αριστοτελικά έργα, Μεταφράσεις, Σχόλια'} />
          </ListItem>
        }
        {/*<ListItem button key='Blogs' onClick={() => { window.location.href = "/admin/Blogs"; }} >
          <ListItemIcon><img style={{marginLeft:10, width:19, height:19}} src={blogIcon} alt="..." /></ListItemIcon>
          <ListItemText primary={'Blogs'} />
        </ListItem>
        <ListItem button key='Wikis' onClick={() => { window.location.href = "/admin/Wikis"; }} >
          <ListItemIcon><img style={{marginLeft:10, width:19, height:19}} src={wikiIcon} alt="..." /></ListItemIcon>
          <ListItemText primary={'Wikis'} />
        </ListItem>*/}
        <ListItem button key="ΣΠΛ" onClick={() => {window.location.href="/admin/Concordance"}}>
          <ListItemIcon><TableChartIcon style={{marginLeft:8}}/></ListItemIcon>
          <ListItemText primary={'Αναζήτηση λέξεων στα αρχαία κείμενα'}/>
        </ListItem>
        {
          access_rights.lexicon && access_rights.lexicon.r &&
          <ListItem button key='Λεξικό εννοιών' onClick={() => { window.location.href = "/admin/Lexicon"; }}>
            <ListItemIcon><MenuBookIcon style={{marginLeft:4, marginTop:7, width:31, height:31}}></MenuBookIcon></ListItemIcon>
            <ListItemText primary={'Λεξικό εννοιών, έργων, ονομάτων'} />
          </ListItem>
        }
        {
          <ListItem button key='Forum Αριστοτελιστών' onClick={() => { window.location.href = "/admin/Forums"; }}>
            <ListItemIcon><ForumIcon style={{marginLeft:4, marginTop:7, width:31, height:31}}></ForumIcon></ListItemIcon>
            <ListItemText primary={'Το φόρουμ των @ριστοτελιστών'} />
          </ListItem>
        }
        {
          access_rights.labs_tr && access_rights.labs_tr.v &&
          <ListItem button key='Εργαστήρια μετάφρασης και πρόσληψης' onClick={() => { window.location.href = "/admin/TranslationReceptionLabs"; }} >
            <ListItemIcon><img style={{marginLeft:4, width:31, height:31}} src={seminarIcon} alt="..." /></ListItemIcon>
            <ListItemText primary={'Εργαστήρια μετάφρασης-πρόσληψης'} />
          </ListItem>
        }
        {
          access_rights.labs_edu && access_rights.labs_edu.v &&
          <ListItem button key='Εργαστήρια εκπαιδευτικά-περιηγητικά' onClick={() => { window.location.href = "/admin/EducationalLabs"; }} >
            <ListItemIcon><SchoolIcon style={{marginLeft:4, width:31, height:31}}/></ListItemIcon>
            <ListItemText primary={'Εργαστήρια εκπαιδευτικά-περιηγητικά'} />
          </ListItem>
        }
        <ListItem button disabled key='Ψηφιακές εκδόσεις και υπηρεσίες'>
          <ListItemIcon><MovieIcon style={{marginLeft:4, width:31, height:31}}/></ListItemIcon>
          <ListItemText primary={'Ψηφιακές εκδόσεις και υπηρεσίες'} />
        </ListItem>
        <ListItem button key='Εκπαιδευτικοί-πολυμεσικοί πόροι' onClick={() => { window.location.href = "/admin/EduSources"; }} >
          <ListItemIcon><BookIcon style={{marginLeft:4, width:31, height:31}}/></ListItemIcon>
          <ListItemText primary={'Εκπαιδευτικοί-πολυμεσικοί πόροι'} />
        </ListItem>
        {
          ((access_rights.labs_edu && access_rights.labs_edu.w) || (access_rights.labs_tour && access_rights.labs_tour.w) ||
          (access_rights.labs_summer && access_rights.labs_summer.w)) &&
          <ListItem button key='Διαχ. εργαστηρίων' onClick={() => { window.location.href = "/admin/AdminEduLabs"; }} >
            <ListItemIcon><SubscriptionsIcon style={{marginLeft:4, width:31, height:31}}/></ListItemIcon>
            <ListItemText primary={'Διαχ. εργαστηρίων'} />
          </ListItem>
        }
        {
          access_rights.users && access_rights.users.r &&
          <ListItem button key='Διαχείριση Χρηστών' onClick={() => { window.location.href = "/admin/adminUsersGroupsPrivs"; }} >
            <ListItemIcon><img style={{marginLeft:4, marginTop:7, width:31, height:31}} src={adminusersIcon} alt="..." /></ListItemIcon>
            <ListItemText primary={'Διαχ. Χρηστών'} />
          </ListItem>
        }
        {
          access_rights.users && access_rights.users.r &&
          <ListItem button key='Διαχείριση Έργων' onClick={() => { window.location.href = "/admin/AdminErgBook"; }} >
            <ListItemIcon><img style={{marginLeft:10, width:20, height:20}} src={adminIcon} alt="..." /></ListItemIcon>
            <ListItemText primary={'Διαχ. Έργων'} />
          </ListItem>
        }

      </List>
      <Divider />
      <List>
        {
          access_rights.original_text && access_rights.original_text.w.length > 0 &&
          <ListItem button key='Νέο Χωρίο' onClick={() => { window.location.href = "/admin/AddLyricsItem"; }} >
            <ListItemIcon><SubjectIcon style={{marginLeft:8}}/></ListItemIcon>
            <ListItemText primary={'Νέο Χωρίο'} />
          </ListItem>
        }
        {
          access_rights.translation && access_rights.translation.w.length > 0 &&
          <ListItem button key='Νέα Μετάφραση' onClick={() => { window.location.href = "/admin/AddTranslation"; }} >
            <ListItemIcon><BackupOutlinedIcon style={{marginLeft:8}}/></ListItemIcon>
            <ListItemText primary={'Νέα Μετάφραση'} />
          </ListItem>
        }
        {
          access_rights.original_text && access_rights.original_text.w.length > 0 &&
          <ListItem button key='Ανέβασμα Σχολίων' onClick={() => { window.location.href= "/admin/UploadComment"}}>
            <ListItemIcon><NoteAddIcon style={{marginLeft:8}}/></ListItemIcon>
            <ListItemText primary='Ανέβασμα Σχολίων'/>
          </ListItem>
        }
      </List>
    </>
  )

  return (
    <div className={classes.grow}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <a href="/admin">
            <img style={{height:40}} src={avatarArist} alt="..." />
          </a> &nbsp;



           <div className={classes.grow} />

           <div className={classes.grow} />
               <div className={classes.sectionDesktop}>
               <Grid
                   item
                   container
                   className={classes.centerColumn}
                   direction="column"
                   display="flex"
                   justify="center"
                 >
                     <Typography variant="body2"noWrap color="inherit" align= 'center'>
                        {isLoggedIn &&
                          localStorage.getItem('UserFName') + " " + localStorage.getItem('UserLName') + " (" + localStorage.getItem('UserName') + ")" }
                    </Typography>
                 </Grid>

                 <IconButton aria-label="show 4 new mails" color="inherit" onClick={(e) => toggleNotificationsDrawer(e, true)}>
                   <Badge badgeContent={notifications.length} color="secondary">
                     <NotificationsIcon />
                   </Badge>
                 </IconButton>
                 {/* Online users drawer */}
                 <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
                   {list('right')}
                 </Drawer>
                 {
                   isLoggedIn ?
                   <>
                     <IconButton aria-label={"show "+numOnlineUsers+" online users"} hover="asda" color="inherit" onClick={toggleDrawer('right', true)}>
                       <Badge badgeContent={numOnlineUsers} color="secondary">
                         <PeopleCircle/>
                       </Badge>
                     </IconButton>
                     <IconButton
                       edge="end"
                       aria-label="account of current user"
                       aria-controls={menuId}
                       aria-haspopup="true"
                       onClick={handleProfileMenuOpen}
                       color="inherit"
                     >
                       <AccountCircle />
                     </IconButton>
                    </> :
                    <>
                      <MenuItem className={classes.signIn} onClick={redirectLogin}>Σύνδεση</MenuItem>
                      <MenuItem className={classes.signIn} onClick={redirectSignup}>Εγγραφή</MenuItem>
                    </>
                  }
               </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {notificationsMenu}

      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer id="mainsidebar"
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={open}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
            variant="permanent"
            open={open}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

    </div>
  );
}
