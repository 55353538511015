import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Link from '@material-ui/core/Link';

import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'

import { getAccessRightsFieldForLabType } from './utils.js'
import { checkNoErrors } from 'utils.js'
import { emailRex } from 'variables/general.js'

import { subscribeToLab } from 'clients/labEducationEtcClient.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const EduLabSignupForm = (props) => {
  const { lab, numAlreadyAttending } = props
  //console.log('max='+lab.maxAttendants)

  const labs_type_access = getAccessRightsFieldForLabType(lab.lab_type)
  // TODO: forbid admin from signing up
  /*const isAdmin = access_rights && labs_type_access &&
    access_rights[labs_type_access].w*/
  const alreadySignedUp = access_rights && labs_type_access &&
    access_rights[labs_type_access].r.includes(lab.id)

  const [contactWay, setContactWay] = React.useState('')
  const [contactDetails, setContactDetails] = React.useState('')
  const [purchaseCode, setPurchaseCode] = React.useState('')
  const [errors, setErrors] = React.useState({})
  const [saving, setSaving] = React.useState(false)

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  const contactDetailsTitle = () => {
    switch (contactWay) {
      case '1':
        return 'Αριθμός Τηλεφώνου'

      case '2':
        return 'Διεύθυνση Email'

      default:
        return 'Στοιχεία Επικοινωνίας'
    }
  }

  const handleContactWayChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.contactWay = validate('contactWay', e.target.value)
    setErrors(newErrors)

    setContactWay(e.target.value)
  }

  const handleContactDetailsChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.contactDetails = validate('contactDetails', e.target.value)
    setErrors(newErrors)

    setContactDetails(e.target.value)
  }

  const handlePurchaseCodeChange = (e) => {
    let newErrors = Object.assign({}, errors)
    newErrors.purchaseCode = validate('purchaseCode', e.target.value)
    setErrors(newErrors)

    setPurchaseCode(e.target.value)
  }

  const validate = (name, value) => {
    switch (name) {
      case 'contactWay':
        return (value==='') ? 'Υποχρεωτικό πεδίο' : ''

      case 'contactDetails':
        if (value.trim()===''){
          return 'Υποχρεωτικό πεδίο'
        } else {
          switch (contactWay) {
            case '1':
              const noNumberPattern = /[^0-9]/
              if (noNumberPattern.test(value)){
                return 'Επιτρέπονται μόνο αριθμοί'
              } else {
                return (value.length===10) ? '' : 'Ο αριθμός τηλεφώνου πρέπει να αποτελείται από 10 ψηφία'
              }

            case '2':
              return emailRex.test(value) ? '' : 'Μη έγκυρη Διεύθυνση Email'

            default:
              return ''
          }
        }

      case 'purchaseCode':
        if (lab.cost==0){
          return ''
        } else {
          return (value.trim()==='') ? 'Υποχρεωτικό πεδίο' : ''
        }

      default:
        return ''
    }
  }

  const handleSubmit = () => {
    let newErrors = Object.assign({}, errors)
    newErrors.contactWay = validate('contactWay', contactWay)
    newErrors.contactDetails = validate('contactDetails', contactDetails)
    newErrors.purchaseCode = validate('purchaseCode', purchaseCode)
    setErrors(newErrors)

    if (checkNoErrors(newErrors)){
      setSaving(true)
      const params = {
        contactWay: contactWay, contactDetails: contactDetails,
        purchaseCode: purchaseCode, lab_id: lab.id
      }
      //console.log(params)

      subscribeToLab(params,
        (data) => {
          if (data.status === 1){
            //update rights in session
            if (access_rights[labs_type_access].r){
              access_rights[labs_type_access].r.push(lab.id)
            } else {
              access_rights[labs_type_access].r = [lab.id]
            }
            //console.log(access_rights)
            localStorage.setItem("access_rights", JSON.stringify(access_rights))
          }

          window.location.href = '/admin/EduLabSignupResults/'+lab.id+'/'+data.status
        },
        (err) => {
          setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
          setSaving(false)
        }
      )
    }
  }

  return(
    alreadySignedUp ?
    <Typography variant='body1' align='center'>Είστε ήδη εγγεγραμμένος σε αυτό το εργαστήριο</Typography> :
    <div align='center'>
      {
        (lab.maxAttendants > 0 && numAlreadyAttending >= lab.maxAttendants) ?
        <Typography variant='body1' align='center' color='error'>
          Όλες οι αιτήσεις έχουν συμπληρώσει. Με την εγγραφή σας θα μπείτε σε λίστα αναμονής.
          {
            (lab.cost != 0) &&
            <> Πηγαίνοντας στο e-shop για να πληρώσετε, θα ενημερωθείτε αν υπάρχουν ακόμα διαθέσιμες θέσεις.</>
          }
        </Typography>
        :
        (lab.maxAttendants > 0 && numAlreadyAttending >= lab.maxAttendants * 0.9) &&
        <Typography variant='body1' align='center' color='error'>Περιορισμένη διαθεσιμότητα</Typography>
      }
      <p>
      {
        (lab.cost != 0) &&
        <Link href={lab.eshop_link} target="_blank">Πατήστε εδώ για πληρωμή και λήψη κωδικού</Link>
      }
      </p>

      <FormControl error={Boolean(errors.contactWay)}>
        <FormLabel component="legend">Προτιμώμενος τρόπος επικοινωνίας</FormLabel>
        <RadioGroup
          aria-label="contactWay"
          name="radio-buttons-contactWay"
          value={contactWay}
          onChange={handleContactWayChange}
        >
          <FormControlLabel value="1" control={<Radio />} label="Τηλέφωνο" />
          <FormControlLabel value="2" control={<Radio />} label="Email" />
        </RadioGroup>
        <FormHelperText>{errors.contactWay}</FormHelperText>
      </FormControl>
      <br/>

      <FormControl error={Boolean(errors.contactDetails)}>
        <TextField id='contactDetails'
         error={Boolean(errors.contactDetails)}
         label={contactDetailsTitle()}
         style = {{width:500, paddingTop:10, marginTop:0}}
         value={contactDetails}
         onChange={handleContactDetailsChange}
         />
         <FormHelperText>{errors.contactDetails}</FormHelperText>
      </FormControl>
      <br/>

     {
       (lab.cost != 0) &&
       <FormControl error={Boolean(errors.purchaseCode)}>
        <TextField id='purchaseCode'
          error={Boolean(errors.purchaseCode)}
          label='Κωδικός αγοράς'
          style = {{width:500, paddingTop:10, marginTop:0}}
          value={purchaseCode}
          onChange={handlePurchaseCodeChange}
        />
        <FormHelperText>{errors.purchaseCode}</FormHelperText>
      </FormControl>
      }
      <br/>

      <Button color="rose" onClick={handleSubmit} disabled={saving}>Καταχωριση</Button>
      {alert}
    </div>
  )
}

export default EduLabSignupForm
