const guestUsergroup = {
    "lexicon" : {
        "r" : true,
        "d" : false,
        "w" : false
    },
    "original_text" : {
        "r" : true,
        "d" : [],
        "w" : []
    },
    "translation" : {
        "r" : false,
        "d" : [],
        "w" : []
    },
    "users" : {
        "r" : false,
        "d" : false,
        "w" : false
    },
    'labs_tr': {
      'v': true,
      'r': [],
      'w': false,
      'd': false
    },
    'labs_edu': {
      'v': true,
      'r': [],
      'w': false,
      'd': false
    },
    'labs_tour': {
      'v': true,
      'r': [],
      'w': false,
      'd': false
    },
    'labs_summer': {
      'v': true,
      'r': [],
      'w': false,
      'd': false
    },
    "forums": {
      'r': true,
      'w': false,
      'd': [],
      'wtl': false,
    },
    "edu_src": {
      'r': true,
      'w': false,
      'd': false,
    },
}

//used for tests
const testUsergroup = {
    "lexicon" : {
        "r" : true,
        "d" : true,
        "w" : true
    },
    "original_text" : {
        "r" : true,
        "d" : [],
        "w" : [1]
    },
    "translation" : {
        "r" : true,
        "d" : [],
        "w" : [1]
    },
    "users" : {
        "r" : true,
        "d" : true,
        "w" : true
    },
    'labs_tr': {
      'v': true,
      'r': [1],
      'w': false,
      'd': false
    },
    'labs_edu': {
      'v': true,
      'r': [],
      'w': false,
      'd': false
    },
    'labs_tour': {
      'v': true,
      'r': [2],
      'w': true,
      'd': false
    },
    'labs_summer': {
      'v': true,
      'r': [],
      'w': false,
      'd': false
    },
    "forums": {
      'r': true,
      'w': true,
      'd': [1,2],
      'wtl': true,
    },
    "edu_src": {
      'r': true,
      'w': true,
      'd': false,
    },
}

export default function get_rights_from_session(){
  //console.log(localStorage.getItem('UserName'))
  if (localStorage.getItem('UserName')===null || localStorage.getItem('UserName')==='null'){
    return guestUsergroup
  } else {
    return JSON.parse(localStorage.getItem("access_rights"))
    //return testUsergroup
  }
}
