import React from 'react';

//import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Button from "components/CustomButtons/Button.js";
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { searchAutocomplete } from 'clients/concordanceClient.js'
import { mappings_case_insensitive } from "variables/letter_mappings.js"

const SearchForm = (props) => {
  const { onSearch, searchTerm } = props

  const [term, setTerm] = React.useState('')//searchTerm || '')
  const [preciseSearch, setPreciseSearch] = React.useState(false)
  const [withAccentSearch, setWithAccentSearch] = React.useState(false)
  const [errors, setErrors] = React.useState([])

  //const [inputValue, setInputValue] = React.useState('');
  const [wordList, setWordList] = React.useState([])

  /*const handleTermChange = (e) => {
    console.log(e.target.value)
    setTerm(e.target.value)
  }*/

  const handlePreciseSearchChange = (e) => {
    setPreciseSearch(e.target.checked)
  }

  const handleWithAccentSearchChange = (e) => {
    setWithAccentSearch(e.target.checked)
  }

  const handleSearch = () => {
    let newErrors = Object.assign({}, errors)
    newErrors.term = validateTerm(term)
    setErrors(newErrors)

    if (newErrors.term === ''){
      //console.log(term)
      const params = {searchTerm: term, exact_search: preciseSearch, with_accents: withAccentSearch}
      onSearch(params)
    }
  }

  const validateTerm = (val) => {
    if (val.trim().length === 0){
      return 'Υποχρεωτικό πεδίο'
    }

    //check only allowed characters
    let allowed_chars = '[^a-zA-Zα-ωΑ-Ω &"\*'
    mappings_case_insensitive.forEach(m => allowed_chars += m['replaceStr'])
    allowed_chars += ']'
    const pattern = new RegExp(allowed_chars)
    if (pattern.test(val)){
      return 'Μη έγκυροι χαρακτήρες - επιτρέπονται μόνο γράμματα, κενό, εισαγωγικά ", & και *'
    }

    //check " exist in pairs
    const matches = val.match(/"/g)
    if (matches && matches.length % 2 !== 0){
      return 'Για την αναζήτηση φράσης η φράση πρέπει να περικλείεται σε εισαγωγικά ". Έχετε ξεχάσει ένα από τα εισαγωγικά'
    }

    let val_formatted = val.trim().replace(/&/g, 'AND').toUpperCase()
    if (val_formatted.search('NOT +OR +') >= 0){
      return 'Μη έγκυρη έκφραση: NOT OR.'
    }
    if (val_formatted.search('NOT +AND +') >= 0){
      return 'Μη έγκυρη έκφραση: NOT AND.'
    }
    if (val_formatted.search('AND +OR +') >= 0){
      return 'Μη έγκυρη έκφραση: AND OR.'
    }
    if (val_formatted.search('OR +AND +') >= 0){
      return 'Μη έγκυρη έκφραση: OR AND.'
    }

    const or_terms = val_formatted.split('OR')
    for (let i in or_terms){
        const term = or_terms[i].trim()
        const and_terms = term.split('AND')

        let ok = false
        for (let j in and_terms){
            const t = and_terms[j].trim()
            const not_term = t.split('NOT')
            //console.log(and_terms)
            //console.log(not_term)
            for (let k in not_term){
                const errMsg = check_individual_term_doesnt_have_blanks(not_term[k].trim())
                if (errMsg !== ''){
                  return errMsg
                } else if (and_terms.length === 1){
                  //console.log(trimEndChar(trimStartChar(not_term[k].trim(), '*'), '*'))
                  if (trimEndChar(trimStartChar(not_term[k].trim(), '*'), '*') === ''){
                      if (not_term.length > 1) {
                          return 'Δεν επιτρέπεται NOT μόνο του ή μόνο με OR. Το NOT πρέπει να χρησιμοποιείται σε συνδυασμό με AND'
                      } else {
                          return 'Κενός τελεστής'
                      }
                  } else {
                      ok = true
                  }
                } else {
                  //console.log(trimEndChar(trimStartChar(not_term[k].trim(), '*'), '*'))
                  if (k > 0) {
                      if (trimEndChar(trimStartChar(not_term[k].trim(), '*'), '*') === ''){
                          return 'Κενός τελεστής'
                      }
                  } else if (trimEndChar(trimStartChar(not_term[k].trim(), '*'), '*') !== ''){
                      ok = true
                  }
                }
          }
        }

        if (!ok){
            return 'Δεν επιτρέπεται NOT μόνο του ή μόνο με OR. Το NOT πρέπει να χρησιμοποιείται σε συνδυασμό με AND'
        }
      }

    return  ''
  }

  const check_individual_term_doesnt_have_blanks = (term) => {
    if (term.startsWith('"') && term.endsWith('"')){
        //exact phrase can have blanks
        return ''
    } else{
        const words = term.split(' ')
        return (words.length > 1) ?
          'Ανάμεσα στις λέξεις πρέπει να υπάρχει είτε &/AND, είτε OR, είτε NOT, εκτός αν πρόκειται για ακριβή φράση (περικλείεται σε "")' :
          ''
    }
  }

  const getTermForAutocompleteSearch = () => {
    let strippedTerm = term.trim()

    if (strippedTerm.startsWith('*')){
      // TODO:
      return ''
    } else {
      strippedTerm = trimEndChar(
        trimEndChar(
          trimStartChar(strippedTerm, '"'),
          '"'),
        '*')
      // TODO: what to check for if contains and or not etc
      if (strippedTerm.includes('*')){
        // TODO:
        return ''
      } else {
        return strippedTerm
      }
    }
  }

  const trimStartChar = (str, charToTrim) => {
    return str.startsWith(charToTrim) ? str.substr(1) : str
  }

  const trimEndChar = (str, charToTrim) => {
    return str.endsWith(charToTrim) ? str.substr(0, str.length - 1) : str
  }

  const delay = ms => new Promise(r => setTimeout(r, ms));
  //https://mui.com/components/autocomplete/#search-as-you-type
  // TODO: throttling requests???
  React.useEffect(() => {
    const strippedTerm = getTermForAutocompleteSearch()
    //console.log(strippedTerm)

    if (strippedTerm !== ''){
      //console.log('search')
      searchAutocomplete(strippedTerm,
        (data) => {
          setWordList(data.list);
          delay(100)
        },
        (err) => {
          setWordList([])
          console.log(err)
          delay(100)
        }
      )

    } else {
      setWordList([])
    }
  }, [term])

  return(
    <Grid id="mainsearchform" container>
      <Grid item xs={6}>
        {/*<TextField
         error={Boolean(errors.term)}
         label='Αναζήτηση'
         style = {{width:'100%', paddingTop:10, marginTop:0}}
         value={term}
         onChange={handleTermChange}
         />*/}
        <Autocomplete
          id="term-search"
          options={wordList}
          autoComplete freeSolo
          getOptionLabel={(option) => option.toString()}
          getOptionSelected={(option, value) => option.toString() === value.toString()}
          filterOptions={(x) => x}
          value={term}
          onInputChange={(event, newInputValue) => {
            //setInputValue(newInputValue);
            setTerm(newInputValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              error={Boolean(errors.term)}
              label='Αναζήτηση'
              style = {{width:'100%', paddingTop:10, marginTop:0}}
            />
          )}
        />
        <FormHelperText error>{errors.term}</FormHelperText>

      </Grid>
      <Grid id="belowsearch" item xs={6}>
        <Button onClick={handleSearch}>Βρες</Button>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h6' component='div'>Επιλογές αναζήτησης</Typography>
        <FormControlLabel control={
          <Checkbox size='small' checked={preciseSearch} onChange={handlePreciseSearchChange} />
        } label='Ακριβής αναζήτηση' />
        <FormControlLabel control={
          <Checkbox size='small' checked={withAccentSearch} onChange={handleWithAccentSearchChange} />
        } label='Ο τονισμός είναι σημαντικός' />
      </Grid>
    </Grid>
  )
}

export default SearchForm
