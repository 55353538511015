import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';

import EditIcon from '@material-ui/icons/Edit';
import ReadIcon from '@material-ui/icons/EventNote'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Button from "components/CustomButtons/Button.js";

import { getAllEduSources } from 'clients/eduSourcesClient.js'
import { eduSourcesFilesURL } from 'clients/clientConfig.js'
import queryString from 'query-string'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  intro: {
    textAlign: 'justify',
  },
  centered: {
    textAlign: 'center',
  },
  introImage: {
    height: 120,
    width: 180,
    margin: 'auto',
    display: 'block'
  },
  creators: {
    fontWeight: 'bold'
  },
  title: {
    position: "absolute",
    //color: "blue",
    top: "50%",
    left: "50%",
  }
}))

const EduSources = (props) => {
  const classes = useStyles()
  const parse = require('html-react-parser');

  const [eduSources, setEduSources] = React.useState([])
  const [filteredEduSources, setFilteredEduSources] = React.useState([])
  const [tagToFilterBy, setTagToFilterBy] = React.useState('')

  React.useEffect(() => {
    getAllEduSources(
      (data) => setEduSources(data.edu_sources),
      (err) => console.log(err)
    )
  }, [])

  React.useEffect(() => {
    const qry_params = queryString.parse(props.location.search)
    setTagToFilterBy(qry_params.tag)
  },  [props.location.search])

  React.useEffect(() => {
    if (tagToFilterBy!==undefined && tagToFilterBy!==null && tagToFilterBy!==''){
      //console.log('filter'+tagToFilterBy)
      setFilteredEduSources(eduSources.filter(e => e.tags && e.tags.includes(tagToFilterBy)))
    } else {
      setFilteredEduSources(eduSources)
    }
  }, [eduSources, tagToFilterBy])

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} >
        <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Typography color="textPrimary">Εκπαιδευτικοί-Πολυμεσικοί Πόροι</Typography>
          </Breadcrumbs>
        </div>
      </Grid>

     <Grid item xs={12} style={{ paddingTop : '20px'}}>
      <Container maxWidth="md">

        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.centered}>
            <Card>
              <div style={{ position: "relative" }}>
                <CardMedia className="hidden" style={{ width: "100%"}}  component="img" image={"/8_poroi-eis.jpg"} title="" alt=""/>
                <Typography color="primary" variant="h5" className={classes.title}>Εκπαιδευτικοί-Πολυμεσικοί Πόροι</Typography>
              </div>
            </Card>
            <p className={classes.intro}>
            </p>
            {
              access_rights.edu_src && access_rights.edu_src.w &&
              <Button justIcon round color="primary" onClick={() => window.location.href='/admin/EduSourceEdit/'}><AddIcon/></Button>
            }
            {
              tagToFilterBy &&
              <Typography variant='body1' align='center'>
                Φιλτράρισμα με βάση λέξη-κλειδί: {tagToFilterBy}
                <Button justIcon round onClick={() => setTagToFilterBy('')} color='danger' size='sm'><HighlightOffIcon/></Button>
              </Typography>
            }
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {filteredEduSources && filteredEduSources.map((es) =>
            <Grid key={es.id} item xs={12} sm={4}>
              <Card>

                <CardActionArea href={"/admin/EduSourceMain/"+es.id}>
                  <CardHeader title={es.title} className="AristosCardHeader"/>
                  <CardMedia className={classes.introImage} image={eduSourcesFilesURL + es.id + '/' + es.image} title={es.title}/>
                  <CardContent>
                    <Typography variant='body2' component='div'>
                    {
                      es.creators &&
                      <div className={classes.creators}>
                        {es.creators}
                      </div>
                    }
                    {parse(es.intro || '')}
                    </Typography>
                  </CardContent>
                </CardActionArea>

                <CardActions>
                  <Tooltip title='Προβολή'>
                    <IconButton aria-label="go to view" href={"/admin/EduSourceMain/"+es.id}><ReadIcon style={{color:"#387BE1", marginTop:"2px"}}/></IconButton>
                  </Tooltip>
                  {
                    access_rights.edu_src && access_rights.edu_src.w &&
                    <Tooltip title='Επεξεργασία'>
                      <IconButton aria-label="go to edit" href={"/admin/EduSourceEdit/"+es.id}><EditIcon style={{color:"#EBBA1A"}}/></IconButton>
                    </Tooltip>
                  }
                </CardActions>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </Grid>

  </Grid>
  </div>
  )
}

export default EduSources
