import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import ContentSearch from './ContentSearch.js'
import queryString from 'query-string'

import { getLabMainPage, getLabTypeName } from './utils.js'
import { searchLabPages } from 'clients/labEducationEtcClient.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  paper: {
    padding: 20
  },
}))

const SearchResults = (props) => {
  const classes = useStyles()

  const [lab, setLab] = React.useState({})
  const [search, setSearch] = React.useState('')
  const [results, setResults] = React.useState([])
  const [errorMessage, setErrorMessage] = React.useState('')
  const [searching, setSearching] = React.useState(true)

  React.useEffect(()=>{
    const qry_params = queryString.parse(props.location.search)
    //console.log(qry_params.search)

    if (props.match.params.lab_id && qry_params.search){
      setSearch(qry_params.search)

      searchLabPages('?lab_id='+props.match.params.lab_id+'&search='+qry_params.search,
        (data) => {
          setLab(data.lab)
          setResults(data.pages)
          setSearching(false)
        },
        (err) => {
          setErrorMessage(err.message)
          setSearching(false)
        }
      )

    } else {
      window.location.href = '/admin/EduLabFullView/' + props.match.params.lab_id
    }
  }, [props.match.params.lab_id, props.location.search])

  return(
    <div className={classes.root}>
        <CssBaseline />
        <Sidebar/>

        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} >
            <div>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                  <Link color="inherit" href="/admin/SelectTransSem">
                    Main
                  </Link>
                  <Link color='inherit' href={getLabMainPage(lab.lab_type || '')}>
                    {getLabTypeName(lab.lab_type || '')}
                  </Link>
                  <Link color='inherit' href={"/admin/EduLabPreview/"+lab.id}>{lab.title || ''}</Link>
                  <Typography color="textPrimary">Αποτελέσματα αναζήτησης</Typography>
              </Breadcrumbs>
            </div>
          </Grid>

         <Grid item xs={12} style={{ paddingTop : '20px'}}>
           <Container maxWidth="md">
            <ContentSearch lab_id={lab.id} search={search}/>

            <Paper className={classes.paper}>

              <Typography variant='h4'>
                {lab.title}
              </Typography>
              <Typography variant='h5'>Αναζήτηση για: {search}</Typography>

              {
                errorMessage &&
                <Typography variant='body1' color='error'>{errorMessage}</Typography>
              }

              <List>
              {
                results && results.map(r =>
                  <ListItem key={r.id}>
                    <Link href={'/admin/EduLabViewPage/'+r.id}>{r.title}</Link>&nbsp;
                    {!r.visible && <VisibilityOffIcon color='disabled'/>}
                  </ListItem>
                )
              }
              </List>
              {
                !searching && results && results.length===0 &&
                <Typography variant='body1'>Δε βρέθηκαν αποτελέσματα</Typography>
              }
            </Paper>
          </Container>
        </Grid>
      </Grid>
    </div>
  )
}

export default SearchResults
