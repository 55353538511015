/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import SelectLyrics from "views/SelectLyrics/SelectLyrics.js";
import BookSelectionPage from "views/Arist/BookSelectionTree.js"
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";

import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import AddLyricsItem from "views/AddLyricsItem/AddLyricsItem.js";
import AddCatErg from "views/AddCatErg/AddCatErg.js";
import AddErg from "views/AddErg/AddErg.js";
import Seminars from "views/Seminars/Seminars.js";
import SeminarStruct from "views/SeminarStruct/SeminarStruct.js";
import AddSeminar from "views/AddSeminar/AddSeminar.js";
import AddBlog from "views/AddBlog/AddBlog.js";
import AddWiki from "views/AddWiki/AddWiki.js";
import AddTranslation from "views/AddTranslation/AddTranslation.js"
import UploadComment from "views/Comments/UploadComment.js"

import SignIn from "views/SignIn/SignIn.js";
import SignUp from "views/SignUp/SignUp.js";
import PasswordReset from "views/PasswordReset/PasswordReset.js"
import PasswordChange from "views/PasswordReset/PasswordChange.js"

import Blog from "views/Blog/Blog.js";
import Wiki from "views/Wiki/Wiki.js";
import WikiCntTable from "views/WikiConceptTable/WikiConceptTable.js";
import WikiReferences from "views/WikiReferences/WikiReferences.js";
import WikiAgenda from "views/WikiAgenda/WikiAgenda.js";
import WikiEnnoiaLink from "views/WikiEnnoiaLink/WikiEnnoiaLink.js";

import Blogs from "views/Blogs/Blogs.js";
import Wikis from "views/Wikis/Wikis.js";


import AddBook from "views/AddBook/AddBook.js";

import CollTranslation from "views/CollTranslation/CollTranslation.js";
import CollTranslationREADONLY from "views/CollTranslationREADONLY/CollTranslationREADONLY.js";
import CommentSearchByTagResults from "views/Comments/CommentSearchByTagResults.js"

import SelectTransSem from "views/SelectTransSem/SelectTransSem.js";
import UserProfile from "views/UserProfile/UserProfile.js";




import Icons from "views/Icons/Icons.js";

import AdminUsersGroupsPrivs from "views/AdminUsersGroupsPrivs/AdminUsersGroupsPrivs.js";
import AdminErgBook from "views/AdminErgBook/AdminErgBook.js";

import Concordance from "views/Concordance/Concordance.js"

import BlogsErgou from "views/BlogsErgou/BlogsErgou.js";
import WikisErgou from "views/WikisErgou/WikisErgou.js";

import Lexicon from "views/Lexicon/Lexicon.js"
import AddLexiconEntry from "views/Lexicon/AddLexiconEntry.js"
import EntryNotFound from "views/Lexicon/EntryNotFound.js"
import LexiconCats from "views/LexiconCats/LexiconCats.js"

import TranslationReceptionLabs from "views/Labs/TranslationReceptionLabs.js"
import EducationalLabs from "views/Labs/EducationalLabs.js"
import TouringLabs from "views/Labs/TouringLabs.js"
import SummerSchools from "views/Labs/SummerSchools.js"

import LabView from "views/Labs/TranslationReception/LabView.js"
import LabEdit from "views/Labs/TranslationReception/LabEdit.js"
import SeminarView from "views/Labs/TranslationReception/SeminarView.js"
import SeminarEdit from "views/Labs/TranslationReception/SeminarEdit.js"

import EduLabPreview from "views/Labs/Educational/EduLabPreview.js"
import EduLabFullView from "views/Labs/Educational/EduLabFullView.js"
import EduLabEdit from "views/Labs/Educational/EduLabEdit.js"
import LabNotFound from "views/Labs/Educational/LabNotFound.js"
import EduLabEditPage from "views/Labs/Educational/EduLabEditPage.js"
import EduLabViewPage from "views/Labs/Educational/EduLabViewPage.js"
import PageNotFound from "views/Labs/Educational/PageNotFound.js"
import EduLabSignup from 'views/Labs/Educational/EduLabSignup.js'
import EduLabSignupResults from 'views/Labs/Educational/EduLabSignupResults.js'
import EduLabSearchResults from 'views/Labs/Educational/SearchResults.js'
import EduLabSelectComments from 'views/Labs/Educational/EduLabSelectComments.js'

import AdminEduLabs from 'views/AdminEduLabs/AdminEduLabs.js'

import ForumContainer from 'views/Forums/containers/forum'
import ThreadContainer from 'views/Forums/containers/thread'
import ForumSearchContainer from 'views/Forums/containers/search'

import ForbiddenAccess from "views/Forbidden/ForbiddenAccess.js"

import EduSources from "views/EduSources/EduSources.js"
import EduSourceEdit from "views/EduSources/EduSourceEdit.js"
import EduSourceNotFound from "views/EduSources/EduSourceNotFound.js"
import EduSourceMain from "views/EduSources/EduSourceMain.js"

import get_rights_from_session from 'rights_from_session.js'
// core components/views for RTL layout
/* import RTLPage from "views/RTLPage/RTLPage.js"; */

const access_rights = get_rights_from_session()
//console.log(access_rights)

const canViewAnyEducationalEtcLab =
        (access_rights.labs_edu && access_rights.labs_edu.v) ||
        (access_rights.labs_tour && access_rights.labs_tour.v) ||
        (access_rights.labs_summer && access_rights.labs_summer.v)

/*const canReadAnyEducationalEtcLab =
      (access_rights.labs_edu &&
        (access_rights.labs_edu.r.length > 0 || access_rights.labs_edu.w)) ||
      (access_rights.labs_tour &&
        (access_rights.labs_tour.r.length > 0 || access_rights.labs_tour.w)) ||
      (access_rights.labs_summer &&
        (access_rights.labs_summer.r.length > 0 || access_rights.labs_summer.w))*/

const canEditAnyEducationalEtcLab =
      (access_rights.labs_edu && access_rights.labs_edu.w) ||
      (access_rights.labs_tour && access_rights.labs_tour.w) ||
      (access_rights.labs_summer && access_rights.labs_summer.w)

const dashboardRoutes = [

  /*{
    path: "/AddCatErg",
    name: "",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "content_paste",
    component: AddCatErg,
    layout: "/admin"
  },

  {
    path: "/AddErg",
    name: "",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "content_paste",
    component: AddErg,
    layout: "/admin"
  },

  {
    path: "/AddBook",
    name: "",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "content_paste",
    component: AddBook,
    layout: "/admin"
  },*/
  {
    path: "/ForbiddenAccess",
    name: "",
    component: ForbiddenAccess,
    layout: "/admin",
    access: true,
  },
  {
    path: "/AddLyricsItem",
    name: "",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "content_paste",
    component: AddLyricsItem,
    layout: "/admin",
    access: access_rights.original_text && access_rights.original_text.w.length > 0,
  },
  {
    path: "/AddTranslation",
    name: "",
    icon: "content_paste",
    component: AddTranslation,
    layout: "/admin",
    access: access_rights.translation && access_rights.translation.w,
  },

  {
  path: "/SelectLyrics/:book_id",
  name: "",
  rtlName: "لوحة القيادةss",
  icon: Dashboard,
  component: SelectLyrics,
  layout: "/admin",
  access: (access_rights.original_text && access_rights.original_text.r) ||
    (access_rights.translation && access_rights.translation.r),
  },

  {
  path: "/bookSelection",
  name: "",
  rtlName: "لوحة القيادةbookSel",
  icon: Dashboard,
  component: BookSelectionPage,
  layout: "/admin",
  access: (access_rights.original_text && access_rights.original_text.r) ||
    (access_rights.translation && access_rights.translation.r),
},

{
path: "/CollTranslation/:section_id",
name: "",
rtlName: "لوحة القيادةbookSel",
icon: Dashboard,
component: CollTranslation,
layout: "/admin",
access: (access_rights.original_text && access_rights.original_text.w.length > 0) ||
  (access_rights.translation && access_rights.translation.w.length > 0),
},

{
path: "/CollTranslationREADONLY/:section_id",
name: "",
rtlName: "لوحة القيادةbookSel",
icon: Dashboard,
component: CollTranslationREADONLY,
layout: "/admin",
access: (access_rights.original_text && access_rights.original_text.r) ||
  (access_rights.translation && access_rights.translation.r),
},

{
  path: "/CommentSearchByTagResults/:tag",
  name: "",
  icon: Dashboard,
  component: CommentSearchByTagResults,
  layout: "/admin",
  access: (access_rights.original_text && access_rights.original_text.r) ||
    (access_rights.translation && access_rights.translation.r),
},


{
path: "/SelectTransSem",
name: "",
rtlName: "لوحة القيادةbookSel",
icon: Dashboard,
component: SelectTransSem,
layout: "/admin",
access: true,
},
/*{
path: "/Seminars",
name: "",
rtlName: "لوحة القيادةbookSel",
icon: Dashboard,
component: Seminars,
layout: "/admin",
access: true,
},
{
path: "/SeminarStruct",
name: "",
rtlName: "لوحة القيادةbookSel",
icon: Dashboard,
component: SeminarStruct,
layout: "/admin",
access: true,
},
{
path: "/AddSeminar",
name: "",
rtlName: "لوحة القيادةbookSel",
icon: Dashboard,
component: AddSeminar,
layout: "/admin",
access: true,
},
{
path: "/AddBlog",
name: "",
rtlName: "لوحة القيادةbookSel",
icon: Dashboard,
component: AddBlog,
layout: "/admin",
access: true,
},
{
path: "/AddWiki",
name: "",
rtlName: "لوحة القيادةbookSel",
icon: Dashboard,
component: AddWiki,
layout: "/admin",
access: true,
},*/

{
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    access: true,
  },

/*
  {
    path: "/user",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
    access: true,
  },*/





  /*{
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/admin",
    access: true,
  },*/

  {
   path: "/SignIn",
   name: "SignIn",
   rtlName: "إخطارات",
   icon: Dashboard,
   component: SignIn,
   layout: "/admin",
   access: true,
 },
 {
  path: "/SignUp",
  name: "SignUp",
  rtlName: "إخطارات",
  icon: Dashboard,
  component: SignUp,
  layout: "/admin",
  access: true,
},
{
  path: "/PasswordReset",
  name: "PasswordReset",
  icon: Dashboard,
  component: PasswordReset,
  layout: "/admin",
  access: true,
},
{
  path: '/PasswordChange/:token',
  name: "PasswordChange",
  icon: Dashboard,
  component: PasswordChange,
  layout: "/admin",
  access: true,
},
 /*{
  path: "/Blog",
  name: "Blog",
  rtlName: "إخطارات",
  icon: Dashboard,
  component: Blog,
  layout: "/admin",
  access: true,
},
{
 path: "/Blogs",
 name: "Blogs",
 rtlName: "إخطارات",
 icon: Dashboard,
 component: Blogs,
 layout: "/admin",
 access: true,
},

{
 path: "/BlogsErgou",
 name: "BlogsErgou",
 rtlName: "إخطارات",
 icon: Dashboard,
 component: BlogsErgou,
 layout: "/admin",
 access: true,
},
{
 path: "/Wiki",
 name: "Wiki",
 rtlName: "إخطارات",
 icon: Dashboard,
 component: Wiki,
 layout: "/admin",
 access: true,
},
{
 path: "/WikiReferences",
 name: "WikiReferences",
 rtlName: "إخطارات",
 icon: Dashboard,
 component: WikiReferences,
 layout: "/admin",
 access: true,
},

{
 path: "/WikiAgenda",
 name: "WikiAgenda",
 rtlName: "إخطارات",
 icon: Dashboard,
 component: WikiAgenda,
 layout: "/admin",
 access: true,
},

{
 path: "/WikiEnnoiaLink",
 name: "WikiEnnoiaLink",
 rtlName: "إخطارات",
 icon: Dashboard,
 component: WikiEnnoiaLink,
 layout: "/admin",
 access: true,
},

{
 path: "/WikiConceptTable",
 name: "WikiCntTable",
 rtlName: "إخطارات",
 icon: Dashboard,
 component: WikiCntTable,
 layout: "/admin",
 access: true,
},

{
 path: "/Wikis",
 name: "Wikis",
 rtlName: "إخطارات",
 icon: Dashboard,
 component: Wikis,
 layout: "/admin",
 access: true,
},

{
 path: "/WikisErgou",
 name: "WikisErgou",
 rtlName: "إخطارات",
 icon: Dashboard,
 component: WikisErgou,
 layout: "/admin",
 access: true,
},*/


{
  path: "/AdminUsersGroupsPrivs",
  name: "AdminUsersGroupsPrivs",
  rtlName: "رد فعل الطاولة",
  mini: "RT",
  rtlMini: "در",
  component: AdminUsersGroupsPrivs,
  layout: "/admin",
  access: access_rights.users && access_rights.users.r,
},
// TODO: rights for AdminErgBook
{
  path: "/AdminErgBook",
  name: "AdminErgBook",
  rtlName: "رد فعل الطاولة",
  mini: "RT",
  rtlMini: "در",
  component: AdminErgBook,
  layout: "/admin",
  access: access_rights.users && access_rights.users.r,
},
// TODO: rights for logged in user
  {
    path: "/UserProfile",
    name: "UserProfile",
    rtlName: "رد فعل الطاولة",
    mini: "RT",
    rtlMini: "در",
    component: UserProfile,
    layout: "/admin",
    access: (localStorage.getItem('UserName')!==null && localStorage.getItem('UserName')!=='null'),
  },

  {
    path: "/Lexicon/:entry_id?",
    name: "Lexicon",
    icon: Dashboard,
    component: Lexicon,
    layout: "/admin",
    access: access_rights.lexicon && access_rights.lexicon.r,
  },

  {
    path: "/AddLexiconEntry/:entry_id?",
    name: "AddLexiconEntry",
    icon: Dashboard,
    component: AddLexiconEntry,
    layout: "/admin",
    access: access_rights.lexicon && access_rights.lexicon.w,
  },

  {
    path: "/LexiconEntryNotFound",
    name: "LexiconEntryNotFound",
    icon: Dashboard,
    component: EntryNotFound,
    layout: "/admin",
    access: access_rights.lexicon && access_rights.lexicon.r,
  },

  {
    path: "/LexiconCats",
    name: "LexiconCats",
    icon: Dashboard,
    component: LexiconCats,
    layout: "/admin",
    access: access_rights.lexicon && access_rights.lexicon.w,
  },

  {
    path: "/UploadComment",
    name: "UploadComment",
    icon: Dashboard,
    component: UploadComment,
    layout: "/admin",
    access: access_rights.original_text && access_rights.original_text.w.length > 0,
  },

  {
    path: "/TranslationReceptionLabs",
    name: "TranslationReceptionLabs",
    icon: Dashboard,
    component: TranslationReceptionLabs,
    layout: "/admin",
    access: access_rights.labs_tr && access_rights.labs_tr.v,
  },

  {
    path: "/EducationalLabs/:keyword?",
    name: "EducationalLabs",
    icon: Dashboard,
    component: EducationalLabs,
    layout: "/admin",
    access: access_rights.labs_edu && access_rights.labs_edu.v,
  },

  {
    path: "/TouringLabs",
    name: "TouringLabs",
    icon: Dashboard,
    component: TouringLabs,
    layout: "/admin",
    access: access_rights.labs_tour && access_rights.labs_tour.v,
  },

  {
    path: "/SummerSchools",
    name: "SummerSchools",
    icon: Dashboard,
    component: SummerSchools,
    layout: "/admin",
    access: access_rights.labs_summer && access_rights.labs_summer.v,
  },

  {
    path: "/TRLabView/:lab_id",
    name: "TRLabView",
    icon: Dashboard,
    component: LabView,
    layout: "/admin",
    access: access_rights.labs_tr && (access_rights.labs_tr.r.length > 0 || access_rights.labs_tr.w),
  },

  {
    path: "/TRLabEdit/:lab_id?",
    name: "TRLabEdit",
    icon: Dashboard,
    component: LabEdit,
    layout: "/admin",
    access: access_rights.labs_tr && access_rights.labs_tr.w,
  },

  {
    path: "/TRSeminarView/:seminar_id",
    name: "TRSeminarView",
    icon: Dashboard,
    component: SeminarView,
    layout: "/admin",
    access: access_rights.labs_tr &&
      (access_rights.labs_tr.r.length > 0 || access_rights.labs_tr.w),
  },

  {
    path: "/TRSeminarEdit/:lab_id/:seminar_id?",
    name: "TRSeminarEdit",
    icon: Dashboard,
    component: SeminarEdit,
    layout: "/admin",
    access: access_rights.labs_tr && access_rights.labs_tr.w,
  },

  {
    path: "/EduLabPreview/:lab_id",
    name: "EduLabPreview",
    icon: Dashboard,
    component: EduLabPreview,
    layout: "/admin",
    access: canViewAnyEducationalEtcLab,
  },

  {
    path: "/EduLabFullView/:lab_id",
    name: "EduLabFullView",
    icon: Dashboard,
    component: EduLabFullView,
    layout: "/admin",
    access: canViewAnyEducationalEtcLab,
  },

  {
    path: "/EduLabEdit/:lab_type/:lab_id?",
    name: "EduLabEdit",
    icon: Dashboard,
    component: EduLabEdit,
    layout: "/admin",
    access: canEditAnyEducationalEtcLab,
  },

  {
    path: "/LabNotFound",
    name: "LabNotFound",
    icon: Dashboard,
    component: LabNotFound,
    layout: "/admin",
    access: true,
  },

  {
    path: "/EduLabEditPage/:lab_id/:page_id?",
    name: "EduLabEditPage",
    icon: Dashboard,
    component: EduLabEditPage,
    layout: "/admin",
    access: canEditAnyEducationalEtcLab,
  },

  {
    path: "/EduLabViewPage/:page_id",
    name: "EduLabViewPage",
    icon: Dashboard,
    component: EduLabViewPage,
    layout: "/admin",
    access: canViewAnyEducationalEtcLab,
  },

  {
    path: '/EduLabSignup/:lab_id',
    name: "EduLabSignup",
    icon: Dashboard,
    component: EduLabSignup,
    layout: "/admin",
    access: true
  },

  {
    path: '/EduLabSignupResults/:lab_id/:status',
    name: "EduLabSignupResults",
    icon: Dashboard,
    component: EduLabSignupResults,
    layout: "/admin",
    access: canViewAnyEducationalEtcLab
  },

  {
    path: '/AdminEduLabs',
    name: "AdminEduLabs",
    icon: Dashboard,
    component: AdminEduLabs,
    layout: "/admin",
    access: canEditAnyEducationalEtcLab
  },

  {
    path: "/PageNotFound",
    name: "PageNotFound",
    icon: Dashboard,
    component: PageNotFound,
    layout: "/admin",
    access: true,
  },

  {
    path: "/EduLabSearchResults/:lab_id",
    name: "EduLabSearchResults",
    icon: Dashboard,
    component: EduLabSearchResults,
    layout: "/admin",
    access: canViewAnyEducationalEtcLab,
  },

  {
    path: "/EduLabSelectComments/:lab_id",
    name: "EduLabSelectComments",
    icon: Dashboard,
    component: EduLabSelectComments,
    layout: "/admin",
    access: canEditAnyEducationalEtcLab,
  },

  {
    path: "/Concordance/:search?",
    name: "Concordance",
    icon: Dashboard,
    component: Concordance,
    layout: "/admin",
    access: true,
  },

  {
    path: "/Forums",
    name: "Forums",
    icon: Dashboard,
    component: ForumContainer,
    layout: "/admin",
    access: true,
  },

  {
    path: "/Forum/:forumid",
    name: "Subforum",
    icon: Dashboard,
    component: ForumContainer,
    layout: "/admin",
    access: true,
  },

  {
    path: "/Thread/:threadid",
    name: 'Thread',
    icon: Dashboard,
    component: ThreadContainer,
    layout: "/admin",
    access: true,
  },

  {
    path: "/ForumSearch/:keyword",
    name: "ForumSearch",
    icon: Dashboard,
    component: ForumSearchContainer,
    layout: "/admin",
    access: true,
  },

  {
    path: "/EduSources",
    name: 'EduSources',
    icon: Dashboard,
    component: EduSources,
    layout: "/admin",
    access: true,//@todo
  },

  {
    path: "/EduSourceEdit/:edusourceid?",
    name: 'EduSourceEdit',
    icon: Dashboard,
    component: EduSourceEdit,
    layout: "/admin",
    access: access_rights.edu_src && access_rights.edu_src.w
  },

  {
    path: "/EduSourceNotFound",
    name: 'EduSourceNotFound',
    icon: Dashboard,
    component: EduSourceNotFound,
    layout: "/admin",
    access: true,
  },

  {
    path: "/EduSourceMain/:edusourceid",
    name: 'EduSourceMain',
    icon: Dashboard,
    component: EduSourceMain,
    layout: "/admin",
    access: true,//@todo
  },

/*   {
    path: "/rtl-page",
    name: "RTL Support",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RTLPage,
    layout: "/rtl"
  },
  {
    path: "/upgrade-to-pro",
    name: "Upgrade To PRO",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: UpgradeToPro,
    layout: "/admin"
  },*/


];

export default dashboardRoutes;
