import { fetchWithGET, fetchWithMethod } from './clientUtils.js'

function requestPasswordReset(vals, success, errorFunc){
  return fetchWithMethod('forgot_password', 'POST', vals, success, errorFunc)
}

function checkPasswordChangeToken(token, success, errorFunc){
  return fetchWithGET('follow_reset_pass_link/' + token, '', success, errorFunc)
}

function passwordChangeWithToken(vals, success, errorFunc){
  return fetchWithMethod('reset_pass', 'POST', vals, success, errorFunc)
}

export { requestPasswordReset, checkPasswordChangeToken, passwordChangeWithToken }
