import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const LabPageOrderSelection = (props) => {
  const { hierarchy, father, order, handleOrderChange, element_id, page_id } = props

  const [options, setOptions] = React.useState([])
  const [order2, setOrder2] = React.useState('')

  React.useEffect(() => {
    //console.log(father)
    if (hierarchy[0]){
      if (father=='0'){
        setOptions(hierarchy[0].units.filter(u => u.id == '0' || u.id != page_id) || [])
      } else {
        //console.log(hierarchy[0].units)
        for (const [unit_idx, unit] of Object.entries(hierarchy[0].units)){
          //console.log('unit'+unit_idx)
          if (unit.id.toString()==father){
            setOptions(unit.chapters.filter(c => c.id != page_id) || [])
            break
          } else {
            //console.log('ch')
            for (const [chapter_idx, chapter] of Object.entries(unit.chapters)){
              if (chapter.id.toString()==father){
                setOptions(chapter.pages.filter(p => p.id != page_id) || [])
                break
              }
            }
          }
        }
      }
    }
  }, [hierarchy, father, page_id])

  React.useEffect(() => {
    //use this because if options is not full, order will not be found in the options list and there will be an error
    if (options.length > 0) {
      setOrder2(order)
    } else if (father > 0 && order != '') {
      setOrder2(0)
    }
  }, [options, order, father])

  return(
    <FormControl sx={{ m: 1, minWidth: 200 }}>
      <InputLabel id="order-label">Επιλογή σειράς</InputLabel>
      <Select
        style={{minWidth: 200}}
        labelId="order-label"
        id={element_id || 'order'}
        value={order2}
        onChange={handleOrderChange}
        label="Επιλογή σειράς"
        displayEmpty
      >
        {
          father && (father > 0) &&
          <MenuItem value="0">Στην αρχή</MenuItem>
        }
        {options.map((option) => (
          <MenuItem key={option.id} value={option.order}>
            Μετά το "{option.title}"
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default LabPageOrderSelection
