export default function invalidateSessionAndRedirectToSign(){
    localStorage.setItem('UserName', null);
    localStorage.setItem('UserFName', null);
    localStorage.setItem('UserLName', null);
    localStorage.setItem('UserId', null);
    localStorage.setItem('thread_subscriptions', null);
    localStorage.setItem('post_draft', null);
    //localStorage.setItem('token', null);

    window.location.href = "/admin/SignIn"
}
