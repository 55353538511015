import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

const PreviewRoleRights = (props) => {
  const { role, worksForRole, labsForRole, forumsForRole } = props

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Typography variant='h4'>{role.name}</Typography>
      </GridItem>

      <GridItem xs={12}>
        <Divider/>
      </GridItem>

      <GridItem xs={12}>
        <Typography variant='h5'>Αρχαίο κείμενο</Typography>
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.original_text.r} name="" color="primary"/>}
          label="Ανάγνωση"
          />
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.original_text.w} name="" color="primary"/>}
          label="Επεξεργασία"
          />
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.original_text.d} name="" color="primary"/>}
          label="Διαγραφή"
          />
      </GridItem>
      <GridItem xs={12}>
        <Divider/>
      </GridItem>

      <GridItem xs={12}>
        <Typography variant='h5'>Μεταφράσεις</Typography>
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.translation.r} name="" color="primary"/>}
          label="Ανάγνωση"
          />
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.translation.w} name="" color="primary"/>}
          label="Επεξεργασία"
          />
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.translation.d} name="" color="primary"/>}
          label="Διαγραφή"
          />
      </GridItem>
      <GridItem xs={12}>
        <Divider/>
      </GridItem>

      <GridItem xs={12}>
        <Typography variant='h5'>Λεξικό</Typography>
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.lexicon.r} name="" color="primary"/>}
          label="Ανάγνωση"
          />
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.lexicon.w} name="" color="primary"/>}
          label="Επεξεργασία"
          />
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.lexicon.d} name="" color="primary"/>}
          label="Διαγραφή"
          />
      </GridItem>
      <GridItem xs={12}>
        <Divider/>
      </GridItem>

      <GridItem xs={12}>
        <Typography variant='h5'>Χρήστες</Typography>
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.users.r} name="" color="primary"/>}
          label="Ανάγνωση"
          />
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.users.w} name="" color="primary"/>}
          label="Επεξεργασία"
          />
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.users.d} name="" color="primary"/>}
          label="Διαγραφή"
          />
      </GridItem>
      <GridItem xs={12}>
        <Divider/>
      </GridItem>

      <GridItem xs={12}>
        <Typography variant='h5'>Εργαστήρια Μετάφρασης &amp; Πρόσληψης</Typography>
      </GridItem>
      <GridItem xs={3}>
        <FormControlLabel
          control={<Checkbox checked={role.labs_tr.v} name="" color="primary"/>}
          label="Προεπισκόπηση"
          />
      </GridItem>
      <GridItem xs={3}>
        <FormControlLabel
          control={<Checkbox checked={role.labs_tr.r} name="" color="primary"/>}
          label="Ανάγνωση"
          />
      </GridItem>
      <GridItem xs={3}>
        <FormControlLabel
          control={<Checkbox checked={role.labs_tr.w} name="" color="primary"/>}
          label="Επεξεργασία"
          />
      </GridItem>
      <GridItem xs={3}>
        <FormControlLabel
          control={<Checkbox checked={role.labs_tr.d} name="" color="primary"/>}
          label="Διαγραφή"
          />
      </GridItem>
      <GridItem xs={12}>
        <Divider/>
      </GridItem>

      <GridItem xs={12}>
        <Typography variant='h5'>Εργαστήρια Εκπαιδευτικά-Περιηγητικά</Typography>
      </GridItem>
      <GridItem xs={3}>
        <FormControlLabel
          control={<Checkbox checked={role.labs_edu.v} name="" color="primary"/>}
          label="Προεπισκόπηση"
          />
      </GridItem>
      <GridItem xs={3}>
        <FormControlLabel
          control={<Checkbox checked={role.labs_edu.r} name="" color="primary"/>}
          label="Ανάγνωση"
          />
      </GridItem>
      <GridItem xs={3}>
        <FormControlLabel
          control={<Checkbox checked={role.labs_edu.w} name="" color="primary"/>}
          label="Επεξεργασία"
          />
      </GridItem>
      <GridItem xs={3}>
        <FormControlLabel
          control={<Checkbox checked={role.labs_edu.d} name="" color="primary"/>}
          label="Διαγραφή"
          />
      </GridItem>
      <GridItem xs={12}>
        <Divider/>
      </GridItem>

      <GridItem xs={12}>
        <Typography variant='h5'>Φόρουμ</Typography>
      </GridItem>
      <GridItem xs={3}>
        <FormControlLabel
          control={<Checkbox checked={role.forums.r} name="" color="primary"/>}
          label="Ανάγνωση"
          />
      </GridItem>
      <GridItem xs={3}>
        <FormControlLabel
          control={<Checkbox checked={role.forums.w} name="" color="primary"/>}
          label="Επεξεργασία"
          />
      </GridItem>
      <GridItem xs={3}>
        <FormControlLabel
          control={<Checkbox checked={role.forums.d} name="" color="primary"/>}
          label="Διαγραφή/Διαχείριση"
          />
      </GridItem>
      <GridItem xs={3}>
        <FormControlLabel
          control={<Checkbox checked={role.forums.wtl} name="" color="primary"/>}
          label="Διαχείριση ανώτατου επιπέδου"
          />
      </GridItem>
      <GridItem xs={12}>
        <Divider/>
      </GridItem>

      <GridItem xs={12}>
        <Typography variant='h5'>Εκπαιδευτικοί &amp; Πολυμεσικοί πόροι</Typography>
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.edu_src.r} name="" color="primary"/>}
          label="Ανάγνωση"
          />
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.edu_src.w} name="" color="primary"/>}
          label="Επεξεργασία"
          />
      </GridItem>
      <GridItem xs={4}>
        <FormControlLabel
          control={<Checkbox checked={role.edu_src.d} name="" color="primary"/>}
          label="Διαγραφή"
          />
      </GridItem>

      {
        role.define_works &&
        <GridItem xs={12}>
          <Divider/>
          <Typography variant='h5' color='secondary'>
            Χρειάζεται ανάθεση έργων
            {worksForRole && ' (Ανατεθειμένα έργα: ' + worksForRole + ')'}
          </Typography>
        </GridItem>
      }

      {
        role.define_labs &&
        <GridItem xs={12}>
          <Divider/>
          <Typography variant='h5' color='secondary'>
            Χρειάζεται ανάθεση εργαστηρίων
            {labsForRole && ' (Ανατεθειμένα εργαστήρια: ' + labsForRole + ')'}
          </Typography>
        </GridItem>
      }

      {
        role.define_forums &&
        <GridItem xs={12}>
          <Divider/>
          <Typography variant='h5' color='secondary'>
            Χρειάζεται ανάθεση φόρουμ
            {forumsForRole && ' (Ανατεθειμένα φόρουμ: ' + forumsForRole + ')'}
          </Typography>
        </GridItem>
      }
    </GridContainer>
  )

}

export default PreviewRoleRights
