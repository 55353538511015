import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from "components/CustomButtons/Button.js";
import Rating from '@material-ui/lab/Rating';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import LabThumbnail from './LabThumbnail.js'
import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'

import { labsEduImagesURL } from 'clients/clientConfig.js'
import { getLabMainPage, getLabTypeName, getAccessRightsFieldForLabType, calcLabRating } from './utils.js'
import { getOneLab, getOtherLabs, copyLab } from 'clients/labEducationEtcClient.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  paper: {
    padding: 20
  },
  centered: {
    textAlign: 'center',
  },
  bordered: {
    border: '1px #808080 solid',
    padding: 10,
  },
  labImage: {
    height: 120,
    width: 180,
    margin: 'auto',
    display: 'block'
  },
  personImage: {
    height: 100,
    width: 100,
    margin: 'auto',
    display: 'block'
  },
  gridcell: {
    marginBottom: '10px',
    marginTop: '10px',
  }
}))

const EduLabPreview = (props) => {
  const classes = useStyles()
  const parse = require('html-react-parser');

  const [otherLabs, setOtherLabs] = React.useState([])
  const [lab, setLab] = React.useState({})
  const [disableCopy, setDisableCopy] = React.useState(false)

  const labs_type_access = getAccessRightsFieldForLabType(lab.lab_type)
  const ratings = calcLabRating(lab)
  //console.log(ratings)

  const canReadLab = () => {
    return access_rights && labs_type_access &&
      (access_rights[labs_type_access].w  || access_rights[labs_type_access].r.includes(lab.id) || lab.freeAccess)
  }

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  const handleCopyLab = () => {
    setDisableCopy(true)
    const params = {lab_id: lab.id}
    //console.log(params)
    copyLab(params,
      (data) => window.location.href = '/admin/EduLabFullView/' + data.inserted_id,
      (err) => {
        setDisableCopy(false)
        setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
      }
    )
  }

  React.useEffect(() => {
    if (props.match.params.lab_id){
      getOneLab(props.match.params.lab_id, '',
        (data) => {
          if (data.education_labs.length > 0){
            setLab(data.education_labs[0])
          } else {
            window.location.href = '/admin/LabNotFound'
          }
        },
        (err) => window.location.href = '/admin/LabNotFound'
      )
    }
  }, [props.match.params.lab_id])

  React.useEffect(() => {
    if (lab.id){
      getOtherLabs('?lab_type=' + lab.lab_type + '&not_id=' + lab.id,
        (data) => setOtherLabs(data.labs),
        (err) => console.log(err)
      )
    }
  }, [lab])

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} >
        <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color='inherit' href={getLabMainPage(lab.lab_type || '')}>
                {getLabTypeName(lab.lab_type || '')}
              </Link>
              <Typography color="textPrimary">{lab.title || ''}</Typography>
          </Breadcrumbs>
        </div>
      </Grid>

     <Grid item xs={12} style={{ paddingTop : '20px'}}>
       <Container id="labpreview" maxWidth="md">
        <Paper className={classes.paper}>

          <Grid container>
            <Grid item xs={4}>
              {lab.image && <img alt='' src={labsEduImagesURL+lab.id+'/'+lab.image} className={classes.labImage}/>}
            </Grid>
            <Grid item xs={8} className={classes.bordered}>
              <Typography variant='h4'>
                {lab.title}&nbsp;
                {!lab.visible && <VisibilityOffIcon color='disabled'/>}&nbsp;
                {
                  access_rights && labs_type_access && access_rights[labs_type_access].w &&
                  <span style={{float: 'right'}}>
                    <Button justIcon round color="primary"
                      onClick={() => window.location.href='/admin/EduLabEdit/'+lab.lab_type+"/"+lab.id}><EditIcon/></Button>
                    <Button justIcon round color="primary"
                      onClick={handleCopyLab} disabled={disableCopy}><FileCopyIcon/></Button>
                  </span>
                }
              </Typography>
              <Typography variant="body1" component='div'>
                {parse(lab.info || '')}
              </Typography>
              <Typography variant="body2">
                <Rating name="read-only" value={ratings} readOnly precision={0.1} />
                {ratings} Αξιολόγηση χρηστών
              </Typography>
            </Grid>

            {
              lab.tags && lab.tags.length > 0 &&
              <Grid item xs={12} className={classes.gridcell}>
                <Typography variant='h5'>Λέξεις - κλειδιά</Typography>
                {
                  lab.tags.map((t, idx) =>
                    <Link key={idx} href={'/admin/EducationalLabs/'+t}>{t} | </Link>
                  )
                }
              </Grid>
            }

            <Grid item xs={12} className={classes.gridcell}>
              <Typography variant='h5'>Επιστημονικός Υπεύθυνος</Typography>
            </Grid>
            <Grid item xs={4}>
              {
                lab.responsible && lab.responsible.image &&
                <img alt={lab.responsible.fullname} src={labsEduImagesURL+lab.id+'/'+lab.responsible.image} className={classes.personImage}/>
              }
            </Grid>
            <Grid item xs={8} className={classes.bordered}>
              {
                lab.responsible &&
                <Typography variant='body1'>
                  {lab.responsible.fullname}<br/>
                  Ιδιότητα: {lab.responsible.specialty}<br/>
                  Λίγα λόγια: {lab.responsible.info}
                </Typography>
              }
            </Grid>

            <Grid item xs={12} className={classes.gridcell}>
              <Typography variant='h5'>Περιγραφή</Typography>
              <Typography variant='body1' component='div'>{parse(lab.description || '')}</Typography>
              <br/>
              <Typography variant='h5'>Άλλες πληροφορίες</Typography>
              <Typography variant='body1' component='div'>
                Διάρκεια παρακολούθησης: {lab.duration}<br/>
                Ημερομηνία διεξαγωγής: {lab.labDateFmt}<br/>
                Τόπος διεξαγωγής: {lab.location}<br/>
                Απαιτήσεις για την παρακολούθηση:<br/>
                {parse(lab.requirements || '')}<br/>
                Κόστος συμμετοχής: {lab.cost || 0} &euro;
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.centered}>
              <Button color='primary' onClick={() => window.location.href = '/admin/EduLabFullView/' + lab.id} disabled={!canReadLab()}>Είσοδος στο εργαστηριο</Button>
            {
              !canReadLab() &&
              <Button color='primary' onClick={() => window.location.href = '/admin/EduLabSignup/' + lab.id}>Εγγραφή στο εργαστηριο</Button>
            }
            </Grid>

            {
              lab.comments && lab.comments.length > 0 &&
              <Grid item xs={12} className={classes.gridcell}>
                <Typography variant='h5'>Σχόλια χρηστών</Typography>
                {
                  lab.comments.map((c, idx) =>
                    <div key={idx}>
                      <Typography variant='subtitle2' component='div'>{c.user_fullname}</Typography>
                      <Typography variant='body2'>{c.text}</Typography>
                    </div>
                )}
              </Grid>
            }

            {
              otherLabs &&
              <Grid item xs={12} className={classes.gridcell}>
                <Typography variant='h5'>Άλλα εργαστήρια των Αριστοτελιστών</Typography>
                <Grid container spacing={2}>
                  {otherLabs && otherLabs.map((other_lab) =>
                    <Grid key={other_lab.id} item xs={12} sm={4}>
                      <LabThumbnail lab={other_lab} writeAccess={access_rights && labs_type_access && access_rights[labs_type_access].w}/>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            }

          </Grid>
         </Paper>
       </Container>

       {alert}
      </Grid>
  </Grid>
  </div>
  )
}

export default EduLabPreview
