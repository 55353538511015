import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'

import AddLexiconCatForm from './AddLexiconCatForm.js'


const AddLexiconCatDialog = (props) => {
  const { open, handleClose, dialogTitle, ...rest } = props

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}
      maxWidth={"xs"}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent dividers>

          <AddLexiconCatForm handleClose={handleClose} {...rest}/>

        </DialogContent>
    </Dialog>
  )
}


export default AddLexiconCatDialog
