import React from "react";
// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';


import avatar from "assets/img/faces/marc.jpg";

import Sidebar from "components/Sidebar/Sidebar.js";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }





}));






export default function Wiki() {

  const [direction, setDirection] = React.useState('right');
  const [hidden, setHidden] = React.useState(false);
  const [spacing, setSpacing] = React.useState(2);

  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const handleHiddenChange = (event) => {
    setHidden(event.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };



  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };






  return (

    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>




      <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} >
        <div>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" href="/admin/SelectTransSem">
              Main
            </Link>

            <Link color="inherit" href="/admin/Wiki">
              Wiki
            </Link>
        </Breadcrumbs>
        </div>
      </Grid>
       <Grid item xs={12} style={{ paddingTop : '20px', maxWidth:1200, margin:'auto'}}>
         <Grid container justify="center" spacing={spacing}>
		 
		 
		                      <Card>
                       <CardHeader color="primary">
                                   Wiki
                       </CardHeader>
                       <CardBody>
					   
                  <iframe style={{ display:'block', margin : 'auto'}} src="http://150.140.90.140/wiki/index.php/%CE%92%CE%BF%CF%85%CE%B3%CE%B5%CE%BD%CE%AD%CF%82_%CE%95%CE%BC%CF%80%CE%B5%CE%B4%CE%BF%CE%BA%CE%BB%CE%AE%CF%82" height="1000" width="100%"  frameBorder="0" title="Iframe Example"></iframe>
				  
				  </CardBody>
				  </Card>
				  
         </Grid>
       </Grid>
      </Grid>
      </div>


  );
}
