import {
  FETCH_HOME_REQUEST,
  FETCH_HOME_SUCCESS,
  FETCH_HOME_FAILURE,
  FETCH_FORUM_REQUEST,
  FETCH_FORUM_SUCCESS,
  FETCH_FORUM_FAILURE,
  CREATE_FORUM_REQUEST,
  CREATE_FORUM_SUCCESS,
  CREATE_FORUM_FAILURE,
  CHANGE_FORUM_THREAD_PAGE,
  DELETE_FORUM_REQUEST,
  DELETE_FORUM_SUCCESS,
  DELETE_FORUM_FAILURE,
} from './types';
import {fetchForumApi, createForumApi, editForumApi, fetchForumsApi,
  massCreateForumsApi, deleteForumApi} from '../api';
import {apiErrorHandler} from '../utils/errorhandler';

export const fetchForums = () => dispatch => {
  dispatch(fetchHomeRequest());

  fetchForumsApi(0,
    (response) => {
      dispatch(fetchHomeSuccess(response.forums));
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(fetchHomeFailure(errorMessage));
    });
};

export const fetchHomeRequest = () => {
  return {
    type: FETCH_HOME_REQUEST,
  };
};

export const fetchHomeSuccess = data => {
  return {
    type: FETCH_HOME_SUCCESS,
    forums: data,
    father: 0,
  };
};

export const fetchHomeFailure = error => {
  return {
    type: FETCH_HOME_FAILURE,
    error,
  };
};

export const fetchForum = forum => dispatch => {
  dispatch(fetchForumRequest());

  fetchForumApi(forum,
    (data) => {
      dispatch(fetchForumSuccess(data));
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(fetchForumFailure(errorMessage));
    });
};

export const fetchForumRequest = () => {
  return {
    type: FETCH_FORUM_REQUEST,
  };
};

export const fetchForumSuccess = data => {
  return {
    type: FETCH_FORUM_SUCCESS,
    name: data.forum.name,
    id: data.forum.id,
    description: data.forum.description,
    threads: data.forum.threads || [],
    forums: data.subforums,
    father: data.forum.id,
    parents: data.parents,
    isPrivate: data.forum.isPrivate || false,
    lab: data.forum.lab || '',
    work: data.forum.work || '',
  };
};

export const fetchForumFailure = error => {
  return {
    type: FETCH_FORUM_FAILURE,
    error,
  };
};

const reloadForum = father => dispatch => {
  if (father===0){
    //console.log('top level')
    fetchForumsApi(0,
      (response) => {
        dispatch(fetchHomeSuccess(response.forums));
      },
      (error) => {
        const errorMessage = apiErrorHandler(error);
        dispatch(fetchHomeFailure(errorMessage));
      });

  } else {
    //console.log(father)
    fetchForumApi(father,
      (data) => {
        dispatch(fetchForumSuccess(data));
      },
      (error) => {
        const errorMessage = apiErrorHandler(error);
        dispatch(fetchForumFailure(errorMessage));
      });
  }
}

export const createForum = newForum => dispatch => {
  dispatch(createForumRequest(newForum));

  if (newForum.id){
    editForumApi(newForum,
      (response) => {
        dispatch(createForumSuccess());

        dispatch(reloadForum(newForum.father))
      },
      (error) => {
        const errorMessage = apiErrorHandler(error);
        dispatch(createForumFailure(errorMessage));
      }
    )
  } else {
    createForumApi(newForum,
      (response) => {
        dispatch(createForumSuccess());

        dispatch(reloadForum(newForum.father))
      },
      (error) => {
        const errorMessage = apiErrorHandler(error);
        dispatch(createForumFailure(errorMessage));
      }
    )
  }
};

export const createForumRequest = newForum => {
  return {
    type: CREATE_FORUM_REQUEST,
    newForum,
  };
};

export const createForumSuccess = () => {
  return {
    type: CREATE_FORUM_SUCCESS,
  };
};

export const createForumFailure = error => {
  return {
    type: CREATE_FORUM_FAILURE,
    error,
  };
};

export const massCreateForums = newForumData => dispatch => {
  dispatch(createForumRequest({}));

  massCreateForumsApi(newForumData,
    (response) => {
      dispatch(createForumSuccess());

      dispatch(reloadForum(newForumData.father))
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(createForumFailure(errorMessage));
    }
  )
};

export const deleteForum = (forumid, father) => dispatch => {
  dispatch(deleteForumRequest(forumid));

  deleteForumApi(forumid,
    (response) => {
      dispatch(deleteForumSuccess());

      dispatch(reloadForum(father))
    },
    (error) => {
      const errorMessage = apiErrorHandler(error);
      dispatch(deleteForumFailure(errorMessage));
    }
  )
}

export const deleteForumRequest = (forumid) => {
  return {
    type: DELETE_FORUM_REQUEST,
    forumid,
  }
}

export const deleteForumSuccess = () => {
  return {
    type: DELETE_FORUM_SUCCESS,
  };
};

export const deleteForumFailure = error => {
  return {
    type: DELETE_FORUM_FAILURE,
    error,
  };
};

export const changeForumThreadPage = page => {
  return {
    type: CHANGE_FORUM_THREAD_PAGE,
    page,
  }
}
