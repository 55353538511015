import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";

import LabsMainPage from './Educational/LabsMainPage.js'
import { getAllLabsForType } from 'clients/labEducationEtcClient.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
}))

const TouringLabs = () => {
  const classes = useStyles()

  const [labs, setLabs] = React.useState([])
  /*[
    {id: 1, title: 'Εργαστήριο 1', image: 'under_construction.png', date: '2/11/2021', duration: '1 μήνας', info: 'Πληροφορίες για το Εργαστήριο 1', language: 'Ελληνικά', location: 'Αθήνα', lab_type: 'edu'},
    {id: 2, title: 'Εργαστήριο 2', image: 'under_construction.png', date: '10/12/2021', duration: '2 μήνες', info: 'Πληροφορίες για το Εργαστήριο 2', language: 'Ελληνικά', location: 'Αθήνα', lab_type: 'edu'},
    {id: 3, title: 'Εργαστήριο 3', image: 'under_construction.png', date: '31/01/2022', duration: '10 ημέρες', info: 'Πληροφορίες για το Εργαστήριο 3', language: 'Αγγλικά', location: 'Αθήνα', lab_type: 'edu'},
  ]*/

  React.useEffect(() => {
    getAllLabsForType('tour',
      (data) => setLabs(data.education_labs),
      (err) => console.log(err)
    )
  }, [])

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} >
        <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Typography color="textPrimary">Περιηγητικά εργαστήρια</Typography>
          </Breadcrumbs>
        </div>
      </Grid>

     <Grid item xs={12} style={{ paddingTop : '20px'}}>
       <LabsMainPage labs={labs} labtype="tour"/>
     </Grid>
  </Grid>
  </div>
  )
}

export default TouringLabs
