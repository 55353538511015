import {
  FETCH_HOME_REQUEST,
  FETCH_HOME_SUCCESS,
  FETCH_HOME_FAILURE,
  FETCH_FORUM_REQUEST,
  FETCH_FORUM_SUCCESS,
  FETCH_FORUM_FAILURE,
  CREATE_FORUM_REQUEST,
  CREATE_FORUM_SUCCESS,
  CREATE_FORUM_FAILURE,
  CREATE_THREAD_REQUEST,
  CREATE_THREAD_SUCCESS,
  CREATE_THREAD_FAILURE,
  CREATE_THREAD_SAVE,
  CREATE_THREAD_TOGGLE,
  //LOGOUT,
  CHANGE_FORUM_THREAD_PAGE,
  DELETE_FORUM_REQUEST,
  DELETE_FORUM_SUCCESS,
  DELETE_FORUM_FAILURE,
} from '../actions/types';

const forumInitialState = {
  isLoading: false,
  name: null,
  id: null,
  description: null,
  isPrivate: null,
  lab: '',
  threads: null,
  error: null,
  forums: [],
  parents: [],
  work: '',
  threadPage: 1,
};

const newForumInitialState = {
  newForumLoading: false,
  newForumSuccess: false,
  newForumError: null,
  newForumId: null,
  newForumName: '',
  newForumDescription: '',
  newForumIsPrivate: false,
}

const newThreadInitialState = {
  newThreadLoading: false,
  newThreadSuccess: false,
  newThreadName: '',
  newThreadContent: '',
  newThreadId: null,
  newThreadError: null,
  newThreadShow: false,
};

const delForumInitialState = {
  isDeleting: null,
  forumDeleteSuccess: false,
  forumDeleteError: null,
}

const initialState = {
  ...forumInitialState,
  ...newThreadInitialState,
  ...newForumInitialState,
  ...delForumInitialState,
};

const forum = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOME_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_HOME_SUCCESS:
      return {
        isLoading: false,
        forums: action.forums,
        error: null,
      };
    case FETCH_HOME_FAILURE:
      return {
        ...initialState,
        isLoading: false,
        error: action.error,
      };
    case FETCH_FORUM_REQUEST:
      return {
        ...forumInitialState,
        ...state,
        newThreadLoading: false,
        newThreadSuccess: false,
        newThreadId: null,
        newThreadError: null,
        newThreadShow: false,
        isLoading: true,
        error: null,
        newForumLoading: false,
        newForumSuccess: false,
        newForumError: null,
        newForumId: null,
        newForumName: '',
        newForumDescription: '',
        newForumIsPrivate: false,
      };
    case FETCH_FORUM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        name: action.name,
        id: action.id,
        description: action.description,
        threads: action.threads,
        forums: action.forums,
        error: null,
        parents: action.parents,
        isPrivate: action.isPrivate,
        lab: action.lab,
        work: action.work,
      };
    case FETCH_FORUM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case CREATE_FORUM_REQUEST:
      return {
        ...state,
        newForumLoading: true,
        newForumSuccess: false,
        newForumError: null,
        newForumId: action.newForum.id,
        newForumName: action.newForum.name,
        newForumDescription: action.newForum.description,
        newForumIsPrivate: action.newForum.isPrivate,
      }
    case CREATE_FORUM_SUCCESS:
      return {
        ...state,
        newForumLoading: false,
        newForumSuccess: true,
        newForumError: null,
        newForumId: null,
        newForumName: '',
        newForumDescription: '',
        newForumIsPrivate: false,
      }
    case CREATE_FORUM_FAILURE:
      return {
        ...state,
        newForumLoading: false,
        newForumSuccess: false,
        newForumError: action.error,
        newForumId: null,
        newForumName: '',
        newForumDescription: '',
        newForumIsPrivate: false,
      }
    case DELETE_FORUM_REQUEST:
      return {
        ...state,
        isDeleting: action.forumid,
        forumDeleteSuccess: false,
        forumDeleteError: null
      }
    case DELETE_FORUM_SUCCESS:
      return {
        ...state,
        isDeleting: null,
        forumDeleteSuccess: true,
        forumDeleteError: null
      }
    case DELETE_FORUM_FAILURE:
      return {
        ...state,
        isDeleting: null,
        forumDeleteSuccess: false,
        forumDeleteError: action.error,
      }
    case CREATE_THREAD_REQUEST:
      return {
        ...state,
        newThreadLoading: true,
        newThreadSuccess: false,
        newThreadError: null,
        newThreadName: action.newThread.name,
        newThreadContent: action.newThread.content,
      };
    case CREATE_THREAD_SUCCESS:
      return {
        ...state,
        newThreadLoading: false,
        newThreadSuccess: true,
        newThreadName: '',
        newThreadContent: '',
        newThreadId: action.newThread.id,
        newThreadShow: false,
        newThreadError: null,
      };
    case CREATE_THREAD_FAILURE:
      return {
        ...state,
        newThreadLoading: false,
        newThreadSuccess: false,
        newThreadId: null,
        newThreadShow: true,
        newThreadError: action.error,
      };
    case CREATE_THREAD_SAVE:
      return {
        ...state,
        newThreadName: action.name,
        newThreadContent: action.content,
      };
    case CREATE_THREAD_TOGGLE:
      return {
        ...state,
        newThreadShow: !state.newThreadShow,
        newThreadSuccess: false,
        newThreadError: null,
      };
    /*case LOGOUT:
      return {
        ...state,
        ...newThreadInitialState,
      };*/
    case CHANGE_FORUM_THREAD_PAGE:
      return {
        ...state,
        threadPage: action.page
      }
    default:
      return state;
  }
};

export default forum;
