import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { TextMaterialViewList, FileMaterialViewList } from './MaterialLists.js'

import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import EditIcon from '@material-ui/icons/Edit';
import Button from "components/CustomButtons/Button.js";

import { getOneSeminar, getOneLab } from 'clients/labTranslationReceptionClient.js'
import { getAllWorks } from 'clients/worksClient.js'
import { labsTRImagesURL } from 'clients/clientConfig.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  paper: {
    padding: 20
  },
  centered: {
    textAlign: 'center',
  },
  seminarImageCell: {
    border: '1px #808080 solid',
    minWidth: 380,
    padding: 10,
  },
  seminarImage: {
    height: 240,
    width: 360,
  },
}))

const SeminarView = (props) => {
  const classes = useStyles()

  const [seminar, setSeminar] = React.useState({})
  const [lab, setLab] = React.useState({})
  const [allWorks, setAllWorks] = React.useState([])
  const [forum_links, setForum_links] = React.useState([])

  const parse = require('html-react-parser');

  React.useEffect(()=> {
    if (props.match.params.seminar_id){
      const ids_arr = props.match.params.seminar_id.split('-')

      getOneSeminar(props.match.params.seminar_id,
        (data) => {
          if (data.seminars.length > 0){
            setSeminar(data.seminars[0])
            setForum_links(data.forum_links)
          } else {
            window.location.href = '/admin/TRLabView/' + ids_arr[0]
          }
        },
        (err) => window.location.href = '/admin/TRLabView/' + ids_arr[0]
      )

      getOneLab(ids_arr[0], '',
        (data) => setLab(data.translation_labs[0] || {id: '', title: ''}),
        (err) => console.log(err)
      )

      getAllWorks(
        (data) => setAllWorks(data.works),
        (err) => console.log(err)
      )
    }
  }, [props.match.params.seminar_id])

  const notEmpty = (arr) => {
    return arr && arr.length > 0
  }

  return(
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} >
          <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>
              <Link color="inherit" href="/admin/TranslationReceptionLabs">
                Εργαστήρια μετάφρασης και πρόσληψης
              </Link>
              <Link color="inherit" href={'/admin/TRLabView/' + lab.id}>{lab.title || ''}</Link>
              <Typography color="textPrimary">{seminar.title}</Typography>
            </Breadcrumbs>
          </div>
        </Grid>

       <Grid item xs={12} style={{ paddingTop : '20px'}}>
        <Container id="labpreview" maxWidth="lg">
          <Paper className={classes.paper}>
            <Grid spacing={2} container>
              <Grid item xs={3} >
                {seminar.image && <img alt='' src={labsTRImagesURL+lab.id+'/'+seminar.image} className={classes.seminarImage}/>}
              </Grid>
              <Grid item xs={8} >
                <Typography variant='h4'>
                  {seminar.title}&nbsp;
                  {
                    access_rights.labs_tr && access_rights.labs_tr.w &&
                    <Button justIcon round color="primary" onClick={() => window.location.href='/admin/TRSeminarEdit/'+seminar.lab_id+'/'+seminar.id}><EditIcon/></Button>
                  }
                </Typography>
              </Grid>
            </Grid>

            {
              seminar.speaker &&
              <Typography variant="body1" component='div'><b>Εισηγητής: {seminar.speaker}</b></Typography>
            }
            <Typography variant="body1" component='div'>
              Συντονιστής: {seminar.coordinator}<br/>
              {parse(seminar.info || '')}
            </Typography>
            <br/>

            {seminar.videoLink &&
              <Typography variant='h5'>Βίντεο σεμιναρίου <Link href={seminar.videoLink}><VideoLibraryIcon/></Link></Typography>}
            <br/>

            {
              (seminar.attendants || seminar.place || seminar.time || seminar.meetingLink) &&
              <>
              <Typography variant='h5'>Λεπτομέρειες συνάντησης</Typography>
              <Typography variant="body1" component="div">
                {seminar.attendants &&
                  <>
                  Συμμετέχοντες:
                  <p>{seminar.attendants}</p>
                  </>
                }
                {seminar.place && <>Τόπος συνάντησης: {seminar.place}<br/></>}
                {seminar.time && <>Χρόνος συνάντησης: {seminar.time}</>}
              </Typography>
              {seminar.meetingLink && <Link href={seminar.meetingLink}><Typography variant="body1">Δεσμός εξ αποστάσεως συνάντησης</Typography></Link>}
              <br/>
              </>
            }

            {
              (notEmpty(seminar.summaryTexts) || notEmpty(seminar.summaryFiles)) &&
              <>
                <Typography variant='h5'>Συνοπτική Παρουσίαση</Typography>
                <TextMaterialViewList textList={seminar.summaryTexts}/>
                <FileMaterialViewList fileList={seminar.summaryFiles} fileUploadFolder={labsTRImagesURL+lab.id}/>
              <br/>
              </>
            }

            {
              (notEmpty(seminar.materialTexts) || notEmpty(seminar.materialFiles)) &&
              <>
                <Typography variant='h5'>Υλικό σεμιναρίου</Typography>
                <TextMaterialViewList textList={seminar.materialTexts}/>
                <FileMaterialViewList fileList={seminar.materialFiles} fileUploadFolder={labsTRImagesURL+lab.id}/>
              <br/>
              </>
            }

            {
              notEmpty(seminar.platform_arr) &&
              <>
              <Typography variant='h5'>Σύνδεσμοι προς την πλατφόρμα</Typography>
              <List>
              {
                seminar.platform_arr.map((link, idx) =>
                  <ListItem key={idx}>
                    <Link href={link.link}>{link.title}</Link>
                  </ListItem>
                )
              }
              </List>
              </>
            }

            {
              notEmpty(seminar.lexicon_arr) &&
              <>
              <Typography variant='h5'>Λεξικό</Typography>
              <List>
              {
                seminar.lexicon_arr.map((lemma, idx) =>
                  <ListItem key={idx}><Link href={lemma.link}>{lemma.name}</Link></ListItem>
                )
              }
              </List>
              </>
            }

            {
              notEmpty(seminar.workTags) &&
              <>
                <Typography variant='h5'>Έργα που αφορά</Typography>
                <Typography variant='body1' className="archaic_text">
                  {allWorks.filter(w => seminar.workTags.includes(w.id)).map(w => w.name).join(', ')}
                </Typography>
              </>
            }

            {
              notEmpty(seminar.links_arr) &&
              <>
              <Typography variant='h5'>Εξωτερικοί σύνδεσμοι</Typography>
              <List>
              {
                seminar.links_arr.map((link, idx) =>
                  <ListItem key={idx}>
                    <Link href={link.link}>{link.title || link.link}</Link>
                  </ListItem>
                )
              }
              </List>
              </>
            }

            {
              forum_links && forum_links.length > 0 &&
              <Grid item xs={12}>
                <Typography variant='h5'>Συζητήσεις σε Φόρουμ</Typography>
                <List>
                {
                  forum_links.map(fl =>
                    <ListItem key={fl.id}>
                      <Link href={'/admin/Forum/'+fl.id}>{fl.name}</Link>
                    </ListItem>
                  )
                }
                </List>
              </Grid>
            }

            {
              notEmpty(seminar.labels) &&
              <>
                <Typography variant='h5'>Λέξεις-κλειδιά</Typography>
                <Typography variant="body1">{seminar.labels.toString()}</Typography>
                <br/>
              </>
            }

          </Paper>
        </Container>
       </Grid>
      </Grid>
    </div>
  )
}

export default SeminarView
