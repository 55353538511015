import React from 'react';
import CustomInput from "components/CustomInput/CustomInput.js";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
/*import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";*/
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import SelectWorksForRole from './SelectWorksForRole.js'
import SelectLabsForRole from './SelectLabsForRole.js'
import SelectForumsForRole from './SelectForumsForRole.js'

import { getAllWorks } from "clients/worksClient.js";
import { fetchForumsApi } from 'views/Forums/api/forum.js'
import { sortWorksAndBooks } from 'sorting.js'

const UsergroupForm = (props) => {
  const { groupName, selectedRoles, worksForRole, roles, labsForRole, labsAllTypes,
    errors, handleChangeName, handleRoleSelect, handleWorkSelect, handleLabSelect,
    forumsForRole, handleForumSelect
   } = props

  const [works, setWorks] = React.useState([])
  const [forums, setForums] = React.useState([])

  React.useEffect(()=> {
    getAllWorks(
      (data) => setWorks(sortWorksAndBooks(data.works)),
      (err) => console.log(err)
    )
    fetchForumsApi(0,
      (data) => setForums(data.forums),
      (err) => console.log(err)
    )
  }, [])

  return (
    <GridContainer>
      <GridItem xs={12}>
        <CustomInput
          labelText="Όνομα Ομάδας"
          id="GroupName"
          formControlProps={{
            fullWidth: true
          }}
          inputProps = {{
            value: groupName,
            onChange: handleChangeName,
          }}
          error={Boolean(errors.groupName)}
        />
        <FormHelperText error>{errors.groupName}</FormHelperText>
      </GridItem>

      <GridItem xs={12}>
        <Typography variant='h4'>Ρόλοι</Typography>
      </GridItem>
      <GridItem xs={12}>
        <FormHelperText error>{errors.roles}</FormHelperText>
      </GridItem>
      <GridItem xs={12}>
        <Divider/>
      </GridItem>

      {
        Object.keys(roles).map(roleid =>
          <RoleSelection key={roleid} roleid={roleid} role={roles[roleid]}
            selectedRoles={selectedRoles} works={works}
            worksForRole={worksForRole} handleRoleSelect={handleRoleSelect}
            handleWorkSelect={handleWorkSelect}
            errorWork={errors['works'+roleid]} errorLabs={errors['labs'+roleid]}
            labs={labsAllTypes} labsForRole={labsForRole} handleLabSelect={handleLabSelect}
            forums={forums} forumsForRole={forumsForRole} handleForumSelect={handleForumSelect}
            errorForum={errors['forums'+roleid]}/>
        )
      }

    </GridContainer>
  )
}

const RoleSelection = (props) => {
  const { selectedRoles, worksForRole, labsForRole, forumsForRole,
    roleid, role, works, labs, forums,
     handleRoleSelect, handleWorkSelect, handleLabSelect, handleForumSelect,
     errorWork, errorLabs, errorForum} = props

  return(
    <>
      <GridItem xs={6}>
        <FormControlLabel
          control={<Checkbox checked={selectedRoles.includes(roleid)}
          onChange={(e) => handleRoleSelect(e, roleid)}
          name="" color="primary"/>}
          label={role.name}
          />
      </GridItem>
      <GridItem xs={6}>
      {
        role.define_works &&
        <SelectWorksForRole work={worksForRole[roleid] || []} allWorks={works}
          handleChange={(e) => handleWorkSelect(e, roleid)} error={errorWork}/>
      }
      {
        role.define_labs &&
        <SelectLabsForRole selectedLabs={labsForRole[roleid] || []} allLabs={labs}
          handleChange={(e) => handleLabSelect(e, roleid)} error={errorLabs}/>
      }
      {
        role.define_forums &&
        <SelectForumsForRole forum={forumsForRole[roleid] || []} allForums={forums}
          handleChange={(e) => handleForumSelect(e, roleid)} error={errorForum}/>
      }
      </GridItem>
    </>
  )
}

export { UsergroupForm }
