import React from 'react';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const ForumsTab = (props) => {
  const { forums } = props

  return(
    forums.map(f =>
      <Typography varian='body1' key={f.id}>
        <Link href={'/admin/Forum/'+f.id} target="_blank">
          {f.name}
        </Link>
      </Typography>
    )
  )
}

export default ForumsTab
