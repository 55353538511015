import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Button from "components/CustomButtons/Button.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'

import { UserForm, getValidationErrorsForUser } from 'views/AdminUsersGroupsPrivs/UserForm.js'
import PasswordForm from './PasswordForm.js'
import { editUser } from "clients/usersClient.js"
import { checkNoErrors } from "utils.js"

const useStyles = makeStyles((theme) => ({

  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexGrow: 1,
  },

 paper: {
   padding: theme.spacing(4),
   margin: '15px',
 },

}));

export default function UserProfile() {
  const classes = useStyles();

  const [userId, setUserId] = React.useState(-1)
  const [firstname, setFirstname] = React.useState('')
  const [lastname, setLastname] = React.useState('')
  const [errors, setErrors] = React.useState({})
  const [disableSubmit, setDisableSubmit] = React.useState(false)

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  //Snackbar messages (to inform user of success)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const handleShowSuccessMessage = (message) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message)
  }

  React.useEffect(() => {
    if (localStorage.getItem('UserName')!==null && localStorage.getItem('UserName')!=='null'){
      setFirstname(localStorage.getItem('UserFName'))
      setLastname(localStorage.getItem('UserLName'))
      setUserId(parseInt(localStorage.getItem('UserId')))
      //console.log(localStorage.getItem('UserId'))
    } else {
      showErrorAlert('Ο χρήστης δε βρέθηκε')
      setDisableSubmit(true)
    }
  }, [])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  const handleChange = (e, fieldname) => {
     switch (fieldname){
       case 'firstname':
          setFirstname(e.target.value)
          break;

      case 'lastname':
          setLastname(e.target.value)
          break;

     default:
         ;
       };
  }

   const validateUser = (fields) => {
     const newErrors = getValidationErrorsForUser(fields, false);
     setErrors(newErrors)
     //console.log(newErrors)

     return checkNoErrors(newErrors)
   }

 const handleSubmit = () => {
   const params = {firstname: firstname, lastname: lastname, user_id: userId}

   if (validateUser(params)){
     setDisableSubmit(true)
     //console.log(params)

     editUser(params,
       () => {
         //store changes in session
         localStorage.setItem('UserFName', firstname);
         localStorage.setItem('UserLName', lastname);
         //show message
         handleShowSuccessMessage('H καταχώριση έγινε με επιτυχία!')
         setDisableSubmit(false)
       },
       (err) => {
         showErrorAlert(err.message)
         setDisableSubmit(false)
       }
     )
   }
 }

 const showErrorAlert = (msg) => {
   setAlert(<ErrorAlert message={msg} hideAlert={hideAlert}/>)
 }

 function Alert(props) {
   return <MuiAlert elevation={6} variant="filled" {...props} />;
 }

  return (
    <div id="userprofile" className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2} style={{ paddingTop : '0px'}} >
          <Grid item xs={12} >
            <div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link color="inherit" href="/admin/UserProfile">
                  Προφίλ Χρήστη
                </Link>

            </Breadcrumbs>
            </div>
          </Grid>


           <Grid item xs={12} style={{paddingTop : '20px'}}>
             <Grid container justify="center" spacing={2}>
              <Paper className={classes.paper}>
                 <Grid item>

                    <UserForm firstname={firstname} lastname={lastname} user_id={userId}
                      handleChange={handleChange} errors={errors}/>

                 </Grid>
                 <Grid item align='right'>
                    <Button color='primary' onClick={handleSubmit} disabled={disableSubmit}>Καταχωριση</Button>
                 </Grid>
              </Paper>
             </Grid>
           </Grid>

           <Grid item xs={12} style={{paddingTop : '20px'}}>
             <Grid container justify="center" spacing={2}>
              <Paper className={classes.paper}>

                  <PasswordForm userId={userId} onSuccess={handleShowSuccessMessage}
                    onError={showErrorAlert}/>

              </Paper>
             </Grid>
           </Grid>
    </Grid>

    {alert}

    <Snackbar anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={openSnackbar}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
      >
      <Alert onClose={handleCloseSnackbar} severity="success">
        {snackbarMessage}
      </Alert>
    </Snackbar>
 </div>





  );
}
