import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from "components/Card/Card.js";
import CardHeader from "@material-ui/core/CardHeader";
import CustomInput from "components/CustomInput/CustomInput.js";

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Pagination from '@material-ui/lab/Pagination';

//import wikiIcon from "images/wiki2.svg";
//import blogIcon from "images/blog2.svg";
//import LockIcon from '@material-ui/icons/Lock';
//import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditIcon from '@material-ui/icons/Edit';
import ReadIcon from '@material-ui/icons/EventNote'
import Tooltip from '@material-ui/core/Tooltip';

import Box from '@material-ui/core/Box';
import { truncateText, disableEvent } from 'utils.js'

const parse = require('html-react-parser');

function truncateTextWithMarkupRemoved(original_text, maxLength){
  const plainText = removeNumberingMarkupFromText(original_text)
  return truncateText(plainText, maxLength)
}

function removeNumberingMarkupFromText(text){
  let plainText = ''
  text.split('\n').forEach((line) =>  {
    //remove Bekker number and lyric number in {}
    let plainLineText = line.replace(/\$\w+\$/i, '')
                            .replace(/\{\w+\}/i, '')

    //remove lyric number from end
    const res = plainLineText.match(/&&\w+&&/gi);
    if (res){
      res.reverse()
      plainLineText = plainLineText.replace(res[0], '')
    }

    plainText += plainLineText
  })

  return plainText
}

const CardGridView = (props) => {
  const { lyrics, translator, classes, canEdit } = props

  const [filteredLyrics, setFilteredLyrics] = React.useState([])
  const [page, setPage] = React.useState(1);
  const pageSize = 6;

  const pageStart = (page - 1) * pageSize
  const pageEnd = pageStart + pageSize

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const searchWithLyrics = (e) => {
    //console.log(e.target.value)
    const tempFilteredLyrics = lyrics.filter((section) => (section.lines.startsWith(e.target.value)))
    setFilteredLyrics(tempFilteredLyrics)
    setPage(1)
  }

  React.useEffect(()=>{
    setFilteredLyrics(lyrics)
  }, [lyrics])

  const defaultCardAction = canEdit() ? 'CollTranslation' : 'CollTranslationREADONLY'

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      {/* End hero unit */}
      <Grid item xs={12}>
        <CustomInput
           labelText="Αναζήτηση στίχων"
           id="searchLines"
           formControlProps={{
             fullWidth: false
           }}
           inputProps={{
             onChange: searchWithLyrics
           }}
        />
      </Grid>
      <Grid id="lyricsgrid" container spacing={4}>
        {filteredLyrics.slice(pageStart, pageEnd).map((section, index) => (
          <Grid item key={section.id} xs={12} sm={6} md={4}>
            <Card className={classes.card}>
            <CardActionArea href={"/admin/"+defaultCardAction+"/"+section.id}>
              <CardHeader
                   title={
                     <Typography gutterBottom noWrap variant="h6" component="div">
                         Στίχοι: {section.lines}
                     </Typography>
                   }
                   className={`${classes.cardHeader} AristosCardHeader`}
               />
               <CardContent className={classes.cardContent}>
                  <Typography gutterBottom className={classes.cardContent} component="span"
                    onCopy={disableEvent} onCut={disableEvent}>
                     <Box class="archaic_text">
                            &lt;&lt;{parse(truncateTextWithMarkupRemoved(section.original_text, 122))}&gt;&gt;
                    </Box>
                  </Typography>
                  <Typography component="span" variant='body2'>
                      <Box  display="flex" flexDirection="row">
                          <Box fontWeight={700} m={1} className={classes.cardContentTrans}>
                              Μεταφρ.:
                            </Box>
                            <Box  m={1} className={classes.cardContentTrans}>
                              {translator}
                            </Box>
                      </Box>
                  </Typography>
                </CardContent>
                </CardActionArea>
                <Divider/>
                <CardActions>

                {/* TODO: decide on the buttons */}
                {/*<Tooltip title='Επεξεργασία'>
                  <IconButton aria-label="edit allowed" href={"/admin/CollTranslation/"+section.id} className="IconButtonLyrics"><LockOpenIcon style={{color:"#149414"}}/></IconButton>
                </Tooltip>
                <Tooltip title='Ανάγνωση'>
                  <IconButton aria-label="read-only" href={"/admin/CollTranslationREADONLY/"+section.id} className="IconButtonLyrics"><LockIcon style={{color:"#ff0000"}} /></IconButton>
                </Tooltip>*/}

                <Tooltip title='Ανάγνωση'>
                  <IconButton aria-label="go to read" href={"/admin/CollTranslationREADONLY/"+section.id} className="IconButtonLyrics"><ReadIcon style={{color:"#387BE1", marginTop:"2px"}}/></IconButton>
                </Tooltip>
                {
                  canEdit() &&
                  <Tooltip title='Επεξεργασία'>
                    <IconButton aria-label="go to edit" href={"/admin/CollTranslation/"+section.id} className="IconButtonLyrics"><EditIcon style={{color:"#EBBA1A"}}/></IconButton>
                  </Tooltip>
                }

                </CardActions>



            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid item  xs={12} sm={12} md={12} style={{textAlign:"center" }}>
          <Grid container justify="center">
            <Pagination count={Math.ceil(filteredLyrics.length/pageSize)} color="primary" page={page} style={{marginTop:"0px"}} onChange={handlePageChange}/>
          </Grid>
      </Grid>
    </Container>
  )
}

export default CardGridView
