import React from 'react';
import ReactTable from "components/ReactTable/ReactTable.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);

const UserSelectableTable = (props) => {
  const { usersdata, checked, handleToggle } = props
  const classes = useStyles();

  return (
    <ReactTable
      columns={[
        {
          Header: "Username",
          accessor: "username"
        },
        {
          Header: "Όνομα",
          accessor: "firstname"
        },
        {
          Header: "Επώνυμο",
          accessor: "lastname"
        },
        {
          Header: "Ενεργός/ή",
          accessor: "activeTxt",
        },
        {
          Header: "Επιλογή",
          accesor: "id",
          Cell: row => {
            //console.log(row.row.id)
            const user = row.data[row.row.id]
            //console.log(user)
            return (
              <div align='center'>
              <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(user.user_id)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      checked={checked.indexOf(user.user_id) >= 0}
                    />
                    </div>
            )
          }
        },
      ]}
      data={usersdata}
    />
  )
}

export default UserSelectableTable;
