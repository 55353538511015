import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  fetchSearchWithKeyword,
  changeSearchResultsPage,
} from '../../actions';
import SearchResults from '../../components/searchresults';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

//access rights
//import get_rights_from_session from 'rights_from_session.js'
//const access_rights = get_rights_from_session()

class ForumSearchContainer extends Component {
  state = {
  }

  loadResults = (keyword) => {
    this.props.fetchSearchWithKeyword(keyword)
  }

  handleChangePage = (e, page) => {
    this.props.changePage(page)
  }

  componentDidMount() {
    //console.log(this.props.match.params)
    const {keyword} = this.props.match.params;
    this.loadResults(keyword)
  }

  render() {
    const {
      isLoading,
      results,
      error,
      page
    } = this.props;

    const rootStyles = {
      display: 'flex',
      flexGrow: 1,
    }

    const {keyword} = this.props.match.params;
    //console.log(results)

    return (
      <div style={rootStyles}>
        <CssBaseline />
        <Sidebar/>

        <Grid container spacing={2} style={rootStyles}>
          <Grid item xs={12} >
            <div>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                  <Link color="inherit" href="/admin/SelectTransSem">
                    Main
                  </Link>
                  <Link color="inherit" href="/admin/Forum">Forums</Link>
                  <Typography color="textPrimary">Αναζήτηση με λέξη-κλειδί</Typography>
              </Breadcrumbs>
            </div>
          </Grid>

         <Grid item xs={12} style={{ paddingTop : '20px'}}>
          <Container maxWidth='lg'>
            <Paper>
              <Typography align='center' variant='h5'>Αναζήτηση με λέξη-κλειδί: {keyword}</Typography>
              <SearchResults
                isLoading={isLoading}
                error={error}
                results={results}
                page={page}
                handleChangePage={this.handleChangePage}
              />

              </Paper>
            </Container>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.search.isLoading,
  results: state.search.results,
  error: state.search.error,
  page: state.search.page || 1,
});

const mapDispatchToProps = dispatch => ({
  fetchSearchWithKeyword: keyword => {
    dispatch(fetchSearchWithKeyword(keyword));
  },
  changePage: page => {
    dispatch(changeSearchResultsPage(page))
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForumSearchContainer);
