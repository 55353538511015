import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles({

  readMore: {
    border: "2px solid #E4766D",
	background:"#E4766D",
	padding: 5,
	fontSize: 14,
	color: "#FFFFFF",
	textTransform: "none",
	maxWidth: 110,
	textAlign: "center",
	borderRadius: 2,
  },

  postDate:{
	fontSize: 12,
	textTransform: "none",
  },
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
}

);

export default function FeaturedPost(props) {
  const classes = useStyles();
  const { post } = props;

  return (
    <Grid item xs={12} md={6}>
      <CardActionArea component="a" href="#">
        <Card className={classes.card}>
          <div className={classes.cardDetails}>
            <CardContent>
              <Typography component="h2" variant="h5">
                {post.title}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" className={classes.postDate}>
                {post.date}
              </Typography>
              <Typography variant="subtitle1" paragraph style={{ marginTop:'10px'}}>
                {post.description}
              </Typography>
              <Typography variant="subtitle1" color="primary" className={classes.readMore}>
                {post.linkText}
              </Typography>
            </CardContent>
          </div>
          {/*<Hidden xsDown>
            <CardMedia className={classes.cardMedia} image={post.image} title={post.imageTitle} />
          </Hidden>*/}
        </Card>
      </CardActionArea>
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.object,
};
