import React from 'react';
import Button from "components/CustomButtons/Button.js";
import Tooltip from '@material-ui/core/Tooltip';
import Close from "@material-ui/icons/Close";
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from "prop-types";

const WorkActions =  (props) => {
  const { work, handleClickEditWork, handleClickDeleteWork } = props;
    return(
      <div className="actions-right">
        {/* use this button to add a edit kind of action */}
        <Button
          justIcon
          round
          simple
          onClick={() => handleClickEditWork(work.id, work.name, work.translator)}
          color="warning"
          className="edit"
        >
          <Tooltip title="Επεξεργασία">
            <EditIcon />
          </Tooltip>
        </Button>{" "}
        {/* use this button to remove the data row */}
        <Button
          justIcon
          round
          simple
          onClick={() => handleClickDeleteWork(work.id)}
          color="danger"
          className="remove"
          disabled={(work.books > 0)}
        >
          <Tooltip title="Διαγραφή">
            <Close />
          </Tooltip>
        </Button>{" "}
      </div>
    )
}

const BookActions = (props) => {
  const { book, handleClickEditBook, handleClickDeleteBook } = props;

  return (
    <div className="actions-right">
      {/* use this button to add a edit kind of action */}
      <Button
        justIcon
        round
        simple
        onClick={() => handleClickEditBook(book.id, book.name)}
        color="warning"
        className="edit"
      >
        <Tooltip title="Επεξεργασία">
          <EditIcon />
        </Tooltip>
      </Button>{" "}
      {/* use this button to remove the data row */}
      <Button
        justIcon
        round
        simple
        onClick={() => handleClickDeleteBook(book.id)}
        color="danger"
        className="remove"
        disabled={(book.sections > 0)}
      >
        <Tooltip title="Διαγραφή">
          <Close />
        </Tooltip>
      </Button>{" "}
    </div>
  )
}

const SectionActions = (props) => {
  const { section, handleClickEditSection, handleClickDeleteSection } = props

  return (
    <div className="actions-right">
      {/* use this button to add a edit kind of action */}
      <Button
        justIcon
        round
        simple
        onClick={() => handleClickEditSection(section.id)}
        color="warning"
        className="edit"
      >
        <Tooltip title="Επεξεργασία">
          <EditIcon />
        </Tooltip>
      </Button>{" "}
      {/* use this button to remove the data row */}
      <Button
        justIcon
        round
        simple
        onClick={() => handleClickDeleteSection(section.id)}
        color="danger"
        className="remove"
        disabled={Boolean(section.translation_url)}
      >
        <Tooltip title="Διαγραφή">
          <Close />
        </Tooltip>
      </Button>{" "}
    </div>
  )
}

/** prop types **/
WorkActions.propTypes = {
  work: PropTypes.object.isRequired,
  handleClickEditWork: PropTypes.func.isRequired,
  handleClickDeleteWork: PropTypes.func.isRequired,
}

BookActions.propTypes = {
  book: PropTypes.object.isRequired,
  handleClickEditBook: PropTypes.func.isRequired,
  handleClickDeleteBook: PropTypes.func.isRequired
}

SectionActions.propTypes = {
  section: PropTypes.object.isRequired,
  handleClickEditSection: PropTypes.func.isRequired,
  handleClickDeleteSection: PropTypes.func.isRequired
}

export { WorkActions, BookActions, SectionActions }
