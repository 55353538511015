import { fetchWithFile } from './clientUtils.js'

/*function addCommentToOriginalText(vals, success, errorFunc){
  return fetchWithMethod('comments_original', 'POST', vals, success, errorFunc)
}*/

function uploadTranslation(file, vals, success, errorFunc){
  return fetchWithFile('translations_from_file', 'POST', file, vals, success, errorFunc)
  //return success({message:''})
}


export { uploadTranslation }
