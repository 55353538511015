import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import NavPills from "components/NavPills/NavPills.js";

import Sidebar from "components/Sidebar/Sidebar.js";
import Grid from '@material-ui/core/Grid';

import { green } from '@material-ui/core/colors';
import { blue } from '@material-ui/core/colors';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import TableView from './TableView.js'
import CardGridView from './CardGridView.js'
import { getBookAndWorkName } from 'clients/booksClient.js'
import { getSectionsForBook } from 'clients/sectionsClient.js'
import { sortSectionLines } from 'sorting.js'

//access rights
import get_rights_from_session from 'rights_from_session.js'
const access_rights = get_rights_from_session()

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },


    exampleWrapper: {
      position: 'relative',
      marginTop: theme.spacing(0),
      height: 80,
    },

    speedDial: {
      position: 'absolute',
      '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
    BlogIconCol: {
      backgroundColor: green[100],
      color: green[600],
    },
    WikiIconCol: {
      backgroundColor: blue[100],
      color: blue[600],
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(2),
    },
    card: {
      height: '300px',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '16.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    cardContentTrans: {
      color:"#3f51b5",
      fontweight: "bold",
    },

    cardHeader: {
      backgroundColor: "#858585",
      color:"#ffffff",

      height:"30px",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },

    IconButtonLyrics: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      color:"#ff0000",


    },


}));


const actions = [


  {/*   { icon: <BlogIcon />, name: 'Προσθήκη Blog', id:1 },
  { icon: <WikiIcon />, name: 'Προσθήκη Wiki', id:2 },
  { icon: <SeminarIcon />, name: 'Προσθήκη Σεμιναρίου', id:3 },       */}
];

function handleClick(event) {
  event.preventDefault();
};

//const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const cards = [
  { lines: '184a10-26', original_text: "<<Ἐπειδὴ τὸ εἰδέναι καὶ τὸ ἐπίστασθαι συμβαίνει περὶ πάσας τὰς μεθόδους, ὧν εἰσὶν ἀρχαὶ ἢ αἴτια ἢ στοιχεῖα, ἐκ τοῦ ταῦτα...>>", translator: "Γιώργος Βάρσος", id:1 },
  { lines: '184b10-26', original_text: "<<ταὐτὸ τοῦτο τρόπον τινὰ καὶ τὰ ὀνόματα πρὸς τὸν λόγον ὅλον γάρ τι καὶ ἀδιορίστως σημαίνει, οἷον ὁ κύκλος, ὁ δὲ ὁρισμὸς...>>", translator: "Ηλίας Γεωργούλας", id:2  },
  { lines: '185a1-30', original_text: "<<οὖν εἰ ἓν καὶ ἀκίνητον τὸ ὂν σκοπεῖν οὐ περὶ φύσεώς ἐστι σκοπεῖν· ὥσπερ γὰρ καὶ τῷ γεωμέτρῃ οὐκέτι λόγος ἔστι...>>", translator: "Μυρτώ Γκαράνη", id:3  },
  { lines: '185b1-35', original_text: "<<οὐκ ἐνδέχεται εἰ μὴ κατὰ συμβεβηκός, εἰ ἅμα καὶ ποσὰ ἄττα εἶεν· ὁ γὰρ τοῦ ἀπείρου λόγος τῷ ποσῷ προσχρῆται, ἀλλ’...>>", translator: "Παντ. Γκολίτσης", id:4  },
  { lines: '186a1-35', original_text: "<<δὲ ἤδη ἠπόρουν, καὶ ὡμολόγουν τὸ ἓν πολλὰ εἶναι – ὥσπερ οὐκ ἐνδεχόμενον ταὐτὸν ἕν τε καὶ πολλὰ εἶναι, μὴ...>>", translator: "Στέλ. Δημόπουλος", id:5  },
  { lines: '186b1-35', original_text: "<<τοῦ ὄντος)· ἔσται τι ἄρα οὐκ ὄν. οὐ δὴ ἔσται ἄλλῳ ὑπάρ νερό, κινείται μέσα στα όριά του, γιατί να μην κινείται...>>", translator: "Βάλια Δημοπούλου", id:6  },
  { lines: '187a1-35', original_text: "<<ἔνιοι δ’ ἐνέδοσαν τοῖς λόγοις ἀμφοτέροις, τῷ μὲν ὅτι πάντα ἕν, εἰ τὸ ὂν ἓν σημαίνει, ὅτι ἔστι τὸ μὴ ὄν, τῷ δὲ ἐκ τῆς...>>", translator: "Παύλος Kαλλιγάς", id:7  },
  { lines: '187b1-35', original_text: "<<ἀναισθήτων ἡμῖν. διό φασι πᾶν ἐν παντὶ μεμῖχθαι, διότι πᾶν ἐκ παντὸς ἑώρων γιγνόμενον· φαίνεσθαι δὲ διαφέροντα καὶ...>>", translator: "Γερ. Κουζέλης", id:8  },
  { lines: '188a10-26', original_text: "<<οὐθὲν ἐκκριθήσεται σῶμα· ἔσται γὰρ ἐλάττων τῆς ἐλαχίστης. ἔτι δ’ ἐν τοῖς ἀπείροις σώμασιν ἐνυπάρχοι ἂν ἤδη σὰρξ...>>", translator: "Παντ. Mπασάκος", id:9  },
  { lines: '1447a-1447b', original_text: "$1447a$ {8} \tΠερὶ <b>ποιητικῆς</b> αὐτῆς τε καὶ τῶν εἰδῶν αὐτῆς, ἥν τινα **1447a**\n"+
  "{9} δύναμιν ἕκαστον ἔχει, καὶ πῶς δεῖ συνίστασθαι τοὺς μύθους \n"+
"{10} εἰ μέλλει καλῶς ἕξειν ἡ ποίησις, ἔτι δὲ ἐκ πόσων κα **10**\n"+
"{11} ποίων ἐστὶ μορίων, ὁμοίως δὲ καὶ περὶ τῶν ἄλλων ὅσα τῆς \n"+
"{12} αὐτῆς ἐστι μεθόδου, λέγωμεν <i>ἀρξάμενοι</i> κατὰ φύσιν πρῶ- \n"+
"{13a} τον ἀπὸ τῶν πρώτων. ἐποποιία δὴ καὶ ἡ τῆς τραγῳδίας **13a**\n"+
"{13b} \t\ttest line with 2 tabs**13b**\n"+
"{14} ποίησις ἔτι δὲ κωμῳδία καὶ ἡ <b>διθυραμβοποιητικὴ</b> καὶ τῆς\n"+
"$1447b$ {8} μιγνῦσα μετ’ ἀλλήλων εἴθ’ ἑνί τινι γένει χρωμένη τῶν μέ- **1447b**\n"+
"{9} τρων ἀνώνυμοι τυγχάνουσι μέχρι τοῦ νῦν· οὐδὲν γὰρ ἂν \n\n"+
"{10} ἔχοιμεν ὀνομάσαι κοινὸν τοὺς Σώφρονος καὶ Ξενάρχου μί- **10**\n"+
"{11} μους καὶ τοὺς Σωκρατικοὺς λόγους οὐδὲ εἴ τις διὰ τριμέτρων",
translator: '', id: 10
}
];



export default function SelectLyrics(props) {

  const [value, setValue] = React.useState(0);
  const [workId, setWorkId] = React.useState(0)
  const [bookName, setBookName] = React.useState("")
  const [workName, setWorkName] = React.useState("")
  const [workTranslator, setWorkTranslator] = React.useState('')
  const [lyrics, setLyrics] = React.useState([])


  const classes = useStyles();

  React.useEffect(() => {
    if (props.match.params.book_id){
      //console.log(props.match.params.book_id)
      const [work_id, book_id] = props.match.params.book_id.split('-')
      //console.log(work_id+' '+book_id)

      getBookAndWorkName(work_id, book_id,
        (data) => {
          setWorkId(parseInt(work_id))
          setBookName(data.names.book_name)
          setWorkName(data.names.work_name)
          setWorkTranslator(data.names.translator)
        },
        (err) => window.location.href = '/admin/bookSelection'
      )

      getSectionsForBook(work_id, book_id,
        (data) => {
          setLyrics(data.sections.sort(sortByLines))
        },
        (err) => console.log(err)
      )
      //setLyrics(cards)
    }
  }, [props.match.params.book_id])

  const sortByLines = (a, b) => {
    return sortSectionLines(a.lines, b.lines)
  }

  const canEdit = () => {
    return (access_rights.original_text &&
      Array.isArray(access_rights.original_text.w) && access_rights.original_text.w.includes(workId)) ||
      (access_rights.translation &&
        Array.isArray(access_rights.translation.w) && access_rights.translation.w.includes(workId))
  }

  return (


    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} >
          <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/admin/SelectTransSem">
                Main
              </Link>

              <Link color="inherit" href="/admin/bookSelection">
                Αριστοτελικά έργα, Μεταφράσεις, Σχόλια
              </Link>
              <Typography color="textPrimary">{workName}</Typography>
              <Typography color="textPrimary">{bookName}</Typography>
          </Breadcrumbs>
          </div>
        </Grid>
       <Grid item xs={12} style={{ paddingTop : '20px'}}>

              <NavPills
                  tabs={[
                    {
                      tabButton: "Λεπτομερής Προβολή",
                      tabContent: (
                          <CardGridView lyrics={lyrics} translator={workTranslator}
                            classes={classes} canEdit={canEdit}/>
                      )
                    },
                    {
                      tabButton: "Σύντομη Προβολή",
                      tabContent: (
                          <TableView lyrics={lyrics} translator={workTranslator}
                            classes={classes} canEdit={canEdit}/>
                      )
                    }
                  ]}/>
        </Grid>


        <Grid item  xs={12} sm={12} md={12} style={{textAlign:"center", marginTop:"20px"  }}>
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
            {/* TODO: decide on the buttons */}
              <BottomNavigationAction label="Πίνακας εννοιών" href="https://docs.google.com/spreadsheets/d/1ZNz7bcaAvKP6DD92bezxbhFBZ1WyQh9gvtEjouoZtEE/edit#gid=0&fvid=166148326" target="_new" icon={<RestoreIcon />} />
              <BottomNavigationAction label="Πίνακας ονομάτων" icon={<FavoriteIcon />} />
              <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
          </BottomNavigation>
        </Grid>


      </Grid>


    </div>


  );
}
