import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Grid from '@material-ui/core/Grid';
import Sidebar from "components/Sidebar/Sidebar.js";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

import { getCommentsByTag } from "clients/commentsClient.js"

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  lyrics: {
    color: '#808080',
    fontWeight: '700'
  },
  scholar: {
    color: 'black',
    fontWeight: '700'
  }
}))

export default function CommentSearchByTagResults(props) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true)
  const [comments, setComments] = React.useState([])
  const [error, setError] = React.useState('')

  React.useEffect(()=>{
    if (props.match.params.tag){
      getCommentsByTag(props.match.params.tag,
        (data) => {
          setComments(data.comments)
          setLoading(false)
        },
        (err) => {
          setError(err.message)
          setLoading(false)
        }
      )
    }
  }, [props.match.params.tag])

  return (

    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} >
        <div>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" href="/admin/SelectTransSem">
              Main
            </Link>
            <Link color="inherit" href="/admin/bookSelection">
              Αριστοτελικά έργα, Μεταφράσεις, Σχόλια
            </Link>
            <Typography color="textPrimary">Αναζήτηση Σχολίων με λέξη-κλειδί</Typography>
        </Breadcrumbs>
        </div>
      </Grid>

     <Grid item xs={12} style={{ paddingTop : '20px'}}>
       <Grid container justify="center" spacing={2}>
           <Grid item xs>

             <Card>
               <CardHeader className="AristosCardHeader">
                  Σχόλια με λέξη-κλειδί: {props.match.params.tag}
               </CardHeader>
               <CardBody>
                <GridContainer spacing={2}>
                  <GridItem xs={12} sm={12} md={6}>

                  {
                    loading && <CircularProgress />
                  }
                  {
                    !loading && error &&
                    <Typography variant='body1'color='error'>Παρουσιάστηκε σφάλμα: {error}</Typography>
                  }
                  {
                    !loading && !error && (!comments || comments.length === 0) &&
                    <Typography variant='body1'>Δε βρέθηκαν αποτελέσματα</Typography>
                  }
                  {
                    !loading && comments && comments.map((c, idx) =>
                      <div key={idx}>
                        <Link href={'/admin/CollTranslationREADONLY/'+c.doc_id}>{c.work_name}, {c.book_name}, Στίχοι: {c.doc_lines}</Link>
                        <Typography className={classes.lyrics}>[{c.lines} {c.phrase}]</Typography>
                        <Typography className={classes.scholar}>{c.scholar} {c.scholar_reference}</Typography>
                        <Divider/>
                      </div>
                    )
                  }

                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>

          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </div>
  )
}
