import { fetchWithGET, fetchWithMethod } from './clientUtils.js'

function getAllBooks(work_id, success, errorFunc){
  const querystring = work_id ? '?work_id=' + work_id : ''

  return fetchWithGET('books', querystring, success, errorFunc)
}

function getBookAndWorkName(work_id, book_id, success, errorFunc){
  const querystring = "?work_id=" + work_id + "&book_id=" + book_id

  return fetchWithGET('work_book_names', querystring, success, errorFunc)
}

function addBook(vals, success, errorFunc){
  //console.log(JSON.stringify(vals));
  //console.log(vals);
  return fetchWithMethod('books', 'POST', vals, success, errorFunc);
}

function editBook(vals, success, errorFunc){
  //console.log(JSON.stringify(vals));
  //console.log(vals);
  return fetchWithMethod('books', 'PUT', vals, success, errorFunc);
}

function deleteBook(vals, success, errorFunc){
  return fetchWithMethod('books', 'DELETE', vals, success, errorFunc);
}

export {getAllBooks, addBook, editBook, deleteBook, getBookAndWorkName}
