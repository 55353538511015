import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const PreviewUserRolesAndUsergroups = (props) => {
  const { roles, usergroups } = props

  return(
    <>
      <Typography variant='h4'>Ρόλοι</Typography>
      <List>
      {
        roles.map(r =>
          <Typography key={r.id} variant='body1'>{r.name}</Typography>
        )
      }
      </List>
      <Divider/>
      <Typography variant='h4'>Ομάδες χρήστη</Typography>
      <List>
      {
        usergroups.map(ug =>
          <Typography key={ug.usergroup_id} variant='body1'>{ug.usergroup_name}</Typography>
        )
      }
      </List>
    </>
  )
}

export default PreviewUserRolesAndUsergroups
