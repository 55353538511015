import React, {Component} from 'react';
import {Segment} from 'semantic-ui-react';
/*import {convertFromRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';*/
import StatusMessage from '../statusmessage';
import Post from '../post';
import NewPost from '../newpost';
import NewThread from '../newthread';
import SelectUserForm from '../selectuserform';
import './styles.css';
import { postPageSize } from '../../utils/config.js'
import { filterVisible } from '../../utils/utils.js'

import Pagination from '@material-ui/lab/Pagination';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import PinDropIcon from '@material-ui/icons/PinDrop';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import Tooltip from '@material-ui/core/Tooltip';
import StarIcon from '@material-ui/icons/Star';
import StarsIcon from '@material-ui/icons/Stars';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import ForwardIcon from '@material-ui/icons/Forward';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'

export default class Thread extends Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef();
  }

  state = {
    editPostID: null,
    editPostContent: '',
    editPostFile: null,
    editPostKeywords: [],
    editThreadName: '',
    editThreadContent: '',
    editThreadFile: null,
    editThreadKeywords: [],
    showEditType: 'post',
    errorAlert: null,
    showHidden: false,
    //Snackbar messages (to inform user of success)
    snackbarMessage: '',
    openSnackbar: false,
    openForwardForm: false,
  }

  goToForm = () => {
    this.formRef.current.scrollIntoView()
  }

  prepareToEditPost = (post) => {
    this.setState({editPostID: post.id, editPostContent: post.content,
      editPostFile: post.file || null,
      editPostKeywords: post.keywords || [],
      showEditType: 'post'})
    //this.props.editPostStart()
    this.goToForm()
  }

  cancelEditPost = () => {
    this.resetEditPostFields()
    this.props.cancelEdit()
  }

  resetEditPostFields = () => {
    //console.log('resetting')
    this.setState({editPostID: null, editPostContent: '',
      editPostFile: null, editPostKeywords: []})
  }

  prepareToEditThread = () => {
    this.setState({
      editThreadName: this.props.name,
      editThreadContent: this.props.content,
      editThreadFile: this.props.file || null,
      editThreadKeywords: this.props.keywords || [],
      showEditType: 'thread',
    })
    this.goToForm()
  }

  cancelEditThread = () => {
    this.resetEditThreadFields()
    this.props.cancelEditThread()
  }

  resetEditThreadFields = () => {
    this.setState({
      editThreadName: '',
      editThreadContent: '',
      editThreadFile: null,
      editThreadKeywords: [],
      showEditType: 'post',
    })
  }

  handleShowSuccessMessage = (message) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
    })
  }

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({openSnackbar: false})
  }

  hideErrorAlert = () => {
    this.setState({errorAlert: null})
  };

  toggleShowHidden = () => {
    this.setState({showHidden: !this.state.showHidden})
  }

  togglePinned = () => {
    const { togglePinned, id, pinned } = this.props
    //console.log(id)
    togglePinned(id, pinned)
  }

  toggleFavourite = (favourite) => {
    const { toggleFavourite, id } = this.props
    toggleFavourite(id, favourite)
  }

  toggleNotifications = () => {
    const { toggleNotifications, id } = this.props
    //console.log('set thread '+id+' notifications to ' + !currentState)
    toggleNotifications(id)
  }

  prepareToForward = () => {
    this.setState({openForwardForm: true})
  }

  closeForwardForm = () => {
    this.setState({openForwardForm: false})
  }

  forwardThread = (selected) => {
    const { id, forwardThread } = this.props
    this.closeForwardForm()
    forwardThread(id, selected)
  }
  /*copyThread = () => {
    const { date, content, posts } = this.props
    console.log(date)
    console.log(this.getHtmlFromContent(content))
    const visiblePosts = posts ? posts.filter(filterVisible) : [];
    visiblePosts.forEach(p => {
      console.log(p.date)
      console.log(this.getHtmlFromContent(p.content))
    });
  }*/

  /*getHtmlFromContent = (content) => {
    const contentState = convertFromRaw(JSON.parse(content));

    to get plain text:
    return contentState.getPlainText()

    to get html:
    return stateToHTML(contentState)
  }*/

  componentDidUpdate(prevProps){
    const newPostJustAdded = this.props.newPostSuccess !== prevProps.newPostSuccess && this.props.newPostSuccess;
    const threadJustEdited = this.props.editThreadSuccess !== prevProps.editThreadSuccess && this.props.editThreadSuccess;
    const postJustDeleted = this.props.deletePostSuccess !== prevProps.deletePostSuccess && this.props.deletePostSuccess;
    const postJustRestored = this.props.restorePostSuccess !== prevProps.restorePostSuccess && this.props.restorePostSuccess;

    if (newPostJustAdded){
      this.resetEditPostFields()
    }
    if (threadJustEdited){
      this.resetEditThreadFields()
    }
    if (this.props.editThreadError !== prevProps.editThreadError && this.props.editThreadError){
      //console.log('show error '+this.props.editThreadError)
      this.setState({editThreadName: this.props.editThreadName,
        editThreadContent: this.props.editThreadContent})
    }

    //handle success messages
    if (newPostJustAdded || threadJustEdited){
      this.handleShowSuccessMessage('Η καταχώριση έγινε με επιτυχία')
    }
    if (postJustDeleted){
      this.handleShowSuccessMessage('H διαγραφή έγινε με επιτυχία')
    }
    if (postJustRestored){
      this.handleShowSuccessMessage('Η επαναφορά έγινε με επιτυχία')
    }
    if (this.props.togglePinnedSuccess !== prevProps.togglePinnedSuccess && this.props.togglePinnedSuccess){
      this.handleShowSuccessMessage('Το ' + (this.props.pinned ? 'καρφίτσωμα' : 'ξεκαρφίτσωμα') + ' έγινε με επιτυχία')
    }
    if (this.props.toggleFavouriteSuccess !== prevProps.toggleFavouriteSuccess && this.props.toggleFavouriteSuccess){
      this.handleShowSuccessMessage('Η ' + (this.getFavouriteStateForToggle() ? 'επισήμανση' : 'αφαίρεση επισήμανσης') + ' έγινε με επιτυχία')
    }
    if (this.props.likeSuccess !== prevProps.likeSuccess && this.props.likeSuccess){
      this.handleShowSuccessMessage('Η ενέργεια έγινε με επιτυχία')
    }
    if (this.props.toggleNotificationsSuccess !== prevProps.toggleNotificationsSuccess && this.props.toggleNotificationsSuccess){
      //get new subscription status
      const threadSubscriptions = JSON.parse(localStorage.getItem("thread_subscriptions")) || []
      const userSubscibedToThread = threadSubscriptions.includes(this.props.id)
      //show message
      this.handleShowSuccessMessage('Η ' + (userSubscibedToThread ? 'ενεργοποίηση' : 'απενεργοποίηση') + ' ειδοποιήσεων έγινε με επιτυχία')
    }
    if (this.props.forwardThreadSuccess !== prevProps.forwardThreadSuccess && this.props.forwardThreadSuccess){
      this.handleShowSuccessMessage('Η προώθηση έγινε με επιτυχία')
    }

    //handle error messages
    let errorToDisplay = ''
    if (this.props.newPostError !== prevProps.newPostError && this.props.newPostError){
      errorToDisplay = this.props.newPostError
    } else if (this.props.editThreadError !== prevProps.editThreadError && this.props.editThreadError){
      errorToDisplay = this.props.editThreadError
    } else if (this.props.deleteError !== prevProps.deleteError && this.props.deleteError){
      errorToDisplay = this.props.deleteError
    } else if (this.props.deletePostError !== prevProps.deletePostError && this.props.deletePostError){
      errorToDisplay = this.props.deletePostError
    } else if (this.props.restorePostError !== prevProps.restorePostError && this.props.restorePostError){
      errorToDisplay = this.props.restorePostError
    } else if (this.props.toggleError !== prevProps.toggleError && this.props.toggleError){
      errorToDisplay = this.props.toggleError
    } else if (this.props.copyThreadError !== prevProps.copyThreadError && this.props.copyThreadError){
      errorToDisplay = this.props.copyThreadError
    } else if (this.props.likeError !== prevProps.likeError && this.props.likeError){
      errorToDisplay = this.props.likeError
    } else if (this.props.forwardThreadError !== prevProps.forwardThreadError && this.props.forwardThreadError){
      errorToDisplay = this.props.forwardThreadError
    }
    if (errorToDisplay !== ''){
      //this.handleShowSuccessMessage(errorToDisplay)
      const alert = (<ErrorAlert message={errorToDisplay} hideAlert={this.hideErrorAlert}/>)
      this.setState({errorAlert: alert})
    }
  }

  componentDidMount(){
    const threadSubscriptions = JSON.parse(localStorage.getItem("thread_subscriptions")) || []
    /*console.log('didmount')
    console.log(threadSubscriptions)*/
    this.setState({userSubscibedToThread: threadSubscriptions.includes(this.props.id)})
  }

  getFavouriteStateForToggle = () => {
    const {authenticatedIsStaff, favourite_admin} = this.props
    return authenticatedIsStaff ? favourite_admin : this.isUserFavourite();
  }

  isUserFavourite = () => {
    const {isAuthenticated, favourite_user} = this.props
    const authenticatedUser = localStorage.getItem('UserId')

    return (favourite_user && isAuthenticated &&
      favourite_user.includes(parseInt(authenticatedUser)))
  }

  render() {
    const {
      id,
      isLoading,
      name,
      content,
      keywords,
      file,
      pinned,
      user_id,
      user_fullname,
      date,
      posts,
      error,
      page,
      date_edited,
      edited_by,
      favourite_admin,
      likes,
      isAuthenticated,
      createPost,
      newPostLoading,
      newPostSuccess,
      editPost,
      authenticatedIsStaff,
      deletePostList,
      deletePost,
      restorePost,
      isDeleting,
      deleteThread,
      editThread,
      editThreadLoading,
      editThreadSave,
      editThreadToggle,
      handleChangePage,
      copyThread,
      isCopying,
      likeThread,
      likePost,
      isLikingID,
      authenticatedIsForumAdmin,
      isForwarding,
    } = this.props;

    if (error || isLoading || isDeleting || !name) {
      let loadingMessage = 'Περιμένετε όσο γίνεται φόρτωση';
      if (isDeleting) {
        loadingMessage = 'Περιμένετε όσο γίνεται διαγραφή';
      }
      return (
        <StatusMessage
          error={error}
          errorClassName="thread-error"
          errorMessage={error}
          loading={isLoading || isDeleting}
          loadingMessage={loadingMessage}
          nothing={!name}
          nothingMessage={'Η συζήτηση δε βρέθηκε'}
          type="default"
        />
      );
    }

    const filteredPosts =  posts ? posts.filter(filterVisible) : []
    //const visiblePosts = this.state.showHidden ? posts : filteredPosts;
    const hasHidden = posts && filteredPosts.length < posts.length
    const pageStart = (page===1) ? 0 : (page - 1) * postPageSize - 1
    const pageEnd = (page===1) ? pageStart + postPageSize - 1 : pageStart + postPageSize
    const totalPostsWithThreadPost = posts ? posts.length + 1 : 1

    const isUserFavourite = this.isUserFavourite()
    const currentFavouriteStateForToggle = this.getFavouriteStateForToggle()
    const threadSubscriptions = JSON.parse(localStorage.getItem("thread_subscriptions")) || []
    //console.log(threadSubscriptions)
    const userSubscibedToThread = threadSubscriptions.includes(id)
    //console.log('newval='+userSubscibedToThread)

    const threadPost = (page===1) ? (
      <Post
        id={id}
        threadID={id}
        isThread={true}
        content={content}
        file={file}
        date={date}
        user_id={user_id}
        user_fullname={user_fullname}
        authenticatedIsStaff={authenticatedIsStaff}
        deleteAction={deleteThread}
        editAction={this.prepareToEditThread}
        isLastPostInThread={totalPostsWithThreadPost===1}
        date_edited={date_edited}
        edited_by={edited_by}
        keywords={keywords}
        isAuthenticated={isAuthenticated}
        likes={likes}
        likeAction={likeThread}
        isLiking={isLikingID===id}
      />
    ) : null;

    const postsList =
      (!posts || posts.length === 0)
        ? null
        : posts.slice(pageStart, pageEnd).map((post, idx) => {
            const {
              id: postID,
              content: postContent,
              date: postCreatedAt,
              file: postFile,
              user_fullname: postCreator,
              user_id: postUserID,
              hidden: postHidden,
              hidden_by: postHiddenBy,
              date_edited: postEditedAt,
              edited_by: postEditedBy,
              keywords: postKeywords,
              likes: postLikes,
            } = post;

            //+1 for thread post, +1 because idx starts at 0
            const postIdxFromStart = pageStart + idx + 2

            return (
              <Post
                key={postID}
                threadID={id}
                id={postID}
                isThread={false}
                content={postContent}
                file={postFile}
                date={postCreatedAt}
                user_fullname={postCreator}
                user_id={postUserID}
                likes={postLikes}
                authenticatedIsStaff={authenticatedIsStaff}
                deletePostList={deletePostList}
                deleteAction={deletePost}
                editAction={this.prepareToEditPost}
                restoreAction={restorePost}
                isLastPostInThread={postIdxFromStart===totalPostsWithThreadPost}
                hidden={postHidden}
                showHidden={this.state.showHidden}
                hidden_by={postHiddenBy}
                date_edited={postEditedAt}
                edited_by={postEditedBy}
                keywords={postKeywords}
                isAuthenticated={isAuthenticated}
                likeAction={likePost}
                isLiking={isLikingID===postID}
              />
            );
          });

    return (
      <div className="threadContainer">
        <div className="thread-title">
          {
            pinned && <PinDropIcon fontSize='large'/>
          }
          {// Note: put in <div style={{color: '#ff0000'}}> to give different colour
            favourite_admin &&
            <Tooltip title='Επισημασμένο από το διαχειριστή'>
              <StarIcon color='primary' fontSize='large'/>
            </Tooltip>
          }
          {
            isUserFavourite &&
            <Tooltip title='Επισημασμένο από εσάς'>
              <StarIcon color='secondary' fontSize='large'/>
            </Tooltip>
          }
          {name}
          {
            authenticatedIsStaff &&
            <>
              <Tooltip title={pinned ? 'Ξεκαρφίτσωμα' : 'Καρφίτσωμα'}>
                <IconButton size='small' onClick={this.togglePinned}>
                  <EditLocationIcon color={pinned ? 'secondary' : 'primary'}/>
                </IconButton>
              </Tooltip>
              <Tooltip title='Προώθηση σε συνεργάτη'>
                <IconButton size='small' onClick={this.prepareToForward}>
                  <ForwardIcon/>
                </IconButton>
              </Tooltip>
            </>
          }
          {
            isAuthenticated &&
            <>
              <Tooltip title={currentFavouriteStateForToggle ? 'Αφαίρεση επισήμανσης' : 'Προσθήκη επισήμανσης'}>
                <IconButton size='small' onClick={() => this.toggleFavourite(currentFavouriteStateForToggle)}>
                  <StarsIcon color={currentFavouriteStateForToggle ? 'secondary' : 'primary'}/>
                </IconButton>
              </Tooltip>
              <Tooltip title='Εξαγωγή συζήτησης σε αρχείο'>
                <IconButton onClick={() => copyThread(id)} disabled={isCopying}>
                  <FileCopyIcon/>
                </IconButton>
              </Tooltip>
              {
                !authenticatedIsForumAdmin &&
                <Tooltip title={(userSubscibedToThread ? 'Απενεργοποίηση ' : 'Ενεργοποίηση ')+'ειδοποιήσεων για αυτή τη συζήτηση'}>
                  <IconButton size='small' onClick={this.toggleNotifications}>
                    {userSubscibedToThread ? <NotificationsOffIcon/> : <AddAlertIcon/>}
                  </IconButton>
                </Tooltip>
              }
            </>
          }
          {/*
            isAuthenticated && !authenticatedIsStaff &&
            <Tooltip title={(userSubscibedToThread ? 'Απενεργοποίηση ' : 'Ενεργοποίηση ')+'ειδοποιήσεων για αυτή τη συζήτηση'}>
            </Tooltip>*/
          }
        </div>

        { authenticatedIsStaff && hasHidden &&
          <div className="thread-message">
          Η συζήτηση περιέχει διαγραμμένες αναρτήσεις&nbsp;&nbsp;
          <FormControlLabel control={<Switch checked={this.state.showHidden} onChange={this.toggleShowHidden} />}
           label="Προβολή διαγραμμένων" />
          </div>
        }
        <Segment.Group className="thread-list">
          {threadPost}
          {postsList}
        </Segment.Group>
        <Pagination count={Math.ceil(totalPostsWithThreadPost/postPageSize)} color="primary" page={page} onChange={handleChangePage}/>
        <div ref={this.formRef}></div>

        {
          this.state.showEditType === 'post' ?
          <NewPost
          isAuthenticated={isAuthenticated}
          threadID={id}
          postID={this.state.editPostID}
          postContent={this.state.editPostContent}
          postFile={this.state.editPostFile}
          postKeywords={this.state.editPostKeywords}
          createPost={createPost}
          editPost={editPost}
          isLoading={newPostLoading}
          cancelEditPost={this.cancelEditPost}
          maxLength={2000}
          success={newPostSuccess}
        />
        :
        <NewThread
          isAuthenticated={isAuthenticated}
          isLoading={editThreadLoading}
          name={this.state.editThreadName}
          content={this.state.editThreadContent}
          keywords={this.state.editThreadKeywords}
          file={this.state.editThreadFile}
          id={id}
          showEditor={true}
          createThread={editThread}
          updateNewThread={editThreadSave}
          toggleShowEditor={editThreadToggle}
          cancelEdit={this.cancelEditThread}
          maxLength={2000}
        />}

        <SelectUserForm open={this.state.openForwardForm} handleClose={this.closeForwardForm}
          formTitle='Επιλογή συνεργατών για προώθηση συζήτησης'
          submitLabel='Προώθηση' onSubmit={this.forwardThread} isLoading={isForwarding}/>

        <Snackbar anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.openSnackbar}
          autoHideDuration={3000}
          onClose={this.handleCloseSnackbar}
          >
          <Alert onClose={this.handleCloseSnackbar} severity="success">
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>

        {this.state.errorAlert}
      </div>
    );
  }
}

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
