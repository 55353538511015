import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'

import Button from "components/CustomButtons/Button.js";
import CircularProgress from '@material-ui/core/CircularProgress';

import UserSelectableTable from "./UserSelectableTable.js"
import { UserForm } from "./UserForm.js"
import { UsergroupForm } from './UsergroupForm.js'
import PreviewRoleRights from './PreviewRoleRights.js'
import SelectWorksForRole from './SelectWorksForRole.js'
import SelectLabsForRole from './SelectLabsForRole.js'
import SelectForumsForRole from './SelectForumsForRole.js'
import PreviewUsergroup from './PreviewUsergroup.js'
import PreviewUserRolesAndUsergroups from './PreviewUserRolesAndUsergroups.js'

const AddUserDialog = (props) => {
  const {handleClose, handleAdd, dialogTitle, dialogButtonLabel, open,
    ...rest} = props
  const disableButton = props.disableButton ? props.disableButton : false

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'md'}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent dividers>

          <UserForm {...rest}/>

        </DialogContent>
        <DialogActions>
          {
            disableButton ?
              <CircularProgress color="primary"/> :
              <Button autoFocus onClick={handleAdd} color="primary">
                {dialogButtonLabel}
              </Button>
          }
        </DialogActions>
      </Dialog>
  )
}

const AddUsergroupDialog = (props) => {
  const {handleClose, handleAdd, dialogTitle, dialogButtonLabel, open, ...rest} = props
  const disableButton = props.disableButton ? props.disableButton : false

    return (
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'md'}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {dialogTitle}
          </DialogTitle>
          <DialogContent dividers>
            <UsergroupForm {...rest}/>
          </DialogContent>
          <DialogActions>
          {
            disableButton ?
              <CircularProgress color="primary"/> :
              <Button autoFocus onClick={handleAdd} color="primary">
                {dialogButtonLabel}
              </Button>
          }
          </DialogActions>
        </Dialog>
    )
}

const AddUsergroupMemberDialog = (props) => {
  const {handleClose, handleAdd, open, Usersdata, ...rest} = props
  const disableButton = props.disableButton ? props.disableButton : false

    return (
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Προσθήκη Μέλους Ομάδας
          </DialogTitle>
          <DialogContent dividers>

            <UserSelectableTable usersdata={Usersdata} {...rest}/>

          </DialogContent>
          <DialogActions>
          {
            disableButton ?
              <CircularProgress color="primary"/> :
              <Button autoFocus onClick={handleAdd} color="primary">
                Προσθηκη
              </Button>
          }
          </DialogActions>
        </Dialog>
    )
}


const AddRolesMemberDialog = (props) => {
  const {handleClose, handleAdd, open, Usersdata,
    labsdata, define_labs, handleChangeLabs, labsForRole, errorLabs,
    Worksdata, define_works, handleChangeWork, worksForRole, errorWork,
    forumsdata, define_forums, handleChangeForums, forumsForRole, errorForums,
    ...rest} = props
  const disableButton = props.disableButton ? props.disableButton : false

    return (
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Προσθήκη Χρηστών σε Ρόλο
          </DialogTitle>
          <DialogContent dividers>

            <UserSelectableTable usersdata={Usersdata} {...rest}/>
            {
              define_works &&
              <SelectWorksForRole work={worksForRole} allWorks={Worksdata}
                handleChange={handleChangeWork} error={errorWork}/>
            }
            {
              define_labs &&
              <SelectLabsForRole selectedLabs={labsForRole} allLabs={labsdata}
                handleChange={handleChangeLabs} error={errorLabs}/>
            }
            {
              define_forums &&
              <SelectForumsForRole forum={forumsForRole} allForums={forumsdata}
                handleChange={handleChangeForums} error={errorForums}/>
            }

          </DialogContent>
          <DialogActions>
          {
            disableButton ?
              <CircularProgress color="primary"/> :
              <Button autoFocus onClick={handleAdd} color="primary">
                Προσθηκη
              </Button>
          }
          </DialogActions>
        </Dialog>
    )
}


const EditRoleMemberDialog = (props) => {
  const { open, handleClose, handleEdit,
    labsdata, define_labs, handleChangeLabs, labsForRole, errorLabs,
    Worksdata, define_works, handleChangeWork, worksForRole, errorWork,
    forumsdata, define_forums, handleChangeForums, forumsForRole, errorForums
    } = props
  const disableButton = props.disableButton ? props.disableButton : false

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Επεξεργασία Ρόλου Χρήστη
        </DialogTitle>
        <DialogContent dividers>

          {
            define_works &&
            <SelectWorksForRole work={worksForRole} allWorks={Worksdata}
              handleChange={handleChangeWork} error={errorWork}/>
          }
          {
            define_labs &&
            <SelectLabsForRole selectedLabs={labsForRole} allLabs={labsdata}
              handleChange={handleChangeLabs} error={errorLabs}/>
          }
          {
            define_forums &&
            <SelectForumsForRole forum={forumsForRole} allForums={forumsdata}
              handleChange={handleChangeForums} error={errorForums}/>
          }

        </DialogContent>
        <DialogActions>
        {
          disableButton ?
            <CircularProgress color="primary"/> :
            <Button autoFocus onClick={handleEdit} color="primary">
              Επεξεργασία
            </Button>
        }
        </DialogActions>
      </Dialog>
  )
}


const ViewRoleRightsDialog = (props) => {
  const {handleClose, open, role} = props

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Δικαιώματα Ρόλου
        </DialogTitle>
        <DialogContent dividers>

          <PreviewRoleRights role={role}/>

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Κλεισιμο
          </Button>
        </DialogActions>
      </Dialog>
  )
}


const ViewGroupRolesAndRightsDialog = (props) => {
  const {handleClose, open, usergroup, works, labs, forums} = props

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'md'}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Επισκόπηση Ρόλων και Δικαιωμάτων Ομάδας Χρηστών
        </DialogTitle>
        <DialogContent dividers>

          <PreviewUsergroup usergroup={usergroup} works={works} labs={labs}
            forums={forums}/>

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Κλεισιμο
          </Button>
        </DialogActions>
      </Dialog>
  )
}


const ViewUserRolesAndUsergroups = (props) => {
  const {handleClose, open, firstname, lastname, ...rest} = props

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'md'}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Προβολή Ρόλων και Ομάδων Χρηστών του χρήστη {firstname} {lastname}
        </DialogTitle>
        <DialogContent dividers>

          <PreviewUserRolesAndUsergroups {...rest}/>

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Κλεισιμο
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export { AddUserDialog, AddUsergroupDialog, AddUsergroupMemberDialog, AddRolesMemberDialog,
  EditRoleMemberDialog, ViewRoleRightsDialog, ViewGroupRolesAndRightsDialog,
  ViewUserRolesAndUsergroups }
