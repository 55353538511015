
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText'
import Sidebar from "components/Sidebar/Sidebar.js";
import CssBaseline from '@material-ui/core/CssBaseline';

import avatar from "assets/img/faces/marc.jpg";


import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function AddBlog() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [state, setState] = React.useState({
    blog: true,
    wiki: true,
    mindmap: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>
      <main className={classes.content}>




      <GridContainer>

          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Προσθήκη Blog</h4>

              </CardHeader>
              <CardBody>

              <List component="nav" aria-label="mailbox folders">
                    <ListItem marginTop="10">
                    <CustomInput
                      labelText="Ονομασία Blog"
                      id="first-name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                       defaultValue: "Περὶ γενέσεως καὶ φθορᾶς"
                      }}
                    
                    />
                    </ListItem>

                    <ListItem marginTop="10">
                    <TextField
                          id="date"
                          label="Κείμενο"
                          type="text"
                          multiline
                          rows={10}
                          style = {{width: 540}}

                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue="Οι σχολιαστές συμφωνούν ότι εδώ έχουμε σε συμπυκνωμένη μορφή την κριτική των επιχειρημάτων των Ατομικών για το φαινόμενο της αύξησης, το οποίο αποδίδουν στην ύπαρξη του κενού. Η θέση του Αριστοτέλη είναι ότι, για να σταθεί η θεωρία τους, παραβιάζουν κάποια από τις τέσσερις συνθήκες που απαριθμεί. Το χωρίο πρέπει να διαβαστεί υπό το φως των αναλύσεων του Αριστοτέλη"
                        />
                        </ListItem>
                  </List>
                  </CardBody>
              <CardFooter>
                <Button color="primary">Προσθήκη</Button>
              </CardFooter>
            </Card>
          </GridItem>

        </GridContainer>

        <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Blogs Αριστοτελικών έργων</h4>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["Blogs", "Ημερομηνία"]}
                    tableData={[
                      [<a href= "./Blog"><u>Φυσική ακρόαση</u></a>, "19/10/2020"],
                      [<a href= "./Blog "><u>Περί γενέσεως και φθοράς</u></a>, "17/9/2020"],
                      [<a href= "./Blog "><u>Μετεωρολογικά</u></a>, "6/5/2020"],
                      [<a href= "./Blog "><u>Μετά τα φυσικά</u></a>, "16/4/2020"],
                      [<a href= "./Blog "><u>Περί κόσμου</u></a>, "22/3/2020"],
                    ]}
                  />

                </CardBody>
              </Card>
            </GridItem>

          </GridContainer>

      </main>
    </div>
  );
}
