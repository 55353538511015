import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Sidebar from "components/Sidebar/Sidebar.js";


import { green } from '@material-ui/core/colors';
import { blue } from '@material-ui/core/colors';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import Grid from '@material-ui/core/Grid';


import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import NavPills from "components/NavPills/NavPills.js";

import Info from "@material-ui/icons/Info";
import LocationOn from "@material-ui/icons/LocationOn";
import BackupIcon from '@material-ui/icons/Backup';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ConfirmDeleteMessage } from 'views/CustomAlerts/ConfirmationDialog.js'
import { ErrorAlert, WarningAlert } from 'views/CustomAlerts/CustomAlerts.js'

import { getAllWorks, getAllWorksWithBooks } from "clients/worksClient.js";
import { getAllBooks } from "clients/booksClient.js"
import { getAllSections } from "clients/sectionsClient.js"

import WorksTab from './WorksTab.js'
import BooksTab from './BooksTab.js'
import SectionsTab from './SectionsTab.js'
import UploadTab from './UploadTab.js'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },


    exampleWrapper: {
      position: 'relative',
      marginTop: theme.spacing(0),
      height: 80,
    },

    speedDial: {
      position: 'absolute',
      '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
    BlogIconCol: {
      backgroundColor: green[100],
      color: green[600],
    },
    WikiIconCol: {
      backgroundColor: "#2C8CF4",
      color: blue[600],
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    },

    LabelCol: {
      color:"#3f51b5",
      fontweight: "bold"
    },

	cardFix: {
      fontSize: "1em!important",
    },

	buttonAdd: {
      margin: "30px auto 0 auto",
	  display: "block",
    },



}));

export default function AdminErgBook() {

  const classes = useStyles();

  const [Worksdata, setWorksData] = React.useState([])
  const [Booksdata, setBooksdata] = React.useState([]);
  const [Sectionsdata, setSectionsdata] = React.useState([])
  const [WorksAndBooksdata, setWorksAndBooksdata] = React.useState([])

  const loadDataForActiveTab = (active) => {
    switch(active){
      case 0:
        getAllWorks(
          (data) => setWorksData(data.works),
          (err) => console.log(err)
        );
        break;

      case 1:
        getAllBooks(
          '',
          (data) => setBooksdata(data.books),
          (err) => console.log(err)
        );
        //also get works list for book insertion form
        getAllWorks(
          (data) => setWorksData(data.works),
          (err) => console.log(err)
        );
        break;

      case 2:
        loadAllSections()
        //also get works list for book insertion form
        getAllWorksWithBooks('',
          (data) => setWorksAndBooksdata(data.works),
          (err) => console.log(err)
        );
        //setWorksData([])
        break;

      default:
        ;
    }
  }

  const loadAllSections = () => {
    //console.log('sections')
    getAllSections(
      (data) => setSectionsdata(data.documents),
      (err) => console.log(err)
    );
  }

  React.useEffect(() => {
    loadDataForActiveTab(0)
  }, [])

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };
  const showConfirmDelete = (id, onConfirmClick) => {
    setAlert(<ConfirmDeleteMessage onConfirm={() => { hideAlert(); onConfirmClick(id); }} onCancel={hideAlert}/>)
  }
  /*const onConfirm = (nextAction, id) => {
    setAlert(null);

    switch (nextAction){
      case 'bookDelete':
        handleDeleteBook(id);
        break;
      case 'sectionDelete':
        handleDeleteSection(id)
        break;
    }
  }*/

  //Snackbar messages (to inform user of success)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const handleShowSuccessMessage = (message) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message)
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (

    <div className={classes.root}>
      <CssBaseline />
      <Sidebar/>

      <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} >
        <div>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" href="/admin/SelectTransSem">
              Main
            </Link>
            <Link color="inherit" href="/admin/Seminars">
              Διαχείριση
            </Link>
        </Breadcrumbs>
        </div>
      </Grid>
      <Grid item  xs={12} style={{ paddingTop : '20px'}}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
        {/*  <h3 className={classes.pageSubcategoriesTitle}>Page Subcategories</h3> */}
          <br />
          <NavPills
            color="warning"
            alignCenter
            onChange={loadDataForActiveTab}
            tabs={[
              {
                tabButton: "Εργα",
                tabIcon: Info,
                tabContent: (
                  <WorksTab Worksdata={Worksdata} updateWorksData={setWorksData} handleShowSuccessMessage={handleShowSuccessMessage}
                    showErrorAlert={(msg) => setAlert(<ErrorAlert message={msg} hideAlert={hideAlert}/>)}
                    showConfirmDelete={showConfirmDelete} classes={classes}
                  />
                )
              },

              {
                tabButton: "Βιβλια",
                tabIcon: LocationOn,
                tabContent: (
                  <BooksTab Booksdata={Booksdata} Worksdata={Worksdata} updateBooksData={setBooksdata} handleShowSuccessMessage={handleShowSuccessMessage}
                    showErrorAlert={(msg) => setAlert(<ErrorAlert message={msg} hideAlert={hideAlert}/>)}
                    showConfirmDelete={showConfirmDelete} classes={classes}
                  />
                )
              },

              {
                tabButton: "Χωρια",
                tabIcon: Info,
                tabContent: (
                  <SectionsTab Sectionsdata={Sectionsdata} Worksdata={WorksAndBooksdata} updateSectionsData={setSectionsdata} handleShowSuccessMessage={handleShowSuccessMessage}
                    showErrorAlert={(msg) => setAlert(<ErrorAlert message={msg} hideAlert={hideAlert}/>)}
                    showWarningAlert={(msg) => setAlert(<WarningAlert message={msg} hideAlert={hideAlert}/>)}
                    showConfirmDelete={showConfirmDelete} classes={classes} onAddSection={loadAllSections}
                  />
                )
              },

              {
                tabButton: "Ανέβασμα τίτλων από Excel",
                tabIcon: BackupIcon,
                tabContent: (
                  <UploadTab handleShowSuccessMessage={handleShowSuccessMessage}
                    showErrorAlert={(msg) => setAlert(<ErrorAlert message={msg} hideAlert={hideAlert}/>)}
                    showWarningAlert={(msg) => setAlert(<WarningAlert message={msg} hideAlert={hideAlert}/>)}/>
                )
              }


            ]}
          />
        </GridItem>
      </GridContainer>

      </Grid>

      </Grid>

        {alert}

        <Snackbar anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          >
          <Alert onClose={handleCloseSnackbar} severity="success">
            {snackbarMessage}
          </Alert>
        </Snackbar>

    </div>

  );
}
