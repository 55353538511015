import React, {Component} from 'react';
//import {Link} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import {Segment, Grid} from 'semantic-ui-react';
import StatusMessage from '../statusmessage';
//import Avatar from '../avatar';
import './styles.css';
import { threadPageSize } from '../../utils/config.js'
import { filterVisible } from '../../utils/utils.js'

import Pagination from '@material-ui/lab/Pagination';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from "components/CustomButtons/Button.js";
import IconButton from '@material-ui/core/IconButton';
import PinDropIcon from '@material-ui/icons/PinDrop';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import Tooltip from '@material-ui/core/Tooltip';
import StarIcon from '@material-ui/icons/Star';
import StarsIcon from '@material-ui/icons/Stars';

export default class ThreadList extends Component {
  state = {
    errorAlert: null,
    showHidden: false,
    //Snackbar messages (to inform user of success)
    snackbarMessage: '',
    openSnackbar: false,
  }

  onRestore = (id) => {
    const { restoreAction, forum_id } = this.props;
    const threadID = forum_id + '-' + id
    //console.log(threadID)
    restoreAction(threadID);
  }

  toggleShowHidden = () => {
    this.setState({showHidden: !this.state.showHidden})
  }

  togglePinned = (id, pinned) => {
    //console.log(id)
    const { togglePinnedAction, forum_id } = this.props
    const threadID = forum_id + '-' + id
    togglePinnedAction(threadID, pinned)
  }

  toggleFavourite = (id, favourite) => {
    const { toggleFavouriteAction, forum_id } = this.props
    const threadID = forum_id + '-' + id
    toggleFavouriteAction(threadID, favourite)
  }

  render() {
    const {isLoading, error, threads, forum_id, page, handleChangePage,
      authenticatedIsStaff, isAuthenticated} = this.props;

    if (error || !threads || isLoading || threads.length === 0) {
      return (
        <StatusMessage
          error={error || !threads}
          errorClassName="forum-error"
          errorMessage={error}
          loading={isLoading}
          loadingMessage={`Περιμένετε όσο γίνεται φόρτωση συζητήσεων`}
          nothing={threads && threads.length === 0}
          nothingMessage={`Δεν υπάρχουν συζητήσεις`}
          nothingClassName="forum-error"
          type="default"
        />
      );
    }

    const filteredThreads =  threads.filter(filterVisible);
    const visibleThreads = this.state.showHidden ? threads : filteredThreads;
    const hasHidden = filteredThreads.length < threads.length
    const pageStart = (page - 1) * threadPageSize
    const pageEnd = pageStart + threadPageSize
    const totalThreads = visibleThreads ? visibleThreads.length : 0

    const authenticatedUser = localStorage.getItem('UserId')

    const headerRow = (
      <Segment vertical className="grammi-titlos">
        <Grid textAlign="left" padded="horizontally">
          <Grid.Column width={6}>
            <Grid.Row>
              <div className="forum-row">
                <div className="forum-column">
                  <div>
                    Συζήτηση
                  </div>
                </div>
              </div>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={2}>
		    <Grid.Row>
          <div className="forum-row">
            <div className="forum-column forum-stats forum-vertical">
			           <div>
                Απαντήσεις
                  </div>
                </div>
              </div>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={4}>Τελευταία δραστηριότητα</Grid.Column>
        </Grid>
      </Segment>
    )

    const threadList = (visibleThreads.length==0) ?
      <StatusMessage
        error={false}
        errorClassName="forum-error"
        nothing={true}
        nothingMessage={`Δεν υπάρχουν συζητήσεις`}
        nothingClassName="forum-error"
        type="default"
      />
      :
      <>
        {headerRow}
        {
          visibleThreads.slice(pageStart, pageEnd).map(thread => {
          let {
            id,
            name,
            pinned,
            user_fullname,
            date,
            replies_count,
            last_activity,
            hidden,
            favourite_admin,
            favourite_user,
          } = thread;

          name = name.length > 57 ? name.substring(0, 55) + '...' : name;

          let lastActivity = last_activity ? (
            <div className="forum-row">
              <div className="forum-column">
                <div className="forum-name">{last_activity.name}</div>
                <div className="forum-meta">
                  {last_activity.user_fullname}
                  <b>{`  —  ${last_activity.date}`}</b>
                </div>
              </div>
            </div>
          ) : (
            <div className="forum-text forum-vertical">{'—  Καμία δραστηριότητα —'}</div>
          );

          const isUserFavourite = (favourite_user && isAuthenticated &&
            favourite_user.includes(parseInt(authenticatedUser)))
          const currentFavouriteStateForToggle = authenticatedIsStaff ?
            favourite_admin : isUserFavourite;

          return (
            <Segment vertical key={id}>
              <Grid textAlign="left" padded="horizontally" className={hidden ? 'thread-hidden' : ''}>
                <Grid.Column width={6}>
                  <Grid.Row>
                    <div className="forum-row">
                      <div className="forum-column">
                        <div>
                          {
                            pinned && <PinDropIcon/>
                          }
                          {// Note: put in <div style={{color: '#ff0000'}}> to give different colour
                            favourite_admin &&
                            <Tooltip title='Επισημασμένο από το διαχειριστή'>
                              <StarIcon color='primary'/>
                            </Tooltip>
                          }
                          {
                            isUserFavourite &&
                            <Tooltip title='Επισημασμένο από εσάς'>
                              <StarIcon color='secondary'/>
                            </Tooltip>
                          }
                          {
                            hidden ?
                            <div className='thread-title-in-list'>
                              {name}
                              <Button size='sm' color='primary' onClick={() => this.onRestore(id)}>
                                Επαναφορά συζήτησης
                              </Button>
                            </div> :
                            <Link href={`/admin/Thread/${forum_id}-${id}`}>{name}</Link>
                          }
                          {
                            authenticatedIsStaff && !hidden &&
                            <Tooltip title={pinned ? 'Ξεκαρφίτσωμα' : 'Καρφίτσωμα'}>
                              <IconButton size='small' onClick={() => this.togglePinned(id, pinned)}>
                                <EditLocationIcon color={pinned ? 'secondary' : 'primary'}/>
                              </IconButton>
                            </Tooltip>
                          }
                          {
                            isAuthenticated && !hidden &&
                            <Tooltip title={currentFavouriteStateForToggle ? 'Αφαίρεση επισήμανσης' : 'Προσθήκη επισήμανσης'}>
                              <IconButton size='small' onClick={() => this.toggleFavourite(id, currentFavouriteStateForToggle)}>
                                <StarsIcon color={currentFavouriteStateForToggle ? 'secondary' : 'primary'}/>
                              </IconButton>
                            </Tooltip>
                          }
                        </div>
                        <div className="forum-meta">
                          {user_fullname}
                          <b>{`  —  ${date}`}</b>
                        </div>
                      </div>
                    </div>
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column width={2}>
                  <div className="forum-column forum-stats forum-vertical">
                    <div>
                      {replies_count}
                      {replies_count != 1 ? ' απαντήσεις' : ' απάντηση'}
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column width={4}>{lastActivity}</Grid.Column>
              </Grid>
            </Segment>
          )
        })
      }
    </>

    return (
      <div className="forumContainer">
        { authenticatedIsStaff && hasHidden &&
          <div className="thread-message">
            Η κατηγορία περιέχει διαγραμμένες συζητήσεις&nbsp;&nbsp;
            <FormControlLabel control={<Switch checked={this.state.showHidden} onChange={this.toggleShowHidden} />}
             label="Προβολή διαγραμμένων" />
          </div>
        }
        <Segment.Group className="forum-list">{threadList}</Segment.Group>
        <Pagination count={Math.ceil(totalThreads/threadPageSize)} color="primary" page={page} onChange={handleChangePage}/>
      </div>
    );
  }
}
