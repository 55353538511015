import React from 'react';
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import EditIcon from '@material-ui/icons/Edit';
import ReadIcon from '@material-ui/icons/EventNote'
import Tooltip from '@material-ui/core/Tooltip';

const UserActions = (props) => {
  const { user, handleClickEdit, handleClickToggleActive, handleClickView } = props
  return (
    <div className="actions-right">
      {/* use this button to add a edit kind of action */}
      <Button
        justIcon
        round
        simple
        onClick={() => handleClickEdit(user)}
        color="warning"
        className="edit"
      >
        <Tooltip title="Επεξεργασία">
          <EditIcon />
        </Tooltip>
      </Button>{" "}
      <Button
        justIcon
        round
        simple
        onClick={() => handleClickView(user)}
        color="info"
      >
        <Tooltip title="Προβολή ρόλων και ομαδών χρήστη">
          <ReadIcon />
        </Tooltip>
      </Button>{" "}      {/* use this button to activate/deactivate the data row */}
      <Button
        round
        size='sm'
        onClick={() => handleClickToggleActive(user.user_id, !user.active)}
        color='primary'
      >
      {user.active ? 'Απενεργοποίηση' : 'Ενεργοποίηση'}
      </Button>{" "}
    </div>
  )
}

const UsergroupActions = (props) => {
  const { usergroup, handleClickEdit, handleClickDelete } = props
  return (
    <div className="actions-right">
      {/* use this button to add a edit kind of action */}
      <Button
        justIcon
        round
        simple
        onClick={() => handleClickEdit(usergroup)}
        color="warning"
        className="edit"
      >
        <Tooltip title="Επεξεργασία">
          <EditIcon />
        </Tooltip>
      </Button>{" "}
      {/* use this button to remove the data row */}
      <Button
        justIcon
        round
        simple
        onClick={() => handleClickDelete(usergroup.usergroup_id)}
        color="danger"
        className="remove"
        disabled={usergroup.num_of_users > 0}
      >
        <Tooltip title="Διαγραφή">
          <Close />
        </Tooltip>
      </Button>{" "}
    </div>
  )
}

const GroupMemberActions = (props) => {
  const { groupMember, handleClickDelete } = props
  return (
    <div className="actions-right">
      {/* use this button to remove the data row */}
      <Button
        justIcon
        round
        simple
        onClick={() => handleClickDelete(groupMember.user_id)}
        color="danger"
        className="remove"
      >
        <Tooltip title="Διαγραφή από ομάδα">
          <Close />
        </Tooltip>
      </Button>{" "}
    </div>
  )
}

const RoleMemberActions = (props) => {
  const { roleMember, handleClickDelete, showEdit, handleClickEdit } = props
  return (
    <div className="actions-right">
      {
        showEdit &&
        <Button
          justIcon
          round
          simple
          onClick={() => handleClickEdit(roleMember.user_id)}
          color="warning"
          className="edit"
        >
          <Tooltip title="Επεξεργασία">
            <EditIcon />
          </Tooltip>
        </Button>
      }
      {/* use this button to remove the data row */}
      <Button
        justIcon
        round
        simple
        onClick={() => handleClickDelete(roleMember.user_id)}
        color="danger"
        className="remove"
      >
        <Tooltip title="Διαγραφή από ρόλο">
          <Close />
        </Tooltip>
      </Button>{" "}
    </div>
  )
}

const WorkAdminActions = (props) => {
  const { workAdmin, handleClickDelete } = props
  return (
    <div className="actions-right">
      {/* use this button to remove the data row */}
      <Button
        justIcon
        round
        simple
        onClick={() => handleClickDelete(workAdmin.id)}
        color="danger"
        className="remove"
      >
        <Tooltip title="Διαγραφή">
          <Close />
        </Tooltip>
      </Button>{" "}
    </div>
  )
}

export { UserActions, UsergroupActions, GroupMemberActions, RoleMemberActions, WorkAdminActions }
