import React from 'react';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const LexiconTab = (props) => {
  const { lemmata } = props

  return (
    lemmata.map((lemma) =>
      <Typography key={lemma.lemma_id}>
         <Link href={'/admin/Lexicon/' + lemma.lemma_id}>{lemma.title}</Link>
      </Typography>
    )
  )
}

export default LexiconTab
