import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SidebarShort from "components/Sidebar/SidebarShort.js";

import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import { UserReg } from "clients/userRegisterAndAuth.js";
import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { Copyright } from 'views/StandardIncludes/Footer.js'
import { emailRex } from 'variables/general.js'



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  /*const [passValue, setPassValue] = React.useState("");
  const [emailValue, setEmailValue] = React.useState("");*/
  const [firstnameValueState, setFirstnameValueState] = React.useState("");
  const [lastnameValueState, setLastnameValueState] = React.useState("");


  // register form
  const [firstnameValue, setFirstnameValue] = React.useState("");
  const [lastnameValue, setLastnameValue] = React.useState("");


  const [registerEmail, setregisterEmail] = React.useState("");
  const [registerEmailState, setregisterEmailState] = React.useState("");
  const [registerConfirmEmail, setregisterConfirmEmail] = React.useState("");
  const [registerConfirmEmailState, setregisterConfirmEmailState] = React.useState("");
  const [registerPassword, setregisterPassword] = React.useState("");
  const [registerPasswordState, setregisterPasswordState] = React.useState("");
  const [registerConfirmPassword, setregisterConfirmPassword] = React.useState(
    ""
  );
  const [
    registerConfirmPasswordState,
    setregisterConfirmPasswordState
  ] = React.useState("");
  /*const [registerCheckbox, setregisterCheckbox] = React.useState(false);
  const [registerCheckboxState, setregisterCheckboxState] = React.useState("");*/

  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };


  // function that returns true if value is email, false otherwise
  const verifyEmail = value => {
    //var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };


  const registerClick = () =>{



    if (firstnameValue===""){
      setFirstnameValueState("error");

    }

    if (lastnameValue===""){
      setLastnameValueState("error");

    }


    if (registerEmailState === "") {
      setregisterEmailState("error");
    }
    if (registerConfirmEmailState === ""){
      setregisterConfirmEmailState("error")
    }
    if (registerPasswordState === "") {
      setregisterPasswordState("error");
    }
    if (registerConfirmPasswordState === "") {
      setregisterConfirmPasswordState("error");
    }



  if ((firstnameValue!=="") && (lastnameValue!=="") && (registerEmailState === "success") && (registerConfirmEmailState === "success") &&
      (registerPasswordState === "success") && (registerConfirmPasswordState === "success"))  {
        UserReg({"firstname": firstnameValue, "lastname": lastnameValue, "username": registerEmail, "pass" : registerPassword},
          () => setOpenSnackbar(true),
          (err) => setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
        );
  }
  else {
    setAlert(<ErrorAlert message='Λάθος/Ελλιπείς παράμετροι εισόδου!' hideAlert={hideAlert}/>);
  }

  };


  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });


  return (

    <Container component="main" maxWidth="xs">
         <CssBaseline />
         <SidebarShort/>
         <div className={classes.paper}>

         <GridContainer justify="center">
           <GridItem xs={12} sm={12} >
             <form>
               <Card login className={classes[cardAnimaton]}>
                 <CardHeader
                   className="AristosCardHeader"
                 >
                   <h4 className={classes.cardTitle}>Εγγραφή Χρήστη</h4>
                 </CardHeader>
                 <CardBody>
                 <Grid container spacing={2}>
                   <Grid item xs={12}>

{/*
                   <CustomInput
                     id="firstname"
                     formControlProps={{
                       fullWidth: true,
                       className: classes.customFormControlClasses
                     }}
                     inputProps={{
                       startAdornment: (
                         <InputAdornment
                           position="start"
                           className={classes.inputAdornment}
                         >
                           <Face className={classes.inputAdornmentIcon} />
                         </InputAdornment>
                       ),
                       placeholder: "Όνομα...",
                       value : firstnameValue,
                       onChange : (e) => {setFistnameValue(e.target.value)}
                     }}
                   />
                   <CustomInput
                    id="lastname"
                     formControlProps={{
                       fullWidth: true,
                       className: classes.customFormControlClasses
                     }}
                     inputProps={{
                       startAdornment: (
                         <InputAdornment
                           position="start"
                           className={classes.inputAdornment}
                         >
                           <Face className={classes.inputAdornmentIcon} />
                         </InputAdornment>
                       ),
                       placeholder: "Επώνυμο...",
                       value : lastnameValue,
                       onChange : (e) => {setLastnameValue(e.target.value)}
                     }}
                   />

*/}

                   <CustomInput
                   success={firstnameValue !== ""}
                   error={firstnameValue === ""}
                     labelText="Όνομα *"
                     id="firstname"
                     formControlProps={{
                       fullWidth: true
                     }}
                     inputProps={{


                       onChange: event => {

                            setFirstnameValue(event.target.value);

                       },
                       type: "text"
                     }}
                   />

                   <CustomInput
                     success={lastnameValue !== ""}
                     error={lastnameValue === ""}
                     labelText="Επώνυμο *"
                     id="lastname"
                     formControlProps={{
                       fullWidth: true
                     }}
                     inputProps={{


                       onChange: event => {
                        setLastnameValue(event.target.value);
                       },
                       type: "text"
                     }}
                   />

                   <CustomInput
                   success={registerEmailState === "success"}
                   error={registerEmailState === "error"}

                     labelText="Email Address *"
                     id="email"
                     formControlProps={{
                       fullWidth: true
                     }}
                     inputProps={{
                   onChange: event => {
                         if (verifyEmail(event.target.value)) {
                           setregisterEmailState("success");
                         } else {
                           setregisterEmailState("error");
                         }
                         setregisterEmail(event.target.value);
                       },
                       type: "email"
                     }}
                   />
                   <CustomInput
                   success={registerConfirmEmailState === "success"}
                   error={registerConfirmEmailState === "error"}

                     labelText="Confirm Email Address *"
                     id="emailConfirm"
                     formControlProps={{
                       fullWidth: true
                     }}
                     inputProps={{
                   onChange: event => {
                         if (verifyEmail(event.target.value) && registerEmail === event.target.value) {
                           setregisterConfirmEmailState("success");
                         } else {
                           setregisterConfirmEmailState("error");
                         }
                         setregisterConfirmEmail(event.target.value);
                       },
                       type: "email"
                     }}
                   />

                   <CustomInput
                     success={registerPasswordState === "success"}
                     error={registerPasswordState === "error"}
                     labelText="Password *"
                     id="registerpassword"
                     formControlProps={{
                       fullWidth: true
                     }}
                     inputProps={{
                       onChange: event => {
                         if (verifyLength(event.target.value, 1)) {
                           setregisterPasswordState("success");
                         } else {
                           setregisterPasswordState("error");
                         }
                         setregisterPassword(event.target.value);
                       },
                       type: "password",
                       autoComplete: "off"
                     }}
                   />
                   <CustomInput
                     success={registerConfirmPasswordState === "success"}
                     error={registerConfirmPasswordState === "error"}
                     labelText="Confirm Password *"
                     id="registerconfirmpassword"
                     formControlProps={{
                       fullWidth: true
                     }}
                     inputProps={{
                       onChange: event => {
                         if (registerPassword === event.target.value) {
                           setregisterConfirmPasswordState("success");
                         } else {
                           setregisterConfirmPasswordState("error");
                         }
                         setregisterConfirmPassword(event.target.value);
                       },
                       type: "password",
                       autoComplete: "off"
                     }}
                   />





                {/*   <CustomInput
                     id="email"
                     formControlProps={{
                       fullWidth: true,
                       className: classes.customFormControlClasses
                     }}
                     inputProps={{
                       startAdornment: (
                         <InputAdornment
                           position="start"
                           className={classes.inputAdornment}
                         >
                           <Email className={classes.inputAdornmentIcon} />
                         </InputAdornment>
                       ),
                       placeholder: "Email...",
                       value : emailValue,
                       onChange : (e) => {setEmailValue(e.target.value)}
                     }}
                   />
                   <CustomInput
                     id="pass"
                     formControlProps={{
                       fullWidth: true,
                       className: classes.customFormControlClasses
                     }}
                     inputProps={{
                       startAdornment: (
                         <InputAdornment
                           position="start"
                           className={classes.inputAdornment}
                         >
                           <Icon className={classes.inputAdornmentIcon}>
                             lock_outline
                           </Icon>
                         </InputAdornment>
                       ),
                       placeholder: "Password...",
                       value : passValue,
                       onChange : (e) => {setPassValue(e.target.value)}
                     }}
                   />

                   */}
                   </Grid>


                 </Grid>
                 {/*<Button
                   type="submit"
                   fullWidth
                   variant="contained"
                   color="primary"
                   className={classes.submit}
                 >
                   Εγγραφη
                 </Button>
               */}

                 <Button
                //   type="submit"
                   fullWidth
                   variant="contained"
                   color="primary"
                //   className={classes.submit}
                   onClick={registerClick}

                 >

                  Εγγραφη
                 </Button>
                 <Grid container justify="flex-end">
                   <Grid item>
                     <Link href="./SignIn" variant="body2">
                      Έχετε ήδη εγγραφεί; Επιλέξτε Είσοδο...
                     </Link>
                   </Grid>
                 </Grid>
                 </CardBody>
                 <CardFooter className={classes.justifyContentCenter}>

                 </CardFooter>
               </Card>
             </form>
           </GridItem>
         </GridContainer>

         {alert}
         <Snackbar anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'center',
           }}
           open={openSnackbar}
           autoHideDuration={3000}
           onClose={handleCloseSnackbar}
           >
           <Alert onClose={handleCloseSnackbar} severity="success">
             Επιτυχημένη εγγραφή χρήστη! Μπορείτε να κάνετε είσοδο.
           </Alert>
         </Snackbar>

         </div>
         
         
         
         
       </Container>


  );
}
