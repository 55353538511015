
import { fetchWithGET, fetchWithMethod, fetchWithFile } from './clientUtils.js'

function getAllWorks(success, errorFunc){
  return fetchWithGET('works', '', success, errorFunc);
}

function getAllWorksWithBooks(params, success, errorFunc){
  let querystring = ''
  if (params){
    //querystring = '?'
    querystring += '?' + Object.keys(params).map((key) => {
      return key+'='+params[key]
    })
    .join('&')
  }
  //console.log(querystring)
  return fetchWithGET('works/info', querystring, success, errorFunc);
}

function addWork(vals, success, errorFunc){
  //console.log(JSON.stringify(vals));
  //console.log(vals);
  return fetchWithMethod('works', 'POST', vals, success, errorFunc);
}

function editWork(vals, success, errorFunc){
  //console.log(JSON.stringify(vals));
  //console.log(vals);
  return fetchWithMethod('works', 'PUT', vals, success, errorFunc);
}

function deleteWork(id, success, errorFunc){
  const vals = {id: id}

  return fetchWithMethod('works', 'DELETE', vals, success, errorFunc);
}

function uploadWorksBooksSections(file, success, errorFunc){
  return fetchWithFile('structure_from_file', 'POST', file, [], success, errorFunc)
  //return success({message:''})
}


export {getAllWorks, getAllWorksWithBooks, addWork, editWork, deleteWork, uploadWorksBooksSections}
