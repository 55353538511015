import React from 'react';
import CustomInput from "components/CustomInput/CustomInput.js";
import FormHelperText from '@material-ui/core/FormHelperText';

import { makeStyles } from '@material-ui/core/styles';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

//import Grid from '@material-ui/core/Grid';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import PermIdentity from "@material-ui/icons/PermIdentity";
import Clearfix from "components/Clearfix/Clearfix.js";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardFix: {
    fontSize: "1em!important",
    width: '600px',
  },
});

const UserForm = (props) => {
  const {handleChange, errors, firstname, lastname, username, username2, user_id, password, password2} = props

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <Card className={classes.cardFix}>
      <CardHeader className="AristosCardHeader" icon>
        <CardIcon color="rose">
          <PermIdentity />
        </CardIcon>
        <h4>
          Στοιχεία Χρήστη<small></small>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={5}>
            <CustomInput
              labelText="Όνομα"
              id="firstname"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: firstname,
                onChange: (e) => handleChange(e, 'firstname')
              }}
              error={Boolean(errors.firstname)}
            />
            <FormHelperText error>{errors.firstname}</FormHelperText>
          </GridItem>
          <GridItem xs={12} sm={12} md={7}>
            <CustomInput
              labelText="Επώνυμο"
              id="lastname"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: lastname,
                onChange: (e) => handleChange(e, 'lastname')
              }}
              error={Boolean(errors.lastname)}
            />
            <FormHelperText error>{errors.lastname}</FormHelperText>
          </GridItem>
          {
            (user_id===0) &&
            <>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Email (λειτουργεί ως Username)"
                  id="username"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: username,
                    onChange: (e) => handleChange(e, 'username'),
                  }}
                  error={Boolean(errors.username)}
                />
                <FormHelperText error>{errors.username}</FormHelperText>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Επιβεβαίωση Email"
                  id="username2"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: username2,
                    onChange: (e) => handleChange(e, 'username2'),
                  }}
                  error={Boolean(errors.username2)}
                />
                <FormHelperText error>{errors.username2}</FormHelperText>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: password,
                    onChange: (e) => handleChange(e, 'password'),
                    type: "password",
                    autoComplete: "off",
                  }}
                  error={Boolean(errors.password)}
                />
                <FormHelperText error>{errors.password}</FormHelperText>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Επιβεβαίωση Password"
                  id="password2"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: password2,
                    onChange: (e) => handleChange(e, 'password2'),
                    type: "password",
                    autoComplete: "off",
                  }}
                  error={Boolean(errors.password2)}
                />
                <FormHelperText error>{errors.password2}</FormHelperText>
              </GridItem>
            </>
          }
        </GridContainer>
        <Clearfix />
      </CardBody>
    </Card>
  )
}

const getValidationErrorsForUser = (fields, addingNewUser) => {
  const {firstname, lastname, username, username2, password, password2} = fields;
  //console.log(fields)
  const email_pattern = /([\w-.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w-]+\.)+)([a-zA-Z]{2,4}))/;
  let newErrors = {}

  if (firstname.length===0){
    newErrors.firstname = 'Υποχρεωτικό πεδίο'
  }
  if (lastname.length===0){
    newErrors.lastname = 'Υποχρεωτικό πεδίο'
  }

  if (addingNewUser){
    //email is checked only for new users
    if (username.length===0){
      newErrors.username = 'Υποχρεωτικό πεδίο'
    } else if (!email_pattern.test(username)){
      newErrors.username = 'Μη έγκυρο email'
    }
    if (username2.length===0){
      newErrors.username2 = 'Υποχρεωτικό πεδίο'
    } else if (!email_pattern.test(username2)){
      newErrors.username2 = 'Μη έγκυρο email'
    }
    if (username!==username2){
      newErrors.username = 'Τα email δεν ταυτίζονται'
      newErrors.username2 = 'Τα email δεν ταυτίζονται'
    }

    //password is checked only for new users
    if (password.length===0){
      newErrors.password = 'Υποχρεωτικό πεδίο'
    }
    if (password2.length===0){
      newErrors.password2 = 'Υποχρεωτικό πεδίο'
    }
    if (password!==password2){
      newErrors.password = 'Οι κωδικοί δεν ταυτίζονται'
      newErrors.password2 = 'Οι κωδικοί δεν ταυτίζονται'
    }
  }
  //console.log(newErrors)

  return newErrors;
}


export { UserForm, getValidationErrorsForUser }
