import React from 'react';
import Typography from '@material-ui/core/Typography';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
/*import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1), //grid padding
  },
}));*/

const LyricsDisplay = (props) => {
  //const classes = useStyles();
  const { text } = props
  const parse = require('html-react-parser');

  return (
    <GridContainer style = {{width:'100%'}}>
      {text && text.split('\n').map((line, idx) => {
        //remove Bekker number and lyric number in {}, add spacing for tab character
        let plainLineText = line.replace(/\$\w+\$/i, '')
                                .replace(/\{\d+[α-ωa-z]*\}/i, '')
                                .replace(/\t/g, '<span class="tab">&nbsp;</span>')

        //get lyric number, if one must be displayed
        let lyricNumber = ''
        const res = plainLineText.match(/&&[a-zα-ω0-9]+&&/gi);
        if (res){
        	res.reverse()
      	  plainLineText = plainLineText.replace(res[0], '')
          lyricNumber = res[0].replace(/&&/g, '')
        }
        if (plainLineText===''){
          plainLineText = '&nbsp;'
        }

        return (
          <GridItem xs={12} key={idx}>
            <GridContainer spacing={2}>
              <GridItem xs={10}>
                <Typography className="archaic_text"> {parse(plainLineText)}</Typography>
              </GridItem>
              <GridItem xs={2}>
                <Typography color='textSecondary'>{lyricNumber}</Typography>
              </GridItem>
            </GridContainer>
          </GridItem>
        )
      })
      }
    </GridContainer>
  )
}

export default LyricsDisplay
