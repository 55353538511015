import React from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'views/Dialogs/Dialogs.js'

const MaterialFileForm = (props) => {
  const { onSave, onClose, open, errors,
     title, handleTitleChange, file, handleFileChange } = props

  return(
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth
      maxWidth={"sm"}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Link σε αρχείο
        </DialogTitle>

        <DialogContent>
          <TextField
            label='Τίτλος'
            value={title}
            error={Boolean(errors.title)}
            onChange={handleTitleChange}
            style = {{width:500, paddingTop:10, marginTop:0}}
          />
          <FormHelperText error>{errors.title}</FormHelperText>

          <TextField
            label='Link'
            value={file}
            error={Boolean(errors.file)}
            onChange={handleFileChange}
            style = {{width:500, paddingTop:10, marginTop:0}}
          />
          <FormHelperText error>{errors.file}</FormHelperText>

        </DialogContent>

        <DialogActions>
          <Button onClick={onSave}>Καταχωριση</Button>
          <Button onClick={onClose}>Κλεισιμο</Button>
        </DialogActions>
     </Dialog>
  )
}

export default MaterialFileForm
