
export default function parseLyricsFile(file) {
  const lines = file.split('\n')
  const errors = []

  lines.forEach((line, idx) => {
    const line_num = idx + 1

    if (idx===0 || line.startsWith('##')){
      //line starts with section title
      const titlePos = line.search(/##\w+/i)
      if (titlePos!==0){
        //console.log(line)
        errors.push('Πρόβλημα σάρωσης αρχείου στη γραμμή: ' + line_num + ' - λείπει ο τίτλος του χωρίου')
      }
    } else {
      if (line.startsWith('$')) {
        //line starts with bekker number
        const bekkerPos = line.search(/\$\w+\$/i)
        if (bekkerPos !== 0){
          errors.push('Πρόβλημα σάρωσης αρχείου στη γραμμή: ' + line_num + ' - Κενός αριθμός Bekker')
        }
        line = line.replace(/\$\w*\$/i, '')
        line = line.trim()
        //console.log('replace'+line)
      }

      if (line.startsWith('{')){
        //line starts with lyric number
        const numberPos = line.search(/\{\d+[α-ωa-z]*\}/i)
        if (numberPos!==0){
          errors.push('Πρόβλημα σάρωσης αρχείου στη γραμμή: ' + line_num + ' - Κενή αρίθμηση στίχου'+numberPos)
        }

        if (line.endsWith('&&')){
          //line has number which must be shown at the end
          const matches = line.match(/&&\w+&&/gi)
          if (!matches){
            errors.push('Πρόβλημα σάρωσης αρχείου στη γραμμή: ' + line_num + ' - Κενή προβολή αρίθμησης στίχου')
          }
        }
      } else if (line.trim()!==''){
      //if line isn't empty, then it is invalid because it doesn't start with either bekker or lyrics number
      //console.log(line)
      errors.push('Πρόβλημα σάρωσης αρχείου στη γραμμή: ' + line_num + ' - Κενή αρίθμηση στίχου')
    }
    }
  })

  return errors
}
