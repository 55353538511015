import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ReactTable from "components/ReactTable/ReactTable.js";

const LexiconCatsGrid = (props) => {
  const { categories, onEditClick } = props

  return(
    <ReactTable data={categories.map((c) => {
      return {
        id: c.category_id,
        title: c.title,
        order: c.cat_order,
        actions: (
          <div className="actions-right">
            <IconButton aria-label="edit" onClick={() => onEditClick(c)}>
              <EditIcon style={{color:"#EBBA1A"}}/>
            </IconButton>
          </div>
        )
      }
    })}
    columns={[
      {
        Header: "Τίτλος",
        accessor: "title"
      },
      {
        Header: "Σειρά εμφάνισης",
        accessor: 'order'
      },
      {
        Header: "Ενέργειες",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ]}
    />
  )
}

export default LexiconCatsGrid
