import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({

  readMore: {
    border: "2px solid #ffffff",
	background:"#FFFFFF",
	padding: 5,
	fontSize: 14,
	color: "#DC7633",
	borderRadius:2,
  },
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
  //  backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: '#E0726F',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
    },
  },
}));

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  const { post } = props;

  return (
  //  <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${post.image})` }}>

  <Paper className={classes.mainFeaturedPost}>
      {/* Increase the priority of the hero background image */}
      {/*  {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />}*/}
      <div className={classes.overlay} />
      <Grid container>
        <Grid item md={12}>
          <div className={classes.mainFeaturedPostContent}>
            <Typography component="h1" variant="h5" color="inherit" gutterBottom>
              {post.title}
            </Typography>
						<Typography variant="h8" color="inherit" gutterBottom>
              {post.date}
            </Typography>
            <Typography variant="subtitle1" paragraph style={{ marginTop:'10px' }}>
              {post.description}
            </Typography>
            <Link variant="subtitle1" href="#" className={classes.readMore}>
              {post.linkText}
            </Link>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.object,
};
