import React from 'react';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import Assignment from "@material-ui/icons/Assignment";
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { uploadWorksBooksSections } from "clients/worksClient.js";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },

  cardFix: {
    fontSize: "1em!important",
  },
  LblClass: {
    color: "#415EA2",
    fontWeight: "700"
  },
  input: {
    display: 'none',
  },
  success: {
    color: 'green',
  },
}));

const UploadTab = (props) => {
  const classes = useStyles();

  const { handleShowSuccessMessage,  showErrorAlert, showWarningAlert } = props

  const [file, setFile] = React.useState(null)
  const [errors, setErrors] = React.useState([])

  const uploadFile = (e) => {
    //console.log('file upload')
    //console.log(e.target.files[0])
    const allowedExtensions = /(\.xlsx)$/i;
    /*
    // TODO: check for size???
    if (e.target.files[0].size > 1000){
      console.log('too big file')
    } else {*/
    if (!allowedExtensions.exec(e.target.files[0].name)) {
      setErrors({file: "Πρέπει να ανεβάσετε αρχείο Excel με κατάληξη .xlsx"})
    } else {
      setFile(e.target.files[0])
    }
  }

  const handleAdd = () => {
    if (!file){
      setErrors({file: "Υποχρεωτικό πεδίο"})
    } else {
      uploadWorksBooksSections(file,
        (data) => {
          //show messages
          setFile(null)
          if (data.message===''){
            handleShowSuccessMessage('Η καταχώριση έγινε με επιτυχία')
          } else {
            showWarningAlert('Η καταχώριση έγινε με επιτυχία, με εξαίρεση τα εξής:' + data.message)
          }
        },
        (err) => showErrorAlert(err.message)
      )
    }
  }

  return (
      <Card className={classes.cardFix}>
        <CardHeader className="AristosCardHeader" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4>Ανέβασμα από Excel</h4>
        </CardHeader>
        <CardBody>
          <Grid justify="center" container spacing={2}>

              <Grid item xs={6}>
               {/*
                 https://reactjs.org/docs/refs-and-the-dom.html
                 https://reactjs.org/docs/uncontrolled-components.html#the-file-input-tag
                 https://material-ui.com/components/buttons/ Upload Button
                 https://www.geeksforgeeks.org/file-uploading-in-react-js/

                 Set input value = '' so that we can upload the same file 2 times in a row https://programmersought.com/article/47732427884/
                 */}
                <input
                  accept=".xlsx"
                  className={classes.input}
                  id="contained-button-file-excel"
                  type="file"
                  onChange={uploadFile}
                  value={''}
                />
                <label htmlFor="contained-button-file-excel">
                  <Button variant="contained" color="primary" component="span">
                    Ανεβασμα αρχειου
                  </Button>
                </label>
                <FormHelperText error>{errors.file}</FormHelperText>
              </Grid>
              <Grid item xs={6}>
                {file && file.name}
              </Grid>

            <Grid item xs={12} style = {{paddingTop:20}}>
              <Button color="primary" onClick={handleAdd} >Προσθήκη</Button>
            </Grid>
        </Grid>

        </CardBody>
      </Card>
  )
}

export default UploadTab
