import React from 'react'

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from "components/CustomButtons/Button.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

import ImageThumbnailGrid from 'views/Images/ImageThumbnailGrid.js'
import ImageViewDialog from 'views/Images/ImageViewDialog.js'

import EditIcon from '@material-ui/icons/Edit';
/*import DeleteIcon from '@material-ui/icons/Delete';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ConfirmDeleteMessage } from 'views/CustomAlerts/ConfirmationDialog.js'
import { ErrorAlert } from 'views/CustomAlerts/CustomAlerts.js'*/

//import { deleteLemma } from 'clients/lexiconClient.js'
import { disableEvent } from 'utils.js'
import { lexiconImagesURL } from 'clients/clientConfig.js'

const useStyles = makeStyles((theme) => ({
  labels_title: {
    color: '#00aa00',
    fontWeight: 'bold'
  },
  entryTitle: {
    fontWeight: 'bold'
  }
}))

const EntryDisplay = (props) => {
  const classes = useStyles()
  const { entry, access_rights } = props
  //const sectionsRef = [{id: '2-1-1', lines: '295b', work_name: 'a work', refLine: '6-7'}, {id:'2-2-1', lines: '114c', work_name: 'work2', refLine: ''}]

  const parse = require('html-react-parser');

  const [open, setOpen] = React.useState(false)
  const [imageToView, setImageToView] = React.useState({})

  const keywordToLink = (keywords) => {
    return keywords.map(kw =>
      <span key={kw}>
        <Link href={'/admin/Lexicon/?keyword='+kw}>{kw}</Link> |&nbsp;
      </span>
    )
  }

  const openImage = (img) => {
    setImageToView(img)
    setOpen(true)
  }

  /*const confirmDelete = (id) => {
    setAlert(<ConfirmDeleteMessage onConfirm={() => deleteEntry(id)} onCancel={hideAlert}
      extraText="Το λήμμα αυτό ενδέχεται να υπάρχει ως link σε άλλα λήμματα. Αν κάνετε διαγραφή, τα link αυτά θα μείνουν ορφανά."/>)
  }

  const deleteEntry = (id) => {
    const params = {lemma_id: id}

    deleteLemma(params,
      () => {
        hideAlert()
        handleShowSuccessMessage('Το λήμμα διαγράφηκε με επιτυχία')

        setTimeout(() => window.location.href = '/admin/Lexicon', 2000)
      },
      (err) => setAlert(<ErrorAlert message={err.message} hideAlert={hideAlert}/>)
    )
  }

  //Alerts used
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  //Snackbar messages (to inform user of success)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const handleShowSuccessMessage = (message) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message)
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }*/

  return(
    <Card id="entrydisplay" className="whitebg">
      <CardHeader color='primary' className="archaic_text basecolorbg AristosCardHeader">
        <span className={classes.entryTitle}>{entry.title}</span>
        {entry.author && ' [Συντάκτης/τρια: ' + entry.author + ']'}
        <span style={{float:'right'}}>
          {
            access_rights && access_rights.w &&
            <Button justIcon round color="primary" onClick={() => window.location.href='/admin/AddLexiconEntry/'+entry.lemma_id}><EditIcon/></Button>
          }
          {/*<Button justIcon round color="primary" onClick={() => confirmDelete(entry.lemma_id)}><DeleteIcon/></Button>*/}
        </span>
        {
          entry.category &&
          <Typography variant='subtitle1' component="div">Θεματική ενότητα: {entry.category}</Typography>
        }
      </CardHeader>

      <CardBody onCopy={disableEvent} onCut={disableEvent}>
        {parse(entry.value)}
        {entry.bibliography &&
          <>
            <Divider/>
            <Typography variant="body1" component="div">
              <span  className={classes.labels_title}>Βιβλιογραφία:</span>
              {parse(entry.bibliography)}
            </Typography>
          </>
        }
        {entry.sectionsRef && entry.sectionsRef.length > 0 &&
          <>
            <Divider/>
            <Typography variant="body1">
              <span  className={classes.labels_title}>Χωρία:&nbsp;</span>
              {entry.sectionsRef.map((sr) =>
                <span key={sr.id}>
                  {' ' + sr.work_name + ', '}
                  <Link href={'/admin/CollTranslationREADONLY/'+sr.id}>{sr.lines}</Link>
                   &nbsp;{sr.refLine} |
                </span>)}
            </Typography>
          </>
        }
        {entry.tags_1 && entry.tags_1.length > 0 &&
          <>
            <Divider/>
            <Typography variant="body1">
              <span  className={classes.labels_title}>Συνδεδεμένα κείμενα: </span>
              {entry.tags_1.join(' | ')}
            </Typography>
          </>
        }
        {
          entry.lexicon_links && entry.lexicon_links.length > 0 &&
          <>
            <Divider/>
            <Typography variant="body1">
              <span  className={classes.labels_title}>Συνδεδεμένα λήμματα: </span>
              {
                entry.lexicon_links.map(l =>
                  <span key={l.lemma_id}>
                    <Link href={'/admin/Lexicon/'+l.lemma_id}>{l.title}</Link> |&nbsp;
                  </span>
                )
              }
            </Typography>
          </>
        }
        {entry.keywords && entry.keywords.length > 0 &&
          <>
            <Divider/>
            <Typography variant="body1">
              <span  className={classes.labels_title}>Λέξεις-κλειδιά: </span>
              {keywordToLink(entry.keywords)}
            </Typography>
          </>
        }
        <Divider/>
        {
          entry.forumsRef && entry.forumsRef.length > 0 &&
          <Typography variant="body1">
            <span  className={classes.labels_title}>Συζητήσεις σε Φόρουμ: </span>
            {entry.forumsRef.map((fr) =>
              <span key={fr.id}>
                <Link href={'/admin/Forum/'+fr.id}>{fr.name}</Link>
                 &nbsp;|&nbsp;
              </span>)}
          </Typography>
        }

        {
          entry.images &&
            <>
              <Divider/>
              <br/>
              <ImageThumbnailGrid images={entry.images} onClick={openImage}
                baseUrl={lexiconImagesURL+'/'+entry.lemma_id+'/'}/>
            </>
        }

        <ImageViewDialog image={imageToView} open={open} baseUrl={lexiconImagesURL+'/'+entry.lemma_id+'/'}
         onClose={() => setOpen(false)}/>

        {/*alert}

        <Snackbar anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          >
          <Alert onClose={handleCloseSnackbar} severity="success">
            {snackbarMessage}
          </Alert>
        </Snackbar>*/}
      </CardBody>
    </Card>
  )
}

export default EntryDisplay
